import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../../../../../../../../utils/codes";
import {
  getFormsDelete,
  getRequestTypes,
} from "../../../../../../../../services/documents/formCustomization/CustomizationService";

export const formCustomizationSlice = createSlice({
  name: "formCustom",
  initialState: {
    formList: [],
    formListDelete: [],
    status: "loading",
    deleteStatus: "loading",
    error: null,
    deleteError: null,
    selectedForm: {},
  },
  reducers: {
    resetFormListStatus(state, action) {
      state.status = "loading";
      state.deleteStatus = "loading";
    },
    setSelectedForm(state, action) {
      state.selectedForm = action.payload;
    },
    updateFormById(state, action) {
      state.selectedForm.sections = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomization.pending, (state) => {
        state.status = "loading";
      })

      .addCase(fetchCustomization.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.formList = action?.payload?.data || [];
      })

      .addCase(fetchCustomization.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.responseMessage;
      })
      .addCase(fetchFormsDelete.pending, (state) => {
        state.deleteStatus = "loading";
      })

      .addCase(fetchFormsDelete.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        state.formListDelete = action.payload.data;
      })

      .addCase(fetchFormsDelete.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.responseMessage;
      });
  },
});

export const { resetFormListStatus, updateFormById } =
  formCustomizationSlice.actions;
export const getUpdateForm = (state) => state.formCustom.selectedForm.sections;
export const getFormList = (state) => state.formCustom.formList;
export const getFormListStatus = (state) => state.formCustom.status;

export const getFormListDelete = (state) => state.formCustom.formListDelete;
export const getFormListStatusDelete = (state) => state.formCustom.deleteStatus;

export const fetchCustomization = createAsyncThunk(
  "formCustom/fetchCustomization",
  async ({ corporateUnitsIds = [], onlyCustomRequests = false }) => {
    const companyId = localStorage.getItem("company");

    const obj = {
      companyId,
      corporateUnitsIds,
      onlyCustomRequests,
    };
    const response = await getRequestTypes(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data.responseMessage,
      };
    }
  }
);

export const fetchFormsDelete = createAsyncThunk(
  "formCustom/fetchFormsDelete",
  async () => {
    const companyId = localStorage.getItem("company");
    const payloadTokenInfo = localStorage.getItem("payloadToken") ?? "{}";
    const userCorporateUnitsInfo = localStorage.getItem("userCorporateUnits") ?? "[]";
    const {
      loginType = "",
      subRole = "",
      corporateUnits: defaultUC = [],
    } = JSON.parse(payloadTokenInfo);
    const userCorporateUnits = JSON.parse(userCorporateUnitsInfo) ?? [];
    const corporateUnitId = localStorage.getItem("corporateUnitId");
    let associatedCorporateUnits = [];

    if (loginType === "ADMIN") {
      if (subRole !== "ADMIN PROPIETARIO") {
        const corporateUnits = localStorage.getItem("userCorporateUnits")
          ? userCorporateUnits
          : defaultUC;
        associatedCorporateUnits = corporateUnits?.map((uc) => uc?._id) ?? [];
      }
    } else {
      associatedCorporateUnits = [corporateUnitId];
    }

    const response = await getFormsDelete({
      companyId,
      corporateUnitsIds: associatedCorporateUnits,
    });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data.responseMessage,
      };
    }
  }
);

export default formCustomizationSlice.reducer;
