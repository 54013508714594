import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Button, IconButton, Tooltip } from "@mui/material";
import Breadcrum from "../../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../../components/sectionTitle";
import LoadingContent from "../../../../../../../components/loadingContent";
import "moment/locale/es";
import CustomSearch from "../../../../../../../components/Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import {
  fetchCustomization,
  getFormListStatus,
  getFormList,
  resetFormListStatus,
} from "./states/formCustomizationSlice";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../unitCorporativeSlice";
import {
  fetchFormTags,
  getTagsFormList,
  getTagsFormStatus,
} from "./pages/manageCustomFormTags/states/tagSliceForms";
//Icons
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippetOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNewOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import juezSearch from "../../../../../../../assets/svg/juezSearch.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { CODES } from "../../../../../../../utils/codes";
import { ModalListSelectCheck } from "../../../../../../../components/Modals/modalListCheck";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import { useNavigate } from "react-router-dom";
import {
  updateCorporateUnitsOnRequestType,
  resetRequestTypeForm,
  deleteTemporalForm,
} from "../../../../../../../services/documents/formCustomization/CustomizationService";
import { ResetRequestTypeFormDto } from "../../../../../../../services/documents/formCustomization/dto/resetRequestTypeFormDto";
import { Check, Close, DeleteOutline, PostAdd } from "@mui/icons-material";
import FilterTagParts from "../../../../../../../parts/filters/FilterTagParts";
import DetailsForm from "./components/DetailsForm";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export default function ManageFormModule() {
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusUnitsCorporatives = useSelector(getUnitsCorporativesStatus);
  const tagsList = useSelector(getTagsFormList);
  const statusTags = useSelector(getTagsFormStatus);
  const customState = useSelector(getFormListStatus);
  const FormCustomList = useSelector(getFormList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const COLUMNS_FORM_CUSTOMIZATION = [
    {
      label: [
        <label
          aria-hidden="true"
          className="default-text--xs text-color--dt-column"
          key="0"
        >
          Nombre
        </label>,
      ],
      field: "icon",
    },

    {
      label: [
        <label
          aria-hidden="true"
          className="default-text--xs text-color--dt-column"
          key="1"
        >
          Descripción
        </label>,
      ],
      field: "description",
    },

    {
      label: [
        <label
          aria-hidden="true"
          className="default-text--xs text-color--dt-column"
          key="2"
        >
          Unidades Corporativas
        </label>,
      ],
      field: "corporateUnits",
    },

    {
      label: [
        <label
          aria-hidden="true"
          className="default-text--xs text-color--dt-column"
          key="3"
        >
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];

  const [data, setData] = useState({
    columns: COLUMNS_FORM_CUSTOMIZATION,
    rows: [],
  });
  const [filteredData, setFilteredData] = useState({
    columns: COLUMNS_FORM_CUSTOMIZATION,
    rows: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingAllTags, setIsLoadingAllTags] = useState(false);
  const [isLoadingAllUC, setIsLoadingAllUC] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [isOpenDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [isOpenModalSelectUC, setIsOpenModalSelectUC] = useState(false);
  const [formSelected, setFormSelected] = useState({});
  const [isModalResetCustomFormOpen, setIsModalResetCustomFormOpen] =
    useState(false);
  const [resetCustomFormResponseData, setResetCustomFormResponseData] =
    useState({});
  const [modalDecisionInfo, setModalDecisionInfo] = useState({
    open: false,
    type: undefined,
    title: undefined,
    message: undefined,
    agreeText: undefined,
    agreeIcon: undefined,
    agreeIconStart: undefined,
    disagreeText: undefined,
    disagreeIcon: undefined,
    disagreeIconStart: undefined,
    handleAgree: () => undefined,
    handleDisagree: () => undefined,
    onClose: () => undefined,
  });

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteCorporateUnit = async ({ id, element }) => {
    try {
      setIsLoadingAll(true);

      const company = localStorage.getItem("company");
      const corporateUnits = element?.corporateUnits ?? [];
      const corporateUnitsToKeep = corporateUnits
        .filter((uc) => uc._id !== id)
        .map((uc) => uc._id);
      const responseDataCorporateUnit = await updateCorporateUnitsOnRequestType(
        {
          company,
          formId: element._id,
          corporateUnitsId: corporateUnitsToKeep,
        }
      );

      let objResponse = {
        title: "Formularios",
        type: "warning",
        message: "No se ha podido agregar o eliminar la unidad corporativa.",
      };

      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        objResponse = {
          title: "Formularios",
          message: "Se ha eliminado la unidad corporativa del formulario",
        };
        handleFetchCustomization();
      } else if (
        responseDataCorporateUnit?.status ===
        CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        objResponse = {
          type: "warning",
          title: "Sin permisos suficientes",
          message: responseDataCorporateUnit.data.responseMessage,
        };
      }
      setIsOpenModalResponse(true);
      setResponseData(objResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleDeleteTag = (tag) => {
    const newTags = tagsSelected.filter((t) => t._id !== tag._id);
    setTagsSelected(newTags);
    if (newTags.length > 0) {
      let filteredForms = [];
      let filterTags = [];
      const selectedList = newTags.map((item) => item._id);

      filteredData.rows.forEach((item) => {
        const labelsList = item.labels;
        filterTags = labelsList.some((tag) => selectedList.includes(tag._id));
        if (filterTags) {
          filteredForms.push(item);
        }
      });
      setFilteredData({ ...filteredData, rows: filteredForms });
    } else {
      setFilteredData(data);
    }
  };

  const handleOpenModalListCorporateUnits = (info) => {
    try {
      setUnitCorporativeSelected(info.corporateUnits);
      setFormSelected(info);
      setIsOpenModalSelectUC(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCorporateUnit = async (data) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalSelectUC(false);
      const company = localStorage.getItem("company");
      const corporateUnitsSelected = data.map((uc) => uc?._id);

      const responseDataCorporateUnit = await updateCorporateUnitsOnRequestType(
        {
          formId: formSelected._id,
          company,
          corporateUnitsId: corporateUnitsSelected,
        }
      );

      let objResponse = {
        title: "Formularios",
        type: "warning",
        message: "No se ha podido agregar o eliminar la unidad corporativa.",
      };

      if (
        responseDataCorporateUnit.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        responseDataCorporateUnit.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        objResponse = {
          title: "Formularios",
          message: "Se ha agregado la unidad corporativa al formulario",
        };
        dispatch(resetFormListStatus());
      } else if (
        responseDataCorporateUnit?.status ===
        CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        objResponse = {
          type: "warning",
          title: "Sin permisos suficientes",
          message: responseDataCorporateUnit.data.responseMessage,
        };
      }

      setIsOpenModalResponse(true);
      setResponseData(objResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
      setFormSelected(null);
    }
  };

  const handleSelectedViewDetails = (selectedForm) => {
    setFormSelected(selectedForm);
    setOpenDetailsDrawer(true);
  };

  const resetCustomForm = async (customForm) => {
    try {
      setIsLoadingAll(true);
      const companyId = localStorage.getItem("company");
      const resetRequestTypeFormDto = new ResetRequestTypeFormDto({
        formId: customForm._id,
        companyId: companyId,
        originalFormId: customForm.originalFormId,
      });
      const response = await resetRequestTypeForm(resetRequestTypeFormDto);
      let objResponse = {
        type: "warning",
        title: "Error restableciendo el formulario",
        message: "No se ha restablecido el formulario.",
      };
      if (response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        objResponse = {
          title: "Formulario restablecido",
          message: "El formulario ha sido restablecido a su version original.",
        };
      } else if (response?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        objResponse = {
          type: "warning",
          title: "Sin permisos suficientes",
          message: response.data.responseMessage,
        };
      }
      setIsOpenModalResponse(true);
      setResponseData(objResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleUpdateFormAction = (item) => {
    navigate(`${item._id}`);
  };

  /**
   * Delete the custom form.
   *
   * @param {string} formId - The form id to delete.
   * @returns {Promise<boolean>} True if the form was deleted, false otherwise.
   */
  const deleteCustomForm = async (formId) => {
    let success = false;
    try {
      setIsLoadingAll(true);
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));
      const response = await deleteTemporalForm({
        formId,
        modifiedBy: userId,
      });
      success = !!response;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
    return success;
  };

  const handleFetchCustomization = () => {
    setIsLoadingAll(true);
    const payloadTokenInfo = localStorage.getItem("payloadToken") ?? "{}"
    const userCorporateUnitsInfo = localStorage.getItem("userCorporateUnits") ?? "[]";
    const {
      loginType = "",
      subRole = "",
      corporateUnits: defaultUC = [],
    } = JSON.parse(payloadTokenInfo);
    const userCorporateUnits = JSON.parse(userCorporateUnitsInfo) ;
    const corporateUnitId = localStorage.getItem("corporateUnitId") ?? "";
    let associatedCorporateUnits = [];

    if (loginType === "ADMIN") {
      if (subRole !== "ADMIN PROPIETARIO") {
        const corporateUnits = localStorage.getItem("userCorporateUnits")
          ? userCorporateUnits
          : defaultUC;
        associatedCorporateUnits = corporateUnits?.map((uc) => uc?._id) ?? [];
      }
    } else {
      associatedCorporateUnits = [corporateUnitId];
    }

    dispatch(
      fetchCustomization({
        corporateUnitsIds: associatedCorporateUnits,
        onlyCustomRequests: loginType === "ADMIN",
      })
    );
    setIsLoadingAll(false);
  };

  /**
   * Open the modal to confirm the delete of the custom form.
   *
   * This modal is opened when the user clicks on the delete button of a custom form.
   *
   * @param {string} customFormId - The custom form id to delete.
   */
  const openDeleteCustomFormWarningModal = (customFormId) => {
    setModalDecisionInfo({
      open: true,
      type: "warning",
      title: "Eliminar formulario",
      message: "¿Seguro quieres eliminar esté formulario?",
      agreeText: "Eliminar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: async () => {
        setModalDecisionInfo((prevState) => ({ ...prevState, open: false }));
        const success = await deleteCustomForm(customFormId);
        if (success) {
          handleFetchCustomization();
          dispatch(resetFormListStatus());
        }
      },
      disagreeText: "Cancelar",
      disagreeIconStart: <Close fontSize="large" />,
      handleDisagree: () =>
        setModalDecisionInfo((prevState) => ({ ...prevState, open: false })),
      onClose: () =>
        setModalDecisionInfo((prevState) => ({ ...prevState, open: false })),
    });
  };

  const buildRows = (data) => {
    return data.flatMap((item) =>
      item.subtypes.map((subtype) => ({
        icon: (
          <Row
            style={{
              paddingLeft: "2%",
              paddingTop: "1%",
              cursor: "pointer",
            }}
            onClick={() => handleUpdateFormAction(subtype)}
          >
            <Col xs={"auto"} className="datatable-CB__tr-name">
              <TextSnippetIcon
                fontSize="large"
                className="heading__primary-color"
              />
            </Col>
            <Col className="datatable-CB__tr-name">{subtype.name}</Col>
          </Row>
        ),

        description: (
          <Row>
            <p
              className="caption justify-text text-ellipsis-t"
              style={{ padding: "0 2rem" }}
            >
              {subtype.description}
            </p>
          </Row>
        ),

        corporateUnits: (
          <Row style={{ rowGap: 5, alignItems: "center" }}>
            {subtype?.corporateUnits?.length > 0 &&
              subtype.corporateUnits.map(({ _id, name }) => {
                return (
                  <Col key={_id} xs={12} lg={6}>
                    <span
                      style={{ cursor: "pointer", display: "inline-block" }}
                      className="caption custom-badges__secundary"
                      onClick={() =>
                        handleDeleteCorporateUnit({
                          id: _id,
                          element: subtype,
                        })
                      }
                    >
                      {name + " "}{" "}
                      <CloseIcon className="custom-badges__delete-button" />
                    </span>
                  </Col>
                );
              })}
            <Col xs={12} lg={6}>
              <span
                style={{ cursor: "pointer" }}
                className="caption custom-badges__disabled"
                onClick={() => handleOpenModalListCorporateUnits(subtype)}
              >
                Agregar
                <AddOutlinedIcon
                  fontSize="large"
                  className="custom-badges__delete-button"
                />
              </span>
            </Col>
          </Row>
        ),
        actions: (
          <Stack direction="horizontal" gap={2} className="flex-wrap">
            <Tooltip title={<h5>Editar formulario</h5>}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => handleUpdateFormAction(subtype)}
              >
                <EditIcon fontSize="large" className="heading__primary-color" />
              </IconButton>
            </Tooltip>
            {subtype.originalFormId ? (
              <Tooltip title={<h5>Restablecer formulario</h5>}>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={() =>
                    setModalDecisionInfo({
                      open: true,
                      type: "warning",
                      title: "Atención",
                      message:
                        "¿Esta seguro de restablecer este formulario? Todos los cambios se restablecerán.",
                      agreeText: "Aceptar",
                      agreeIconStart: <Check fontSize="large" />,
                      handleAgree: () => {
                        setModalDecisionInfo((prevState) => ({
                          ...prevState,
                          open: false,
                        }));
                        resetCustomForm(subtype);
                      },
                      disagreeText: "Cancelar",
                      disagreeIconStart: <Close fontSize="large" />,
                      handleDisagree: () =>
                        setModalDecisionInfo((prevState) => ({
                          ...prevState,
                          open: false,
                        })),
                      onClose: () =>
                        setModalDecisionInfo((prevState) => ({
                          ...prevState,
                          open: false,
                        })),
                    })
                  }
                >
                  <SettingsBackupRestoreIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<h5>Eliminar formulario</h5>}>
                <IconButton
                  className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                  sx={{ borderRadius: "8px" }}
                  onClick={() => openDeleteCustomFormWarningModal(subtype._id)}
                >
                  <DeleteOutline
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={<h5>Ver detalles</h5>}>
              <IconButton
                className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={() => handleSelectedViewDetails(subtype)}
              >
                <OpenInNewIcon
                  fontSize="large"
                  className="heading__primary-color"
                />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        labels: subtype.labels,
      }))
    );
  };

  /**
   * Set the corporate units by the user role and login type.
   *
   * If the user is an owner admin, the corporate units are set by the list of corporate units, otherwise the corporate units are set by the user corporate units.
   */
  const setCorporativeUnitsByUserRoleAndLoginType = () => {
    try {
      const {
        loginType = "",
        subRole = "",
        corporateUnits: defaultUC = [],
      } = JSON.parse(localStorage.getItem("payloadToken"));
      const userCorporateUnits = JSON.parse(localStorage.getItem("userCorporateUnits")) ?? [];
      let associatedCorporateUnits = [];

      if (loginType === "ADMIN" && subRole === "ADMIN PROPIETARIO") {
        associatedCorporateUnits = getListUnitsCorporatives ?? [];
      } else {
        const corporateUnits = localStorage.getItem("userCorporateUnits")
          ? userCorporateUnits
          : defaultUC;
        associatedCorporateUnits = corporateUnits ?? [];
      }

      setListUnitsCorporatives(associatedCorporateUnits);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Check if the user has the permissions this module.
   */
  useEffect(() => {
    /** @type {Object | undefined} */
    let payloadToken;
    try {
      payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    } catch (error) {
      throw new Error("Error obtaining the permissions for update custom forms.");
    }
    /** @type {Object | undefined} */
    const permissionsRequests = payloadToken?.permissions?.find(({module}) => module === "Requests");
    /** @type {boolean} */
    const isAllowUpdateCustomForms = permissionsRequests?.actions?.updateCustomForms ?? false;

    !isAllowUpdateCustomForms && navigate(-1);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customState === "loading") {
          handleFetchCustomization();
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [customState, dispatch]);

  useEffect(() => {
    if (FormCustomList.length) {
      const rows = buildRows(FormCustomList);
      setData({ columns: COLUMNS_FORM_CUSTOMIZATION, rows: rows });
      setFilteredData({ columns: COLUMNS_FORM_CUSTOMIZATION, rows: rows });
    }
  }, [FormCustomList]);

  useEffect(() => {
    if (customState === "loading") {
      setIsLoadingAll(true);
    } else {
      setIsLoadingAll(false);
    }
  }, [customState]);

  useEffect(() => {
    if (statusUnitsCorporatives === "loading") {
      setIsLoadingAllUC(true);
    } else {
      setIsLoadingAllUC(false);
    }
    
    if (statusUnitsCorporatives === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    } else if (statusUnitsCorporatives === "success") {
      setCorporativeUnitsByUserRoleAndLoginType();
    }
  }, [statusUnitsCorporatives]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFormTags("CUSTOMFORM"));
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (statusTags === "loading") {
      setIsLoadingAllTags(true);
    } else {
      setIsLoadingAllTags(false);
    }
  }, [statusTags]);

  useEffect(() => {
    if (isLoadingAll || isLoadingAllTags || isLoadingAllUC) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingAllUC, isLoadingAll, isLoadingAllTags, isLoading]);

  return (
    <Container
      fluid
      className="custom-container-scroll d-flex flex-column gap-4"
    >
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row className="align-items-center justify-content-between g-3">
        <Col xs={"auto"}>
          <SectionTitle title={"Personaliza tus formularios"} />
        </Col>
        <Col xs="auto">
          <Stack direction="horizontal" gap={3}>
            <Button
              variant="contained"
              startIcon={<DeleteOutlinedIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => navigate("paperbasket")}
              sx={{ whiteSpace: "nowrap", width: "fit-content" }}
            >
              <p className="button">Papelera</p>
            </Button>

            <Button
              variant="contained"
              startIcon={<BookmarksOutlinedIcon />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("tags")}
              sx={{ whiteSpace: "nowrap", width: "fit-content" }}
            >
              <p className="button">Administrar etiquetas</p>
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <p className="caption">
          Adapta los formularios de las solicitudes según tus necesidades.
        </p>
      </Row>
      <Row className="justify-content-between align-items-center g-3">
        <Col xs={12} md={6}>
          <CustomSearch
            placeholder="Buscar"
            dataTableInfo={data}
            setFilteredDataTable={setFilteredData}
            maxLength={20}
          />
        </Col>
        <Col xs="auto">
          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <Button
              variant="contained"
              startIcon={<PostAdd />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("create")}
              style={{
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              Crear formulario
            </Button>
            <FilterTagParts
              list={data}
              setFilteredInfo={setFilteredData}
              handleTagsSelected={handleTagsSelected}
              tagsList={tagsList}
              currentTagSelected={tagsSelected}
              classStyle="custom-input__button__secondary-color"
              widthStyle="fit-content"
            />
          </Stack>
        </Col>
      </Row>
      <Row style={{ justifyContent: "end" }}>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag}>
              <span className="caption custom-badges__disabled">
                {tag.name + " "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="data-table dt-list-items ">
        <MDBDataTableV5
          hover={filteredData.rows && filteredData.rows.length > 0}
          pagingTop
          searchBottom={false}
          entries={6}
          data={filteredData}
          entrieslabel=""
          infoLabel={["Mostrando", "a", "de", "formulario"]}
          fullPagination
          onSort={(value) => {
            onSortHTMLElement({
              excludesColumns: ["actions", "description", "corporateUnits"],
              sort: value,
              filteredDataTable: filteredData,
              setFilteredDataTable: setFilteredData,
            });
          }}
          noRecordsFoundLabel={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={juezSearch} alt="No hay resultados" />
            </div>
          }
        />
      </Row>
      <ModalListSelectCheck
        handleAgree={handleAddCorporateUnit}
        list={listUnitsCorporatives}
        onClose={() => setIsOpenModalSelectUC(false)}
        open={isOpenModalSelectUC}
        title="Agregar unidades corporativas"
        currentDataSelected={unitCorporativeSelected}
      />
      <ModalDecision
        open={isOpenModalResponse}
        title={responseData?.title}
        message={responseData?.message}
        agreeText={"Aceptar"}
        type={responseData?.type}
        onClose={() => setIsOpenModalResponse(false)}
        handleAgree={() => setIsOpenModalResponse(false)}
      />
      <ModalDecision {...modalDecisionInfo} />
      <ModalDecision
        open={isModalResetCustomFormOpen}
        title={
          resetCustomFormResponseData?.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
            ? "Formulario restablecido"
            : "Error restableciendo el formulario"
        }
        message={
          resetCustomFormResponseData?.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
            ? "El formulario ha sido restablecido a su version original."
            : "No se ha restablecido el formulario."
        }
        agreeText={"Aceptar"}
        type={
          resetCustomFormResponseData?.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
            ? undefined
            : "warning"
        }
        onClose={() => {
          dispatch(resetFormListStatus());
          setIsModalResetCustomFormOpen(false);
        }}
        handleAgree={() => {
          dispatch(resetFormListStatus());
          setIsModalResetCustomFormOpen(false);
        }}
      />
      <DetailsForm
        openDetailsDrawer={isOpenDetailsDrawer}
        setOpenDetailsDrawer={setOpenDetailsDrawer}
        selectedElement={formSelected}
      />
    </Container>
  );
}
