
/**
 * Represents the dto for reset a request type form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class ResetRequestTypeFormDto {
  /**
   * @readonly
   */
  formId;
  /**
   * @readonly
   */
  companyId;
  /**
   * @readonly
   */
  originalFormId;

  /**
   * Initializes a new instance of the FindRequestTypeFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} props.formId - The id of the form.
   * @param {string} props.originalFormId - The id of the original form.
   * @returns {ResetRequestTypeFormDto} The new instance of the FindRequestTypeFormDto class.
   * @throws {Error} If the validation fails.
   * @example
   * const findRequestTypeFormDto = new FindRequestTypeFormDto({ formId: 'formId', companyId: 'companyId', originalFormId: 'originalFormId' }); 
   */
  constructor({ formId, companyId, originalFormId }) {
    ResetRequestTypeFormDto.validate({ formId, companyId, originalFormId });
    this.formId = formId;
    this.companyId = companyId;
    this.originalFormId = originalFormId;
    Object.freeze(this);
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ formId, companyId, originalFormId }) {
    if (!formId || typeof formId !== "string")
      throw new Error("The formId is required and must be a string");
    if (!companyId || typeof companyId !== "string")
      throw new Error("The companyId is required and must be a string");
    if (!originalFormId || typeof companyId !== "string")
      throw new Error("The originalFormId is required and must be a string");
  }
}
