import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ModalInfo from "../../components/Modals/modalInfo";
import { createTags } from "../manageTags/tagSlice";
import { createTags as createTagsFolder } from "../../parts/folders/folderTagSlice";
import { createFormTags } from "../../pages/views/admin/modules/unitCorporative/personalize/formCustomization/pages/manageCustomFormTags/states/tagSliceForms";
import { useDispatch } from "react-redux";
import { addTagContractType } from "../manageTags/contractTypes/tagContractTypesSlice";
import { CODES } from "../../utils/codes";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalCreateTag = ({ type }) => {
  const dispatch = useDispatch();
  const [isOpenModalCreateTag, setIsOpenModalCreateTag] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("*Este campo es obligatorio"),
    description: yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setIsOpenModalCreateTag(false);
  };
  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
    handleClose();
  };

  const handleClickOpen = () => {
    setIsOpenModalCreateTag(true);
  };

  const handleCreateTag = async (data) => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      let obj = {
        name: data.name,
        description: data.description,
        company: companyId,
        resourceType: type,
      };
      let response = {};
      if (type === "BUSINESSFOLDER") {
        response = await dispatch(createTagsFolder(obj)).unwrap();
      }
      if (type === "CONTRACTTYPES") {
        response = await dispatch(addTagContractType(obj)).unwrap();
      }
      if (["CLAUSES", "PARTSLIBRARY", "DOCUMENT_TEMPLETES"].includes(type)) {
        response = await dispatch(createTags(obj)).unwrap();
      }
      if (type === "CUSTOMFORM") {
        response = await dispatch(createFormTags(obj)).unwrap();
      }
      setResponseData(response);
      reset();
      setIsLoading(false);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Button
        variant="contained"
        startIcon={<AddIcon fontSize="large" />}
        className="custom-input__button__primary-color"
        onClick={handleClickOpen}
      >
        Crear etiqueta
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        open={isOpenModalCreateTag}
        aria-labelledby="customized-dialog-title"
      >
        <Form>
          <DialogTitleContainer onClose={handleClose}>
            <label className="heading__primary-color">{"Crear etiqueta"}</label>
          </DialogTitleContainer>
          <DialogContent className="caption" dividers>
            <Container fluid>
              <Form.Group className="mb-5">
                <Form.Label className="form__label">
                  Nombre de la etiqueta{" "}
                  <span style={{ color: "red" }}>{"*"}</span>
                </Form.Label>
                <Form.Control
                  {...register("name")}
                  placeholder={"Nombre de la etiqueta"}
                  bsPrefix={
                    errors?.name || watch("name")?.length == 100
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  maxLength={100}
                />
                <div className="caption custom-input__error">
                  {errors.name?.message}
                </div>

                <label
                  className={
                    watch("name")?.length == 100
                      ? "caption__error"
                      : "caption__primary-color"
                  }
                >
                  Máximo 100 caracteres permitidos
                </label>
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Label className="form__label">
                  Descripción de la etiqueta
                </Form.Label>
                <Form.Control
                  {...register("description")}
                  as="textarea"
                  placeholder="Descripción de la etiqueta"
                  bsPrefix={
                    errors?.description || watch("description")?.length == 200
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  maxLength={200}
                />
                <div className="caption custom-input__error">
                  {errors.description?.message}
                </div>

                <label
                  className={
                    watch("description")?.length == 200
                      ? "caption__error"
                      : "caption__primary-color"
                  }
                >
                  Máximo 200 caracteres permitidos
                </label>
              </Form.Group>
            </Container>
          </DialogContent>
          <DialogActions>
            <Container fluid>
              <Row xs={"auto"} style={{ justifyContent: "right" }}>
                <Col>
                  <Button
                    variant="contained"
                    className="custom-input__button__secondary-color"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    onClick={handleSubmit(handleCreateTag)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        className="spinner__white-background"
                      />
                    ) : (
                      "Aceptar"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </DialogActions>
        </Form>
      </Dialog>
      <ModalInfo
        title={
          responseData.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
            ? "Cambios guardados"
            : "Error al crear etiqueta"
        }
        responseData={responseData}
        open={isOpenModalInfo}
        onClose={handleCloseModalInfo}
      />
    </Container>
  );
};

export default ModalCreateTag;
