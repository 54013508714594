import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const createApproval = (
  consecutive,
  aplicantName,
  approverName,
  approverPosition,
  approverMail,
  message,
  associatedDocument,
  company,
  isMainDocument,
  state,
  corporateUnit
) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/addApprovalToRequest`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      approval: {
        aplicantName,
        approverName,
        approverPosition,
        approverMail,
        message,
        associatedDocument,
        company,
        isMainDocument,
        state,
        corporateUnit,
      },
      companyId: company,
      corporateUnit
    },
  });
};

export const addCommentApproval = ({
  consecutive,
  approvalId,
  approvalComment,
  isDiscussionComment,
  isApprovedWithComments = false,
  companyId,
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/createApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive,
      addCommentDto: { approvalId, approvalComment },
      isDiscussionComment,
      isApprovedWithComments,
      companyId,
      corporateUnit
    },
  });
};

export const sendApprovalAgain = ({consecutive, approvalId, updateApproval, companyId, corporateUnit}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/updateApprovalwithDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive,
      approvalId,
      updateApproval,
      companyId,
      corporateUnit
    },
  });
};

export const uploadDocumentForApproval = ({
  companyId,
  applicantId,
  consecutive,
  principalDocument,
  attorneyName,
  applicantName,
  comment,
  corporateUnit
}) => {
  const formData = new FormData();
  formData.append("companyId", companyId);
  formData.append("applicantId", applicantId);
  formData.append("consecutive", consecutive);
  formData.append("mainDocuments", principalDocument);
  formData.append("attorneyName", attorneyName);
  formData.append("applicantName", applicantName);
  formData.append("comment", comment);
  formData.append("corporateUnit", corporateUnit);
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/add/documentsForApproval`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
