import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../parts/breadcrum";
import SectionTitle from "../../../../../components/sectionTitle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import ClearIcon from "@mui/icons-material/Clear";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import { useNavigate } from "react-router-dom";
import FilterBar from "../../../../../components/FilterBar/FilterBar";
import FilterTag from "../../../../../parts/filters/FilterTag";
import { useDispatch, useSelector } from "react-redux";
import {
  getFolders,
  fetchAllFolders,
} from "../../../../../parts/folders/foldersSlice";
import {
  fetchFolderTags,
  getFolderTagsList,
  getFolderTagsStatus,
} from "../../../../../parts/folders/folderTagSlice";
import LoadingContent from "../../../../../components/loadingContent";
import {
  updateDeletedFolder,
  moveBusinessFolder,
} from "../../../../../services/business_folder/businessFolderService";
import ModalAssociateVersion from "../../../../../components/Modals/modalAssociateVersion";
import { onSortHTMLElement } from "../../../../../utils/findComponentHTML";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import { FOLDER_STATE } from "../../../../../utils/documentStates";
import { CODES } from "../../../../../utils/codes";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import DetailsBar from "../../../../../components/DetailsBar/DetailsBar";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import { Mixpanel } from "../../../../../utils/mixPanel";

const BusinessFolderService = () => {
  /**
   * Tabla
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Último acceso
        </label>,
      ],
      field: "lastAccess",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Alertas
        </label>,
      ],
      field: "alerts",
    },
  ];
  const rows = [];

  /**
   * UseNavigate
   */
  const navigate = useNavigate();

  /**
   * UseDispatch
   */
  const dispatch = useDispatch();

  /**
   * UseSelector
   */

  //Folders
  const foldersList = useSelector(getFolders);

  //Tags
  const tagsList = useSelector(getFolderTagsList);
  const statusTags = useSelector(getFolderTagsStatus);

  //Plan
  const companyPlan = useSelector(getCompanyPlan);

  /**
   * UseState
   */

  //Tabla
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [extraValuesFilter, setExtraValuesFilter] = useState([]);

  //Elemento y Carpeta para Anchor
  const [selectedElement, setSelectedElement] = useState({});
  const [selectedFolder, setSelectedFolder] = useState({});

  //Loading content
  const [isLoading, setIsLoading] = useState(false);

  //Filter Tags
  const [tagsSelected, setTagsSelected] = useState([]);

  //Filterbar Drawner aún no está hecha
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);

  //Drawners
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [openModalMove, setOpenModalMove] = useState(false);
  const [versionSelected, setVersionSelected] = useState({});
  const [isMoveConfirmed, setIsMoveConfirmed] = useState(false);
  const [openModalDecision, setOpenModalDecision] = useState({
    isOpen: false,
    title: "",
    message: "",
  });
  const [openModalInfo, setOpenModalInfo] = useState({
    reponse: "",
    title: "",
    isOpen: false,
  });

  //Anchors
  //Anchor menu create

  //Anchor menu upload (disabled en esta pantalla)
  const [anchorUpload, setAnchorUpload] = useState(null);
  const openUpload = Boolean(anchorUpload);

  //Anchor mini-menu de contrato (disabled para esta pantalla)
  const [anchorContract, setAnchorContract] = useState(null);
  const openContract = Boolean(anchorContract);

  //Anchor para elemento seleccionado
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);

  //Open tooltip company plan is full
  const [isOpenTooltipPlan, setIsOpenTooltipPlan] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);

  /**
   * Handles
   */

  const handleOpenTooltipPlan = () => {
    if (!isStoragePlanLimit) return;
    setIsOpenTooltipPlan(true);
  };

  //Anchors

  //Menu Crear

  const handleCreateFolder = () => {
    if (isStoragePlanLimit) return;
    navigate("/service/folders/create");
  };
  const handleDelete = async () => {
    try {
      setOpenModalDecision(false);
      setIsLoading(true);
      const requestTempDelete = await updateDeletedFolder({
        newStatus: FOLDER_STATE.deleted._id,
        id: selectedElement._id,
      });
      if (
        requestTempDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestTempDelete.data.success
      ) {
        navigate(`delete/document`);
      } else {
        setOpenModalDecision(false);
        setOpenModalInfo({
          reponse: requestTempDelete,
          title: "Sin permisos suficientes",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Menu Upload (disabled por el momento)
  const handleCloseUpload = (event) => {
    setAnchorUpload(null);
  };

  const handleMenuClickUpload = (selec) => {
    switch (selec) {
      case 1:
        navigate("upload/uploadSigned");
        break;
      case 2:
        navigate("upload/uploadWarranty");
        break;
      case 3:
        navigate("upload/uploadAttachments");
        break;
      case 4:
        navigate("upload/uploadMinutes");
        break;
      case 5:
        navigate("upload/uploadCommunication");
        break;
      case 6:
        navigate("upload/uploadOther");
        break;
      default:
        break;
    }
  };

  //Mini-menu contratos (disabled por el momento)
  const handleClickContract = (event) => {
    setAnchorContract(event.currentTarget);
  };
  const handleCloseContract = (event) => {
    setAnchorContract(null);
  };

  //Menu Selección de elemento
  const handleClickSelected = (event) => {
    setAnchorSelected(event.currentTarget);
  };

  const handleCloseSelected = () => {
    setAnchorSelected(null);
    setSelectedElement({});
  };

  //Menu para folders
  const handlePerMenuFolder = (selec) => {
    switch (selec) {
      case 0:
        navigate(`/service/folders/edit/${selectedElement._id}`);
        break;
      case 1:
        setAnchorSelected(null);
        setSelectedFolder(selectedElement);
        setOpenDetailsDrawner(true);
        break;
      case 2:
        setAnchorSelected(null);
        setOpenModalMove(true);
        break;
      case 3:
        setAnchorSelected(null);
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar carpeta",
          message:
            "¿Esta seguro de que desea eliminar la carpeta seleccionada? ",
        });
        break;
      default:
        break;
    }
  };

  //Tags

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags?.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  //View folder

  const handleViewFolder = (id) => {
    navigate(`viewFolder/${id}`);
  };

  const handleRequestMoveFolder = async () => {
    try {
      if (selectedElement?._id === versionSelected?._id) return;
      await moveBusinessFolder({
        sourceFolderId: selectedElement.businessFolderFatherId,
        currentFolderId: selectedElement._id,
        destinaFolderId: versionSelected._id,
      });
      setOptionsFilterBar((prev) => [...prev]);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Constantes de Menus
   */

  const menuUpload = [
    {
      title: "Contrato",
      selectionClick: 0,
    },
    {
      title: "Contrato firmado",
      selectionClick: 1,
    },
    {
      title: "Garantía",
      selectionClick: 2,
    },
    {
      title: "Anexos de Contrato",
      selectionClick: 3,
    },
    {
      title: "Actas",
      selectionClick: 4,
    },
    {
      title: "Comunicaciones",
      selectionClick: 5,
    },
    {
      title: "Otros documentos",
      selectionClick: 6,
    },
  ];

  const menuFolder = [
    {
      title: "Editar información de la carpeta",
      icon: (
        <CreateOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Ver detalles de la carpeta",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 1,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 2,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 3,
    },
  ];

  /**
   * UseEffect
   */

  //Cargar tags
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const foldersService = await dispatch(fetchAllFolders());

        const valuesExtra = [];

        const processedFolders = foldersService.payload.map((folder) => {
          const created = moment(folder.createdAt).format("DD/MM/YYYY");
          const modified = moment(folder.updatedAt).format("DD/MM/YYYY");
          const lastAccess = moment(folder.lastAccessAt).format("DD/MM/YYYY");
          let labels = "";

          for (const element of folder.labels) {
            labels += element.name + ",";
          }

          const valuesBack = {
            element: folder.name,
            type: "Carpeta",
            createdAt: folder.createdAt,
            updatedAt: folder.updatedAt,
            lastAccess: folder.lastAccessAt,
            alerts: folder.numberOfAlerts,
          };

          valuesExtra.push(valuesBack);

          return {
            element: (
              <Row
                style={{
                  paddingLeft: "2%",
                  paddingTop: "1%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleViewFolder(folder._id);
                }}
              >
                <Col xs={"auto"} className="datatable-CB__tr-name">
                  <FolderIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </Col>
                <Col className="datatable-CB__tr-name">{folder.name}</Col>
              </Row>
            ),
            type: "Carpeta",
            createdAt: created,
            updatedAt: modified,
            lastAccess: !folder.lastAccessAt ? "Sin ultimo acceso" : lastAccess,
            labels: labels,
            alerts: (
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`alerts/${folder._id}`)}
                  className={
                    folder.numberOfAlerts > 0 ? "" : "visibility__hidden"
                  }
                >
                  <p className="internal-alerts">
                    {" "}
                    {`${folder.numberOfAlerts} Alertas`}
                  </p>
                </Col>

                <Col>
                  <IconButton
                    onClick={(e) => {
                      setSelectedElement(folder);
                      handleClickSelected(e);
                    }}
                  >
                    <MoreHorizIcon fontSize="large" />
                  </IconButton>
                </Col>
              </Row>
            ),
          };
        });

        setExtraValuesFilter(valuesExtra);

        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
        setTimeout(setIsLoading(false), 50000);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [optionsFilterBar]);

  useEffect(() => {
    if (versionSelected.name) {
      handleRequestMoveFolder();
    }
  }, [isMoveConfirmed]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  const handleManageTags = () => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    Mixpanel.track("Btn administrar etiquetas", {
      homeButton: "Carpetas",
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
    navigate("tags");
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row className="col-flex">
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Carpetas" />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={() => {
              navigate("delete/document");
            }}
          >
            <DeleteOutlinedIcon fontSize="large" />
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<SettingsIcon fontSize="large" />}
            onClick={handleManageTags}
          >
            Administrar etiquetas
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <Tooltip
            open={isOpenTooltipPlan}
            onOpen={handleOpenTooltipPlan}
            onClose={() => setIsOpenTooltipPlan(false)}
            title={
              <h5>
                La compañía no cuenta con almacenamiento suficiente para esta
                acción, contáctate con el usuario administrador
              </h5>
            }
          >
            <Button
              fullWidth
              className={
                isStoragePlanLimit
                  ? "custom-input__button__disabled"
                  : "custom-input__button__primary-color"
              }
              startIcon={<AddIcon className="heading__primary-border" />}
              onClick={handleCreateFolder}
              variant={!isStoragePlanLimit && "contained"}
            >
              Crear carpeta
            </Button>
          </Tooltip>
        </Col>
        <Col md={"auto"} className="col-flex">
          <Menu
            id="basic-menu"
            anchorEl={anchorUpload}
            open={openUpload}
            onClose={handleCloseUpload}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {menuUpload.map((menuElement, index) => (
              <div key={menuElement?.title}>
                <MenuItem
                  style={{ width: "267px" }}
                  className="body-content__disabled"
                  onClick={(e) => {
                    index === 0
                      ? handleClickContract(e)
                      : handleMenuClickUpload(menuElement.selectionClick);
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "Roboto",
                      fontSize: "1.4rem",
                      fontWeight: "regular",
                      lineHeight: "2rem",
                      color: "#676879",
                    }}
                    primary={menuElement.title}
                  />
                  <ChevronRightOutlinedIcon fontSize="medium" />
                </MenuItem>
                {index + 1 === menuUpload.length ? <></> : <hr />}
              </div>
            ))}
          </Menu>

          {/**CONTRATO */}
          <Menu
            id="basic-menu"
            anchorEl={anchorContract}
            open={openContract}
            onClose={handleCloseContract}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            style={{ margin: "0px 0px 0px 270px" }}
          >
            <MenuItem>Cargar primera versión</MenuItem>
            <hr />
            <MenuItem>Cargar versión</MenuItem>
            <hr />
            <MenuItem>Cargar varias versiones de un contrato</MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={5}>
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <FilterTag
            tagsList={tagsList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
            classStyle="custom-input__button__secondary-color"
          />
        </Col>
      </Row>
      <br />
      <Row style={{ height: "2%" }}>
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row>
        {
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "carpetas"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                sort: value,
                filteredDataTable,
                setFilteredDataTable: ({ columns, rows, extraValues }) => {
                  setFilteredDataTable({ columns, rows });
                  setExtraValuesFilter(extraValues);
                },
                otherListValues: extraValuesFilter,
              });
            }}
            noRecordsFoundLabel={"No se han encontrado resultados"}
          />
        }
      </Row>
      <Menu
        id="basic-menu"
        anchorEl={anchorSelected}
        open={openSelected}
        onClose={handleCloseSelected}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuFolder.map((menuElement, index) => (
          <div key={menuElement._id || menuElement.title}>
            <MenuItem
              onClick={() => {
                handlePerMenuFolder(menuElement.selectionClick);
              }}
              style={{ width: "351px" }}
            >
              <ListItemIcon>{menuElement.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary={menuElement.title}
              />
            </MenuItem>
            {index + 1 === menuFolder.length ? <></> : <hr />}
          </div>
        ))}
      </Menu>
      <FilterBar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setOptionsFilterBar={setOptionsFilterBar}
        folders={true}
      />

      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedFolder}
        pageName={"Folder"}
      />
      <ModalDecision
        title={openModalDecision.title}
        message={openModalDecision.message}
        open={openModalDecision.isOpen}
        onClose={() => {
          setOpenModalDecision({ isOpen: false, title: "", message: "" });
        }}
        agreeText={"Eliminar"}
        disagreeText={"Cancelar"}
        handleAgree={handleDelete}
        handleDisagree={() => {
          setOpenModalDecision({ isOpen: false, title: "", message: "" });
        }}
      />
      <ModalInfo
        title={openModalInfo.title}
        open={openModalInfo.isOpen}
        onClose={() =>
          setOpenModalInfo({
            title: openModalInfo.title,
            open: false,
            reponse: "",
          })
        }
        responseData={openModalInfo.reponse}
        confirmationText="Aceptar"
      />
      {foldersList?.length > 0 && (
        <ModalAssociateVersion
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={(file) => {
            setOpenModalMove(false);
            setVersionSelected(file);
            setIsMoveConfirmed(!isMoveConfirmed);
          }}
          onClose={() => {
            setOpenModalMove(false);
          }}
          open={openModalMove}
          folders={foldersList}
          versionSelected={versionSelected}
          title="Mover a"
          isMovingDocument={true}
          isMoveConfirmed={isMoveConfirmed}
        />
      )}
    </Container>
  );
};

export default BusinessFolderService;
