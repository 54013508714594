import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTagsByCompanyAndResourceType,
  getTagById,
  createTag,
  updateTag,
  deleteTag,
} from "../../../../../../../../../../services/tags/tagsServices.js";
import { CODES } from "../../../../../../../../../../utils/codes.js";

export const tagSliceForms = createSlice({
  name: "customFormTags",
  initialState: {
    tagList: [],
    status: "fetch",
    statusCreate: "",
    statusUpdate: "",
    statusDelete: "",
    error: null,
    errorCreate: null,
    errorUpdate: null,
    errorDelete: null,
  },
  reducers: {
    setStatusFetch(state, action) {
      const status = action.payload.status;
      state.status = status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFormTags.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFormTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.tagList = action.payload.data.responseMessage;
      })
      .addCase(fetchFormTags.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createFormTags.pending, (state, action) => {
        state.statusCreate = "loading";
      })
      .addCase(createFormTags.fulfilled, (state, action) => {
        state.statusCreate = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(createFormTags.rejected, (state, action) => {
        state.statusCreate = "failed";
        state.errorCreate = action.error.message;
      })
      .addCase(updateFormTagById.pending, (state, action) => {
        state.statusUpdate = "loading";
      })
      .addCase(updateFormTagById.fulfilled, (state, action) => {
        state.statusUpdate = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(updateFormTagById.rejected, (state, action) => {
        state.statusUpdate = "failed";
        state.errorUpdate = action.error.message;
      })
      .addCase(deleteFormTagById.pending, (state, action) => {
        state.statusDelete = "loading";
      })
      .addCase(deleteFormTagById.fulfilled, (state, action) => {
        state.statusDelete = "succeeded";
        // Add any fetched posts to the array
        if (
          action.payload.status === CODES.COD_RESPONSE_HTTP_OK &&
          action.payload.data.success
        ) {
          state.tagList = action.payload.dataToAdd;
        }
      })
      .addCase(deleteFormTagById.rejected, (state, action) => {
        state.statusDelete = "failed";
        state.errorDelete = action.error.message;
      });
  },
});

export const getTagsFormList = (state) => state.formCustomTags.tagList;

export const getTagsFormStatus = (state) => state.formCustomTags.status;

export const getStatusCreate = (state) => state.formCustomTags.statusCreate;

export const getStatusUpdate = (state) => state.formCustomTags.statusUpdate;

export const getStatusDelete = (state) => state.formCustomTags.statusDelete;

export const { setStatusFetch } = tagSliceForms.actions;

export const fetchFormTags = createAsyncThunk(
  "tags/fetchFormTags",
  async (_, { rejectWithValue }) => {
    try {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: "CUSTOMFORM",
      };
      const response = await getTagsByCompanyAndResourceType(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fetchTagById = createAsyncThunk(
  "tags/fetchFormTagById",
  async (id) => {
    const response = await getTagById(id);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        data: response.data,
        status: response.status,
      };
    }
  }
);

export const createFormTags = createAsyncThunk(
  "tags/createFormTag",
  async (data) => {
    const companyId = localStorage.getItem("company");

    let obj = {
      name: data.name,
      description: data.description,
      company: companyId,
      resourceType: "CUSTOMFORM",
    };

    const response = await createTag(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      response.data.success
    ) {
      const companyId = localStorage.getItem("company");

      let obj = {
        companyId: companyId,
        resourceType: "CUSTOMFORM",
      };
      const responseFetch = await getTagsByCompanyAndResourceType(obj);
      return {
        dataToAdd: responseFetch.data.responseMessage,
        data: response.data,
        status: response.status,
      };
    }
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const updateFormTagById = createAsyncThunk(
  "tags/updateFormTagById",
  async (data) => {
    let obj = {
      id: data.id,
      updateLabel: data.updateLabel,
      resourceType: "CUSTOMFORM",
    };
    const response = await updateTag(obj);
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: "CUSTOMFORM",
      };
      const responseFetch = await getTagsByCompanyAndResourceType(obj);
      return {
        dataToAdd: responseFetch.data.responseMessage,
        data: response.data,
        status: response.status,
      };
    }
    return {
      data: response.data,
      status: response.status,
    };
  }
);

export const deleteFormTagById = createAsyncThunk(
  "tags/deleteFormTag",
  async (data) => {
    const responseDelete = await deleteTag(data);
    if (
      responseDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
      responseDelete.data.success
    ) {
      const companyId = localStorage.getItem("company");
      let obj = {
        companyId: companyId,
        resourceType: "CUSTOMFORM",
      };
      const responseFetch = await getTagsByCompanyAndResourceType(obj);
      return {
        dataToAdd: responseFetch.data.responseMessage,
        data: responseDelete.data,
        status: responseDelete.status,
      };
    }
    return {
      data: responseDelete.data,
      status: responseDelete.status,
    };
  }
);

export default tagSliceForms.reducer;
