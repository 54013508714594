import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ModalDecision from "../Modals/modalDecision";
import BlankSpaceForm from "./forms/BlankSpaceForm";
import { Close, Save } from "@mui/icons-material";

/**
 * Initial states used in BlankSpaceFormModal
 * @type {BlankSpaceFormModalInitialStatesType}
 * @constant
 */
const InitialStates = Object.freeze({
  modalDecisionProps: {
    open: false,
    type: undefined,
    title: undefined,
    message: undefined,
    children: undefined,
    agreeText: undefined,
    agreeIcon: undefined,
    agreeIconStart: undefined,
    disagreeText: undefined,
    disagreeIcon: undefined,
    disagreeIconStart: undefined,
    handleAgree: () => undefined,
    handleDisagree: () => undefined,
    onClose: () => undefined,
  },
});

/**
 * A modal that allows edit or create a blank space
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @name BlankSpaceFormModal
 * @memberof BlankSpaceNameSpace
 * @param {Object} props
 * @param {{ name: string, description?: string }} [props.selectedBlankSpace] The blank space to be modified, if is passed, the form will be in edit mode else it will be in create mode
 * @param {Array<{ name: string, description?: string }>} props.blankSpaces The list of existing blank spaces, is used for validations like has unique names, default is an empty array
 * @param {boolean} props.open If the modal is open
 * @param {({ name: string, description?: string }) => void} props.onAgree The function to be called when the form is submitted
 * @param {() => void} props.onDisagree The function to be called when the disagree button is clicked
 * @param {() => void} props.onClose The function to be called when the close button is clicked
 * @returns {JSX.Element} The rendered component
 * @throws {Error} If the selectedBlankSpace is not contained in the array of blankSpaces
 * @example
 * // Example usage of create mode
 * import { useState } from "react";
 * import BlankSpaceFormModal from "./BlankSpaceFormModal"; // Change the import path fot your own
 *
 * const BlankSpaceFormCreateMode = () => {
 *  const [isBlankSpaceFormModalOpen, setIsBlankSpaceFormModalOpen] = useState(false);
 *  const [blankSpaces, setBlankSpaces] = useState([
 *    {
 *      name: "blank space name",
 *      description: "blank space description",
 *    },
 *    {
 *      name: "another blank space name 2",
 *      description: "another blank space description 2",
 *    },
 *  ]);
 *
 *  const addBlankSpace = (createdBlankSpace) => {
 *    setBlankSpaces((prevState) => [...prevState, createdBlankSpace]);
 *    console.log(createdBlankSpace);
 *  };
 *  const openBlankSpaceFormModal = () => {
 *    setIsBlankSpaceFormModalOpen(true);
 *  };
 *  const closeBlankSpaceFormModal = () => {
 *    setIsBlankSpaceFormModalOpen(false);
 *  };
 *
 *  return (
 *    <>
 *      <button onClick={openBlankSpaceFormModal}>Add blank space</button>
 *      <BlankSpaceFormModal
 *        blankSpaces={blankSpaces}
 *        open={isBlankSpaceFormModalOpen}
 *        onAgree={addBlankSpace}
 *        onDisagree={closeBlankSpaceFormModal}
 *        onClose={closeBlankSpaceFormModal}
 *      />
 *    </>
 *  );
 * }
 * @example
 * // Example usage of edit mode
 * import { useState } from "react";
 * import BlankSpaceFormModal from "./BlankSpaceFormModal";
 *
 * const BlankSpaceFormEditMode = () => {
 *  const [isBlankSpaceFormModalOpen, setIsBlankSpaceFormModalOpen] = useState(false);
 *  const [blankSpaces, setBlankSpaces] = useState([
 *    {
 *      name: "editable blank space name",
 *      description: "editable blank space description",
 *    },
 *    {
 *      name: "another editable blank space name 2",
 *      description: "another editable blank space description 2",
 *    },
 *  ]);
 *  const selectedBlankSpace = blankSpaces[0];
 *
 *  const editBlankSpace = (editedBlankSpace) => {
 *    setBlankSpaces((prevState) => {
 *      const newState = [...prevState];
 *      const selectedBlankSpaceIndex = newState.findIndex((blankSpace) => blankSpace.name === selectedBlankSpace.name);
 *      newState[selectedBlankSpaceIndex] = editedBlankSpace;
 *      return newState;
 *    });
 *    console.log(editedBlankSpace);
 *  };
 *  const openBlankSpaceFormModal = () => {
 *    setIsBlankSpaceFormModalOpen(true);
 *  };
 *  const closeBlankSpaceFormModal = () => {
 *    setIsBlankSpaceFormModalOpen(false);
 *  };
 *
 *  return (
 *    <>
 *      <button onClick={openBlankSpaceFormModal}>Edit blank space</button>
 *      <BlankSpaceFormModal
 *        selectedBlankSpace={selectedBlankSpace}
 *        blankSpaces={blankSpaces}
 *        open={isBlankSpaceFormModalOpen}
 *        onAgree={editBlankSpace}
 *        onDisagree={closeBlankSpaceFormModal}
 *        onClose={closeBlankSpaceFormModal}
 *      />
 *    </>
 *  );
 * }
 */
const BlankSpaceFormModal = ({
  selectedBlankSpace,
  blankSpaces,
  open,
  onAgree,
  onDisagree,
  onClose,
}) => {
  const [modalDecisionProps, setModalDecisionProps] = useState(
    InitialStates.modalDecisionProps
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => {
    setModalDecisionProps({
      ...modalDecisionProps,
      open: true,
      title: selectedBlankSpace
        ? "Editar espacio en blanco"
        : "Añadir espacio en blanco",
      agreeText: "Guardar",
      agreeIconStart: <Save fontSize="large" />,
      disagreeText: "Cancelar",
      disagreeIconStart: <Close fontSize="large" />,
      handleAgree: () => setIsSubmitting(true),
      handleDisagree: onDisagree,
      onClose: onClose,
    });
  };

  const closeModal = () => {
    setModalDecisionProps(InitialStates.modalDecisionInfo);
  };

  useEffect(() => {
    if (open) {
      openModal();
    } else {
      closeModal();
    }
  }, [open]);

  /**
   * Reset the isSubmitting state for the next form submission
   */
  useEffect(() => {
    if (isSubmitting) {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  return (
    <ModalDecision {...modalDecisionProps}>
      <BlankSpaceForm
        selectedBlankSpace={selectedBlankSpace}
        blankSpaces={blankSpaces}
        onSubmit={onAgree}
        isBeingSubmittedExternally={isSubmitting}
      />
    </ModalDecision>
  );
};

BlankSpaceFormModal.propTypes = {
  selectedBlankSpace: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  blankSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BlankSpaceFormModal;
