import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingContent from "../../components/loadingContent";
import SectionTitle from "../../components/sectionTitle";
import Breadcrum from "../breadcrum";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalInfo from "../../components/Modals/modalInfo";
import ModalDecision from "../../components/Modals/modalDecision";
import { createTags } from "./tagSlice";
import { CODES } from "../../utils/codes";

const CreateTag = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [searchParams] = useSearchParams();
  const resourceType = searchParams.get("resource");

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test(
        "maxNumbers",
        "*Máximo 100 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(100);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    description: yup
      .string()
      .test(
        "maxNumbers",
        "*Máximo 200 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(200);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleCreateTag = async (data) => {
    try {
      setIsLoading(true);
      let obj = {
        name: data.name,
        description: data.description,
        resourceType: resourceType || "CLAUSES",
      };

      const createTagService = await dispatch(createTags(obj)).unwrap();
      if (createTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(createTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(createTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Crear etiqueta"} />
      </Row>
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleCreateTag)}>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Nombre de la etiqueta
                  <span style={{ color: "red" }}>{"* "}</span>
                </Form.Label>
                <Form.Control
                  {...register("name")}
                  placeholder={"Nombre de la etiqueta"}
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  maxLength={101}
                  bsPrefix={
                    errors?.name
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors?.name?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Descripción de la etiqueta
                </Form.Label>

                <Form.Control
                  {...register("description")}
                  placeholder="Descripción de la etiqueta"
                  as="textarea"
                  style={{
                    border: "0.1rem solid #c5c7d0",
                  }}
                  maxLength={201}
                  bsPrefix={
                    errors.description
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors.description?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Creación exitosa"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Seguro que deseas cancelar la creación de la etiqueta? todos los cambios se perderán"
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
    </Container>
  );
};

export default CreateTag;
