import React, { useState } from "react";
import { Container, Form, InputGroup, Row, Col } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import FilterByCarvajal from "./FilterByCarvajal";
import { statusList } from "../../../../../../utils/statusExternalRequest";

const isElementMatchWord = ({ element, word }) => {
  if (!element) return false;
  return element?.toLocaleString().toLowerCase().includes(word.toLowerCase());
};

const SearchBarCarvajal = ({
  DataTable,
  setFilteredDataTable,
  setFilterStatus,
  setOptionsFilterBar,
  filterMyId,
  setFilterMyId,
  setSearchParams,
}) => {
  const [filters, showFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    showFilters(false);
  };
  const handleFilterStatus = (status) => {
    setFilterStatus(status);
  };

  function filter(filterInfo) {
    let filtered = [];
    if (filterInfo === "") {
      filtered = DataTable.rows;
    } else {
      filtered = DataTable.rows.filter((info) => {
        const values = Object.values(info);
        if (
          values.some((value) =>
            isElementMatchWord({
              element: value,
              word: filterInfo.toLowerCase(),
            })
          )
        ) {
          return info;
        }
      });
    }
    setFilteredDataTable({
      columns: DataTable.columns,
      rows: filtered,
    });
  }
  return (
    <>
      <Col xs={"auto"} md={6}>
        <InputGroup bsPrefix="input-group-container">
          <Form.Control
            bsPrefix="custom-input"
            placeholder="Buscar"
            onChange={(event) => filter(event.target.value)}
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Col>
      <Col
        xs={"auto"}
        md={4}
        className="d-flex justify-content-end align-items-center"
      >
        <Form.Group className="body" controlId="Terminos">
          <Form.Check
            className="label__description__default"
            value={filterMyId}
            type="checkbox"
            label="Solo solicitudes asignadas a mí"
            onChange={(e) => {
              setFilterMyId(e.target.checked);
            }}
          />
        </Form.Group>
      </Col>
      <Col xs={"auto"}>
        <Button
          variant="contained"
          style={{ height: "90%" }}
          size="small"
          startIcon={<FilterAltIcon width="5%" />}
          className="custom-input__button__secondary-color"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          Filtrar
        </Button>
      </Col>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={filters}
        onClose={handleClose}
      >
        {statusList.map((name) => (
          <MenuItem key={name.Status} value={name.text}>
            <ListItemText
              primary={name.text}
              onClick={() => {
                handleFilterStatus(name.key);
              }}
            />
          </MenuItem>
        ))}
      </Menu>
      <FilterByCarvajal
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setOptionsFilterBar={setOptionsFilterBar}
        setSearchParams={setSearchParams}
      />
    </>
  );
};
export default SearchBarCarvajal;
