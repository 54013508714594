import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import SectionCardButtonWithNavButton from "../../../../../../components/Cards/SectionCardButtonWithNavButton";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

export default function ManageDocumentsModule() {
  return (
    <Container
      fluid
      className="custom-container-scroll d-flex flex-column gap-4"
    >
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Personalización"} />
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"}>
          <p className="caption">
            Personaliza tus membretes, documentos y formularios.
          </p>
        </Col>
      </Row>
      <Row className="g-4">
        {/* <Col xs={12} sm={6} md={6} lg={4}>
                <SectionCardButtonWithNavButton
                    icon={<BusinessCenterIcon className="cards__icon-svg" />}
                    title="Aprobaciones"
                    description="Selecciona la ubicación de la marca de aprobaciones."
                    navigationButton={
                        <ArrowForwardIosOutlinedIcon className="cards__nav-button__icon" />
                    }
                    navigationPath="admin/unitsCorporatives/documents/manage-approvals"
                />
            </Col> */}
        <Col xs={12} md={6} lg={4}>
          <SectionCardButtonWithNavButton
            icon={<BusinessCenterIcon className="cards__icon-svg" />}
            title="Membretes y logos"
            description="Personaliza tus membretes y logos"
            navigationButton={
              <ArrowForwardIosOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="admin/unitsCorporatives/personalize/manage-headings"
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <SectionCardButtonWithNavButton
            icon={<ArticleOutlinedIcon className="cards__icon-svg" />}
            title="Formularios"
            description="Personaliza tus formularios"
            navigationButton={
              <ArrowForwardIosOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="admin/unitsCorporatives/personalize/manage-forms"
          />
        </Col>
      </Row>
    </Container>
  );
}
