import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import CardFilter from "../../components/Cards/CardFilter";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import SpokeIcon from "@mui/icons-material/Spoke";
import ReactHtmlParser from "react-html-parser";
import { Mixpanel } from "../../utils/mixPanel";

const FilterCategory = ({
  categoriesList,
  list,
  setFilteredInfo,
  handleCategoriesSelected,
}) => {
  const [show, setShow] = useState(false);
  const [countChecked, setCountChecked] = useState(0);

  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleShow = () => {
    setFilteredInfo(list);
    setCategories(categoriesList);
    setFilteredData(categoriesList);
    setShow(true);
    Mixpanel.track("Btn ver por categoría  ", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const handleChecked = (e) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setCategorySelected((oldArray) => [...oldArray, item.name]);
    } else {
      setCountChecked(countChecked - 1);
      setCategorySelected(categorySelected.filter((tag) => tag !== item.name));
    }
  };

  const handleClose = () => {
    setCategorySelected([]);
    setCountChecked(0);
    setShow(false);
  };

  const handleFilterCategories = (categoriesSelected) => {
    if (categoriesSelected.length > 0) {
      const filterCategories = list.filter((item) => {
        return item.categories.some((category) =>
          categoriesSelected.includes(category.name)
        );
      });
      setFilteredInfo(filterCategories);
      handleCategoriesSelected(categoriesSelected);
    } else {
      setFilteredInfo(list);
    }

    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<SpokeIcon />}
        className="custom-input__button__primary-color"
        onClick={handleShow}
      >
        <p className="button">Ver por Categoría</p>
      </Button>
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">
              Ver por categoría
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Selecciona las categorías de cláusulas que quieres ver para
                  enfocar tu revisión del documento contractual.
                </p>
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} seleccionadas
                </p>
              </Col>
              <Col>
                <CustomSearchFilter
                  list={categories}
                  setFilteredData={setFilteredData}
                  placeholder="Buscar categoría"
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row sm={3}>
              {filteredData.map((category, i) => {
                const nameCategory = category?.name?.toLowerCase();
                const nameCategoryCapitalize = nameCategory.charAt(0).toUpperCase() + nameCategory.slice(1);
                return (
                  <Col
                    className="filter-tag__check"
                    style={{ marginBottom: "1.5%" }}
                    key={category?._id}
                    xs={12}
                    md={6}
                    xl={4}
                  >
                    <input
                      defaultChecked={categorySelected.find(
                        (item) => item === category.name
                      )}
                      id={category._id}
                      name={category.name}
                      type="checkbox"
                      onClick={handleChecked}
                    />
                    <label htmlFor={category._id}>
                      <CardFilter
                        icon={
                          <SpokeIcon
                            fontSize="large"
                            className="filter-tag__icon"
                          />
                        }
                        minHeight={"9em"}
                        title={nameCategoryCapitalize}
                        description={ReactHtmlParser(category.description)}
                      />
                    </label>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => handleFilterCategories(categorySelected)}
              >
                <p className="button">Filtrar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterCategory;
