import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { faFile, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from "@mui/icons-material/Clear";
import { CardDocument } from "../../../../../components/Cards/cardDocumentUpload";
import LoadingContent from "../../../../../components/loadingContent";
import ModalAssociateFolder from "../../../../../components/Modals/ModalAssociateFolder";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../components/Modals/ModalUploadDocs";
import ModalWarningForm from "../../../../../components/Modals/ModalWarningForm";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import { createNewVersion } from "../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../utils/codes";

const ChoseDocumentToSign = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpLoadDocument, setOpenModalUpLoadDocument] = useState(false);
  const [openModalFromDoc, setOpenModalFromDoc] = useState(false);
  const [openModalSelectFolder, setOpenModalSelectFolder] = useState(false);
  const [openModalSelectDocument, setOpenModalSelectDocument] = useState(false);
  const [
    openModalWarningSelectedDocument,
    setOpenModalWarningSelectedDocument,
  ] = useState(false);
  const [openModalWarningSaveDocument, setOpenModalWarningSaveDocument] =
    useState(false);
  const [folderSelected, setFolderSelected] = useState({});
  const [documentData, setDocumentData] = useState({});
  const [documentType, setDocumentType] = useState("");
  const [responseData, setResponseData] = useState({});
  const [isOpenModalResponseSaveInfo, setIsOpenModalResponseSaveInfo] =
    useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const handleSendDocument = async (data) => {
    try {
      setIsLoading(true);
      let obj;
      const companyId = localStorage.getItem("company");
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      const isSendEmail = data.sendEmail;
      const isEmptyFolderSelected = Object.entries(folderSelected).length === 0;
      const isEmptyDocSelected = Object.entries(documentData).length === 0;
      if (isEmptyDocSelected && !documentData.name) {
        setOpenModalWarningSelectedDocument(true);
        return;
      }
      if (isEmptyFolderSelected && !isSendEmail) {
        setOpenModalWarningSaveDocument(true);
        return;
      }
      let uploadDocument;
      if (documentType === "local") {
        const corporateUnit = JSON.parse(localStorage.getItem("corporateUnit"));
        const createVersionDocument = await createNewVersion({
          name: documentData.name,
          fileType: "624af556c455d0c496c43ed9",
          documentContent: {
            name: documentData.name,
          },
          corporateUnit: corporateUnit._id,
          file: documentData,
        });
        if (
          createVersionDocument.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          createVersionDocument.data.success
        ) {
          uploadDocument = createVersionDocument.data.responseMessage;
        } else {
          setResponseData(createVersionDocument);
          setIsOpenModalResponseSaveInfo(true);
          return;
        }
      } else if (documentType === "legal") {
        uploadDocument = documentData;
      }

      obj = {
        description: data.description,
        isEmailWithSignedDocumentSend: isSendEmail,
        folderSelected: folderSelected?._id || null,
        docTypeToSign: documentType,
        originalDocument: uploadDocument,
        documentType: "signedDocuments",
        companyId,
        corporateUnitId,
        signatureType: "lateralPanel",
        documentId: uploadDocument?._id,
      };
      sessionStorage.setItem('signatureInfo', JSON.stringify(obj));
      navigate(`/service/addSignatories`, { state: obj });
      setIsOpenModalResponseSaveInfo(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUploadLocal = () => {
    setOpenModalFromDoc(false);
    setOpenModalUpLoadDocument(true);
    setDocumentType("local");
  };
  const handleUploadLegal = () => {
    setOpenModalFromDoc(false);
    setOpenModalSelectDocument(true);
    setDocumentType("legal");
  };
  const handleEditDocument = () => {
    setOpenModalFromDoc(true);
  };
  const handleDeleteDocumentSelected = () => {
    setFolderSelected({});
  };

  return (
    <>
      <Container fluid>
        {isLoading && <LoadingContent />}
        <Row className="col-start">
          <Breadcrum />
        </Row>
        <Row>
          <Col md={2} className="col-start">
            <SectionTitle title="Firmar documento" />
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row>
          <Col className="home-init-cards-row__title-row">
            Sigue los siguientes pasos para firmar.
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col className="caption label__description__default">
            1. Escribe el asunto de la firma
          </Col>
        </Row>
        <br />
        <Form onSubmit={handleSubmit(handleSendDocument)}>
          <Form.Group>
            <Row>
              <Col xs={5}>
                <Form.Control
                  {...register("description", {
                    required: {
                      value: true,
                      message: "*Este campo es obligatorio",
                    },
                  })}
                  type="text"
                  bsPrefix={
                    errors?.description
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  placeholder={"Solicitud de firma para..."}
                />
                <span className="caption custom-input__error">
                  {errors?.description?.message}
                </span>
              </Col>
            </Row>
          </Form.Group>
          <br />
          <br />
          <Row>
            <Col className="caption label__description__default">
              2. Selecciona y carga el documento que deseas firmar.
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={"auto"}>
              {documentData?.name ? (
                <>
                  <CardDocument
                    name={documentData.name}
                    edit={handleEditDocument}
                    /* download={handleDownloadDocument} */
                  />
                </>
              ) : (
                <button
                  className="btn_white_background"
                  style={{ padding: "12px" }}
                  type="button"
                  onClick={() => setOpenModalFromDoc(true)}
                >
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Cargar documento
                </button>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="caption label__description__default">
              3. Selecciona donde quieres guardar tu documento firmado.
            </Col>
          </Row>
          <br />
          <Row
            style={{
              alignItems: "center",
            }}
          >
            {watch("sendEmail") || (
              <Col xs={"auto"}>
                <button
                  className="btn_white_background"
                  type="button"
                  style={{ padding: "12px", marginBottom: "10px" }}
                  onClick={() => setOpenModalSelectFolder(true)}
                >
                  <FontAwesomeIcon
                    icon={faFolder}
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Seleccionar carpeta en Legal AI
                </button>
              </Col>
            )}
            {!folderSelected.name && !watch("sendEmail") && (
              <Col xs={"auto"}>
                <span className="label__description">ó</span>
              </Col>
            )}
            {folderSelected.name ? (
              <Col xs={"auto"}>
                <span className="caption custom-badges__disabled label__description">
                  Carpetas / {folderSelected.name}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={handleDeleteDocumentSelected}
                  />
                </span>
              </Col>
            ) : (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  {...register("sendEmail")}
                  type="checkbox"
                  style={{ marginRight: "5px" }}
                />
                <span className="label__description">
                  Enviar solo al correo electrónico
                </span>
              </Col>
            )}
          </Row>
          <br />
          <br />
          <button
            type="submit"
            className="btn_blue_background"
            style={{ padding: "10px" }}
          >
            Seguir con el firmado
          </button>
        </Form>
      </Container>
      <ModalDecision
        title={"Cargar documento a firmar"}
        message={
          "¿Quieres cargar el documento a firmar desde el PC, o desde la plataforma de Legal AI?"
        }
        open={openModalFromDoc}
        onClose={() => setOpenModalFromDoc(false)}
        agreeText="Desde Legal AI"
        disagreeText="Desde el PC"
        handleAgree={handleUploadLegal}
        handleDisagree={handleUploadLocal}
      />
      <ModalUploadDocs
        message={"Subir documento."}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => setOpenModalUpLoadDocument(false)}
        onClose={() => setOpenModalUpLoadDocument(false)}
        open={openModalUpLoadDocument}
        documentData={documentData}
        setDocumentData={setDocumentData}
      />
      <ModalAssociateFolder
        type="folder"
        open={openModalSelectFolder}
        setDataSelected={setFolderSelected}
        close={() => setOpenModalSelectFolder(false)}
      />
      <ModalAssociateFolder
        type="document"
        open={openModalSelectDocument}
        setDataSelected={setDocumentData}
        close={() => setOpenModalSelectDocument(false)}
      />
      <ModalWarningForm
        title={"Advertencia"}
        description={
          "No se ha subido un documento para firmar, esto es obligatorio."
        }
        open={openModalWarningSelectedDocument}
        onClose={() => setOpenModalWarningSelectedDocument(false)}
      />
      <ModalWarningForm
        title={"Advertencia"}
        description={
          "No se ha definido donde se guardará el documento firmado."
        }
        open={openModalWarningSaveDocument}
        onClose={() => setOpenModalWarningSaveDocument(false)}
      />
      <ModalInfo
        open={isOpenModalResponseSaveInfo}
        confirmationText="Aceptar"
        onClose={() => setIsOpenModalResponseSaveInfo(false)}
        responseData={responseData}
        title={getTitle(responseData?.data?.responseCode)}
        buttonClose={true}
        handleCloseDefault={() => setIsOpenModalResponseSaveInfo(false)}
      />
    </>
  );
};

const getTitle = (code) => {
  switch (code) {
    case CODES.COD_RESPONSE_SUCCESS:
      return "Firmado correcto";
    case CODES.COD_RESPONSE_HTTP_UNAUTHORIZED:
      return "Permisos de firma";
    case CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED:
      return "Límite de firmas";
    default:
      return "Error";
  }
};

export default ChoseDocumentToSign;
