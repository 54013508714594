export const IndentifyBlankSpaces = (clauses) => {
  const regexBlanks = [/\b([_x]{2,})/g, /\[(.*?)\]/gs];

  if (clauses.length) {
    return clauses.map((clause) => {
      let updateClause = clause;
      const { results, text } = matchText({
        regexps: regexBlanks,
        text: clause.text,
        className: "reports__blankspaces",
      });
      if (results.length) {
        updateClause = { ...updateClause, text, blankspacesReports: results };
      }
      return { ...updateClause, hasBlankspaces: !!results.length };
    });
  }

  return [];
};

export const IndentifyMilestones = (clauses) => {
  const regexMilestones = [
    /\b(hora)\b/g,
    /\b(horas)\b/g,
    /\b(d[ií]as)\b/g,
    /\b(d[ií]a)\b/g,
    /\b(semanas)\b/g,
    /\b(semana)\b/g,
    /\b(meses)\b/g,
    /\b(mes)\b/g,
    /\b(años)\b/g,
    /\b(año)\b/g,
    /\b(tiempo)\b/g,
    /\b(tiempos)\b/g,
    /\b(enero)\b/g,
    /\b(ene)\b/g,
    /\b(febrero)\b/g,
    /\b(feb)\b/g,
    /\b(marzo)\b/g,
    /\b(mar)\b/g,
    /\b(abril)\b/g,
    /\b(abr)\b/g,
    /\b(mayo)\b/g,
    /\b(may)\b/g,
    /\b(junio)\b/g,
    /\b(jun)\b/g,
    /\b(julio)\b/g,
    /\b(jul)\b/g,
    /\b(agosto)\b/g,
    /\b(ago)\b/g,
    /\b(septiembre)\b/g,
    /\b(sep)\b/g,
    /\b(octubre)\b/g,
    /\b(oct)\b/g,
    /\b(noviembre)\b/g,
    /\b(nov)\b/g,
    /\b(diciembre)\b/g,
    /\b(dic)\b/g,
    /\b(lunes)\b/g,
    /\b(martes)\b/g,
    /\b(mi[eé]rcoles)\b/g,
    /\b(jueves)\b/g,
    /\b(viernes)\b/g,
    /\b(s[áa]bado)\b/g,
    /\b(s[áa]bados)\b/g,
    /\b(domingo)\b/g,
    /\b(domingos)\b/g,
    /\b(fecha)\b/g,
    /\b(calendario)\b/g,
    /\b(aniversario)\b/g,
    /\b(festividad)\b/g,
    /\b([ée]poca)\b/g,
    /\b(temporada)\b/g,
    /\b(per[íi]odo)\b/g,
    /\b(ciclo)\b/g,
    /\b(instante)\b/g,
    /\b(momento)\b/g,
    /\b(plazo)\b/g,
    /\b(vencimiento)\b/g,
    /\b(jubileo)\b/g,
    /\b(diari\w*)\b/g,
    /\b(semanal\w*)\b/g,
    /\b(mensual\w*)\b/g,
    /\b(anual\w*)\b/g,
    /\b(bimestr\w*)\b/g,
    /\b(trimestr\w*)\b/g,
    /\b(cuatrimestr\w*)\b/g,
    /\b(semestr\w*)\b/g,
    /\b(quinquena\w*)\b/g,
    /\b(quincena\w*)\b/g,
    /\b(mañana)\b/g,
    /\b(amanecer)\b/g,
    /\b(madrugada)\b/g,
    /\b(tarde)\b/g,
    /\b(noche)\b/g,
    /\b(anochecer)\b/g,
    /\b(mediod[íi]a)\b/g,
    /\b(medianoche)\b/g,
    /\b\d{1,2}:\d{2}(?::\d{2})?\b/g,
    /\b(a\.?m\.?)\b/g,
    /\b(p\.?m\.?)\b/g,
    /\b\d{4}\b/g,
  ];

  if (clauses.length) {
    return clauses.map((clause) => {
      const { results } = matchText({
        regexps: regexMilestones,
        text: clause.text,
        className: "reports__milestones",
      });
      return { ...clause, hasMilestone: !!results.length };
    });
  }

  return [];
};

export const IndentifyValues = (clauses) => {
  const regexValues = [
    /\$\s*(\d+[.,]?\d+)/gs,
    /(\d+[.,]?\d+)\s*%/g,
    /\b(mil)\b/g,
    /\b(miles)\b/g,
    /\b(millon)\b/g,
    /\b(millones)\b/g,
    /\b(billon)\b/g,
    /\b(billones)\b/g,
    /\b(peso)\b/g,
    /\b(pesos)\b/g,
    /\b(dolar)\b/g,
    /\b(dolares)\b/g,
    /\b(euro)\b/g,
    /\b(euros)\b/g,
    /\b(cop)\b/g,
    /\b(usd)\b/g,
    /\b(us\$)\b/g,
    /\b(col\$)\b/g,
    /\b(eur)\b/g,
    /\b(€)\b/g,
    /\b(por ciento)\b/g,
    /\b(porcentaje)\b/g,
    /\b(porcentajes)\b/g,
    /\b(tasa)\b/g,
    /\b(tasas)\b/g,
    /\b(fracci[oó]n)\b/g,
    /\b(fracci[oó]nes)\b/g,
    /\b(proporci[óo]n)\b/g,
    /\b(proporci[óo]nes)\b/g,
  ];

  if (clauses.length) {
    return clauses.map((clause) => {
      const { results } = matchText({
        regexps: regexValues,
        text: clause.text,
        className: "reports__values",
      });
      return { ...clause, hasValues: !!results.length };
    });
  }

  return [];
};

const matchText = ({ regexps, text, className }) => {
  let newText = text;
  const results = [];
  regexps.forEach((regex) => {
    const match = newText?.match(regex);
    if (match) {
      newText = newText?.replace(
        regex,
        `<mark class=${className}>${match?.[0]}</mark>`
      );
      results.push(match?.[0]);
    }
  });
  return {
    text: newText,
    results,
  };
};

export const escapeRegex = (string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
};

function compareTextLength(a, b) {
  if (a?.text?.length < b?.text?.length) {
    return 1;
  } else if (a?.text?.length > b?.text?.length) {
    return -1;
  } else {
    return 0;
  }
}

const GetReferenceNormative = (clause) => {
  const references = clause?.references || [];
  let refs = [...references];
  if (refs.length > 0) {
    const newText = clause.text;
    let refClause = { ...clause, text: newText };
    refs.sort((a, b) => compareTextLength(a, b));
    for (const item of refs) {
      let link = `<a target='_blank' href='https://www.google.com/search?q=${item.text}'>${item.text}</a>`;
      let regEx = new RegExp("(" + item.text + ")(?!([^<]+)?>)", "gi");
      refClause = {
        ...refClause,
        text: refClause?.text?.replace(regEx, link),
      };
    }
    return refClause;
  }
  return clause;
};

const markValueInClause = (clause) => {
  const values = clause?.values || [];
  let foundValues = [...values].filter((item) => !!item?.text);

  if (foundValues.length > 0) {
    let newText = clause?.text;
    foundValues.sort((a, b) => compareTextLength(a, b));
    for (const item of foundValues) {
      let value = `<mark class="reports__values">${item.text}</mark>`;

      const cleanValue = escapeRegex(item.text);
      let regEx = new RegExp(`${cleanValue}`, "gi");

      newText = newText?.replace(regEx, value);
    }
    return {
      ...clause,
      text: newText,
    };
  }
  return clause;
};

const markMilestoneInClause = (clause) => {
  const milestones = clause?.milestones || [];
  let foundMilestones = [...milestones].filter((item) => !!item?.text);
  if (foundMilestones.length > 0) {
    let newText = clause?.text;
    foundMilestones.sort((a, b) => compareTextLength(a, b));
    for (const item of foundMilestones) {
      const milestone = `<mark class="reports__milestones">${item.text}</mark>`;

      const cleanMilestone = escapeRegex(item.text);
      
      const regEx = new RegExp(`${cleanMilestone}`, "gi");
      
      newText = newText?.replace(regEx, milestone);
    }
    return {
      ...clause,
      text: newText,
    };
  }
  return clause;
};

export const getClauseMarkedText = (clause) => {
  let newClause = { ...clause };
  let clauseReferences = GetReferenceNormative(newClause);
  let clauseValue = markValueInClause(clauseReferences);
  newClause = markMilestoneInClause(clauseValue);
  return newClause;
};
