import { QuestionFormTypes } from "../constants/questionFormTypes";
import { DocumentFileExtensions } from "../constants/documentFileExtensions";

/**
 * Represents the form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class UpdatedCustomRequestTypeDto {
  originalFormId;
  formId;
  group;
  type;
  name;
  description;
  companyId;
  corporateUnitsId;
  updateActualQuestions;
  listDocuments;
  allowUpdateOtherDocumentsToTrace;
  labels;
  modifiedBy;
  timeZone;
  startModificationDate;
  fields;

  /**
   * Initializes a new instance of the UpdatedCustomRequestTypeDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} props.formId - The id of the form.
   * @param {string} [props.group] - The type of the form.
   * @param {string} [props.type] - The subtype of the form.
   * @param {string} [props.name] - The name of the form.
   * @param {string} [props.description] - The description of the form.
   * @param {string} [props.companyId] - The id of the company.
   * @param {string[]} [props.corporateUnitsId] - The id of the corporate units.
   * @param {SectionsFormDto[]} [props.updateActualQuestions] - The sections of the form.
   * @param {DocumentsListDto[]} [props.listDocuments] - The list of documents of the form.
   * @param {boolean} [props.allowUpdateOtherDocumentsToTrace] - Whether the form allows update other documents to trace or not.
   * @param {any[]} [props.labels] - The labels of the form.
   * @param {string} [props.modifiedBy] - The id of the user who modified the form.
   * @param {number} [props.timeZone] - The time zone of the form.
   * @param {Date} [props.startModificationDate] - The start modification date of the form.
   * @param {Date} [props.fields] - The fields of the form.
   * @returns {UpdatedCustomRequestTypeDto} The new instance of the UpdatedCustomRequestTypeDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const updatedCustomRequestTypeDto = new UpdatedCustomRequestTypeDto({ formId: 'formId', name: 'name', description: 'description', companyId: 'companyId', corporateUnitsId: ['corporateUnitsId'], updateActualQuestions: [sectionsFormDto] });
   * // Some props
   * const updatedCustomRequestTypeDto = new UpdatedCustomRequestTypeDto({ formId: 'formId', name: 'name', updateActualQuestions: [sectionsFormDto] });
   */
  constructor({
    originalFormId,
    formId,
    group,
    type,
    name,
    description,
    companyId,
    corporateUnitsId,
    updateActualQuestions,
    listDocuments,
    allowUpdateOtherDocumentsToTrace,
    labels,
    modifiedBy,
    timeZone,
    startModificationDate,
    fields,
  }) {
    UpdatedCustomRequestTypeDto.validate({
      originalFormId,
      formId,
      group,
      type,
      name,
      description,
      companyId,
      corporateUnitsId,
      updateActualQuestions,
      listDocuments,
      allowUpdateOtherDocumentsToTrace,
      labels,
      modifiedBy,
      timeZone,
      startModificationDate,
      fields,
    });
    this.originalFormId = originalFormId;
    this.formId = formId;
    this.group = group;
    this.type = type;
    this.name = name;
    this.description = description;
    this.companyId = companyId;
    this.corporateUnitsId = corporateUnitsId;
    this.updateActualQuestions = updateActualQuestions;
    this.listDocuments = listDocuments;
    this.allowUpdateOtherDocumentsToTrace = allowUpdateOtherDocumentsToTrace;
    this.labels = labels;
    this.modifiedBy = modifiedBy;
    this.timeZone = timeZone;
    this.startModificationDate = startModificationDate;
    this.fields = fields;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the UpdatedCustomRequestTypeDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {UpdatedCustomRequestTypeDto} The new instance of the UpdatedCustomRequestTypeDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({
    originalFormId,
    formId,
    group,
    type,
    name,
    description,
    companyId,
    corporateUnitsId,
    updateActualQuestions,
    listDocuments,
    allowUpdateOtherDocumentsToTrace,
    labels,
    modifiedBy,
    timeZone,
    startModificationDate,
    fields,
  }) {
    /**
     * The sections of the form.
     * @constant
     * @type {SectionsFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const sectionsFormDtoArray = updateActualQuestions?.map((section) =>
      SectionsFormDto.fromObject(section)
    );
    /**
     * The list of documents of the form.
     * @constant
     * @type {DocumentsListDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const documentsListDtoArray = listDocuments?.map((document) =>
      DocumentsListDto.fromObject(document)
    );
    /**
     * The fields of the form.
     * 
     * @constant
     * @type {QuestionFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const fieldsArray = fields?.map((field) => QuestionFormDto.fromObject(field));

    return new UpdatedCustomRequestTypeDto({
      originalFormId,
      formId,
      group,
      type,
      name,
      description,
      companyId,
      corporateUnitsId,
      updateActualQuestions: sectionsFormDtoArray,
      listDocuments: documentsListDtoArray,
      allowUpdateOtherDocumentsToTrace,
      labels,
      modifiedBy,
      timeZone,
      startModificationDate,
      fields: fieldsArray,
    });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({
    originalFormId,
    formId,
    group,
    type,
    name,
    description,
    companyId,
    corporateUnitsId,
    updateActualQuestions,
    listDocuments,
    allowUpdateOtherDocumentsToTrace,
    labels,
    modifiedBy,
    timeZone,
    startModificationDate,
    fields,
  }) {
    if (originalFormId && typeof originalFormId !== "string")
      throw new Error("The formId must be a string");
    if (!formId || typeof formId !== "string")
      throw new Error("The formId is required and must be a string");
    if (group && typeof group !== "string")
      throw new Error("The group must be a string");
    if (type && typeof type !== "string")
      throw new Error("The type must be a string");
    if (name && typeof name !== "string")
      throw new Error("The name must be a string");
    if (description && typeof description !== "string")
      throw new Error("The description must be a string");
    if (companyId && typeof companyId !== "string")
      throw new Error("The companyId must be a string");
    if (corporateUnitsId && !Array.isArray(corporateUnitsId))
      throw new Error("The corporateUnitsId must be an array");
    if (updateActualQuestions && !Array.isArray(updateActualQuestions))
      throw new Error("The updateActualQuestions must be an array");
    if (listDocuments && !Array.isArray(listDocuments))
      throw new Error("The listDocuments must be an array");
    if (
      allowUpdateOtherDocumentsToTrace &&
      typeof allowUpdateOtherDocumentsToTrace !== "boolean"
    )
      throw new Error(
        "The allowUpdateOtherDocumentsToTrace is required and must be a boolean"
      );
    if (labels && !Array.isArray(labels))
      throw new Error("The labels must be an array");
    if (modifiedBy && typeof modifiedBy !== "string")
      throw new Error("The modifiedBy must be a string");
    if (timeZone && typeof timeZone !== "number")
      throw new Error("The timeZone must be a number");
    if (startModificationDate && !(startModificationDate instanceof Date))
      throw new Error("The startModificationDate must be a Date");
    if (fields && !Array.isArray(fields))
      throw new Error("The fields must be an array of QuestionFormDto");
  }
}

/**
 * Represents the list of documents of a form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class DocumentsListDto {
  /**
   * @readonly
   */
  originalFilename;
  /**
   * @readonly
   */
  type;
  /**
   * @readonly
   */
  isRequired;
  /**
   * @readonly
   */
  fileExtension;

  /**
   * Initializes a new instance of the DocumentsListDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} props.originalFilename - The original filename of the document.
   * @param {string} props.type - The type of the document.
   * @param {boolean} [props.isRequired] - Whether the document is required or not.
   * @param {string[]} [props.fileExtension] - The file extensions accepted for the form when uploading documents. Accept strings from DocumentFileExtension object.
   * @returns {DocumentsListDto} The new instance of the DocumentsListDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const documentsList = new DocumentsListDto({ originalFilename: 'originalFilename', type: 'requiredDocument', isRequired: true, fileExtension: ['pdf'] });
   * // Some props
   * const documentsList = new DocumentsListDto({ originalFilename: 'originalFilename', fileExtension: ['pdf'] });
   */
  constructor({ originalFilename, type, isRequired, fileExtension }) {
    DocumentsListDto.validate({
      originalFilename,
      type,
      isRequired,
      fileExtension,
    });
    this.originalFilename = originalFilename;
    this.type = type;
    this.isRequired = isRequired;
    this.fileExtension = fileExtension;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the DocumentsListDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {DocumentsListDto} The new instance of the DocumentsListDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({ originalFilename, type, isRequired, fileExtension }) {
    return new DocumentsListDto({
      originalFilename,
      type,
      isRequired,
      fileExtension,
    });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ originalFilename, type, isRequired, fileExtension }) {
    const checkFileExtension = (fileExtensions) => {
      return fileExtensions.reduce((state, fileExtension) => {
        const fileExtensionState = Object.values(
          DocumentFileExtensions
        ).includes(fileExtension);
        return fileExtensionState && state;
      }, false);
    };
    if (!originalFilename || typeof originalFilename !== "string")
      throw new Error("The originalFilename is required and must be a string");
    if (!type || typeof type !== "string")
      throw new Error("The type is required and must be a string");
    if (isRequired && typeof isRequired !== "boolean")
      throw new Error("The isRequired must be a boolean");
    if (
      fileExtension &&
      Array.isArray(fileExtension) &&
      checkFileExtension(fileExtension)
    )
      throw new Error(
        `The given fileExtension ${fileExtension.join(
          ", "
        )} must be an array of DocumentFileExtension ${Object.values(
          DocumentFileExtensions
        ).join(", ")}`
      );
  }
}

/**
 * Represents the sections in a form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class SectionsFormDto {
  /**
   * @readonly
   */
  name;
  /**
   * @readonly
   */
  fields;

  /**
   * Initializes a new instance of the SectionsFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} props.name - The name of the section.
   * @param {QuestionFormDto[]} props.fields - The fields of the section.
   * @returns {SectionsFormDto} The new instance of the SectionsFormDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * const sections = new SectionsFormDto({ name: 'name', fields: [questionFormDto1, questionFormDto2] });
   */
  constructor({ name, fields }) {
    SectionsFormDto.validate({ name, fields });
    this.name = name;
    this.fields = fields;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the SectionsFormDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {SectionsFormDto} The new instance of the SectionsFormDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({ name, fields }) {
    /**
     * The questions for the section.
     * @constant
     * @type {QuestionFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const questionFormDtoArray = fields?.map((field) => {
      return QuestionFormDto.fromObject(field);
    });
    return new SectionsFormDto({ name, fields: questionFormDtoArray });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ name, fields }) {
    if (!name || typeof name !== "string")
      throw new Error("The name is required and must be a string");
    if (!fields || !Array.isArray(fields))
      throw new Error(
        "The fields are required and must be an array of QuestionFormDto"
      );
  }
}

/**
 * Represents the questions in a form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class QuestionFormDto {
  /**
   * @readonly
   */
  type;
  /**
   * @readonly
   */
  value;
  /**
   * @readonly
   */
  placeholder;
  /**
   * @readonly
   */
  label;
  /**
   * @readonly
   */
  options;
  /**
   * @readonly
   */
  subtypes;
  /**
   * @readonly
   */
  validations;

  /**
   * Initializes a new instance of the QuestionFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} props.type - The type of the question.
   * @param {string} props.value - The value of the question.
   * @param {string} [props.placeholder] - The placeholder of the question, is obligatory when the type is 'input'.
   * @param {string} [props.label] - The label of the question, is obligatory when the type is 'input-checkbox'.
   * @param {string[]} [props.options] - The options of the question, is obligatory when the type is 'list'.
   * @param {QuestionSubtypesFormDto} [props.subtypes] - The subtypes of the question, is obligatory when the type is 'list'.
   * @param {ValidationFormDto} [props.validations] - The validations of the question, is optional.
   * @throws {Error} If the validation fails.
   * @returns {QuestionFormDto} The new instance of the QuestionFormDto class.
   * @example // Valid constructions
   * // All props
   * const question = new QuestionFormDto({ type: 'input', value: 'value', placeholder: 'placeholder', label: 'label', options: ['option1', 'option2'], subtypes: questionSubtypesFormDto, validations: validationsFormDto });
   * // Input props
   * const question = new QuestionFormDto({ type: 'input', value: 'value', placeholder: 'placeholder' });
   * // Input checkbox props
   * const question = new QuestionFormDto({ type: 'input-checkbox', value: 'value', label: 'label' });
   * // List props
   * const question = new QuestionFormDto({ type: 'list', value: 'value', options: ['option1', 'option2'] });
   * // List checkbox props
   * const question = new QuestionFormDto({ type: 'list-checkbox-multiple', value: 'value', options: ['option1', 'option2'] });
   * // List input props
   * const question = new QuestionFormDto({ type: 'list-input', value: 'value', options: ['option1', 'option2'] });
   * // List input checkbox props
   * const question = new QuestionFormDto({ type: 'list-input-checkbox', value: 'value', options: ['option1', 'option2'] });
   * // Date props
   * const question = new QuestionFormDto({ type: 'date', value: 'value' });
   * // Date checkbox props
   * const question = new QuestionFormDto({ type: 'date-checkbox', value: 'value' });
   */
  constructor({
    type,
    value,
    placeholder,
    label,
    options,
    subtypes,
    validations,
  }) {
    QuestionFormDto.validate({
      type,
      value,
      placeholder,
      label,
      options,
      subtypes,
      validations,
    });
    this.type = type;
    this.value = value;
    this.placeholder = placeholder;
    this.label = label;
    this.options = options;
    this.subtypes = subtypes;
    this.validations = validations;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the QuestionFormDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {QuestionFormDto} The new instance of the QuestionFormDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({
    type,
    value,
    placeholder,
    label,
    options,
    subtypes,
    validations,
  }) {
    /**
     * The subtypes options for the question, is optional.
     * @constant
     * @type {QuestionSubtypesFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const subtypeDtoArray = subtypes?.map((subtype) =>
      QuestionSubtypesFormDto.fromObject(subtype)
    );
    /**
     * The validations of the question, is optional.
     * @constant
     * @type {ValidationFormDto | undefined}
     * @throws {Error} If the validation fails.
     */
    const validationFormDto = ValidationFormDto.fromObject(validations);
    return new QuestionFormDto({
      type,
      value,
      placeholder,
      label,
      options,
      subtypes: subtypeDtoArray,
      validations: validationFormDto,
    });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ type, value, options, subtypes, validations }) {
    if (
      !type ||
      typeof type !== "string" ||
      !Object.values(QuestionFormTypes).includes(type)
    )
      throw new Error(
        "The type is required and must be a string and one of the following: " +
          Object.values(QuestionFormTypes).join(", ")
      );
    if (!value || typeof value !== "string")
      throw new Error("The value is required and must be a string");
    if (!options && !subtypes && !validations)
      throw new Error(
        "At least one of the options, subtypes or validations is required"
      );
  }
}

/**
 * Represents the subtypes for a question.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class QuestionSubtypesFormDto {
  /**
   * @readonly
   */
  answerToShow;
  /**
   * @readonly
   */
  placeholder;
  /**
   * @readonly
   */
  validations;

  /**
   * Initializes a new instance of the QuestionSubtypesFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {string} [props.answerToShow] - The answer to show, is optional.
   * @param {string} [props.placeholder] - The placeholder, is optional.
   * @param {ValidationFormDto} [props.validations] - The validations, is optional.
   * @returns {QuestionSubtypesFormDto} The new instance of the QuestionSubtypesFormDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const questionSubtypes = new QuestionSubtypesFormDto({ answerToShow: 'answerToShow', placeholder: 'placeholder', validations: validationFormDto });
   * // Some props
   * const questionSubtypes = new QuestionSubtypesFormDto({ answerToShow: 'answerToShow', placeholder: 'placeholder' });
   */
  constructor({ answerToShow, placeholder, validations }) {
    QuestionSubtypesFormDto.validate({
      answerToShow,
      placeholder,
      validations,
    });
    this.answerToShow = answerToShow;
    this.placeholder = placeholder;
    this.validations = validations;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the QuestionSubtypesFormDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {QuestionSubtypesFormDto} The new instance of the QuestionSubtypesFormDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({ answerToShow, placeholder, validations }) {
    /**
     * The subtypes of the question of type list.
     * @constant
     * @type {ValidationFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const validationFormDto = ValidationFormDto.fromObject(validations);

    return new QuestionSubtypesFormDto({
      answerToShow,
      placeholder,
      validations: validationFormDto,
    });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ answerToShow, placeholder, validations }) {
    if (!answerToShow && !placeholder && !validations)
      throw new Error(
        "At least one of the answerToShow, placeholder or validations is required"
      );
  }
}

/**
 * Represents the validation for a form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class ValidationFormDto {
  /**
   * @readonly
   */
  required;
  /**
   * @readonly
   */
  maxLength;
  /**
   * @readonly
   */
  minLength;
  /**
   * @readonly
   */
  pattern;

  /**
   * Initializes a new instance of the ValidationFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {ValidationQuestionFormDto} [props.required] - The required validation question, is optional.
   * @param {ValidationQuestionFormDto} [props.maxLength] - The maximum length validation question, is optional.
   * @param {ValidationQuestionFormDto} [props.minLength] - The minimum length validation question, is optional.
   * @param {ValidationQuestionFormDto} [props.pattern] - The pattern validation question, is optional.
   * @returns {ValidationFormDto} The new instance of the ValidationFormDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const validation = new ValidationFormDto({ required: validationQuestionFormDto1, maxLength: validationQuestionFormDto2, minLength: validationQuestionFormDto3, pattern: validationQuestionFormDto4 });
   * // Some props
   * const validation = new ValidationFormDto({ required: validationQuestionFormDto1, maxLength: validationQuestionFormDto2, minLength: validationQuestionFormDto3 });
   */
  constructor({ required, maxLength, minLength, pattern }) {
    ValidationFormDto.validate({ required, maxLength, minLength, pattern });
    this.required = required;
    this.maxLength = maxLength;
    this.minLength = minLength;
    this.pattern = pattern;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the ValidationFormDto class from an object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {ValidationFormDto} The new instance of the ValidationFormDto class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({ required, maxLength, minLength, pattern }) {
    /**
     * The required validation of the question.
     * @constant
     * @type {ValidationQuestionFormDto | undefined}
     * @throws {Error} If the validation fails.
     */
    const requiredValidation = required
      ? new ValidationQuestionFormDto(required)
      : undefined;
    /**
     * The maximum length validation of the question.
     * @constant
     * @type {ValidationQuestionFormDto | undefined}
     * @throws {Error} If the validation fails.
     */
    const maxLengthValidation = maxLength
      ? new ValidationQuestionFormDto(maxLength)
      : undefined;
    /**
     * The minimum length validation of the question.
     * @constant
     * @type {ValidationQuestionFormDto | undefined}
     * @throws {Error} If the validation fails.
     */
    const minLengthValidation = minLength
      ? new ValidationQuestionFormDto(minLength)
      : undefined;
    /**
     * The pattern validation of the question.
     * @constant
     * @type {ValidationQuestionFormDto | undefined}
     * @throws {Error} If the validation fails.
     */
    const patternValidation = pattern
      ? new ValidationQuestionFormDto(pattern)
      : undefined;
    return new ValidationFormDto({
      required: requiredValidation,
      maxLength: maxLengthValidation,
      minLength: minLengthValidation,
      pattern: patternValidation,
    });
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ required, maxLength, minLength, pattern }) {
    if (!required && !maxLength && !minLength && !pattern)
      throw new Error(
        "At least one of the required, maxLength, minLength or pattern is required"
      );
  }
}

/**
 * Represents the validations for a one or more questions in a form.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class ValidationQuestionFormDto {
  value;
  message;

  /**
   * Initializes a new instance of the ValidationQuestionFormDto class.
   * @param {Object} props - The options for the constructor.
   * @param {any} [props.value] - The value of the validation question, is optional.
   * @param {string} [props.message] - The message associated with the validation question, is optional.
   * @returns {ValidationQuestionFormDto} The new instance of the ValidationQuestionFormDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const validationQuestion = new ValidationQuestionFormDto({ value: 'value', message: 'message' });
   * // Some props
   * const validationQuestion = new ValidationQuestionFormDto({ value: 'value' });
   */
  constructor({ value, message }) {
    ValidationQuestionFormDto.validate({ value, message });
    this.value = value;
    this.message = message;
    Object.freeze(this);
  }

  /**
   * Validate the properties of the object.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({ value, message }) {
    if (!value && !message)
      throw new Error("At least one of the value or message is required");
  }
}
