import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../../../../../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../../../../../parts/breadcrum";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalDecision from "../../../../../../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../../../../../../components/Modals/modalInfo";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  updateFormTagById,
  fetchFormTags,
  getTagsFormList,
  getTagsFormStatus,
} from "../../states/tagSliceForms";
import { CODES } from "../../../../../../../../../../../utils/codes";

const UpdateTagForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const params = useParams();
  const tagsList = useSelector(getTagsFormList);
  const tagsStatus = useSelector(getTagsFormStatus);

  const nameValue = watch("name");
  let bsPrefixName =
    errors.name || nameValue?.length === 100
      ? "input-group-container__no-icon-error label"
      : "input-group-container__no-icon label";

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test(
        "maxNumbers",
        "*Máximo 100 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(100);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    description: yup
      .string()
      .test(
        "maxNumbers",
        "*Máximo 200 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(200);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  /* UseEffects Fetch Tags */

  useEffect(() => {
    if (tagsStatus === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [tagsStatus]);

  useEffect(() => {
    try {
      if (tagsStatus === "fetch") {
        dispatch(fetchFormTags("CUSTOMFORM"));
      }
    } catch (error) {
      console.error(error);
    }
  }, [tagsStatus, dispatch]);

  useEffect(() => {
    if (tagsList && typeof tagsList !== "string") {
      const tag = tagsList.find((item) => item._id === params.id);
      if (tag) {
        setValue("name", tag.name);
        setValue("description", tag.description);
      }
    }
  }, [tagsList]);

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleUpdateTag = async (data) => {
    try {
      setIsLoading(true);

      let obj = {
        id: params.id,
        updateLabel: {
          name: data.name,
          description: data.description,
          resourceType: "CUSTOMFORM",
        },
      };
      const updateTagService = await dispatch(updateFormTagById(obj)).unwrap();
      if (updateTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(updateTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(updateTagService);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar etiqueta"} />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Diligencia los siguientes campos para editar tu etiqueta.
        </Col>
      </Row>
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleUpdateTag)}>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Nombre de la etiqueta
                  <span style={{ color: "red" }}>{"*"}</span>
                </Form.Label>
                <Form.Control
                  {...register("name")}
                  placeholder={"Nombre de la etiqueta"}
                  bsPrefix={bsPrefixName}
                  maxLength={100}
                />
                <div className="caption custom-input__error">
                  {errors.name?.message}
                </div>

                <label
                  className={
                    watch("name")?.length == 100
                      ? "caption__error"
                      : "caption__primary-color"
                  }
                >
                  Máximo 100 caracteres permitidos
                </label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={"auto"} md={"auto"} lg={4}>
              <Form.Group>
                <Form.Label className="form__label">
                  Descripción de la etiqueta
                </Form.Label>
                <Form.Control
                  {...register("description")}
                  placeholder="Descripción de la etiqueta"
                  as="textarea"
                  bsPrefix={
                    errors.description || watch("description")?.length == 200
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  maxLength={200}
                />
                <div className="caption custom-input__error">
                  {errors.description?.message}
                </div>

                <label
                  className={
                    watch("description")?.length == 200
                      ? "caption__error"
                      : "caption__primary-color"
                  }
                >
                  Máximo 200 caracteres permitidos
                </label>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row
            className="d-fex justify-content-end sidebar__bottom__container"
            xs={"auto"}
            md={"auto"}
          >
            <Col>
              <Button
                startIcon={<ArrowBackOutlinedIcon />}
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                startIcon={<SaveOutlinedIcon />}
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>

      <ModalInfo
        title={"Etiqueta actualizada"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Cancelar"}
        type={"warning"}
        message={
          "¿Seguro que deseas cancelar la edición de la etiqueta? todos los cambios se perderán"
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default UpdateTagForm;
