import * as yup from "yup";
import DetailsFormInputs from "../constants/detailsFormInputs";
import createYupSchemaBasedOnInputsStructure from "../../../../../utils/createYupSchemaBasedOnInputsStructure";

/**
 * The schema for the details section, based on the details form inputs `DetailsFormInputs`.
 * 
 * @constant
 */
const DetailsSchema = createYupSchemaBasedOnInputsStructure(DetailsFormInputs);

export default DetailsSchema;
