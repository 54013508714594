import AddIcon from "@mui/icons-material/Add";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Button } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import {
  fetchOtherDocumentTemplates,
  getOtherDocumentTemplates,
  getTempleteStatus,
  setSelectedTemplate,
  setStatusTemplete,
} from "./templeteSlice";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempletesList,
  getTagDocumentTempleteStatus,
} from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import { handlePreviewTemplate } from "../../create/other/utils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { v4 as uuidv4 } from "uuid";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { deleteOtherDocumentTemplete } from "../../../../../../services/documentTemplates/templeteServices";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../utils/codes";
import { MDBDataTableV5 } from "mdbreact";
import CustomSearch from "../../../../../../components/Datatable/customSearch";
import { resetOtherDocumentInfo } from "../../../../../../parts/document/otherDocumentSlice";

const Templetedocument = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusTemplete = useSelector(getTempleteStatus);
  const templeteList = useSelector(getOtherDocumentTemplates);
  const tagTempleteList = useSelector(getTagDocumentTempletesList);
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);

  const [isLoading, setIsLoading] = useState(false);
  const [filterTagTemplete, setFilterTagTemplete] = useState([]);
  const [tagTemplete, setTagTemplete] = useState([]);

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Plantilla
        </label>,
      ],

      field: "templateName",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Etiquetas
        </label>,
      ],

      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Acciones
        </label>,
      ],

      field: "actions",
    },
  ];
  const rows = [];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [modalResponse, setModalResponse] = useState({
    open: false,
    title: "",
    response: {},
    action: null,
  });

  const handleUpdateTemplete = (templete) => {
    dispatch(setSelectedTemplate(templete));
    navigate(templete._id);
  };

  const handleTagsSelected = (tags) => {
    setFilterTagTemplete(tags);
  };

  const handleDeleteTag = (tag) => {
    setFilterTagTemplete(filterTagTemplete.filter((t) => t !== tag));
    const newTags = filterTagTemplete.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredTags = [];
      let filterTags = [];
      dataTable.rows.forEach((item) => {
        const rawLabels = item.labels;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredTags.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredTags });
    } else {
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  const handleDeleteTemplete = async (templete) => {
    try {
      setIsLoading(true);
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        company,
        corporateUnit,
        userId,
        id: templete?._id,
      };
      const responseDelete = await deleteOtherDocumentTemplete(obj);
      if (
        responseDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDelete.data.success
      ) {
        dispatch(setStatusTemplete({ status: "fetch" }));
        setModalResponse({
          open: true,
          response: {
            ...responseDelete,
            data: {
              responseMessage: "Se ha eliminado correctamente la plantilla",
            },
          },
          title: "Se ha eliminado correctamente",
          action: () =>
            setModalResponse({
              open: false,
              response: {},
              title: "",
              action: null,
            }),
        });
      } else if (
        responseDelete.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setModalResponse({
          open: true,
          response: responseDelete,
          title: "Sin permisos suficientes",
          action: () =>
            setModalResponse({
              open: false,
              response: {},
              title: "",
              action: null,
            }),
        });
      } else {
        setModalResponse({
          open: true,
          response: {
            data: {
              responseMessage: "No se ha podido eliminar la plantilla",
            },
          },
          title: "Ha ocurrido un error",
          action: () =>
            setModalResponse({
              open: false,
              response: {},
              title: "",
              action: null,
            }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const buildRows = (rows) => {
    if (typeof rows !== "string") {
      const returnRows = rows.map((row) => {
        return {
          templateName: (
            <Row style={{ width: "25rem" }}>
              <Col xs={12}>{row.name}</Col>
            </Row>
          ),
          labels: (
            <Row style={{ width: "30rem" }}>
              {row.labels.map((label) => label.name).join(", ")}
            </Row>
          ),
          actions: row.company && (
            <Row>
              <Col xs="auto">
                <Button
                  variant="contained"
                  startIcon={<VisibilityOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={async () => {
                    try {
                      setIsLoading(true);
                      await handlePreviewTemplate(row);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  <p className="button">Previsualizar</p>
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="contained"
                  startIcon={<ArticleOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    dispatch(resetOtherDocumentInfo());
                    navigate("/service/create/other/from-template/create", {
                      state: row,
                    });
                  }}
                >
                  <p className="button">Usar</p>
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="contained"
                  startIcon={<EditOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => handleUpdateTemplete(row)}
                >
                  <p className="button">Editar</p>
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="contained"
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => handleDeleteTemplete(row)}
                >
                  <p className="button">Eliminar</p>
                </Button>
              </Col>
            </Row>
          ),
        };
      });
      return returnRows;
    }
    return [];
  };

  useEffect(() => {
    const templeteData = async () => {
      try {
        if (statusTemplete === "fetch") {
          setIsLoading(true);
          dispatch(fetchOtherDocumentTemplates());
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    templeteData();
  }, [statusTemplete, dispatch]);

  useEffect(() => {
    const formatRows = buildRows(templeteList);
    setFilteredDataTable({
      columns: columns,
      rows: formatRows,
    });
    setDataTable({
      columns: columns,
      rows: formatRows,
    });
  }, [templeteList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsDocumentTempletes === "fetch") {
          dispatch(fetchTagsDocumentTempletes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsDocumentTempletes, dispatch]);

  useEffect(() => {
    dispatch(setSelectedTemplate({}));
    setTagTemplete(tagTempleteList);
  }, [tagTempleteList]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Mis Plantillas"} />
      </Row>
      <br />
      <Row>
        <p className="caption">Gestiona tus plantillas.</p>
      </Row>
      <br />
      <Row className="justify-content-end">
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<BookmarksOutlinedIcon />}
            className="custom-input__button__primary-color"
            onClick={() => navigate("tags")}
          >
            <p className="button">Administrar etiquetas</p>
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} lg={5}>
          <CustomSearch
            placeholder="Buscar plantilla"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col />

        <Col xs={"auto"}>
          <FilterTag
            classStyle="custom-input__button__secondary-color"
            tagsList={tagTempleteList}
            list={dataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
          />
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={() => navigate("create")}
          >
            <p className="button">Crear plantilla</p>
          </Button>
        </Col>
      </Row>
      <br />
      <Row style={{ justifyContent: "end" }}>
        {filterTagTemplete.map((tag) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag || ""}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}></Row>
      <br />
      <Row className="dt-list-center-content">
        <MDBDataTableV5
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={7}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "plantillas"]}
          fullPagination
        />
      </Row>
      <ModalInfo
        title={modalResponse.title}
        open={modalResponse.open}
        onClose={modalResponse.action}
        responseData={modalResponse.response}
      />
    </Container>
  );
};

export default Templetedocument;
