import { Button } from "@mui/material";
import { MDBBadge } from "mdbreact";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";

import { CODES } from "../../../../../utils/codes";
const InfoStatusAlerts = () => {
  const navigate = useNavigate();

  const dataStatus = [
    {
      _id: "626a9b5456c1dd7a1b145cb9",
      colorId: 100,
      description: "Ha llegado la fecha de activación de la alerta.",
      name: "Activa",
      __v: 0,
    },
    {
      _id: "626a9acf56c1dd7a1b145cb8",
      colorId: 200,
      description: "No ha llegado la fecha de activación de la alerta.",
      name: "Inactiva",
      __v: 0,
    },

    {
      _id: "626a9c0456c1dd7a1b145cbb",
      colorId: 400,
      description:
        "Llegó la fecha de activación de la alerta, pero no se ha marcado como completada.",
      name: "Vencida",
      __v: 0,
    },
    {
      _id: "626a9bbd56c1dd7a1b145cba",
      colorId: 300,
      description:
        "La alerta se marcó como completada y ha sido resuelta con éxito.",
      name: "Completada",
      __v: 0,
    },
  ];

  const getBackgroundColor = (colorId) => {
    switch (colorId) {
      case CODES.COD_LIGHT_ORANGE:
        return "#FFD7B1";
      case CODES.COD_LIGHT_GREEN:
        return "#CCF4E3";
      case CODES.COD_LIGHT_PURPLE:
        return "#E6E9EF";
      case CODES.COD_COLOR_BLUE:
        return "#FFD7B1";
      default:
        return "#F9DADE";
    }
  };

  return (
    <Container fluid>
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Tipos de estado"} />
      </Row>
      <br />
      <br />
      <br />
      <div
        className="data-table dt-list-items"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100vh - 10rem)",
        }}
      >
        {dataStatus.map((state, index) => (
          <div key={uuidv4()}>
            <Row style={{ marginLeft: "1rem", alignItems: "center" }}>
              <Col md={1}>
                <MDBBadge
                  color=""
                  pill
                  className="caption"
                  style={{
                    backgroundColor: getBackgroundColor(state?.colorId),
                    fontSize: "1.2rem",
                    boxShadow: "none",
                    padding: "0.8rem",
                  }}
                >
                  <span className="custom-badge">{state.name}</span>
                </MDBBadge>
              </Col>

              <Col className="label__description">{state.description}</Col>
            </Row>
            <br />
          </div>
        ))}
        <Row className="justify-content-md-end" style={{ marginTop: "20%" }}>
          <Col md={1}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => {
                navigate(-1);
              }}
            >
              Regresar
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default InfoStatusAlerts;
