import { Col, Container, Row } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";

const CardDocumentInfo = (props) => {
  const { name, documentName: additionalInfo = null, handleDownload } = props;

  return (
    <Container fluid>
      <Row className="cards-document-informative">
        <Col style={{ paddingLeft: "5%" }} md={7}>
          <Row className="col-start heading">{name}</Row>
          <Row className="col-start caption">{additionalInfo}</Row>
        </Col>
        <Col>
          <Row className="col-start">
            <Col className="col-flex">
              <Button
                size="small"
                className={"button-primary-folders"}
                onClick={handleDownload}
              >
                <FileDownloadIcon fontSize="large" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CardDocumentInfo;
