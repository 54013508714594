import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SortIcon from "@mui/icons-material/Sort";
import { Button, IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DetailsBar from "../../../../../../components/DetailsBar/DetailsBar";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import CustomSearchClauses from "../../../../../../components/Search/CustomSearchClauses";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../parts/categories/clauseCategorySlice";
import FilterCategory from "../../../../../../parts/filters/FilterCategory";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import {
  fetchTags,
  getStatus,
  getTagList,
} from "../../../../../../parts/manageTags/tagSlice";
import { getClausesByCompany } from "../../../../../../services/clauses/clausesServices";
import { CODES } from "../../../../../../utils/codes";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import { NUMBERINGWORDSO } from "../../../../../../utils/numberings";
import {
  fetchContractTypes,
  getContractsTypesList,
  getStatus as getStatusContractTypes,
  setStatusFetchContractTypes,
} from "../../library/contractTypes/contractTypesSlice";
import { setClearClauseBulk } from "./clauseBulkSlice";
import {
  fetchClauses,
  getClausesList,
  getClausesStatus,
  removeClause,
  setClauseByIdStatus,
} from "./clauseSlice";
import CustomSearchBar, {
  CustomSearchBarOptions,
  CustomSearchBarSearchTypes,
} from "../../../../../../components/Search/customSearchBar";

const Clauses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const clausesList = useSelector(getClausesList);
  const statusContractTypes = useSelector(getStatusContractTypes);
  const contractTypes = useSelector(getContractsTypesList);

  const statusClauses = useSelector(getClausesStatus);
  const [clauses, setClauses] = useState([]);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalDecisionRemoveClause, setIsOpenModalDecisionRemoveClause] =
    useState(false);
  const [clauseToRemove, setClauseToRemove] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  const [anchorOrderBy, setAnchorOrderBy] = useState(null);
  const openOrderBy = Boolean(anchorOrderBy);
  const [textCustomSearch, setTextCustomSearch] = useState("");
  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [isLoadingContractTypes, setIsLoadingContractTypes] = useState(false);
  const [messageDeleteClause, setMessageDeleteClause] = useState("");
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("CLAUSES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusClauses === "fetch") {
          dispatch(fetchClauses(textCustomSearch));
        }

        setClauses(clausesList);
        setFilteredClauses(clausesList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusClauses, dispatch]);

  useEffect(() => {
    if (
      statusClauses === "loading" ||
      statusCategories === "loading" ||
      statusTags === "loading"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusClauses, statusCategories, statusTags]);

  useEffect(() => {
    setClauses(clausesList);
    setFilteredClauses(clausesList);
  }, [clausesList]);

  useEffect(() => {
    dispatch(setClearClauseBulk());
  }, []);

  const handleClickAnchorCreate = (event) => {
    setAnchorElCreate(event.currentTarget);
  };

  const handleCloseAnchorCreate = () => {
    setAnchorElCreate(null);
  };

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = clauses.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.title));
      });

      setFilteredClauses(filterTags);
    } else {
      setFilteredClauses(clauses);
    }
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });

      setFilteredClauses(filterCategories);
    } else {
      setFilteredClauses(clauses);
    }
  };

  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
  };

  const handleCloseModalDesicionRemoveClause = () => {
    setIsOpenModalDecisionRemoveClause(false);
  };

  const handleDisagreeModalDesicionRemoveClause = () => {
    handleCloseModalDesicionRemoveClause();
  };

  const handleAgreeModalDesicionRemoveClause = async () => {
    try {
      setIsLoading(true);
      handleCloseModalDesicionRemoveClause();
      const response = await dispatch(removeClause(clauseToRemove)).unwrap();
      setResponseData(response);
      setResponseData(response);
      if (response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(response);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData({
        ...response,
        data: { message: "La cláusula ha sido actualizada exitosamente" },
      });
      setIsOpenModalInfo(true);
      dispatch(setStatusFetchContractTypes());
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClause = (clauseId) => {
    setClauseToRemove(clauseId);
    const message = getContractTypesListByClause(clauseId);
    setMessageDeleteClause(message);

    setIsOpenModalDecisionRemoveClause(true);
  };

  const handleUpdateClause = (clause) => {
    dispatch(setClauseByIdStatus());
    navigate(clause._id);
  };

  const handleOrderClauses = (type) => {
    const clauses = [...clausesList];

    if (type === "userBy") {
      const { userId } = JSON.parse(localStorage.getItem("payloadToken"));

      const clausesUser = clauses.filter(
        (clause) => clause?.createdBy?._id === userId
      );
      const clausesOtherUser = clauses.filter(
        (clause) => clause?.createdBy?._id !== userId
      );

      clausesUser.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      );
      clausesOtherUser.sort((a, b) =>
        `${a?.firstName} ${a?.firstSurname}`.localeCompare(
          `${b?.firstName} ${b?.firstSurname}`
        )
      );

      setClauses([...clausesUser, ...clausesOtherUser]);
      setFilteredClauses([...clausesUser, ...clausesOtherUser]);
    } else if (type === "titleAsc") {
      clauses.sort((a, b) => a.title.localeCompare(b.title));

      setClauses(clauses);
      setFilteredClauses(clauses);
    } else if (type === "titleDesc") {
      clauses.sort((a, b) => b.title.localeCompare(a.title));

      setClauses(clauses);
      setFilteredClauses(clauses);
    } else {
      clauses.sort((a, b) => new Date(b[type]) - new Date(a[type]));

      setClauses(clauses);
      setFilteredClauses(clauses);
    }
  };

  const handleCustomSearch = async ({ searchText }) => {
    try {
      if (searchText === "") {
        setFilteredClauses(clauses);
      } else {
        setTextCustomSearch(searchText);
        setIsLoading(true);
        const company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          company,
          corporateUnit,
          searchTerm: searchText,
        };
        const response = await getClausesByCompany(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          setFilteredClauses(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusContractTypes === "fetch") {
          dispatch(fetchContractTypes({ isPublish: false }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusContractTypes, dispatch]);

  useEffect(() => {
    if (statusContractTypes === "loading") {
      setIsLoadingContractTypes(true);
    } else {
      setIsLoadingContractTypes(false);
    }
  }, [statusContractTypes]);

  const getContractTypesListByClause = (clauseId) => {
    if (!contractTypes?.length) return "";
    const contractTypeFilter = contractTypes.filter((ct) =>
      ct?.clauses?.some((cla) => cla?.clause?._id === clauseId)
    );
    let message = "¿Seguro quieres eliminar esta cláusula?";
    if (contractTypeFilter.length) {
      message = (
        <Container fluid className="caption">
          <Row>
            ¿Seguro quieres eliminar esta cláusula? Si continuas también se
            eliminará de las siguientes minutas:
          </Row>
          <br />
          <Row style={{ paddingLeft: "5%" }}>
            <ul>
              {contractTypeFilter.map(({ _id, name }) => (
                <li key={_id}>{name}</li>
              ))}
            </ul>
          </Row>
        </Container>
      );
    }
    return message;
  };

  return (
    <Container fluid>
      {(isLoading || isLoadingContractTypes || isLoadingContractTypes) && (
        <LoadingContent />
      )}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Mis Cláusulas"} />
      </Row>
      <br />
      <br />
      <Row>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<SettingsOutlinedIcon />}
            className="custom-input__button__secondary-color"
            onClick={() => navigate("tags")}
          >
            <p className="button">Administrar etiquetas</p>
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="custom-input__button__primary-color"
            onClick={handleClickAnchorCreate}
          >
            <p className="button">Crear cláusula</p>
          </Button>
          <Menu
            id="create-clause-menu"
            anchorEl={anchorElCreate}
            open={openCreateMenu}
            onClose={handleCloseAnchorCreate}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("create");
                Mixpanel.track("Biblioteca crear cláusulas ", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                  type: "Desde cero",
                });
              }}
            >
              <span className="body-content">Desde cero</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                Mixpanel.track("Biblioteca crear cláusulas ", {
                  email: payloadToken?.email,
                  companyName: payloadToken?.companyName,
                  type: "Desde contrato",
                });
                navigate("create-massive?from=clauses");
              }}
            >
              <span className="body-content">Desde un contrato</span>
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col xs={5}>
          <CustomSearchBar
            onSearch={handleCustomSearch}
            placeholder="Buscar cláusula"
            options={
              new CustomSearchBarOptions({
                searchType: CustomSearchBarSearchTypes.Remote,
                maxLength: 20,
              })
            }
          />
        </Col>
        <Col xs={{ span: "auto", offset: 1 }}>
          <Button
            variant="contained"
            startIcon={<SortIcon />}
            className="custom-input__button__secondary-color"
            onClick={(event) => setAnchorOrderBy(event.currentTarget)}
          >
            <p className="button">Ordenar por</p>
          </Button>
          <Menu
            anchorEl={anchorOrderBy}
            open={openOrderBy}
            onClose={() => setAnchorOrderBy(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                handleOrderClauses("titleAsc");
                setAnchorOrderBy(null);
              }}
            >
              <span className="body-content">Orden alfabético (A - Z)</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleOrderClauses("titleDesc");
                setAnchorOrderBy(null);
              }}
            >
              <span className="body-content">Orden alfabético (Z - A)</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOrderClauses("createdAt");
                setAnchorOrderBy(null);
              }}
            >
              <span className="body-content">Fecha de creación</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOrderClauses("updatedAt");
                setAnchorOrderBy(null);
              }}
            >
              <span className="body-content">Fecha de modificación</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOrderClauses("userBy");
                setAnchorOrderBy(null);
              }}
            >
              <span className="body-content">Usuario que las creó</span>
            </MenuItem>
            {}
          </Menu>
        </Col>
        <Col xs={"auto"}>
          <FilterTag
            tagsList={tagsList}
            list={clauses}
            setFilteredInfo={setFilteredClauses}
            handleTagsSelected={handleTagsSelected}
            currentTagSelected={tagsSelected}
          />
        </Col>
        <Col xs={"auto"}>
          <FilterCategory
            categoriesList={categoriesList}
            list={clauses}
            setFilteredInfo={setFilteredClauses}
            handleCategoriesSelected={handleCategoriesSelected}
          />
        </Col>
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}

        {categoriesSelected.map((category) => {
          return (
            <Col xs={"auto"} key={category?._id}>
              <span className="caption custom-badges__secundary">
                {category + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteCategory(category)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row>
        <div className="custom-accordion__container">
          {!filteredClauses?.length && (
            <p className="body-content">No se han encontraron resultados.</p>
          )}
          {typeof filteredClauses === "string" ? (
            <p className="caption">{filteredClauses}</p>
          ) : (
            filteredClauses.map((clause, i) => {
              return (
                <div key={clause?._id}>
                  <Accordion className="custom-accordion">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Row style={{ width: "100%" }}>
                        <Col sm={7}>
                          <p className="heading__primary-color">
                            {clause?.title || ""}
                          </p>
                        </Col>
                        <Col sm={2} style={{ display: "flex" }}>
                          <span className="subheading">
                            A favor de:{" "}
                            <span className="caption">{clause.inFavor}</span>
                          </span>
                        </Col>
                        <Col sm={2}>
                          <span className="subheading">
                            Categorías:{" "}
                            {clause.categories.map((category) => {
                              return (
                                <span
                                  key={category?._id}
                                  style={{ marginRight: "1%" }}
                                  className="caption custom-badges__secundary"
                                >
                                  {category.name}
                                </span>
                              );
                            })}
                          </span>
                        </Col>
                        <Col sm={1}>
                          <IconButton
                            className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                            sx={{ borderRadius: "8px" }}
                            onClick={() => {
                              setOpenDetailsDrawner(true);
                              setSelectedElement(clause);
                            }}
                          >
                            <InfoOutlinedIcon fontSize="large" />
                          </IconButton>
                        </Col>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row>
                        <span className="subheading">
                          Etiquetas:{" "}
                          {clause?.labels?.length > 0 &&
                            clause.labels.map((tag) => {
                              return (
                                <span
                                  key={tag?._id}
                                  style={{ marginRight: "1%" }}
                                  className="caption custom-badges__disabled"
                                >
                                  {tag.name}
                                </span>
                              );
                            })}
                        </span>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <span className="caption">
                          {/* Text clause */}
                          {ReactHtmlParser(clause.text)}
                          <ol className="clauses">
                            {clause.subclauses?.map((item, i) => {
                              return (
                                <li
                                  key={item?._id}
                                  className="justify-text clauses-number"
                                >
                                  <b>
                                    {item.subclause?.title &&
                                      `${item.subclause.title}:`}
                                  </b>
                                  <span className="caption">
                                    {ReactHtmlParser(item.subclause?.text)}
                                  </span>
                                  <ol>
                                    {item.subclause?.childs &&
                                      item.subclause?.childs.map((child) => {
                                        return (
                                          <li
                                            key={child?._id}
                                            className="justify-text clauses-number"
                                          >
                                            <b>{child.title || ""}</b>
                                            <span className="caption">
                                              {ReactHtmlParser(child.text)}
                                            </span>
                                          </li>
                                        );
                                      })}
                                  </ol>
                                </li>
                              );
                            })}
                          </ol>
                          <br />
                          <ol className="paragraphs clauses-number">
                            {clause.paragraphs.map((el, i) => {
                              return (
                                <li
                                  key={el?._id}
                                  className="justify-text clauses-number"
                                  numbering={`Parágrafo ${
                                    NUMBERINGWORDSO[i + 1]
                                  }`}
                                >
                                  <span className="caption">
                                    {ReactHtmlParser(el.paragraph?.text)}
                                  </span>
                                </li>
                              );
                            })}
                          </ol>
                        </span>
                      </Row>
                      {clause.company && (
                        <Row style={{ justifyContent: "right" }}>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<EditOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                Mixpanel.track("Biblioteca editar cláusula ", {
                                  email: payloadToken?.email,
                                  companyName: payloadToken?.companyName,
                                });
                                handleUpdateClause(clause);
                              }}
                            >
                              <p className="button">Editar</p>
                            </Button>
                          </Col>
                          <Col xs={"auto"}>
                            <Button
                              variant="contained"
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              className="custom-input__button__primary-color"
                              onClick={() => {
                                Mixpanel.track(
                                  "Biblioteca eliminar cláusula ",
                                  {
                                    email: payloadToken?.email,
                                    companyName: payloadToken?.companyName,
                                  }
                                );
                                handleDeleteClause(clause._id);
                              }}
                            >
                              <p className="button">Eliminar</p>
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          )}
        </div>
      </Row>
      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModalInfo}
        onClose={handleCloseModalInfo}
        buttonClose={true}
        handleCloseDefault={handleCloseModalInfo}
      />

      <ModalDecision
        title={"Eliminar cláusula"}
        message={messageDeleteClause}
        open={isOpenModalDecisionRemoveClause}
        onClose={handleCloseModalDesicionRemoveClause}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicionRemoveClause}
        handleDisagree={handleDisagreeModalDesicionRemoveClause}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={{ ...selectedElement, name: selectedElement.title }}
        pageName={"Clause"}
      />
    </Container>
  );
};

export default Clauses;
