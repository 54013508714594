import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import BaseTypeInput from "./BaseTypeInput";
import { Button, IconButton, TextField } from "@mui/material";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

// BEGINNING OF TYPE DEFINITIONS
/**
 * The type of the question.
 *
 * @typedef {Object} QuestionType
 * @property {string} _id The id of the question.
 * @property {string} value The value of the question.
 * @property {string} label The label of the question.
 * @property {string[]} options The options of the question.
 * @property {{ required: { value: boolean } }} validations The validations of the question.
 */

// END OF TYPE DEFINITIONS

/**
 * The check list question input component.
 *
 * @param {Object} props The props of the component.
 * @param {QuestionType} props.question The question to display.
 * @param {(question: QuestionType) => void} props.onDataChange The function to call when the data changes.
 * @param {() => void} props.onDelete The function to call when the question is deleted.
 * @returns The JSX element to render.
 */
export const TypeInputCheckList = ({ question, onDataChange, onDelete }) => {
  const { options } = question;
  const [isEditable, setIsEditable] = useState(false);
  const [transformedOptions, setTransformedOptions] = useState(() => {
    if (options.length && typeof options[0] === "object") {
      return options.map((option, index) => ({
        ...option,
        id: index + 1,
        isEditing: false,
      }));
    } else if (options.length && typeof options[0] === "string") {
      return options.map((option, index) => ({
        id: index + 1,
        answerToShow: option,
        isEditing: false,
      }));
    } else {
      return [];
    }
  });
  const [saveChangesFlag, setSaveChangesFlag] = useState(false);

  const activeSaveChangesFlag = () => {
    setSaveChangesFlag(true);
  };

  const desactiveSaveChangesFlag = () => {
    setSaveChangesFlag(false);
  };

  const addOption = () => {
    setTransformedOptions((prevTransformedOptions) => {
      const newOptionId = prevTransformedOptions.length
        ? prevTransformedOptions[prevTransformedOptions.length - 1].id + 1
        : 1;

      return [
        ...prevTransformedOptions,
        {
          id: newOptionId,
          answerToShow: `Opción ${newOptionId}`,
          isEditing: false,
        },
      ];
    });
    activeSaveChangesFlag();
  };

  const deleteOption = (id) => {
    setTransformedOptions((prevTransformedOptions) =>
      prevTransformedOptions.filter((option) => option.id !== id)
    );
    activeSaveChangesFlag();
  };

  const handleOptionLabelChange = (id, newAnswerToShow) => {
    setTransformedOptions((prevTransformedOptions) =>
      prevTransformedOptions.map((option) =>
        option.id === id ? { ...option, answerToShow: newAnswerToShow } : option
      )
    );
    activeSaveChangesFlag();
  };

  const handleToggleIsEditable = (isEditing) => {
    setIsEditable(isEditing);
  };

  const transformQuestionToOriginalStructure = (question) => {
    const optionsOriginalStructure = transformedOptions.map(
      (option) => option.answerToShow
    );

    return {
      ...question,
      options: optionsOriginalStructure,
    };
  };

  const saveChanges = (question) => {
    const questionOriginalStructure =
      transformQuestionToOriginalStructure(question);
    onDataChange(questionOriginalStructure);
    desactiveSaveChangesFlag();
  };

  return (
    <BaseTypeInput
      question={question}
      onDelete={onDelete}
      onDataChange={saveChanges}
      onToggleIsEditing={handleToggleIsEditable}
      saveChangesExternally={saveChangesFlag}
    >
      <Col className="d-flex flex-column gap-2">
        {transformedOptions.map((option) => (
          <div
            key={`${option.answerToShow}-${option.id}`}
            className="d-flex align-items-center"
          >
            <Form.Check
              type={"checkbox"}
              className="caption w-100"
              label={
                isEditable ? (
                  <TextField
                    variant="standard"
                    value={option.answerToShow}
                    fullWidth
                    onChange={(e) =>
                      handleOptionLabelChange(option.id, e.target.value)
                    }
                    className="caption"
                    InputProps={{ classes: { input: "caption" } }}
                  />
                ) : (
                  <p className="caption">{option.answerToShow}</p>
                )
              }
            />
            {isEditable && (
              <IconButton onClick={() => deleteOption(option.id)}>
                <DeleteOutlined
                  fontSize="large"
                  className="heading__primary-color"
                />
              </IconButton>
            )}
          </div>
        ))}
        {isEditable && (
          <Button
            variant="contained"
            startIcon={<AddOutlined />}
            onClick={addOption}
            className="custom-input__button__primary-color"
          >
            Agregar opción
          </Button>
        )}
      </Col>
    </BaseTypeInput>
  );
};

TypeInputCheckList.propTypes = {
  question: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.string.isRequired,
    validations: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
