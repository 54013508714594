import React from "react";
import { Fade } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CardButtonWithNavButton from "../../../components/Cards/CardButtonWithNavButton";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";


const Login = () => {
  return (
    <Container fluid>
      <Fade
        in={true}
        style={{
          transitionDuration: "2s",
        }}
      >
        <Container className="select-role">
          <Row>
            <Col>
              <p className="display-x-large__primary-color">
                ¿Qué tipo de usuario eres?
              </p>
              <p className="display-large__primary-color-hover">
                Selecciona el tipo de usuario con el que quieres iniciar sesión
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <CardButtonWithNavButton
              icon={<ManageAccountsOutlinedIcon className="cards__icon-svg" />}
              title="Usuario administrador"
              description="Ingresa como usuario administrador para gestionar y administrar tus usuarios de servicios, plan y facturación."
              navigationButton={
                <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
              }
              navigationPath="admin"
            />
          </Row>
          <br />
          <br />
          <Row>
            <CardButtonWithNavButton
              icon={<PersonOutlineOutlinedIcon className="cards__icon-svg" />}
              title="Usuario de servicios"
              description="Ingresa como usuario de servicios para acceder al módulo de contratos."
              navigationButton={
                <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
              }
              navigationPath="serviceUser"
            />
          </Row>
        </Container>
      </Fade>
    </Container>
  );
};
export default Login;
