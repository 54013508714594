import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateFolder from "../../../../../../components/Modals/ModalAssociateFolder";
import ModalAssociateVersion from "../../../../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../../parts/folders/foldersSlice";
import {
  fetchFolderTags,
  getFolderTagsStatus,
} from "../../../../../../parts/folders/folderTagSlice";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";
import { getPartsFromDocument } from "../../../../../../services/documents/documentsRequest";

import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { ChooseReviewRule } from "./ChooseReviewRule";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import LoadingContentInBackground from "../../../../../../components/loadingContentInBackground";
import useReviewDocument from "../hooks/useReviewDocument";
import { cleanStore } from "../../../../../../parts/document/currentDocumentSlice";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
const UploadFirstTime = () => {
  // Constants

  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  //States

  const [selectedDocument, setSelectedDocument] = useState();
  const [documentData, setDocumentData] = useState({});
  const [versionSelected, setVersionSelected] = useState({});
  const [documentSelected, setDocumentSelected] = useState({});
  const [reviewRule, setReviewRule] = useState("");
  const [documentPartList, setDocumentPartList] = useState([]);
  const [isSaveinFolder, setIsSaveinFolder] = useState(false);
  const [ShowDocumentOther, SetDocumentOther] = useState(false);
  const [showDocumentContract, setDocumentContract] = useState(false);
  const [isReviewRule, setIsReviewRule] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [openRuleList, setOpenRuleList] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [isLoadingFetchParts, setIsLoadingFetchParts] = useState(false);
  // Modals

  const [openModal, setOpenModal] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [documentFieldData, setDocumentFieldData] = useState({});
  const [openModalFolder, setOpenModalFolder] = useState(false);
  const [isOpenModalInfoRulesNotFound, setIsOpenModalInfoRulesNotFound] =
    useState(false);
  const [isOpenModalLimitPlan, setIsOpenModalLimitPlan] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);

  //Hooks

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const statusTags = useSelector(getFolderTagsStatus);
  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const companyPlan = useSelector(getCompanyPlan);
  const schema = isSaveinFolder
    ? yup.object().shape({
        owner: yup
          .string()
          .required("*Este campo es obligatorio")
          .max(250, "*Máximo 250 caracteres permitidos"),
        name: yup.string().required("*Este campo es obligatorio"),
      })
    : yup.object().shape({
        owner: yup
          .string()
          .required("*Este campo es obligatorio")
          .max(250, "*Máximo 250 caracteres permitidos"),
      });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    idProcess,
    isLoading,
    isLoadingInBackground,
    setIsLoadingInBackground,
    allowBackgroundOptions,
    listRules,
    notFoundRulesMessage,
    moduleName,
    isEmptyDocumentLoad,
    setIsEmptyDocumentLoad,
    isEmptyFolderSelected,
    setIsEmptyFolderSelected,
    responseDataError,
    handleRequest,
    handleReviewOtherDocuments,
  } = useReviewDocument({
    selectedDocument: selectedDocument,
    documentData: documentData,
    versionSelected: versionSelected,
    reviewRule: reviewRule,
    documentSelected: documentSelected,
    replaceRedirectionUrls: true,
    modals: {
      setOpenModalSave,
      setIsOpenModalLimitPlan,
      setIsOpenModalError,
      setIsOpenModalInfoRulesNotFound,
    },
  });

  //handlers

  const handleClose = () => {
    setOpenModalFolder(false);
  };

  const handleModalSave = (data) => {
    setDocumentFieldData(data);
    if (!documentData.name && !documentSelected?.name) {
      setIsEmptyDocumentLoad(true);
      return;
    }
    if (isStoragePlanLimit) {
      setIsOpenModalLimitPlan(true);
      return;
    }
    if (
      documentSelected?.fileType === codesDocumentType.contract._id ||
      documentSelected?.fileType === codesDocumentType.draft._id
    ) {
      handleRequest(data);
      return;
    }
    setOpenModalSave(true);
  };

  const handleSelectChange = (event) => {
    setSelectedDocument(event.target.value);

    if (event.target.value === "Contrato") {
      setDocumentContract(true);
      SetDocumentOther(false);
      Mixpanel.track("Revisar contrato ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    } else if (event.target.value === "Otro") {
      Mixpanel.track("Revisar Otro ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      setDocumentContract(false);
      SetDocumentOther(true);
    } else {
      setDocumentContract(false);
      SetDocumentOther(false);
    }
    setDocumentData({});
    setDocumentSelected({});
  };

  const handleClickRule = (event) => {
    setOpenRuleList(true);
  };

  const handleCloseTagReviewRule = () => {
    setIsReviewRule(false);
    setReviewRule(null);
  };

  const checkDocumentSelected = () => {
    if (documentData.name && !documentSelected.name) {
      return false;
    } else if (!documentData.name && documentSelected.name) {
      return false;
    } else {
      return true;
    }
  };
  const handleProccesDocumentPartsExtraction = async ({
    file,
    filenameInBucket,
    bucketName,
  }) => {
    try {
      const company = localStorage.getItem("company");
      const partsExtractionObj = {
        file,
        filenameInBucket,
        bucketName,
        company,
      };
      setIsLoadingFetchParts(true);
      const service = await getPartsFromDocument(partsExtractionObj);

      if (
        service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        service?.data?.success
      ) {
        setDocumentPartList(service.data?.responseMessage?.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingFetchParts(false);
    } finally {
      setIsLoadingFetchParts(false);
    }
  };

  // UseEffects

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setDisableSave(true);
      }
    }
  }, [companyPlan]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (foldersStatus === "fetch") {
          dispatch(fetchAllFolders());
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [dispatch, foldersStatus]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  useEffect(() => {
    dispatch(cleanStore());
  }, []);

  if (!openRuleList) {
    return (
      <Container fluid>
        {isLoading && !isLoadingInBackground && <LoadingContent />}

        {isLoadingInBackground && !isLoading && (
          <LoadingContentInBackground
            actions={allowBackgroundOptions}
            open={isLoadingInBackground}
            setIsOpen={setIsLoadingInBackground}
            moduleName={moduleName}
            processId={idProcess}
          />
        )}
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={"Cargar documento para primera revisión"} />
        </Row>
        <Row md={4}>
          <Form onSubmit={handleSubmit(handleRequest)}>
            <Form.Group>
              <Form.Label className="form__label">
                Tipo de documento<span style={{ color: "red" }}>{"* "}</span>
              </Form.Label>
              <Form.Select
                className="label"
                size="lg"
                as="select"
                placeholder="Tipo de documento"
                value={selectedDocument}
                bsPrefix={
                  errors?.firstReview
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("firstReview", {
                  required: {
                    value: true,
                    message: "*Este campo es obligatorio",
                  },
                })}
                onChange={handleSelectChange}
              >
                <option className="label" value="" disabled={false}>
                  Tipo de documento
                </option>
                <option className="label" value="Contrato">
                  Contrato
                </option>
                <option className="label" value="Otro">
                  Otro
                </option>
              </Form.Select>
            </Form.Group>
            <span className="caption custom-input__error">
              {errors?.firstReview?.message}
            </span>
          </Form>
        </Row>

        {ShowDocumentOther && (
          <>
            <br />
            <Row>
              <h1 className="form__label">Seleccione una regla de revisión</h1>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={handleClickRule}
                >
                  Escoger regla de revisión
                </Button>
              </Col>
              {isReviewRule && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${reviewRule?.name || ""}`}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        handleCloseTagReviewRule();
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}

        {showDocumentContract && (
          <>
            <br />
            <Row style={{ alignItems: "center" }}>
              <Col md={"auto"}>
                <h1 className="form__label">
                  Cargue un documento en formato tipo .docx y .pdf
                </h1>
              </Col>
              <Col xs={"auto"}>
                <PopoverInfo
                  section={{
                    description:
                      "Te recordamos que la revisión de los documentos funcionará mejor si tienen una estructura de contratos.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<FolderOutlinedIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModalVersion(true);
                    Mixpanel.track("Revisar Doc de LegalAI ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Seleccionar documento de Legal AI
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModal(true);
                    Mixpanel.track("Revisar cargar documento ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Cargar documento
                </Button>
              </Col>
              {documentData.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentData.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setIsSaveinFolder(false);
                        setDocumentData({});
                      }}
                    />
                  </span>
                </Col>
              )}
              {documentSelected?.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentSelected.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setIsSaveinFolder(false);
                        setDocumentSelected({});
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}
        {ShowDocumentOther && (
          <>
            <br />
            <Row>
              <h1 className="form__label">
                Cargue un documento en formato tipo .pdf
              </h1>
            </Row>
            <Row>
              <Col xs={"auto"}>
                <Button
                  disabled={!isReviewRule}
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    setOpenModal(true);
                    Mixpanel.track("Revisar cargar documento ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Cargar documento
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  disabled={!isReviewRule}
                  variant="contained"
                  startIcon={<FolderOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setOpenModalVersion(true);
                    Mixpanel.track("Revisar Doc de LegalAI ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Seleccionar documento de Legal AI
                </Button>
              </Col>
              {documentData.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentData.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setDocumentData({});
                      }}
                    />
                  </span>
                </Col>
              )}
              {documentSelected?.name && (
                <Col
                  xs={"auto"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="caption custom-badges__disabled">
                    {`${documentSelected.name} `}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => {
                        setDocumentSelected({});
                      }}
                    />
                  </span>
                </Col>
              )}
            </Row>
          </>
        )}
        <Form onSubmit={handleSubmit(handleRequest)}>
          {showDocumentContract || ShowDocumentOther ? (
            <>
              <br />

              {showDocumentContract && (
                <>
                  <Row className="heading" style={{ alignItems: "center" }}>
                    <Col xs={"auto"}>
                      <h1 className="form__label">
                        ¿Qué rol contractual representas?
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <Form.Group>
                        {isLoadingFetchParts ? (
                          <CustomToolTip
                            direction="right"
                            message={
                              <Row className="align-items-center">
                                <Col xs="auto">
                                  {" "}
                                  <Spinner
                                    animation="border"
                                    className="spinner__iaColor"
                                  />
                                </Col>

                                <Col xs="auto">
                                  <p className="caption p-2 gradient-text">
                                    Identificando roles contractuales con
                                    nuestra IA
                                  </p>
                                </Col>
                              </Row>
                            }
                          >
                            <Form.Select
                              id={"field-documentType-legal"}
                              {...register("represented", {
                                required: {
                                  value: true,
                                  message: "*Este campo es obligatorio",
                                },
                              })}
                              disabled
                              className="label"
                              defaultValue={""}
                            >
                              <option
                                value=""
                                disabled
                                defaultValue
                                className="label"
                              >
                                Rol contractual
                              </option>
                            </Form.Select>
                          </CustomToolTip>
                        ) : (
                          <Form.Select
                            id={"field-documentType-legal"}
                            {...register("represented", {
                              required: {
                                value: true,
                                message: "*Este campo es obligatorio",
                              },
                            })}
                            className="label"
                            defaultValue={""}
                            onChange={(e) => {
                              setValue("represented", e.target.value);
                              setValue("otherRepresented", "");
                            }}
                          >
                            <option
                              value=""
                              disabled
                              defaultValue
                              className="label"
                            >
                              Rol contractual
                            </option>

                            {documentPartList.map((part, index) => {
                              return (
                                <option
                                  value={part}
                                  className="label"
                                  key={`${part}${index}`}
                                >
                                  {part}
                                </option>
                              );
                            })}
                            <option value="OTRO" className="label">
                              Otro
                            </option>
                          </Form.Select>
                        )}
                        <div className="caption custom-input__error">
                          {errors?.documentType?.message}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {watch("represented") === "OTRO" && (
                      <Col md={4}>
                        <Form.Group>
                          <Form.Control
                            {...register("otherRepresented", {
                              required: {
                                value: true,
                                message: "*Este campo es obligatorio",
                              },
                            })}
                            placeholder={"Rol contractual"}
                            style={{
                              border: "0.1rem solid #c5c7d0",
                            }}
                            bsPrefix={
                              errors?.otherRepresented
                                ? "input-group-container__no-icon-error label"
                                : "input-group-container__no-icon label"
                            }
                          />
                          <div className="caption custom-input__error">
                            {errors?.otherRepresented?.message}
                          </div>
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <Row className="heading" style={{ alignItems: "center" }}>
                <Col xs={"auto"}>
                  <h1 className="form__label">
                    ¿De quién proviene está versión?
                  </h1>
                </Col>
                <Col xs={"auto"}>
                  <PopoverInfo
                    section={{
                      description:
                        "Con este nombre en un futuro podrás saber de dónde vino esta versión.",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-5">
                    <Form.Control
                      maxLength={251}
                      {...register("owner")}
                      placeholder="Transportador - Área Jurídica - Camila Pérez"
                      bsPrefix={
                        errors.owner
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.owner?.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : null}
          {isSaveinFolder && showDocumentContract && (
            <>
              <Row className="heading" style={{ alignItems: "center" }}>
                <Col xs={"auto"}>Nombre de documento</Col>
                <Col xs={"auto"}>
                  <PopoverInfo
                    section={{
                      description:
                        "El nombre que pongas en el archivo es el que será utilizado para el almacenamiento. Por lo tanto, no se verá reflejado en el documento final",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-5">
                    <Form.Control
                      {...register("name")}
                      placeholder="Documento_1_ver"
                      bsPrefix={
                        errors.name
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.name?.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="heading">
                Selecciona la carpeta para almacenar del documento
              </Row>
              <Row style={{ padding: "2% 0 3% 0" }}>
                <Col md={4}>
                  <Button
                    variant="contained"
                    startIcon={<FolderOutlinedIcon />}
                    className="custom-input__button__secondary-color"
                    onClick={() => {
                      setOpenModalFolder(true);
                    }}
                  >
                    Seleccionar carpeta en Legal AI
                  </Button>
                </Col>
                {versionSelected.name && (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span className="caption custom-badges__disabled">
                      {`${versionSelected.name} `}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => {
                          setVersionSelected({});
                        }}
                      />
                    </span>
                  </Col>
                )}
              </Row>
            </>
          )}
          {isSaveinFolder && showDocumentContract ? (
            <Row>
              <Col md={2}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  type="submit"
                  variant="contained"
                  className={
                    watch("owner") === "" ||
                    watch("name") === "" ||
                    checkDocumentSelected()
                      ? "custom-input__button__disabled"
                      : "custom-input__button__primary-color"
                  }
                  disabled={
                    watch("owner") === "" ||
                    watch("name") === "" ||
                    checkDocumentSelected()
                  }
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          ) : (
            <Row>
              {showDocumentContract ? (
                <Col xs={"auto"}>
                  <Button
                    type="button"
                    variant="contained"
                    className={
                      watch("owner") === "" ||
                      watch("represented") === "" ||
                      (watch("represented") === "OTRO" &&
                        watch("otherRepresented") === "") ||
                      (!documentData.name && !documentSelected.name)
                        ? "custom-input__button__disabled"
                        : "custom-input__button__primary-color"
                    }
                    onClick={handleSubmit(handleModalSave)}
                    endIcon={<ArrowForwardIosIcon />}
                    disabled={
                      watch("owner") === "" ||
                      watch("represented") === "" ||
                      (watch("represented") === "OTRO" &&
                        watch("otherRepresented") === "") ||
                      (!documentData.name && !documentSelected.name)
                    }
                  >
                    Continuar
                  </Button>
                </Col>
              ) : (
                ShowDocumentOther && (
                  <Col md={2}>
                    <Button
                      type="button"
                      variant="contained"
                      className={
                        watch("owner") === "" ||
                        (!documentData.name && !documentSelected?.name) ||
                        !isReviewRule
                          ? "custom-input__button__disabled"
                          : "custom-input__button__primary-color"
                      }
                      onClick={handleSubmit(handleReviewOtherDocuments)}
                      endIcon={<ArrowForwardIosIcon />}
                      disabled={
                        watch("owner") === "" ||
                        (!documentData.name && !documentSelected?.name) ||
                        !isReviewRule
                      }
                    >
                      Revisar
                    </Button>
                    {/* {isFetchingInfoReview ? (
                      <CustomToolTip
                        direction="left"
                        message="Estamos procesando una solicitud, puedes intentar de nuevo en breve."
                      >
                        <Button
                          type="button"
                          variant="contained"
                          className={
                            watch("owner") === "" ||
                            (!documentData.name && !documentSelected?.name) ||
                            !isReviewRule
                              ? "custom-input__button__disabled"
                              : "custom-input__button__primary-color"
                          }
                          endIcon={<ArrowForwardIosIcon />}
                          disabled
                        >
                          Revisar
                        </Button>
                      </CustomToolTip>
                    ) : (
                    )} */}
                  </Col>
                )
              )}
            </Row>
          )}
        </Form>

        <ModalUploadDocs
          message={
            showDocumentContract
              ? "El archivo que estas a punto de subir debe estar en formato .docx y .pdf"
              : "El archivo que estas a punto de subir debe estar en formato .pdf"
          }
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={() => {
            setOpenModal(false);
          }}
          onClose={() => {
            setOpenModal(false);
          }}
          open={openModal}
          documentData={documentData}
          setDocumentData={(file) => {
            if (!file?.name && !file?.filename) return;
            if (showDocumentContract) {
              setValue("represented", "");
              handleProccesDocumentPartsExtraction({ file });
            }

            setDocumentData(file);
            setDocumentSelected({});
          }}
          typeDocument={showDocumentContract ? "Contrato" : "Otro"}
          title={"Selecciona el documento a revisar"}
          messageContractValidation={
            "Formato incompatible. Recuerd​a ​cargar documentos en formato .docx y .pdf"
          }
          noStorageAvailable={disableSave}
        />
        <ModalDecision
          title={"Guardar en tus carpetas"}
          message={"¿Quieres almacenar este archivo entre tus carpetas?"}
          open={openModalSave}
          onClose={setOpenModalSave}
          agreeText="Almacenar en carpetas"
          disagreeText="No almacenar"
          isDisabledAgree={isStoragePlanLimit}
          handleAgree={() => {
            if (isStoragePlanLimit) return;
            setIsSaveinFolder(true);
            setOpenModalSave(false);
          }}
          handleDisagree={() => handleRequest(documentFieldData)}
        />
        <ModalAssociateFolder
          type="folder"
          open={openModalFolder}
          setDataSelected={setVersionSelected}
          close={handleClose}
        />

        <ModalWarningForm
          title={"Advertencia"}
          description={"No se ha cargado un documento para hacer una revisón"}
          open={isEmptyDocumentLoad}
          onClose={() => setIsEmptyDocumentLoad(false)}
        />
        <ModalWarningForm
          title={"Advertencia"}
          description={"No se ha seleccionado un carpeta para almacenar"}
          open={isEmptyFolderSelected}
          onClose={() => setIsEmptyFolderSelected(false)}
        />
        <ModalInfo
          title={"Error revisando el documento"}
          open={isOpenModalError}
          responseData={responseDataError}
          confirmationText="Aceptar"
          handleCloseDefault={() => setIsOpenModalError(false)}
          onClose={() => setIsOpenModalError(false)}
        />
        <ModalInfo
          title={"Información de interés"}
          responseData={notFoundRulesMessage}
          onClose={() => setIsOpenModalInfoRulesNotFound(false)}
          open={isOpenModalInfoRulesNotFound}
          buttonClose={true}
        />
        <ModalDecision
          title={"Límite de almacenamiento"}
          message={
            "En el momento no está disponible la funcionalidad de guardado, porque la compañía ha alcanzado el máximo de su plan. Comunícate con el usuario administrador para adaptar el plan. ¿Deseas continuar de todas formas?"
          }
          agreeText={"Continuar"}
          disagreeText={"Cancelar"}
          handleDisagree={() => {
            setIsOpenModalLimitPlan(false);
          }}
          handleAgree={handleRequest}
          onClose={() => {
            setIsOpenModalLimitPlan(false);
          }}
          open={isOpenModalLimitPlan}
          type={"warning"}
        />
        <ModalAssociateVersion
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={(file) => {
            setOpenModalVersion(false);
            setDocumentSelected(file);
            handleProccesDocumentPartsExtraction({
              filenameInBucket: file.filenameInBucket,
              bucketName: file.bucketName,
            });
            setDocumentData({});
          }}
          onClose={() => {
            setOpenModalVersion(false);
          }}
          open={openModalVersion}
          folders={folders}
          versionSelected={documentSelected}
          title="Asociar documento"
          filterExtensionsDocument={["pdf"]}
        />
      </Container>
    );
  } else {
    return (
      <ChooseReviewRule
        openRuleList={openRuleList}
        setOpenRuleList={setOpenRuleList}
        ruleList={listRules}
        reviewRule={reviewRule}
        setReviewRule={setReviewRule}
        setIsReviewRule={setIsReviewRule}
      />
    );
  }
};

export default UploadFirstTime;
