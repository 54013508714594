import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { Col, Container, Form, Row } from "react-bootstrap";
import {
  getAllBlankSpaces,
  getFillBlankSpaces,
} from "../../utils/countBlankSpaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { flashItemSelected } from "../../utils/ScrollDocItems";
import changeDefaultBlankSpaceName from "../../utils/changeDefaultBlankSpaceName";

export const FillBlankSpaceClause = ({
  clause,
  indexClause,
  setCurrentDocClauses,
  contentDocumentContainerRef,
}) => {
  const [isCompleteClause, setIsCompleteClause] = useState(false);

  useEffect(() => {
    if (clause) {
      const fillBlankSpaces = getFillBlankSpaces({ clause });
      const allBlankSpaces = getAllBlankSpaces({ clause });

      if (fillBlankSpaces > 0) {
        if (fillBlankSpaces / allBlankSpaces === 1 || allBlankSpaces === 0) {
          setIsCompleteClause(true);
        } else {
          setIsCompleteClause(false);
        }
      } else {
        setIsCompleteClause(false);
      }
    }
  }, [clause]);

  const handleFillBlankspaces = (
    textHtml,
    { name, value, id },
    originalValue
  ) => {
    if (!textHtml || !name) return "";
    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    //Validamos que exista la data para cambiar su valor
    const blankSpaceElement =
      htmlElement?.querySelector(`[data-blank-space="${name}"]`) ??
      htmlElement?.querySelector(`[data-blank-space="blankSpace-${id}"]`);
    if (blankSpaceElement) {
      blankSpaceElement.innerText = value ? `¬${value}¬` : `¬${originalValue}¬`;
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(
      /<\/?(html|head|body)>/gi,
      ""
    );

    return cleanedString;
  };
  const handleBlankspacesClause = ({
    text,
    blankSpace: { name, value, id },
    clauseId,
    originalValue,
  }) => {
    const newText = handleFillBlankspaces(
      text,
      { name, value, id },
      originalValue
    );
    setCurrentDocClauses((prevClauses) => {
      const newClausesList = prevClauses.map((clause) => {
        if (clause.clause._id === clauseId) {
          return {
            ...clause,
            clause: {
              ...clause.clause,
              text: newText,
              blankspaces: clause.clause.blankspaces.map(
                (blankspace, index) => {
                  if (blankspace.name === name) {
                    return { ...blankspace, value: value };
                  } else return blankspace;
                }
              ),
            },
          };
        } else return clause;
      });
      return newClausesList;
    });
  };

  const handleBlankspacesSubclause = ({
    text,
    blankSpace: { name, value, id },
    index,
    clauseId,
    originalValue,
  }) => {
    const newText = handleFillBlankspaces(
      text,
      { name, value, id },
      originalValue
    );
    setCurrentDocClauses((prevInfo) => {
      const formatedClausesList = prevInfo.map((clause) => {
        if (clause.clause._id === clauseId) {
          const newClause = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map(
                (subclause, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...subclause,
                      subclause: {
                        ...subclause.subclause,
                        text: newText,
                        blankspaces: subclause.subclause.blankspaces.map(
                          (blankspace, index) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            } else return blankspace;
                          }
                        ),
                      },
                    };
                  } else return subclause;
                }
              ),
            },
          };
          return newClause;
        } else {
          return clause;
        }
      });
      return formatedClausesList;
    });
  };

  const handleBlankspacesSubclauseChild = ({
    text,
    blankSpace: { name, value, id },
    indexSub,
    indexChild,
    clauseId,
    originalValue,
  }) => {
    const newText = handleFillBlankspaces(
      text,
      { name, value, id },
      originalValue
    );
    setCurrentDocClauses((prev) => {
      const formatedClausesList = prev.map((clause) => {
        if (clause.clause._id === clauseId) {
          const updateClause = {
            ...clause,
            clause: {
              ...clause.clause,
              subclauses: clause.clause.subclauses.map((sub, index) => {
                if (index === indexSub) {
                  return {
                    ...sub,
                    subclause: {
                      ...sub.subclause,
                      childs: sub.subclause.childs.map((child, index2) => {
                        if (index2 === indexChild) {
                          return {
                            ...child,
                            text: newText,
                            blankspaces: child.blankspaces.map(
                              (blankspace, index) => {
                                if (blankspace.name === name) {
                                  return { ...blankspace, value: value };
                                } else return blankspace;
                              }
                            ),
                          };
                        } else return child;
                      }),
                    },
                  };
                } else return sub;
              }),
            },
          };
          return updateClause;
        } else return clause;
      });
      return formatedClausesList;
    });
  };

  const handleBlankspacesParagraphs = ({
    text,
    blankSpace: { name, value, id },
    index,
    clauseId,
    originalValue,
  }) => {
    const newText = handleFillBlankspaces(
      text,
      { name, value, id },
      originalValue
    );
    setCurrentDocClauses((prev) => {
      const formatedClausesList = prev.map((clause) => {
        if (clause.clause._id === clauseId) {
          const prueba = {
            ...clause,
            clause: {
              ...clause.clause,
              paragraphs: clause.clause.paragraphs.map(
                (paragraph, indexSubclause) => {
                  if (index === indexSubclause) {
                    return {
                      ...paragraph,
                      paragraph: {
                        ...paragraph.paragraph,
                        text: newText,
                        blankspaces: paragraph.paragraph.blankspaces.map(
                          (blankspace, index) => {
                            if (blankspace.name === name) {
                              return { ...blankspace, value: value };
                            } else return blankspace;
                          }
                        ),
                      },
                    };
                  } else return paragraph;
                }
              ),
            },
          };
          return prueba;
        } else {
          return clause;
        }
      });
      return formatedClausesList;
    });
  };

  const getBlankSpaceDataAttribute = ({ name, id, blankSpaceIndex }) => {
    const htmlElement = contentDocumentContainerRef?.current;
    const waysToGetBlankSpaceDataAttribute = [
      `[data-blank-space="blankSpace-${id}"]`,
      `[data-blank-space="blankSpace-${blankSpaceIndex}"]`,
      `[data-blank-space="${name}"]`,
    ];
    for (const way of waysToGetBlankSpaceDataAttribute) {
      if (htmlElement?.querySelector(way)) {
        return way;
      }
    }
  };

  return (
    <Accordion
      className="blankspaces__box"
      defaultExpanded
      id={`c.${indexClause + 1}.0.0`}
    >
      <AccordionSummary>
        <Container
          fluid
          className="container-blankspaces__box"
          style={{ margin: "0 !important" }}
        >
          <Row>
            <Col xs={10} className="heading">
              {clause.title}
            </Col>
            <Col xs={1}>
              {isCompleteClause ? (
                <CheckCircleOutlineIcon fontSize="large" />
              ) : (
                <InfoOutlinedIcon
                  className="heading__error-color"
                  fontSize="large"
                />
              )}
            </Col>
            <Col xs={1}>
              <KeyboardArrowDownIcon fontSize="large" />
            </Col>
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        {clause?.blankspaces && (
          <Container fluid>
            <Row>
              {clause.blankspaces.map((blankspace, clauseBlankSpaceIndex) => (
                <FillableBlankSpace
                  key={`${clause?._id}-${blankspace?.index}`}
                  blankSpace={blankspace}
                  getBlankSpaceDataAttribute={getBlankSpaceDataAttribute}
                  handleBlankSpaces={(originalValue, onChangeEvent) =>
                    handleBlankspacesClause({
                      text: clause.text,
                      blankSpace: {
                        name: blankspace.name,
                        value: onChangeEvent.target.value,
                        id: blankspace?.id ?? blankspace?.index,
                      },
                      clauseId: clause._id,
                      originalValue,
                    })
                  }
                  idElementForFlashItemSelected={`content-clauses-${indexClause}`}
                  blankSpaceIndex={clauseBlankSpaceIndex}
                />
              ))}
            </Row>
            <Row>
              {clause?.subclauses?.map((subclause, index) => (
                <div key={subclause?.subclause?._id}>
                  {subclause?.subclause?.blankspaces?.map((blankspace, subClauseBlankSpaceIndex) => (
                    <FillableBlankSpace
                      key={`blankspace-subclause-${blankspace.index}`}
                      blankSpace={blankspace}
                      getBlankSpaceDataAttribute={getBlankSpaceDataAttribute}
                      handleBlankSpaces={(originalValue, onChangeEvent) =>
                        handleBlankspacesSubclause({
                          text: subclause.subclause.text,
                          blankSpace: {
                            name: blankspace.name,
                            value: onChangeEvent.target.value,
                            id: blankspace?.id ?? blankspace?.index,
                          },
                          index,
                          clauseId: clause._id,
                          originalValue,
                        })
                      }
                      idElementForFlashItemSelected={`content-subclauses-${indexClause}.${index}`}
                      blankSpaceIndex={subClauseBlankSpaceIndex}
                    />
                  ))}

                  {subclause?.subclause?.childs?.map((child, index2) => (
                    <div key={`${child.title}`}>
                      {child.blankspaces.map((blankspace, childBlankSpaceIndex) => (
                        <FillableBlankSpace
                          key={`blankspace-subclause-childs-${blankspace.index}`}
                          blankSpace={blankspace}
                          getBlankSpaceDataAttribute={
                            getBlankSpaceDataAttribute
                          }
                          handleBlankSpaces={(originalValue, onChangeEvent) =>
                            handleBlankspacesSubclauseChild({
                              text: child.text,
                              blankSpace: {
                                name: blankspace.name,
                                value: onChangeEvent.target.value,
                                id: blankspace?.id ?? blankspace?.index,
                              },
                              indexSub: index,
                              indexChild: index2,
                              clauseId: clause._id,
                              originalValue,
                            })
                          }
                          idElementForFlashItemSelected={`content-subclauses-childs-${indexClause}.${index}.${index2}`}
                          blankSpaceIndex={childBlankSpaceIndex}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </Row>
            <Row>
              {clause?.paragraphs?.map((paragraph, index) => (
                <div key={`${paragraph?.paragraph?._id}`}>
                  {paragraph?.paragraph?.blankspaces?.map((blankspace, paragraphBlankSpaceIndex) => (
                    <FillableBlankSpace
                      key={`blankspace-paragraph-${blankspace.index}`}
                      blankSpace={blankspace}
                      getBlankSpaceDataAttribute={getBlankSpaceDataAttribute}
                      handleBlankSpaces={(originalValue, onChangeEvent) =>
                        handleBlankspacesParagraphs({
                          text: paragraph.paragraph.text,
                          blankSpace: {
                            name: blankspace.name,
                            value: onChangeEvent.target.value,
                            id: blankspace?.id ?? blankspace?.index,
                          },
                          index,
                          clauseId: clause._id,
                          originalValue,
                        })
                      }
                      idElementForFlashItemSelected={`content-paragraphs-${indexClause}.${index}`}
                      blankSpaceIndex={paragraphBlankSpaceIndex}
                    />
                  ))}
                </div>
              ))}
            </Row>
          </Container>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const FillableBlankSpace = ({
  blankSpace,
  getBlankSpaceDataAttribute,
  handleBlankSpaces,
  idElementForFlashItemSelected,
  blankSpaceIndex,
}) => {
  return (
    <article fluid className="d-flex flex-column gap-3 py-3">
      <Row>
        <Col>
          <p className="heading__subtitle">
            {changeDefaultBlankSpaceName(blankSpace?.name)}
          </p>
          {blankSpace?.description && (
            <p className="label__description">{blankSpace?.description}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Form.Group className="my-1">
          <Form.Control
            placeholder={`Escribir aquí`}
            defaultValue={blankSpace.value}
            bsPrefix={"input-group-container__no-icon label"}
            onChange={(event) => {
              let originalValue = `ESPACIO #${
                (blankSpace?.id ?? blankSpace?.index) || ""
              }`;
              handleBlankSpaces(originalValue, event);
            }}
            onFocus={() => {
              const nameBlankspace = getBlankSpaceDataAttribute({
                name: blankSpace.name,
                id: blankSpace.id ?? blankSpace.index,
                blankSpaceIndex: blankSpaceIndex + 1,
              });
              flashItemSelected(
                nameBlankspace,
                "query",
                idElementForFlashItemSelected
              );
            }}
          />
        </Form.Group>
      </Row>
    </article>
  );
};
