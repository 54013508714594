import { objectDeepEqual } from "./object.utils";

/** BEGIN TYPE DEFINITIONS  */
/**
 * @typedef {Object} ItemFrequencyType
 * @property {any} item The item that is repeated or not.
 * @property {number} frequency The frequency that the item is repeated.
 * @property {Array<number>} occurrencesIndex The indexes where the item is repeated in the original array.
 */
/** END TYPE DEFINITIONS */

/**
 * Get the items that are repeated in the array.
 *
 * @param {Array<string>} array The array to get the repeated items.
 * @returns {Array<ItemFrequencyType>} The array of objects with the items and their frequency.
 */
export function getItemsFrequency(array = []) {
  /** @type {Array<ItemFrequencyType>} */
  const itemsFrequency = [];

  for (const [key, item] of array.entries()) {
    const itemFrequency = findItemOnItemsFrequency(item, itemsFrequency);

    if (itemFrequency) {
      itemFrequency.frequency++;
      itemFrequency.occurrencesIndex.push(key);
    } else {
      itemsFrequency.push({ item, frequency: 1, occurrencesIndex: [key] });
    }
  }

  return itemsFrequency;
}

/**
 * Find an item in the array based on its frequency.
 *
 * @param {any} item The item to find in the array.
 * @param {Array<ItemFrequencyType>} itemsFrequency The array of objects with the items and their frequency.
 *
 * @returns {ItemFrequencyType | undefined} The item object if found, otherwise undefined.
 */
export function findItemOnItemsFrequency(item = {}, itemsFrequency = []) {
  return itemsFrequency.find((itemFrequency) =>
    objectDeepEqual(itemFrequency.item, item)
  );
}