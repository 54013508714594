import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import { CODES } from "../../../../../../utils/codes";
import { setClearClauseBulk, setDocumentInfoBulk } from "./clauseBulkSlice";
import axios from "axios";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { Buffer } from "buffer";
import {
  selectListInfoForModule,
  updateListBackgroundProcesses,
} from "../../../../../../parts/backgroundProcess/backgroundProcessSlice";
import GetToken from "../../../../../../utils/getToken";
import { env } from "../../../../../../env";
import WorkerBuilder from "../../../../../../workers/worker-builder";
import WorkerGetClausesAndContractType from "../../../../../../workers/getClausesAndContractTypeWorker";
import { v4 as uuidv4 } from "uuid";
import LoadingContentInBackground from "../../../../../../components/loadingContentInBackground";
const CreateClauseBulk = () => {
  //Router
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const params = searchParams.get("from");
  // DATA GET CONTRACT TYPE  BACKGROUND

  const listInfoGetContractTypeBackground = useSelector((state) =>
    selectListInfoForModule(
      state,
      params === "clauses"
        ? "backgroundProcessGetClauses"
        : "backgroundProcessGetContractType"
    )
  );

  const listInfoGetContractTypeBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoGetContractTypeBackground) {
      let currentInfo = {};
      listInfoGetContractTypeBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoGetContractTypeBackgroundRef.current = currentInfo;
    }
  }, [listInfoGetContractTypeBackground]);

  //States
  const [isLoading, setIsLoading] = useState(false);
  const [allowOptions, setAllowOptions] = useState(false);
  const [isOpenModalUploadDoc, setIsOpenModalUploadDoc] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isOpenModalDocumentEmpty, setIsOpenModalDocumentEmpty] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [progress, setProgress] = useState(0);
  const [openFailedSegModal, setOpenFailedSegModal] = useState(false);

  //Worker
  const contractTypeWorkerRef = useRef(null);
  const [moduleName, setModuleName] = useState("");
  const [idProcess, setIdProcess] = useState(null);
  const serviceUrl = env.REACT_APP_API_URL;
  const token = GetToken();
  const { state: locationState } = location;

  useEffect(() => {
    if (!["clauses", "contractTypes"].includes(params)) navigate("/");
    dispatch(setClearClauseBulk());
  }, []);

  const schema = yup.object().shape({
    parts: yup.array().of(
      yup.object().shape({
        title: yup.string().required("*Este campo es obligatorio"),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parts", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const handleDeletePart = (idx) => {
    partsRemove(idx);
  };

  const handleCloseModalDocumentEmpty = () => {
    setIsOpenModalDocumentEmpty(false);
  };

  const progressFuntion = (prevProgress) => {
    if (prevProgress >= 90 && prevProgress < 99) {
      return prevProgress + 0.1;
    }
    if (prevProgress >= 99) {
      return 99;
    }
    if (prevProgress === 100) {
      setIsLoading(false);
      return 0;
    }
    const newProgress = prevProgress + 5;
    return newProgress <= 100 ? newProgress : 100;
  };

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        // Actualizar el progreso de manera incremental
        setProgress(progressFuntion);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isLoading]);

  const handleDestructureDocument = async (data) => {
    try {
      setIsLoading(true);
      setAllowOptions(false);
      const idProcess = uuidv4();
      setModuleName(
        params === "clauses"
          ? "backgroundProcessGetClauses"
          : "backgroundProcessGetContractType"
      );
      setIdProcess(idProcess);

      if (!documentData.name) {
        setIsOpenModalDocumentEmpty(true);
        setIsLoading(false);
        return;
      }
      const parts = {
        partA: data.parts[0].title,
        partB: data.parts[1].title,
        partC: data.parts[2]?.title || null,
        partD: data.parts[3]?.title || null,
      };
      const payloadToken = JSON.parse(localStorage.payloadToken);

      let dataFile = documentData;
      if (documentData?.type !== "application/pdf") {
        const convertDoc = await convertToPdfService(documentData, "file");
        const docView = convertDoc.data.responseMessage.data.buffer.data;
        const bf = Buffer.from(docView);
        const name = documentData?.name.substring(
          0,
          documentData?.name.lastIndexOf(".")
        );
        dataFile = new File([bf], name + ".pdf", {
          type: "application/pdf",
        });
      }
      setAllowOptions(true);
      if (contractTypeWorkerRef.current) {
        contractTypeWorkerRef.current.postMessage({
          token,
          serviceUrl,
          idProcess,
          files: dataFile,
          parts,
          company: payloadToken.company,
        });
        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,

            module:
              params === "clauses"
                ? "backgroundProcessGetClauses"
                : "backgroundProcessGetContractType",
            updateData: {
              id: idProcess,
              nameFile: documentData.name,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                url: `${location.pathname}${location.search}`,

                state: {
                  processId: idProcess,
                },
              },

              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setResponseData({
          status: 500,
          data: {
            responseMessage:
              "El proceso esta tomando más tiempo de lo normal, por favor intentelo más tarde.",
          },
        });
        setOpenFailedSegModal(true);
      }
      console.log(error);
    }
  };

  const handleProcessDestructureDocument = (response) => {
    setIsLoading(false);
    if (
      [CODES.COD_RESPONSE_HTTP_OK, CODES.COD_RESPONSE_HTTP_CREATED].includes(
        response.status
      )
    ) {
      dispatch(
        setDocumentInfoBulk({
          parts: response.parts,
          title: response.data?.responseMessage?.title,
          clauses: response.data?.responseMessage?.clauses,
        })
      );
      navigate("result");
    } else {
      setResponseData(response);
      setIsOpenModal(true);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  // Extract clause or contract type from file background

  const handleMessageExtractClausesOrContractTypes = useCallback(
    async (event) => {
      try {
        const { data, idProcess } = event.data;
        const { success } = data;

        if (success) {
          dispatch(
            updateListBackgroundProcesses({
              id: idProcess,
              type: "UPDATE",
              module:
                params === "clauses"
                  ? "backgroundProcessGetClauses"
                  : "backgroundProcessGetContractType",
              updateData: {
                isFetchingInfo: false,
                finishProcessing: true,
                navigationInfo: {
                  ...listInfoGetContractTypeBackgroundRef.current?.[idProcess]
                    ?.navigationInfo,
                  otherInfo: {
                    ...listInfoGetContractTypeBackgroundRef.current?.[idProcess]
                      ?.navigationInfo?.otherInfo,
                    responseGetContractInfo: event.data,
                  },
                },
              },
            })
          );
          setIsLoading(false);
          const userLeft =
            listInfoGetContractTypeBackgroundRef.current?.[idProcess]?.userLeft;

          if (!userLeft) {
            handleProcessDestructureDocument(event.data);
            dispatch(
              updateListBackgroundProcesses({
                type: "DELETE",
                module:
                  params === "clauses"
                    ? "backgroundProcessGetClauses"
                    : "backgroundProcessGetContractType",
                id: idProcess,
              })
            );
          }

          const itemsRef = Object.values(
            listInfoGetContractTypeBackgroundRef.current
          );

          if (!itemsRef.length) {
            contractTypeWorkerRef.current.removeEventListener(
              "message",
              handleMessageExtractClausesOrContractTypes
            );
            contractTypeWorkerRef.current.terminate();
          }
        } else {
          const userLeft =
            listInfoGetContractTypeBackgroundRef.current?.[idProcess]?.userLeft;

          if (!userLeft) {
            handleProcessDestructureDocument(event.data);
          }

          dispatch(
            updateListBackgroundProcesses({
              id: idProcess,
              type: "UPDATE",
              module:
                params === "clauses"
                  ? "backgroundProcessGetClauses"
                  : "backgroundProcessGetContractType",
              updateData: {
                isFetchingInfo: false,
                finishProcessing: false,
                isErrorProcessing: true,
                isErrorProcessingMessage: event.data?.data?.responseMessage,
                navigationInfo: {},
              },
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    []
  );

  const handleMessageRefeExtractClausesOrContractTypes = useRef(
    handleMessageExtractClausesOrContractTypes
  );

  useEffect(() => {
    if (!contractTypeWorkerRef.current) {
      // add worker
      contractTypeWorkerRef.current = new WorkerBuilder(
        WorkerGetClausesAndContractType
      );
    }
    contractTypeWorkerRef.current.removeEventListener(
      "message",
      handleMessageRefeExtractClausesOrContractTypes.current
    );

    contractTypeWorkerRef.current.addEventListener(
      "message",
      handleMessageExtractClausesOrContractTypes
    );
  }, [handleMessageExtractClausesOrContractTypes]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (
        listInfoGetContractTypeBackgroundRef.current?.[idProcess]
          ?.finishProcessing
      ) {
        try {
          const response =
            listInfoGetContractTypeBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.responseGetContractInfo;
          handleProcessDestructureDocument(response);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module:
                params === "clauses"
                  ? "backgroundProcessGetClauses"
                  : "backgroundProcessGetContractType",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoGetContractTypeBackgroundRef, locationState]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && (
        <LoadingContentInBackground
          actions={allowOptions}
          open={isLoading}
          setIsOpen={setIsLoading}
          moduleName={moduleName}
          processId={idProcess}
        />
      )}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={`Crear ${
            params === "clauses" ? "cláusula" : "minuta"
          } desde un contrato`}
        />
      </Row>
      <br />
      <br />
      <Row>
        <Row className="mb-3">
          <label className="heading">
            {`Cargue el contrato en donde están las ${
              params === "clauses" ? "cláusulas" : "minutas"
            }`}
          </label>
        </Row>
        <Row className="mb-4">
          <label className="body-content__disabled">
            Las imágenes del documento cargado no serán tenidas en cuenta por el
            sistema*
          </label>
        </Row>
        <Row xs={"auto"} className="mb-5">
          <Col>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => setIsOpenModalUploadDoc(true)}
            >
              Cargar documento
            </Button>
          </Col>
          {documentData.name && (
            <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
              <span className="caption custom-badges__disabled">
                {`${documentData.name} `}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => {
                    setDocumentData({});
                  }}
                />
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <label className="heading">
            Incorporar designación de las partes del contrato
            <PopoverInfo
              section={{
                description:
                  "Escribe la denominación de las partes sin artículo porque el sistema podrá detectarlos.",
              }}
            />
          </label>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(handleDestructureDocument)}>
            <Form.Group className="mb-3">
              {partsFields.map((item, idx) => {
                return (
                  <Row key={item?.id}>
                    <Col xs={3}>
                      <Form.Control
                        maxLength={50}
                        disabled={!documentData.name}
                        {...register(`parts.${idx}.title`)}
                        placeholder="Ej. Contratista, Contratante, etc."
                        bsPrefix={
                          errors?.parts?.[idx]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                    </Col>
                    {idx > 1 && (
                      <Col xs={1}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => handleDeletePart(idx)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </Button>
                      </Col>
                    )}
                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[idx]?.title?.message}
                    </div>
                  </Row>
                );
              })}
              {partsFields.length < 4 && (
                <Row xs={"auto"} style={{ justifyContent: "left" }}>
                  <Col>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        partsAppend({
                          title: "",
                        });
                      }}
                    >
                      Parte
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.Group>
            <br />
            <br />
            <Form.Group as={Row}>
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<ArticleOutlinedIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                >
                  {`Obtener ${params === "clauses" ? "cláusulas" : "minuta"}`}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Row>

        <ModalUploadDocs
          message={
            "El archivo que estas a punto de subir debe estar en formato PDF o Word."
          }
          agreeText={"Aceptar"}
          disagreeText={"Cancelar"}
          handleAgree={() => {
            setIsOpenModalUploadDoc(false);
          }}
          onClose={() => {
            setIsOpenModalUploadDoc(false);
          }}
          open={isOpenModalUploadDoc}
          documentData={documentData}
          setDocumentData={setDocumentData}
        />

        <ModalWarningForm
          title={"Advertencia"}
          description={
            "No se ha asociado ningún documento a destructurar. Por favor, cargue un documento para poder continuar."
          }
          open={isOpenModalDocumentEmpty}
          onClose={handleCloseModalDocumentEmpty}
        />

        <ModalInfo
          title={`Creación de ${params === "clauses" ? "cláusula" : "minuta"}`}
          responseData={responseData}
          open={isOpenModal}
          onClose={handleClose}
        />

        <ModalInfo
          title={"Ha ocurrido un error procesando el nuevo documento"}
          responseData={responseData}
          open={openFailedSegModal}
          onClose={() => {
            setOpenFailedSegModal(false);
          }}
        />
      </Row>
    </Container>
  );
};

export default CreateClauseBulk;
