import { createSlice } from "@reduxjs/toolkit";

const backgroundProcessSlice = createSlice({
  name: "background processing",
  initialState: {
    backgroundProcesses: {
      backgroundProcessReview: {
        id: null,
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
        list: [],
      },
      backgroundProcessReviewContract: {
        id: null,
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
        list: [],
      },
      backgroundProcessReviewSavedContract: {
        id: null,
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
        list: [],
      },
    },
    listBackgroundProcesses: {
      backgroundProcessReview: {
        processName: "backgroundProcessReview",
        groupName: "Otros documentos",
        items: [],
      },
      backgroundProcessReviewContract: {
        processName: "backgroundProcessReviewContract",
        groupName: "Contratos",
        items: [],
      },
      backgroundProcessReviewSavedContract: {
        processName: "backgroundProcessReviewSavedContract",
        groupName: "Contratos guardados",
        items: [],
      },
      backgroundProcessReportsContract: {
        processName: "backgroundProcessReportsContract",
        groupName: "Informes",
        items: [],
      },
      backgroundProcessResumeContract: {
        processName: "backgroundProcessResumeContract",
        groupName: "Resumen",
        items: [],
      },
      backgroundProcessCreatePartCertificate: {
        processName: "backgroundProcessCreatePartCertificate",
        groupName: "Crear parte desde certificado",
        items: [],
      },

      backgroundProcessGetContractType: {
        processName: "backgroundProcessGetContractType",
        groupName: "Crear minuta desde un contrato",
        items: [],
      },
      backgroundProcessGetClauses: {
        processName: "backgroundProcessGetClauses",
        groupName: "Crear cláusula desde un contrato",
        items: [],
      },
    },
  },
  reducers: {
    setIsFetchingInfoBackgroundProcess: (state, action) => {
      const { module, value, id, nameFile } = action.payload;
      state.backgroundProcesses[module].isFetchingInfo = value;
      state.backgroundProcesses[module].id = id;
      state.backgroundProcesses[module].nameFile = nameFile;
    },
    setFinishProcessingBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].finishProcessing = value;
    },

    setUserLeftBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].userLeft = value;
    },
    setFinishAdditionalInfoBackgroundProcess: (state, action) => {
      const { module, value } = action.payload;
      state.backgroundProcesses[module].navigationInfo = value;
    },
    resetInfoForModule: (state, action) => {
      const { module } = action.payload;

      state.backgroundProcesses[module] = {
        isFetchingInfo: false,
        finishProcessing: false,
        navigationInfo: {},
        userLeft: false,
      };
    },
    resetStatesButInfoForModule: (state, action) => {
      const { module } = action.payload;

      state.backgroundProcesses[module] = {
        isFetchingInfo: false,
        finishProcessing: false,
        userLeft: false,
        navigationInfo: state.backgroundProcesses[module].navigationInfo,
      };
    },
    updateListBackgroundProcesses: (state, action) => {
      const {
        type,
        updateData = null,
        id = null,
        module = null,
      } = action.payload;
      if (type === "ADD") {
        state.listBackgroundProcesses?.[module]?.items.push(updateData);
      } else if (type === "DELETE") {
        const indexItem = state.listBackgroundProcesses?.[
          module
        ]?.items.findIndex((item) => item?.id === id);
        state.listBackgroundProcesses?.[module]?.items.splice(indexItem, 1);
      } else if (type === "UPDATE") {
        const indexItem = state.listBackgroundProcesses?.[
          module
        ]?.items.findIndex((item) => item?.id === id);
        state.listBackgroundProcesses[module].items[indexItem] = {
          ...state.listBackgroundProcesses?.[module]?.items?.[indexItem],
          ...updateData,
        };
      }
    },
  },
});

export const {
  setIsFetchingInfoBackgroundProcess,
  setFinishProcessingBackgroundProcess,
  setFinishAdditionalInfoBackgroundProcess,
  setUserLeftBackgroundProcess,
  resetInfoForModule,
  resetStatesButInfoForModule,
  updateListBackgroundProcesses,
} = backgroundProcessSlice.actions;

export const getListBackgroundProcesses = (state) =>
  state.backgroundProcess.listBackgroundProcesses;

export const getBackgroundProcesses = (state) =>
  state.backgroundProcess.backgroundProcesses;

export const selectIsFetchingInfoForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.isFetchingInfo || false;
};

export const selectUserLeftForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.userLeft || false;
};

export const selectAdditionalInfoForModule = (state, moduleName) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.navigationInfo || {};
};

export const selectAdditionalInfoForFinishedModule = (state) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  const finishedModule = Object.values(backgroundProcesses).find(
    (module) => module?.finishProcessing
  );
  return finishedModule ? finishedModule?.navigationInfo : null;
};

export const setFinishBackgroundProcessingForModule =
  (module, value) => (dispatch) => {
    dispatch(setFinishProcessingBackgroundProcess({ module, value }));
  };

export const setBackgroundProcessingAdditionalInfoForModule =
  (module, value) => (dispatch) => {
    dispatch(setFinishAdditionalInfoBackgroundProcess({ module, value }));
  };

export const resetBackgroundInfoForModule = (module) => (dispatch) => {
  dispatch(resetInfoForModule(module));
};

export const selectIsAnyModuleProcessingFinished = (state) => {
  const backgroundProcesses = getBackgroundProcesses(state);
  for (const module in backgroundProcesses) {
    if (backgroundProcesses[module]?.finishProcessing) {
      return {
        isFinished: true,
        moduleName: module,
        navigationInfo: backgroundProcesses[module].navigationInfo,
        userLeft: backgroundProcesses[module].userLeft,
      };
    }
  }
  return {
    isFinished: false,
    moduleName: "",
    navigationInfo: {},
    userLeft: false,
  };
};
export const selectListInfoForModule = (state, moduleName) => {
  const backgroundProcesses = getListBackgroundProcesses(state);
  return backgroundProcesses[moduleName]?.items || [];
};

export default backgroundProcessSlice.reducer;
