import AddIcon from "@mui/icons-material/Add";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeedIcon from "@mui/icons-material/Feed";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import dateFormat from "dateformat";
import { MDBDataTableV5 } from "mdbreact";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";

import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailsBar from "../../../../../components/DetailsBar/DetailsBar";
import LoadingContent from "../../../../../components/loadingContent";
import ModalAssociateVersion from "../../../../../components/Modals/modalAssociateVersion";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import { ModalListSelectCheck } from "../../../../../components/Modals/modalListCheck";
import ModalUploadDocs from "../../../../../components/Modals/ModalUploadDocs";
import VersionBar from "../../../../../components/VersionBar/versionBar";
import { WordBlobDocument } from "../../../../../components/WordCreator/WordCreator";
import {
  addSignatoryWithoutPart,
  cleanStore,
  setFileType,
  setHeaders,
  setIdDraft,
  setInitialInfoLibrary,
  setName,
  setPartsHeaderText,
  setReports,
  setSelectedClauses,
  setVersionAssociated,
} from "../../../../../parts/document/currentDocumentSlice";
import {
  fetchAllFolders,
  fetchFolderById,
  getFolder,
  getFolders,
  getStatusFolder,
  getStatusFolders,
  setFolderStatus,
} from "../../../../../parts/folders/foldersSlice";
import {
  fetchFolderTags,
  getFolderTagsStatus,
} from "../../../../../parts/folders/folderTagSlice";
import {
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../../../../parts/headings/headingsSlice";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import {
  moveBusinessFolder,
  moveDocumentBusinessFolder,
  updateBusinessFolderlastAccess,
  updateDeletedFolder,
} from "../../../../../services/business_folder/businessFolderService";
import {
  getDocById,
  getFileDocument,
  segmentDocument,
  updateVersionLastAccess,
} from "../../../../../services/documents/documentsRequest";
import {
  createNewVersion,
  updateStateDocument,
} from "../../../../../services/versions/versionRequest";
import { CODES } from "../../../../../utils/codes";
import { codesDocumentType } from "../../../../../utils/codesDocumentType";
import { REPORTS_TYPE } from "../../../../../utils/codesReportsTypes";
import {
  DOCUMENT_STATE,
  FOLDER_STATE,
} from "../../../../../utils/documentStates";
import { onSortHTMLElement } from "../../../../../utils/findComponentHTML";
import { Mixpanel } from "../../../../../utils/mixPanel";
import {
  selectListInfoForModule,
  updateListBackgroundProcesses,
} from "../../../../../parts/backgroundProcess/backgroundProcessSlice";
import GetToken from "../../../../../utils/getToken";
import { env } from "../../../../../env";
import WorkerBuilder from "../../../../../workers/worker-builder";
import { v4 as uuidv4 } from "uuid";
import LoadingContentInBackground from "../../../../../components/loadingContentInBackground";
import WorkerResume from "../../../../../workers/resumeWorker";
import WorkerReports from "../../../../../workers/reportsWorker";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";
import { changeFileExtension } from "../../../../../utils/fileUtils";
import { CopyTextComponent } from "../../../../../components/Text/CopyTextComponent";

const MenuItemComponent = ({
  keyProp,
  index,
  handleClick,
  menuElement,
  menuType,
}) => {
  return (
    <div key={keyProp}>
      <MenuItem
        onClick={() => {
          handleClick(menuElement.selectionClick);
        }}
        style={{ width: "351px" }}
      >
        <ListItemIcon>{menuElement.icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontFamily: "Roboto",
            fontSize: "1.4rem",
            fontWeight: "regular",
            lineHeight: "2rem",
            color: "#676879",
          }}
          primary={menuElement.title}
        />
      </MenuItem>
      {index + 1 === menuType.length ? <></> : <hr />}
    </div>
  );
};

const ViewBusinessFolder = () => {
  /**
   * Tabla
   */
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Último acceso
        </label>,
      ],
      field: "lastAccessAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Alertas asignadas
        </label>,
      ],
      field: "alerts",
    },
  ];
  const rows = [];

  const listReport = [
    REPORTS_TYPE.MILESTONES_AND_DATES,
    REPORTS_TYPE.VALUES,
    REPORTS_TYPE.PARTS,
    REPORTS_TYPE.POLICIES,
    REPORTS_TYPE.OBLIGATIONS,
  ];

  /**
   * Use Navigate
   */
  const navigate = useNavigate();
  const location = useLocation();

  //  worker
  const resumeWorkerRef = useRef(null);
  const reportsWorkerRef = useRef(null);
  const serviceUrl = env.REACT_APP_API_URL;
  const token = GetToken();
  const { state: locationState } = location;

  // DATA RESUME BACKGROUND

  const listInfoResumeBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessResumeContract")
  );

  const listInfoResumeBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoResumeBackground) {
      let currentInfo = {};
      listInfoResumeBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoResumeBackgroundRef.current = currentInfo;
    }
  }, [listInfoResumeBackground]);

  // DATA REPORTS BACKGROUND

  const listInfoReportsBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessReportsContract")
  );

  const listInfoReportsBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoReportsBackground) {
      let currentInfo = {};
      listInfoReportsBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoReportsBackgroundRef.current = currentInfo;
    }
  }, [listInfoReportsBackground]);

  /**
   * Use Selector
   */
  const foldersList = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const folderStatus = useSelector(getStatusFolder);
  const currentFolder = useSelector(getFolder);
  //Tags
  const statusTags = useSelector(getFolderTagsStatus);
  const companyPlan = useSelector(getCompanyPlan);
  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Params
   */
  const { businessFolderId } = useParams();

  /**
   * Use State
   */

  //Folder
  const [actualFolder] = useState({});
  const [selectedFolder, setSelectedFolder] = useState({});
  const [documentData, setDocumentData] = useState({});
  const [versionSelected, setVersionSelected] = useState({});

  //Modals
  const [openModal, setOpenModal] = useState(false);
  const [openModalMove, setOpenModalMove] = useState(false);
  const [openModalDecision, setOpenModalDecision] = useState({
    isOpen: false,
    title: "",
    message: "",
  });
  const [openModalInfo, setOpenModalInfo] = useState({
    message: "",
    title: "",
    isOpen: false,
  });
  const [isOpenVersions, setIsOpenVersions] = useState(false);
  const [idVersionView, setIdVersionView] = useState(null);
  //Open tooltip company plan is full
  const [isOpenTooltipPlan, setIsOpenTooltipPlan] = useState(false);
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [isOpenTooltipPlanUpload, setIsOpenTooltipPlanUpload] = useState(false);

  //Loading Data
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);

  //Datatable
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [extraValuesFilter, setExtraValuesFilter] = useState([]);

  //SelectedItem
  const [selectedElement, setSelectedElement] = useState({
    id: "",
    type: "",
    name: "",
  });
  const [actualDocument, setActualDocument] = useState({});

  //Tags
  const [tagsSelected, setTagsSelected] = useState([]);

  //Filterbar
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);

  //Drawners
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);

  //Anchors
  //Menu Create
  const [anchorCreate, setAnchorCreate] = React.useState(null);
  const openCreate = Boolean(anchorCreate);

  //Menu Upload
  const [anchorUpload, setAnchorUpload] = useState(null);
  const openUpload = Boolean(anchorUpload);

  //Mini-menu contract
  const [anchorContract, setAnchorContract] = useState(null);
  const openContract = Boolean(anchorContract);

  //Anchor for selected item
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);

  //User Confirmation for moving document
  const [isMoveConfirmed, setIsMoveConfirmed] = useState(false);

  //Generate report
  const [isOpenModalChooseReportToCreate, setIsOpenModalChooseReportToCreate] =
    useState(false);

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  //Worker
  const [moduleName, setModuleName] = useState("");
  const [idProcess, setIdProcess] = useState(null);

  //summary
  const [isOpenModalSummary, setIsOpenModalSummary] = useState({
    open: false,
    text: "",
  });

  /**
   * Handles
   */

  //Anchors

  //Menu Create
  const handleClickCreate = (event) => {
    if (isStoragePlanLimit) return;
    setAnchorCreate(event.currentTarget);
  };
  const handleCloseCreate = () => {
    setAnchorCreate(null);
  };

  const handleCreateAlert = (id) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    Mixpanel.track("Btn crear alerta", {
      homeButton: "Carpetas - Crear alerta a elemento",
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
    navigate(`/service/alerts/create/${id}`);
  };

  const handleMenuClickCreate = (selec) => {
    switch (selec) {
      case 0:
        navigate(`/service/folders/create/${businessFolderId}`);
        break;
      case 1:
        navigate("/service/create");
        break;
      case 2:
        window.alert("Funcionalidad no disponible");
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };

  //Menu Upload
  const handleClickUpload = (event) => {
    if (isStoragePlanLimit) return;
    setAnchorUpload(event.currentTarget);
  };
  const handleCloseUpload = (event) => {
    setAnchorUpload(null);
  };

  const handleMenuClickUpload = (selec) => {
    switch (selec) {
      case 1:
        navigate("/service/folders/upload/uploadSigned/" + businessFolderId);
        break;
      case 2:
        navigate("/service/folders/upload/uploadWarranty/" + businessFolderId);
        break;
      case 3:
        navigate(
          "/service/folders/upload/uploadAttachments/" + businessFolderId
        );
        break;
      case 4:
        navigate("/service/folders/upload/uploadMinutes/" + businessFolderId);
        break;
      case 5:
        navigate(
          "/service/folders/upload/uploadCommunication/" + businessFolderId
        );
        break;
      case 6:
        navigate("/service/folders/upload/uploadOther/" + businessFolderId);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };

  //Mini-menu contract
  const handleClickContract = (event) => {
    setAnchorContract(event.currentTarget);
  };
  const handleCloseContract = (event) => {
    setAnchorContract(null);
  };

  //Selected Item
  const handleClickSelected = (event) => {
    setAnchorSelected(event.currentTarget);
  };

  const handleCloseSelected = () => {
    setAnchorSelected(null);
    setSelectedElement({ id: "", type: "", name: "" });
  };

  //Tags

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });
      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  /**
   * Menu Options TYPE DOCUMENT BASED
   */

  //Type: CONTRACT
  const handlePerMenuContract = (selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    setAnchorSelected(null);
    switch (selec) {
      case 1:
        setIsOpenModalChooseReportToCreate(true);
        break;
      case 2:
        handleCreateSummaryContract(actualDocument);
        break;
      case 3:
        setIsOpenVersions(true);
        setIdVersionView(actualDocument._id);
        break;
      case 4:
        handleTempView(actualDocument, selec);
        break;
      case 5:
        handleDownloadDocument();
        break;
      case 6:
        setOpenModalMove(true);
        break;
      case 7:
        handleCreateAlert(actualDocument._id);
        break;
      case 8:
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar documento",
          message:
            "¿Esta seguro de que desea eliminar el documento seleccionado? ",
        });
        break;
      case 9:
        setAnchorSelected(null);
        handleTempView(actualDocument, 0);
        break;
      case 10:
        setAnchorSelected(null);
        setSelectedFolder(actualDocument);
        Mixpanel.track("Btn ver detalles", {
          homeButton: "Carpetas - Ver detalles elemento",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          type: "Contrato",
        });
        setOpenDetailsDrawner(true);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };
  const handleViewVersionDocument = (versionId) => {
    const versionView = {
      ...versionId.versionId,
      fileType: "624af416c455d0c496c43ed3",
    };
    handleTempView(versionView, 0);
  };

  //Type: SIGNED CONTRACT
  const handlePerMenuSigned = (selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    switch (selec) {
      case 0:
        setAnchorSelected(null);
        handleTempView(actualDocument, 0);
        break;
      case 1:
        setAnchorSelected(null);
        handleDownloadDocument();
        break;
      case 3:
        setAnchorSelected(null);
        setIsOpenModalChooseReportToCreate(true);
        break;
      case 4:
        setAnchorSelected(null);
        handleCreateSummaryContract(actualDocument);
        break;
      case 6:
        setAnchorSelected(null);
        setOpenModalMove(true);
        break;
      case 7:
        setAnchorSelected(null);
        handleCreateAlert(actualDocument._id);
        break;
      case 8:
        setAnchorSelected(null);
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar documento",
          message:
            "¿Esta seguro de que desea eliminar el documento seleccionado? ",
        });
        break;
      case 9:
        setAnchorSelected(null);
        setSelectedFolder(actualDocument);
        Mixpanel.track("Btn ver detalles", {
          homeButton: "Carpetas - Ver detalles elemento",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          type: "Contrato firmado",
        });
        setOpenDetailsDrawner(true);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };

  //Type: FOLDER
  const handlePerMenuFolder = (selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    switch (selec) {
      case 0:
        navigate(`/service/folders/edit/${selectedElement._id}`);
        break;
      case 1:
        setAnchorSelected(null);
        setSelectedFolder(selectedElement);
        Mixpanel.track("Btn ver detalles", {
          homeButton: "Carpetas - Ver detalles elemento",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          type: "Carpeta",
        });
        setOpenDetailsDrawner(true);
        break;
      case 2:
        setAnchorSelected(null);
        setOpenModalMove(true);
        break;
      case 3:
        setAnchorSelected(null);
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar carpeta",
          message:
            "¿Esta seguro de que desea eliminar la carpeta seleccionada? ",
        });
        break;
      case 4:
        setAnchorSelected(null);
        setSelectedFolder(actualDocument);
        setOpenDetailsDrawner(true);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };
  //Type: Draft
  const handlePerMenuDraft = (selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    switch (selec) {
      case 0:
        setAnchorSelected(null);
        handleTempView(actualDocument, selec);
        break;
      case 1:
        setAnchorSelected(null);
        handleDownloadDocument();
        break;
      case 2:
        setAnchorSelected(null);
        setOpenModalMove(true);
        break;
      case 3:
        setAnchorSelected(null);
        handleCreateAlert(actualDocument._id);
        break;
      case 4:
        setAnchorSelected(null);
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar documento",
          message:
            "¿Esta seguro de que desea eliminar el documento seleccionado? ",
        });
        break;
      case 5:
        setAnchorSelected(null);
        handleTempView(actualDocument, 0);
        break;
      case 6:
        setAnchorSelected(null);
        Mixpanel.track("Btn ver detalles", {
          homeButton: "Carpetas - Ver detalles elemento",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          type: "Borrador",
        });
        setSelectedFolder(actualDocument);
        setOpenDetailsDrawner(true);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };

  //Type: OTHER
  const handlePerMenuOther = (selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    switch (selec) {
      case 0:
        setAnchorSelected(null);
        handleTempView(actualDocument, selec);
        break;
      case 1:
        setAnchorSelected(null);
        handleDownloadDocument();
        break;
      case 6:
        setAnchorSelected(null);
        setOpenModalMove(true);
        break;
      case 7:
        setAnchorSelected(null);
        handleCreateAlert(actualDocument._id);
        break;
      case 8:
        setAnchorSelected(null);
        setOpenModalDecision({
          isOpen: true,
          title: "Eliminar documento",
          message:
            "¿Esta seguro de que desea eliminar el documento seleccionado? ",
        });
        break;
      case 9:
        setAnchorSelected(null);
        Mixpanel.track("Btn ver detalles", {
          homeButton: "Carpetas - Ver detalles elemento",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          type: "Otro",
        });
        setSelectedFolder(actualDocument);
        setOpenDetailsDrawner(true);
        break;
      default:
        window.alert("Funcionalidad no disponible");
        break;
    }
  };

  /**
   * Menu Constants
   */

  //Create
  const menuCreate = [
    {
      title: "Carpeta",
      icon: (
        <FolderOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Crear contrato",
      icon: <FeedIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 1,
    },
  ];

  //Upload
  const menuUpload = [
    {
      title: "Contrato",
      selectionClick: 0,
    },
    {
      title: "Contrato firmado",
      selectionClick: 1,
    },
    {
      title: "Garantía",
      selectionClick: 2,
    },
    {
      title: "Anexos de Contrato",
      selectionClick: 3,
    },
    {
      title: "Actas",
      selectionClick: 4,
    },
    {
      title: "Comunicaciones",
      selectionClick: 5,
    },
    {
      title: "Otros documentos",
      selectionClick: 6,
    },
  ];

  // BASED ON DOCUMENT TYPE

  //TYPE: CONTRACT
  const menuContract = [
    {
      title: "Generar informe",
      icon: <PostAddIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 1,
    },
    {
      title: "Ver resumen de contrato",
      icon: (
        <ArticleOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 2,
    },
    {
      title: "Ver una versión específica",
      icon: (
        <FindInPageOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 3,
    },
    {
      title: "Visualizar documento",
      icon: (
        <IosShareOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 4,
    },
    {
      title: "Descargar",
      icon: (
        <FileDownloadIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 5,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 6,
    },
    {
      title: "Crear alerta",
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 7,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 8,
    },
    {
      title: "Editar",
      icon: <EditIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 9,
    },
    {
      title: "Ver detalles del contrato",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 10,
    },
  ];

  //TYPE: CONTRACT SIGNED
  const menuSigned = [
    {
      title: "Ver",
      icon: (
        <RemoveRedEyeOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Descargar",
      icon: (
        <FileDownloadIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 1,
    },
    {
      title: "Generar informe",
      icon: <PostAddIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 3,
    },
    {
      title: "Ver resumen de contrato",
      icon: (
        <ArticleOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 4,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 6,
    },
    {
      title: "Crear alerta",
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 7,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 8,
    },
    {
      title: "Ver detalles del contrato",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 9,
    },
  ];

  //TYPE: FOLDER
  const menuFolder = [
    {
      title: "Editar información de la carpeta",
      icon: (
        <CreateOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Ver detalles de la carpeta",
      icon: (
        <InfoOutlinedIcon fontSiz="large" className="heading__primary-color" />
      ),
      selectionClick: 1,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 2,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 3,
    },
    {
      title: "Ver detalles de la subcarpeta",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 4,
    },
  ];

  //TYPE: DRAFT
  const menuDraft = [
    {
      title: "Ver",
      icon: (
        <RemoveRedEyeOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Descargar",
      icon: (
        <FileDownloadIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 1,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 2,
    },
    {
      title: "Crear alerta",
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 3,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 4,
    },
    {
      title: "Editar",
      icon: <EditIcon fontSize="large" className="heading__primary-color" />,
      selectionClick: 5,
    },
    {
      title: "Ver detalles del documento",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 6,
    },
  ];

  //TYPE: OTHER
  const menuOther = [
    {
      title: "Ver",
      icon: (
        <RemoveRedEyeOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 0,
    },
    {
      title: "Descargar",
      icon: (
        <FileDownloadIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 1,
    },
    {
      title: "Ver contrato firmado",
      icon: (
        <TaskOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 0,
    },
    {
      title: "Mover a",
      icon: (
        <DriveFileMoveOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 6,
    },
    {
      title: "Crear alerta",
      icon: (
        <NotificationsNoneOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 7,
    },
    {
      title: "Eliminar",
      icon: (
        <DeleteOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      selectionClick: 8,
    },
    {
      title: "Ver detalles del documento",
      icon: (
        <InfoOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
      selectionClick: 9,
    },
  ];

  const LIST_MENU_ELEMENTS = {
    Contrato: menuContract.map((menuElement, index) => (
      <MenuItemComponent
        key={menuElement.title + menuElement.selectionClick}
        keyProp={menuElement.title + menuElement.selectionClick}
        index={index}
        handleClick={handlePerMenuContract}
        menuElement={menuElement}
        menuType={menuContract}
      />
    )),
    "Contrato Firmado": menuSigned.map((menuElement, index) => (
      <MenuItemComponent
        key={menuElement.title + menuElement.selectionClick}
        keyProp={menuElement.title + menuElement.selectionClick}
        index={index}
        handleClick={handlePerMenuSigned}
        menuElement={menuElement}
        menuType={menuSigned}
      />
    )),
    Carpeta: menuFolder.map((menuElement, index) => (
      <MenuItemComponent
        key={menuElement.title + menuElement.selectionClick}
        keyProp={menuElement.title + menuElement.selectionClick}
        index={index}
        handleClick={handlePerMenuFolder}
        menuElement={menuElement}
        menuType={menuFolder}
      />
    )),
    Borrador: menuDraft.map((menuElement, index) => (
      <MenuItemComponent
        key={menuElement.title + menuElement.selectionClick}
        keyProp={menuElement.title + menuElement.selectionClick}
        index={index}
        handleClick={handlePerMenuDraft}
        menuElement={menuElement}
        menuType={menuDraft}
      />
    )),
  };

  /**
   * Handle Request
   */
  const handleRequest = async () => {
    handleCloseUpload();
    handleCloseContract();
    try {
      setIsloading(true);
      const payloadToken = JSON.parse(localStorage.payloadToken);
      const corporateUnit = JSON.parse(localStorage.corporateUnit);
      let dataFile = documentData;
      if (dataFile?.type !== "application/pdf") {
        const convertDoc = await convertToPdfService(dataFile, "file");
        const docView = convertDoc.data.responseMessage.data.buffer.data;
        const bf = Buffer.from(docView);
        const name = documentData?.name.substring(
          0,
          dataFile?.name.lastIndexOf(".")
        );
        dataFile = new File([bf], name + ".pdf", {
          type: "application/pdf",
        });
      }
      const segmentDoc = await segmentDocument({
        file: dataFile,
        company: payloadToken.company,
      });

      const response = await createNewVersion({
        name: documentData.name,
        fileType: codesDocumentType.contract._id,
        documentContent: {
          ...segmentDoc.data.responseMessage,
          name: documentData.name,
        },
        folderAssociated: businessFolderId,
        file: documentData,
        corporateUnit: corporateUnit._id,
        versionFrom: "FOLDER",
      });
      setDocumentData({});
      if (
        response?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        response?.data?.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(response);
        throw new Error("No tienes los permisos suficientes");
      }
      setOptionsFilterBar((prev) => [...prev]);
      dispatch(setFolderStatus({ newStatus: "fetch" }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleRequestMove = async () => {
    try {
      setIsloading(true);
      const request = await moveDocumentBusinessFolder(
        businessFolderId,
        selectedElement._id,
        versionSelected._id
      );

      if (request && request.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setIsOpenModalUnauthorized(true);
        return setResponseDataUnauthorized(request);
      }
      setOptionsFilterBar((prev) => [...prev]);
      navigate(`../folders/viewFolder/${versionSelected._id}`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleRequestMoveFolder = async () => {
    try {
      setIsloading(true);
      if (
        [selectedElement?._id, businessFolderId].includes(versionSelected?._id)
      )
        return;
      await moveBusinessFolder({
        sourceFolderId: selectedElement.businessFolderFatherId,
        currentFolderId: selectedElement._id,
        destinaFolderId: versionSelected._id,
      });
      setOptionsFilterBar((prev) => [...prev]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const buildDocument = (doc) => {
    let parts = [];
    if (doc?.documentContent?.parts?.partA) {
      const a = { ...doc?.documentContent?.parts?.partA };
      parts.push(a);
    }
    if (doc?.documentContent?.parts?.partB) {
      const b = { ...doc?.documentContent?.parts?.partB };
      parts.push(b);
    }
    if (doc?.documentContent?.parts?.partC) {
      const c = { ...doc.documentContent.parts.partC };
      parts.push(c);
    }

    if (doc?.documentContent?.parts?.partD) {
      const d = { ...doc.documentContent.parts.partD };
      parts.push(d);
    }

    const finalObj = {
      title: doc?.documentContent?.title,
      parts: parts,
      fileType: doc?.fileType,
      letterheadAssociated: doc?.letterheadAssociated,
    };
    dispatch(
      setPartsHeaderText({
        partsHeaderText: doc?.documentContent?.partsHeader,
      })
    );
    dispatch(setInitialInfoLibrary(finalObj));
    if (doc?.documentContent?.clauses?.length) {
      const clauses = doc.documentContent.clauses.map((cla) => {
        return cla.parts
          ? {
              clause: {
                ...cla,
                parts: cla.parts.partD
                  ? [
                      cla.parts.partA,
                      cla.parts.partB,
                      cla.parts.partC,
                      cla.parts.partD,
                    ]
                  : cla.parts.partC
                  ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
                  : [cla.parts.partA, cla.parts.partB],
              },
            }
          : { clause: cla };
      });
      dispatch(setSelectedClauses({ clauses }));
    }
    dispatch(
      setName({
        name: doc?.documentContent?.name,
      })
    );

    dispatch(setHeaders(doc?.documentContent?.headers));

    dispatch(
      setFileType({
        fileType: Object.values(codesDocumentType).find(
          (code) => code._id === doc?.fileType
        ),
      })
    );
    if (doc?.fileType === codesDocumentType?.draft?._id) {
      dispatch(
        setVersionAssociated({
          id: doc?.documentContent?.versionAssociated,
        })
      );
      dispatch(
        setIdDraft({
          id: doc._id,
        })
      );
    } else {
      dispatch(
        setVersionAssociated({
          id: idVersionView ?? doc?._id,
        })
      );
    }
    dispatch(addSignatoryWithoutPart(doc?.documentContent?.otherSignatories));
  };

  /**
   * Update document last access
   *
   * @param {String} id The document id
   */
  const updateDocumentLastAccess = async (id) => {
    try {
      await updateVersionLastAccess(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTempView = async (doc, selec) => {
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    Mixpanel.track("Btn ver", {
      homeButton: "Carpetas - Ver elemento",
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
    if (
      doc.fileType === codesDocumentType.contract._id ||
      doc.fileType === codesDocumentType.draft._id
    ) {
      console.log(doc);
      if (selec === 3 || doc?.status?._id === DOCUMENT_STATE.archived._id) {
        navigate(`viewOther/${doc._id}`);
      } else if (doc?.documentContent?.canEdit) {
        if (doc?.documentContent?.otherDocType === "template") {
          navigate("/service/create/other/from-template/create", {
            state: doc.documentContent,
          });
        } else {
          navigate("/service/create/other/from-text/create", {
            state: doc.documentContent,
          });
        }
      } else {
        dispatch(setSelectedHeaderId(null));
        dispatch(setSelectedHeader(null));
        dispatch(cleanStore());
        buildDocument(doc);
        updateDocumentLastAccess(doc._id);
        navigate(`editDocument`);
      }
    } else if (doc.fileType === codesDocumentType.OtherDocs._id) {
      if (doc?.documentContent?.canEdit) {
        if (doc?.documentContent?.otherDocType === "template") {
          navigate("/service/create/other/from-template/create", {
            state: doc.documentContent,
          });
        } else {
          navigate("/service/create/other/from-text/create", {
            state: doc.documentContent,
          });
        }
      } else {
        navigate(`viewOther/${doc._id}`);
      }
    } else {
      navigate(`viewOther/${doc._id}`);
    }
  };

  const handleDelete = async () => {
    try {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      Mixpanel.track("Btn eliminar", {
        homeButton: "Carpetas - Eliminar elemento",
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      setIsloading(true);
      setOpenModalDecision((prev) => ({ ...prev, isOpen: false }));
      const requestTempDelete =
        selectedElement.type === "Carpeta"
          ? await updateDeletedFolder({
              newStatus: FOLDER_STATE.deleted._id,
              id: selectedElement._id,
            })
          : await updateStateDocument({
              newStatus: DOCUMENT_STATE.deleted._id,
              id: selectedElement._id,
              fileType: selectedElement?.fileType,
            });
      if (
        requestTempDelete.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestTempDelete.data.success
      ) {
        navigate(`deleteDocument`);
      } else if (
        requestTempDelete?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        requestTempDelete?.data?.responseCode ===
          CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseDataUnauthorized(requestTempDelete);
      } else {
        setOpenModalInfo({
          message: requestTempDelete.data.responseMessage,
          title: "Error",
          isOpen: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleNavigateSubFolders = async (idFolder) => {
    try {
      let company = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let obj = {
        corporateUnit,
        company,
        lastAccessAt: new Date(),
        id: idFolder,
        timeZone: new Date().getTimezoneOffset() / -60,
      };
      await updateBusinessFolderlastAccess(obj);
      navigate(`/service/folders/viewFolder/${idFolder}`);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadDocument = async () => {
    try {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      Mixpanel.track("Btn descargar", {
        homeButton: "Carpetas - Descargar elemento",
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      let blob;
      if (actualDocument.fileType === codesDocumentType.draft._id) {
        if (actualDocument.documentContent) {
          blob = await WordBlobDocument({
            title: actualDocument.documentContent.title,
            headers: actualDocument.documentContent.headers,
            parts: actualDocument.documentContent.parts,
            clauses: actualDocument.documentContent.clauses,
            partHeader: "",
            numberingType: "number",
            otherSignatories: [],
          });
        }
      } else {
        const file = await getFileDocument(
          actualDocument.filenameInBucket,
          actualDocument.bucketName,
          false
        );
        const fileType = file.data.responseMessage.contentType;
        const bf = Buffer.from(file.data.responseMessage.buffer.data);
        blob = new Blob([bf], {
          type: fileType,
        });
      }

      const extention = actualDocument.name.split(".").pop().toLowerCase();
      const newName = () => {
        if (extention) {
          if (blob.type === "application/pdf") {
            return changeFileExtension(actualDocument.name, "pdf");
          } else {
            return actualDocument.name;
          }
        } else {
          return `${actualDocument.name}.docx`;
        }
      };
      saveAs(blob, newName());
    } catch (error) {
      console.log(error);
    }
  };

  const buildPolicies = (data) => {
    const arrayPolicies = [];
    for (const report of data) {
      const addIdpolicie = report.policies.map((item) => ({
        ...item,
        id_text: report.id_text,
        text: report.text,
        usage: report.usage,
      }));
      arrayPolicies.push(addIdpolicie);
    }
    return arrayPolicies.flat();
  };

  const handleProcessReports = async (documentSelected, response) => {
    try {
      const reportsData = response.map((item) => {
        if (item?.typeReport === "Pólizas") {
          return {
            ...item,
            reports: buildPolicies(item.reports),
          };
        }
        return item;
      });
      dispatch(setReports({ reports: reportsData }));
      buildDocument(documentSelected);
      navigate(`reports/${documentSelected._id}`);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleAgreeGenerateReport = async (data) => {
    try {
      setIsLoadingInBackground(true);
      setIsOpenModalChooseReportToCreate(false);
      let versionId = "";
      let associatedDocumentInfo;
      if (
        !selectedElement.documentContent &&
        selectedElement.type === "Contrato Firmado"
      ) {
        setIsloading(true);
        versionId =
          selectedElement?.contractAssociated?._id ||
          selectedElement?.signContractAssociated?._id;
        const requestGetDoc = await getDocById(versionId);
        if (
          requestGetDoc.status === CODES.COD_RESPONSE_HTTP_OK &&
          requestGetDoc.data.success
        ) {
          associatedDocumentInfo = requestGetDoc.data.responseMessage;
        }
        setIsloading(false);
      } else {
        versionId = selectedElement._id;
      }

      if (reportsWorkerRef.current) {
        const body = {
          versionId,
          filenameInBucket: selectedElement?.filenameInBucket,
          bucketName: selectedElement?.bucketName,
        };

        const idProcess = uuidv4();
        setModuleName("backgroundProcessReportsContract");
        setIdProcess(idProcess);

        reportsWorkerRef.current.postMessage({
          body,
          token,
          serviceUrl,
          idProcess,
          typesReports: data,
        });

        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessReportsContract",
            updateData: {
              id: idProcess,
              nameFile: selectedElement?.name,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                url: location.pathname,
                otherInfo: {
                  documentSelected: selectedElement,
                  versionId,
                  associatedDocumentInfo,
                },
                state: {
                  processId: idProcess,
                },
              },
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      console.log(
        "============== Start Error Handle Generate Report =============="
      );
      console.error(error);
      console.log(
        "============== Finish Error Handle Generate Report =============="
      );
    } finally {
      setIsloading(false);
    }
  };

  /**
   * Handle Modals
   */
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalSummary = () => {
    setIsOpenModalSummary({ open: false, text: "" });
  };

  const handleProcessResume = async (documentSelected, responseSummary) => {
    try {
      setIsOpenModalSummary({ open: true, text: responseSummary.data });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleCreateSummaryContract = async () => {
    try {
      setIsLoadingInBackground(true);
      const { clauses } = selectedElement?.documentContent || [];

      if (resumeWorkerRef.current) {
        const idProcess = uuidv4();
        setModuleName("backgroundProcessResumeContract");
        setIdProcess(idProcess);

        resumeWorkerRef.current.postMessage({
          clauses,
          token,
          serviceUrl,
          idProcess,
        });
        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessResumeContract",
            updateData: {
              id: idProcess,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                url: `${location.pathname}`,
                otherInfo: {
                  documentSelected: selectedElement,
                },
                state: {
                  processId: idProcess,
                },
              },
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      console.log("======= Start Error Hanlde Create Summary Contract =======");
      console.error(error);
      console.log(
        "======= Finish Error Hanlde Create Summary Contract ======="
      );
    } finally {
      setIsloading(false);
    }
  };

  const handleOpenTooltipPlan = () => {
    if (!isStoragePlanLimit) return;
    setIsOpenTooltipPlan(true);
  };

  const handleOpenTooltipPlanUpload = () => {
    if (!isStoragePlanLimit) return;
    setIsOpenTooltipPlanUpload(true);
  };

  //Loading data table
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        const codesDocumentTypeToOmitForShowLastAccess = [
          codesDocumentType.contract,
          codesDocumentType.draft,
        ];
        const finalList = currentFolder?.subFolders?.concat(
          currentFolder?.documents
        );
        const valuesExtra = [];

        const processedDocs = finalList?.map((doc) => {
          const created = dateFormat(doc?.createdAt, "dd/mm/yyyy");
          const modified = dateFormat(doc?.updatedAt, "dd/mm/yyyy");
          const lastAccess = moment(doc?.lastAccessAt).format("DD/MM/YYYY");
          const validateLastAccessAt = !doc?.lastAccessAt
            ? "Sin último acceso"
            : lastAccess;
          const fileType = Object.values(codesDocumentType).find(
            (code) => code._id === doc.fileType
          );
          const valuesBack = {
            element: doc.name,
            type: fileType?.name || "Carpeta",
            createdAt: doc.createdAt,
            updatedAt: doc.updatedAt,
            alerts: doc.numberOfAlerts,
            lastAccessAt:
              fileType &&
              !codesDocumentTypeToOmitForShowLastAccess.includes(fileType)
                ? "No aplica"
                : validateLastAccessAt,
          };

          valuesExtra.push(valuesBack);

          return {
            element: (
              <Row
                style={{
                  paddingLeft: "2%",
                  paddingTop: "1%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  fileType
                    ? handleTempView(doc, 0)
                    : handleNavigateSubFolders(doc._id);
                }}
              >
                <Col xs={"auto"} className="datatable-CB__tr-name">
                  {fileType ? (
                    <FeedIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  ) : (
                    <FolderIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  )}
                </Col>
                <Col className="datatable-CB__tr-name">{doc.name}</Col>
              </Row>
            ),
            type: fileType ? fileType.name : "Carpeta",
            createdAt: created,
            updatedAt: modified,
            alerts: (
              <Row>
                <Col
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (fileType) {
                      navigate(
                        `/service/folders/alerts/${doc._id}/?folder=${doc.folderAssociated}`
                      );
                    } else {
                      navigate(`/service/folders/alerts/${doc._id}`);
                    }
                  }}
                  className={doc.numberOfAlerts > 0 ? "" : "visibility__hidden"}
                >
                  <p className="internal-alerts">{`${doc.numberOfAlerts} Alertas`}</p>
                </Col>

                <Col>
                  <IconButton
                    onClick={(e) => {
                      setSelectedElement({
                        ...doc,
                        type: fileType?.name || "Carpeta",
                      });
                      setActualDocument(doc);
                      handleClickSelected(e);
                    }}
                  >
                    <MoreHorizIcon fontSize="large" />
                  </IconButton>
                </Col>
              </Row>
            ),
            lastAccessAt:
              fileType &&
              !codesDocumentTypeToOmitForShowLastAccess.includes(fileType)
                ? "No aplica"
                : validateLastAccessAt,
          };
        });

        const proccesedRequest = currentFolder?.requests?.map((req) => {
          const created = dateFormat(req?.createdAt, "dd/mm/yyyy");
          const modified = dateFormat(req?.updatedAt, "dd/mm/yyyy");

          const valuesBack = {
            element: `Solicitud #${req.consecutive}`,
            type: "Solicitudes",
            createdAt: req.createdAt,
            updatedAt: req.updatedAt,
            alerts: req.numberOfAlerts,
            lastAccessAt: "No aplica",
          };

          valuesExtra.push(valuesBack);

          return {
            element: (
              <Row style={{ paddingLeft: "2%", paddingTop: "1%" }}>
                <Col xs={"auto"} className="datatable-CB__tr-name">
                  <FeedIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </Col>
                <Col className="datatable-CB__tr-name">
                  Solicitud #{req.consecutive}
                </Col>
              </Row>
            ),
            type: "Solicitudes",
            createdAt: created,
            updatedAt: modified,
            alerts: (
              <Row>
                <Col
                  className={req.numberOfAlerts > 0 ? "" : "visibility__hidden"}
                >
                  <p className="internal-alerts">{`${req.numberOfAlerts} Alertas`}</p>
                </Col>

                <Col>
                  <IconButton
                    onClick={(e) => {
                      setSelectedElement({
                        ...req,
                      });
                      handleClickSelected(e);
                    }}
                  >
                    <MoreHorizIcon fontSize="large" />
                  </IconButton>
                </Col>
              </Row>
            ),
            lastAccessAt: "No aplica",
          };
        });

        setExtraValuesFilter(valuesExtra);

        const unified = processedDocs?.concat(proccesedRequest);
        setDataTable({ columns: columns, rows: unified });
        setFilteredDataTable({ columns: columns, rows: unified });
        setTimeout(setIsloading(false), 50000);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [optionsFilterBar, businessFolderId, currentFolder]);

  useEffect(async () => {
    if (folderStatus == "fetch") {
      setIsloading(true);
      await dispatch(fetchFolderById(businessFolderId));
      setIsloading(false);
    }
  }, [dispatch, folderStatus]);

  useEffect(() => {
    return dispatch(setFolderStatus({ newStatus: "fetch" }));
  }, [location]);

  //Folders
  useEffect(() => {
    if (foldersStatus == "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [dispatch, foldersStatus]);

  //Cargar tags
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  //Upload first version
  useEffect(() => {
    documentData.name && handleRequest();
  }, [openModal]);

  //Handle UploadVersions
  useEffect(() => {
    if (versionSelected.name) {
      selectedElement.documents
        ? handleRequestMoveFolder()
        : handleRequestMove();
    } else {
      selectedElement.documents && handleRequestMoveFolder();
    }
  }, [isMoveConfirmed]);

  //Handle UpDateLastAccessAt
  useEffect(() => {
    const fetchData = async () => {
      try {
        let company = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        let obj = {
          corporateUnit,
          company,
          lastAccessAt: new Date(),
          id: businessFolderId,
          timeZone: new Date().getTimezoneOffset() / -60,
        };
        await updateBusinessFolderlastAccess(obj);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  // Resume background

  const handleMessageBackgroundResume = useCallback(async (event) => {
    try {
      const { data, idProcess } = event.data;
      const { success, responseMessage: response } = data;
      if (success) {
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessResumeContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: true,
              navigationInfo: {
                ...listInfoResumeBackgroundRef.current?.[idProcess]
                  ?.navigationInfo,
                otherInfo: {
                  ...listInfoResumeBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo,
                  resonseReport: response,
                },
              },
            },
          })
        );
        setIsloading(false);
        setIsLoadingInBackground(false);
        const userLeft =
          listInfoResumeBackgroundRef.current?.[idProcess]?.userLeft;

        if (!userLeft) {
          const selectedElement =
            listInfoResumeBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.documentSelected;

          await handleProcessResume(selectedElement, response);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessResumeContract",
              id: idProcess,
            })
          );
        }

        const itemsRef = Object.values(listInfoResumeBackgroundRef.current);

        if (!itemsRef.length) {
          resumeWorkerRef.current.removeEventListener(
            "message",
            handleMessageBackgroundResume
          );
          resumeWorkerRef.current.terminate();
        }
      } else {
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessResumeContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: false,
              isErrorProcessing: true,
              navigationInfo: {},
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const handleMessageRefResume = useRef(handleMessageBackgroundResume);

  useEffect(() => {
    if (!resumeWorkerRef.current) {
      // add worker
      resumeWorkerRef.current = new WorkerBuilder(WorkerResume);
    }
    resumeWorkerRef.current.removeEventListener(
      "message",
      handleMessageRefResume.current
    );

    resumeWorkerRef.current.addEventListener(
      "message",
      handleMessageBackgroundResume
    );
  }, [handleMessageBackgroundResume]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (listInfoResumeBackgroundRef.current?.[idProcess]?.finishProcessing) {
        setIsloading(true);
        try {
          const selectedElement =
            listInfoResumeBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.documentSelected;
          const response =
            listInfoResumeBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.resonseReport;
          await handleProcessResume(selectedElement, response);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessResumeContract",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsloading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoResumeBackgroundRef, locationState]);

  // Reports Background
  const handleMessageBackgroundReports = useCallback(async (event) => {
    try {
      const { data, idProcess } = event.data;
      const { success, data: response } = data;

      if (success) {
        const documentSelected =
          listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.documentSelected;
        const associatedDocumentInfo =
          listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.associatedDocumentInfo;
        const reportPartIndex = response.findIndex(
          (item) => item?.typeReport === "Partes"
        );
        let newDocumentSelected = documentSelected;
        if (reportPartIndex) {
          newDocumentSelected = associatedDocumentInfo
            ? {
                ...associatedDocumentInfo,
                documentContent: {
                  ...associatedDocumentInfo.documentContent,
                  parts: {
                    ...associatedDocumentInfo?.documentContent?.parts,
                    partA: {
                      ...associatedDocumentInfo?.documentContent?.parts?.partA,
                      signatories: response[reportPartIndex]?.[0]?.signatories,
                    },
                    partB: {
                      ...associatedDocumentInfo?.documentContent?.parts?.partB,
                      signatories: response[reportPartIndex]?.[1]?.signatories,
                    },
                  },
                },
              }
            : {
                ...documentSelected,
                documentContent: {
                  ...documentSelected?.documentContent,
                  parts: {
                    ...documentSelected?.documentContent?.parts,
                    partA: {
                      ...documentSelected?.documentContent?.parts?.partA,
                      signatories: response[reportPartIndex]?.[0]?.signatories,
                    },
                    partB: {
                      ...documentSelected?.documentContent?.parts?.partB,
                      signatories: response[reportPartIndex]?.[1]?.signatories,
                    },
                  },
                },
              };
        }
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessReportsContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: true,
              navigationInfo: {
                ...listInfoReportsBackgroundRef.current?.[idProcess]
                  ?.navigationInfo,
                otherInfo: {
                  ...listInfoReportsBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo,
                  responseReports: response,
                  documentSelected: newDocumentSelected,
                },
              },
            },
          })
        );

        setIsloading(false);
        const userLeft =
          listInfoReportsBackgroundRef.current?.[idProcess]?.userLeft;

        if (!userLeft) {
          await handleProcessReports(newDocumentSelected, response);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReportsContract",
              id: idProcess,
            })
          );
        }

        const itemsRef = Object.values(listInfoReportsBackgroundRef.current);

        if (!itemsRef.length) {
          reportsWorkerRef.current.removeEventListener(
            "message",
            handleMessageBackgroundResume
          );
          reportsWorkerRef.current.terminate();
        }
      } else {
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessReportsContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: false,
              isErrorProcessing: true,
              navigationInfo: {},
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const handleMessageRefReports = useRef(handleMessageBackgroundReports);

  useEffect(() => {
    if (!reportsWorkerRef.current) {
      // add worker
      reportsWorkerRef.current = new WorkerBuilder(WorkerReports);
    }
    reportsWorkerRef.current.removeEventListener(
      "message",
      handleMessageRefReports.current
    );

    reportsWorkerRef.current.addEventListener(
      "message",
      handleMessageBackgroundReports
    );
  }, [handleMessageBackgroundReports]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (listInfoReportsBackgroundRef.current?.[idProcess]?.finishProcessing) {
        setIsloading(true);
        try {
          const documentSelected =
            listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.documentSelected;
          const response =
            listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.responseReports;
          await handleProcessReports(documentSelected, response);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReportsContract",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsloading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoReportsBackgroundRef, locationState]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && !isLoadingInBackground && <LoadingContent />}

      {isLoadingInBackground && !isLoading && (
        <LoadingContentInBackground
          actions={true}
          open={isLoadingInBackground}
          setIsOpen={setIsLoadingInBackground}
          moduleName={moduleName}
          processId={idProcess}
        />
      )}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={currentFolder.name} />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={() => {
              navigate("deleteDocument");
            }}
          >
            <DeleteOutlinedIcon fontSize="large" />
          </Button>
        </Col>
        <Col md={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            startIcon={<InfoOutlinedIcon />}
            onClick={() => {
              setSelectedFolder(currentFolder);
              setOpenDetailsDrawner(true);
            }}
          >
            Detalles de la carpeta
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={"auto"}>
          <Tooltip
            open={isOpenTooltipPlan}
            onOpen={handleOpenTooltipPlan}
            onClose={() => setIsOpenTooltipPlan(false)}
            title={
              <h5>
                La compañía no cuenta con almacenamiento suficiente para esta
                acción, contáctate con el usuario administrador
              </h5>
            }
          >
            <Button
              className={
                isStoragePlanLimit
                  ? "custom-input__button__disabled"
                  : "custom-input__button__primary-color"
              }
              endIcon={<ExpandMoreIcon />}
              startIcon={<AddIcon className="heading__primary-border" />}
              onClick={handleClickCreate}
              variant={!isStoragePlanLimit && "contained"}
            >
              Crear
            </Button>
          </Tooltip>
          {/**CREAR */}
          <Menu
            id="basic-menu"
            anchorEl={anchorCreate}
            open={openCreate}
            onClose={handleCloseCreate}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {menuCreate.map((menuElement, index) => (
              <div key={menuElement?.selectionClick}>
                <MenuItem
                  onClick={() => {
                    handleMenuClickCreate(menuElement.selectionClick);
                  }}
                  style={{ width: "351px" }}
                >
                  <ListItemIcon>{menuElement.icon}</ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "Roboto",
                      fontSize: "1.4rem",
                      fontWeight: "regular",
                      lineHeight: "2rem",
                      color: "#676879",
                    }}
                    primary={menuElement.title}
                  />
                </MenuItem>
                {index + 1 === menuCreate.length ? <></> : <hr />}
              </div>
            ))}
          </Menu>
        </Col>
        <Col md={"auto"}>
          <Tooltip
            open={isOpenTooltipPlanUpload}
            onOpen={handleOpenTooltipPlanUpload}
            onClose={() => setIsOpenTooltipPlanUpload(false)}
            title={
              <h5>
                La compañía no cuenta con almacenamiento suficiente para esta
                acción, contacta con el usuario administrador
              </h5>
            }
          >
            <Button
              className={
                isStoragePlanLimit
                  ? "custom-input__button__disabled"
                  : "custom-input__button__primary-color"
              }
              endIcon={<ExpandMoreIcon />}
              startIcon={<CloudUploadOutlinedIcon />}
              onClick={handleClickUpload}
              variant={!isStoragePlanLimit && "contained"}
            >
              Cargar documento
            </Button>
          </Tooltip>
          {/**CARGAR */}
          <Menu
            id="basic-menu"
            anchorEl={anchorUpload}
            open={openUpload}
            onClose={handleCloseUpload}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {menuUpload.map((menuElement, index) => (
              <div key={menuElement?.selectionClick}>
                <MenuItem
                  style={{ width: "267px" }}
                  className="body-content__disabled"
                  onClick={(e) => {
                    index === 0
                      ? handleClickContract(e)
                      : handleMenuClickUpload(menuElement.selectionClick);
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "Roboto",
                      fontSize: "1.4rem",
                      fontWeight: "regular",
                      lineHeight: "2rem",
                      color: "#676879",
                    }}
                    primary={menuElement.title}
                  />
                  <ChevronRightOutlinedIcon fontSize="medium" />
                </MenuItem>
                {index + 1 === menuUpload.length ? <></> : <hr />}
              </div>
            ))}
          </Menu>

          {/**CONTRATO */}
          <Menu
            id="basic-menu"
            anchorEl={anchorContract}
            open={openContract}
            onClose={handleCloseContract}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            style={{ margin: "0px 0px 0px 270px" }}
          >
            <MenuItem
              onClick={() => {
                handleOpenModal();
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary="Cargar primera versión"
              />
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                navigate(
                  `/service/folders/upload/uploadVersion/${businessFolderId}`
                );
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary="Cargar versión"
              />
            </MenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                window.alert("Funcionalidad no disponible");
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Roboto",
                  fontSize: "1.4rem",
                  fontWeight: "regular",
                  lineHeight: "2rem",
                  color: "#676879",
                }}
                primary="Cargar varias versiones de un contrato"
              />
            </MenuItem>
          </Menu>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={5} className="col-flex">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
      </Row>
      <Row>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row>
        {
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={5}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "elementos"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                sort: value,
                filteredDataTable,
                setFilteredDataTable: ({ columns, rows, extraValues }) => {
                  setFilteredDataTable({ columns, rows });
                  setExtraValuesFilter(extraValues);
                },
                otherListValues: extraValuesFilter,
              });
            }}
            noRecordsFoundLabel={"No se han encontrado resultados"}
          />
        }

        {/**MENUS */}
        <Menu
          id="basic-menu"
          anchorEl={anchorSelected}
          open={openSelected}
          onClose={handleCloseSelected}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {LIST_MENU_ELEMENTS[selectedElement.type] ||
            menuOther.map((menuElement, index) => (
              <MenuItemComponent
                key={menuElement.title}
                keyProp={menuElement.title}
                index={index}
                handleClick={handlePerMenuOther}
                menuElement={menuElement}
                menuType={menuOther}
              />
            ))}
        </Menu>
      </Row>

      <DetailsBar
        openDetailsDrawner={openDetailsDrawner}
        setOpenDetailsDrawner={setOpenDetailsDrawner}
        selectedElement={selectedFolder}
        pageName={selectedFolder.fileType ? "Document" : "Folder"}
      />
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenModal(false);
        }}
        onClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
        documentData={documentData}
        setDocumentData={setDocumentData}
        typeDocument={"Contrato"}
        maxFileSize={78643200}
      />
      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
          Mixpanel.track("Btn mover", {
            homeButton: "Carpetas - Mover elemento",
            email: payloadToken?.email,
            companyName: payloadToken?.companyName,
          });
          setOpenModalMove(false);
          setVersionSelected(file);
          //CUBRE PARCIALMENTE PARA FOLDERS Y QUE NO SE LANCE LA PETICIÓN A LA LOCA
          setIsMoveConfirmed(!isMoveConfirmed);
        }}
        onClose={() => {
          setOpenModalMove(false);
        }}
        open={openModalMove}
        folders={foldersList}
        versionSelected={versionSelected}
        title="Mover a"
        isMovingDocument={true}
        isMoveConfirmed={isMoveConfirmed}
      />
      <ModalDecision
        title={openModalDecision.title}
        message={openModalDecision.message}
        open={openModalDecision.isOpen}
        onClose={() => {
          setOpenModalDecision({ isOpen: false, title: "", message: "" });
        }}
        agreeText={"Eliminar"}
        disagreeText={"Cancelar"}
        handleAgree={handleDelete}
        handleDisagree={() => {
          setOpenModalDecision({ isOpen: false, title: "", message: "" });
        }}
      />
      <ModalInfo
        title={openModalInfo.title}
        open={openModalInfo.isOpen}
        onClose={() => setOpenModalInfo((prev) => ({ ...prev, isOpen: false }))}
        responseData={openModalInfo.message}
      />
      <ModalListSelectCheck
        open={isOpenModalChooseReportToCreate}
        onClose={() => setIsOpenModalChooseReportToCreate(false)}
        title={"Generar informe de:"}
        list={listReport}
        handleAgree={handleAgreeGenerateReport}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title="Resumen de contrato"
        onClose={handleCloseModalSummary}
        open={isOpenModalSummary.open}
        responseData={{ data: { responseMessage: isOpenModalSummary.text } }}
        confirmationText="Aceptar"
        AdditionalFields={CopyTextComponent}
        additionalFieldsProps={{ text: isOpenModalSummary.text }}
      />
      <VersionBar
        isOpen={isOpenVersions}
        setIsOpen={setIsOpenVersions}
        from={"folders"}
        documentId={idVersionView}
        handleAction={handleViewVersionDocument}
      />
    </Container>
  );
};

export default ViewBusinessFolder;
