import EventNoteIcon from "@mui/icons-material/EventNote";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import ClearIcon from "@mui/icons-material/Clear";
import ReactHtmlParser from "react-html-parser";
import LoadingContent from "../../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalInfoFullScreen from "../../../../../../../components/Modals/ModalInfoFullScreen";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { ModalListSelectCheck } from "../../../../../../../components/Modals/modalListCheck";
import VersionBar from "../../../../../../../components/VersionBar/versionBar";
import {
  addSignatoryWithoutPart,
  getCurrentDocument,
  getReferenceNormativeStatus,
  setClauseSelected,
  setDocumentSummary,
  setFileType,
  setHeaders,
  setIdDraft,
  setInitialInfoLibrary,
  setName,
  setPartsHeaderText,
  setReports,
  setSelectedClauses,
  setSelectedClausesWithoutCheckPart,
  setVersionAssociated,
} from "../../../../../../../parts/document/currentDocumentSlice";
import { SendInfoToSupport } from "../../../../../../../services/admin/manageUserService";
import {
  getAdvicesFromClauses,
  getImportanceFromClauses,
  getRefNormativeDocument,
  getSummaryFromClauses,
} from "../../../../../../../services/documents/documentsRequest";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { TEXT_LOREM_IPSUM } from "../../../../../../../utils/loremIpsum";
import {
  NUMBERINGWORDSA,
  NUMBERINGWORDSO,
} from "../../../../../../../utils/numberings";
import { signatoriesTypes } from "../../../../../../../utils/typesSignatures";
import { Mixpanel } from "../../../../../../../utils/mixPanel";
import FilterCategory from "../../../../../../../parts/filters/FilterCategory";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../../parts/categories/clauseCategorySlice";
import PopoverArrayCards from "../../../../../../../components/Popover/PopoverArrayCards";
import { env } from "../../../../../../../env";
import GetToken from "../../../../../../../utils/getToken";
import {
  selectListInfoForModule,
  updateListBackgroundProcesses,
} from "../../../../../../../parts/backgroundProcess/backgroundProcessSlice";
import WorkerBuilder from "../../../../../../../workers/worker-builder";
import LoadingContentInBackground from "../../../../../../../components/loadingContentInBackground";
import WorkerReports from "../../../../../../../workers/reportFromClausesWorker";
import { codesDocumentType } from "../../../../../../../utils/codesDocumentType";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DataArrayOutlinedIcon from "@mui/icons-material/DataArrayOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {
  IndentifyBlankSpaces,
  IndentifyMilestones,
  IndentifyValues,
  getClauseMarkedText,
} from "./utils";
import { CODES } from "../../../../../../../utils/codes";
import { removeHtmlTags } from "../../../../../../../utils/partsCorrection";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import {
  milestonesReportFromClauses,
  valuesReportFromClauses,
} from "../../../../../../../services/versions/versionRequest";

import { ImportanceTag } from "../../../../../../../components/Tags/ImportanceTag";
import LoadingCardIA from "../../../../../../../components/loadings/loadingIaCard";
const ViewReview = () => {
  const numeration = 0;

  /**
   * Use Selector
   */
  const currentDocument = useSelector(getCurrentDocument);

  const statusRefNormative = useSelector(getReferenceNormativeStatus);

  /**
   * Use Navigate
   */

  const navigate = useNavigate();
  const { idDocument } = useParams();
  const location = useLocation();
  const scrollContainerClausesrRef = useRef(null);

  //  worker
  const reportsWorkerRef = useRef(null);
  const serviceUrl = env.REACT_APP_API_URL;
  const token = GetToken();
  const { state: locationState } = location;

  // DATA REPORTS BACKGROUND

  const listInfoReportsBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessReportsContract")
  );

  const listInfoReportsBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoReportsBackground) {
      let currentInfo = {};
      listInfoReportsBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoReportsBackgroundRef.current = currentInfo;
    }
  }, [listInfoReportsBackground]);

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();
  /**
   * Use state
   */
  const [typeView, setTypeView] = useState(true);

  const [filteredClauses, setFilteredClauses] = useState([]);
  const [isOpenVersions, setIsOpenVersions] = useState(false);
  const [openModalDiscard, setOpenModalDiscard] = useState(false);
  const [isOpenModalInfoReference, setIsOpenModalInfoReference] =
    useState(false);
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [adviceReportId, setAdviceReportId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFetchIa, setIsLoadingFetchIa] = useState(false);
  const [isOpenModalInfoReportAdvice, setIsOpenModalInfoReportAdvice] =
    useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalChooseReportToCreate, setIsOpenModalChooseReportToCreate] =
    useState(false);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const listReport = [
    REPORTS_TYPE.MILESTONES_AND_DATES,
    REPORTS_TYPE.VALUES,
    REPORTS_TYPE.POLICIES,
    REPORTS_TYPE.OBLIGATIONS,
  ];
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [idProcess, setIdProcess] = useState(false);

  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const [copied, setCopied] = useState(false);

  const [isLoadingIaReports, setIsLoadingIaReports] = useState(false);
  /**
   * Use Effect
   */

  const handleUpdateFilteredClauses = async (clauses, document = null) => {
    let newClauses = clauses;
    if (!document?.reviewInfo?.isValueReview) {
      newClauses = IndentifyValues(newClauses);
    }
    if (!document?.reviewInfo?.isMilestoneReview) {
      newClauses = IndentifyMilestones(newClauses);
    }
    newClauses = IndentifyBlankSpaces(newClauses);
    setFilteredClauses(newClauses);
  };

  useEffect(() => {
    handleUpdateFilteredClauses(currentDocument.clauses, currentDocument);
  }, [currentDocument]);

  const searchIntoClauses = (text) => {
    const clausesFilter = text
      ? currentDocument.clauses.filter((cla) => {
          const clauseTextContent = Array.from([cla.title, cla.text])
            .join(" ")
            .toLowerCase();
          return !!clauseTextContent.includes(text.toLowerCase());
        })
      : currentDocument.clauses;
    return clausesFilter;
  };

  const handleFilter = (text) => {
    const clausesFilter = searchIntoClauses(text);
    handleUpdateFilteredClauses(clausesFilter);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
    const newCategories = categoriesSelected.filter((c) => c !== category);
    if (newCategories.length > 0) {
      const filterCategories = currentDocument.clauses.filter((item) => {
        return item.categories.some((category) =>
          newCategories.includes(category.name)
        );
      });
      handleUpdateFilteredClauses(filterCategories);
    } else {
      handleUpdateFilteredClauses(currentDocument.clauses);
    }
  };

  const handleAgreeModalInputReport = async (text) => {
    try {
      setIsLoading(true);
      setIsOpenModalInputReport(false);
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const message = `${text}.\n\n cláusula: ${
        adviceReportId.title
      }.\n\n Consejo: ${
        adviceReportId?.advice?.advice ||
        adviceReportId?.advice?.advices?.[adviceReportId?.indexAdvice]
          ?.advice ||
        ""
      }.`;
      let obj = {
        userEmail: payloadToken.email,
        subject: "Reporte consejo",
        message,
        firstName: payloadToken.firstName || payloadToken.companyName,
        ...(payloadToken.firstSurname && {
          lastName: payloadToken.firstSurname,
        }),
      };
      // Actualizar servicio para reporte de consejos.
      const responseSendInfoToSupport = await SendInfoToSupport(obj);
      setIsOpenModalInfoReportAdvice(true);
      setResponseData(responseSendInfoToSupport);
    } catch (error) {
      console.log(
        "============== Start Error Handle Report Advice =============="
      );
      console.error(error);
      console.log(
        "============== Finish Error Handle Report Advice =============="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisagreeModalInputReport = () => {
    setIsOpenModalInputReport(false);
  };

  //Pronta solución desde back
  const buildPolicies = (data) => {
    const arrayPolicies = [];
    for (const report of data) {
      const addIdpolicie = report.policies.map((item) => ({
        ...item,
        id_text: report.id_text,
        text: report.text,
        usage: report.usage,
      }));
      arrayPolicies.push(addIdpolicie);
    }
    return arrayPolicies.flat();
  };

  const handleCreateReport = async (data) => {
    try {
      setIsOpenModalChooseReportToCreate(false);
      setIsLoadingInBackground(true);
      Mixpanel.track("Generar Informes AI ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      const clauses = currentDocument.clauses;
      if (reportsWorkerRef.current) {
        const body = {
          clauses,
        };
        const idProcess = uuidv4();
        setModuleName("backgroundProcessReportsContract");
        setIdProcess(idProcess);

        reportsWorkerRef.current.postMessage({
          body,
          token,
          serviceUrl,
          idProcess,
          typesReports: data,
        });

        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessReportsContract",
            updateData: {
              id: idProcess,
              nameFile: currentDocument?.name,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                url: location.pathname,
                otherInfo: {
                  documentSelected: currentDocument,
                  clauses,
                },
                state: {
                  processId: idProcess,
                },
              },
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      console.log(
        "============== Start Error Handle Generate Report =============="
      );
      console.error(error);
      console.log(
        "============== Finish Error Handle Generate Report =============="
      );
    } finally {
      setIsLoading(false);
    }
  };

  //plain text clauses
  const cleanText = (clauses) => {
    const currentClauses = clauses;
    let newClauses = currentClauses.map((clause) => {
      let subclauses = [];
      let paragraphs = [];
      const newTextClause = clause.text;
      const arraySubclauses = clause?.subclauses || [];
      const arrayParagraphs = clause?.paragraphs || [];
      for (const subclause of arraySubclauses) {
        subclauses.push({
          ...subclause,
          subclause: {
            ...subclause.subclause,
            text: subclause?.subclause?.text || "",
          },
        });
      }
      for (const paragraph of arrayParagraphs) {
        paragraphs.push({
          ...paragraph,
          paragraph: {
            ...paragraph.paragraph,
            text: paragraph?.paragraph?.text || "",
          },
        });
      }
      return {
        ...clause,
        text: newTextClause,
        subclauses,
        paragraphs,
      };
    });
    return newClauses;
  };

  const handleCopyText = ({ text }) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Error al copiar al portapapeles:", err));
  };

  useEffect(() => {
    Mixpanel.track("Panel de revisión", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  }, [payloadToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  // Reports Background

  const handleProcessReports = async (documentSelected, response) => {
    try {
      const reportsData = response.map((item) => {
        if (item?.typeReport === "Pólizas") {
          return {
            ...item,
            reports: buildPolicies(item.reports),
          };
        }
        return item;
      });
      dispatch(setReports({ reports: reportsData }));
      buildDocument(documentSelected);
      navigate(`reports`);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleMessageBackgroundReports = useCallback(async (event) => {
    try {
      const { data, idProcess } = event.data;
      const { success, data: response } = data;

      if (success) {
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessReportsContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: true,
              navigationInfo: {
                ...listInfoReportsBackgroundRef.current?.[idProcess]
                  ?.navigationInfo,
                otherInfo: {
                  ...listInfoReportsBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo,
                  responseReports: response,
                },
              },
            },
          })
        );

        setIsLoading(false);
        const userLeft =
          listInfoReportsBackgroundRef.current?.[idProcess]?.userLeft;

        if (!userLeft) {
          const documentSelected =
            listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.documentSelected;
          await handleProcessReports(documentSelected, response, false);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReportsContract",
              id: idProcess,
            })
          );
        }

        const itemsRef = Object.values(listInfoReportsBackgroundRef.current);

        if (!itemsRef.length) {
          reportsWorkerRef.current.removeEventListener(
            "message",
            handleMessageBackgroundReports
          );
          reportsWorkerRef.current.terminate();
        }
      } else {
        dispatch(
          updateListBackgroundProcesses({
            id: idProcess,
            type: "UPDATE",
            module: "backgroundProcessReportsContract",
            updateData: {
              isFetchingInfo: false,
              finishProcessing: false,
              isErrorProcessing: true,
              navigationInfo: {},
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const handleMessageRefReports = useRef(handleMessageBackgroundReports);

  useEffect(() => {
    if (!reportsWorkerRef.current) {
      // add worker
      reportsWorkerRef.current = new WorkerBuilder(WorkerReports);
    }
    reportsWorkerRef.current.removeEventListener(
      "message",
      handleMessageRefReports.current
    );

    reportsWorkerRef.current.addEventListener(
      "message",
      handleMessageBackgroundReports
    );
  }, [handleMessageBackgroundReports]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (listInfoReportsBackgroundRef.current?.[idProcess]?.finishProcessing) {
        setIsLoading(true);
        try {
          const documentSelected =
            listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.documentSelected;
          const response =
            listInfoReportsBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.responseReports;
          await handleProcessReports(documentSelected, response, true);
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReportsContract",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoReportsBackgroundRef, locationState]);

  // Build Document

  const buildDocument = (doc) => {
    let parts = [];
    if (doc?.parts?.partA || doc?.parts?.[0]) {
      const partA = doc?.parts?.partA || doc?.parts?.[0];
      const a = { ...partA };
      parts.push(a);
    }
    if (doc?.parts?.partB || doc?.parts?.[1]) {
      const partB = doc?.parts?.partA || doc?.parts?.[1];
      const b = { ...partB };
      parts.push(b);
    }
    if (doc?.parts?.partC || doc?.parts?.[2]) {
      const partC = doc?.parts?.partA || doc?.parts?.[2];
      const c = { ...partC };
      parts.push(c);
    }

    if (doc?.parts?.partD || doc?.parts?.[3]) {
      const partD = doc?.parts?.partA || doc?.parts?.[3];
      const d = { ...partD };
      parts.push(d);
    }

    const finalObj = {
      title: doc?.title,
      parts,
      fileType: doc?.fileType,
      letterheadAssociated: doc?.letterheadAssociated,
    };

    dispatch(
      setPartsHeaderText({
        partsHeaderText: doc?.partsHeader,
      })
    );

    dispatch(setInitialInfoLibrary(finalObj));
    if (doc?.clauses?.length) {
      const clauses = doc.clauses.map((cla) => {
        if (cla.parts) {
          return {
            ...cla,
            parts: cla.parts.partD
              ? [
                  cla.parts.partA,
                  cla.parts.partB,
                  cla.parts.partC,
                  cla.parts.partD,
                ]
              : cla.parts.partC
              ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
              : [cla.parts.partA, cla.parts.partB],
          };
        }
        return cla;
      });
      dispatch(setSelectedClauses({ clauses }));
    }
    dispatch(
      setName({
        name: doc?.name,
      })
    );

    dispatch(setHeaders(doc?.headers));

    dispatch(
      setFileType({
        fileType: Object.values(codesDocumentType).find(
          (code) => code._id === doc?.fileType
        ),
      })
    );
    if (doc?.fileType === codesDocumentType?.draft?._id) {
      dispatch(
        setVersionAssociated({
          id: doc?.versionAssociated,
        })
      );
      dispatch(
        setIdDraft({
          id: doc._id,
        })
      );
    } else {
      dispatch(
        setVersionAssociated({
          id: doc?._id,
        })
      );
    }
    dispatch(addSignatoryWithoutPart(doc?.otherSignatories));
  };

  const buildSummary = (response) => {
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      dispatch(
        setDocumentSummary({
          summary: response.data?.responseMessage?.data || "",
          isReview: true,
        })
      );
      return response.data?.responseMessage?.data;
    }
    return "";
  };

  const buildImportances = async (response, document, summary) => {
    if (
      response?.status === CODES.COD_RESPONSE_HTTP_OK &&
      response?.data?.responseCode === CODES.COD_RESPONSE_HTTP_ACCEPTED
    ) {
      const clausesWithImportance =
        response?.data?.responseMessage?.data?.clauses;
      const newClausesWithImportance = document.clauses.map(
        (clause, clauseIdx) => {
          return {
            ...clause,
            importance: clausesWithImportance?.[clauseIdx]?.importance,
          };
        }
      );
      const documentImportance = {
        ...document,
        clauses: newClausesWithImportance,
        reviewInfo: {
          ...document.reviewInfo,
          isImporanceReview: true,
        },
      };

      const responseAdvice = await getAdvicesFromClauses({
        clauses: newClausesWithImportance,
        represented: document?.represented,
        summary,
      });

      const newDocument = buildAdvices(responseAdvice, documentImportance);

      return newDocument;
    }
    return {
      ...document,
      reviewInfo: {
        ...document.reviewInfo,
        isImporanceReview: true,
      },
    };
  };

  const buildAdvices = (response, document) => {
    if (
      response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      const clausesWithAdvices = response?.data?.responseMessage?.data;
      const newClausesWithAdvices = document.clauses.map(
        (clause, clauseIdx) => {
          return {
            ...clause,
            advices: clausesWithAdvices?.[clauseIdx]?.advices,
          };
        }
      );
      return {
        ...document,
        clauses: newClausesWithAdvices,
        reviewInfo: {
          ...document.reviewInfo,
          isAdvicesReview: true,
        },
      };
    }
    return {
      ...document,
      reviewInfo: {
        ...document.reviewInfo,
        isAdvicesReview: true,
      },
    };
  };

  const buildMilestones = (response, document) => {
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response?.data?.responseCode === CODES.COD_RESPONSE_HTTP_ACCEPTED
    ) {
      const clausesWithMilestone = response?.data?.responseMessage?.data;

      const newClausesWithMilestone = document.clauses.map(
        (clause, clauseIdx) => {
          const id = `${clauseIdx + 1}.0.0`;
          const findMilestone = clausesWithMilestone.filter(
            (clause) => clause?.id_text === id
          );
          return {
            ...clause,
            milestones: findMilestone,
            hasMilestone: !!findMilestone?.length,
          };
        }
      );
      return {
        ...document,
        clauses: newClausesWithMilestone,
        reviewInfo: {
          ...document.reviewInfo,
          isMilestoneReview: true,
        },
      };
    }
    return {
      ...document,
      reviewInfo: {
        ...document.reviewInfo,
        isMilestoneReview: true,
      },
    };
  };

  const buildValues = (response, document) => {
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response?.data?.responseCode === CODES.COD_RESPONSE_HTTP_ACCEPTED
    ) {
      const clausesWithValue = response?.data?.responseMessage?.data;

      const newClausesWithValue = document.clauses.map((clause, clauseIdx) => {
        const id = `${clauseIdx + 1}.0.0`;
        const findValue = clausesWithValue.filter(
          (clause) => clause?.id_text === id
        );
        return {
          ...clause,
          values: findValue,
          hasValues: !!findValue.length,
        };
      });
      return {
        ...document,
        clauses: newClausesWithValue,
        reviewInfo: {
          ...document.reviewInfo,
          isValueReview: true,
        },
      };
    }
    return {
      ...document,
      reviewInfo: {
        ...document.reviewInfo,
        isValueReview: true,
      },
    };
  };

  const buildReferences = (response, document) => {
    if (
      response.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
    ) {
      const clausesWithReferences = response?.data?.responseMessage?.data;

      const newClausesWithReferences = document.clauses.map(
        (clause, clauseIdx) => {
          const id = `${clauseIdx + 1}.0.0`;
          const findReference = clausesWithReferences.filter(
            (clause) => clause?.id_text === id
          );
          return {
            ...clause,
            references: findReference,
            hasReferences: !!findReference,
          };
        }
      );
      return {
        ...document,
        clauses: newClausesWithReferences,
        reviewInfo: {
          ...document.reviewInfo,
          isReferencesReview: true,
        },
      };
    }
    return {
      ...document,
      reviewInfo: {
        ...document.reviewInfo,
        isReferencesReview: true,
      },
    };
  };

  const fetchIaData = async () => {
    let summary = "";
    const fetchData = [];
    const typeFetch = [];
    const clauses = currentDocument?.clauses || [];
    const represented = currentDocument?.represented || [];

    const hasSummary = currentDocument?.reviewInfo?.isSummaryReview;
    const hasClauses = currentDocument?.clauses?.length;

    const isReferencesReview = currentDocument?.reviewInfo?.isReferencesReview;
    const isValueReview = currentDocument?.reviewInfo?.isValueReview;
    const isMilestoneReview = currentDocument?.reviewInfo?.isMilestoneReview;
    const isImporanceReview = currentDocument?.reviewInfo?.isImporanceReview;

    if (!hasSummary && hasClauses) {
      const response = await getSummaryFromClauses({
        clauses: currentDocument?.clauses,
      });
      summary = buildSummary(response);
    }

    if (!isImporanceReview && !hasSummary) {
      typeFetch.push("importance");
      fetchData.push(getImportanceFromClauses({ clauses, represented }));
    }

    if (!isMilestoneReview && !hasSummary) {
      typeFetch.push("milestone");
      fetchData.push(milestonesReportFromClauses({ clauses }));
    }

    if (!isValueReview && !hasSummary) {
      typeFetch.push("value");
      fetchData.push(valuesReportFromClauses({ clauses }));
    }

    if (!isReferencesReview && statusRefNormative === "fetch" && !hasSummary) {
      typeFetch.push("reference");
      fetchData.push(getRefNormativeDocument({ clauses: cleanText(clauses) }));
    }
    if (fetchData?.length) {
      try {
        setIsLoadingIaReports(true);

        const allResponses = await Promise.allSettled(fetchData);

        let newDocument = currentDocument;

        for (let index = 0; index < allResponses.length; index++) {
          const response = allResponses[index];
          const type = typeFetch[index];

          if (type === "importance") {
            newDocument = await buildImportances(
              response.value,
              newDocument,
              summary
            );
          }
          if (type === "milestone") {
            newDocument = buildMilestones(response.value, newDocument);
          }
          if (type === "value") {
            newDocument = buildValues(response.value, newDocument);
          }
          if (type === "reference") {
            newDocument = buildReferences(response.value, newDocument);
          }
        }

        if (allResponses.length) {
          dispatch(
            setSelectedClausesWithoutCheckPart({
              clauses: newDocument.clauses,
              reviewInfo: newDocument.reviewInfo,
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingIaReports(false);
      }
    }
  };

  useEffect(() => {
    if (currentDocument) fetchIaData();
  }, [currentDocument]);

  return (
    <Container fluid>
      {isLoadingIaReports && <LoadingCardIA />}
      {(isLoading || isLoadingFetchIa) && !isLoadingInBackground && (
        <LoadingContent />
      )}

      {isLoadingInBackground && !(isLoading || isLoadingFetchIa) && (
        <LoadingContentInBackground
          actions={true}
          open={isLoadingInBackground}
          setIsOpen={setIsLoadingInBackground}
          moduleName={moduleName}
          processId={idProcess}
        />
      )}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Panel de Revisión"} />
        </Col>
        <Col md={7}></Col>
      </Row>
      <Row className="mt-4 flex-wrap g-4 align-items-center">
        <Col xs={"12"} md={"auto"} className="me-auto flex-grow-1">
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(e) => {
                handleFilter(e.target.value);
              }}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>

        <Col xs={"12"} md={"auto"} className={"d-flex flex-grow-1"}>
          <Row className="align-items-center ms-md-auto g-4">
            <Col xs={"auto"}>
              <ButtonGroup variant="contained" size="large">
                <Button
                  variant="text"
                  className={
                    !typeView
                      ? "custom-input__link"
                      : "custom-input__link selected-option"
                  }
                  onClick={() => {
                    setTypeView(true);
                  }}
                >
                  <EventNoteIcon className="logo-primary-color" />
                </Button>
                <Button
                  variant="text"
                  className={
                    typeView
                      ? "custom-input__link"
                      : "custom-input__link selected-option"
                  }
                  onClick={() => {
                    setTypeView(false);
                  }}
                >
                  <SubjectOutlinedIcon className="logo-primary-color" />
                </Button>
              </ButtonGroup>
            </Col>
            <Col xs={"auto"}>
              <FilterCategory
                categoriesList={categoriesList}
                list={currentDocument.clauses}
                setFilteredInfo={handleUpdateFilteredClauses}
                handleCategoriesSelected={handleCategoriesSelected}
              />
            </Col>
            {idDocument !== "NEW" && (
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<FindInPageOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  size="large"
                  onClick={() => {
                    setIsOpenVersions(true);
                    Mixpanel.track("Btn Navegar entre versiones ", {
                      email: payloadToken?.email,
                      companyName: payloadToken?.companyName,
                    });
                  }}
                >
                  Navegar entre versiones
                </Button>
              </Col>
            )}
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<FactCheckOutlinedIcon />}
                className="custom-input__button__iaColor"
                size="large"
                onClick={(e) => {
                  if (isLoadingIaReports) return;
                  setIsOpenModalChooseReportToCreate(true);
                  Mixpanel.track("Funcionalidades AI Informes ", {
                    email: payloadToken?.email,
                    companyName: payloadToken?.companyName,
                  });
                }}
              >
                {isLoadingIaReports ? (
                  <Spinner
                    animation="border"
                    className="spinner__white-background"
                  />
                ) : (
                  "Informes"
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row style={{ flexDirection: "row-reverse", rowGap: "10px" }}>
        {categoriesSelected.map((category) => {
          return (
            <Col xs={"auto"} key={category?._id}>
              <span className="caption custom-badges__secundary">
                {category + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteCategory(category)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} style={{ paddingLeft: "1.5%" }}>
          <Row>
            <Col md={"auto"} className="crud-titles">
              {currentDocument?.title}
            </Col>
          </Row>
        </Col>
      </Row>

      {currentDocument?.summary && (
        <Container fluid>
          <Row className="mt-3">
            <Accordion className="custom-accordion">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Row style={{ width: "100%" }}>
                  <Col md={4} className="heading__primary-color">
                    Resumen
                  </Col>
                </Row>
              </AccordionSummary>
              <AccordionDetails>
                <Col md={"auto"} className="body-content text-justify">
                  {currentDocument?.summary}
                  <IconButton
                    title={copied ? "¡Copiado!" : "Copiar texto"}
                    aria-label="close"
                    onClick={() =>
                      handleCopyText({
                        text: removeHtmlTags(currentDocument?.summary),
                      })
                    }
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      padding: 0,
                    }}
                  >
                    {copied ? (
                      <AssignmentTurnedInOutlinedIcon />
                    ) : (
                      <ContentCopyOutlinedIcon />
                    )}
                  </IconButton>
                </Col>
              </AccordionDetails>
            </Accordion>
          </Row>
        </Container>
      )}
      <br />
      <div
        className="custom-accordion__container position-relative"
        style={{ paddingBottom: "5%" }}
        ref={scrollContainerClausesrRef}
      >
        {!typeView ? (
          <ViewWord
            currentDocument={currentDocument}
            filteredClauses={filteredClauses}
            numeration={numeration}
          />
        ) : (
          <Container fluid>
            <Row className="heading__primary-color">Partes</Row>
            <Row>
              {currentDocument?.parts?.length > 0 &&
                currentDocument.parts.map((rol, index) => (
                  <Accordion key={"part" + index} className="custom-accordion">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Row style={{ width: "100%" }}>
                        <Col md={4} className="heading__primary-color">
                          {rol.name ? rol.name : "Parte sin nombre"}
                        </Col>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails>
                      {rol?.signatories?.length > 0 &&
                        rol.signatories.map((part) => (
                          <Accordion
                            className="custom-accordion"
                            key={part?._id}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Row
                                style={{ width: "100%", alignItems: "center" }}
                              >
                                <Col
                                  xs={"auto"}
                                  className="col-start heading__primary-color"
                                >
                                  <p className="heading">
                                    {part?.information?.name || ""}
                                  </p>
                                </Col>
                              </Row>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul>
                                {part?.signatories?.length > 0 &&
                                  part.signatories.map((sig) => {
                                    const cleanNumberDocument =
                                      sig?.documentNumber?.replace(/[.,]/g, "");
                                    const stringNumberDocument =
                                      Number(cleanNumberDocument);
                                    const documentNumberFormated =
                                      stringNumberDocument.toLocaleString(
                                        "es",
                                        { style: "decimal", useGrouping: true }
                                      );
                                    return (
                                      <li key={uuidv4()} className="caption">
                                        {`${sig?.name ? sig.name + ", " : ""}${
                                          sig?.documentType || ""
                                        } ${
                                          documentNumberFormated !== "NaN"
                                            ? documentNumberFormated
                                            : ""
                                        }`}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      {!rol?.signatories?.length && (
                        <p className="caption mt-2" style={{ color: "red" }}>
                          Sin partes asociadas al Rol Contractual
                        </p>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Row>

            <Row
              className="heading__primary-color"
              style={{ marginTop: "0.5%" }}
            >
              Cláusulas
            </Row>
            <Row>
              {filteredClauses?.length > 0 ? (
                filteredClauses.map((cla, index) => {
                  const clause = getClauseMarkedText(cla);
                  return (
                    <Accordion
                      index={"cla" + index}
                      className="custom-accordion"
                      key={cla?._id}
                    >
                      <Row style={{ alignItems: "center" }}>
                        <Col>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Col>
                              <Row style={{ width: "100%" }}>
                                <p className="heading__primary-color">
                                  {`${
                                    numeration === 0
                                      ? index + 1
                                      : "Cláusula " + NUMBERINGWORDSA[index + 1]
                                  }. ${cla.title}`}
                                </p>
                              </Row>
                              <br />
                              <Row>
                                <Col className="subheading">
                                  {cla?.categories?.length > 0 &&
                                    cla.categories.map((cat, i) => {
                                      return (
                                        <span
                                          key={uuidv4()}
                                          style={{ marginRight: "1%" }}
                                          className="caption custom-badges__secundary"
                                        >
                                          {cat.name}
                                        </span>
                                      );
                                    })}
                                </Col>
                              </Row>
                            </Col>
                          </AccordionSummary>
                        </Col>
                        {cla?.advices?.length > 0 && (
                          <Tooltip
                            title={<h3>Consejos</h3>}
                            PopperProps={{
                              disablePortal: true,
                            }}
                          >
                            <Col xs={"auto"}>
                              <PopoverArrayCards
                                icon={
                                  <LightbulbOutlinedIcon fontSize="large" />
                                }
                                containerRef={scrollContainerClausesrRef}
                                parentId="clause-parent"
                                classNameButton="icon-button__default-color"
                                type="xlarge"
                                items={cla?.advices.map((ad, indexAdvice) => ({
                                  ...ad,
                                  description: ad.advice,
                                  textToCopy: ad.advice,
                                  color:
                                    ad?.type === "generative" ? "ia" : "dark",
                                  textAditional:
                                    ad?.type === "generative"
                                      ? "Consejo creado por IA"
                                      : "",
                                  actions: [
                                    {
                                      text: "Reportar",
                                      handleAction: () => {
                                        setIsOpenModalInputReport(true);
                                        setAdviceReportId({
                                          ...cla,
                                          indexAdvice,
                                        });
                                      },
                                      show: true,
                                    },
                                  ],
                                }))}
                              />
                            </Col>
                          </Tooltip>
                        )}
                        {cla?.hasMilestone && (
                          <Col xs={"auto"}>
                            <Tooltip title={<h3>Hitos</h3>}>
                              <IconButton
                                aria-label="milestones"
                                onClick={() => console.log("close")}
                                className="icon-button__milestones"
                              >
                                <AccessTimeOutlinedIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </Col>
                        )}
                        {cla?.hasValues && (
                          <Col xs={"auto"}>
                            <Tooltip title={<h3>Valores</h3>}>
                              <IconButton
                                aria-label="values"
                                onClick={() => console.log("close")}
                                className="icon-button__values"
                              >
                                <MonetizationOnOutlinedIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </Col>
                        )}
                        {cla?.hasBlankspaces && (
                          <Col xs={"auto"}>
                            <Tooltip title={<h3>Espacios en blanco</h3>}>
                              <IconButton
                                aria-label="blankspaces"
                                onClick={() => console.log("close")}
                                className="icon-button__blankspaces"
                              >
                                <DataArrayOutlinedIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          </Col>
                        )}
                        <ImportanceTag clause={cla} />
                      </Row>
                      <AccordionDetails>
                        <Row style={{ margin: "2% 0" }}></Row>

                        <Row style={{ paddingLeft: "1.3%" }}>
                          <span className="caption">
                            {ReactHtmlParser(clause?.text || "")}
                            {cla?.subclauses?.length > 0 &&
                              cla.subclauses.map((sub, i) => {
                                return (
                                  <Row
                                    key={uuidv4()}
                                    className="justify-text clauses-number"
                                    style={{ margin: "1% 0" }}
                                  >
                                    <Col xs={"auto"}>
                                      <b>{`${index + 1}.${i + 1} ${
                                        sub.subclause.title + ":" || ""
                                      }`}</b>
                                    </Col>
                                    <Col>
                                      <span className="caption">
                                        <p>
                                          {ReactHtmlParser(
                                            sub?.subclause?.text || ""
                                          )}
                                        </p>
                                      </span>
                                    </Col>
                                    <Container>
                                      {sub?.subclause?.childs?.length > 0 &&
                                        sub.subclause.childs.map(
                                          (child, index2) => {
                                            return (
                                              <Row
                                                key={uuidv4()}
                                                className="justify-text clauses-number"
                                                style={{
                                                  paddingLeft: "1.3%",
                                                  margin: "1% 0",
                                                }}
                                              >
                                                <div
                                                  className="caption"
                                                  style={{
                                                    display: "inline",
                                                  }}
                                                >
                                                  <b>{`${index + 1}.${i + 1}.${
                                                    index2 + 1
                                                  } ${child?.title || ""}:`}</b>
                                                  <p>
                                                    {ReactHtmlParser(
                                                      child?.text || ""
                                                    )}
                                                  </p>
                                                </div>
                                              </Row>
                                            );
                                          }
                                        )}
                                    </Container>
                                  </Row>
                                );
                              })}
                            <Container fluid style={{ paddingLeft: "1%" }}>
                              {cla?.paragraphs?.length > 0 &&
                                cla.paragraphs.map((paragraph, index3) => {
                                  return (
                                    <Row
                                      key={uuidv4()}
                                      className="justify-text mb-3 mt-2"
                                    >
                                      <Col xs={"auto"}>
                                        <b>{`Parágrafo ${
                                          NUMBERINGWORDSO[index3 + 1]
                                        }`}</b>
                                      </Col>
                                      <Col xs={"auto"}>
                                        {ReactHtmlParser(
                                          paragraph?.paragraph?.text || ""
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                })}
                            </Container>
                          </span>
                        </Row>
                        <Row
                          style={{
                            justifyContent: "right",
                            margin: "2% 0",
                          }}
                        >
                          <Col md={4}>
                            <Button
                              variant="contained"
                              startIcon={<LocalLibraryOutlinedIcon />}
                              className="custom-input__button__secondary-color"
                              onClick={() => {
                                const clause = currentDocument.clauses[index];
                                dispatch(setClauseSelected(clause));
                                navigate(`edit-clause/reviewDoc`);
                              }}
                            >
                              Agregar esta cláusula a mi biblioteca
                            </Button>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <p className="container_text__clause-text label">
                  Sin cláusulas.
                </p>
              )}
            </Row>
          </Container>
        )}
      </div>

      <ModalDecision
        title={"Descartar revisión"}
        message={"¿Está seguro?"}
        open={openModalDiscard}
        onClose={() => {
          setOpenModalDiscard(false);
        }}
        agreeText="Descartar"
        disagreeText="Cancelar"
        handleAgree={() => {
          navigate("/service/home");
        }}
        handleDisagree={() => {
          setOpenModalDiscard(false);
        }}
      />
      <ModalInfoFullScreen
        title={"Referencia normativa"}
        description={TEXT_LOREM_IPSUM.MEDIUM} //referenceData
        onClose={() => setIsOpenModalInfoReference(false)}
        open={isOpenModalInfoReference}
        textBackButton={"Volver a la revisión"}
      />
      <ModalInpuText
        title={"Reportar"}
        message={
          "Cuéntanos si crees que la sugerencia es irrelevante o imprecisa. ¡Consideraremos tus sugerencias para mejorar Legal AI!"
        }
        agreeText={"Reportar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={handleDisagreeModalInputReport}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
      <ModalInfo
        title={"Reportar consejo"}
        onClose={() => setIsOpenModalInfoReportAdvice(false)}
        open={isOpenModalInfoReportAdvice}
        responseData={responseData}
        confirmationText={"Aceptar"}
      />
      <ModalListSelectCheck
        open={isOpenModalChooseReportToCreate}
        onClose={() => setIsOpenModalChooseReportToCreate(false)}
        title={"Generar informe de:"}
        list={listReport}
        handleAgree={handleCreateReport}
      />
      <VersionBar
        isOpen={isOpenVersions}
        setIsOpen={setIsOpenVersions}
        setIsLoadingFetch={setIsLoadingFetchIa}
      />
    </Container>
  );
};

const ViewWord = ({ currentDocument, filteredClauses, numeration }) => (
  <Container fluid style={{ height: "100%" }}>
    <Row className="heading__primary-color">Partes</Row>
    <Row>
      {currentDocument?.parts?.length > 0 &&
        currentDocument.parts.map((part, index) => (
          <Container key={part?._id} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. ${
                part.name || "Sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__subclause">
              <ul>
                {part?.signatories?.length > 0 ? (
                  part.signatories.map((sig, i) => (
                    <li key={uuidv4()} className="label">
                      {sig.type === "natural" && sig.gender === "M"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPN.type
                          }), ${sig.text}`
                        : sig.type === "natural" && sig.gender === "F"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariAPN.type
                          }), ${signatoriesTypes.SignatariAPN.text}`
                        : sig.type === "legal" && sig.gender === "M"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPJ.type
                          }), ${signatoriesTypes.SignatariOPJ.text}`
                        : sig.type === "legal" && sig.gender === "F"
                        ? `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariAPJ.type
                          }), ${signatoriesTypes.SignatariAPJ.text}`
                        : `Signatario ${i + 1} (${
                            signatoriesTypes.SignatariOPN.type
                          }), ${sig.text}`}
                    </li>
                  ))
                ) : (
                  <li className="label">Sin signatarios</li>
                )}
              </ul>
            </Row>
            <Row className="container_text__subclause label">
              {ReactHtmlParser(part.text || "Sin texto final")}
            </Row>
          </Container>
        ))}
    </Row>
    <Row className="heading__primary-color">Encabezados</Row>
    <Row>
      {currentDocument?.headers?.length > 0 ? (
        currentDocument.headers.map((header, index) => (
          <Container key={header?._id} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. ${
                header.title || "Sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__clause-text label">
              {ReactHtmlParser(header?.text || "")}
            </Row>
            <Row className="container_text__clause-text">
              <ul>
                {header?.childs?.length > 0 &&
                  header.childs.map((child, index2) => (
                    <Container
                      key={child?._id}
                      className="container_text__subclause"
                    >
                      <Row>
                        <Col className="heading__primary-color">{`${
                          index + 1
                        }.${index2 + 1}`}</Col>
                      </Row>
                      <Row className="container_text__clause-text label">
                        {ReactHtmlParser(child?.text || "")}
                      </Row>
                    </Container>
                  ))}
              </ul>
            </Row>
          </Container>
        ))
      ) : (
        <p className="container_text__clause-text label">Sin encabezados.</p>
      )}
    </Row>
    <Row className="heading__primary-color">Cláusulas</Row>
    <Row>
      {filteredClauses?.length > 0 ? (
        filteredClauses.map((cla, index) => (
          <Row key={cla?._id}>
            <Container className="container_text">
              <Row>
                <Col md={4} className="heading__primary-color">{`${
                  numeration === 0
                    ? index + 1
                    : "Cláusula " + NUMBERINGWORDSA[index + 1]
                }. ${cla.title || "Sin titulo"}`}</Col>
              </Row>
              <Row className="container_text__clause-text label">
                {ReactHtmlParser(`<p>${cla.text}</p>`)}
              </Row>
            </Container>
            {cla?.subclauses?.length > 0 &&
              cla.subclauses.map((sub, index2) => (
                <Container key={sub?._id} className="container_text__subclause">
                  <Row style={{ alignItems: "center" }}>
                    <Col xs={"auto"} className="heading__primary-color">
                      {`${index + 1}.${index2 + 1}. ${
                        sub?.subclause?.title || ""
                      }`}
                    </Col>
                    <Col className="container_text__clause-text label">
                      {ReactHtmlParser(`<p>${sub?.subclause?.text}</p>`)}
                    </Col>
                  </Row>
                  <Row className="container_text__clause-text label"></Row>
                  {sub?.subclause?.childs?.length > 0 &&
                    sub.subclause.childs.map((child, index4) => (
                      <Container
                        key={child?._id}
                        className="container_text__subclause"
                      >
                        <Row>
                          <Col className="heading__primary-color">{`${
                            index + 1
                          }.${index2 + 1}.${index4 + 1}. ${
                            child?.title || ""
                          }`}</Col>
                        </Row>
                        <Row className="container_text__clause-text label">
                          {ReactHtmlParser(`<p>${child?.text}</p>`)}
                        </Row>
                      </Container>
                    ))}
                </Container>
              ))}
            {cla?.paragraphs?.length > 0 &&
              cla.paragraphs.map((para, index3) => (
                <Container key={uuidv4()} className="container_text__subclause">
                  <Row>
                    <Col className="heading__primary-color">{`Parágrafo ${
                      NUMBERINGWORDSO[index3 + 1]
                    } ${para?.paragraph?.title || ""}`}</Col>
                  </Row>
                  <Row className="container_text__clause-text label">
                    {ReactHtmlParser(`<p>${para?.paragraph?.text}</p>`)}
                  </Row>
                </Container>
              ))}
          </Row>
        ))
      ) : (
        <p className="container_text__clause-text label">Sin cláusulas.</p>
      )}
    </Row>
    <Row className="heading__primary-color">Firmas</Row>
    <Row>
      {currentDocument?.parts?.length > 0 &&
        currentDocument.parts.map((part, index) => (
          <Container key={uuidv4()} className="container_text">
            <Row>
              <Col className="heading__primary-color">{`${index + 1}. Firma ${
                part.name || "sin titulo"
              }`}</Col>
            </Row>
            <Row className="container_text__subclause">
              <ul>
                {part.signatories.length > 0 ? (
                  part.signatories.map((sig, i) =>
                    sig?.signatures?.attributes?.map((attri, index) => (
                      <li key={uuidv4()} className="label">
                        {attri.name + " " + attri.value}
                      </li>
                    ))
                  )
                ) : (
                  <li className="label">Sin firmas</li>
                )}
              </ul>
            </Row>
          </Container>
        ))}
    </Row>
  </Container>
);

export default ViewReview;
