import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//Material Import
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";
import CustomSearchFilter from "../../../../../components/Search/CustomSearchFilter";
import SectionTitle from "../../../../../components/sectionTitle";
//Icons
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getUserPermissions,
  setPermissions,
} from "./currentUserSlice";
//Services
//Utils
import { v4 as uuidv4 } from "uuid";
import { Modules, ModulesExternal } from "./Modules";
const UserPermissions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataPermissions = useSelector(getUserPermissions);
  const getCurrentUserInfo = useSelector(getCurrentUser);

  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [dataFormatFilter, setDataFormatFilter] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataPlain, setDataPlain] = useState([]);

  const handleCheckedModule = (e, module, action) => {
    const data = dataPlain.map((item) => {
      if (item._id === module._id) {
        return {
          ...item,
          actions: {
            ...item.actions,
            [action.title]: e.target.checked,
          },
        };
      }
      return item;
    });
    setDataPlain(data);
    const dataFormat = buildData(data);
    setDataFormatFilter(dataFormat);
    setFilteredData(dataFormat);
  };

  const handleCheckedSubModule = (e, module, subModule, action) => {
    const data = dataPlain.map((item) => {
      if (item._id === module._id) {
        return {
          ...item,
          subModules: item.subModules.map((sub) => {
            if (sub._id === subModule._id) {
              return {
                ...sub,
                actions: {
                  ...sub.actions,
                  [action.title]: e.target.checked,
                },
              };
            }
            return sub;
          }),
        };
      }
      return item;
    });
    setDataPlain(data);
    const dataFormat = buildData(data);
    setDataFormatFilter(dataFormat);
    setFilteredData(dataFormat);
  };

  const handleUpdateUsersPermissions = () => {
    dispatch(setPermissions({ permissions: dataPlain }));
    navigate(-1);
  };

  const buildData = (data) => {
    return data.map(permission => 
      getCurrentUserInfo?.subRole === "EXTERNAL SOLICITANTE" ?
      ModulesExternal(permission) :
      Modules(permission)
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = [...dataPermissions];
        const dataOrder = data.sort((a, b) => a.module.localeCompare(b.module));
        setDataPlain(dataOrder);
        const dataFormat = buildData(dataOrder);
        setDataFormatFilter(dataFormat);
        setFilteredData(dataFormat);
      } catch (error) {
        console.log("======= Error Fetch Users Permissions ========");
        console.error(error);
        console.log("======= Error Fetch Update Users Permissions ========");
      }
    };
    fetchData();
  }, [dataPermissions]);

  return (
    <Container fluid className="custom-container-scroll">
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Gestionar permisos"} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Selecciona los permisos que le dejarás a tu usuario{" "}
          {getCurrentUserInfo?.role === "EXTERNAL"
            ? "solicitante"
            : "de servicios"}
          .
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={5}>
          <CustomSearchFilter
            list={dataFormatFilter}
            setFilteredData={setFilteredData}
            placeholder={"Buscar permisos"}
          />
        </Col>
      </Row>
      <br />
      {filteredData?.map((module, idx) => {
        return (
          <div key={uuidv4()}>
            {module?.authorized && (
              <Row>
                <p className="heading__primary-color">{module?.title}</p>
                {module?.actions?.length > 0 &&
                  module.actions.map((action) => (
                    <Row
                      key={action?.name}
                      style={{
                        alignItems: "center",
                        //borderBottom: "1px solid #e0e0e0"
                      }}
                    >
                      <Col xs={"auto"}>
                        <Checkbox
                          checked={action.isCheck}
                          onClick={(e) => {
                            const mod = dataPermissions.find(p => p._id === module?._id);
                            handleCheckedModule(e, mod, action)
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 20 },
                            color: "#C5C7D0",
                            "&.Mui-checked": {
                              color: "#00374f",
                            },
                          }}
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <p className="caption__disabled">{action.name}</p>
                      </Col>
                    </Row>
                  ))}
                {module?.subModules?.length > 0 &&
                  module.subModules.map(
                    (subModule, index) =>
                      subModule?.authorized && (
                        <div key={uuidv4()}>
                          <p className="caption" style={{ marginLeft: "1rem" }}>
                            {subModule.title}
                          </p>
                          {subModule?.actions?.length > 0 && 
                            subModule.actions.map((action) => (
                            <Row
                              key={action?.name}
                              style={{
                                alignItems: "center",
                              }}
                            >
                              <Col xs={"auto"}>
                                <Checkbox
                                  checked={action.isCheck}
                                  onClick={(e) =>{
                                    const mod = dataPermissions?.find(p => p._id === module?._id);
                                    const subMo = mod?.subModules?.find(sp => sp?._id === subModule?._id);
                                    handleCheckedSubModule(
                                      e,
                                      mod,
                                      subMo,
                                      action
                                    )
                                  }}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                    color: "#C5C7D0",
                                    "&.Mui-checked": {
                                      color: "#00374f",
                                    },
                                  }}
                                />
                              </Col>
                              <Col xs={"auto"}>
                                <p className="caption__disabled">
                                  {action.name}
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )
                  )}
              </Row>
            )}
            <hr />
          </div>
        );
      })}
      <br />
      <Row style={{ justifyContent: "end", marginRight: "5%" }}>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleUpdateUsersPermissions}
          >
            Actualizar permisos
          </Button>
        </Col>
      </Row>

      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />
    </Container>
  );
};

export default UserPermissions;
