import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { Col, Container, Form, Row } from "react-bootstrap";
import { getFillBlankSpacesOtherDocs } from "../../utils/countBlankSpaces";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { flashItemSelected } from "../../utils/ScrollDocItems";

import changeDefaultBlankSpaceName from "../../utils/changeDefaultBlankSpaceName";

export const FillBlankSpaceOtherDocument = ({
  indexClause,
  setCurrentDocInfo,
  currentDocInfo,
}) => {
  //States
  const [isCompleteBlankSpaces, setIsCompleteBlankspaces] = useState(false);
  //Selectors

  useEffect(() => {
    if (currentDocInfo) {
      const fillBlankSpaces = getFillBlankSpacesOtherDocs(currentDocInfo);
      const allBlankSpaces = currentDocInfo?.blankSpaces?.length;

      if (fillBlankSpaces > 0) {
        if (fillBlankSpaces / allBlankSpaces === 1 || allBlankSpaces === 0) {
          setIsCompleteBlankspaces(true);
        } else {
          setIsCompleteBlankspaces(false);
        }
      } else {
        setIsCompleteBlankspaces(false);
      }
    }
  }, [currentDocInfo]);

  const handleFillBlankspaces = (textHtml, { name, value, id }) => {
    if (!textHtml || !name) return "";

    //Convertimos el string html a un Object Document
    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    //Validamos que exista la data para cambiar su valor
    const blankSpaceElement =
      htmlElement?.querySelector(`[data-blank-space="${name}"]`) ??
      htmlElement?.querySelector(`[data-blank-space="blankSpace-${id}"]`);
    if (blankSpaceElement) {
      blankSpaceElement.innerText = value
        ? `¬${value}¬`
        : `¬${changeDefaultBlankSpaceName(name)}¬`;
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument.replace(
      /<\/?(html|head|body)>/gi,
      ""
    );

    return cleanedString;
  };

  const handleBlankspacesParagraphs = ({ name, value, id }) => {
    const newText = handleFillBlankspaces(currentDocInfo?.text, {
      name,
      value,
      id,
    });

    setCurrentDocInfo((prev) => {
      return {
        ...prev,
        text: newText,
        blankSpaces: prev.blankSpaces?.map((blankspace, index) => {
          if (blankspace.name === name) {
            return { ...blankspace, value: value };
          } else return blankspace;
        }),
      };
    });
  };

  const getBlankSpaceDataAttribute = ({ name, id, blankSpaceIndex }) => {
    const parser = new DOMParser();
    const htmlElement = parser.parseFromString(
      currentDocInfo?.text,
      "text/html"
    );
    const waysToGetBlankSpaceDataAttribute = [
      `[data-blank-space="blankSpace-${id}"]`,
      `[data-blank-space="blankSpace-${blankSpaceIndex}"]`,
      `[data-blank-space="${name}"]`,
    ];
    for (const way of waysToGetBlankSpaceDataAttribute) {
      if (htmlElement?.querySelector(way)) {
        return way;
      }
    }
  };

  return (
    <Accordion
      className="blankspaces__box"
      defaultExpanded
      id={`c.${indexClause + 1}.0.0`}
    >
      <AccordionSummary>
        <Container
          fluid
          className="container-blankspaces__box"
          style={{ margin: "0 !important" }}
        >
          <Row>
            <Col xs={10} className="heading__primary-color">
              Espacios en blanco
            </Col>
            <Col xs={1}>
              {isCompleteBlankSpaces ? (
                <CheckCircleOutlineIcon fontSize="large" />
              ) : (
                <InfoOutlinedIcon
                  className="heading__error-color"
                  fontSize="large"
                />
              )}
            </Col>
            <Col xs={1}>
              <KeyboardArrowDownIcon fontSize="large" />
            </Col>
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        {currentDocInfo.blankSpaces && (
          <div className="d-flex flex-column gap-5">
            {currentDocInfo.blankSpaces?.map((blankspace, index) => (
              <Container
                className="d-flex flex-column gap-3"
                key={`otherDoc_${index}`}
              >
                <Row>
                  <Col>
                    <p className="heading__subtitle">
                      {changeDefaultBlankSpaceName(blankspace?.name)}
                    </p>
                    {blankspace?.description && (
                      <p className="label__description">
                        {blankspace?.description}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Form.Group
                    className="my-1"
                    key={`blankspace-paragraph-${blankspace.index}`}
                  >
                    <Form.Control
                      placeholder={`Escribir aquí`}
                      defaultValue={blankspace.value}
                      bsPrefix={"input-group-container__no-icon label"}
                      onChange={(e) => {
                        handleBlankspacesParagraphs({
                          name: blankspace.name,
                          value: e.target.value,
                          id: blankspace.id,
                        });
                      }}
                      onFocus={() => {
                        const blankSpaceName = getBlankSpaceDataAttribute({
                          name: blankspace.name,
                          id: blankspace.id,
                          blankSpaceIndex: index + 1,
                        });
                        flashItemSelected(
                          blankSpaceName,
                          "query",
                          `content-otherDocxs`
                        );
                      }}
                    />
                  </Form.Group>
                </Row>
              </Container>
            ))}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
