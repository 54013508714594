// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* eslint-disable no-restricted-globals */
  self.addEventListener("message", async (event) => {
    const { serviceUrl, token, idProcess, files, company, parts } = event.data;

    const formData = new FormData();
    formData.append("parts", JSON.stringify(parts));
    formData.append("file", files);
    formData.append("company", company);
    try {
      const response = await fetch(
        `${serviceUrl}/documents/segmentAndClassifyClauses`,
        {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        self.postMessage({
          data: errorData,
          idProcess,
          status: response.status,
          statusText: response.statusText,
        });
        return;
      }

      const data = await response.json();
      self.postMessage({
        data: data,
        parts,
        status: response.status,
        statusText: response.statusText,
        idProcess,
      });
    } catch (error) {
      self.postMessage({
        data: {
          success: false,
          responseCode: 1001,
          responseMessage: "Error segmentando el documento.",
        },
        idProcess,
        status: error.response?.status || 500,
        statusText: error.response?.statusText || "Error",
      });
    }
    /* eslint-disable no-restricted-globals */
  });
};
