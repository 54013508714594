import React, { useEffect, useState, useRef } from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { MDBDataTableV5 } from "mdbreact";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../loadingContent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PopoverActions from "../Popover/PopoverActions";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  deleteSignatoryTemporarily,
  manageSignatoriesPartsRelation,
  updatePart,
} from "../../services/parts/partsServices";
import {
  resetPartListStatus,
  resetSignatoriesListStatus,
  setSelectedPart,
  setSelectedSignatory,
} from "../../parts/parts/partsSlice";
import { CODES } from "../../utils/codes";
import { useDispatch } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ModalDecision from "./modalDecision";
import ModalInfo from "./modalInfo";
import {
  PARTS_LIBRARY_STATE,
  TYPE_CHARGES,
} from "../../pages/views/userService/modules/library/parts/utils";
import { DetailBarParts } from "../../pages/views/userService/modules/library/parts/parts";

import { PartsDtoFactory } from "../../services/parts/factories/partsDtoFactory";
import { MANAGE_SIGNATORIES_PARTS_ACTIONS } from "../../services/parts/consts/partsConsts";

import { PopoverLimits } from "../Popover/PopoverLimits";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ModalInfoSignatories = ({
  data,
  open,
  handleClose,
  selectedElement,
  title = null,
  message = null,
  showButtons = true,
}) => {
  const containerRef = useRef(null);
  const containerRefDialog = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpenModalSignatoriesInfo, setIsOpenModalSignatoriesInfo] =
    useState(false);
  const [partsWithDefaultSignatory, setPartsWithDefaultSignatory] = useState(
    []
  );
  const [
    selectedPartWithDefaultSignatory,
    setSelectedPartWithDefaultSignatory,
  ] = useState({});
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Nombre de firmante
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Id de firmante
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Contacto firmante
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Cargo
        </label>,
      ],
      field: "charge",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Firmante por defecto
        </label>,
      ],
      field: "actions",
    },
    {
      label: [<label aria-hidden="true" key="5"></label>],
      field: "options",
    },
  ];
  const [dataTableInfo, setDataTableInfo] = useState({ rows: [], columns });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [signatorySelected, setSignatorySelected] = useState(null);
  const [anchorSelected, setAnchorSelected] = useState(null);
  const [deleteSignatoryResponse, setDeleteSignatoryResponse] = useState({});
  const [
    isOpenModalDecisionDeleteSignatory,
    setIsOpenModalDecisionDeleteSignatory,
  ] = useState(false);
  const [
    isOpenModalDeleteSignatoryResponse,
    setIsOpenModalDeleteSignatoryResponse,
  ] = useState(false);
  const openSelected = Boolean(anchorSelected);
  const handleChangeDefaultSignatory = async (info) => {
    try {
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));

      setIsLoading(true);
      const newData = data.relatedSignatories.map((item) => {
        if (item?.signatory?._id === info?._id)
          return { ...item, isDefault: true };
        return { ...item, isDefault: false };
      });

      const obj = {
        modifiedBy: userId,
        company,
        relatedSignatories: newData.map((item) => {
          return {
            isDefault: item.isDefault,
            signatory: item.signatory?._id,
            position: item.position,
          };
        }),
        partId: selectedElement._id,
      };

      const newPartService = await updatePart(obj);
      if (newPartService.status === CODES.COD_RESPONSE_HTTP_OK) {
        dispatch(setSelectedPart(newPartService.data?.responseMessage?.data));
        dispatch(resetPartListStatus());
      }

      const rows = buildRows(newData);
      setDataTableInfo({ rows, columns });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModalDeleteSignatory = () => {
    setIsOpenModalDecisionDeleteSignatory(false);
  };
  const handleDeleteSignatory = async (item) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalDecisionDeleteSignatory(false);
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const newStatusId = PARTS_LIBRARY_STATE.DELETED._id;
      const responseDeleteSignatory = await deleteSignatoryTemporarily({
        signatoryId: item?._id,
        status: newStatusId,
        modifiedBy: userId,
      });
      setIsOpenModalDeleteSignatoryResponse(true);
      setDeleteSignatoryResponse(responseDeleteSignatory);
      getPartsWithDefaultSignatory(item);
      if (
        responseDeleteSignatory.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeleteSignatory.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        if (
          responseDeleteSignatory.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseDeleteSignatory.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          const elementsToDelete = item?.relatedParties?.map((relatedPart) =>
            PartsDtoFactory.generateManagePartToSignatoryDto(
              item?._id,
              data._id,
              relatedPart?.position
            )
          );
          if (elementsToDelete?.length) {
            const signatoriesForPart = [];
            const partsForSignatory = [];
            signatoriesForPart.push(
              PartsDtoFactory.generateManageSignatoryToPartsDto(
                elementsToDelete,
                MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
              )
            );
            partsForSignatory.push(
              PartsDtoFactory.generateManagePartToSignatoriesDto(
                elementsToDelete,
                MANAGE_SIGNATORIES_PARTS_ACTIONS.DELETE
              )
            );
            await manageSignatoriesPartsRelation(
              PartsDtoFactory.generateManageSignatoriesPartsRelationDto(
                signatoriesForPart,
                partsForSignatory
              )
            );
          }
        }

        setDeleteSignatoryResponse(responseDeleteSignatory);
        dispatch(resetSignatoriesListStatus());
        dispatch(resetPartListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;

    return fullName.replace(/  +/g, " ");
  };

  const getPartName = (part) => {
    let name = "";
    name = getName(part);
    if (part?.typePart === "JURIDICA") {
      name = part?.business?.businessName;
    }
    return name;
  };
  const getPartsWithDefaultSignatory = (signatory) => {
    const parts = signatory.relatedParties.map((part) => part.part);
    const tempPartsWithDefaultSignatory = [];
    parts.forEach((part) => {
      const relatedSignatories = part.relatedSignatories;
      const isDefault = relatedSignatories.some(
        (relatedSignatory) =>
          relatedSignatory?.signatory?._id === signatory?._id &&
          relatedSignatory?.isDefault
      );
      const areMoreSignatories = relatedSignatories.length > 1;
      if (isDefault && areMoreSignatories) {
        tempPartsWithDefaultSignatory.push(
          deleteNestedIdFromArray(part, signatory._id)
        );
      }
    });

    if (tempPartsWithDefaultSignatory.length > 0) {
      setPartsWithDefaultSignatory(tempPartsWithDefaultSignatory);
      setSelectedPartWithDefaultSignatory(tempPartsWithDefaultSignatory?.[0]);
      setIsOpenModalSignatoriesInfo(true);
    }
    return parts;
  };
  const handleSelectedDefaultSignatoryForPart = (item) => {
    if (partsWithDefaultSignatory.length > 0) {
      const tempPartsWithDefaultSignatory = partsWithDefaultSignatory.filter(
        (part) => part._id !== item._id
      );
      setPartsWithDefaultSignatory(tempPartsWithDefaultSignatory);
      setSelectedPartWithDefaultSignatory(tempPartsWithDefaultSignatory[0]);
      setIsOpenModalSignatoriesInfo(
        tempPartsWithDefaultSignatory[0] ? true : false
      );
    }
  };

  function deleteNestedIdFromArray(part, nestedIdToDelete) {
    let newSignatories = [];
    if (part) {
      newSignatories = part.relatedSignatories.filter(
        (nestedObj) => nestedObj.signatory?._id !== nestedIdToDelete
      );
    }
    const updatedObject = { ...part, relatedSignatories: newSignatories };

    return updatedObject;
  }
  const buildRows = (data) => {
    if (!data.length) return [];

    return data
      .map((signatory) => {
        const item = signatory.signatory;
        let number = `+${item?.mobileNumberData?.countryCode || ""} ${
          item?.mobileNumberData?.phone || ""
        }`;
        let telephone = `+${item?.telephoneNumberData?.countryCode || ""} ${
          item?.telephoneNumberData?.cityCode || ""
        } ${item?.telephoneNumberData?.telephone || ""}`;
        return {
          name: getName(item),
          id: `${item?.documentType || ""}. ${item?.documentNumber || ""}`,

          contact: (
            <Row className="caption" style={{ alignItems: "center" }}>
              <Col xs={"auto"}>{item?.email}</Col>
              <Col xs={"auto"}>
                {item?.email && (
                  <PopoverActions
                    containerRef={containerRef}
                    parentId="signatories-content"
                    type="large"
                    classNameButton=""
                    description={
                      <div>
                        <Row style={{ top: 10 }}>
                          <span className="home-init-cards-row__title-row">
                            Más datos de contacto
                          </span>
                        </Row>
                        <Row>
                          <span className="caption" style={{ width: "auto" }}>
                            {item?.residence?.physicalAddress}{" "}
                          </span>
                        </Row>
                        <Row>
                          <span className="caption" style={{ width: "auto" }}>
                            {item?.residence?.country || ""}{" "}
                            {item?.residence?.province || ""}{" "}
                            {item?.residence?.city || ""}
                          </span>
                        </Row>
                        <Row>
                          <span className="caption" style={{ width: "auto" }}>
                            {number}
                          </span>
                        </Row>
                        <Row>
                          <span className="caption" style={{ width: "auto" }}>
                            {telephone}
                          </span>
                        </Row>
                      </div>
                    }
                    icon={<InfoOutlinedIcon fontSize="large" />}
                  />
                )}
              </Col>
            </Row>
          ),
          charge: (
            <Row>
              <Col xs={"auto"} lg={8}>
                <p className="caption">{signatory?.position || "Apoderado"}</p>
              </Col>

              {signatory?.limitations &&
                signatory?.position.toUpperCase() ===
                  TYPE_CHARGES.LEGAL.toUpperCase() && (
                  <Col xs={"auto"} lg={4}>
                    <PopoverLimits
                      containerRef={containerRefDialog}
                      limitationsUpdatedDate={signatory?.limitationsUpdatedDate}
                      createdAt={signatory?.signatory?.createdAt}
                      limitations={signatory.limitations}
                    />
                  </Col>
                )}
            </Row>
          ),
          actions: (
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
              <Col xs={"auto"}>
                <input
                  type="radio"
                  name="documentAssociated"
                  checked={signatory?.isDefault}
                  onChange={() => handleChangeDefaultSignatory(item)}
                />
              </Col>
            </Row>
          ),
          options: (
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
              <Col xs={"auto"}>
                <IconButton
                  onClick={(event) => {
                    setAnchorSelected(event.currentTarget);
                    setSignatorySelected(item);
                    dispatch(setSelectedSignatory(item));
                  }}
                >
                  <MoreHorizIcon fontSize="large" />
                </IconButton>
              </Col>
            </Row>
          ),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const handleSelectedViewDetails = (item) => {
    setAnchorSelected(null);
    /** @type {string | undefined} */
    const loginType = getLoginType();
    /** @type {string} base path to redirect the user based on the role */
    const basePath =
      loginType === "ADMIN"
        ? "/admin/library"
        : `/service/library/partsAndSignatures`;

    switch (item) {
      case 0:
        navigate(`${basePath}/update/${signatorySelected._id}`);
        break;
      case 1:
        setIsOpenModalDecisionDeleteSignatory(true);
        break;
      case 2:
        setOpenDetailsDrawner(true);
        break;
      default:
        break;
    }
  };

  /**
   * Redirect the user to the create signatory page based on the user role.
   */
  const handleAddSignatoryFromScratch = () => {
    /** @type {string | undefined} */
    const loginType = getLoginType();
    /** @type {string} base path to redirect the user based on the role */
    const basePath =
      loginType === "ADMIN"
        ? "/admin/library"
        : `/service/library/partsAndSignatures`;

    handleClose();
    navigate(`${basePath}/create/signatory`, {
      state: { signatory: data._id },
    });
  }

  /**
   * Redirect the user to the select signatory page based on the user role.
   */
  const handleAddSignatoryFromLibrary = () => {
    /** @type {string | undefined} */
    const loginType = getLoginType();
    /** @type {string} base path to redirect the user based on the role */
    const basePath =
      loginType === "ADMIN"
        ? "/admin/library"
        : `/service/library/partsAndSignatures`;

    handleClose();
    navigate(`${basePath}/selectSignatory/${data._id}`);
  }

  /**
   * Get the login type from the local storage.
   * 
   * @throws {Error} An error occurred while trying to get the login type from the local storage or the login type is not available in the local storage.
   * @returns {string | undefined} The login type.
   */
  const getLoginType = () => {
    /** @type {string | undefined} */
    let loginType;

    try {
      loginType = JSON.parse(
        localStorage.getItem("payloadToken")
      )?.loginType;
    } catch(error) {
      throw new Error("An error occurred while trying to get the login type from the local storage");
    }

    if (!loginType) {
      throw new Error("The login type is not available in the local storage");
    }

    return loginType;
  }

  useEffect(() => {
    if (open) {
      const rows = buildRows(data.relatedSignatories);

      setDataTableInfo({ rows, columns });
    }
  }, [open, data]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={"md"}
        ref={containerRefDialog}
      >
        <Container id="signatories-content" ref={containerRef}>
          {isLoading && <LoadingContent />}
          <Row className="justify-content-md-end">
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Col>
          </Row>
          {title && (
            <DialogTitleContainer>
              <label className="heading__primary-color">{title}</label>
            </DialogTitleContainer>
          )}
          {message && (
            <DialogContent className="caption" dividers>
              {message}
            </DialogContent>
          )}
          <Row className="dt-list-center-content">
            <MDBDataTableV5
              noRecordsFoundLabel={
                "Esta parte no cuenta con firmantes asociados"
              }
              hover
              pagingTop
              searchBottom={false}
              entries={10}
              data={dataTableInfo}
              entriesLabel=""
              infoLabel={["Mostrando", "a", "de", "firmantes"]}
              fullPagination
            />
          </Row>
          {showButtons && (
            <DialogActions>
              <Row>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    className="custom-input__button__secondary-color"
                    onClick={handleAddSignatoryFromScratch}
                    startIcon={<PersonAddAltIcon fontSize="large" />}
                  >
                    Agregar firmante desde cero
                  </Button>
                </Col>

                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    onClick={handleAddSignatoryFromLibrary}
                    startIcon={<LocalLibraryOutlinedIcon fontSize="large" />}
                  >
                    Agregar firmante desde la biblioteca
                  </Button>
                </Col>
              </Row>
            </DialogActions>
          )}
        </Container>
        <Menu
          id="basic-menu"
          anchorEl={anchorSelected}
          open={openSelected}
          onClose={() => setAnchorSelected(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleSelectedViewDetails(0);
            }}
          >
            <ListItemIcon>
              <EditOutlinedIcon
                fontSize="large"
                className="heading__primary-color"
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={"Editar"}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSelectedViewDetails(1);
            }}
          >
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon
                fontSize="large"
                className="heading__primary-color"
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={"Eliminar"}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSelectedViewDetails(2);
            }}
          >
            <ListItemIcon>
              <InfoOutlinedIcon
                fontSize="large"
                className="heading__primary-color"
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={"Ver detalles"}
            />
          </MenuItem>
        </Menu>
        <ModalDecision
          title={"Eliminar firmante"}
          message={
            "Si eliminas al firmante dejarás de tener sus datos disponibles para la creación de documentos. ¿Seguro quieres eliminarlo?"
          }
          agreeText={"Eliminar"}
          disagreeText={"Cancelar"}
          handleAgree={() => {
            handleDeleteSignatory(signatorySelected);
          }}
          handleDisagree={handleCloseModalDeleteSignatory}
          onClose={handleCloseModalDeleteSignatory}
          open={isOpenModalDecisionDeleteSignatory}
          type={"warning"}
        />
        <ModalInfo
          title={
            deleteSignatoryResponse?.status === CODES.COD_RESPONSE_HTTP_OK &&
            deleteSignatoryResponse?.data?.responseCode ===
              CODES.COD_RESPONSE_SUCCESS
              ? "Firmante eliminado"
              : "Error eliminando al firmante"
          }
          responseData={deleteSignatoryResponse}
          open={isOpenModalDeleteSignatoryResponse}
          buttonClose={true}
          confirmationText="Aceptar"
          onClose={() => {
            setIsOpenModalDeleteSignatoryResponse(false);
            handleClose();
          }}
          handleCloseDefault={() => {
            setIsOpenModalDeleteSignatoryResponse(false);
            handleClose();
          }}
        />
        {isOpenModalSignatoriesInfo && (
          <ModalInfoSignatories
            data={selectedPartWithDefaultSignatory}
            open={isOpenModalSignatoriesInfo}
            handleClose={() => {
              setIsOpenModalSignatoriesInfo(false);
              handleSelectedDefaultSignatoryForPart(
                selectedPartWithDefaultSignatory
              );
            }}
            selectedElement={selectedPartWithDefaultSignatory}
            title={"Firmantes por defecto"}
            message={`Elige al firmante por defecto que quieres que aparezca como primera opción al crear documentos con esta parte (${getPartName(
              selectedPartWithDefaultSignatory
            )})`}
            showButtons={false}
          />
        )}
      </Dialog>
      <DetailBarParts
        openDetailsDrawner={openDetailsDrawner}
        handleClose={() => {
          setOpenDetailsDrawner(false);
          setSignatorySelected(null);
        }}
        selectedElement={signatorySelected}
        selectedElementIsPart={false}
      />
    </>
  );
};
