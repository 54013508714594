// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    /* eslint-disable no-restricted-globals */
    self.addEventListener("message", async (event) => {
      const { serviceUrl, token, idProcess, body, typesReports } = event.data;
      try {
        
        const SERVICES = {
            1: {
                name: "Hitos y fechas",
                report: "milestonesReportFromClauses"
            },
            2: {
                name: "Valores",
                report: "valuesReportFromClauses"
            },
            4: {
                name: "Pólizas",
                report: "policesReportFromClauses"
            },
            5: { 
                name: "Obligaciones",
                report: "obligationsReportFromClauses"
            },
        };

        const reportsData = [];

        const fetchReports = typesReports.map(value => {
            if (Object.keys(SERVICES).includes(value._id)) {
                return fetch(
                    `${serviceUrl}/versions/${SERVICES[value._id].report}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: token,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body)
                    }
                ).then(response => response.json());
            }
        });

        const responses = await Promise.allSettled(fetchReports);

        responses.forEach((response, index) => {
            const value = typesReports[index];
            const typeReport = SERVICES[value._id].name;

            if (response.status === "fulfilled") {
                reportsData.push({
                    typeReport,
                    reports: response?.value?.responseMessage?.sorter || response?.value?.responseMessage?.data || []
                });
            } else {
                console.error(`Error fetching report ${typeReport}:`, response.reason);
                reportsData.push({
                    typeReport,
                    reports: []                    
                });
            }
        });

        self.postMessage({
          data: {
            success: true,
            data: reportsData
          },
          status: 200,
          statusText: "Se han generado los informes correctamente",
          idProcess
        });
      } catch (error) {
        self.postMessage({
          data: {
            success: false,
            responseCode: 1001,
            responseMessage: {
              message: "Error haciendo resumen del documento",
            },
          },
          idProcess,
          status: error.response?.status || 500,
          statusText: error.response?.statusText || "Error",
        });
      }
      /* eslint-disable no-restricted-globals */
    });
  };
  