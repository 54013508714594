import { Avatar, Button } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SidebarApprover = () => {
  /**
   * Use Navigate
   */

  const navigate = useNavigate();
  return (
    <Container className="sidebar-base__container">
      <Row>
        <Container fluid className="sidebar-base__header-row__header-container">
          <Row>
            <Avatar
              sx={{
                width: "4.8rem",
                height: "4.8rem",
                backgroundColor: "#00374F",
                fontSize: "2rem",
                fontFamily: "Roboto",
              }}
            >
              P A
            </Avatar>
          </Row>
          <Row className="sidebar-base__header-row__header-container__name">
            <p className="heading">Aprobador</p>
          </Row>
          <Row className="sidebar-base__header-row__header-container__role">
            <p className="caption">Usuario firmante</p>
          </Row>
        </Container>
      </Row>
      <hr fullWidth />
      <Row className="sidebar-base__navbar-row"></Row>
      <Row className="sidebar-base__navbar-row__navbar-container__nav-row">
        <Button
          variant="text"
          className="sidebar-base__logout-row__button"
          onClick={() => {
            navigate("/");
          }}
        >
          <Row style={{ paddingLeft: "5%" }}>
            <Col md={3} className="sidebar-base__logout-row__logout-container">
              <img
                className="sidebar-base__logout-row__logout-icon"
                src={require("../../assets/svg/logoutIcon.svg").default}
              />
            </Col>
            <Col className="sidebar-base__logout-row__logout-text">Salir</Col>
          </Row>
        </Button>
      </Row>
      <hr />
      <Row className="sidebar-base__logo-row col-flex">
        <Col md={3} className="sidebar-base__logo-row__logo-container">
          <img
            className="sidebar-base__logo-row__logo-svg"
            src={require("../../assets/svg/singleLogo.svg").default}
            alt="Logo Legal"
          />
        </Col>
        <Col className="sidebar-base__logo-row__logo-title">
          <p>Legal AI</p>
        </Col>
      </Row>
    </Container>
  );
};

export default SidebarApprover;
