import ClearIcon from "@mui/icons-material/Clear";
import FolderIcon from "@mui/icons-material/Folder";
import { Button } from "@mui/material";
import dateFormat from "dateformat";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import FilterTag from "../../../../../parts/filters/FilterTag";
import {
  fetchFolderTags,
  getFolderTagsList,
  getFolderTagsStatus,
} from "../../../../../parts/folders/folderTagSlice";
import { getFoldersByCompany } from "../../../../../services/business_folder/businessFolderService";
const FolderAssociateAlert = () => {
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Carpetas
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Última modificación
        </label>,
      ],
      field: "updatedAt",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Último acceso
        </label>,
      ],
      field: "lastAccess",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Acciones
        </label>,
      ],
      field: "alerts",
    },
  ];
  const rows = [];

  /**
   * UseNavigate
   */
  const navigate = useNavigate();

  /**
   * UseDispatch
   */
  const dispatch = useDispatch();

  /**
   * UseSelector
   */

  //Tags
  const tagsList = useSelector(getFolderTagsList);
  const statusTags = useSelector(getFolderTagsStatus);

  /**
   * UseState
   */

  //Tabla
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: rows,
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: rows,
  });

  //Loading content
  const [isLoading, setIsLoading] = useState(false);

  //Filter Tags
  const [tagsSelected, setTagsSelected] = useState([]);

  //Tags

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      let filteredFolders = [];
      let filterTags = [];
      filteredDataTable.rows.forEach((item) => {
        const rawLabels = item.labels.props.children;
        const labelsList = rawLabels.split(",").map((item) => {
          return item.trim();
        });
        filterTags = labelsList.some((tag) => newTags.includes(tag));
        if (filterTags) {
          filteredFolders.push(item);
        }
      });

      setFilteredDataTable({ ...filteredDataTable, rows: filteredFolders });
    } else {
      /**Ninguna etiqueta se está buscando */
      setFilteredDataTable({
        columns: dataTable.columns,
        rows: dataTable.rows,
      });
    }
  };

  //View folder

  const handleViewFolder = (id) => {
    navigate(id);
  };

  /**
   * UseEffect
   */

  //Cargar tags
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFolderTags());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyId = localStorage.getItem("company");
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const foldersService = await getFoldersByCompany({
          companyId,
          corporateUnit,
        });
        const processedFolders = foldersService.data.responseMessage.map(
          (folder) => {
            let labels = "";

            for (const label of folder.labels) {
              labels += label.name + ",";
            }

            return {
              element: (
                <Row
                  style={{
                    paddingLeft: "2%",
                    paddingTop: "1%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleViewFolder(folder._id);
                  }}
                >
                  <Col xs={"auto"} className="datatable-CB__tr-name">
                    <FolderIcon
                      fontSize="large"
                      className="heading__primary-color"
                    />
                  </Col>
                  <Col className="datatable-CB__tr-name">{folder.name}</Col>
                </Row>
              ),
              type: "Carpeta",
              createdAt: dateFormat(folder?.createdAt, "dd/mm/yyyy"),
              updatedAt: dateFormat(folder?.updatedAt, "dd/mm/yyyy"),
              lastAccess: dateFormat(folder?.lastAccessAt, "dd/mm/yyyy"),
              labels: labels,
              alerts: (
                <Row>
                  <Col xs={"auto"}>
                    <Button
                      variant="contained"
                      className="custom-input__button__secondary-color"
                      onClick={() => {
                        handleViewFolder(folder._id);
                      }}
                    >
                      Abrir
                    </Button>
                  </Col>
                </Row>
              ),
            };
          }
        );

        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
        setTimeout(setIsLoading(false), 50000);
      } catch (error) {
        console.log(error);
      }
    };
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row className="col-flex">
        <Breadcrum />
      </Row>
      <Row>
        <Col md={2} className="col-start">
          <SectionTitle title="Crear alerta" />
        </Col>
        <Col md={7}></Col>
      </Row>
      <br />
      <Row>
        <Col md={5} className="col-flex">
          <CustomSearch
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <FilterTag
            tagsList={tagsList}
            list={filteredDataTable}
            setFilteredInfo={setFilteredDataTable}
            handleTagsSelected={handleTagsSelected}
            classStyle="custom-input__button__secondary-color"
            widthStyle="auto"
          />
        </Col>
      </Row>
      <Row style={{ height: "2%" }}>
        {tagsSelected.map((tag, idx) => {
          return (
            <Col xs={"auto"} key={uuidv4()}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row>
        {
          <MDBDataTableV5
            noRecordsFoundLabel={"No se encuentran carpetas"}
            hover
            pagingTop
            searchBottom={false}
            entries={7}
            data={filteredDataTable}
            entriesLabel=""
            infoLabel={["Mostrando", "a", "de", "carpetas"]}
            fullPagination
          />
        }
      </Row>
    </Container>
  );
};

export default FolderAssociateAlert;
