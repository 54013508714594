import { Col, Row, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DetailsSchema from "./schemas/detailsSchema";
import DetailsFormInputs from "./constants/detailsFormInputs";
import { useEffect } from "react";
import ManageCustomFormModes from "../../constants/manageCustomFormModes";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { PlaylistAddOutlined } from "@mui/icons-material";

// BEGINNING OF TYPE DEFINITIONS

/**
 * The modes of the DetailsSection component.
 *
 * @typedef {"create" | "edit"} DetailsSectionModesType
 */

// END OF TYPE DEFINITIONS

/**
 * DetailsSection component, this is a section that contains a title and a list of details
 *
 * @param {Object} props
 * @param {JSX.Element} props.Wrapper Wrapper component
 * @param {JSX.Element} props.TittleWrapper Wrapper component for the title
 * @param {JSX.Element} props.ContentWrapper Wrapper component for the content
 * @param {String} props.title Title of the section
 * @param {String} props.subTitle Subtitle of the section
 * @param {string} [props.originalFormId] The original form id
 * @param {String} [props.group] Type of the group
 * @param {String} [props.type] Subtype of the group
 * @param {String} [props.description] Description of the group
 * @param {DetailsSectionModesType} props.mode The mode of the form
 * @param {boolean} props.isSubmittingExternally If the form is submitting externally
 * @param {(newGroup: string, newType: string, description: string) => void} props.saveDetailsSectionInfo Function to call when the form is submitted
 * @returns The DetailsSection component
 */
export const DetailsSection = ({
  Wrapper = ({ children }) => <div>{children}</div>,
  TittleWrapper = ({ children }) => <div>{children}</div>,
  ContentWrapper = ({ children }) => <div>{children}</div>,
  title,
  subTitle,
  originalFormId,
  group,
  type,
  description,
  mode,
  isSubmittingExternally,
  saveDetailsSectionInfo,
}) => {
  // Constants
  const detailsSchemaDefault = DetailsSchema.getDefault();

  // hooks
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(DetailsSchema),
    defaultValues: detailsSchemaDefault,
    mode: "all",
  });

  /**
   * Handles the text input change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e The event.
   * @param {Function} onChange The onChange function provided by the react-hook-form `controller`.
   * @param {Object} sectionFormInput The section form input.
   */
  const handleTextInputChange = (e, onChange, sectionFormInput) => {
    /** @type {string} The input name. */
    const inputName = sectionFormInput.InputName;
    /** @type {number} The max length of the input. */
    const maxLength = sectionFormInput.Validations.MaxLength;
    /** @type {string} The error message for the max length of the input. */
    const maxLengthMessage = sectionFormInput.Validations.MaxLengthMessage;
    const { value } = e.target;

    if (value.length <= maxLength) {
      onChange(e);
    } else {
      setValue(inputName, value.slice(0, maxLength));
      setError(inputName, {
        type: "maxLength",
        message: maxLengthMessage,
      });
    }
  };

  useEffect(() => {
    if (group) {
      setValue(
        DetailsFormInputs.DocumentGroup.InputName,
        group ?? detailsSchemaDefault[DetailsFormInputs.DocumentGroup.InputName]
      );
    }
    if (type) {
      setValue(
        DetailsFormInputs.DocumentType.InputName,
        type ?? detailsSchemaDefault[DetailsFormInputs.DocumentType.InputName]
      );
    }
    if (description) {
      setValue(
        DetailsFormInputs.DocumentDescription.InputName,
        description ??
          detailsSchemaDefault[DetailsFormInputs.DocumentDescription.InputName]
      );
    }
  }, [group, type, description]);

  useEffect(() => {
    if (isSubmittingExternally) {
      trigger();
      saveDetailsSectionInfo(
        getValues(DetailsFormInputs.DocumentGroup.InputName),
        getValues(DetailsFormInputs.DocumentType.InputName),
        getValues(DetailsFormInputs.DocumentDescription.InputName)
      );
    }
  }, [isSubmittingExternally]);

  return (
    <Wrapper>
      <TittleWrapper>
        <h1 className="heading__primary-color">{title}</h1>
      </TittleWrapper>
      {ContentWrapper ? (
        <ContentWrapper>
          <Container fluid className="d-flex flex-column gap-4">
            <p className="heading__default mb-4">{subTitle}</p>
            <Row className="g-3">
              <Col xs="12" md="4" lg="3" xl="2">
                <label className="form__label m-0">
                  {DetailsFormInputs.DocumentGroup.InputLabel}
                  {DetailsFormInputs.DocumentGroup.Validations.Required && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
              </Col>
              <Col xl="7">
                <Controller
                  name={DetailsFormInputs.DocumentGroup.InputName}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      type="select"
                      sx={{
                        fontSize: "",
                        "& .MuiSelect-select": {
                          display: "flex",
                        },
                      }}
                      fullWidth
                      disabled={
                        mode === ManageCustomFormModes.EDIT && originalFormId
                      }
                    >
                      {mode === ManageCustomFormModes.EDIT &&
                        !DetailsFormInputs.DocumentGroup.Validations.Options.includes(
                          group
                        ) && (
                          <MenuItem key={group} value={group}>
                            {group}
                          </MenuItem>
                        )}
                      {DetailsFormInputs.DocumentGroup.Validations.Options.map(
                        (option) => (
                          <MenuItem
                            key={option.text}
                            value={option.text}
                            sx={{
                              fontSize: "",
                            }}
                          >
                            <ListItemIcon>
                              {option.icon}
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                ".MuiListItemText-primary": {
                                  fontSize: "1.4rem",
                                },
                              }}
                            >
                              {option.text}
                            </ListItemText>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </Col>
            </Row>
            <Row className="g-3">
              <Col xs="12" md="4" lg="3" xl="2">
                <label className="form__label m-0">
                  {DetailsFormInputs.DocumentType.InputLabel}
                  {DetailsFormInputs.DocumentType.Validations.Required && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
              </Col>
              <Col xl="7">
                <Controller
                  name={DetailsFormInputs.DocumentType.InputName}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) =>
                        handleTextInputChange(
                          e,
                          field.onChange,
                          DetailsFormInputs.DocumentType
                        )
                      }
                      fullWidth
                      type="text"
                      size="small"
                      sx={{
                        "& .MuiInputBase-root": { fontSize: "" },
                        "& .MuiFormHelperText-root": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder={
                        DetailsFormInputs.DocumentType.InputPlaceholder
                      }
                      error={!!errors[DetailsFormInputs.DocumentType.InputName]}
                      helperText={
                        errors[DetailsFormInputs.DocumentType.InputName]
                          ?.message
                      }
                      disabled={
                        mode === ManageCustomFormModes.EDIT && originalFormId
                      }
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="g-3">
              <Col xs="12" md="4" lg="3" xl="2">
                <label className="form__label m-0">
                  {DetailsFormInputs.DocumentDescription.InputLabel}
                  {DetailsFormInputs.DocumentDescription.Validations
                    .Required && <span style={{ color: "red" }}>*</span>}
                </label>
              </Col>
              <Col xl="7">
                <Controller
                  name={DetailsFormInputs.DocumentDescription.InputName}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) =>
                        handleTextInputChange(
                          e,
                          field.onChange,
                          DetailsFormInputs.DocumentDescription
                        )
                      }
                      fullWidth
                      type="text"
                      size="small"
                      multiline
                      rows={4}
                      sx={{
                        "& .MuiInputBase-root": { fontSize: "" },
                        "& .MuiFormHelperText-root": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder={
                        DetailsFormInputs.DocumentDescription.InputPlaceholder
                      }
                      error={
                        !!errors[
                          DetailsFormInputs.DocumentDescription.InputName
                        ]
                      }
                      helperText={
                        errors[DetailsFormInputs.DocumentDescription.InputName]
                          ?.message
                      }
                      disabled={
                        mode === ManageCustomFormModes.EDIT && originalFormId
                      }
                    />
                  )}
                />
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      ) : (
        <div></div>
      )}
    </Wrapper>
  );
};

DetailsSection.propTypes = {
  Wrapper: PropTypes.any,
  TittleWrapper: PropTypes.any,
  ContentWrapper: PropTypes.any,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  originalFormId: PropTypes.string,
  group: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(ManageCustomFormModes)).isRequired,
  isSubmittingExternally: PropTypes.bool.isRequired,
  saveDetailsSectionInfo: PropTypes.func.isRequired,
};
