import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBarCarvajal from "./SearchBarCarvajal";
import { MDBDataTableV5, MDBBadge } from "mdbreact";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PreFilterFromHome,
  ShowDataExternalRequest,
} from "../../../../../../services/general/CarvajalServices";
import { CODES } from "../../../../../../utils/codes";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../components/loadingContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { onSortHTMLElement } from "../../../../../../utils/findComponentHTML";
import { Button } from "@mui/material";

const DataTableCarvajal = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [optionsFilterBar, setOptionsFilterBar] = useState([]);
  const [filterMyId, setFilterMyId] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isQueryParam = searchParams.get("filter");
  const [isActiveButtonUpdateCustomForms, setIsActiveButtonUpdateCustomForms] = useState(false);

  const columns = [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Caso No.
        </label>,
      ],
      field: "idCons",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="2"
        >
          Asunto de la solicitud
        </label>,
      ],
      field: "requestMatter",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="3"
        >
          Solicitante
        </label>,
      ],
      field: "applicant",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Requiere
        </label>,
      ],
      field: "requestType",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="5"
        >
          Fecha de creación
        </label>,
      ],
      field: "creationDate",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="6"
        >
          Fecha límite
        </label>,
      ],
      field: "limitDate",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="7"
        >
          Estado
        </label>,
      ],
      field: "status",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="8"
        >
          Empresa
        </label>,
      ],
      field: "company",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="9"
        >
          Ver
        </label>,
      ],
      field: "example",
    },
  ];

  const [DataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });

  const STATES_BY_QUERY_PARAMS = {
    open: ["Nueva", "Revisión solicitante", "Revisión abogado"],
    attorneyReview: ["Revisión abogado"],
    expired: ["Revisión solicitante", "Revisión abogado"],
  };

  const COLORS_STATES = {
    [CODES.COD_LIGHT_ORANGE]: "#FFD7B1",
    [CODES.COD_LIGHT_BLUE]: "#CBECEE",
    [CODES.COD_LIGHT_GREEN]: "#CCF4E3",
    [CODES.COD_LIGHT_RED]: "#F9DADE",
    [CODES.COD_LIGHT_PURPLE]: "#E6E9EF",
  };

  const STATES_REQUEST = ["Nueva", "Solucionada", "Devuelta", "Cancelada"];

  const buildRows = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      const { firstName, firstSurname } = item?.applicant || {
        firstName: "",
        firstSurname: "",
      };
      const requestType = item.requestType?.type !== "Otrosí"
        ? item.requestType?.name
        : item.requestSubtype;

      return {
        idCons: item?.consecutive || " - ",
        requestMatter: item?.requestSubject || " - ",
        applicant: ` ${firstName || ""} ${firstSurname || ""}`,
        requestType: requestType ?? "No aplica",
        creationDate: !item.createdAt ? "-" : item.createdAt,
        limitDate: STATES_REQUEST.includes(item?.requestStatus?.name)
          ? "No aplica"
          : item?.responseLimitDate,
        status: (
          <MDBBadge
            color=""
            pill
            className="caption"
            style={{
              backgroundColor:
                COLORS_STATES[item.requestStatus.colorId] || "#F3DEBC",
              fontSize: "1.2rem",
              borderRadius: "0.8rem",
              padding: "0.4rem",
            }}
          >
            <span className="custom-badge">{item.requestStatus.name}</span>
          </MDBBadge>
        ),
        example: !item.example ? (
          <button
            style={{ border: "none", borderRadius: "4px" }}
            onClick={() => handleClickViewRequest(item.consecutive)}
          >
            <FontAwesomeIcon
              className="icon-navbar"
              icon={faEye}
              color="#00374F"
              size="lg"
              style={{
                padding: "0.8rem",
                backgroundColor: "#E7F9FC",
                borderRadius: "0.4rem",
                cursor: "pointer",
              }}
            />
          </button>
        ) : (
          item.example
        ),
        company: item?.applicantCompany || "--",
        statusText: item.requestStatus.name,
      };
    });
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (["open", "attorneyReview", "expired"].includes(isQueryParam)) {
          let states = STATES_BY_QUERY_PARAMS[isQueryParam] || [];

          const { company, userId } = JSON.parse(
            localStorage.getItem("payloadToken")
          );

          const corporateUnit = localStorage.getItem("corporateUnitId");

          const filterANS = {
            expired: "VENCIDAS",
            open: "",
            attorneyReview: "",
          };

          const obj = {
            applicantsIds: [],
            states: states,
            names: [],
            attorneyId: userId,
            companies: [],
            company: company,
            corporateUnit: corporateUnit,
            filterStateByANS: filterANS[isQueryParam],
          };

          const service = await PreFilterFromHome(obj);
          if (service.status) {
            if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
              const { responseMessage } = service.data;
              const ProcessRows = buildRows(responseMessage);

              setDataTable({
                columns: columns,
                rows: ProcessRows,
              });
              setFilteredDataTable({
                columns: columns,
                rows: ProcessRows,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [isQueryParam]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (!["open", "attorneyReview", "expired"].includes(isQueryParam)) {
          const { company } = JSON.parse(localStorage.getItem("payloadToken"));
          const obj = {
            filterStatus: filterStatus,
            optionsFilterBar: optionsFilterBar,
            filterMyId: filterMyId,
            companyId: company,
            filterStateByANS: isQueryParam,
          };
          const GetDataTable = await ShowDataExternalRequest(obj);
          if (GetDataTable.status === CODES.COD_RESPONSE_HTTP_OK) {
            const { responseMessage } = GetDataTable.data;

            const ProcessRows = buildRows(responseMessage);

            setDataTable({
              columns: columns,
              rows: ProcessRows,
            });
            setFilteredDataTable({
              columns: columns,
              rows: ProcessRows,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [filterStatus, optionsFilterBar, filterMyId, isQueryParam]);

  const handleClickViewRequest = (consecutive) => {
    navigate("viewRequest/" + consecutive);
  };

  const handleInfoStatus = () => {
    navigate("infoStatus");
  };

  useEffect(() => {
    /** @type {Object | undefined} */
    let payloadToken;
    try {
      payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    } catch (error) {
      throw new Error("Error obtaining the permissions for update custom forms.");
    }
    /** @type {Object | undefined} */
    const permissionsRequests = payloadToken?.permissions?.find(({module}) => module === "Requests");
    /** @type {boolean} */
    const isAllowUpdateCustomForms = permissionsRequests?.actions?.updateCustomForms ?? false;
    setIsActiveButtonUpdateCustomForms(isAllowUpdateCustomForms);
  }, [])

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <>
        <Row>
          <Breadcrum />
        </Row>
        <Row xs={"auto"}>
          <SectionTitle title={"Solicitudes"} />
        </Row>
        <br />
        <div
          className="data-table dt-list-items"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc(100vh - 10rem)",
          }}
        >
          <Row className="caption" style={{ margin: "0.7rem 0" }}>
            Selecciona la solicitud que quieres revisar o gestionar.
          </Row>

          <Row
            style={{ display: "flex", marginTop: "1rem", alignItems: "center" }}
          >
            <SearchBarCarvajal
              DataTable={DataTable}
              setDataTable={setDataTable}
              filteredDataTable={filteredDataTable}
              setFilteredDataTable={setFilteredDataTable}
              setFilterStatus={setFilterStatus}
              setOptionsFilterBar={setOptionsFilterBar}
              filterMyId={filterMyId}
              setFilterMyId={setFilterMyId}
              setSearchParams={setSearchParams}
            />
            {isActiveButtonUpdateCustomForms && (
              <Col xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<EditOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  onClick={() => navigate("customize")}
                >
                  Personalizar
                </Button>
              </Col>
            )}
          </Row>
          <Row style={{ marginBottom: "2rem" }}></Row>
          <Row>
            {
              <MDBDataTableV5
                hover
                pagingTop
                searchBottom={false}
                entries={7}
                data={filteredDataTable}
                entriesLabel=""
                infoLabel={["Mostrando", "a", "de", "solicitudes"]}
                fullPagination
                noRecordsFoundLabel={"No se han encontrado solicitudes."}
                onSort={(value) => {
                  onSortHTMLElement({
                    excludesColumns: ["example"],
                    sort: value,
                    filteredDataTable,
                    setFilteredDataTable,
                  });
                }}
              />
            }
          </Row>
          <Row style={{ alignItems: "center" }}>
            <Col xs={"auto"}>
              <InfoOutlinedIcon fontSize="large" />
            </Col>
            <Col>
              <p className="caption">
                <span
                  className="caption__other-Color-Active"
                  onClick={handleInfoStatus}
                >
                  {`Conoce más `}
                </span>
                sobre los estados de las solicitudes.
              </p>
            </Col>
          </Row>
        </div>
      </>
    </Container>
  );
};

export default DataTableCarvajal;
