import { replaceBlankSpacesOnText } from "../../../../../../utils/blankspaces";

export const changeNameParts = (textHtml, parts) => {
  if (!textHtml) return "";

  //Convertimos el string html a un Object Document
  const parser = new DOMParser();
  let htmlElement = parser.parseFromString(textHtml, "text/html");

  //Validamos que exista la data para cambiar su valor
  for (let index = 0; index < 3; index++) {
    if (
      htmlElement?.querySelector(`[data-part-number="${index}"]`) &&
      parts[index]
    ) {
      const data = htmlElement?.querySelectorAll(
        `[data-part-number="${index}"]`
      );
      if (data?.length) {
        for (const item of data) {
          item.textContent = `°${parts[index]}°`;
          item.setAttribute("data-part", `${parts[index]}`);
        }
      }
    }
  }

  //Extraemos de Object Document el HTML String
  const stringifiedDocument = htmlElement.documentElement.outerHTML;

  //Limpiamos el HTML string para que no tenga etiquetas principales del html
  const cleanedString = stringifiedDocument.replace(
    /<\/?(html|head|body)>/gi,
    ""
  );

  return cleanedString;
};

/**
 * Replace blank spaces on text for a clause including its subclauses and paragraphs.
 *
 * @param {Object} clause The clause to modify.
 * @param {string} newTextForBlankSpaces The new text for the blank spaces in the text.
 * @param {string} blankSpaceTextWrapper The wrapper for the blank space text. Default is "¬".
 * @returns {Object} The modified clause.
 */
export const replaceBlankSpacesTextOnTextClause = (
  clause,
  newTextForBlankSpaces,
  blankSpaceTextWrapper = "¬"
) => {
  const clauseToModify = { ...clause };
  const blankSpaces = clauseToModify.blankspaces ?? clauseToModify.blankSpaces;
  /**
   * Replace blank spaces on text for the clause.
   */
  clauseToModify.text = replaceBlankSpacesOnText(
    clauseToModify.text,
    blankSpaces,
    newTextForBlankSpaces,
    blankSpaceTextWrapper
  );

  /**
   * Replace blank spaces on text for each subclause.
   */
  if (clauseToModify.subclauses) {
    clauseToModify.subclauses = clauseToModify.subclauses.map((subclause) => {
      const subClauseToModify = { ...subclause.subclause };
      const subclauseText = subClauseToModify.text;
      const subclauseBlankSpaces =
        subClauseToModify.blankspaces ?? subClauseToModify.blankSpaces;

      subClauseToModify.text = replaceBlankSpacesOnText(
        subclauseText,
        subclauseBlankSpaces,
        newTextForBlankSpaces,
        blankSpaceTextWrapper
      );

      /**
       * Replace blank spaces on text for each child of the subclause.
       */
      if (subClauseToModify.childs) {
        subClauseToModify.childs = subClauseToModify.childs.map((child) => {
          const childToModify = { ...child };
          const childText = childToModify.text;
          const childBlankSpaces =
            childToModify.blankspaces ?? childToModify.blankSpaces;

          childToModify.text = replaceBlankSpacesOnText(
            childText,
            childBlankSpaces,
            newTextForBlankSpaces,
            blankSpaceTextWrapper
          );

          return childToModify;
        });
      }

      return {
        subclause: subClauseToModify,
      };
    });
  }

    /**
     * Replace blank spaces on text for each paragraph.
     */
    if (clauseToModify.paragraphs) {
      clauseToModify.paragraphs = clauseToModify.paragraphs.map((paragraph) => {
        const paragraphToModify = { ...paragraph.paragraph };
        const paragraphText = paragraphToModify.text;
        const paragraphBlankSpaces =
        paragraphToModify.blankspaces ?? paragraphToModify.blankSpaces;

        paragraphToModify.text = replaceBlankSpacesOnText(
          paragraphText,
          paragraphBlankSpaces,
          newTextForBlankSpaces,
          blankSpaceTextWrapper
        );
        
        return {
            paragraph: paragraphToModify,
        };
      });
    }

  return clauseToModify;
};
