import { DocumentsListDto, QuestionFormDto, SectionsFormDto } from "./updatedCustomRequestTypeDto";

/**
 * Add new custom request type data transfer object for the service `addNewCustomRequestType`.
 */
export default class AddNewCustomRequestTypeDto {
  companyId;
  name;
  group;
  type;
  description;
  sections;
  requiredDocuments;
  allowUpdateOtherDocumentsToTrace;
  fields;

  /**
   * Initializes a new instance of the `AddNewCustomRequestTypeDto` class.
   * 
   * @param {Object} props - The options for the constructor.
   * @param {string} props.name - The name of the form.
   * @param {string} props.group - The group of the form.
   * @param {string} props.type - The type of the form.
   * @param {string} props.description - The description of the form.
   * @param {string} props.companyId - The id of the company.
   * @param {Array<QuestionFormDto>} [props.fields] - The fields of the form.
   * @param {Array<SectionsFormDto>} props.sections - The sections of the form.
   * @param {Array<DocumentsListDto>} [props.requiredDocuments] - The list of documents of the form.
   * @param {boolean} props.allowUpdateOtherDocumentsToTrace - Whether the form allows update other documents to trace or not.
   * @returns {AddNewCustomRequestTypeDto} The new instance of the `AddNewCustomRequestTypeDto` class.
   * @throws {Error} If the validation fails.
   */
  constructor({
    name,
    group,
    type,
    description,
    companyId,
    sections,
    requiredDocuments,
    allowUpdateOtherDocumentsToTrace,
    fields,
  }) {
    AddNewCustomRequestTypeDto.validate({
      name,
      group,
      type,
      description,
      companyId,
      sections,
      requiredDocuments,
      allowUpdateOtherDocumentsToTrace,
      fields,
    });
    this.name = name;
    this.group = group;
    this.type = type;
    this.description = description;
    this.companyId = companyId;
    this.sections = sections;
    this.requiredDocuments = requiredDocuments;
    this.allowUpdateOtherDocumentsToTrace = allowUpdateOtherDocumentsToTrace;
    this.fields = fields;
    Object.freeze(this);
  }

  /**
   * Creates a new instance of the `AddNewCustomRequestTypeDto` class from an object.
   * 
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @returns {AddNewCustomRequestTypeDto} The new instance of the `AddNewCustomRequestTypeDto` class.
   * @throws {Error} If the validation fails.
   */
  static fromObject({
    name,
    group,
    type,
    description,
    companyId,
    sections,
    requiredDocuments,
    allowUpdateOtherDocumentsToTrace,
    fields
  }) {
    /**
     * The sections of the form.
     * 
     * @constant
     * @type {SectionsFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const sectionsFormDtoArray = sections?.map((section) =>
      SectionsFormDto.fromObject(section)
    );
    /**
     * The list of documents of the form.
     * 
     * @constant
     * @type {DocumentsListDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const documentsListDtoArray = requiredDocuments?.map((document) =>
      DocumentsListDto.fromObject(document)
    );
    /**
     * The fields of the form.
     * 
     * @constant
     * @type {QuestionFormDto[] | undefined}
     * @throws {Error} If the validation fails.
     */
    const fieldsArray = fields?.map((field) => QuestionFormDto.fromObject(field));


    return new AddNewCustomRequestTypeDto({
      name,
      group,
      type,
      description,
      companyId,
      sections: sectionsFormDtoArray,
      requiredDocuments: documentsListDtoArray,
      allowUpdateOtherDocumentsToTrace,
      fields: fieldsArray,
    });
  }

  /**
   * Validate the properties of the object.
   * 
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validate({
    name,
    group,
    type,
    description,
    companyId,
    sections,
    requiredDocuments,
    allowUpdateOtherDocumentsToTrace,
    fields,
  }) {
    if (typeof name !== "string")
      throw new Error("The name must be a string");
    if (typeof group !== "string")
      throw new Error("The group must be a string");
    if (typeof type !== "string")
      throw new Error("The type must be a string");
    if (typeof description !== "string")
      throw new Error("The description must be a string");
    if (typeof companyId !== "string")
      throw new Error("The companyId must be a string");
    if (!Array.isArray(sections))
      throw new Error("The sections must be an array");
    if (requiredDocuments && !Array.isArray(requiredDocuments))
      throw new Error("The requiredDocuments must be an array");
    if (
      typeof allowUpdateOtherDocumentsToTrace !== "boolean"
    )
      throw new Error(
        "The allowUpdateOtherDocumentsToTrace is required and must be a boolean"
      );
    if (fields && !Array.isArray(fields))
      throw new Error("The fields must be an array");
  }
}