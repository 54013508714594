/**
 * Collection of regulated inputs that have defined rules
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @readonly
 */
const RegulatedInputs = Object.freeze({
  Short: {
    type: "text",
    rules: {
      maxLength: 250,
      maxLengthErrorMessage: "Máximo 250 caracteres permitidos",
    },
  },
  Long: {
    type: "text",
    rules: {
      maxLength: 500,
      maxLengthErrorMessage: "Máximo 500 caracteres permitidos",
    },
  },
});

export default RegulatedInputs;
