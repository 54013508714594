import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CardFilter from "../../components/Cards/CardFilter";
import CustomSearchFilterButton from "../../components/Search/CustomSearchFilterButton";

const FilterTagParts = ({
  tagsList,
  list,
  setFilteredInfo,
  handleTagsSelected,
  classStyle = "custom-input__button__primary-color",
  widthStyle = "auto",
  heightStyle = "auto",
  currentTagSelected = [],
}) => {
  const [show, setShow] = useState(false);
  const [countChecked, setCountChecked] = useState(0);

  const [tags, setTags] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);

  const handleShow = () => {
    handleFilterTags(currentTagSelected);
    const currentList = [...tagsList];
    const orderList = currentList.sort((a, b) => a.name.localeCompare(b.name));
    setTags(orderList);
    setFilteredData(orderList);
    setShow(true);
    setTagSelected(currentTagSelected);
    setCountChecked(currentTagSelected.length);
  };

  const handleChecked = (e, tag) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setTagSelected((oldArray) => [...oldArray, tag]);
    } else {
      setCountChecked(countChecked - 1);
      const tags = tagSelected.filter(
        (tagCurrent) => tagCurrent._id !== tag._id
      );
      setTagSelected(tags);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleFilterTags = (tagsSelected) => {
    if (tagsSelected.length > 0) {
      let filteredFolders = [];
      const selectedList = tagsSelected.map((item) => item._id);
      if (list.rows) {
        filteredFolders = list.rows.filter((item) => {
          const labelsList = item.labels;
          return labelsList.some((tag) => selectedList.includes(tag._id));
        });
        setFilteredInfo({ ...list, rows: filteredFolders });
        handleTagsSelected(tagsSelected);
      } else if (list) {
        const filterTags = list.filter((item) => {
          return item.labels.some((tag) => selectedList.includes(tag._id));
        });
        setFilteredInfo(filterTags);
        handleTagsSelected(tagsSelected);
      }
    } else {
      setFilteredInfo(list);
      handleTagsSelected([]);
    }

    handleClose();
  };

  useEffect(() => {
    if (currentTagSelected.length === 0) {
      setFilterButtonDisabled(!countChecked);
    } else if (currentTagSelected.length > 0) {
      setFilterButtonDisabled(false);
    }
  }, [countChecked, currentTagSelected, show]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<LocalOfferIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{
          width: `${widthStyle}`,
          height: `${heightStyle} !important`,
        }}
      >
        <p className="button">Ver por etiqueta</p>
      </Button>
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">Ver por etiqueta</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Por favor, selecciona las etiquetas con las que deseas
                  filtrar:
                </p>
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} seleccionadas
                </p>
              </Col>
              <Col>
                <CustomSearchFilterButton
                  list={tags}
                  setFilteredData={setFilteredData}
                  placeholder="Buscar etiqueta"
                  validations={{ maxLength: 20 }}
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row sm={3}>
              {filteredData?.length > 0 ? (
                filteredData.map((tag, i) => {
                  return (
                    <Col
                      className="filter-tag__check"
                      style={{ marginBottom: "1.5%" }}
                      key={tag?._id}
                    >
                      <input
                        defaultChecked={tagSelected.find(
                          (item) => item._id === tag._id
                        )}
                        id={tag._id}
                        name={tag.name}
                        type="checkbox"
                        onClick={(event) => handleChecked(event, tag)}
                      />
                      <label htmlFor={tag._id}>
                        <CardFilter
                          icon={
                            <LocalOfferIcon
                              fontSize="large"
                              className="filter-tag__icon"
                            />
                          }
                          title={tag.name}
                          description={tag.description}
                        />
                      </label>
                    </Col>
                  );
                })
              ) : (
                <label className="caption">
                  No se encontraron resultados para tu búsqueda
                </label>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                disabled={filterButtonDisabled}
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => handleFilterTags(tagSelected)}
              >
                <p className="button">Filtrar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterTagParts;
