import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import ModalDecision from "../../../components/Modals/modalDecision";
import ModalInfo from "../../../components/Modals/modalInfo";
import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
import { CODES } from "../../../utils/codes";
import Breadcrum from "../../breadcrum";
import {
  addTagDocumentTempletes,
  resetOtherDocTagStatus,
} from "./tagDocumentTempletesSlice";

export const DocumentTempleteCreateTag = () => {
  // USESTATES

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  // HOOCKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // SCHEMA
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test(
        "maxNumbers",
        "*Máximo 100 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(100);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    description: yup
      .string()
      .test(
        "maxNumbers",
        "*Máximo 200 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(200);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
  });

  // USEFORM
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // HANDLE FUNCTIONS
  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleCreateTag = async (data) => {
    try {
      setIsLoading(true);
      let obj = {
        name: data.name,
        description: data.description,
      };
      const createTagService = await dispatch(
        addTagDocumentTempletes(obj)
      ).unwrap();
      if (createTagService.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(createTagService);
        setIsOpenModalUnauthorized(true);
        return;
      }

      let responseDataValidated;
      if (
        createTagService?.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        createTagService?.data?.success
      ) {
        responseDataValidated = {
          data: createTagService?.data,
          status: createTagService?.status,
        };
      }

      dispatch(resetOtherDocTagStatus());
      setResponseData(responseDataValidated);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title="Crear Etiqueta" />
      </Row>

      <br />
      <br />
      <br />

      <Row>
        <Form onSubmit={handleSubmit(handleCreateTag)}>
          <Form.Group
            as={Col}
            xs={{
              span: 5,
              offset: 0,
            }}
            className="mb-5"
          >
            <Form.Label className="form__label">
              Nombre de la etiqueta <span style={{ color: "red" }}>{"*"}</span>
            </Form.Label>
            <Form.Control
              {...register("name")}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={101}
              placeholder={"Nombre de la etiqueta"}
            />
            <div className="caption custom-input__error">
              {errors.name?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} className="mb-5" xs={{ span: 8, offset: 0 }}>
            <Form.Label className="form__label">
              Descripción de la etiqueta
            </Form.Label>
            <Form.Control
              {...register("description")}
              as="textarea"
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={201}
              placeholder="Descripción de la etiqueta"
            />
            <div className="caption custom-input__error">
              {errors.description?.message}
            </div>
          </Form.Group>
          <Row
            xs={"auto"}
            style={{
              paddingLeft: "17px",
            }}
          >
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>

      {/* MODALS */}
      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
    </Container>
  );
};

export default DocumentTempleteCreateTag;
