import React, { useCallback, useEffect, useState } from "react";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Breadcrum from "../../../../../../../parts/breadcrum";
import CustomSearch from "../../../../../../../components/Datatable/customSearch";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LoadingContent from "../../../../../../../components/loadingContent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import SectionTitle from "../../../../../../../components/sectionTitle";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import { Col, Container, Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { CODES } from "../../../../../../../utils/codes";
import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";
import { deletePermanentlyForm, restoreTemporalFormDelete } from "../../../../../../../services/documents/formCustomization/CustomizationService";
import { fetchFormsDelete, getFormListDelete, getFormListStatusDelete, resetFormListStatus } from "../../personalize/formCustomization/states/formCustomizationSlice";
import DetailsForm from "../../personalize/formCustomization/components/DetailsForm";

const columns = [
    {
        label: [
            <label aria-hidden="true" key="0">
                Formulario
            </label>,
        ],
        field: "element",
    },
    {
        label: [
            <label aria-hidden="true" key="1">
                Fecha de eliminación
            </label>,
        ],
        field: "deleteAt",
    },
    {
        label: [
            <label aria-hidden="true" key="2">
                Días restantes
            </label>,
        ],
        field: "countDays",
    },
    {
        label: "",
        field: "actions",
    },
];

const FormsPaperBasket = () => {

    //Redux
    const deleteStatus = useSelector(getFormListStatusDelete);
    const formsListDelete = useSelector(getFormListDelete);

    //hooks
    const dispatch = useDispatch();

    //state

    const [formsList, setFormsList] = useState({ rows: [], columns: columns });
    const [filterFormsList, setFilterFormsList] = useState({ rows: [], columns: columns });
    const [anchorSelected, setAnchorSelected] = useState(null);
    const openSelected = Boolean(anchorSelected);
    const [selectedElement, setSelectedElement] = useState({ id: "", type: "", name: "" });
    const [openModalInfo, setOpenModalInfo] = useState({ message: "", title: "", isOpen: false });
    const [openModalDecision, setOpenModalDecision] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);

    const optionMenu = [
        {
            title: "Restaurar",
            icon: <RestoreFromTrashOutlinedIcon fontSize="large" />,
            option: 0,
        },
        {
            title: "Eliminar permanentemente",
            icon: <DeleteForeverOutlinedIcon fontSize="large" />,
            option: 1,
        },
        {
            title: "Ver detalles",
            icon: <InfoOutlinedIcon fontSize="large" />,
            option: 2,
        },
    ];

    //handles
    const handleCloseSelected = () => {
        setAnchorSelected(null);
        setSelectedElement({ id: "", type: "", name: "" });
    };

    const formDeletedRequest = useCallback(async (forms) => {
        if (!forms?.length) return; const finalList = forms;
        const listForms = finalList.flatMap(form => form.subtypes.filter(subtype => !!subtype?.company));
        const componentDocumentList = listForms.map((form, index) => {
            const modified = form?.deletedAt || form?.updatedAt || "";
            return {
                element: (
                    <Row
                        style={{
                            paddingLeft: "2%",
                            paddingTop: "1%",
                            cursor: "pointer",
                        }}
                    >
                        <Col xs={"auto"} className="datatable-CB__tr-name">
                            <ArticleOutlinedIcon fontSize="large" className="heading__primary-color" />
                        </Col>
                        <Col className="datatable-CB__tr-name">
                            {form?.name || ""}
                        </Col>
                    </Row>
                ),
                deleteAt: modified,
                countDays: form?.daysUntilDelete || 0,
                deletedBy: form?.deletedBy,
                actions: (
                    <IconButton
                        data-testid={`menu-btn-test-${index}`}
                        onClick={(event) => {
                            setSelectedElement(form);
                            setAnchorSelected(event.currentTarget);
                        }}
                    >
                        <MoreHorizIcon fontSize="large" />
                    </IconButton>
                ),
            };
        });
        setFormsList({ rows: componentDocumentList, columns: columns });
        setFilterFormsList({
            rows: componentDocumentList,
            columns: columns,
        });
    }, []);

    const handleManageOptionsMenu = (option) => {
        if (option === 0) {
            handleRestore();
        } else if (option === 1) {
            setOpenModalDecision(true);
        } else if (option === 2) {
            setAnchorSelected(null);
            setOpenDetailsDrawner(true);
        }
    };

    const handleRestore = async () => {
        try {
            setIsloading(true);
            setAnchorSelected(null);
            const companyId = localStorage.getItem("company");
            const requestRestoreForm = await restoreTemporalFormDelete({
                companyId,
                originalFormId: selectedElement?.originalFormId,
                formId: selectedElement?._id,
            });
            if (
                requestRestoreForm?.status === CODES.COD_RESPONSE_HTTP_OK &&
                requestRestoreForm?.data?.success
            ) {
                setOpenModalInfo({
                    message: {
                        status: 201,
                        data: { responseMessage: "Se ha restaurado con éxito el formulario." }
                    },
                    title: "Correcto",
                    isOpen: true,
                });
                dispatch(resetFormListStatus());
            } else {
                setOpenModalInfo({
                    message: {
                        status: 500,
                        data: requestRestoreForm?.data
                    },
                    title: "Error",
                    isOpen: true,
                });
            }
        } catch (error) {
            console.log("========== HANDLE RESTORE START ERROR ==========");
            console.error(error);
            console.log("========== HANDLE RESTORE END ERROR ==========");
        } finally {
            setIsloading(false);
        }
    };

    const handleDeletePerma = async () => {
        try {
            setAnchorSelected(null);
            setIsloading(true);
            const companyId = localStorage.getItem("company");
            const requestDeleteForm = await deletePermanentlyForm({
                companyId,
                originalFormId: selectedElement?.originalFormId,
                formId: selectedElement?._id,
            });
            if (
                requestDeleteForm.status === CODES.COD_RESPONSE_HTTP_CREATED &&
                requestDeleteForm.data.success
            ) {
                setOpenModalDecision(false);
                setOpenModalInfo({
                    message: {
                        status: 200,
                        data: { responseMessage: "Se ha eliminado correctamente el formulario." }
                    },
                    title: "Correcto",
                    isOpen: true,
                });
                dispatch(resetFormListStatus());
            } else {
                setIsloading(false);
                setOpenModalDecision(false);
                setOpenModalInfo({
                    message: {
                        satus: 500,
                        data: requestDeleteForm?.data
                    },
                    title: "Error",
                    isOpen: true,
                });
            }
        } catch (error) {
            console.log("========== HANDLE DELETE START ERROR ==========");
            console.log(error);
            console.log("========== HANDLE DELETE START ERROR ==========");
        } finally {
            setIsloading(false);
        }
    };

    const handleFilterByMyId = (isCheck) => {   
        if (isCheck) {
            const infoUser = JSON.parse(localStorage.payloadToken);
            setFilterFormsList((prev) => ({
                rows: prev.rows.filter(
                    (row) =>
                        row?.deletedBy?.firstName === infoUser.firstName &&
                        row?.deletedBy?.firstSurname === infoUser.firstSurname
                ),
                columns: columns,
            }));
        } else {
            setFilterFormsList(formsList);
        }
    };

    //useEffect
    useEffect(() => {
        if (formsListDelete) {
            formDeletedRequest(formsListDelete);
        }
    }, [formsListDelete, formDeletedRequest]);

    useEffect(() => {
      const fetchData = async () => {
        if (deleteStatus === "loading") {
            dispatch(fetchFormsDelete());
        }
      };
      fetchData();
    }, [deleteStatus, dispatch]);

    useEffect(() => {
        setIsloading(deleteStatus === "loading");
    }, [deleteStatus])

    return (
        <Container fluid>
            {isLoading && <LoadingContent />}
            <Row style={{ height: "9%" }} className="col-flex">
                <Breadcrum />
            </Row>
            <Row style={{ height: "10%" }}>
                <Col md={"auto"} className="col-start">
                    <SectionTitle title={"Basurero de formularios"} />
                </Col>
            </Row>
            <br />
            <Row style={{ rowGap: "1rem", alignItems: "center" }}>
                <Col xs={12} md={6} lg={5}>
                    <CustomSearch
                        dataTableInfo={formsList}
                        setFilteredDataTable={setFilterFormsList}
                    />
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Form.Group className="body" controlId="Terminos">
                        <Form.Check
                            data-testid="check-btn-test"
                            className="label__description__default"
                            type="checkbox"
                            label="Solo eliminados por mí"
                            onChange={(e) => handleFilterByMyId(e.target.checked)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <br />
            <Row className="dt-list-items-change-last-child">
                <MDBDataTableV5
                    hover
                    pagingTop
                    searchBottom={false}
                    entries={6}
                    data={filterFormsList}
                    entriesLabel=""
                    infoLabel={["Mostrando", "a", "de", "elementos"]}
                    fullPagination
                    noRecordsFoundLabel={"No se han encontrado resultados"}
                    onSort={(value) => {
                      onSortHTMLElement({
                        excludesColumns: ["actions"],
                        sort: value,
                        filteredDataTable: filterFormsList,
                        setFilteredDataTable: setFilterFormsList,
                      });
                    }}
                />
                <Menu
                    data-testid="menu-event-test"
                    id="basic-menu"
                    anchorEl={anchorSelected}
                    open={openSelected}
                    onClose={handleCloseSelected}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {optionMenu.map((doc) => (
                        <MenuItem
                            data-testid={`btn-option-${doc.option}`}
                            key={uuidv4()}
                            onClick={() => {
                                handleManageOptionsMenu(doc.option);
                            }}
                        >
                            <ListItemIcon>{doc.icon}</ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontFamily: "Roboto",
                                    fontSize: "1.4rem",
                                    fontWeight: "regular",
                                    lineHeight: "2rem",
                                    color: "#676879",
                                }}
                                primary={`${doc.title}`}
                            />
                        </MenuItem>
                    ))}
                </Menu>
            </Row>
            <ModalInfo
                title={openModalInfo.title}
                open={openModalInfo.isOpen}
                onClose={() => {
                    setOpenModalInfo((prev) => ({ ...prev, isOpen: false }));
                }}
                responseData={openModalInfo.message}
            />
            <ModalDecision
                title={"Eliminar"}
                message={`¿Seguro quieres eliminarlo definitivamente?`}
                agreeText={"Eliminar"}
                disagreeText={"Cancelar"}
                handleAgree={() => {
                    handleDeletePerma();
                }}
                handleDisagree={() => setOpenModalDecision(false)}
                onClose={() => setOpenModalDecision(false)}
                open={openModalDecision}
            />
            <DetailsForm
                openDetailsDrawer={openDetailsDrawner}
                setOpenDetailsDrawer={setOpenDetailsDrawner}
                selectedElement={selectedElement}
            />
        </Container>
    );
};

export default FormsPaperBasket;
