import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CardFilter from "../../components/Cards/CardFilter";
import CustomSearchFilter from "../../components/Search/CustomSearchFilter";
import { Mixpanel } from "../../utils/mixPanel";

const FilterTag = ({
  tagsList,
  list,
  setFilteredInfo,
  handleTagsSelected,
  classStyle = "custom-input__button__primary-color",
  widthStyle = "auto",
  heightStyle = "auto",
}) => {
  const [show, setShow] = useState(false);
  const [countChecked, setCountChecked] = useState(0);
  const [tags, setTags] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleShow = () => {
    setFilteredInfo(list);
    setTags(tagsList);
    setFilteredData(tagsList);
    setShow(true);
    Mixpanel.track("Btn ver por etiqueta  ", {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  const handleChecked = (e) => {
    const item = e.target;
    if (item.checked) {
      setCountChecked(countChecked + 1);
      setTagSelected((oldArray) => [...oldArray, item.name]);
    } else {
      setCountChecked(countChecked - 1);
      setTagSelected(tagSelected.filter((tag) => tag !== item.name));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleFilterTags = (tagsSelected) => {
    if (tagsSelected.length > 0) {
      let filteredFolders = [];
      if (list.rows) {
        let filterTags = [];
        list.rows.map((item) => {
          /**ALINEADAS CON REACT ELEMENTS EN TABLA */
          if (item.labels?.props) {
            const rawLabels = item.labels.props.children;
            const labelsList = rawLabels.split(",").map((item) => {
              return item.trim();
            });
            filterTags = labelsList.some((tag) => tagsSelected.includes(tag));
            if (filterTags) {
              filteredFolders.push(item);
            }
          } else {
            /**SIN ALINEACIÓN DE ELEMENTOS CON REACT */
            const rawLabels = item.labels;
            const labelsList = rawLabels?.split(",").map((item) => {
              return item.trim();
            });
            filterTags = labelsList?.some((tag) => tagsSelected.includes(tag));
            if (filterTags) {
              filteredFolders.push(item);
            }
          }
        });

        setFilteredInfo({ ...list, rows: filteredFolders });
        handleTagsSelected(tagsSelected);
      } else if (list) {
        const filterTags = list.filter((item) => {
          return item.labels.some((tag) => tagsSelected.includes(tag.name));
        });
        setFilteredInfo(filterTags);
        handleTagsSelected(tagsSelected);
      }
    } else {
      setFilteredInfo(list);
      setTagSelected([]);
    }

    handleClose();
  };

  return (
    <>
      <Button
        data-testid="viewByTag-test"
        variant="contained"
        startIcon={<LocalOfferIcon />}
        className={classStyle}
        onClick={handleShow}
        style={{
          width: `${widthStyle} !important`,
          height: `${heightStyle} !important`,
        }}
      >
        <p className="button">Ver por etiqueta</p>
      </Button>
      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="label__primary-color__page-title">Ver por etiqueta</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="filter-tag__headers">
              <Col>
                <p className="caption">
                  Por favor selecciona las etiquetas por las que desea filtrar
                  el listado:
                </p>
              </Col>
              <Col sm={{ span: "auto", offset: 3 }}>
                <p className="caption__primary-color__active">
                  {countChecked} seleccionadas
                </p>
              </Col>
              <Col>
                <CustomSearchFilter
                  list={tags}
                  setFilteredData={setFilteredData}
                  placeholder="Buscar etiqueta"
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row xs={12} lg={3}>
              {filteredData?.map((tag, i) => {
                return (
                  <Col
                    className="filter-tag__check"
                    style={{ marginBottom: "1.5%" }}
                    key={tag?._id}
                  >
                    <input
                      data-testid={tag?._id}
                      defaultChecked={tagSelected.find(
                        (item) => item._id === tag._id
                      )}
                      id={tag._id}
                      name={tag.name}
                      type="checkbox"
                      onClick={handleChecked}
                    />
                    <label htmlFor={tag._id}>
                      <CardFilter
                        icon={
                          <LocalOfferIcon
                            fontSize="large"
                            className="filter-tag__icon"
                          />
                        }
                        title={tag.name}
                        description={tag.description}
                        minHeight={"50px"}
                      />
                    </label>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                <p className="button">Cancelar</p>
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                data-testid="buttonFilter-test"
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={() => handleFilterTags(tagSelected)}
              >
                <p className="button">Filtrar</p>
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterTag;
