/**
 * Replace the text at the specific occurrence in a string.
 * 
 * @param {string} str The original string.
 * @param {string} searchText The text to find.
 * @param {string} replaceText The text to replace.
 * @param {number} occurrence The specific occurrence to replace (1 for the first, 2 for the second, etc.).
 * @returns {string | undefined} The resulting string with the replacement done or undefined if the occurrence is not found.
 */
export function replaceTextAtOccurrence(
  str,
  searchText,
  replaceText,
  occurrence
) {
  let index = -1;
  let count = 0;

  /** Search for the specific occurrence of the text. */
  while (count < occurrence) {
    index = str.indexOf(searchText, index + 1);

    if (index === -1) {
      return;
    }

    count++;
  }

  /** Replace the text at the specific occurrence. */
  const replacedString = `${str.substring(
    0,
    index
  )}${replaceText}${str.substring(index + searchText.length)}`;

  return replacedString;
}
