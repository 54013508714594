import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import { env } from "../../../../../../../env";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import { v4 as uuid } from "uuid";
import { Col, Container, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCurrentDocument,
  getReports,
  setReports,
} from "../../../../../../../parts/document/currentDocumentSlice";
import LoadingContent from "../../../../../../../components/loadingContent";
import { CODES } from "../../../../../../../utils/codes";
import fileDownload from "js-file-download";
import PopoverInfo from "../../../../../../../components/Popover/PopoverInfo";
import { convertToPdfService } from "../../../../../../../services/utils/convertWordToPdf";
import { reportValuesCreator } from "../../../../../../../components/WordCreator/reportMilestonesDocx";
import { Mixpanel } from "../../../../../../../utils/mixPanel";
export const ReportsValues = ({ type }) => {
  const MENU_FILTER_BY = [
    {
      title: "Fórmula de reajuste",
      selectionClick: 0,
    },
    {
      title: "Garantías y pólizas",
      selectionClick: 1,
    },
    {
      title: "Limitaciones de responsabilidad",
      selectionClick: 2,
    },
    {
      title: "Multas o cláusulas penales",
      selectionClick: 3,
    },
    {
      title: "Precio de contrato",
      selectionClick: 4,
    },
    {
      title: "Retenciones",
      selectionClick: 5,
    },
    {
      title: "Valor total del contrato",
      selectionClick: 6,
    },
    {
      title: "Otros valores",
      selectionClick: 7,
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const currentDocument = useSelector(getCurrentDocument);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const [anchorFilterBy, setAnchorFilterBy] = useState(null);
  const [filterSelected, setFilterSelected] = useState(null);
  const [nameDocument, setNameDocument] = useState("");
  const openFilterBy = Boolean(anchorFilterBy);
  const handleDeleteFilter = () => {
    setFilterSelected(null);
    setFilteredData(data);
  };
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const handleMenuItemClickFilterBy = (option) => {
    setAnchorFilterBy(null);
    setFilterSelected(option);
    const filter = data.filter((item) => item.type === option.title);
    setFilteredData(filter);
  };
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.VALUES.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  const handleChangeCurrencyType = (item) => {
    if (item.character === "Error") return;
    const VALUE_SMMLV_COLOMBIA = parseFloat(env.REACT_APP_VALUE_SMMLV_COLOMBIA);
    let newValues = { ...item };
    if (item) {
      if (["Peso", "Peso colombiano"].includes(item.currency)) {
        let valueConverted = parseFloat(item.value) / VALUE_SMMLV_COLOMBIA;
        newValues = {
          ...newValues,
          currency: "Salario",
          value: valueConverted.toString(),
          character: "Salario",
          text: `${valueConverted} SMMLV`,
        };
      } else if (item.currency !== "Peso" && item.character === "Salario") {
        let valueConverted = parseFloat(item.value) * VALUE_SMMLV_COLOMBIA;
        newValues = {
          ...newValues,
          currency: "Peso",
          value: valueConverted.toString(),
          character: "Valor",
          text: `$ ${valueConverted.toLocaleString("en-US")}`,
        };
      }
    }
    let updateValues = data.map((current) => ({
      ...current,
      titlesClauses: current.titlesClauses.map((keysPhrases) => ({
        ...keysPhrases,
        keysPhrases: keysPhrases.keysPhrases.map((reports) => ({
          ...reports,
          reports: reports.reports.map((report) =>
            report?.idValue === item?.idValue ? newValues : report
          ),
        })),
      })),
    }));
    let updateReports = dataReports.map((item) => {
      if (item.typeReport === type) {
        return {
          ...item,
          reports: updateValues,
        };
      }
      return item;
    });
    dispatch(setReports({ reports: updateReports }));
  };
  const handleGenerateReport = async () => {
    try {
      setIsLoading(true);
      const doc = await reportValuesCreator({
        reports: data,
        contract: nameDocument,
      });
      Mixpanel.track("Generar PDF IA Informes  ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
        type: "Valores",
      });
      if (doc) {
        const pdf = await convertToPdfService(doc);
        if (pdf.status === CODES.COD_RESPONSE_HTTP_OK) {
          const dateName = dateFormat(new Date(), "isoDate");
          const filename = `${dateName}-Valores-${nameDocument}.pdf`;
          const dataFile = pdf?.data?.responseMessage?.data;
          const file = new Uint8Array(dataFile?.buffer?.data).buffer;
          fileDownload(file, filename);
        }
      }
    } catch (error) {
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
      console.error(error);
      console.log(
        "============ Start Error Download Report Values PDF ============"
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (currentDocument) {
      setNameDocument(
        currentDocument?.title || currentDocument?.name || "Informe_de_valores"
      );
    }
  }, [currentDocument]);
  useEffect(() => {
    if (type) {
      const reportsData = dataReports.find(
        (item) => item.typeReport === type
      ).reports;
      setData(reportsData);
      setFilteredData(reportsData);
    }
  }, [dataReports]);
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ alignItems: "center" }}>
        <Col>
          {filterSelected && (
            <span
              style={{ marginRight: "1%" }}
              className="subheading caption custom-badges__disabled"
            >
              {filterSelected.title}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilter()}
              />
            </span>
          )}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={handleGenerateReport}
          >
            Generar PDF
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            endIcon={<ExpandMoreIcon />}
            className={"custom-input__button__secondary-color"}
            onClick={(e) => setAnchorFilterBy(e.currentTarget)}
          >
            <p className="button">Filtrar por</p>
          </Button>
          <Menu
            id="menu-filter-by"
            anchorEl={anchorFilterBy}
            open={openFilterBy}
            onClose={() => setAnchorFilterBy(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {MENU_FILTER_BY.map((option) => (
              <MenuItem
                key={option.title}
                onClick={() => handleMenuItemClickFilterBy(option)}
              >
                <span className="body-content">{option.title}</span>
              </MenuItem>
            ))}
          </Menu>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        {filteredData?.length > 0 &&
          filteredData.map((item, index) => (
            <Accordion className="custom-accordion" key={item?.type}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="header-reports-values"
              >
                <Col>
                  <p className="heading__primary-color">
                    {`${index + 1}. ${item.type}`}
                  </p>
                </Col>
              </AccordionSummary>
              <AccordionDetails>
                {item?.titlesClauses?.length &&
                  item?.titlesClauses?.map((reportByClause, indexTitle) => (
                    <Accordion
                      key={reportByClause.titleClause}
                      id="custom-accordion-clauses"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="header-reports-values-clauses"
                      >
                        <Col>
                          <p className="heading__primary-color">
                            {`${index + 1}.${indexTitle + 1}. ${
                              reportByClause.titleClause
                            }`}
                          </p>
                        </Col>
                      </AccordionSummary>
                      <AccordionDetails>
                        {reportByClause?.keysPhrases.length > 0 &&
                          reportByClause?.keysPhrases?.map(
                            (reportByKeyPhrase, indexPhrase) => (
                              <div key={uuid()}>
                                {reportByKeyPhrase?.reports?.length > 0 &&
                                  reportByKeyPhrase?.reports?.map(
                                    (report, indexReport) => (
                                      <div
                                        key={report?.idValue}
                                        style={{ marginLeft: "1.5rem" }}
                                      >
                                        <Row
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "end",
                                          }}
                                        >
                                          <Col>
                                            <span className="subheading">
                                              {"Valor:  "}
                                              <span className="caption">
                                                {["Valor", "Salario"].includes(
                                                  report.character
                                                )
                                                  ? `${report?.text || report?.value || ""} `
                                                  : `${report.value} `}
                                              </span>
                                              {report.character === "Error" && (
                                                <PopoverInfo
                                                  section={{
                                                    description:
                                                      "Error en el valor.",
                                                  }}
                                                />
                                              )}
                                            </span>
                                            {["Valor", "Salario"].includes(
                                              report.character
                                            ) && (
                                              <>
                                                <Row>
                                                  <span className="subheading">
                                                    {"Moneda:  "}
                                                    <span className="caption">
                                                      {`${report.currency} `}
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#17B4BC",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                        onClick={() =>
                                                          handleChangeCurrencyType(
                                                            report
                                                          )
                                                        }
                                                      >
                                                        {[
                                                          "Peso",
                                                          "Peso colombiano",
                                                        ].includes(
                                                          report.currency
                                                        )
                                                          ? `Convertir a SMLMV`
                                                          : report.character ===
                                                            "Salario"
                                                          ? `Convertir a pesos`
                                                          : ""}
                                                      </span>
                                                    </span>
                                                  </span>
                                                </Row>
                                                <br />
                                              </>
                                            )}
                                          </Col>
                                          <Col xs={"auto"}>
                                            <Button
                                              variant="contained"
                                              startIcon={<ErrorOutlineIcon />}
                                              className="custom-input__button__secondary-color"
                                              onClick={() => {
                                                setItemReport(report);
                                                setIsOpenModalInputReport(true);
                                              }}
                                            >
                                              <p className="button">Reportar</p>
                                            </Button>
                                          </Col>
                                          <Col xs={"auto"}>
                                            <Button
                                              variant="contained"
                                              startIcon={
                                                <RemoveRedEyeOutlinedIcon />
                                              }
                                              className="custom-input__button__primary-color"
                                              onClick={() =>
                                                navigate(
                                                  `viewContract/#${report.id_text}`
                                                )
                                              }
                                            >
                                              <p className="button">
                                                Ver en contrato
                                              </p>
                                            </Button>
                                          </Col>
                                        </Row>
                                        <br />
                                      </div>
                                    )
                                  )}
                                <br />
                                <Row style={{ marginLeft: "1.5rem" }}>
                                  <span className="subheading">
                                    {"Ubicación en frase completa: "}
                                    <span className="caption">
                                      ... {reportByKeyPhrase.keyPhrase} ...
                                    </span>
                                  </span>
                                </Row>
                                <hr />
                              </div>
                            )
                          )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        {!filteredData?.length > 0 && (
          <span className="caption">No se han encontrado resultados</span>
        )}
      </Row>
      <ModalInpuText
        title={"Reportar valor"}
        message={"¿Deseas reportar el siguiente valor?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
