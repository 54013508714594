import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const getApprovals = ({ requestConsecutive, companyId, corporateUnitId }) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getApprovals/${requestConsecutive}`,
    params: {
      consecutive: requestConsecutive,
      companyId,
      corporateUnitId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getApprovalById = ({ approvalId }) => {
  return axios({
    method: "GET",
    url: `approvals/getOne/${approvalId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const addComment = ({
  consecutive,
  approvalId,
  newState,
  userName,
  userPosition,
  message,
  associatedDocument,
  isCommentOnly,
  sendEmail,
  isDiscussionComment,
  isDesisted,
  isApprovedWithComments = false,
  companyId = "",
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/createApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      companyId,
      consecutive: consecutive,
      isDiscussionComment: isDiscussionComment,
      isApprovedWithComments,
      addCommentDto: {
        approvalId: approvalId,
        newState: newState,
        approvalComment: {
          approvalId: approvalId,
          userName: userName,
          userPosition: userPosition,
          message: message,
          associatedDocument: associatedDocument,
          isCommentOnly: isCommentOnly,
          isSendMail: sendEmail,
          isDesisted: isDesisted,
        },
      },
      corporateUnit
    },
  });
};
export const desistApproval = ({
  consecutive,
  approvalId,
  newState,
  userName,
  userPosition,
  message,
  associatedDocument,
  isCommentOnly,
  sendEmail,
  isDiscussionComment,
  isDesisted,
  isApprovedWithComments = false,
  corporateUnit,
  companyId
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/desistApprovalComment`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      isDiscussionComment: isDiscussionComment,
      isApprovedWithComments,
      addCommentDto: {
        approvalId: approvalId,
        newState: newState,
        approvalComment: {
          approvalId: approvalId,
          userName: userName,
          userPosition: userPosition,
          message: message || "Aprobación desistida",
          associatedDocument: associatedDocument,
          isCommentOnly: isCommentOnly,
          isSendMail: sendEmail,
          isDesisted: isDesisted,
        },
      },
      corporateUnit,
      companyId
    },
  });
};

export const sendReminder = ({
  consecutive,
  approvalId,
  userName,
  userPosition,
  message,
  associatedDocument,
  company,
  corporateUnit
}) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/rememberApproval`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      consecutive: consecutive,
      approvalId: approvalId,
      userName: userName,
      userPosition: userPosition,
      message: message,
      associatedDocument: associatedDocument,
      company,
      corporateUnit
    },
  });
};

export const sendApprovaWithDocument = ({
  approvalId,
  companyId,
  consecutive,
  document,
  documentType,
  applicantName,
  attorneyName,
  description,
  corporateUnit
}) => {
  const formData = new FormData();
  formData.append("companyId", companyId);
  formData.append("consecutive", consecutive);
  formData.append(`${documentType}`, document);
  formData.append(`applicantName`, applicantName);
  formData.append(`attorneyName`, attorneyName);
  formData.append(`description`, description);
  formData.append(`approvalId`, approvalId);
  formData.append(`corporateUnit`, corporateUnit);
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/add/documentsForApproval`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};

export const updateOneApproval = (data) => {
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/updateApprovalwithDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};
