import RegulatedInputs from "../../../utils/regulatedInputs";

/**
 * Inputs for a blank space form that use the BlankSpaceSchema
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @readonly
 */
const BlankSpaceFormInputs = Object.freeze({
  Name: {
    inputName: "name",
    inputType: "text",
    inputLabel: "Renombrar espacio en blanco",
    inputPlaceholder: "Escribe un nombre...",
    maxLength: RegulatedInputs.Short.rules?.maxLength,
    maxLengthErrorMessage: RegulatedInputs.Short.rules?.maxLengthErrorMessage,
  },
  Description: {
    inputName: "description",
    inputType: "text",
    inputLabel: "Agrega una descripción al espacio en blanco",
    inputPlaceholder: "Agrega una descripción...",
    maxLength: RegulatedInputs.Long.rules?.maxLength,
    maxLengthErrorMessage: RegulatedInputs.Long.rules?.maxLengthErrorMessage,
  },
});

export default BlankSpaceFormInputs;
