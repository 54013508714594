import React from "react";
import { Col, Row } from "react-bootstrap";
export default function ProgressBarCapacity({
  used,
  capacity,
  typeInfo,
  infoLabel,
  colorProgress,
  colorBackground,
}) {
  let currentColorProgress = colorProgress || "#00374F";

  if (used > capacity) {
    currentColorProgress = "#E44258";
  }

  const percentage = (Number(used) * 100) / Number(capacity);

  return (
    <div style={{ height: "inherit" }}>
      {typeInfo && (
        <Row style={{ marginBottom: "0.4rem" }}>
          <Col>
            <span
              style={{
                color: currentColorProgress,
              }}
              className="caption"
            >
              {`${used.toFixed(2) || 0}${typeInfo || ""} ${infoLabel ? infoLabel.join(" ") : " - "
                } ${capacity || 0}${typeInfo || ""
                } ( ${percentage.toFixed(2)}% )`}
            </span>
          </Col>
          <Col className="caption" xs={"auto"}>
            <span>{`${capacity}${typeInfo || ""}`}</span>
          </Col>
        </Row>
      )}
      <Row style={{ marginBottom: "0.4rem" }}>
        <Col>
          <div
            style={{
              background: colorBackground || "#eeeeee",
              borderRadius: "6px",
              height: 12,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: 12,
                transform:
                  percentage <= 100 ? `translateX(${percentage - 100}%)` : null,
                background: currentColorProgress,
              }}
            ></div>
          </div>
        </Col>
      </Row>
      {typeInfo && (
        <Row>
          <Col>
            <span className="caption">0%</span>
          </Col>
          <Col xs={"auto"}>
            <span className="caption">100%</span>
          </Col>
        </Row>
      )}
    </div>
  );
}
