import { UpdatedCustomRequestTypeDto } from "../dto/updatedCustomRequestTypeDto";

/**
 * Provides a factory for creating instances of the UpdatedCustomRequestTypeDto class that is configured for specific use cases.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @class
 */
export class UpdatedCustomRequestTypeDtoFactory {
  /**
   * Creates a new instance of the UpdatedCustomRequestTypeDto class, that is configured for update a custom form.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @param {string} props.formId - The id of the form.
   * @param {string} props.companyId - The id of the company.
   * @param {string} [props.group] - The type of the form.
   * @param {string} [props.type] - The subtype of the form.
   * @param {string} [props.name] - The name of the form.
   * @param {string} [props.description] - The description of the form.
   * @param {QuestionFormDto} [props.fields] - The fields of the form. [Optional
   * @param {SectionsFormDto} [props.updateActualQuestions] - The section of the form.
   * @param {DocumentsListDto[]} [props.listDocuments] - The list of documents of the form.
   * @param {boolean} [props.allowUpdateOtherDocumentsToTrace] - Whether the form allows update other documents to trace or not.
   * @param {any[]} [props.labels] - The labels of the form.
   * @param {string} [props.modifiedBy] - The id of the user who modified the form.
   * @param {string} [props.modifiedBy] - The id of the user who modified the form.
   * @param {number} [props.startModificationDate] - The start modification date of the form.
   * @returns {UpdatedCustomRequestTypeDto} The new instance of the UpdatedCustomRequestTypeDto class.
   * @throws {Error} If the validation fails.
   * @example // Valid constructions
   * // All props
   * const updatedCustomRequestTypeDto = UpdatedCustomRequestTypeDtoFactory.createUpdateCustomFormDto({ formId: 'formId', companyId: 'companyId', updateActualQuestion: sectionsFormDto, listDocuments: documentsListDto, allowUpdateOtherDocumentsToTrace: true, labels: [], modifiedBy: 'modifiedBy' });
   * // Some props
   * const updatedCustomRequestTypeDto = UpdatedCustomRequestTypeDtoFactory.createUpdateCustomFormDto({ formId: 'formId', updateActualQuestion: sectionsFormDto });
   */
  static createUpdateCustomFormDto({
    formId,
    companyId,
    group,
    type,
    name,
    description,
    updateActualQuestions,
    listDocuments,
    allowUpdateOtherDocumentsToTrace,
    labels,
    modifiedBy,
    startModificationDate,
    fields,
  }) {
    UpdatedCustomRequestTypeDtoFactory.validateCreateUpdateCustomFormDto({
      companyId,
      group,
      type,
      name,
      description,
      updateActualQuestions,
      listDocuments,
      allowUpdateOtherDocumentsToTrace,
      labels,
      modifiedBy,
    });

    return UpdatedCustomRequestTypeDto.fromObject({
      formId,
      companyId,
      group,
      type,
      name,
      description,
      updateActualQuestions,
      listDocuments,
      allowUpdateOtherDocumentsToTrace,
      labels,
      modifiedBy,
      startModificationDate,
      fields,
    });
  }

  /**
   * Validates the properties of the object for createUpdateCustomFormDto.
   * @method
   * @static
   * @param {Object} props - The options for the constructor.
   * @throws {Error} If the validation fails.
   */
  static validateCreateUpdateCustomFormDto({
    companyId,
    group,
    type,
    name,
    description,
    updateActualQuestions,
    listDocuments,
    allowUpdateOtherDocumentsToTrace,
    labels,
    modifiedBy,
  }) {
    if (!companyId) throw new Error("The companyId is required");
    if (!modifiedBy) throw new Error("The modifiedBy is required");
    if (
      !updateActualQuestions &&
      !listDocuments &&
      !allowUpdateOtherDocumentsToTrace &&
      !labels &&
      !group &&
      !type &&
      !name &&
      !description
    ) {
      throw new Error(
        "At least one of the following properties is required: updateActualQuestions, listDocuments, allowUpdateOtherDocumentsToTrace, labels, group, type, name, description"
      );
    }
  }
}
