import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalDecision = ({
  title,
  message,
  children,
  agreeText,
  disagreeText,
  handleAgree,
  handleDisagree,
  onClose,
  open,
  type,
  agreeIcon,
  disagreeIcon,
  agreeIconStart = null,
  disagreeIconStart = null,
}) => {
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose(event);
    } 
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          {!type && <label className="heading__primary-color">{title}</label>}

          {type === "warning" && (
            <label className="heading__warning-color">
              <ErrorOutlineIcon fontSize="large" />
              {"   "}
              {title}
            </label>
          )}
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          {message ?? children}
        </DialogContent>
        <DialogActions>
          <Row style={{ width: "100%", justifyContent: "end" }}>
            <Col xs={"auto"}>
              {disagreeText && (
                <Button
                  data-testid="btn-disagree-modal-decision"
                  variant="text"
                  className="custom-input__button__secondary-color"
                  startIcon={disagreeIconStart || ""}
                  onClick={handleDisagree}
                  endIcon={disagreeIcon || ""}
                >
                  {disagreeText}
                </Button>
              )}
            </Col>
            <Col xs={"auto"}>
              {agreeText && (
                <Button
                  data-testid="btn-agree-modal-decision"
                  variant="contained"
                  className="custom-input__button__primary-color"
                  startIcon={agreeIconStart || ""}
                  onClick={handleAgree}
                  endIcon={agreeIcon || ""}
                >
                  {agreeText}
                </Button>
              )}
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDecision;
