import * as yup from "yup";

/**
 * Creates a yup schema based on the inputs structure.
 * 
 * @param {Object} inputsStructure The inputs structure.
 * @returns The yup schema.
 * @example
 * const inputsStructure = Object.freeze({
 *  Name: {
 *    InputName: "name",
 *    DefaultValue: "Nombre de ejemplo",
 *    InputType: "text",
 *    Validations: {
 *      ValueType: "string",
 *      Required: true,
 *      RequiredMessage: "Este campo es obligatorio",
 *    },
 *  },
 * });
 * const schema = createYupSchemaBasedOnInputsStructure(inputsStructure);
 */
export default function createYupSchemaBasedOnInputsStructure(inputsStructure) {
  return yup.object().shape(
    Object.values(inputsStructure).reduce((schema, inputStructure) => {
      let yupInputBuilder = yup;

      switch (inputStructure.Validations?.ValueType) {
        case "string":
          yupInputBuilder = yupInputBuilder.string().default(inputStructure.DefaultValue);
          break;
        case "number":
          yupInputBuilder = yupInputBuilder.number().default(inputStructure.DefaultValue);
          break;
        case "boolean":
          yupInputBuilder = yupInputBuilder.boolean().default(inputStructure.DefaultValue);
          break;
        default:
          break;
      }
      if (inputStructure.Validations?.Required) {
        yupInputBuilder = yupInputBuilder.required(inputStructure.Validations.RequiredMessage);
      }
      if (inputStructure.Validations?.MaxLength) {
        yupInputBuilder = yupInputBuilder.max(
          inputStructure.Validations.MaxLength,
          inputStructure.Validations.MaxLengthMessage
        );
      }
      if (inputStructure.Validations?.Options) {
        yupInputBuilder = yupInputBuilder.oneOf(
          inputStructure.Validations.Options,
          `*Opción no válida, las opciones permitidas son: ${inputStructure.Validations.Options.join(
            ", "
          )}`
        );
      }

      schema[inputStructure.InputName] = yupInputBuilder;
      return schema;
    }, {})
  );
}