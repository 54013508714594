import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ClearIcon from "@mui/icons-material/Clear";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { ModalListSelectCheckBox } from "../../../../../../components/Modals/ModalListCheckBox";
import SectionTitle from "../../../../../../components/sectionTitle";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import Breadcrum from "../../../../../../parts/breadcrum";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";
import {
  createAdminUser,
  createUser,
  getUserPermissionsBySubRole,
} from "../../../../../../services/admin/manageUserService";
import { CODES } from "../../../../../../utils/codes";
import { countries } from "../../../../../../utils/countries";
import { REGEXP } from "../../../../../../utils/regexp";
import { RESPONSE_UNITS_CORPORATIVES } from "../../../../../../utils/responseUnitsCorporatives";
import HelpButton from "../../../Components/HelpButton";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../unitCorporative/unitCorporativeSlice";
import { createUser as createUserRedux } from "../companyUsersSlice";
import {
  getCurrentUser,
  getUserCorporateUnits,
  getUserPermissions,
  isStoringInfo,
  setCurrentUserDataTest,
  setNewUnits,
  setPermissions,
} from "../currentUserSlice";
const CreateUser = () => {
  /**
   * Variables de selección
   */

  let typeOfUser = [
    {
      id: 0,
      name: "Usuario administrador",
      value: "ADMIN",
    },
    {
      id: 1,
      name: "Usuario de Servicios",
      value: "SERVICE",
    },
    {
      id: 2,
      name: "Usuario solicitante",
      value: "EXTERNAL",
    },
  ];

  const adminRoles = [
    {
      id: 1,
      name: "Usuario administrador",
      value: "ADMIN ADMINISTRADOR",
    },
  ];

  const serviceRoles = [
    {
      id: 0,
      name: "Abogado Director",
      value: "SERVICE DIRECTOR",
    },
    {
      id: 1,
      name: "Abogado Senior",
      value: "SERVICE SENIOR",
    },
    {
      id: 2,
      name: "Abogado Junior",
      value: "SERVICE JUNIOR",
    },
    {
      id: 3,
      name: "Practicante",
      value: "SERVICE PRACTICANTE",
    },
  ];

  const externalRoles = [
    {
      id: 0,
      name: "Usuario solicitante",
      value: "EXTERNAL SOLICITANTE",
    },
  ];

  const arrayUserRoles = {
    ADMIN: adminRoles,
    SERVICE: serviceRoles,
    EXTERNAL: externalRoles,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(getUserPermissions);
  const userCorporativeUnits = useSelector(getUserCorporateUnits);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const [documentType, setDocumentType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [typeSelected, setTypeSelected] = useState("");
  const [subTypeSelected, setSubTypeSelected] = useState("");
  const [unitCorporativeSelected, setUnitCorporativeSelected] =
    useState(userCorporativeUnits);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [isApplyPhoneCheck, setIsApplyPhoneCheck] = useState(false);
  const [actualCallSign, setActualCallSign] = useState("57");
  const [modalInfoTitle, setOpenModalInfoTitle] = useState("");
  const companyPlan = useSelector(getCompanyPlan);
  const [createUserDisabled, setCreateUserDisabled] = useState(false);
  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");

  const storingInfo = useSelector(isStoringInfo);
  const getCurrentUserInfo = useSelector(getCurrentUser);
  const handleDeleteUnitCorporative = (item) => {
    const deleteFilter = unitCorporativeSelected.filter(
      (unit) => unit._id !== item._id
    );
    setUnitCorporativeSelected(deleteFilter);
  };

  const schema = yup.object().shape({
    role: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    subrole: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    documentType: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
    documentNumber: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.LETTERS_AND_NUMBERS, {
        message: "*Solo debe incluir números y letras",
      }),
    firstName: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" }),
    secondName: yup
      .string()
      .notRequired()
      .test("secondName", "*Solo se permiten letras", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONLY_LETTERS);
          return schema.isValidSync(value);
        }
        return true;
      }),
    firstSurname: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" }),
    secondSurname: yup
      .string()
      .notRequired()
      .test("SurName", "*Solo se permiten letras", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONLY_LETTERS);
          return schema.isValidSync(value);
        }
        return true;
      }),
    documentType2: !documentType
      ? yup.string().nullable().default("")
      : yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
    email: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido"),
    emailConfirmation: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido")
      .oneOf([yup.ref("email"), null], "*El correo no coincide"),

    countryCode: yup.string().required("*Este campo es obligatorio"),
    phoneNumber: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_NUMBERS, {
        message: "*Solo debe incluir números",
      }),
    extension: !isApplyPhoneCheck
      ? yup
          .string()
          .notRequired()
          .test("extension", "*Solo se permiten números", function (value) {
            if (!!value) {
              const schema = yup.string().matches(REGEXP.ONLY_NUMBERS);
              return schema.isValidSync(value);
            }
            return true;
          })
      : yup.string().nullable().default(""),
    cityCode: !isApplyPhoneCheck
      ? yup
          .string()
          .required("*Este campo es obligatorio")
          .min(1, "Mínimo 1 números")
          .max(1, "Máximo 1 números")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
    telephoneNumber: !isApplyPhoneCheck
      ? yup
          .string()
          .min(7, "Mínimo 7 números")
          .max(7, "Máximo 7 números")
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSetDocument = (e) => {
    e.target.value === "OTRO" ? setDocumentType(true) : setDocumentType(false);
  };

  const handleCreateUser = async (data) => {
    try {
      setIsLoading(true);
      if (unitCorporativeSelected.length <= 0) {
        setResponseData(RESPONSE_UNITS_CORPORATIVES.CREATE_ERROR_WO_UC);
        setIsOpenModal(true);
        return;
      }
      const finalUnits = unitCorporativeSelected.map((unit) => {
        return unit._id;
      });
      const payloadJWT = JSON.parse(localStorage.getItem("payloadToken"));
      let infoTelephone = {};
      if (!isApplyPhoneCheck) {
        infoTelephone = {
          cityCode: data?.cityCode || "",
          extension: data?.extension || "",
          landlineNumber: data?.telephoneNumber || "",
        };
      }
      let obj = {
        userData: {
          email: data.email,
          phoneNumber: data.phoneNumber,
          firstName: data.firstName,
          secondName: data.secondName,
          firstSurname: data.firstSurname,
          secondSurname: data.secondSurname,
          documentType: data.documentType,
          documentNumber: data.documentNumber,
          role: data.role,
          subRole: data.subrole,
          countryCode: data.countryCode,
          ...infoTelephone,
        },
        permissions: userPermissions,
        corporateUnits: finalUnits,
        tokenInfo: {
          email: payloadJWT.email,
          role: payloadJWT["custom:role"],
          company: payloadJWT.company,
        },
        _id: payloadJWT.userId,
      };

      if (data.role === "EXTERNAL") {
        let { subRole, permissions, ...external } = obj.userData;

        obj = {
          userData: {
            ...external,
            subRole: "EXTERNAL SOLICITANTE",
          },
          corporateUnits: finalUnits,
          permissions: userPermissions,
          tokenInfo: {
            email: payloadJWT.email,
            role: payloadJWT["custom:role"],
            company: payloadJWT.company,
          },
          _id: payloadJWT.userId,
        };
      }
      let createUserResponse = "";
      if (
        obj.userData.role === "ADMIN" &&
        obj.userData.subRole === "ADMIN PROPIETARIO"
      ) {
        createUserResponse = await createAdminUser(obj);
      } else {
        createUserResponse = await createUser(obj);
      }

      if (createUserResponse.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        if (
          createUserResponse.data.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
        ) {
          setOpenModalInfoTitle("Máximo de usuarios creados");
        } else {
          setOpenModalInfoTitle("Sin permisos suficientes");
        }
        setResponseDataUnauthorized(createUserResponse);
        setIsOpenModalUnauthorized(true);

        return;
      }

      setResponseData(createUserResponse);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);

    if (
      responseData.status === CODES.COD_RESPONSE_HTTP_CREATED ||
      responseData.data.success
    ) {
      dispatch(
        createUserRedux({ createdUser: responseData.data.responseMessage.data })
      );
      navigate("/admin/manageUser");
    }
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleSaveChanges = () => {
    const value = getValues();
    let data = {
      storingUserInfo: true,
      email: value?.email,
      phoneNumber: value?.phoneNumber,
      firstName: value?.firstName,
      secondName: value?.secondName,
      firstSurname: value?.firstSurname,
      secondSurname: value?.secondSurname,
      documentType: value?.documentType,
      documentNumber: value?.documentNumber,
      role: value?.role,
      subRole: value?.subrole,
      countryCode: value?.countryCode,
      extension: value?.extension,
      emailConfirmation: value?.emailConfirmation,
      telephoneNumber: value?.telephoneNumber,
    };
    dispatch(setCurrentUserDataTest(data));
  };

  useEffect(() => {
    try {
      const { subRole, corporateUnits: defaultUC } = JSON.parse(
        localStorage.getItem("payloadToken")
      );

      const corporateUnits = localStorage.getItem("userCorporateUnits")
        ? JSON.parse(localStorage.getItem("userCorporateUnits"))
        : defaultUC;
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.log("========== Error Fetch SubType User ==========");
      console.log(error);
      console.log("========== Finish Error Fetch SubType User ==========");
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
    if (statusFetchUnits === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    try {
      if (storingInfo) {
        setValue("documentType", getCurrentUserInfo?.documentType);
        setValue("documentNumber", getCurrentUserInfo?.documentNumber);
        setValue("firstName", getCurrentUserInfo?.firstName);
        setValue("secondName", getCurrentUserInfo?.secondName);
        setValue("firstSurname", getCurrentUserInfo?.firstSurname);
        setValue("secondSurname", getCurrentUserInfo?.secondSurname);
        setValue("email", getCurrentUserInfo?.email);
        setValue("phoneNumber", getCurrentUserInfo?.phoneNumber);
        setValue("countryCode", getCurrentUserInfo?.countryCode);
        setValue("extension", getCurrentUserInfo?.extension);
        setValue("role", getCurrentUserInfo?.role);
        setTypeSelected(getCurrentUserInfo?.role);
        if (getCurrentUserInfo?.role === "EXTERNAL") {
          setValue("subrole", "EXTERNAL");
        } else {
          setValue("subrole", getCurrentUserInfo?.subRole);
        }
        setSubTypeSelected(getCurrentUserInfo?.subRole);
        setValue("emailConfirmation", getCurrentUserInfo?.emailConfirmation);
        setValue("telephoneNumber", getCurrentUserInfo?.telephoneNumber);
      }
    } catch (error) {
      console.log("========== Error Set Value User Data ==========");
      console.log(error);
      console.log("========== Error Set Value User Data Finish ==========");
    }
  }, [storingInfo, getCurrentUserInfo]);

  useEffect(() => {
    dispatch(setNewUnits({ units: unitCorporativeSelected }));
  }, [unitCorporativeSelected]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        const response = await getUserPermissionsBySubRole(subTypeSelected);
        if (
          response.data.success &&
          response.status === CODES.COD_RESPONSE_HTTP_OK
        ) {
          const { permissions } = response.data.responseMessage.data;
          handleSaveChanges();
          dispatch(setPermissions({ permissions: permissions }));
        }
      } catch (error) {
        console.log("========= Error fetch Permissions By Subrole =========");
        console.log(error);
        console.log(
          "========= Finish Error fetch Permissions By Subrole ========="
        );
      } finally {
        setIsLoading(false);
      }
    };
    if (subTypeSelected !== "") {
      if (subTypeSelected !== getCurrentUserInfo?.subRole) {
        fetch();
      }
    }
  }, [subTypeSelected]);

  useEffect(() => {
    if (companyPlan) {
      setCreateUserDisabled(false);
      if (getDisabledCreation()) {
        setCreateUserDisabled(true);
      }
    }
  }, [companyPlan, typeSelected]);

  const getDisabledCreation = () => {
    if (typeSelected === "EXTERNAL" && !companyPlan.isUserExternalEnable) {
      return true;
    } else if (typeSelected === "SERVICE" && !companyPlan.isUserServiceEnable) {
      return true;
    } else if (typeSelected === "ADMIN" && !companyPlan.isUserAdminEnable) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Creación de usuario"} />
      </Row>
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleCreateUser)}>
          <Row className="text-description">
            Asigna un tipo de usuario y rol para brindar permisos específicos a
            tu usuario:
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={3}>
              <Form.Select
                disabled={isLoading}
                className="label mb-4"
                as="select"
                value={typeSelected}
                placeholder="Tipo de usuario"
                bsPrefix={
                  errors.role
                    ? "input-group-container__no-icon-error label text-fields"
                    : "input-group-container__no-icon label text-fields"
                }
                {...register("role")}
                onChange={(e) => {
                  setTypeSelected(e.target.value);
                  setSubTypeSelected("");
                  setValue("role", e.target.value);
                  setValue("subRole", "");
                }}
              >
                <option className="label" value="" disabled>
                  Tipo de usuario
                </option>
                {typeOfUser.map((type, idx) => (
                  <option key={type.id} className="label" value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.role?.message}
              </div>
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Select
                className="label mb-4"
                as="select"
                value={subTypeSelected}
                placeholder="Tipo de usuario"
                bsPrefix={
                  errors.subrole
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("subrole")}
                onChange={(e) => {
                  setValue("subrole", e.target.value);
                  setSubTypeSelected(e.target.value);
                }}
                disabled={getDisabledCreation()}
              >
                <option className="label" value="" disabled>
                  Rol del usuario
                </option>
                {typeSelected &&
                  arrayUserRoles[typeSelected].map((role) => (
                    <option key={role.id} className="label" value={role.value}>
                      {role.name}
                    </option>
                  ))}
              </Form.Select>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.subrole?.message}
              </div>
            </Form.Group>

            <Col xs={12}>
              {createUserDisabled && typeSelected !== "" && (
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  Has alcanzado el máximo de{" "}
                  {typeOfUser
                    .find(({ value }) => value === typeSelected)
                    ?.name?.toLowerCase()}
                </div>
              )}
            </Col>
          </Row>
          <hr style={{ width: "50%" }} />
          <Row className="text-description">
            Agrega unidades corporativas a tu usuario de ser necesario:
          </Row>
          <br />
          <Row style={{ alignItems: "center", marginBottom: "2%" }}>
            <Col
              xs={"auto"}
              onClick={() => {
                handleSaveChanges();
              }}
            >
              <ModalListSelectCheckBox
                icon={<AddIcon />}
                list={listUnitsCorporatives}
                name="Agregar unidades corporativas"
                title={"Añadir unidades corporativas al usuario"}
                itemsSelected={unitCorporativeSelected}
                setItemsSelected={setUnitCorporativeSelected}
              />
            </Col>
            {unitCorporativeSelected.map((item, index) => {
              return (
                <Col xs={"auto"} key={uuidv4()}>
                  <span className="caption custom-badges__disabled">
                    {item?.name + " "}{" "}
                    <ClearIcon
                      className="custom-badges__delete-button"
                      onClick={() => handleDeleteUnitCorporative(item)}
                    />
                  </span>
                </Col>
              );
            })}
          </Row>
          <hr style={{ width: "50%" }} />
          <br />
          <Row className="text-description">
            Diligencia los datos personales del usuario:
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Select
                className="label mb-1"
                defaultValue=""
                bsPrefix={
                  errors.documentType
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("documentType")}
                onChange={(e) => {
                  setValue("documentType", e.target.value);
                  handleSetDocument(e);
                }}
              >
                {/* INE y CURP */}
                <option className="label" value="" disabled>
                  Tipo de documento
                </option>
                <option className="label" value="CEDULA DE CIUDADANIA">
                  Cédula de ciudadanía - COL
                </option>
                <option className="label" value="TARJETA DE IDENTIDAD">
                  Tarjeta de identidad - COL
                </option>
                <option className="label" value="CEDULA DE EXTRANJERIA">
                  Cédula de extranjería - COL
                </option>
                <option className="label" value="INE">
                  INE - MEX
                </option>
                <option className="label" value="CURP">
                  CURP - MEX
                </option>
                <option className="label" value="PASAPORTE">
                  Pasaporte
                </option>
                <option className="label" value="OTRO">
                  Otros
                </option>
              </Form.Select>
              <p className="caption custom-input__error">
                {errors.documentType?.message}
              </p>
            </Form.Group>
            {documentType && (
              <Form.Group as={Col} md={4}>
                <Form.Control
                  {...register("documentType2")}
                  placeholder={"Ingrese tipo de documento"}
                  bsPrefix={
                    errors.documentType2
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div className="caption custom-input__error">
                  {errors.documentType2?.message}
                </div>
              </Form.Group>
            )}
            <Form.Group as={Col} md={4}>
              <Form.Control
                {...register("documentNumber")}
                placeholder={"Número de documento"}
                bsPrefix={
                  errors.documentNumber
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.documentNumber?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Control
                {...register("firstName")}
                placeholder={"Primer nombre"}
                bsPrefix={
                  errors.firstName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.firstName?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Control
                {...register("secondName")}
                placeholder={"Segundo nombre"}
                bsPrefix={
                  errors.secondName
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.secondName?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Control
                {...register("firstSurname")}
                placeholder={"Primer apellido"}
                bsPrefix={
                  errors.firstSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.firstSurname?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Control
                {...register("secondSurname")}
                placeholder={"Segundo apellido"}
                bsPrefix={
                  errors.secondSurname
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.secondSurname?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <InputGroup
                bsPrefix={
                  errors.email
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("email")}
                  bsPrefix="custom-input"
                  placeholder="Correo electrónico"
                />
              </InputGroup>
              <div className="caption custom-input__error">
                {errors.email?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <InputGroup
                bsPrefix={
                  errors.emailConfirmation
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <AlternateEmailIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("emailConfirmation")}
                  bsPrefix="custom-input"
                  placeholder="Confirmar correo electrónico"
                  onPaste={(e) => e.preventDefault()}
                />
              </InputGroup>
              <div className="caption custom-input__error">
                {errors.emailConfirmation?.message}
              </div>
            </Form.Group>
          </Row>
          <br />
          <Row>
            <Form.Group as={Col} md={4}>
              <Select
                defaultValue={"57"}
                className={
                  errors.countryCode
                    ? "input-group-container__no-icon__country-list__error label"
                    : "input-group-container__no-icon__country-list label"
                }
                {...register("countryCode")}
                onChange={(e) => {
                  setActualCallSign(e.target.value);
                  setValue("countryCode", e.target.value);
                }}
              >
                <MenuItem
                  value={""}
                  className="input-group-container__no-icon__country-list label"
                  style={{ border: "none !important" }}
                >
                  Selecciona
                </MenuItem>
                {countries.map((c) => (
                  <MenuItem
                    key={c.code}
                    value={c.phone}
                    className="input-group-container__no-icon__country-list label"
                    style={{ border: "none !important" }}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                      alt={`Flag of ${c.label}`}
                    />
                    {` ${c.label} (${c.code}) +${c.phone}`}
                  </MenuItem>
                ))}
              </Select>
              <div className="caption custom-input__error">
                {errors.countryCode?.message}
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <InputGroup
                bsPrefix={
                  errors.phoneNumber
                    ? "input-group-container__error"
                    : "input-group-container"
                }
              >
                <InputGroup.Text bsPrefix="container-icon">
                  <PhoneAndroidIcon fontSize="large" />
                </InputGroup.Text>
                <Form.Control
                  {...register("phoneNumber")}
                  bsPrefix="custom-input__small"
                  placeholder={"Número de celular*"}
                />
              </InputGroup>
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.phoneNumber?.message}
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Col xs="auto">
              <span className="caption label">Teléfono fijo</span>
            </Col>
            <Col>
              <Form.Check
                checked={isApplyPhoneCheck}
                className="label"
                label="No aplica"
                onChange={(event) => {
                  setIsApplyPhoneCheck(event.target.checked);
                }}
              ></Form.Check>
            </Col>
          </Row>
          <br />
          {!isApplyPhoneCheck && (
            <Row>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    placeholder="Ind. país"
                    disabled
                    value={actualCallSign}
                    bsPrefix={"input-group-container__no-icon label"}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("cityCode")}
                    placeholder="Ind. ciudad"
                    bsPrefix={
                      errors.cityCode
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.cityCode?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("extension")}
                    placeholder="Ext"
                    bsPrefix={
                      errors.extension
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.extension?.message}
                  </div>
                </Form.Group>
              </Col>
              <Form.Group as={Col} md={4}>
                <InputGroup
                  bsPrefix={
                    errors.telephoneNumber
                      ? "input-group-container__error"
                      : "input-group-container"
                  }
                >
                  <InputGroup.Text bsPrefix="container-icon">
                    <LocalPhoneRoundedIcon fontSize="large" />
                  </InputGroup.Text>
                  <Form.Control
                    {...register("telephoneNumber")}
                    bsPrefix="custom-input__small"
                    placeholder={"Número de teléfono fijo"}
                  />
                </InputGroup>
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.telephoneNumber?.message}
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Col xs={"auto"}>
              <Button
                startIcon={<ManageAccountsIcon />}
                className={"custom-input__button__secondary-color"}
                onClick={() => {
                  if (subTypeSelected !== "") {
                    navigate("servicePermissions");
                    handleSaveChanges();
                  }
                }}
              >
                Gestionar permisos
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              {createUserDisabled ? (
                <CustomToolTip
                  direction="left"
                  message="Has alcanzado el máximo de usuarios permitidos en tu plan"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="custom-input__button__primary-color"
                    disabled
                  >
                    Guardar
                  </Button>
                </CustomToolTip>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="custom-input__button__primary-color"
                >
                  Guardar
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Creación de usuario"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
      <ModalDecision
        title={"Cancelar creación de usuario"}
        message={"¿Está seguro?"}
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Descartar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
      <ModalInfo
        title={modalInfoTitle}
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <HelpButton />
    </Container>
  );
};

export default CreateUser;
