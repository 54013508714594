import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ReactHtmlParser from "react-html-parser";
import { removeHtmlTags } from "../../utils/partsCorrection";

const defaultColors = {
  ia: {
    colorIcons: "#17ccbd",
    classNameText: "custom-input__button__withOutBackground_ia",
  },
  dark: {
    colorIcons: (theme) => theme.palette.grey[500],
    classNameText: "custom-input__button__withOutBackground",
  },
};

const defaultProps = {
  type: "default",
  icon: () => <></>,
  classNameButton: "custom-input__button__default-color",
  containerRef: "",
  setIsOpenExternal: () => {},
  items: [],
  textIcon: "",
  id: "",
};

const PopoverArrayCards = (props) => {
  const mergedProps = { ...defaultProps, ...props };

  const {
    type,
    icon,
    classNameButton,
    containerRef,
    setIsOpenExternal,
    items,
    textIcon,
    id,
  } = mergedProps;

  const [show, setShow] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [defaultColor, setDefaultColor] = useState("dark");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (items?.length) {
      const isLastItem = items.length === 1;
      setItemSelected({ ...items[0], index: 0, isLastItem, isFirstItem: true });
      setDefaultColor(items[0]?.color || "dark");
    }
  }, [items]);

  const handleChangeItem = ({ index, items }) => {
    setCopied(false);
    const currentItem = items?.[index];
    const isLastItem = index === items.length - 1;
    const isFirstItem = index === 0;
    if (currentItem) {
      setItemSelected({ ...currentItem, index, isLastItem, isFirstItem });
      setDefaultColor(currentItem?.color || "dark");
    }
  };

  const handleCopyText = ({ text = "" }) => {
    navigator.clipboard
      .writeText(removeHtmlTags(text))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Error al copiar al portapapeles:", err));
  };

  const handleClose = () => {
    setShow(false);
    if (setIsOpenExternal) {
      setIsOpenExternal(false);
    }
  };

  const popover = (
    <Popover className={`custom-popover__${type}`} id={id}>
      <Popover.Body className="custom-popover body-content__center">
        <div className="custom-popover-content-button">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Row className="align-items-center">
          <Col xs={3}>
            <img
              style={{ width: "100%" }}
              src={require("../../assets/svg/iaAdvices.svg").default}
              alt="logo-ia"
            />
          </Col>
          <Col xs={9}>
            {itemSelected && (
              <>
                {itemSelected?.title && (
                  <Row className={defaultColors[defaultColor].classNameText}>
                    <Col className="text-align-justify">
                      {itemSelected.title}
                    </Col>
                  </Row>
                )}
                <Row
                  className="custom-popover__body"
                  style={{ textAlign: "justify" }}
                >
                  <Col>
                    {ReactHtmlParser(itemSelected?.description || "")}{" "}
                    {itemSelected.textToCopy && (
                      <IconButton
                        title={copied ? "¡Copiado!" : "Copiar texto"}
                        aria-label="close"
                        onClick={() =>
                          handleCopyText({ text: itemSelected.textToCopy })
                        }
                        sx={{
                          color: defaultColors[defaultColor].colorIcons,
                          padding: 0,
                        }}
                      >
                        {copied ? (
                          <AssignmentTurnedInOutlinedIcon />
                        ) : (
                          <ContentCopyOutlinedIcon />
                        )}
                      </IconButton>
                    )}
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                  {itemSelected?.textAditional && (
                    <Col
                      key={uuidv4()}
                      xs={"auto"}
                      className={`${defaultColors[defaultColor].classNameText} font-style-italic`}
                    >
                      {itemSelected?.textAditional}
                    </Col>
                  )}
                  {!itemSelected.isFirstItem && (
                    <Col key={uuidv4()} xs={"auto"}>
                      <IconButton
                        title={"Anterior"}
                        aria-label="back"
                        onClick={() =>
                          handleChangeItem({
                            index: itemSelected.index - 1,
                            items,
                          })
                        }
                        sx={{ color: defaultColors[defaultColor].colorIcons }}
                      >
                        <ArrowCircleLeftOutlinedIcon fontSize="large" />
                      </IconButton>
                    </Col>
                  )}
                  {items.length > 1 && (
                    <Col
                      xs={"auto"}
                      className={defaultColors[defaultColor].classNameText}
                    >
                      {itemSelected.index + 1}/{items.length}
                    </Col>
                  )}
                  {!itemSelected.isLastItem && (
                    <Col key={uuidv4()} xs={"auto"}>
                      <IconButton
                        title={"Siguiente"}
                        aria-label="next"
                        onClick={() =>
                          handleChangeItem({
                            index: itemSelected.index + 1,
                            items,
                          })
                        }
                        sx={{ color: defaultColors[defaultColor].colorIcons }}
                      >
                        <ArrowCircleRightOutlinedIcon fontSize="large" />
                      </IconButton>
                    </Col>
                  )}
                  {itemSelected?.actions.length > 0 &&
                    itemSelected?.actions?.map(
                      (item) =>
                        item.show && (
                          <Col
                            key={uuidv4()}
                            xs={"auto"}
                            className="align-self-end"
                          >
                            <Button
                              className={
                                defaultColors[defaultColor].classNameText
                              }
                              size="large"
                              onClick={item.handleAction}
                            >
                              {item.text}
                            </Button>
                          </Col>
                        )
                    )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  const handleClosePopOver = () => {
    setShow((show) => !show);
    if (setIsOpenExternal) {
      setIsOpenExternal((show) => !show);
    }
  };

  return (
    <OverlayTrigger
      show={show}
      placement="auto"
      overlay={popover}
      rootClose
      trigger="click"
      container={containerRef?.current}
    >
      <IconButton
        aria-label="icon"
        className={classNameButton}
        onClick={handleClosePopOver}
      >
        {icon}
        {textIcon || ""}
      </IconButton>
    </OverlayTrigger>
  );
};

export default PopoverArrayCards;
