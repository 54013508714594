import { Col, Container, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import { NUMBERINGWORDSO } from "../../../../../../../utils/numberings";
import { Button, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RichEditorText from "../../../../../../../parts/RichEditorText";
import { createRef, useEffect, useState } from "react";
import { changeNameParts } from "../utils";
import { plainTextTagsLinks } from "../../../../../../../utils/partsCorrection";
import { getBlankSpaceIndex } from "../../../../../../../utils/blankspaces";
import BlankSpaceFormModal from "../../../../../../../components/Blankspaces/BlankSpaceFormModal";
import { EditOutlined } from "@mui/icons-material";
import changeDefaultBlankSpaceName from "../../../../../../../utils/changeDefaultBlankSpaceName";

export default function ParagraphModule({
  control,
  getValues,
  watch,
  errors,
  clause,
  setClause,
  paragraphRichEditorTextRef,
}) {
  const [blankSpaceFormModalProps, setBlankSpaceFormModalProps] = useState({
    selectedBlankSpace: undefined,
    blankSpaces: [],
    open: false,
    onAgree: () => undefined,
    onDisagree: () => undefined,
    onClose: () => undefined,
  });

  const {
    fields: paragraphsFields,
    append: paragraphsAppend,
    remove: paragraphsRemove,
  } = useFieldArray({
    control,
    name: "paragraphs",
  });

  const handleParagraphBlankSpaces = (blankSpace, paragraphIdx) => {
    const blankSpacesParagraphs =
      clause?.paragraphs?.[paragraphIdx]?.blankspaces ||
      clause?.paragraphs?.[paragraphIdx]?.blankSpaces ||
      [];
    let blankSpaceId = getBlankSpaceIndex(blankSpacesParagraphs);
    let item = {
      id: blankSpaceId,
      name: `ESPACIO #${blankSpaceId}`,
      description: "",
    };

    setClause((clause) => ({
      ...clause,
      paragraphs: clause.paragraphs.map((paragraph, idx) => {
        if (idx === paragraphIdx) {
          const blankSpacesParagraphs =
            paragraph?.blankspaces || paragraph?.blankSpaces || [];
          return {
            ...paragraph,
            blankspaces: [...blankSpacesParagraphs, item],
          };
        }
        return paragraph;
      }),
    }));

    paragraphRichEditorTextRef.current[paragraphIdx].current.addBlankSpace(
      clause?.paragraphs?.[paragraphIdx]?.blankspaces ||
        clause?.paragraphs?.[paragraphIdx]?.blankSpaces ||
        []
    );
  };

  const handleRemoveParagraph = (index) => {
    setClause((clause) => ({
      ...clause,
      paragraphs: clause.paragraphs.filter(
        (__, idxParagraph) => idxParagraph !== index
      ),
    }));
    paragraphsRemove(index);
  };

  const paragraphRemoveBlankSpace = (index, paragraphIdx) => {
    setClause((clause) => ({
      ...clause,
      paragraphs: clause.paragraphs.map((paragraph, idx) => {
        if (idx === paragraphIdx) {
          const blankSpacesParagraphs =
            paragraph?.blankspaces || paragraph?.blankSpaces || [];
          return {
            ...paragraph,
            blankspaces: blankSpacesParagraphs.filter(
              (blankSpace) => blankSpace.id !== index
            ),
          };
        }
        return paragraph;
      }),
    }));
    paragraphRichEditorTextRef.current[paragraphIdx].current.removeBlankSpace(
      index
    );
  };

  const handleCreateParagraph = () => {
    let item = {
      title: "",
      text: "",
      blankspaces: [],
    };
    setClause((clause) => ({
      ...clause,
      paragraphs: [...clause.paragraphs, item],
    }));
    paragraphsAppend(item);
    const index = clause?.paragraphs?.length ?? 0;
    paragraphRichEditorTextRef.current[index] = createRef();
  };

  /**
   * Edits a blank space
   * @param {Object} editedBlankSpace The edited blank space
   * @param {number} editedBlankSpace.id The id of the blank space
   * @param {string} editedBlankSpace.name The name of the blank space
   * @param {string} editedBlankSpace.description The description of the blank space
   * @param {any} editedBlankSpace.value The value of the blank space
   * @param {number} paragraphIdx The index of the paragraph
   */
  const editBlankSpace = (editedBlankSpace, paragraphIdx) => {
    setClause((clause) => ({
      ...clause,
      paragraphs: clause.paragraphs.map((paragraph, idx) => {
        if (idx === paragraphIdx) {
          const blankSpacesParagraphs =
            paragraph?.blankspaces || paragraph?.blankSpaces || [];
          return {
            ...paragraph,
            blankspaces: blankSpacesParagraphs.map((blankSpace) => {
              if (blankSpace.id === editedBlankSpace.id) {
                return editedBlankSpace;
              }
              return blankSpace;
            }),
          };
        }
        return paragraph;
      }),
    }));

    paragraphRichEditorTextRef.current[paragraphIdx].current.editBlankSpace(
      editedBlankSpace.id,
      editedBlankSpace
    );
  };

  const handleOpenBlankSpaceFormModal = ({ blankSpace, paragraphIdx }) => {
    const blankSpacesParagraphs =
      clause?.paragraphs?.[paragraphIdx]?.blankspaces ||
      clause?.paragraphs?.[paragraphIdx]?.blankSpaces ||
      [];
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: true,
      selectedBlankSpace: blankSpace,
      blankSpaces: blankSpacesParagraphs,
      onAgree: (blankSpace) => {
        editBlankSpace(blankSpace, paragraphIdx);
        handleCloseBlankSpaceFormModal();
      },
      onDisagree: handleCloseBlankSpaceFormModal,
      onClose: handleCloseBlankSpaceFormModal,
    });
  };

  const handleCloseBlankSpaceFormModal = () => {
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: false,
    });
  };

  useEffect(() => {
    clause.paragraphs.forEach((paragraph, idx) => {
      if (
        paragraph.isImported &&
        paragraphRichEditorTextRef.current[idx].current
      ) {
        const parts = getValues("parts").map((part) => part.title);
        const text = changeNameParts(paragraph.text, parts);
        paragraphRichEditorTextRef.current[idx].current?.setEditorContents(
          plainTextTagsLinks(text)
        );
        setClause((clause) => ({
          ...clause,
          paragraphs: clause.paragraphs.map((paragraph, index) => {
            const blankSpacesParagraphs =
              paragraph?.blankspaces || paragraph?.blankSpaces || [];
            if (blankSpacesParagraphs?.length) {
              for (const blankSpace of blankSpacesParagraphs) {
                paragraphRichEditorTextRef?.current?.[
                  idx
                ]?.current.setBlankSpacesValues(blankSpace.id);
              }
            }
            if (index === idx) return { ...paragraph, text, isImported: false };
            return paragraph;
          }),
        }));
      }
    });
  }, [paragraphsFields]);

  return (
    <>
      {paragraphsFields.map((item, idx) => {
        const blankSpacesParagraphs =
          clause?.paragraphs?.[idx]?.blankspaces ||
          clause?.paragraphs?.[idx]?.blankSpaces ||
          [];
        return (
          <Row key={item?.id} className="mb-5" style={{ paddingLeft: "5%" }}>
            <Col className="flex-grow-1">
              <Row className="mb-4">
                <Col>
                  <u className="heading__primary-color">{`Parágrafo ${
                    NUMBERINGWORDSO[idx + 1]
                  }`}</u>
                </Col>
                <Col xs={"auto"}>
                  <Row>
                    {getValues("parts").map((item, idxParts) => {
                      if (watch(`parts.${idxParts}.title`)) {
                        return (
                          <Col
                            className="create-clause-coachmark-step-3"
                            xs={"auto"}
                            key={item?.title}
                          >
                            <Button
                              onClick={() =>
                                paragraphRichEditorTextRef.current[
                                  idx
                                ].current.addPart(item.title, idxParts)
                              }
                              variant="contained"
                              className="custom-input__button__secondary-color"
                            >
                              {item.title}
                            </Button>
                          </Col>
                        );
                      } else {
                        return null;
                      }
                    })}

                    <Col className="create-clause-coachmark-step-4" xs={"auto"}>
                      <Button
                        variant="contained"
                        startIcon={<AddIcon fontSize="large" />}
                        className="custom-input__button__secondary-color"
                        onClick={() => {
                          const blankSpacesParagraphs =
                            clause?.paragraphs[idx]?.blankspaces ||
                            clause?.paragraphs[idx]?.blankSpaces ||
                            [];
                          const index = blankSpacesParagraphs.length ?? 0;
                          handleParagraphBlankSpaces(index + 1, idx);
                        }}
                      >
                        Espacio en blanco
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    onClick={() => handleRemoveParagraph(idx)}
                  >
                    <DeleteOutlineIcon fontSize="large" />
                  </Button>
                </Col>
              </Row>
              <Row style={{ maxWidth: "120rem" }}>
                <Col xs={12}>
                  <Controller
                    control={control}
                    name={`paragraphs.${idx}.text`}
                    render={({ field: { onChange, onBlur } }) => {
                      return (
                        <RichEditorText
                          ref={paragraphRichEditorTextRef.current[idx]}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          customClassName={
                            errors?.paragraphs?.[idx]?.text?.message
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          deleteBlankSpace={(id) => {
                            paragraphRemoveBlankSpace(id, idx);
                          }}
                        />
                      );
                    }}
                  />
                  <div className="caption custom-input__error">
                    {errors?.paragraphs?.[idx]?.text?.message || ""}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={"2"} className="flex-shrink-0">
              <Container>
                <Row style={{ marginTop: "5%" }}>
                  <p className="label">{blankSpacesParagraphs?.length ?? 0} Espacios en blanco:</p>
                </Row>
                {blankSpacesParagraphs?.length > 0 &&
                  blankSpacesParagraphs.map((blankSpace) => {
                    return (
                      <Row xs={"auto"} key={blankSpace?.id}>
                        <Col
                          xs={"auto"}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <p className="heading__primary-color text-break">
                            {changeDefaultBlankSpaceName(blankSpace.name)}
                          </p>
                        </Col>
                        <Col xs={"auto"}>
                          <Stack direction="row">
                            <IconButton
                              onClick={() =>
                                paragraphRemoveBlankSpace(blankSpace.id, idx)
                              }
                            >
                              <DeleteOutlineIcon
                                className="heading__primary-color"
                                fontSize="large"
                              />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleOpenBlankSpaceFormModal({
                                  blankSpace,
                                  paragraphIdx: idx,
                                })
                              }
                            >
                              <EditOutlined
                                className="heading__primary-color"
                                fontSize="large"
                              />
                            </IconButton>
                          </Stack>
                        </Col>
                      </Row>
                    );
                  })}
              </Container>
            </Col>
          </Row>
        );
      })}
      <Row xs={"auto"} style={{ justifyContent: "right" }}>
        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="large" />}
          className="custom-input__button__secondary-color"
          onClick={() => handleCreateParagraph()}
        >
          Parágrafo
        </Button>
      </Row>
      <BlankSpaceFormModal {...blankSpaceFormModalProps} />
    </>
  );
}
