import React from "react";
import { Container, Form } from "react-bootstrap";
import BaseTypeInput from "./BaseTypeInput";
import PropTypes from "prop-types";

// BEGINNING OF TYPE DEFINITIONS
/**
 * The type of the question.
 *
 * @typedef {Object} QuestionType
 * @property {string} _id The id of the question.
 * @property {string} value The value of the question.
 * @property {{ required: { value: boolean } }} validations The validations of the question.
 */

// END OF TYPE DEFINITIONS

/**
 * The short question input component.
 * 
 * @param {Object} props The props of the component.
 * @param {QuestionType} props.question The question to display.
 * @param {(question: QuestionType) => void} props.onDataChange The function to call when the data changes.
 * @param {() => void} props.onDelete The function to call when the question is deleted.
 * @returns The JSX element to render.
 */
export const TypeInputDate = ({
  question,
  onDataChange,
  onDelete,
}) => {

  return (
    <BaseTypeInput
      question={question}
      onDelete={onDelete}
      onDataChange={onDataChange}
    >
      <Container>
        <Form.Control
          size="lg"
          type="date"
          placeholder="Fecha"
          min={new Date().toISOString().split("T")[0]}
          style={{
            textAlign: "justify",
          }}
          disabled
        />
      </Container>
    </BaseTypeInput>
  );
};

TypeInputDate.propTypes = {
  question: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    validations: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};