export const saveFileToIndexedDB = async (file, fileId) => {
  const dbOpenRequest = indexedDB.open("legalDB", 1);

  dbOpenRequest.onupgradeneeded = (event) => {
    const db = event.target.result;
    if (!db.objectStoreNames.contains("files")) {
      db.createObjectStore("files", { keyPath: "id" });
    }
  };

  dbOpenRequest.onsuccess = (event) => {
    const db = event.target.result;
    const transaction = db.transaction("files", "readwrite");
    const store = transaction.objectStore("files");
    store.put({ id: fileId, file });
  };

  dbOpenRequest.onerror = (event) => {
    console.error("IndexedDB error:", event.target.error);
  };
};
export const getFileFromIndexedDB = async (fileId) => {
  return new Promise((resolve, reject) => {
    const dbOpenRequest = indexedDB.open("legalDB");

    dbOpenRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction("files", "readonly");
      const store = transaction.objectStore("files");
      const fileRequest = store.get(fileId);

      fileRequest.onsuccess = () => {
        resolve(fileRequest.result.file);
      };

      fileRequest.onerror = (event) => {
        reject("File retrieval error:", event.target.error);
      };
    };

    dbOpenRequest.onerror = (event) => {
      reject("IndexedDB error:", event.target.error);
    };
  });
};

export const deleteFileFromIndexedDB = async (fileId) => {
  try {
    const dbOpenRequest = indexedDB.open("legalDB");

    if (fileId) {
      dbOpenRequest.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        store.delete(fileId);
      };
    }

    dbOpenRequest.onerror = (event) => {
      console.error("IndexedDB error:", event.target.error);
    };
  } catch (error) {
    console.log(error);
  }
};
