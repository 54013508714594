/**
 * The modes of the ManageCustomForm component.
 *
 * @readonly
 * @enum {string}
 */
const ManageCustomFormModes = Object.freeze({
  CREATE: "create",
  EDIT: "edit",
});

export default ManageCustomFormModes;