import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Checkbox } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomSearchFilter from "../../../../../../components/Search/CustomSearchFilter";
import LoadingContent from "../../../../../../components/loadingContent";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import FilterTag from "../../../../../../parts/filters/FilterTag";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  fetchTagsDocumentTempletes,
  getTagDocumentTempleteStatus,
  getTagDocumentTempletesList,
} from "../../../../../../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { handlePreviewTemplate } from "./utils";
import {
  fetchPrederminatedTexts,
  getPrederminatedTextsList,
  getStatusPrederminatedTexts,
} from "../../library/pretederminatedTexts/prederminatedTextsSlice";

import { resetOtherDocumentInfo } from "../../../../../../parts/document/otherDocumentSlice";
import { setHeader } from "../../../../../../parts/document/currentDocumentSlice";
import { renameRepeatedBlankSpaces, unifyObjectsWithBlankSpaces } from "../../../../../../utils/blankspaces";

const SelectPredeterminedTextOtherDocument = () => {
  //Router
  const navigate = useNavigate();
  const { type } = useParams();
  //Redux

  const dispatch = useDispatch();

  const listPredeterminatedTexts = useSelector(getPrederminatedTextsList);
  const statusPredeterminatedTexts = useSelector(getStatusPrederminatedTexts);
  const tagTempleteList = useSelector(getTagDocumentTempletesList);
  const statusTagsDocumentTempletes = useSelector(getTagDocumentTempleteStatus);

  //States
  const [dataPredeterminedText, setDataPredeterminedText] = useState([]);
  const [filterDataPredeterminedText, setFilterDataPredeterminedText] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [tagTemplete, setTagTemplete] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [selectedTextList, setSelectedTextList] = useState([]);
  //Use effects

  useEffect(() => {
    const fetchPredeterminatedTextsData = async () => {
      try {
        if (statusPredeterminatedTexts === "fetch") {
          dispatch(fetchPrederminatedTexts());
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPredeterminatedTextsData();
  }, [statusPredeterminatedTexts, dispatch]);

  useEffect(() => {
    setDataPredeterminedText(listPredeterminatedTexts);
    setFilterDataPredeterminedText(listPredeterminatedTexts);
  }, [listPredeterminatedTexts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTagsDocumentTempletes === "fetch") {
          dispatch(fetchTagsDocumentTempletes());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTagsDocumentTempletes, dispatch]);

  useEffect(() => {
    setTagTemplete(tagTempleteList);
  }, [tagTempleteList]);

  useEffect(() => {
    dispatch(resetOtherDocumentInfo());
  }, []);
  // Functions

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
    const newTags = tagsSelected.filter((t) => t !== tag);
    if (newTags.length > 0) {
      const filterTags = dataPredeterminedText.filter((item) => {
        return item.labels.some((tag) => newTags.includes(tag.title));
      });

      setFilterDataPredeterminedText(filterTags);
    } else {
      setFilterDataPredeterminedText(dataPredeterminedText);
    }
  };

  const handleChecked = (e, template) => {
    const item = e.target;
    if (item.checked) {
      setSelectedTextList([...selectedTextList, template]);
    } else {
      setSelectedTextList(
        selectedTextList.filter((t) => t._id !== template._id)
      );
    }
  };

  const handleCancel = () => {
    navigate(-1);
    setSelectedTextList([]);
  };

  const handleTransformFinalText = (selectedTextList) => {
    let concatenatedText = "";
    let currentBlankSpacesLength = 0;
    const blankSpaces = [];

    selectedTextList.forEach((predeterminedText) => {
      const blankSpacesPropertyNames = ["blankSpaces", "blankspaces"];
      /** @type {Array<{name: string, description?: string}>} */
      const predeterminedTextBlankSpaces = [];
      /** @type {string} */
      let predeterminedTextText = predeterminedText.text;

      for (const propertyName of blankSpacesPropertyNames) {
        if (predeterminedText[propertyName]) {
          predeterminedTextBlankSpaces.push(...predeterminedText[propertyName]);
          break;
        }
      }

      for (const [
        blankSpaceIndex,
        blankSpace,
      ] of predeterminedTextBlankSpaces.entries()) {
        const newBlankSpace = {
          ...blankSpace,
          id: currentBlankSpacesLength + 1,
        };

        predeterminedTextBlankSpaces[blankSpaceIndex] = newBlankSpace;
        currentBlankSpacesLength++;
        predeterminedTextText = predeterminedTextText.replace(
          blankSpace.name,
          newBlankSpace.name
        );
        predeterminedTextText = predeterminedTextText.replace(
          `blankSpace-${blankSpaceIndex + 1}`,
          `blankSpace-${newBlankSpace.id}`
        );
      }

      blankSpaces.push(...predeterminedTextBlankSpaces);
      concatenatedText += `${predeterminedTextText} </br>`;
    });

    const renameResult = renameRepeatedBlankSpaces(blankSpaces, concatenatedText);

    return {
      name: selectedTextList[0].name,
      text: renameResult.text,
      blankspaces: renameResult.blankSpaces,
    };
  };

  const transformSelectedTextList = (textList) => {
    const finalTextTransformed = unifyObjectsWithBlankSpaces(textList);

    return finalTextTransformed;
  };

  const handleImportBlockToDoc = () => {
    if (type === "to-doc") {
      selectedTextList.forEach((text) => {
        const headerObj = {
          title: text.name,
          text: text.text,
          childs: [],
        };
        dispatch(setHeader(headerObj));
      });
      navigate(-1);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Biblioteca de bloques"} />
      </Row>
      <br />
      <Row>
        <p className="caption">
          Busca y selecciona los bloques que quieres utilizar para tu nuevo
          documento.
        </p>
      </Row>
      <br />

      <br />
      <br />
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} lg={5}>
          <CustomSearchFilter
            placeholder="Buscar bloque"
            list={dataPredeterminedText}
            setFilteredData={setFilterDataPredeterminedText}
          />
        </Col>
        <Col />

        <Col xs={"auto"}>
          <FilterTag
            tagsList={tagTemplete}
            list={dataPredeterminedText}
            setFilteredInfo={setFilterDataPredeterminedText}
            handleTagsSelected={handleTagsSelected}
            currentTagSelected={tagsSelected}
          />
        </Col>
      </Row>
      <br />
      <Row style={{ justifyContent: "end" }}>
        {tagsSelected.map((tag) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__disabled">
                {tag + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeleteTag(tag)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <br />
      <Row sm={"auto"} style={{ justifyContent: "right" }}></Row>
      <br />
      <Row>
        <div
          className="custom-accordion__container custom-container-scroll "
          style={{ paddingBottom: "6rem" }}
        >
          {filterDataPredeterminedText.length > 0 &&
            filterDataPredeterminedText.map((predeterminedText) => {
              return (
                <Accordion
                  className="custom-accordion"
                  key={predeterminedText._id}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Row
                      style={{ width: "100%" }}
                      className="align-items-center"
                    >
                      <Col xs={"auto"}>
                        <Checkbox
                          checked={selectedTextList.some(
                            (t) => t._id === predeterminedText._id
                          )}
                          onClick={(e) => handleChecked(e, predeterminedText)}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 18 },
                            color: "#00374f",
                            "&.Mui-checked": {
                              color: "#00374f",
                            },
                          }}
                        />
                      </Col>
                      <Col>
                        <p className="heading__primary-color">
                          {predeterminedText?.name || ""}
                        </p>
                      </Col>
                      <Col md={"auto"}>
                        <p className="subheading">
                          Etiquetas:{" "}
                          {predeterminedText.labels.map((tag) => {
                            return (
                              <span
                                key={tag?._id}
                                className="caption custom-badges__disabled ml-3"
                              >
                                {tag?.name}
                              </span>
                            );
                          })}
                        </p>
                      </Col>
                    </Row>
                  </AccordionSummary>
                  <AccordionDetails>
                    <br />
                    <Row>
                      <span className="subheading">
                        Etiquetas:{" "}
                        {predeterminedText?.labels?.length > 0 &&
                          predeterminedText.labels.map((tag) => {
                            return (
                              <span
                                key={tag?._id}
                                className="caption custom-badges__disabled  ml-3"
                              >
                                {tag?.name}
                              </span>
                            );
                          })}
                      </span>
                    </Row>
                    <br />
                    <br />
                    <Row className="mb-5">
                      <p
                        className="body-content"
                        style={{ textAlign: "justify" }}
                      >
                        {ReactHtmlParser(predeterminedText?.text)}
                      </p>
                    </Row>

                    <Row className="justify-content-end">
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          startIcon={<VisibilityOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          onClick={async () => {
                            try {
                              setIsLoading(true);
                              await handlePreviewTemplate({...predeterminedText, name: undefined });
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setIsLoading(false);
                            }
                          }}
                        >
                          <p className="button">Previsualizar</p>
                        </Button>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={"auto"}>
          <Button
            type="submit"
            variant="contained"
            className="custom-input__button__secondary-color"
            startIcon={<ClearIcon fontSize="large" />}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckCircleOutlineIcon fontSize="large" />}
            onClick={() => {
              if (!type) {
                navigate("./../create", {
                  state: transformSelectedTextList(selectedTextList),
                });
              } else {
                handleImportBlockToDoc();
              }
            }}
            disabled={!selectedTextList.length}
          >
            Confirmar selección
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectPredeterminedTextOtherDocument;
