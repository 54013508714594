import * as yup from "yup";

export const TypeInputFormSchemaPropertiesName = Object.freeze({
  title: "title",
  isRequired: "isRequired",
});

const TypeInputFormSchema = yup.object().shape({
  [TypeInputFormSchemaPropertiesName.title]: yup
    .string()
    .default("Pregunta")
    .required("*Este campo es obligatorio"),
  [TypeInputFormSchemaPropertiesName.isRequired]: yup
    .boolean()
    .default(true)
    .required("*Este campo es obligatorio"),
});

export default TypeInputFormSchema;
