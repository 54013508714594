import axios from "axios";
import { env } from "../../env";
import GetToken from "../../utils/getToken";

export const createNewVersion = ({
  name,
  fileType,
  documentContent,
  contractAssociated,
  signContractAssociated,
  folderAssociated,
  file,
  versionFrom,
  corporateUnit = "",
  inReview = false,
  letterheadAssociated,
  reviewCounts,
}) => {
  const data = {
    name,
    fileType,
    documentContent,
    contractAssociated,
    signContractAssociated,
    folderAssociated,
    versionFrom,
    corporateUnit,
    inReview,
    letterheadAssociated,
    reviewCounts,
  };
  const formData = new FormData();
  formData.append("data", JSON.stringify(data));
  file && formData.append("file", file);
  return axios({
    method: "POST",
    url: `versions/createVersion`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      versionFrom,
      fileType,
    },
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
export const addVersion = ({
  _id,
  name,
  fileType,
  documentContent,
  file,
  inReview = false,
  versionFrom,
  letterheadAssociated,
}) => {
  const data = documentContent
    ? {
        _id,
        name,
        fileType,
        documentContent,
        versionAssociated: documentContent.versionAssociated,
        inReview,
        versionFrom,
        letterheadAssociated,
      }
    : {
        _id,
        name,
        fileType,
        inReview,
      };
  const formData = new FormData();
  formData.append("data", JSON.stringify(data));
  formData.append("file", file);

  return axios({
    method: "PUT",
    url: `versions/modifyVersion`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};

export const getDocumentDeletedList = ({ corporateUnit, companyId }) => {
  return axios({
    method: "GET",
    url: `versions/getDocumentsDeleted`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      corporateUnit,
      company: companyId,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};
//Esta enfocado a cambiar estado a eliminar
export const updateStateDocument = ({ id, newStatus, fileType }) => {
  return axios({
    method: "PUT",
    url: `versions/updateStatusVersion/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      status: newStatus,
      fileType,
    },
  });
};

export const updateStateGeneralDocument = ({ id, newStatus }) => {
  return axios({
    method: "PUT",
    url: `versions/updateGeneralStatusVersion`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      versionId: id,
      status: newStatus,
    },
  });
};

export const milestonesReport = ({ versionId }) => {
  return axios({
    method: "GET",
    url: `versions/milestonesReport`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { versionId },
  });
};

export const valuesReport = ({ versionId }) => {
  return axios({
    method: "GET",
    url: `versions/valuesReport`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { versionId },
  });
};

export const milestonesReportFromClauses = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `versions/milestonesReportFromClauses`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { clauses },
  });
};

export const valuesReportFromClauses = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `versions/valuesReportFromClauses`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { clauses },
  });
};

export const partsReport = ({ filenameInBucket, bucketName }) => {
  return axios({
    method: "POST",
    url: `documents/getPartsClassification`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      filenameInBucket,
      bucketName,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};
export const createSummaryContract = ({ filenameInBucket, bucketName }) => {
  return axios({
    method: "POST",
    url: `versions/createSummaryContract`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: { filenameInBucket, bucketName },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getClausesImportantsFromClauses = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `versions/getClausesImportantsFromClauses`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      clauses,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const policesReportFromClauses = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `versions/policesReportFromClauses`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { clauses },
  });
};

export const policesReport = ({ versionId }) => {
  return axios({
    method: "GET",
    url: `versions/policesReport`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { versionId },
  });
};

export const obligationsReportFromClauses = ({ clauses }) => {
  return axios({
    method: "POST",
    url: `versions/obligationsReportFromClauses`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { clauses },
  });
};

export const obligationsReport = ({ versionId }) => {
  return axios({
    method: "GET",
    url: `versions/obligationsReport`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { versionId },
  });
};

export const createNewVersionOtherDocument = ({
  name,
  fileType,
  documentContent,
  letterheadAssociated,
  folderAssociated,
  file,
  versionFrom,
  corporateUnit = "",
}) => {
  const data = {
    name,
    fileType,
    documentContent,
    letterheadAssociated,
    folderAssociated,
    versionFrom,
    corporateUnit,
  };
  const formData = new FormData();
  formData.append("data", JSON.stringify(data));
  file && formData.append("file", file);
  return axios({
    method: "POST",
    url: `versions/createOtherDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      versionFrom,
      fileType,
    },
    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
