import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
import {
  getFillBlankSpacesOtherDocs,
} from "../../../../../../utils/countBlankSpaces";
import ProgressBar from "../../../../../../components/Blankspaces/ProgressBar";
import {
  flashItemSelected,
  scrollToItem,
} from "../../../../../../utils/ScrollDocItems";
import {
  getDocumentInfo,
  setDocumentInfo,
} from "../../../../../../parts/document/otherDocumentSlice";
import { FillBlankSpaceOtherDocument } from "../../../../../../components/Blankspaces/FillBlankSpaceOtherDocument";

const FillBlankspacesOtherDocument = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const currentOtherDocument = useSelector(getDocumentInfo);

  //state
  const [currentDocInfo, setCurrentDocInfo] = useState({});
  const [countGeneralBlankspaces, setCountGeneralBlankspaces] = useState({});
  const [globalIndex, setGlobalIndex] = useState(0);

  const handleSubmitBlankspaces = () => {
    dispatch(setDocumentInfo(currentDocInfo));
    navigate(-1);
  };

  const handleCountGeneralFillBlankspaces = () => {
    if (currentDocInfo) {
      let count = 0;
      let total = 0;
      const fillBlankSpaces = getFillBlankSpacesOtherDocs(currentDocInfo);
      const allBlankSpaces = currentDocInfo?.blankSpaces?.length;
      count += fillBlankSpaces;
      total += allBlankSpaces;

      const percentageComplete = Math.trunc((count / total) * 100);
      setCountGeneralBlankspaces({
        percentage: percentageComplete,
        countBlank: count,
        totalBlank: total,
      });
    }
  };

  //UseEffect
  useEffect(() => {
    setCurrentDocInfo(currentOtherDocument);
  }, [currentOtherDocument]);

  useEffect(() => {
    handleCountGeneralFillBlankspaces();
  }, [currentDocInfo]);

  useEffect(() => {
    if (id) {
      const checkElementExists = () => {
        let idElement = `${Number(id) + 1}.0.0`;
        const element = document.getElementById(idElement);

        if (element) {
          flashItemSelected(idElement);
          scrollToItem(`c.${idElement}`);
        } else {
          setTimeout(checkElementExists, 100);
        }
      };

      checkElementExists();
    }
  }, []);

  return (
    <Container fluid className="pb-3">
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={12} className="col-start">
          <SectionTitle title={`Rellenar espacios en blanco`} />
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col xs={12}>
          <p className="caption">
            Diligencia la información a la izquierda y visualiza tu documento en
            la sección derecha
          </p>
        </Col>
      </Row>
      <div>
        <Row>
          <Col xs={4} className="blankspaces__container">
            {" "}
            {currentOtherDocument.blankSpaces.length && (
              <FillBlankSpaceOtherDocument
                setCurrentDocInfo={setCurrentDocInfo}
                globalIndex={globalIndex}
                setGlobalIndex={setGlobalIndex}
                currentDocInfo={currentDocInfo}
              />
            )}
          </Col>
          <Col
            xs={8}
            className="blankspaces__container"
            id={"content-document"}
          >
            <Container fluid>
              <Row
                className="display-large justify-content-center"
                style={{ margin: "1% 0" }}
              >
                {currentDocInfo?.name}
              </Row>

              <Row style={{ margin: "1% 0" }}>
                <Row
                  className="container_text__clause-text label"
                  style={{ padding: "0 0 0 5%" }}
                  id={`content-otherDocxs`}
                >
                  {ReactHtmlParser(currentDocInfo?.text)}
                </Row>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
      <Row className="justify-content-md-end" style={{ margin: "1% 0" }}>
        <Col xs={6}>
          <ProgressBar capacity={100} used={countGeneralBlankspaces} />
        </Col>
        <Col md={1}>
          <Button
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => {
              navigate(-1);
            }}
          >
            Descartar
          </Button>
        </Col>
        <Col md={1}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            onClick={handleSubmitBlankspaces}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FillBlankspacesOtherDocument;
