import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buffer } from "buffer";
import { env } from "../../../../../env";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { MovePageSafe } from "../../../../../components/blocker/blocker";
import LoadingContent from "../../../../../components/loadingContent";
import LoadingLinear from "../../../../../components/loadings/loadingLinear";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import PopoverInfo from "../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import { GetFileFromUrl } from "../../../../../services/files/fileServices";
import { saveSignaturesRequest } from "../../../../../services/general/requestServices";
import { saveSignatureSidePanel } from "../../../../../services/signatures/signaturesService";
import { CODES } from "../../../../../utils/codes";
import GetToken from "../../../../../utils/getToken";
import { REGEXP } from "../../../../../utils/regexp";
import { countries } from "../../../../../utils/countries";
import {
  deleteVersion,
  getFileDocument,
} from "../../../../../services/documents/documentsRequest";
import { resetSignaturesSidePanelStatus } from "../../../../../parts/signatures/signaturesSlice";
import { convertToPdfService } from "../../../../../services/utils/convertWordToPdf";
import { PARTS_LIBRARY_STATE } from "../library/parts/utils";
import { findSignatoriesByCompany } from "../../../../../services/parts/partsServices";

const SignatoryData = () => {
  const WORKER_URL = env.REACT_APP_PDF_WORKER_URL;
  const zoomPluginInstance = zoomPlugin();
  const companyId = localStorage.getItem("company");
  const signatureInfo = JSON.parse(sessionStorage.getItem("signatureInfo"));
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    attorney,
    description,
    isEmailWithSignedDocumentSend,
    folderSelected,
    docTypeToSign,
    originalDocument,
    consecutive,
    requestId,
    documentType,
    documentId,
    documentAssociated,
    signatureType,
    selectedSignatoriesFromLibrary, // Sended from selectSignatoryForContract.jsx
  } = state;
  const info = JSON.parse(localStorage.payloadToken);
  const {
    firstName = "",
    firstSurname = "",
    userId = "",
    companyName = "",
  } = info;
  const [isLoading, setIsLoading] = useState(false);
  const [signatoriesList, setSignatoriesList] = useState([]);
  const [show, setShow] = useState(false);
  const [path, setPath] = useState(null);
  const [responseData, setResponseData] = useState({});
  const [openModalInfoSign, setOpenModalInfoSign] = useState(false);
  const [isOpenModaltoCancel, setIsOpenModaltoCancel] = useState(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [isOpenModalCancelSign, setIsOpenModalCancelSign] = useState(false);
  const [infoToCancel, setInfoToCancel] = useState({});
  const [isBlockNavigate, setIsBlockNavigate] = useState(true);
  const [isDisableContinueButton, setIsDisableContinueButton] = useState(false);
  const [openModalNoSignatories, setOpenModalNoSignatories] = useState(false);
  const [openModalErrorSignature, setOpenModalErrorSignature] = useState(false);

  const schema = yup.object().shape({
    signatories: yup.array().of(
      yup.object().shape({
        firstName: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
        firstSurname: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
        documentNumber: yup
          .string()
          .notRequired()
          .test("onlyNumbers", "*Solo debe incluir números", function (value) {
            if (!!value) {
              const schema = yup.string().matches(REGEXP.ONLY_NUMBERS);
              return schema.isValidSync(value);
            }
            return true;
          })
          .test("min", "*Mínimo 7 números", function (value) {
            if (!!value) {
              const schema = yup.string().min(7, "");
              return schema.isValidSync(value);
            }
            return true;
          }),
        email: yup
          .string()
          .email("*Este campo debe ser un email válido")
          .required("*Este campo es requerido"),
        messageToSignatory: yup
          .string()
          .notRequired()
          .max(250, "*Máximo 250 caracteres"),
        countryCode: yup.string().notRequired(),
        phoneNumber: yup
          .string()
          .notRequired()
          .test("onlyNumbers", "*Solo debe incluir números", function (value) {
            if (!!value) {
              const schema = yup.string().matches(REGEXP.ONLY_NUMBERS);
              return schema.isValidSync(value);
            }
            return true;
          }),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      signatories: [{}],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "signatories",
  });

  /**
   * Handlers
   */

  const handleClosePreview = () => {
    setShow(false);
  };

  const handleZoom = (doc, scale) => {
    console.log(`Zoom document to ${scale}`);
  };

  const handlePreviewDoc = async () => {
    try {
      setShow(true);
      if (path) return;

      setIsLoadingDocument(true);
      let url = "";

      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      let newUrl = "";
      if (
        signatureType === "request" ||
        signatureInfo?.signatureType === "request"
      ) {
        if (documentId) {
          url = `${env.REACT_APP_API_URL}/thirdPartyRequest/get/document?corporateUnit=${corporateUnit}&companyId=${companyId}&consecutive=${consecutive}&documentId=${documentId}&documentType=${documentType}`;
        } else {
          url = `${env.REACT_APP_API_URL}/thirdPartyRequest/get/document?corporateUnit=${corporateUnit}&companyId=${companyId}&consecutive=${signatureInfo?.consecutive}&documentId=${signatureInfo?.documentId}&documentType=${signatureInfo?.documentType}`;
        }
        const blobFile = await GetFileFromUrl(url);
        const newUrl = window.URL.createObjectURL(blobFile.data);
        setPath(newUrl);
      } else if (
        signatureType === "lateralPanel" ||
        signatureInfo?.signatureType === "lateralPanel"
      ) {
        const filenameInBucket = originalDocument
          ? originalDocument?.filenameInBucket
          : signatureInfo?.originalDocument?.filenameInBucket;
        const bucketName = originalDocument
          ? originalDocument?.bucketName
          : signatureInfo?.originalDocument?.bucketName;
        const file = await getFileDocument(filenameInBucket, bucketName, false);
        let bf = {};

        if (
          file.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          let docView = file.data.responseMessage.buffer;

          const { data } = file.data.responseMessage.buffer || {};
          const { contentType } = file.data?.responseMessage || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);

          docView = pdfConverted?.data?.responseMessage?.data?.buffer;

          const finalFile = URL.createObjectURL(
            new Blob([new Uint8Array(docView.data).buffer], {
              type: "application/pdf",
            })
          );
          newUrl = finalFile;
        } else {
          bf = Buffer.from(file.data.responseMessage.buffer.data);
          const finalFile = URL.createObjectURL(
            new Blob([bf], {
              type: "application/pdf",
            })
          );
          newUrl = finalFile;
        }
        setPath(newUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDocument(false);
    }
  };

  const handleSendSignatoryData = async (data) => {
    try {
      setIsBlockNavigate(false);
      setIsLoading(true);
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const obj = {
        attorney: attorney ? attorney : signatureInfo?.attorney,
        consecutive: consecutive ? consecutive : signatureInfo?.consecutive,
        companyId: companyId ? companyId : signatureInfo?.companyId,
        corporateUnitId: corporateUnit,
        description: description ? description : signatureInfo?.description,
        isSendEmailWithSignedDocument: isEmailWithSignedDocumentSend
          ? isEmailWithSignedDocumentSend
          : signatureInfo?.isEmailWithSignedDocumentSend,
        documentId: documentId ? documentId : signatureInfo?.documentId,
        documentType: documentType ? documentType : signatureInfo?.documentType,
        documentAssociated: documentAssociated
          ? documentAssociated
          : signatureInfo?.documentAssociated,
        folderSelected: folderSelected
          ? folderSelected
          : signatureInfo?.folderSelected,
        originalDocument: originalDocument
          ? originalDocument
          : signatureInfo?.originalDocument,
        signatoriesInfo: data.signatories,
        associatedRequest: requestId ? requestId : signatureInfo?.requestId,
        authorName:
          firstName || companyName
            ? `${firstName || companyName || ""} ${firstSurname || ""}`
            : `${
                signatureInfo?.firstName || signatureInfo?.companyName || ""
              } ${signatureInfo?.firstSurname || ""}`,
        userId: userId ? userId : signatureInfo?.userId,
      };

      if (
        signatureInfo?.signatureType === "request" ||
        signatureType === "request"
      ) {
        const service = await saveSignaturesRequest(obj);
        if (
          service?.status === CODES.COD_RESPONSE_HTTP_OK ||
          service?.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          navigate("/service/requests", { replace: true });
          return;
        }
        setOpenModalInfoSign(true);
        setResponseData(service);
      } else if (
        signatureInfo?.signatureType === "lateralPanel" ||
        signatureType === "lateralPanel"
      ) {
        const service = await saveSignatureSidePanel(obj);
        if (
          service?.status === CODES.COD_RESPONSE_HTTP_OK ||
          service?.status === CODES.COD_RESPONSE_HTTP_CREATED
        ) {
          navigate("/service/signView", { replace: true });
          dispatch(resetSignaturesSidePanelStatus());
          return;
        }
        setOpenModalInfoSign(true);
        setResponseData(service);
      } else {
        setOpenModalErrorSignature(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getNameSignatory = (index) => {
    const data = watch(`signatories.${index}`);
    const name = `${data?.firstName || ""} ${data?.secondName || ""} ${
      data?.firstSurname || ""
    } ${data?.secondSurname || ""} `;
    return name;
  };

  const changePositionSignatory = ({ source, destination }) => {
    const isSameIndex = source.index === destination.index;
    if (!source || !destination || isSameIndex) return;
    const [removed] = fields.splice(source.index, 1);
    fields.splice(destination.index, 0, removed);
    const data = getValues()?.signatories;
    const [removedData] = data.splice(source.index, 1);
    data.splice(destination.index, 0, removedData);
    setValue("signatories", data);
  };

  const handleAddSignatory = () => {
    append({});
  };

  const handleAddSignatoryFromLibrary = async () => {
    try {
      setIsBlockNavigate(false);
      setIsLoading(true);
      const status = PARTS_LIBRARY_STATE.ACTIVE._id;
      const company = localStorage.getItem("company");
      let associatedCorporateUnits = [localStorage.getItem("corporateUnitId")];
      const response = await findSignatoriesByCompany({
        company,
        status,
        associatedCorporateUnits,
      });
      const signatoriesListResponse = response.data?.responseMessage?.data;
      setSignatoriesList(signatoriesListResponse);

      if (
        !signatoriesListResponse?.length ||
        signatoriesListResponse?.length <= 0
      ) {
        setOpenModalNoSignatories(true);
      } else {
        navigate(`/service/addSignatories/select-signatory/lateral-panel`, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFieldsSignatory = (index) => {
    remove(index);
  };

  const handleAgreeCancelSign = async () => {
    try {
      setIsBlockNavigate(false);
      setIsOpenModalCancelSign(false);
      setIsLoading(true);
      let response = {
        status: 200,
        data: {
          responseMessage: "Se ha cancelado el proceso de firma correctamente",
        },
      };
      if (signatureType === "lateralPanel" && docTypeToSign === "local") {
        response = await deleteVersion(documentId);
        if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
          response = {
            status: response.status,
            data: {
              responseMessage:
                "Se ha cancelado el proceso de firma correctamente",
            },
          };
        }
      }
      setInfoToCancel(response);
      setIsOpenModaltoCancel(true);
    } catch (error) {
      console.log("========= Start Handle Cancel Sign =========");
      console.error(error);
      console.log("========= End Handle Cancel Sign =========");
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModalCancelSign = () => {
    setIsOpenModaltoCancel(false);
    navigate("/service/home", { replace: true });
  };

  /**
   * UseEffects Block
   */
  //Para actualizar los formularios cuando se obtenga la lista de firmantes de la biblioteca
  useEffect(async () => {
    const handleSelectedSignatoriesFromLibrary = async () => {
      if (selectedSignatoriesFromLibrary?.length > 0) {
        for (
          let index = 0;
          index < selectedSignatoriesFromLibrary.length - 1;
          index++
        ) {
          handleAddSignatory();
        }

        selectedSignatoriesFromLibrary.map((item, index) => {
          setValue(
            `signatories.${index}.firstName`,
            selectedSignatoriesFromLibrary[index]?.firstName
          );
          setValue(
            `signatories.${index}.secondName`,
            selectedSignatoriesFromLibrary[index]?.secondName
          );
          setValue(
            `signatories.${index}.firstSurname`,
            selectedSignatoriesFromLibrary[index]?.firstSurname
          );
          setValue(
            `signatories.${index}.secondSurname`,
            selectedSignatoriesFromLibrary[index]?.secondSurname
          );
          setValue(
            `signatories.${index}.documentNumber`,
            selectedSignatoriesFromLibrary[index]?.documentNumber
          );
          setValue(
            `signatories.${index}.email`,
            selectedSignatoriesFromLibrary[index]?.email
          );
        });
      }
    };

    await handleSelectedSignatoriesFromLibrary();
  }, [signatoriesList]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <MovePageSafe
        isBlockNavigate={isBlockNavigate}
        handleAction={() => {
          //Eliminar version del documento
          handleAgreeCancelSign();
        }}
      />
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={`Firmar documento ${
            consecutive ? "- Solicitud " + consecutive : ""
          }`}
        />
      </Row>
      <Row style={{ rowGap: 10 }}>
        <Col xs={"auto"}>
          <p
            className="home-init-cards-row__title-row"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Datos de los firmantes
          </p>
        </Col>
        {/**
         * Boton Elegir de Biblioteca
         */}
        <Col></Col>
        <Col xs={"auto"}>
          <Tooltip title={<h5>Agrega un nuevo firmante</h5>}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              type="button"
              onClick={handleAddSignatoryFromLibrary}
              startIcon={<AddOutlinedIcon fontSize="large" />}
            >
              Elegir desde biblioteca
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(handleSendSignatoryData)}>
          <DragDropContext
            onDragEnd={(result) => {
              changePositionSignatory(result);
            }}
          >
            <Droppable droppableId="forms-signatories">
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className="custom-accordion__container-headers"
                >
                  {fields.map((item, index) => (
                    <Draggable
                      key={item?.id}
                      draggableId={`${item?.id}-draggable-form`}
                      index={index}
                    >
                      {(draggablePorvider) => (
                        <Accordion
                          {...draggablePorvider.draggableProps}
                          {...draggablePorvider.dragHandleProps}
                          className="custom-accordion"
                          ref={draggablePorvider.innerRef}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Row
                              style={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "end",
                              }}
                            >
                              <Col xs={"auto"}>
                                <DragIndicatorIcon
                                  fontSize="large"
                                  className="drag-color"
                                />
                              </Col>
                              <Col>
                                <p className="heading__primary-color">
                                  {getNameSignatory(index)}
                                  <span className="caption">
                                    Firmante #{index + 1}
                                  </span>
                                </p>
                              </Col>
                              {Object.keys(errors).length > 0 &&
                                errors.signatories[index] && (
                                  <Col xs={"auto"}>
                                    <PopoverInfo
                                      section={{
                                        description:
                                          "Debe completar todos los campos obligatorios",
                                      }}
                                      icon={
                                        <InfoOutlinedIcon
                                          fontSize="large"
                                          color="error"
                                        />
                                      }
                                    />
                                  </Col>
                                )}
                              {fields.length > 1 && (
                                <Col xs={"auto"}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      handleDeleteFieldsSignatory(index);
                                    }}
                                  >
                                    <DeleteIcon fontSize="large" />
                                  </IconButton>
                                </Col>
                              )}
                            </Row>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormSignatory
                              register={register}
                              errors={errors}
                              index={index}
                              setValue={setValue}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <br />
          <Row style={{ rowGap: 10 }}>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Agrega un nuevo firmante</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  type="button"
                  onClick={handleAddSignatory}
                  startIcon={<AddOutlinedIcon fontSize="large" />}
                >
                  Agregar firmante
                </Button>
              </Tooltip>
            </Col>
            <Col></Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Visualizar documento a firmar</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  type="button"
                  onClick={handlePreviewDoc}
                  startIcon={<RemoveRedEyeOutlinedIcon fontSize="large" />}
                >
                  Visualizar
                </Button>
              </Tooltip>
            </Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Cancelar proceso de firma</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  type="button"
                  onClick={() => setIsOpenModalCancelSign(true)}
                >
                  Cancelar
                </Button>
              </Tooltip>
            </Col>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Continuar con el proceso de firma</h5>}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  type="submit"
                  disabled={isDisableContinueButton}
                >
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      className="spinner__blue_background"
                    />
                  ) : (
                    "Continuar"
                  )}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Row>
      <Worker workerUrl={WORKER_URL}>
        <Modal show={show} onHide={handleClosePreview} scrollable>
          <Modal.Header closeLabel="cerrar" closeButton>
            <Modal.Title
              className="heading-secondary"
              style={{ marginRight: "6rem" }}
            >
              Documento a firmar
            </Modal.Title>

            <>
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </>
          </Modal.Header>
          <Modal.Body>
            {isLoadingDocument && <LoadingLinear />}
            {path && (
              <Viewer
                plugins={[zoomPluginInstance]}
                fileUrl={path}
                onZoom={handleZoom}
                httpHeaders={{
                  Authorization: GetToken(),
                }}
              />
            )}
          </Modal.Body>
        </Modal>
      </Worker>
      <ModalInfo
        title={
          responseData?.data?.responseCode ===
          CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
            ? "Límite de firmas"
            : "Información"
        }
        responseData={responseData}
        onClose={() => {
          if (
            responseData?.data?.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            setIsDisableContinueButton(true);
          }
          setOpenModalInfoSign(false);
        }}
        open={openModalInfoSign}
        confirmationText="Aceptar"
        buttonClose={true}
        handleCloseDefault={() => {
          if (
            responseData?.data?.responseCode ===
            CODES.COD_RESPONSE_ERROR_LIMIT_PLAN_USED
          ) {
            setIsDisableContinueButton(true);
          }
          setOpenModalInfoSign(false);
        }}
      />
      <ModalInfo
        title="Proceso de firma cancelado"
        responseData={infoToCancel}
        onClose={onCloseModalCancelSign}
        open={isOpenModaltoCancel}
        confirmationText="Aceptar"
      />
      <ModalInfo
        title="Sin firmantes guardados"
        onClose={() => setOpenModalNoSignatories(false)}
        open={openModalNoSignatories}
        responseData={{
          data: {
            message: "No existen firmantes guardados en biblioteca",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
      <ModalInfo
        title="Error en el proceso de firma"
        onClose={() => setOpenModalErrorSignature(false)}
        open={openModalErrorSignature}
        responseData={{
          data: {
            message:
              "Error: No se puede continuar con el proceso de firma. Intente crear un nuevo proceso.",
          },
        }}
        confirmationText="Aceptar"
        buttonClose={true}
      />
      <ModalDecision
        title={"Cancelar proceso de firma"}
        message={"¿Está seguro de cancelar el proceso de firma?"}
        open={isOpenModalCancelSign}
        onClose={() => setIsOpenModalCancelSign(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeCancelSign}
        handleDisagree={() => setIsOpenModalCancelSign(false)}
      />
    </Container>
  );
};

const FormSignatory = ({ index, register, errors, setValue }) => {
  return (
    <>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label className="form__label">
              Primer nombre:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.firstName`)}
              type="text"
              placeholder="Primer nombre"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.firstName
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.firstName?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicSecondName">
            <Form.Label className="form__label">Segundo nombre:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.secondName`)}
              type="text"
              placeholder="Segundo nombre"
              bsPrefix={"input-group-container__no-icon label"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicLastname">
            <Form.Label className="form__label">
              Primer apellido:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.firstSurname`)}
              type="text"
              placeholder="Primer apellido"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.firstSurname
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.firstSurname?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicSecondLastname">
            <Form.Label className="form__label">Segundo apellido:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.secondSurname`)}
              type="text"
              placeholder="Segundo apellido"
              bsPrefix={"input-group-container__no-icon label"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicDocumentNumber">
            <Form.Label className="form__label">No. Documento:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.documentNumber`)}
              placeholder="No. documento"
              bsPrefix={
                errors?.signatories &&
                errors?.signatories[index]?.documentNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.documentNumber?.message}
            </p>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Correo electrónico:<span style={{ color: "red" }}>{"* "}</span>
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.email`)}
              type="email"
              placeholder="Correo electrónico"
              bsPrefix={
                errors?.signatories && errors?.signatories[index]?.email
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.email?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicContactInfo">
            <Form.Label className="form__label">Código de país:</Form.Label>
            <Select
              defaultValue={"57"}
              className={
                errors?.signatories?.[index]?.countryCode
                  ? "input-group-container__no-icon__country-list__error label"
                  : "input-group-container__no-icon__country-list label"
              }
              {...register(`signatories.${index}.countryCode`)}
              onChange={(e) => {
                setValue(`signatories.${index}.countryCode`, e.target.value);
              }}
              sx={{
                "& .MuiSelect-select": { padding: 0 },
              }}
            >
              <MenuItem
                value={""}
                className="input-group-container__no-icon__country-list label"
                style={{ border: "none !important" }}
              >
                Selecciona
              </MenuItem>
              {countries.map((c) => (
                <MenuItem
                  key={c.code}
                  value={c.phone}
                  className="input-group-container__no-icon__country-list label"
                  style={{ border: "none !important" }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                    alt={`Flag of ${c.label}`}
                  />
                  {` ${c.label} (${c.code}) +${c.phone}`}
                </MenuItem>
              ))}
            </Select>
            <div className="caption custom-input__error">
              {errors?.signatories?.[index]?.countryCode?.message}
            </div>
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicContact">
            <Form.Label className="form__label">Número de celular:</Form.Label>
            <Form.Control
              {...register(`signatories.${index}.phoneNumber`)}
              type="text"
              placeholder="Número de celular"
              bsPrefix={
                errors?.signatories?.[index]?.phoneNumber
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
            />
            <p className="caption custom-input__error">
              {errors?.signatories?.[index]?.phoneNumber?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
      <Row xs={"auto"}>
        <p
          className="home-init-cards-row__title-row"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Mensaje
        </p>
      </Row>
      <Row>
        <Col md={5} xs={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form__label">
              Escribe un mensaje al firmante
            </Form.Label>
            <Form.Control
              {...register(`signatories.${index}.messageToSignatory`)}
              type="text"
              as="textarea"
              rows={3}
              placeholder="Ej. Cordial saludo, a continuación iniciaré con el proceso de la firma."
              bsPrefix={
                errors?.signatories &&
                errors?.signatories[index]?.messageToSignatory
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={250}
            />
            <p className="caption custom-input__error">
              {errors?.signatories &&
                errors?.signatories[index]?.messageToSignatory?.message}
            </p>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default SignatoryData;
