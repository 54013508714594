import React, { useMemo, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { isDefined } from "../../../../../../../../../../utils/validations";
import { SectionsFormDto } from "../../../../../../../../../../services/documents/formCustomization/dto/updatedCustomRequestTypeDto";
import uuid from "react-uuid";

// TYPE DEFINITIONS BEGIN

/**
 * Defines the structure of a the custom form.
 *
 * @typedef {Object} CustomFormType
 * @property {Object} company The company of the form.
 * @property {string} [originalFormId] The original form id.
 * @property {string} [group] The group of the form.
 * @property {string} type The type of the form.
 * @property {string} name The name of the form.
 * @property {Array} description The description of the form.
 * @property {Array} [labels] The labels of the form.
 * @property {Array<SectionType>} sections The sections of the form.
 * @property {Array<RequiredDocumentType>} requiredDocuments The required documents of the form.
 * @property {Array<QuestionType>} fields The questions of the form.
 * @property {boolean} allowUpdateOtherDocumentsToTrace The flag to allow update other documents to trace.
 */

/**
 * Defines the structure of a section.
 *
 * @typedef {Object} SectionType
 * @property {string} [name] The name of the section.
 * @property {Array<QuestionType>} [fields] The questions of the section.
 */

/**
 * Defines the structure of a question.
 *
 * @typedef {Object} QuestionType
 *
 * @property {string} type The type of the question.
 * @property {string} value The value of the question.
 * @property {string} [label] The label of the question.
 * @property {Array<string>} [options] The options of the question.
 * @property {ValidationType} validations The validations of the question.
 */

/**
 * Defines the structure of a validation.
 *
 * @typedef {Object} ValidationType
 * @property {ValidationPropsType} [required] The required validation.
 * @property {ValidationPropsType} [minLength] The min length validation.
 * @property {ValidationPropsType} [maxLength] The max length validation.
 * @property {ValidationPropsType} [pattern] The pattern validation.
 */

/**
 * Defines the props of a validation.
 *
 * @typedef {Object} ValidationPropsType
 * @property {string} message The message of the validation.
 * @property {any} value The value of the validation.
 */

/**
 * Defines the structure of a required document.
 *
 * @typedef {Object} RequiredDocumentType
 * @property {string} [originalFilename] The original name of the document.
 * @property {boolean} [isRequired] Whether the document is required or not.
 * @property {string} [fileExtension] The file extensions of the document.
 * @property {string} [type] The type of the document.
 */

// Action types
/**
 * Defines the structure of a custom form action.
 *
 * @typedef {Object} CustomFormActionType
 * @property {CustomFormActions} type The type of the action.
 * @property {AddQuestionPayloadType | DeleteQuestionPayloadType | UpdateQuestionPayloadType | RequiredDocumentType} payload The payload of the action.
 */

// Action payload types
/**
 * Defines the payload of the AddQuestion action.
 *
 * @typedef {Object} AddQuestionPayloadType
 * @property {number} [sectionIndex] The index of the section where the question will be added.
 * @property {QuestionType} question The question to be added.
 */

/**
 * Defines the payload of the DeleteQuestion action.
 *
 * @typedef {Object} DeleteQuestionPayloadType
 * @property {number} questionIndex The index of the question to be deleted.
 * @property {number} [sectionIndex] The index of the section where the question is located.
 */

/**
 * Defines the payload of the UpdateQuestion action.
 *
 * @typedef {Object} UpdateQuestionPayloadType
 * @property {number} questionIndex The index of the question to be updated.
 * @property {number} [sectionIndex] The index of the section where the question is located.
 * @property {QuestionType} question The updated question.
 */

/**
 * Defines the payload of the UpdateRequiredDocument action.
 *
 * @typedef {Object} UpdateRequiredDocumentPayloadType
 * @property {RequiredDocumentType} requiredDocument The updated required document.
 * @property {number} requiredDocumentIndex The index of the required document to be updated.
 */

/**
 * Defines the payload of `UpdateSectionName` action.
 *
 * @typedef {Object} UpdateSectionNamePayloadType
 * @property {number} sectionIndex The index of the section to be updated.
 * @property {string} newSectionName The new name of the section.
 */

// TYPE DEFINITIONS END

/**
 * The actions to manage the custom form.
 */
const CustomFormActions = Object.freeze({
  AddQuestion: "ADD_QUESTION",
  DeleteQuestion: "DELETE_QUESTION",
  UpdateQuestion: "UPDATE_QUESTION",
  AddRequiredDocument: "ADD_REQUIRED_DOCUMENT",
  DeleteRequiredDocument: "DELETE_REQUIRED_DOCUMENT",
  UpdateRequiredDocument: "UPDATE_REQUIRED_DOCUMENT",
  ToggleAllowUpdateOtherDocumentsToTrace:
    "TOGGLE_ALLOW_UPDATE_OTHER_DOCUMENTS_TO_TRACE",
  SetCustomFormState: "SET_CUSTOM_FORM_STATE",
  SetLabels: "SET_LABELS",
  DeleteLabel: "DELETE_LABEL",
  ChangeGroup: "CHANGE_GROUP",
  ChangeType: "CHANGE_TYPE",
  ChangeName: "CHANGE_NAME",
  ChangeDescription: "CHANGE_DESCRIPTION",
  AddSection: "ADD_SECTION",
  UpdateSectionName: "UPDATE_SECTION_NAME",
  DeleteSection: "DELETE_SECTION",
});

/**
 * The functions to manage the custom form.
 */
const CustomFormFunctions = Object.freeze({
  /**
   * Add a question to the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {AddQuestionPayloadType} payload The payload of the action.
   * @returns {CustomFormType} The new state of the custom form.
   */
  AddQuestion: (customFormState, payload) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section index and a question to add a question to the form."
        );
      },
      sectionNotFound: () => {
        console.warn(
          "The section you are trying to add the question to does not exist."
        );
      },
    };
    const { sectionIndex, question } = payload;
    if (!question) {
      logWarningMessages.nonPropsProvided();

      return customFormState;
    }
    if (!isDefined(sectionIndex)) {
      const fieldsCopy = [...customFormState.fields];
      fieldsCopy.push(question);

      return {
        ...customFormState,
        fields: fieldsCopy,
      };
    } else if (!customFormState.sections[sectionIndex]) {
      logWarningMessages.sectionNotFound();

      return customFormState;
    } else {
      const sectionsCopy = [...customFormState.sections];
      sectionsCopy[sectionIndex].fields.push(question);

      return {
        ...customFormState,
        sections: sectionsCopy,
      };
    }
  },
  /**
   * Delete a question from the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {DeleteQuestionPayloadType} payload The payload of the action.
   * @returns {CustomFormType} The new state of the custom form.
   */
  DeleteQuestion: (customFormState, payload) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section a question index to delete a question from the form."
        );
      },
      sectionNotFound: () => {
        console.warn(
          "The section you are trying to delete the question from does not exist."
        );
      },
      questionNotFound: () => {
        console.warn("The question you are trying to delete does not exist.");
      },
    };
    const { sectionIndex, questionIndex } = payload;

    if (!isDefined(questionIndex)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (isDefined(sectionIndex)) {
      if (!customFormState.sections[sectionIndex]) {
        logWarningMessages.sectionNotFound();
        return customFormState;
      } else if (
        !customFormState.sections[sectionIndex].fields[questionIndex]
      ) {
        logWarningMessages.questionNotFound();
        return customFormState;
      }

      const sectionsCopy = [...customFormState.sections];
      sectionsCopy[sectionIndex].fields.splice(questionIndex, 1);

      return {
        ...customFormState,
        sections: sectionsCopy,
      };
    } else if (!customFormState.fields[questionIndex]) {
      logWarningMessages.questionNotFound();
      return customFormState;
    } else {
      const fieldsCopy = [...customFormState.fields];
      fieldsCopy.splice(questionIndex, 1);

      return {
        ...customFormState,
        fields: fieldsCopy,
      };
    }
  },
  /**
   * Update a question in the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {UpdateQuestionPayloadType} payload The payload of the action.
   * @returns {CustomFormType} The new state of the custom form.
   */
  UpdateQuestion: (customFormState, payload) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section index, a question index and a question to update a question in the form."
        );
      },
      sectionNotFound: () => {
        console.warn(
          "The section you are trying to update the question in does not exist."
        );
      },
      questionNotFound: () => {
        console.warn("The question you are trying to update does not exist.");
      },
    };
    const { sectionIndex, questionIndex, question } = payload;
    if (!isDefined(questionIndex) || !question) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (isDefined(sectionIndex)) {
      if (!customFormState.sections[sectionIndex]) {
        logWarningMessages.sectionNotFound();
        return customFormState;
      } else if (
        !customFormState.sections[sectionIndex].fields[questionIndex]
      ) {
        logWarningMessages.questionNotFound();
        return customFormState;
      } else {
        const sectionsCopy = [...customFormState.sections];

        sectionsCopy[sectionIndex].fields[questionIndex] = {
          ...sectionsCopy[sectionIndex].fields[questionIndex],
          ...question,
        };

        return {
          ...customFormState,
          sections: sectionsCopy,
        };
      }
    } else if (!customFormState.fields[questionIndex]) {
      logWarningMessages.questionNotFound();

      return customFormState;
    } else {
      const fieldsCopy = [...customFormState.fields];

      fieldsCopy[questionIndex] = {
        ...fieldsCopy[questionIndex],
        ...question,
      };

      return {
        ...customFormState,
        fields: fieldsCopy,
      };
    }
  },
  /**
   * Add a required document to the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {RequiredDocumentType} requiredDocument The required document to be added.
   * @returns {CustomFormType} The new state of the custom form.
   */
  AddRequiredDocument: (customFormState, requiredDocument) => {
    const requiredDocumentsCopy = [...customFormState.requiredDocuments];
    requiredDocumentsCopy.push(requiredDocument);

    return {
      ...customFormState,
      requiredDocuments: requiredDocumentsCopy,
    };
  },
  /**
   * Delete a required document from the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {number} requiredDocumentIndex The index of the required document to be deleted.
   * @returns {CustomFormType} The new state of the custom form.
   */
  DeleteRequiredDocument: (customFormState, requiredDocumentIndex) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a required document index to delete a required document from the form."
        );
      },
      requiredDocumentNotFound: () => {
        console.warn(
          "The required document you are trying to delete does not exist."
        );
      },
    };

    if (!isDefined(requiredDocumentIndex)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    if (!customFormState.requiredDocuments[requiredDocumentIndex]) {
      logWarningMessages.requiredDocumentNotFound();
      return customFormState;
    }

    const requiredDocumentsCopy = [...customFormState.requiredDocuments];
    requiredDocumentsCopy.splice(requiredDocumentIndex, 1);

    return {
      ...customFormState,
      requiredDocuments: requiredDocumentsCopy,
    };
  },
  /**
   * Update a required document in the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {UpdateRequiredDocumentPayloadType} payload The payload of the action.
   * @returns {CustomFormType} The new state of the custom form.
   */
  UpdateRequiredDocument: (customFormState, payload) => {
    const { requiredDocument, requiredDocumentIndex } = payload;
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a required document index and a required document to update a required document in the form."
        );
      },
      requiredDocumentNotFound: () => {
        console.warn(
          "The required document you are trying to update does not exist."
        );
      },
    };

    if (!requiredDocument || !isDefined(requiredDocumentIndex)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (!customFormState.requiredDocuments[requiredDocumentIndex]) {
      logWarningMessages.requiredDocumentNotFound();
      return customFormState;
    }

    const requiredDocumentsCopy = [...customFormState.requiredDocuments];
    requiredDocumentsCopy[requiredDocumentIndex] = {
      ...requiredDocumentsCopy[requiredDocumentIndex],
      ...requiredDocument,
    };

    return {
      ...customFormState,
      requiredDocuments: requiredDocumentsCopy,
    };
  },
  /**
   * Toggle the flag to allow update other documents to trace.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  ToggleAllowUpdateOtherDocumentsToTrace: (customFormState) => {
    return {
      ...customFormState,
      allowUpdateOtherDocumentsToTrace:
        !customFormState.allowUpdateOtherDocumentsToTrace,
    };
  },
  /**
   * Set the custom form.
   *
   * @param {CustomFormType} newCustomForm The new custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  SetCustomFormState: (newCustomForm) => {
    return newCustomForm;
  },
  /**
   * Set the labels of the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {Array} labels The labels of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  SetLabels: (customFormState, labels) => {
    return {
      ...customFormState,
      labels,
    };
  },
  /**
   * Delete a label from the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {number} labelIndex The index of the label to be deleted.
   * @returns {CustomFormType} The new state of the custom form.
   */
  DeleteLabel: (customFormState, labelIndex) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a label index to delete a label from the form."
        );
      },
      indexNotFound: () => {
        console.warn(
          "The label index you are trying to delete does not exist."
        );
      },
    };

    if (!isDefined(labelIndex)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (!customFormState.labels[labelIndex]) {
      logWarningMessages.indexNotFound();
      return customFormState;
    }

    const labelsCopy = [...customFormState.labels];
    labelsCopy.splice(labelIndex, 1);

    return {
      ...customFormState,
      labels: labelsCopy,
    };
  },
  /**
   * Change the group of the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {string} newGroup The new group of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  ChangeGroup: (customFormState, newGroup) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a new group to change the group of the custom form."
        );
      },
    };

    if (!isDefined(newGroup)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    return {
      ...customFormState,
      group: newGroup,
    };
  },
  /**
   * Change the type of the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {string} newType The new type of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  ChangeType: (customFormState, newType) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a new type to change the type of the custom form."
        );
      },
    };

    if (!isDefined(newType)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    return {
      ...customFormState,
      type: newType,
    };
  },
  /**
   * Change the name of the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {string} newName The new name of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  ChangeName: (customFormState, newName) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a new name to change the name of the custom form."
        );
      },
    };

    if (!isDefined(newName)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    return {
      ...customFormState,
      name: newName,
    };
  },
  /**
   * Change the description of the custom form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {string} newDescription The new description of the custom form.
   * @returns {CustomFormType} The new state of the custom form.
   */
  ChangeDescription: (customFormState, newDescription) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a new description to change the description of the custom form."
        );
      },
    };

    if (!isDefined(newDescription)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    return {
      ...customFormState,
      description: newDescription,
    };
  },
  /**
   * Add a section to the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {string} sectionName The name of the section to be added.
   * @returns {CustomFormType} The new state of the custom form.
   */
  AddSection: (customFormState, sectionName) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section name to add a section to the form."
        );
      },
    };

    if (!isDefined(sectionName)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }

    const sectionsCopy = [...customFormState.sections];
    sectionsCopy.push({
      ...new SectionsFormDto({
        name: sectionName,
        fields: [],
      }),
      _id: uuid(),
    });

    return {
      ...customFormState,
      sections: sectionsCopy,
    };
  },
  /**
   * Update the name of a section.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {UpdateSectionNamePayloadType} payload The payload of the action.
   * @returns {CustomFormType} The new state of the custom form.
   */
  UpdateSectionName: (customFormState, payload) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section index and a new section name to update the section name."
        );
      },
      sectionNotFound: () => {
        console.warn("The section you are trying to update does not exist.");
      },
    };
    const { sectionIndex, newSectionName } = payload;

    if (!isDefined(sectionIndex) || !isDefined(newSectionName)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (!customFormState.sections[sectionIndex]) {
      logWarningMessages.sectionNotFound();
      return customFormState;
    }

    const sectionsCopy = [...customFormState.sections];
    sectionsCopy[sectionIndex].name = newSectionName;

    return {
      ...customFormState,
      sections: sectionsCopy,
    };
  },
  /**
   * Delete a section from the form.
   *
   * @param {CustomFormType} customFormState The state of the custom form.
   * @param {number} sectionIndex The index of the section to be deleted.
   * @returns {CustomFormType} The new state of the custom form.
   */
  DeleteSection: (customFormState, sectionIndex) => {
    const logWarningMessages = {
      nonPropsProvided: () => {
        console.warn(
          "You must provide a section index to delete a section from the form."
        );
      },
      sectionNotFound: () => {
        console.warn("The section you are trying to delete does not exist.");
      },
    };

    if (!isDefined(sectionIndex)) {
      logWarningMessages.nonPropsProvided();
      return customFormState;
    }
    if (!customFormState.sections[sectionIndex]) {
      logWarningMessages.sectionNotFound();
      return customFormState;
    }

    const sectionsCopy = [...customFormState.sections];
    sectionsCopy.splice(sectionIndex, 1);

    return {
      ...customFormState,
      sections: sectionsCopy,
    };
  },
});

/**
 * The custom form reducer.
 *
 * @param {CustomFormType} state The current state of the custom form.
 * @param {CustomFormActionType} action The action to be performed.
 * @returns {CustomFormType} The new state of the custom form.
 */
const customFormReducer = (state, action) => {
  const { type } = action;

  const logWarningMessages = {
    actionTypeNotFound: () => {
      console.warn(`The action type ${type} does not exist.`);
    },
  };

  switch (type) {
    case CustomFormActions.AddQuestion:
      return CustomFormFunctions.AddQuestion(state, action.payload);
    case CustomFormActions.DeleteQuestion:
      return CustomFormFunctions.DeleteQuestion(state, action.payload);
    case CustomFormActions.UpdateQuestion:
      return CustomFormFunctions.UpdateQuestion(state, action.payload);
    case CustomFormActions.AddRequiredDocument:
      return CustomFormFunctions.AddRequiredDocument(state, action.payload);
    case CustomFormActions.DeleteRequiredDocument:
      return CustomFormFunctions.DeleteRequiredDocument(state, action.payload);
    case CustomFormActions.UpdateRequiredDocument:
      return CustomFormFunctions.UpdateRequiredDocument(state, action.payload);
    case CustomFormActions.ToggleAllowUpdateOtherDocumentsToTrace:
      return CustomFormFunctions.ToggleAllowUpdateOtherDocumentsToTrace(state);
    case CustomFormActions.SetCustomFormState:
      return CustomFormFunctions.SetCustomFormState(action.payload);
    case CustomFormActions.SetLabels:
      return CustomFormFunctions.SetLabels(state, action.payload);
    case CustomFormActions.DeleteLabel:
      return CustomFormFunctions.DeleteLabel(state, action.payload);
    case CustomFormActions.ChangeGroup:
      return CustomFormFunctions.ChangeGroup(state, action.payload);
    case CustomFormActions.ChangeType:
      return CustomFormFunctions.ChangeType(state, action.payload);
    case CustomFormActions.ChangeName:
      return CustomFormFunctions.ChangeName(state, action.payload);
    case CustomFormActions.ChangeDescription:
      return CustomFormFunctions.ChangeDescription(state, action.payload);
    case CustomFormActions.AddSection:
      return CustomFormFunctions.AddSection(state, action.payload);
    case CustomFormActions.UpdateSectionName:
      return CustomFormFunctions.UpdateSectionName(state, action.payload);
    case CustomFormActions.DeleteSection:
      return CustomFormFunctions.DeleteSection(state, action.payload);
    default:
      logWarningMessages.actionTypeNotFound();
      return state;
  }
};

/**
 * Manages the custom form state.
 *
 * For a correct use, follow the next steps:
 * 1. Use the `useCustomFormStateManager` hook to get the custom form state and the functions to manage it.
 * 2. Use the functions to manage the custom form state.
 * 3. If you want to update a question frequently, use the `updateQuestion` function with the `useTemporaryData` parameter set to `true`.
 * 4. Before saving the form, call the `prepareCustomFormForSave` function to update the custom form state with the temporary data and validate it.
 *
 * @param {Object} props The component props.
 * @param {CustomFormType} props.customForm The custom form, by default is an empty object.
 * @returns The custom form state and the functions to manage it.
 */
const useCustomFormStateManager = ({ customForm = {} }) => {
  const [customFormState, dispatch] = useReducer(customFormReducer, customForm);
  /**
   * The temporary data of the custom form, used to store data tha is updated frequently,
   * is important to avoid re-renders when the data is updated.
   */
  const temporaryData = useMemo(() => {
    return {
      /**
       * The questions that have been modified.
       *
       * @type {Array<{modifiedQuestion: QuestionType, sectionIndex: ?number, questionIndex: number}>}
       */
      modifiedQuestions: [],
    };
  }, [customForm]);

  const modifiedPropertiesName = useMemo(() => {
    return new Set();
  }, [customForm]);

  const modificablePropertiesName = {
    Name: "name",
    Sections: "sections",
    RequiredDocuments: "requiredDocuments",
    AllowUpdateOtherDocumentsToTrace: "allowUpdateOtherDocumentsToTrace",
    Labels: "labels",
    Fields: "fields",
  };

  // Reducer functions

  /**
   * Add a question to the form.
   *
   * @param {number} [sectionIndex] The index of the section where the question will be added.
   * @param {QuestionType} question The question to be added.
   */
  const addQuestion = (sectionIndex, question) => {
    dispatch({
      type: CustomFormActions.AddQuestion,
      payload: {
        sectionIndex,
        question,
      },
    });
    addModifiedPropertyName(modificablePropertiesName.Fields);
  };

  /**
   * Delete a question from the form.
   *
   * @param {number} [sectionIndex] The index of the section where the question is located.
   * @param {number} questionIndex The index of the question to be deleted.
   */
  const deleteQuestion = (sectionIndex, questionIndex) => {
    dispatch({
      type: CustomFormActions.DeleteQuestion,
      payload: {
        sectionIndex,
        questionIndex,
      },
    });
    deleteTemporaryModifiedQuestions(sectionIndex, questionIndex);
    addModifiedPropertyName(modificablePropertiesName.Fields);
  };

  /**
   * Update a question in the form.
   *
   * @param {number} [sectionIndex] The index of the section where the question is located.
   * @param {number} questionIndex The index of the question to be updated.
   * @param {QuestionType} question The updated question.
   * @param {boolean} [useTemporaryData=true] Whether to use the temporary data or not, helps to avoid re-renders. Default is `true`.
   */
  const updateQuestion = (
    sectionIndex,
    questionIndex,
    question,
    useTemporaryData = true
  ) => {
    if (useTemporaryData) {
      updateTemporaryModifiedQuestions(sectionIndex, questionIndex, question);
    } else {
      dispatch({
        type: CustomFormActions.UpdateQuestion,
        payload: {
          sectionIndex,
          questionIndex,
          question,
        },
      });
    }
    addModifiedPropertyName(modificablePropertiesName.Fields);
  };

  /**
   * Add a required document to the form.
   *
   * @param {RequiredDocumentType} requiredDocument The required document to be added.
   */
  const addRequiredDocument = (requiredDocument) => {
    dispatch({
      type: CustomFormActions.AddRequiredDocument,
      payload: requiredDocument,
    });
    addModifiedPropertyName(modificablePropertiesName.RequiredDocuments);
  };

  /**
   * Delete a required document from the form.
   *
   * @param {number} requiredDocumentIndex The index of the required document to be deleted.
   */
  const deleteRequiredDocument = (requiredDocumentIndex) => {
    dispatch({
      type: CustomFormActions.DeleteRequiredDocument,
      payload: requiredDocumentIndex,
    });
    addModifiedPropertyName(modificablePropertiesName.RequiredDocuments);
  };

  /**
   * Update a required document in the form.
   *
   * @param {RequiredDocumentType} requiredDocument The updated required document.
   * @param {number} requiredDocumentIndex The index of the required document to be updated.
   */
  const updateRequiredDocument = (requiredDocument, requiredDocumentIndex) => {
    dispatch({
      type: CustomFormActions.UpdateRequiredDocument,
      payload: {
        requiredDocument,
        requiredDocumentIndex,
      },
    });
    addModifiedPropertyName(modificablePropertiesName.RequiredDocuments);
  };

  /**
   * Toggle the flag to allow update other documents to trace.
   */
  const toggleAllowUpdateOtherDocumentsToTrace = () => {
    dispatch({
      type: CustomFormActions.ToggleAllowUpdateOtherDocumentsToTrace,
    });
    addModifiedPropertyName(
      modificablePropertiesName.AllowUpdateOtherDocumentsToTrace
    );
  };

  /**
   * Set the custom form.
   *
   * @param {CustomFormType} newCustomForm The new custom form.
   */
  const setCustomFormState = (newCustomForm) => {
    dispatch({
      type: CustomFormActions.SetCustomFormState,
      payload: newCustomForm,
    });
  };

  /**
   * Set the labels of the custom form.
   *
   * @param {Array} labels The labels of the custom form.
   */
  const setLabels = (labels) => {
    dispatch({
      type: CustomFormActions.SetLabels,
      payload: labels,
    });
    addModifiedPropertyName(modificablePropertiesName.Labels);
  };

  /**
   * Delete a label from the custom form.
   *
   * @param {number} labelIndex The index of the label to be deleted.
   */
  const deleteLabel = (labelIndex) => {
    dispatch({
      type: CustomFormActions.DeleteLabel,
      payload: labelIndex,
    });
    addModifiedPropertyName(modificablePropertiesName.Labels);
  };

  /**
   * Change the group of the custom form.
   *
   * @param {string} newGroup The new group of the custom form.
   */
  const changeGroup = (newGroup) => {
    dispatch({
      type: CustomFormActions.ChangeGroup,
      payload: newGroup,
    });
  };

  /**
   * Change the type of the custom form.
   *
   * @param {string} newType The new type of the custom form.
   */
  const changeType = (newType) => {
    dispatch({
      type: CustomFormActions.ChangeType,
      payload: newType,
    });
  };

  /**
   * Change the name of the custom form.
   *
   * @param {string} newName The new name of the custom form.
   */
  const changeName = (newName) => {
    dispatch({
      type: CustomFormActions.ChangeName,
      payload: newName,
    });
    addModifiedPropertyName(modificablePropertiesName.Name);
  };

  /**
   * Change the description of the custom form.
   *
   * @param {string} newDescription The new description of the custom form.
   */
  const changeDescription = (newDescription) => {
    dispatch({
      type: CustomFormActions.ChangeDescription,
      payload: newDescription,
    });
  };

  /**
   * Add a section to the form.
   *
   * @param {string} sectionName The name of the section to be added.
   */
  const addSection = (sectionName) => {
    dispatch({
      type: CustomFormActions.AddSection,
      payload: sectionName,
    });
    addModifiedPropertyName(modificablePropertiesName.Sections);
  };

  /**
   * Update the name of a section.
   *
   * @param {number} sectionIndex The index of the section to be updated.
   * @param {string} newSectionName The new name of the section.
   */
  const updateSectionName = (sectionIndex, newSectionName) => {
    dispatch({
      type: CustomFormActions.UpdateSectionName,
      payload: {
        sectionIndex,
        newSectionName,
      },
    });
    addModifiedPropertyName(modificablePropertiesName.Sections);
  };

  /**
   * Delete a section from the form.
   *
   * @param {number} sectionIndex The index of the section to be deleted.
   */
  const deleteSection = (sectionIndex) => {
    dispatch({
      type: CustomFormActions.DeleteSection,
      payload: sectionIndex,
    });
    addModifiedPropertyName(modificablePropertiesName.Sections);
  };

  // Temporary data functions

  /**
   * Update the temporary modified questions.
   *
   * @param {number} [sectionIndex] The index of the section where the question is located.
   * @param {number} questionIndex The index of the question to be updated.
   * @param {QuestionType} question The updated question.
   */
  const updateTemporaryModifiedQuestions = (
    sectionIndex,
    questionIndex,
    question
  ) => {
    const modifiedQuestionIndex = temporaryData.modifiedQuestions.findIndex(
      (modifiedQuestion) => modifiedQuestion.modifiedValues._id === question._id
    );
    if (modifiedQuestionIndex === -1) {
      temporaryData.modifiedQuestions.push({
        modifiedValues: question,
        sectionIndex,
        questionIndex,
      });
    } else {
      temporaryData.modifiedQuestions[modifiedQuestionIndex] = {
        modifiedValues: question,
        sectionIndex,
        questionIndex,
      };
    }
  };

  /**
   * Delete the temporary modified questions.
   *
   * @param {number} [sectionIndex] The index of the section where the question is located.
   * @param {number} questionIndex The index of the question to be deleted.
   */
  const deleteTemporaryModifiedQuestions = (sectionIndex, questionIndex) => {
    const modifiedQuestionIndex = temporaryData.modifiedQuestions.findIndex(
      (modifiedQuestion) =>
        modifiedQuestion.sectionIndex === sectionIndex &&
        modifiedQuestion.questionIndex === questionIndex
    );
    if (modifiedQuestionIndex !== -1) {
      temporaryData.modifiedQuestions.splice(modifiedQuestionIndex, 1);
    }
  };

  /**
   * Get the unified custom form state with the temporary data.
   *
   * @returns {CustomFormType} The unified custom form state with the temporary data.
   */
  const getUnifiedCustomFormStateWithTemporaryData = () => {
    const fieldsCopy = Array.isArray(customFormState.fields)
      ? [...customFormState.fields]
      : [];
    const sectionsCopy = [...customFormState.sections];

    temporaryData.modifiedQuestions.forEach((modifiedQuestion) => {
      if (!isDefined(modifiedQuestion.sectionIndex)) {
        fieldsCopy[modifiedQuestion.questionIndex] =
          modifiedQuestion.modifiedValues;
      } else {
        sectionsCopy[modifiedQuestion.sectionIndex].fields[
          modifiedQuestion.questionIndex
        ] = modifiedQuestion.modifiedValues;
      }
    });
    return {
      ...customFormState,
      fields: fieldsCopy,
      sections: sectionsCopy,
    };
  };

  // Validation functions

  /**
   * Prepare the custom form for save.
   *
   * This function is used to update the custom form state with the temporary data before saving it and validate it.
   *
   * @returns {string | CustomFormType} The error message if the custom form is not valid, otherwise the new custom form state.
   */
  const prepareCustomFormForSave = () => {
    const unifiedCustomFormState = getUnifiedCustomFormStateWithTemporaryData();
    const errorMessage = validateCustomForm(unifiedCustomFormState);

    setCustomFormState(unifiedCustomFormState);
    if (errorMessage) {
      return errorMessage;
    }

    return unifiedCustomFormState;
  };

  /**
   * Validate the custom form, validations are:
   *
   * - The name of the form is required.
   * - The group of the form is required.
   * - The type of the form is required.
   * - The name of the sections is required.
   * - The value of the questions is required.
   * - The name of the required documents is required.
   *
   * @param {CustomFormType} customForm The custom form to be validated.
   * @returns {string} The error message if the custom form is not valid, otherwise `undefined`.
   */
  const validateCustomForm = (customForm) => {
    const errorMessages = {
      name: "El nombre del formulario es obligatorio.",
      group: "El tipo del formulario es obligatorio.",
      type: "El subtipo del formulario es obligatorio.",
    };
    const validationResults = {
      validateSectionsNameIsNotEmptyResult:
        validateSectionsNameIsNotEmpty(customForm),
      validateQuestionsValueIsNotEmptyResult:
        validateQuestionsValueIsNotEmpty(customForm),
      validateRequiredDocumentsOriginalFilenameIsNotEmptyResult:
        validateRequiredDocumentsOriginalFilenameIsNotEmpty(customForm),
    };

    if (!customForm.name) {
      return errorMessages.name;
    }
    if (!customForm.group) {
      return errorMessages.group;
    }
    if (!customForm.type) {
      return errorMessages.type;
    }
    if (!validationResults.validateSectionsNameIsNotEmptyResult.isValid) {
      return validationResults.validateSectionsNameIsNotEmptyResult.message;
    }
    if (!validationResults.validateQuestionsValueIsNotEmptyResult.isValid) {
      return validationResults.validateQuestionsValueIsNotEmptyResult.message;
    }
    if (
      !validationResults
        .validateRequiredDocumentsOriginalFilenameIsNotEmptyResult.isValid
    ) {
      return validationResults
        .validateRequiredDocumentsOriginalFilenameIsNotEmptyResult.message;
    }
  };

  /**
   * Validate if the name of the sections is not empty.
   *
   * @param {CustomFormType} customForm The custom form to be validated.
   * @returns An object with the validation result.
   */
  const validateSectionsNameIsNotEmpty = (customForm) => {
    let isValid = true;
    let message = "";

    customForm.sections.forEach((section, sectionIndex) => {
      if (!section.name) {
        isValid = false;
        message = `Lo sentimos, debes completar la información que falta en la sección ${
          sectionIndex + 1
        } para poder guardar el formulario`;
      }
    });

    const returnObject = {
      /**
       * Whether the name of the sections is not empty or not.
       */
      isValid,
      /**
       * The error message if the name of the sections is empty.
       */
      message,
    };

    return returnObject;
  };

  /**
   * Validate if the value of the questions is not empty.
   *
   * @param {CustomFormType} customForm The custom form to be validated.
   * @returns An object with the validation result.
   */
  const validateQuestionsValueIsNotEmpty = (customForm) => {
    let isValid = true;
    let message = "";
    let questionIterator = 0;

    /**
     * Validate the questions on the sections.
     */
    customForm.sections.forEach((section, sectionIndex) => {
      const sectionName = section.name ?? `la sección ${sectionIndex + 1}`;
      let sectionQuestionIterator = 0;

      while (
        !message.length &&
        sectionQuestionIterator < section.fields.length
      ) {
        const question = section.fields[sectionQuestionIterator];

        if (!question.value) {
          isValid = false;
          message = `Lo sentimos, debes completar la información que falta en ${sectionName} para poder guardar el formulario`;
        }
        sectionQuestionIterator++;
      }
    });

    /**
     * Validate the questions without sections.
     */
    while (!message.length && questionIterator < customForm.fields.length) {
      const question = customForm.fields[questionIterator];

      if (!question.value) {
        isValid = false;
        message = `Lo sentimos, debes completar la información que falta en la pregunta ${
          questionIterator + 1
        } de las preguntas sin sección para poder guardar el formulario`;
      }
      questionIterator++;
    }

    const returnObject = {
      /**
       * Whether the value of the questions is not empty or not.
       */
      isValid,
      /**
       * The error message if the value of the questions is empty.
       */
      message,
    };

    return returnObject;
  };

  /**
   * Validate if the original filename of the required documents is not empty.
   *
   * @param {CustomFormType} customForm The custom form to be validated.
   * @returns An object with the validation result.
   */
  const validateRequiredDocumentsOriginalFilenameIsNotEmpty = (customForm) => {
    let isValid = true;
    let message = "";
    let requiredDocumentIterator = 0;

    while (requiredDocumentIterator < customForm.requiredDocuments.length) {
      const requiredDocument =
        customForm.requiredDocuments[requiredDocumentIterator];

      if (!requiredDocument.originalFilename) {
        isValid = false;
        message = `Lo sentimos, debes completar la información que falta en el documento ${
          requiredDocumentIterator + 1
        } en los documentos de la solicitud para poder guardar el formulario`;
      }
      requiredDocumentIterator++;
    }

    const returnObject = {
      /**
       * Whether the value of the questions is not empty or not.
       */
      isValid,
      /**
       * The error message if the value of the questions is empty.
       */
      message,
    };

    return returnObject;
  };

  // Modified properties functions

  /**
   * Add a modified property name to the custom form.
   *
   * @param {string} propertyName The name of the modified property.
   */
  const addModifiedPropertyName = (propertyName) => {
    modifiedPropertiesName.add(propertyName);
  };

  /**
   * Get the modified properties of the custom form.
   *
   * Is used for keep a track of the properties that have been modified.
   *
   * @returns {Object} The modified properties of the custom form.
   */
  const getModifiedProperties = () => {
    const transformedPropertiesNameForUpdateFormDto = {
      [modificablePropertiesName.Sections]: "updateActualQuestions",
      [modificablePropertiesName.RequiredDocuments]: "listDocuments",
    }
    const modifiedProperties = {};
    const customForm = prepareCustomFormForSave();

    for (const propertyName of modifiedPropertiesName) {
      const modifiedPropertyName = transformedPropertiesNameForUpdateFormDto[propertyName] ?? propertyName;
      modifiedProperties[modifiedPropertyName] = customForm[propertyName];
    }

    return modifiedProperties;
  };

  return {
    customFormState,
    addQuestion,
    deleteQuestion,
    updateQuestion,
    addRequiredDocument,
    deleteRequiredDocument,
    updateRequiredDocument,
    toggleAllowUpdateOtherDocumentsToTrace,
    setCustomFormState,
    setLabels,
    deleteLabel,
    prepareCustomFormForSave,
    changeGroup,
    changeType,
    changeName,
    changeDescription,
    addSection,
    updateSectionName,
    deleteSection,
    getModifiedProperties,
  };
};

useCustomFormStateManager.propTypes = {
  customForm: PropTypes.shape({
    labels: PropTypes.array,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            label: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            validations: PropTypes.shape({
              required: PropTypes.shape({
                message: PropTypes.string.isRequired,
                value: PropTypes.any,
              }),
              minLength: PropTypes.shape({
                message: PropTypes.string.isRequired,
                value: PropTypes.any,
              }),
              maxLength: PropTypes.shape({
                message: PropTypes.string.isRequired,
                value: PropTypes.any,
              }),
              pattern: PropTypes.shape({
                message: PropTypes.string.isRequired,
                value: PropTypes.any,
              }),
            }),
          })
        ),
      })
    ).isRequired,
    requiredDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        originalFilename: PropTypes.string,
        isRequired: PropTypes.bool,
        fileExtension: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default useCustomFormStateManager;
