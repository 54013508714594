import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  findPartByCompany,
  findSignatoriesByCompany,
} from "../../services/parts/partsServices";
import { CODES } from "../../utils/codes";

import {
  PARTS_LIBRARY_STATE,
  SIGNATORIES_STATE,
} from "../../pages/views/userService/modules/library/parts/utils";

export const partsSlice = createSlice({
  name: "parts",
  initialState: {
    partsList: [],
    partsListStatus: "fetch",
    partsListError: null,
    selectedPart: {},
    partsListDelete: [],
    partsListStatusDelete: "fetch",
    partsListErrorDelete: null,
    signatoriesList: [],
    signatoriesListStatus: "fetch",
    signatoriesListError: null,
    selectedSignatory: {},
    signatoriesListDelete: [],
    signatoriesListStatusDelete: "fetch",
    signatoriesListErrorDelete: null,
  },
  reducers: {
    resetPartListStatus(state, action) {
      state.partsListStatus = "fetch";
      state.partsListStatusDelete = "fetch";
    },
    setSelectedPart(state, action) {
      state.selectedPart = action.payload;
    },
    resetSignatoriesListStatus(state, action) {
      state.signatoriesListStatus = "fetch";
      state.signatoriesListStatusDelete = "fetch";
    },
    setSelectedSignatory(state, action) {
      state.selectedSignatory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartsList.pending, (state, _) => {
        state.partsListStatus = "loading";
      })
      .addCase(fetchPartsList.fulfilled, (state, action) => {
        state.partsListStatus = "succeeded";
        state.partsList = action.payload.data.responseMessage.data;
      })
      .addCase(fetchPartsList.rejected, (state, action) => {
        state.partsListStatus = "failed";
        state.partsListError = action.error.message;
      })
      .addCase(fetchPartsListDelete.pending, (state, _) => {
        state.partsListStatusDelete = "loading";
      })
      .addCase(fetchPartsListDelete.fulfilled, (state, action) => {
        state.partsListStatusDelete = "succeeded";
        state.partsListDelete = action?.payload?.data?.responseMessage?.data;
      })
      .addCase(fetchPartsListDelete.rejected, (state, action) => {
        state.partsListStatusDelete = "failed";
        state.partsListErrorDelete = action.error.message;
      })

      .addCase(fetchSignatoriesList.pending, (state, _) => {
        state.signatoriesListStatus = "loading";
      })
      .addCase(fetchSignatoriesList.fulfilled, (state, action) => {
        state.signatoriesListStatus = "succeeded";
        state.signatoriesList = action.payload.data.responseMessage.data;
      })
      .addCase(fetchSignatoriesList.rejected, (state, action) => {
        state.signatoriesListStatus = "failed";
        state.signatoriesListError = action.error.message;
      })
      .addCase(fetchSignatoriesListDelete.pending, (state, _) => {
        state.signatoriesListStatusDelete = "loading";
      })
      .addCase(fetchSignatoriesListDelete.fulfilled, (state, action) => {
        state.signatoriesListStatusDelete = "succeeded";
        state.signatoriesListDelete =
          action?.payload?.data?.responseMessage?.data;
      })
      .addCase(fetchSignatoriesListDelete.rejected, (state, action) => {
        state.signatoriesListStatusDelete = "failed";
        state.partsListErrorDelete = action.error.message;
      });
  },
});

export const { resetPartListStatus } = partsSlice.actions;

export const { setSelectedPart } = partsSlice.actions;

export const getSelectedPart = (state) => state.parts.selectedPart;

export const getPartsList = (state) => state.parts.partsList;

export const getPartsListStatus = (state) => state.parts.partsListStatus;

export const getPartsListDelete = (state) => state.parts.partsListDelete;

export const getPartsListStatusDelete = (state) =>
  state.parts.partsListStatusDelete;

export const fetchPartsList = createAsyncThunk(
  "parts/fetchPartsList",
  async () => {
    const company = localStorage.getItem("company");
    const status = PARTS_LIBRARY_STATE.ACTIVE._id;
    const associatedCorporateUnits = getCorporateUnitsByLoginType();

    const response = await findPartByCompany({
      company,
      status,
      associatedCorporateUnits,
    });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const fetchPartsListDelete = createAsyncThunk(
  "parts/fetchPartsListDelete",
  async (data, { rejectWithValue }) => {
    try {
      const company = localStorage.getItem("company");
      const associatedCorporateUnits = getCorporateUnitsByLoginType();
      let deletedBy = null;

      if (data?.userId) deletedBy = data.userId;
      const status = PARTS_LIBRARY_STATE.DELETED._id;
      const response = await findPartByCompany({
        company,
        status,
        associatedCorporateUnits,
        deletedBy,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// signatories

export const { resetSignatoriesListStatus } = partsSlice.actions;

export const { setSelectedSignatory } = partsSlice.actions;

export const getSelectedSignatory = (state) => state.parts.selectedSignatory;

export const getSignatoriesList = (state) => state.parts.signatoriesList;

export const getSignatoriesListStatus = (state) =>
  state.parts.signatoriesListStatus;

export const getSignatoriesListDelete = (state) =>
  state.parts.signatoriesListDelete;

export const getSignatoriesListStatusDelete = (state) =>
  state.parts.signatoriesListStatusDelete;

export const fetchSignatoriesList = createAsyncThunk(
  "parts/fetchSignatoriesList",
  async () => {
    const company = localStorage.getItem("company");
    const status = SIGNATORIES_STATE.ACTIVE._id;
    const associatedCorporateUnits = getCorporateUnitsByLoginType();

    const response = await findSignatoriesByCompany({
      company,
      status,
      associatedCorporateUnits,
    });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return {
        status: response.status,
        data: response.data,
      };
    }
  }
);

export const fetchSignatoriesListDelete = createAsyncThunk(
  "parts/fetchSignatoriesListDelete",
  async (data, { rejectWithValue }) => {
    try {
      const company = localStorage.getItem("company");
      const associatedCorporateUnits = getCorporateUnitsByLoginType();
      let deletedBy = null;

      if (data?.userId) deletedBy = data.userId;
      const status = SIGNATORIES_STATE.DELETED._id;
      const response = await findSignatoriesByCompany({
        company,
        status,
        associatedCorporateUnits,
        deletedBy,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return {
          status: response.status,
          data: response.data,
        };
      }
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

/**
 * Get the corporate units associated with the login type of the user logged in the system and the sub role.
 * 
 * @returns {Array} Array of corporate units associated with the user.
 */
function getCorporateUnitsByLoginType() {
  const {
    loginType,
    subRole,
    corporateUnits: defaultUC = [],
  } = JSON.parse(localStorage.getItem("payloadToken"));
  const corporateUnitId = localStorage.getItem("corporateUnitId");
  let associatedCorporateUnits = [];

  if (loginType === "ADMIN") {
    if (subRole !== "ADMIN PROPIETARIO") {
      const corporateUnits = localStorage.getItem("userCorporateUnits")
        ? JSON.parse(localStorage.getItem("userCorporateUnits"))
        : defaultUC;
      associatedCorporateUnits = corporateUnits?.map((uc) => uc?._id) ?? [];
    }
  } else {
    associatedCorporateUnits = [corporateUnitId];
  }

  return associatedCorporateUnits;
}

export default partsSlice.reducer;
