import React from "react";
import { Box } from "@mui/material";
import { Row, Spinner } from "react-bootstrap";

const LoadingCardIA = () => {
  return (<Box className="loading-content-card" sx={{ alignItems: "center" }}>
        <Row className="align-items-center justify-content-center">
            <Spinner
                animation="border"
                className="spinner__iaColor spinner-border-size"
            />
        </Row>
        <br />
        <Row className="align-items-center">
            <p className="label__description__primary__hover" style={{ textAlign: "center", fontWeight: "700", fontSize: "1rem" }}>
                Complementando revisión con nuestra IA…
            </p>
        </Row>
      </Box>);
};

export default LoadingCardIA;