import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { DialogTitleContainer } from "./DialogTitleContainer";

const ModalSelectDocumentType = ({
  onClose,
  open,
  handleAgree,
  options,
  validationMessage,
  cancelText,
  acceptText,
  title,
  disableOptionString,
}) => {
  const handleClose = () => {
    onClose();
    reset();
  };

  const {
    register,
    handleSubmit,
    watch,

    reset,
  } = useForm({
    defaultValues: {
      saveType: "",
    },
  });

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{title}</label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          <Row>
            <Col xs={12} className="mt-3">
              <Form.Select
                {...register("saveType", {
                  required: validationMessage,
                })}
                className="label mb-1"
                defaultValue=""
              >
                <option value="" disabled defaultValue className="label">
                  {disableOptionString}
                </option>
                {options.map((type) => {
                  return (
                    <option value={type._id} className="label">
                      {type.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                onClick={handleClose}
                className="custom-input__button__secondary-color__forced"
              >
                {cancelText}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleSubmit(handleAgree)}
                variant="contained"
                className="custom-input__button__primary-color__forced"
                disabled={!watch("saveType")}
              >
                {acceptText}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSelectDocumentType;
