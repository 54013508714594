import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";

const CustomSearchFilter = ({
  list,
  setFilteredData,
  placeholder = "Buscar",
  maxLength,
}) => {
  const filterSentence = (word) => {
    if (word) {
      const filterWord = word.toLowerCase();
      const filter = list?.filter((elem) => {
        const titleMatch = elem.title?.toLowerCase().includes(filterWord);
        const nameMatch = elem.name?.toLowerCase().includes(filterWord);
        const descriptionMatch = elem.description
          ?.toLowerCase()
          .includes(filterWord);
        return titleMatch || nameMatch || descriptionMatch;
      });
      setFilteredData(filter);
    } else {
      setFilteredData(list);
    }
  };

  return (
    <Container fluid>
      <Row>
        <InputGroup bsPrefix="input-group-container" style={{ padding: "8px" }}>
          <Form.Control
            bsPrefix="custom-input"
            placeholder={placeholder}
            onChange={(e) => filterSentence(e.target?.value)}
            maxLength={maxLength}
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
    </Container>
  );
};

export default CustomSearchFilter;
