import { AddBlankSpaceButton, BlankSpacesList } from "../ManageBlankSpaces";
import { useState } from "react";
import { PropTypes } from 'prop-types';

/**
 * useManageBlankSpaces is a custom hook to manage blank spaces.
 *
 * @param {Object} props Component props.
 * @param {Array<Object>} [props.currentBlankSpaces] List of blank spaces, by default is an empty array.
 * @param {(blankSpaces: Array<Object>) => void} [props.onAddBlankSpace] Function to execute when a blank space is added.
 * @param {(index: number, editedBlankSpace: Object) => void} [props.onEditBlankSpace] Function to execute when a blank space is edited.
 * @param {(index: number) => void} [props.onDeleteBlankSpace] Function to execute when a blank space is deleted.
 * @returns Components and functions to manage blank spaces.
 */
const useManageBlankSpaces = ({
  currentBlankSpaces = [],
  onAddBlankSpace = () => {},
  onEditBlankSpace = () => {},
  onDeleteBlankSpace = () => {},
}) => {
  /** States */
  const [blankSpaces, setBlankSpaces] = useState(currentBlankSpaces);

  /**
   * Generate a new id for a blank space based on the a list of blank spaces.
   * 
   * @param {Array} [currentBlankSpaces] List of blank spaces. If not provided, the hook will use the blankSpaces state.
   * @returns {number} New id for a blank space.
   */
  const generateBlankSpaceId = (currentBlankSpaces) => {
    let id = 1;
    const blankSpacesToCheck = currentBlankSpaces || blankSpaces;

    if (blankSpacesToCheck.length > 0) {
      id = blankSpacesToCheck[blankSpacesToCheck.length - 1].id + 1;
    }

    return id;
  };

  /**
   * Add an id property to a blank space if it doesn't have one.
   * 
   * If the validation fails, a warning message will be displayed in the console.
   * 
   * @param {Object} blankSpace Blank space to add the id property.
   * @param {Array<Object>} currentBlankSpaces List of blank spaces.
   * @returns {Object} Blank space with the id property.
   */
  const addIdProperty = (blankSpace, currentBlankSpaces) => {
    const messageLogger = {
      withIdProperty: () => {
        console.warn(
          `The blank space "${blankSpace.name}" already has an id property assigned (id: ${blankSpace.id})`
        );
      },
    };

    if (blankSpace.id) {
      messageLogger.withIdProperty();
      return blankSpace;
    }

    return {
      id: generateBlankSpaceId(currentBlankSpaces),
      ...blankSpace,
    };
  };

  /**
   * Add a new blank space to the list of blank spaces and execute the onAddBlankSpace function.
   */
  const addBlankSpace = () => {
    const blankSpaceId = generateBlankSpaceId();
    const defaultNewBlankSpace = {
      id: blankSpaceId,
      name: `ESPACIO #${blankSpaceId}`,
      value: undefined,
      description: undefined,
    };
    
    setBlankSpaces((prevBlankSpaces) => [
      ...prevBlankSpaces,
      defaultNewBlankSpace,
    ]);
    onAddBlankSpace(blankSpaces);
  };

  /**
   * Edit a blank space from the list of blank spaces and execute the onEditBlankSpace function.
   * 
   * If the validation fails, a warning message will be displayed in the console.
   * 
   * @param {Object} editedBlankSpace Edited blank space.
   * @param {number} index Index of the blank space in the list.
   */
  const editBlankSpace = (editedBlankSpace, index) => {
    const messageLogger = {
      notFoundBlankSpace: () => {
        console.warn(`Blank space with index "${index}" was not found`);
      },
    };
    const blankSpaceToEdit = blankSpaces[index];
    
    setBlankSpaces((prevBlankSpaces) => {
      if (!prevBlankSpaces[index]) {
        messageLogger.notFoundBlankSpace();
        return prevBlankSpaces;
      }
      const newBlankSpaces = [...prevBlankSpaces];

      newBlankSpaces[index] = {
        ...blankSpaceToEdit,
        ...editedBlankSpace,
      };

      return newBlankSpaces;
    });
    onEditBlankSpace(blankSpaceToEdit.id, editedBlankSpace);
  };

  /**
   * Delete a blank space from the list of blank spaces and execute the onDeleteBlankSpace function.
   * 
   * If the validation fails, a warning message will be displayed in the console.
   * 
   * @param {Object} blankSpace Blank space to delete.
   * @param {number} index Index of the blank space in the list.
   */
  const deleteBlankSpace = (blankSpace, index) => {
    const messageLogger = {
      notFoundBlankSpace: () => {
        console.warn(`Blank space with index "${index}" was not found`);
      },
    };
    
    setBlankSpaces((prevBlankSpaces) => {
      if (!prevBlankSpaces[index]) {
        messageLogger.notFoundBlankSpace();
        return prevBlankSpaces;
      }
      const newBlankSpaces = [...prevBlankSpaces];

      newBlankSpaces.splice(index, 1);

      return newBlankSpaces;
    });
    onDeleteBlankSpace(blankSpace.id);
  };

  /**
   * Delete a blank space from the list of blank spaces by its id.
   * 
   * If the validation fails, a warning message will be displayed in the console.
   * 
   * @param {number} blankSpaceId Id of the blank space to delete.
   */
  const deleteBlankSpaceById = (blankSpaceId) => {
    const messageLogger = {
      notFoundBlankSpaceWithId: () => {
        console.warn(`Blank space with id ${blankSpaceId} not found`);
      },
    };

    setBlankSpaces((prevBlankSpaces) => {
      const newBlankSpaces = prevBlankSpaces.filter(
        (space) => space.id !== blankSpaceId
      );

      if (prevBlankSpaces.length === newBlankSpaces.length) {
        messageLogger.notFoundBlankSpaceWithId();
        return prevBlankSpaces;
      }

      return newBlankSpaces;
    });
  };

  /**
   * Change the list of blank spaces.
   * 
   * If the validation fails, a warning message will be displayed in the console.
   * 
   * @param {Array<Object>} newBlankSpaces New list of blank spaces.
   * @returns {Array<Object>} New list of blank spaces with the id property.
   */
  const changeBlankSpaces = (newBlankSpaces) => {
    const messageLogger = {
      isNotArray: () => {
        console.warn("The new blank spaces are not an array");
      },
    };

    if (!Array.isArray(newBlankSpaces)) {
      messageLogger.isNotArray();
      return [];
    }
    const newBlankSpacesWithId = []

    for (const newBlankSpace of newBlankSpaces) {
      const blankSpaceWithId = addIdProperty(newBlankSpace, newBlankSpacesWithId);
      newBlankSpacesWithId.push(blankSpaceWithId);
    }

    setBlankSpaces(newBlankSpacesWithId);

    return newBlankSpacesWithId;
  };


  return {
    AddBlankSpaceButtonComponent: (
      <AddBlankSpaceButton onClick={addBlankSpace} />
    ),
    BlankSpaceListComponent: (
      <BlankSpacesList
        blankSpaces={blankSpaces}
        onEdit={editBlankSpace}
        onDelete={deleteBlankSpace}
      />
    ),
    blankSpaces,
    deleteBlankSpaceById,
    changeBlankSpaces,
  };
};

useManageBlankSpaces.propTypes = {
  currentBlankSpaces: PropTypes.arrayOf(PropTypes.object),
  onAddBlankSpace: PropTypes.func,
  onEditBlankSpace: PropTypes.func,
  onDeleteBlankSpace: PropTypes.func,
};

export default useManageBlankSpaces;
