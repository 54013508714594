import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
const token = localStorage.getItem("accessToken");

export function ShowDataExternalRequest(status, optionsFilterBar, filterMyId) {
  const subtypes = optionsFilterBar.find(
    (option) => option.title === "Solicitante"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Documento requerido")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const states = optionsFilterBar.find(
    (option) => option.title === "Estado de la solicitud"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Estado de la solicitud")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const applicantsIds = optionsFilterBar.find(
    (option) => option.title === "Documento requerido"
  )
    ? optionsFilterBar
        .find((option) => option.title === "Solicitante")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const attorneyId = filterMyId ? payloadToken.userId : null;
  return axios({
    method: "GET",
    url: `thirdPartyRequest/service-user/requests`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      state: status,
    },
    params: {
      companyId: payloadToken.company,
      applicantsIds: applicantsIds,
      states: states,
      subtypes: subtypes,
      attorneyId: attorneyId,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const reasignrequest = (data) => {
  return axios({
    method: "PUT",
    url: "thirdPartyRequest/reasignrequest",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};

export const getAttorneys = (data) => {
  return axios({
    method: "GET",
    url: `thirdPartyRequest/getattorneys/${data.companyId}`,
    params: {
      isApplied: true,
      corporateUnit: data.corporateUnit,
      consecutive: data.consecutive,
    },
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getApplicants = (data) => {
  return axios({
    method: "GET",
    url: `users/external-users/by/company/${data.company}`,
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: token,
    },
  });
};

export const changeRequestState = (data) => {
  const corporateUnit = localStorage.getItem("corporateUnitId");
  return axios({
    method: "PUT",
    url: `thirdPartyRequest/changeRequestState`,
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      requestId: data.requestId,
      state: data.state,
      returnMessage: data.message ? data.message : "",
      timeZone: data.timeZone,
      isActive: data.isActive,
      corporateUnit,
    },
    validateStatus: null,
  });
};

export function getFoldersServiceUser(data) {
  return axios({
    method: "GET",
    baseURL: env.REACT_APP_API_URL,
    url: `/businessFolder/find/all/${data.companyId}`,
    validateStatus: null,
  });
}

export function DownloadDocs(data) {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/get/document",
    baseURL: env.REACT_APP_API_URL,
    responseType: "blob", // IMPORTANT!
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: GetToken(),
    },
    params: {
      consecutive: data.consecutive,
      documentId: data.documentId,
      documentType: data.documentType,
      corporateUnit: data.corporateUnit,
      companyId: data.company,
    },
  });
}

export function DownloadDocsPanel(data) {
  return axios({
    method: "GET",
    url: "/signatures/sidePanel/document",
    baseURL: env.REACT_APP_API_URL,
    responseType: "blob", // IMPORTANT!
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: GetToken(),
    },
    params: {
      consecutive: data.consecutive,
      documentId: data.documentId,
      documentType: data.documentType,
      signatureId: data.signatureId,
      downloadType: data.downloadType,
      filenameInBucket: data.filenameInBucket,
      bucketName: data.bucketName,
    },
  });
}

export function DownloadDocsByURL(data) {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/get/file-url",
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
    params: {
      consecutive: data.consecutive,
      documentId: data.documentId,
      documentType: data.documentType,
      corporateUnit: data.corporateUnit,
      companyId: data.company,
    },
  });
}

export const AddRQTToBusinessFolders = (BFId, RQTId) => {
  return axios({
    method: "PUT",
    url: `businessFolder/addRequest`,
    baseURL: env.REACT_APP_API_URL,
    data: {
      businessFolderId: BFId,
      requestId: RQTId,
    },
  });
};

export function CreateComment(data) {
  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/createComment",
    baseURL: env.REACT_APP_API_URL,
    data: {
      requestId: data.requestId,
      commentMessage: data.commentMessage,
    },
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function SendInfoToApplicant(data) {
  const {
    companyId,
    consecutive,
    applicantId,
    otherDocuments,
    comment,
    principalDocument,
    attorneyName,
    applicantName,
    corporateUnit,
  } = data;

  const formData = new FormData();
  formData.append("companyId", companyId);
  formData.append("consecutive", consecutive);
  formData.append("applicantId", applicantId);
  formData.append("corporateUnit", corporateUnit);
  if (otherDocuments.length) {
    otherDocuments.map((doc) => {
      formData.append("otherAttorneyDocuments", doc);
    });
  }

  if (principalDocument) {
    formData.append("description", comment);
    formData.append("userRole", "SERVICE");
    formData.append("attorneyName", attorneyName);
    formData.append("applicantName", applicantName);
    formData.append("mainDocuments", principalDocument);
  }

  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/add/documents/",
    baseURL: env.REACT_APP_API_URL,
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function saveSignaturesRequest(data) {
  return axios({
    method: "POST",
    url: "signatures/save",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function SendPersonalDataToSign(data) {
  return axios({
    method: "PUT",
    url: `signatures/add/signatory/info/${data.requestId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function VerifyTokenToSign(data) {
  return axios({
    method: "GET",
    url: `/signatures/verify/signature/token`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      token: data.token,
      signatureId: data.signatureId,
    },
  });
}

export function VerifyTokenToSignPanel(data) {
  return axios({
    method: "GET",
    url: `/signatures/verify/signatureSidePanel/token`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      token: data.token,
      signatureId: data.signatureId,
    },
  });
}

export function ReturnSignWithComments(data) {
  return axios({
    method: "PUT",
    url: `/signatures/reject/signature/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      rejectionMessage: data.message,
    },
  });
}

export function ReturnSignWithCommentsSidePanel(data) {
  return axios({
    method: "PUT",
    url: `/signatures/reject/signatureSidePanel/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      rejectionMessage: data.message,
    },
  });
}

export function GenerateOTPCode(signatureId) {
  return axios({
    method: "POST",
    url: `/signatures/send/otp/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function GenerateOTPCodePanel(signatureId) {
  return axios({
    method: "POST",
    url: `/signatures/send/otpSidePanel/${signatureId}`,
    validateStatus: null,
    baseURL: env.REACT_APP_API_URL,
  });
}

export function ValidateOTPCode(data) {
  return axios({
    method: "PUT",
    url: `signatures/sign/document/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      otp: data.otp,
    },
  });
}

export function ValidateOTPCodePanel(data) {
  return axios({
    method: "PUT",
    url: `signatures/signSidePanel/document/${data.signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      otp: data.otp,
    },
  });
}

export function SaveRequestDocumentsInFolders(data) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/associate/docs/to/folder`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function TerminateRequest(data) {
  return axios({
    method: "PUT",
    url: `/thirdPartyRequest/addDocumentsToFolder`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function LimitPlanState({ signatureId, data }) {
  return axios({
    method: "PUT",
    url: `signatures/suspend/signature/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data,
  });
}

export function LimitPlanStateSidePanel({ signatureId }) {
  return axios({
    method: "PUT",
    url: `signatures/suspend/signatureSidePanel/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
  });
}

export function ReturnSigntureSidePanel({ signatureId, restartOTP }) {
  return axios({
    method: "PUT",
    url: `signatures/resume/signatureSidePanel/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      restartOTP: restartOTP,
    },
  });
}

export const deleteSignatureSidePanel = (signatureId) => {
  return axios({
    method: "DELETE",
    url: `signatures/deleteSignature`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      signatureId,
      signatureType: "lateralPanel",
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};
export const saveNewVersionToFolderFromRequest = (data) => {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/associate/doc/to/folder`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: data,
  });
};
