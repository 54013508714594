import { configureStore } from "@reduxjs/toolkit";
import companyUsersReducer from "../pages/views/admin/modules/manageUser/companyUsersSlice";
import currentUserReducer from "../pages/views/admin/modules/manageUser/currentUserSlice";
import unitCorporativeReducer from "../pages/views/admin/modules/unitCorporative/unitCorporativeSlice";
import alertReducer from "../pages/views/userService/modules/alert/alertSlice";
import clausesBulkReducer from "../pages/views/userService/modules/library/clauses/clauseBulkSlice";
import clauseReducer from "../pages/views/userService/modules/library/clauses/clauseSlice";
import contractTypeSliceReducer from "../pages/views/userService/modules/library/contractTypes/contractTypesSlice";
import templeteSliceReducer from "../pages/views/userService/modules/library/documentTemplates/templeteSlice";
import prederminatedTextsSliceReducer from "../pages/views/userService/modules/library/pretederminatedTexts/prederminatedTextsSlice";
import requestSliceReducer from "../pages/views/userService/modules/request/requestSlice";
import clauseCategoryReducer from "../parts/categories/clauseCategorySlice";
import currentDocumentReducer from "../parts/document/currentDocumentSlice";
import folderTagReducer from "../parts/folders/folderTagSlice";
import foldersReducer from "../parts/folders/foldersSlice";
import headersSliceReducer from "../parts/headings/headingsSlice";
import tagContractTypesSliceReducer from "../parts/manageTags/contractTypes/tagContractTypesSlice";
import tagDocumentTempletesSliceReducer from "../parts/manageTags/documentTempletes/tagDocumentTempletesSlice";
import tagSliceReducer from "../parts/manageTags/tagSlice";
import clausesSelectedSlice from "../parts/minutas/clausesSelectedSlice";
import minutasReducer from "../parts/minutas/minutasSlice";
import minutasTagReducer from "../parts/minutas/minutasTagSlice";
import partsReducer from "../parts/parts/partsSlice";
import signaturesSliceReducer from "../parts/signatures/signaturesSlice";
import storageSliceReducer from "../parts/storage/storageSlice";
import warningRoleReducer from "../parts/warnings/warningRoleStorage";
import otherDocumentSliceReducer from "../parts/document/otherDocumentSlice";
import chatSliceReducer from "../parts/chat/chatSlice";
import backgroundProcessSliceReducer from "../parts/backgroundProcess/backgroundProcessSlice";
import formCustomizationReducer from "../pages/views/admin/modules/unitCorporative/personalize/formCustomization/states/formCustomizationSlice"
import tagSliceFormsReducer from "../pages/views/admin/modules/unitCorporative/personalize/formCustomization/pages/manageCustomFormTags/states/tagSliceForms";
import otherDocumentTemplatesSliceReducer from "../pages/views/userService/modules/library/documentTemplates/templeteSlice";

export default configureStore({
  reducer: {
    companyUsers: companyUsersReducer,
    /* clauseTags: clauseTagReducer, */
    clauseCategories: clauseCategoryReducer,
    clauses: clauseReducer,
    folders: foldersReducer,
    folderTags: folderTagReducer,
    minutas: minutasReducer,
    minutasTags: minutasTagReducer,
    currentDocument: currentDocumentReducer,
    clausesSelected: clausesSelectedSlice,
    alerts: alertReducer,
    tags: tagSliceReducer,
    contractTypes: contractTypeSliceReducer,
    clausesBulk: clausesBulkReducer,
    unitsCoporatives: unitCorporativeReducer,
    currentUser: currentUserReducer,
    tagsContractTypes: tagContractTypesSliceReducer,
    requests: requestSliceReducer,
    signatures: signaturesSliceReducer,
    headers: headersSliceReducer,
    storage: storageSliceReducer,
    chat: chatSliceReducer,
    wrongRole: warningRoleReducer,
    parts: partsReducer,
    tagsDocumentTempletes: tagDocumentTempletesSliceReducer,
    prederminatedTexts: prederminatedTextsSliceReducer,
    templete: templeteSliceReducer,
    otherDocument: otherDocumentSliceReducer,
    backgroundProcess: backgroundProcessSliceReducer,
    formCustom: formCustomizationReducer,
    formCustomTags: tagSliceFormsReducer,
  },
});
