import { useEffect } from "react";
import { useInfiniteDataLoader } from "./useInfiniteDataLoader";
import { useScrollSpy } from "./useScrollSpy";
import PropTypes from "prop-types";

/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @enum {string}
 * @name ScrollPositions
 * @description The enum for the scroll positions
 */
const ScrollOptions = {
  TOP: "top",
  BOTTOM: "bottom",
};

/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @function
 * @name useInfiniteDataScroll
 * @description The hook for the infinite data scroll. To show all the data use rememberLastScrollPosition and keepPreviousData in true, to show only the new data use rememberLastScrollPosition and keepPreviousData in false
 * @param {object} props - The props of the hook
 * @param {{current: any}} props.elementRef - The ref of the element
 * @param {boolean} [props.rememberLastScrollPosition] - Whether to remember the last scroll position, default is true
 * @param {array} props.data - The data of the element
 * @param {boolean} [props.keepPreviousData] - Whether to keep previous data, default is true
 * @param {number} [props.elementsQtyToAdd] - The quantity of elements to be added, default is 20
 * @param {number} [props.previousElementsQtyToKeep] - The quantity of previous elements to keep, default is 0
 * @returns {{filteredData: filteredData, goToPageAndScroll: goToPageAndScroll}} An object with the following properties:
 * @property {array} filteredData - The filtered data
 * @property {function} goToPageAndScroll - The function to go to a specific page and scroll to a specific position
 * @throws {Error} If rememberLastScrollPosition and keepPreviousData are not the same
 * @example
 * const elementRef = useRef();
 * const data = Array.from({length: 50}).map((_, index) => `Item ${index}`);
 * const { filteredData } = useInfiniteDataScroll({
 *   elementRef,
 *   data,
 * });
 */
// TODO: Improve the performance @Roberto
export const useInfiniteDataScroll = ({
  elementRef,
  rememberLastScrollPosition = true,
  data,
  keepPreviousData = true,
  elementsQtyToAdd = 20,
  previousElementsQtyToKeep = 0,
}) => {
  if (rememberLastScrollPosition !== keepPreviousData) {
    throw new Error(
      "rememberLastScrollPosition and keepPreviousData must be the same"
    );
  }
  const { isNearToBottom, isNearToTop } = useScrollSpy({
    elementRef,
    rememberLastScrollPosition,
  });
  const { filteredData, loadFilteredData, isInInitialData, isInLastData, goToPage } =
    useInfiniteDataLoader({
      data,
      keepPreviousData,
      elementsQtyToAdd,
      previousElementsQtyToKeep,
    });

  const goToPageAndScroll = (page, scrollTo) => {
    if (!Object.values(ScrollOptions).includes(scrollTo)) {
      throw new Error(`scrollTo must be one of ${Object.keys(ScrollOptions).join(", ")}`);
    }
    goToPage(page);
    setTimeout(() => {
      // TODO: Use a better solution for scroll to last page @Roberto
      const { clientHeight, scrollHeight } = elementRef.current;
      elementRef.current.scrollTop = scrollTo === ScrollOptions.TOP ? 2 : scrollHeight - clientHeight - 2;
    }, 100)
  }

  useEffect(() => {
    const next = isNearToBottom ? 1 : 0;
    const previous = isNearToTop ? -1 : 0;
    let previousOrNext = 0;
    if (next) previousOrNext = next;
    else if (previous) previousOrNext = previous;
    if (previousOrNext !== 0) {
      loadFilteredData(previousOrNext);
    }
    if (!keepPreviousData && !isInInitialData && isNearToTop) {
      const { clientHeight, scrollHeight } = elementRef.current;
      elementRef.current.scrollTop = scrollHeight - clientHeight - 2;
    }
    if (!keepPreviousData && !isInLastData && isNearToBottom) {
      elementRef.current.scrollTop = 2;
    }
  }, [isNearToBottom, isNearToTop, elementRef, data, keepPreviousData]);

  return {
    filteredData,
    goToPageAndScroll,
  };
};

useInfiniteDataScroll.propTypes = {
  props: PropTypes.shape({
    elementRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    rememberLastScrollPosition: PropTypes.bool,
    data: PropTypes.array.isRequired,
    keepPreviousData: PropTypes.bool,
    elementsQtyToAdd: PropTypes.number,
    previousElementsQtyToKeep: PropTypes.number,
  }),
};
