import { useState } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import Button from "@mui/material/Button";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import AddIcon from "@mui/icons-material/Add";
import uuid from "react-uuid";
import PropTypes from "prop-types";
import { QuestionFormTypes } from "../../../../../../../../../../services/documents/formCustomization/constants/questionFormTypes";

/**
 * Menu button to add a question to the form
 *
 * @param {object} props Component props
 * @param {Function} handleAddQuestion Function to add a question to the form
 * @param {string} [className] Class name of the component, default is an empty string
 * @param {string} [btnMessage] Message of the button, default is "Agregar pregunta"
 * @returns The TypeInputMenuButton component
 */
export const TypeInputMenuButton = ({ handleAddQuestion, className = "", btnMessage = "Agregar pregunta" }) => {
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);

  const OPTIONS_QUESTIONS = [
    {
      id: 1,
      icon: (
        <FormatColorTextIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      name: "Respuesta corta",
      action: () =>
        handleAddQuestion({
          _id: uuid(),
          options: [],
          label: "",
          placeholder: "",
          type: "input",
          value: "Pregunta",
          validations: {
            required: {
              value: true,
              message: "*Este campo es obligatorio",
            },
            maxLength: {
              message: "Máximo 250 caracteres permitidos",
              value: 250,
            },
          },
        }),
    },
    {
      id: 2,
      icon: (
        <FormatColorTextIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      name: "Respuesta larga",
      action: () =>
        handleAddQuestion({
          _id: uuid(),
          options: [],
          label: "",
          placeholder: "",
          type: QuestionFormTypes.INPUT,
          value: "Pregunta",
          validations: {
            required: {
              value: true,
              message: "*Este campo es obligatorio",
            },
            maxLength: {
              message: "Máximo 1000 caracteres permitidos",
              value: 1000,
            },
          },
        }),
    },
    {
      id: 3,
      icon: (
        <CalendarTodayOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      name: "Fecha",
      action: () =>
        handleAddQuestion({
          _id: uuid(),
          options: [],
          label: "",
          placeholder: "",
          type: QuestionFormTypes.DATE,
          value: "Pregunta",
          validations: {
            required: {
              value: true,
              message: "*Este campo es obligatorio",
            },
          },
        }),
    },
    {
      id: 4,
      icon: (
        <ChecklistOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      name: "Selección multiple",
      action: () =>
        handleAddQuestion({
          _id: uuid(),
          options: ["Opción 1"],
          label: "",
          placeholder: "",
          type: QuestionFormTypes.LIST_CHECKBOX,
          value: "Pregunta",
          validations: {
            required: {
              value: true,
              message: "*Este campo es obligatorio",
            },
          },
        }),
    },
    {
      id: 5,
      icon: (
        <SegmentOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
      name: "Menú desplegable",
      action: () =>
        handleAddQuestion({
          _id: uuid(),
          options: ["Opción 1"],
          label: "",
          placeholder: "",
          type: QuestionFormTypes.LIST,
          value: "Pregunta",
          validations: {
            required: {
              value: true,
              message: "*Este campo es obligatorio",
            },
          },
        }),
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        className={`custom-input__button__secondary-color ${className}`}
        onClick={(event) => setAnchorElCreate(event.currentTarget)}
        style={{
          whiteSpace: "nowrap",
          width: "fit-content",
        }}
      >
        {btnMessage}
      </Button>
      <Menu
        className="ml-4"
        anchorEl={anchorElCreate}
        open={openCreateMenu}
        onClose={() => setAnchorElCreate(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {OPTIONS_QUESTIONS.map((question) => (
          <MenuItem
            key={question.id}
            onClick={() => {
              question.action();
              setAnchorElCreate(null);
            }}
          >
            <ListItemIcon>{question.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={question.name}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

TypeInputMenuButton.propTypes = {
  handleAddQuestion: PropTypes.func.isRequired,
  className: PropTypes.string,
  btnMessage: PropTypes.string,
};
