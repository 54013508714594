import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export const getAllSignaturesDetails = (data) => {
  return axios({
    method: "GET",
    url: "signatures/getAllSignaturesSidePanel",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      companyId: data.company,
      corporateUnitId: data.corporateUnit,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const saveSignatureSidePanel = (data) => {
  return axios({
    method: "POST",
    url: "signatures/saveSidePanel",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const SendPersonalDataToSignPanel = (data) => {
  return axios({
    method: "PUT",
    url: `signatures/add/signatory/infoSidePanel/${data.requestId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: data,
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const getSignatureById = (id) => {
  return axios({
    method: "GET",
    url: "signatures/getSignatureById",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: { id },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const findAllSignaturesByConsecutive = ({
  consecutive,
  companyId,
  corporateUnitId,
}) => {
  return axios({
    method: "GET",
    url: "signatures/findAllSignaturesByConsecutive",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: { consecutive, companyId, corporateUnitId },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function ReturnSignture({ signatureId, restartOTP }) {
  return axios({
    method: "PUT",
    url: `signatures/resume/signature/${signatureId}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: {
      restartOTP: restartOTP,
    },
  });
}

export const deleteSignature = (signatureId) => {
  return axios({
    method: "DELETE",
    url: `signatures/deleteSignature`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    data: {
      signatureId,
      signatureType: "request",
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export const updateEmailSignaturesSidePanel = ({ signatureId, signatoryId, newEmail }) => {
  return axios({
    method: "PUT",
    url: `signatures/updateSidePanelSignature`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { signatureId, signatoryId, newEmail },
  });
};

export const updateEmailSignaturesRequest = ({ signatureId, signatoryId, newEmail }) => {
  return axios({
    method: "PUT",
    url: `signatures/updateRequestSignature`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    data: { signatureId, signatoryId, newEmail },
  });
};

