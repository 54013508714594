import createYupSchemaBasedOnInputsStructure from "../../../../../utils/createYupSchemaBasedOnInputsStructure";
import SectionFormInputs from "../constants/sectionFormInputs";

/**
 * The schema for the section form, based on the inputs structure `SectionFormInputs`.
 * 
 * @constant
 */
const SectionSchema = createYupSchemaBasedOnInputsStructure(SectionFormInputs);

export default SectionSchema;
