import axios from "axios";
import GetToken from "../../../utils/getToken";
import { env } from "../../../env";

export function getRequestTypes(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getRequestTypes/`,
    params: {
      companyId: data.companyId,
      corporateUnitsIds: data.corporateUnitsIds,
      onlyCustomRequests: data.onlyCustomRequests,
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
}

export function getCustomRequestType(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getCustomRequestType/`,
    params: {
      companyId: data.companyId,
      formId: data.formId,
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
}

export function updateCustomForm(data) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/updatedCustomRequestType`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateCorporateUnitsOnRequestType({
  formId,
  company,
  corporateUnitsId,
}) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/updateCorporateUnitsOnRequestType`,
    data: {
      formId,
      companyId: company,
      corporateUnitsId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function resetRequestTypeForm({ formId, companyId, originalFormId }) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/resetRequestTypeForm`,
    data: {
      formId,
      companyId,
      originalFormId,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
/**
 * Add a new custom request type to the company
 *
 * @param {AddNewCustomRequestTypeDto} data - The data to add the new custom request type.
 * @returns {Promise<Object>} The response of the request.
 */
export function addNewCustomRequestType(data) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/addNewCustomRequestType`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function getFormsDelete(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getDeletedRequestTypes`,
    params: data,
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
}

export function deletePermanentlyForm(data) {
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/deleteCustomRequestType`,
    data: {
      originalFormId: data?.originalFormId,
      formId: data?.formId,
      companyId: data?.companyId,
      corporateUnitId: data?.corporateUnitId    
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
};

export function deleteTemporalForm(data) {
  const ID_STATUS_DELETE = "621fdcd7e1b7dfa85f10bf46";
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/updateDeleteCustomRequestType`,
    data: {
      formId: data?.formId,
      modifiedBy: data?.modifiedBy,
      state: ID_STATUS_DELETE
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
};

export function restoreTemporalFormDelete(data) {
  const ID_STATUS_ACTIVE = "621fdce7e1b7dfa85f10bf48";
  return axios({
    method: "POST",
    url: `/thirdPartyRequest/updateDeleteCustomRequestType`,
    data: {
      formId: data?.formId,
      modifiedBy: data?.modifiedBy,
      state: ID_STATUS_ACTIVE
    },
    baseURL: env.REACT_APP_API_URL,
    headers: { Authorization: GetToken() },
    validateStatus: null,
  });
};

