import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { MDBDataTableV5 } from "mdbreact";
import React, { useState, useEffect, useRef } from "react";
import PopoverActions from "../Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Checkbox from "@mui/material/Checkbox";
import ModalInfo from "./modalInfo";
import ModalDesicion from "./modalDecision";
import { CODES } from "../../utils/codes";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalAssignParts = ({
  title,
  message,
  agreeText,
  disagreeText,
  handleAgree,
  handleDisagree,
  onClose,
  open,
  type,
  partsList,
  setAssociatedParts,
  associatedParts,
  create,
}) => {
  const [data, setData] = useState({ rows: [], columns: [] });
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Seleccionar
        </label>,
      ],
      field: "checkbox",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Nombre
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Id
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Unidades corporativas
        </label>,
      ],
      field: "corporateUnits",
    },
  ];

  const containerRef = useRef(null);
  // Functions
  const handleChecked = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedParts([...selectedParts, item]);
    } else {
      setSelectedParts(selectedParts.filter((itm) => itm?._id !== item?._id));
    }
  };

  const getName = (item) => {
    return `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
  };
  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      let contact = `${item?.email || ""}`;
      let address = item?.physicalAddress;
      let country = item?.country;
      let city = `${item?.province || ""}, ${item?.city || ""}`;
      let number = `+${item?.countryCodeNumber || ""} ${item?.phone || ""}`;
      if (item?.typePart === "JURIDICA") {
        name = item?.business?.businessName;
        id = `Nit. ${item?.business?.nit}`;
      }
      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;
      formatedData.push({
        checkbox: (
          <Row>
            <Col md={"auto"}>
              <Checkbox
                checked={selectedParts?.some((itm) => itm?._id === item?._id)}
                onClick={(e) => handleChecked(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 28 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        name,
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  containerRef={containerRef}
                  parentId="signatories-content"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),
        corporateUnits: (
          <Row style={{ rowGap: 5, alignItems: "center" }}>
            {item?.associatedCorporateUnits?.length > 0 &&
              item.associatedCorporateUnits.map(({ _id, name }) => (
                <Col key={_id} xs={12} lg={6}>
                  <span
                    style={{ display: "inline-block" }}
                    className="caption custom-badges__secundary"
                  >
                    {name}
                  </span>
                </Col>
              ))}
          </Row>
        ),
        ...item,
      });
    }
    return formatedData;
  };

  // Use Effect
  useEffect(() => {
    const transformData = buildData(partsList);
    setData({ rows: transformData, columns });
  }, [selectedParts, partsList, associatedParts]);

  useEffect(() => {
    setSelectedParts(associatedParts.map((item) => item.part));
  }, [associatedParts, open]);

  // Handles
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          {!type && <label className="heading__primary-color">{title}</label>}

          {type === "warning" && (
            <label className="heading__warning-color">
              <ErrorOutlineIcon fontSize="large" />
              {"   "}
              {title}
            </label>
          )}
        </DialogTitleContainer>
        <DialogContent
          className="caption"
          dividers
          id="signatories-content"
          ref={containerRef}
        >
          {message}
          <Row className="dt-list-center-content">
            {
              <MDBDataTableV5
                noRecordsFoundLabel={
                  !data?.rows?.length
                    ? "Aún no tienes partes creadas"
                    : "No se encontraron resultados para tu búsqueda"
                }
                hover
                pagingTop
                searchBottom={false}
                entries={10}
                data={data}
                infoLabel={["Mostrando", "a", "de", "partes"]}
                fullPagination
              />
            }
          </Row>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              {disagreeText && (
                <Button
                  variant="text"
                  className="custom-input__button__secondary-color__forced"
                  onClick={() => setIsOpenModalDecision(true)}
                >
                  {disagreeText}
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__primary-color__forced"
                onClick={() => {
                  if (create) {
                    setAssociatedParts((prevData) => {
                      const newSelectedParts = selectedParts?.filter(
                        (itm) =>
                          !prevData?.some((item) => item?._id === itm?._id)
                      );
                      return [
                        ...prevData,
                        ...newSelectedParts.map((itm) => ({ _id: itm._id })),
                      ];
                    });
                  } else {
                    setAssociatedParts((prevData) => {
                      const newSelectedParts = selectedParts?.filter(
                        (itm) =>
                          !prevData?.some((item) => item.part?._id === itm?._id)
                      );

                      return [
                        ...prevData,
                        ...newSelectedParts
                          .map((itm) => ({ part: itm, position: "" }))
                          .filter((itm) => itm?.part),
                      ];
                    });
                  }

                  setSelectedParts([]);
                  setIsOpenModalInfo(true);
                }}
              >
                {agreeText}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>

      <ModalDesicion
        open={isOpenModalDecision}
        message={
          "¿Estás seguro que deseas cancelar? los cambios realizados se perderán"
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        title={"Cancelar"}
        type={"warning"}
        onClose={() => setIsOpenModalDecision(false)}
        handleDisagree={() => setIsOpenModalDecision(false)}
        handleAgree={() => {
          setSelectedParts([]);
          setIsOpenModalDecision(false);
          handleDisagree();
        }}
      />
      <ModalInfo
        open={isOpenModalInfo}
        title={"Cambios guardados"}
        responseData={{
          status: CODES.COD_RESPONSE_HTTP_OK,
          data: { message: "Parte vinculada con éxito." },
        }}
        buttonClose={true}
        onClose={() => {
          setIsOpenModalInfo(false);
          handleAgree();
        }}
      />
    </div>
  );
};

export default ModalAssignParts;
