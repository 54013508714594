import React, { useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SlidingLoadScreens from "../components/SlidingLoadScreens";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Robotinfo from "../assets/svg/robotInfo.svg";
import { useState } from "react";
import {
  setUserLeftBackgroundProcess,
  updateListBackgroundProcesses,
} from "../parts/backgroundProcess/backgroundProcessSlice";
import { useDispatch } from "react-redux";
import juezInfo from "../assets/svg/juezInfo.svg";
export const LoadingContentInBackground = ({
  open,
  setIsOpen,
  actions,
  moduleName,
  processId,
}) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  const MAX_PROGRESS = 99;
  const PROGRESS_INCREMENT_SMALL = 0.1;
  const PROGRESS_INCREMENT_LARGE = 2.5;
  const UPDATE_INTERVAL = 1000;
  const data = [
    {
      title: "¿Sabías Que?",
      label:
        "En nuestro módulo de revisión puedes extraer información relevante de tus documentos, como las partes involucradas, fechas importantes, valores, referencias normativas y consejos y todo esto, gracias a la Inteligencia Artificial (IA).",
    },
    {
      title: "¿Sabías Que?",
      label:
        "En Legal AI puedes generar resúmenes e informes de contratos, resaltando los puntos más relevantes para ahorrar tiempo y facilitar su comprensión.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "Legal AI es una plataforma capaz de alertar a los usuarios sobre cláusulas que suelen ser importantes en los contratos, con ayuda de la Inteligencia Artificial podrás identificarlas para que puedas priorizar su eliminación o negociación antes de la firma del contrato.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "En Legal AI puedes centralizar tus solicitudes de contratos, si no lo sabías te invitamos a usar nuestro módulo de solicitudes, el cual te permitirá tener una trazabilidad y gestionar todo el ciclo de vida del contrato de manera muchas más eficiente.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "En Legal AI tenemos una herramienta con la que puedes identificar expresiones que pueden comprometer al representante legal de la empresa en los formularios SAGRILAFT.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "En Legal AI disponemos de un módulo de indicadores estratégicos que te proporciona datos claves sobre el rendimiento y la gestión de tus documentos, está diseñado para que tomes decisiones informadas y alcances con éxito tus metas.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "La firma realizada en Legal AI tiene el mismo valor legal que una firma manuscrita en Colombia.\n\nSe trata de una firma electrónica que cumple con los requisitos de la Ley 527 de 1999 y el Decreto 2364 del 2012: integridad, autenticidad y no repudio. ",
    },
    {
      title: "¿Sabías Que?",
      label:
        "La Inteligencia Artificial usada en Legal AI puede clasificar y agrupar automáticamente las cláusulas de tus contratos en distintas categorías, lo que facilita la creación y revisión de documentos de una forma más estratégica.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "En Legal AI puedes extraer automáticamente las cláusulas de los contratos para que las puedas utilizar en la creación de nuevos documentos.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "Legal AI cuenta con una estructura de almacenamiento seguro y centralizado, respaldado por los servicios de AWS.",
    },
    {
      title: "¿Sabías Que?",
      label:
        "Legal AI puede procesar documentos escaneados mediante su tecnología de reconocimiento óptico de caracteres (OCR). En todo caso, por las limitaciones inherentes a esta tecnología, obtendrás mejores resultados cargando documentos en formato PDF con una capa de texto nativo, o incluso en Word.",
    },
  ];

  const updateProgress = (prevProgress) => {
    if (prevProgress === MAX_PROGRESS) return MAX_PROGRESS;
    if (prevProgress >= 90) return prevProgress + PROGRESS_INCREMENT_SMALL;
    return prevProgress + PROGRESS_INCREMENT_LARGE;
  };

  useEffect(() => {
    if (open) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => updateProgress(prevProgress));
      }, UPDATE_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    }
  }, [open]);

  const handleAgree = () => {
    setIsOpen(false);
    dispatch(
      updateListBackgroundProcesses({
        type: "UPDATE",
        id: processId,
        module: moduleName,
        updateData: {
          userLeft: true,
        },
      })
    );
  };
  const handleDisagree = () => {
    setIsWaiting(true);
  };

  useEffect(() => {
    if (open) {
      setUserLeftBackgroundProcess({
        module: moduleName,
        value: false,
      });
    }
  }, [open]);

  return (
    <div className="loading-content loading-content--spinner">
      <Dialog style={{ marginLeft: 100 }} open={open} maxWidth={"xs"}>
        <DialogTitle>
          <label className="heading__primary-color">
            Procesando información...
          </label>
        </DialogTitle>
        <DialogContent
          className="caption"
          dividers
          sx={{ textAlign: "center" }}
        >
          {isWaiting && (
            <>
              <Row>
                <img src={juezInfo} alt="Robot Info" className="imageUpload" />
              </Row>
              <Row>
                <SlidingLoadScreens data={data} />
              </Row>
              <br />
              <Row>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#00374F",
                      },
                    }}
                    style={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "#F0F0F7",
                    }}
                  />
                </Box>
              </Row>
            </>
          )}
          {!isWaiting && (
            <>
              <Row>
                <img src={Robotinfo} alt="Robot Info" className="imageUpload" />
              </Row>
              <Row>
                <label className="caption text-aline-justify">
                  Esto podría tardar unos segundos más. ¿Quieres seguir usando
                  otras funcionalidades mientras esperas, y te avisamos cuando
                  esto esté listo?
                </label>
              </Row>
              <br />
              <Row>
                <Box>
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#00374F",
                      },
                    }}
                    style={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "#F0F0F7",
                    }}
                  />
                </Box>
              </Row>
            </>
          )}
          {!isWaiting && actions && (
            <Row className="mt-3 justify-content-center">
              <Col xs={"auto"}>
                <Button
                  variant="text"
                  className="custom-input__button__secondary-color"
                  onClick={handleDisagree}
                >
                  Esperar
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={handleAgree}
                >
                  Avísame
                </Button>
              </Col>
            </Row>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingContentInBackground;
