import React, { useRef, useEffect, createRef, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SortIcon from "@mui/icons-material/Sort";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCurrentDocument,
  editHeader,
} from "../../../../../../parts/document/currentDocumentSlice";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RichEditorText from "../../../../../../parts/RichEditorText";
import { REGEXP } from "../../../../../../utils/regexp";
import BlockerPage from "../../../../../../components/blocker/blocker";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import { ImportPredeterminedText } from "../../../../../../parts/associations/ImportPredeterminedText";
import AddIcon from "@mui/icons-material/Add";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
const EditHeader = () => {
  //hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDocument = useSelector(getCurrentDocument);
  const richEditorTextRef = useRef();
  const richSubHeaderEditorTextRef = useRef([]);
  const { id } = useParams();
  const [blockNavigate, setBlockNavigate] = useState(true);
  const [indexEnableRol, setIndexEnableRol] = useState(null);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(null);
  const [isOpenModalImportText, setIsOpenModalImportText] = useState(false);
  const [isOpenModalImportTextToHeader, setIsOpenModalImportTextToHeader] =
    useState(false);

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    text: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
    childs: yup.array().of(
      yup.object().shape({
        text: yup.string(),
      })
    ),
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isNumberingAvaliable: !location.pathname.includes("add-header-without"),
    },
    resolver: yupResolver(schema),
  });
  const {
    fields: childFields,
    append: childAppend,
    remove: childRemove,
    move: childMove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "childs", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  //handle
  const handleDeleteText = () => {
    setValue("text", "");
    richEditorTextRef.current.setEditorContents("");
  };
  const handleAddHeader = (data) => {
    setBlockNavigate(false);
    dispatch(editHeader({ index: id, header: data }));
    navigate(-1);
  };
  const buildChilds = (childs) => {
    let childArray = [];
    for (const [index, child] of childs.entries()) {
      let obj = { ...child, isImported: true };
      childArray.push(obj);
      richSubHeaderEditorTextRef.current[index] =
        richSubHeaderEditorTextRef.current[index] ?? createRef();
    }
    return childArray;
  };
  const buildTextHeader = (text) => {
    try {
      if (richEditorTextRef.current) {
        if (typeof richEditorTextRef.current.setEditorContents === "function") {
          richEditorTextRef.current.setEditorContents(text);
        } else {
          console.error(
            "Method setContents is not available on the editor instance."
          );
        }
      }

      return text;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewHeader = (data) => {
    let item = {
      text: "",
      isImported: true,
    };
    const index = childFields.length;
    richSubHeaderEditorTextRef.current[index] = createRef();
    childAppend(item);

    setIsOpenModalDecision(false);
  };

  const handleAddBlockMainToHeader = async (selectedTextList) => {
    let concatText = "";
    selectedTextList.forEach(async (predeterminedText) => {
      concatText += predeterminedText.text;
    });

    setValue("text", concatText);
    richEditorTextRef.current.addText(concatText);
  };

  const handleAddBlockToHeader = async (selectedTextList) => {
    selectedTextList.forEach(async (predeterminedText) => {
      let item = {
        text: predeterminedText.text,
        isImported: true,
      };
      const index = childFields.length;
      richSubHeaderEditorTextRef.current[index] = createRef();

      childAppend(item);
    });
  };

  //UseEffect

  useEffect(() => {
    richSubHeaderEditorTextRef.current = childFields.map(
      (_, idx) => richSubHeaderEditorTextRef.current[idx] ?? createRef()
    );
    getValues("childs").forEach((child, index) => {
      if (child.isImported) {
        if (richSubHeaderEditorTextRef.current[index]) {
          richSubHeaderEditorTextRef.current[index].current?.setEditorContents(
            child.text
          );
        }
        setValue(`childs.${index}`, { text: child.text, isImported: false });
      }
    });
    //aqui set content
  }, [childFields]);

  useEffect(() => {
    reset({
      title: currentDocument?.headers[id]?.title,
      text: buildTextHeader(currentDocument?.headers[id]?.text),
      childs: buildChilds(currentDocument?.headers[id]?.childs),
    });
  }, []);

  useEffect(() => {
    if (childFields.length) {
      setIndexEnableRol(null);
      richSubHeaderEditorTextRef.current.map((item) => item.current.setBlur());
    }
  }, [childFields]);

  return (
    <Container fluid className="custom-container-scroll">
      <BlockerPage
        blockNavigate={blockNavigate}
        message={
          "¿Estás seguro de que quieres salir? Perderás los datos que no hayas guardado."
        }
      />
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }}>
        <Col md={2} className="col-start">
          <SectionTitle title="Antecedentes o consideraciones" />
        </Col>
        <Col md={9}></Col>
      </Row>
      <Form onSubmit={handleSubmit(handleAddHeader)}>
        <Row className="heading">Título del encabezado</Row>
        <Row style={{ margin: "2% 0" }}>
          <Col md={5}>
            <Form.Group className="mb-3">
              <Form.Control
                {...register("title")}
                placeholder="Ej. Antecedentes"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.title?.message}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row
          className="heading"
          style={{ marginBottom: "2%", rowGap: "10px", alignItems: "center" }}
        >
          <Col xs={"auto"}>Texto del encabezado</Col>
          <Col />
          {currentDocument?.parts?.length > 0 &&
            currentDocument.parts.map((part, index) => (
              <Col xs={"auto"} key={part?._id}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    richEditorTextRef.current.addPart(part?.name, index);
                  }}
                >
                  {part?.name || ""}
                </Button>
              </Col>
            ))}
        </Row>
        <Row style={{ margin: "2% 0" }}>
          <Col md={10}>
            <Controller
              control={control}
              name="text"
              render={({ field: { onChange, onBlur } }) => (
                <RichEditorText
                  ref={richEditorTextRef}
                  onBlur={onBlur}
                  onChange={onChange}
                  customClassName={
                    errors.text
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                  placeholder={"Agrega aquí el texto del encabezado"}
                />
              )}
            />
            <div className="caption custom-input__error">
              {errors.text?.message}
            </div>
          </Col>

          <Col md={2}>
            <Row>
              <Col xs={12}>
                <Button
                  variant="contained"
                  className="custom-input__button__primary-color"
                  onClick={handleDeleteText}
                >
                  <DeleteOutlinedIcon fontSize="large" />
                </Button>
              </Col>
              <Col xs={12} className="mt-4">
                <Button
                  variant="contained"
                  startIcon={<ImportExportOutlinedIcon />}
                  className="custom-input__button__primary-color"
                  size="medium"
                  onClick={() => setIsOpenModalImportTextToHeader(true)}
                >
                  Importar Bloque
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <DragDropContext
          onDragEnd={(result) => {
            const { source, destination } = result;
            childMove(source.index, destination.index);
          }}
        >
          <Droppable droppableId={"droppable-headers"}>
            {(droppableProvided) => (
              <Col
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {childFields?.map((child, index) => (
                  <Draggable
                    key={`${child.id}-draggable`}
                    draggableId={`${child.id}-draggable`}
                    index={index}
                  >
                    {(draggablePorvider) => (
                      <div
                        {...draggablePorvider.draggableProps}
                        {...draggablePorvider.dragHandleProps}
                        ref={draggablePorvider.innerRef}
                      >
                        <Container
                          fluid
                          style={{ padding: "0px 44px 0px 44px" }}
                          key={child.id}
                        >
                          <Row
                            className="heading"
                            style={{ margin: "1% 0", alignItems: "center" }}
                          >
                            <Col xs="auto">
                              <DragIndicatorIcon
                                fontSize="large"
                                className="drag-color"
                              />
                            </Col>
                            <Col xs="auto">
                              <span className="heading">{`${index + 1}.`}</span>
                            </Col>
                            <Col />
                            {currentDocument?.parts?.length > 0 &&
                              currentDocument.parts.map((part, indexPart) => (
                                <Col xs={"auto"}>
                                  <Button
                                    disabled={indexEnableRol !== child.id}
                                    variant="contained"
                                    className="custom-input__button__secondary-color"
                                    onClick={() => {
                                      richSubHeaderEditorTextRef.current[
                                        index
                                      ].current.addPart(part?.name, indexPart);
                                    }}
                                  >
                                    {part?.name || ""}
                                  </Button>
                                </Col>
                              ))}
                          </Row>
                          <Row>
                            <Col>
                              <Controller
                                control={control}
                                name={`childs.${index}.text`}
                                render={({ field: { onChange, onBlur } }) => (
                                  <RichEditorText
                                    ref={
                                      richSubHeaderEditorTextRef.current[index]
                                    }
                                    onBlur={onBlur}
                                    onFocus={() => setIndexEnableRol(child.id)}
                                    onChange={onChange}
                                    customClassName={
                                      "input-group-container__no-icon label"
                                    }
                                    placeholder={
                                      "Agrega aquí el texto del numeral"
                                    }
                                  />
                                )}
                              />
                              <div className="caption custom-input__error">
                                {errors?.childs}
                              </div>
                            </Col>
                            <Col xs={"auto"}>
                              <Button
                                variant="contained"
                                className="custom-input__button__primary-color"
                                onClick={() => {
                                  childRemove(index);
                                }}
                              >
                                <DeleteOutlinedIcon fontSize="large" />
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Col>
            )}
          </Droppable>
        </DragDropContext>
        {!location.pathname.includes("add-header-without") && (
          <Row className="justify-content-md-end" style={{ margin: "1% 0" }}>
            <Col md={2}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                startIcon={<SortIcon />}
                onClick={() => {
                  setIsOpenModalDecision(true);
                }}
              >
                Agregar nivel
              </Button>
            </Col>
          </Row>
        )}
        <br />
        <br />
        <br />
        <Row className="sidebar__bottom__container">
          <Col md={1}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                navigate(-1);
              }}
            >
              Descartar
            </Button>
          </Col>
          <Col md={1}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalDecision
        title={"Agregar bloque"}
        message={"¿Quieres importar o crear un nuevo nivel?"}
        open={isOpenModalDecision}
        onClose={() => setIsOpenModalDecision(false)}
        agreeText="Crear"
        disagreeText="Importar"
        handleAgree={handleAddNewHeader}
        handleDisagree={() => {
          setIsOpenModalDecision(false);
          setIsOpenModalImportText(true);
        }}
        agreeIconStart={<ImportExportOutlinedIcon />}
        disagreeIconStart={<AddIcon />}
      />
      <ImportPredeterminedText
        show={isOpenModalImportText}
        setShow={setIsOpenModalImportText}
        mixPanelTrack="Crear Btn Importar Texto Predeterminado"
        onSelect={handleAddBlockToHeader}
      />
      <ImportPredeterminedText
        show={isOpenModalImportTextToHeader}
        setShow={setIsOpenModalImportTextToHeader}
        mixPanelTrack="Crear antecedentes Importar Texto Predeterminado"
        onSelect={handleAddBlockMainToHeader}
      />
    </Container>
  );
};

export default EditHeader;
