import React from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
const CustomSearch = ({
  dataTableInfo,
  setFilteredDataTable,
  placeholder = "Buscar",
  maxLength,
  setFilteredWord,
  characterLimit = Infinity,
}) => {
  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[\W_]+/g, "")
      .toLowerCase();
  };

  function getTextContent(element) {
    if (!element) return "";
    if (typeof element === "string") {
      return normalizeText(element);
    }

    if (Array.isArray(element)) {
      return element.map(getTextContent).join("");
    }

    if (element.props && element.props.children) {
      if (element.type === "button") {
        return "";
      }
      return getTextContent(element.props.children);
    }

    return "";
  }
  const isElementMatchWord = ({ element, word }) => {
    const normalizedWord = normalizeText(word);
    if (typeof element !== "string") {
      const elementText = getTextContent(element);
      return elementText.includes(normalizedWord);
    } else {
      return normalizeText(element).includes(normalizedWord);
    }
  };

  const getKeys = ({ obj, word }) => {
    return Object.keys(obj).some((key) => {
      if (key === "actions") return false;
      if (isElementMatchWord({ element: obj[key], word })) return true;
      const elements = obj[key]?.props?.children;
      if (!Array.isArray(elements)) return false;
      return elements?.some((element) =>
        isElementMatchWord({ element: element?.props?.children, word })
      );
    });
  };

  const filter = ({ dataTableInfo, filterInfo, setFilteredDataTable }) => {
    let filtered = [];
    if (setFilteredWord) {
      setFilteredWord(filterInfo);
    }
    if (filterInfo === "") {
      filtered = dataTableInfo.rows;
    } else {
      const filteredText = filterInfo.slice(0, characterLimit);
      filtered = dataTableInfo.rows.filter((info) => {
        if (getKeys({ obj: info, word: filteredText })) {
          return info;
        }
        return false;
      });
    }
    setFilteredDataTable({
      columns: dataTableInfo.columns,
      rows: filtered,
    });
  };

  const handleChange = (e) => {
    const inputText = e.target.value.slice(0, characterLimit);

    filter({
      dataTableInfo,
      filterInfo: inputText,
      setFilteredDataTable,
    });
  };

  return (
    <Container fluid>
      <Row>
        <InputGroup bsPrefix="input-group-container">
          <Form.Control
            bsPrefix="custom-input"
            placeholder={placeholder}
            className="input-group-container__searchBar"
            onChange={handleChange}
            maxLength={maxLength}
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
    </Container>
  );
};

export default CustomSearch;
