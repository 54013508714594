import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row, Container } from "react-bootstrap";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import {
  getFolder,
  fetchFolderById,
  fetchAllFolders,
} from "../../parts/folders/foldersSlice";
import LoadingContent from "../loadingContent";

const ModalAssociateVersion = ({
  title,
  agreeText,
  disagreeText,
  handleAgree,
  onClose,
  open,
  folders,
  versionSelected,
  isMovingDocument = false,
  isMoveConfirmed = false,
  filterTypeDocument = null,
  filterExtensionsDocument = [],
}) => {
  const [folderSelect, setFolderSelect] = useState(useSelector(getFolder));
  const [levelFolder, setLevelFolder] = useState("");
  const [foldersList, setFoldersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const foldersPru = useSelector(getFolder);

  const handleClose = () => {
    onClose();
    setLevelFolder("");
    dispatch(fetchAllFolders()).unwrap();
  };

  const handleCancel = () => {
    onClose();
  };

  const getIsButtonIsDisabled = () => {
    return (
      !folderSelect?._id ||
      folderSelect.hasOwnProperty("businessFolderFatherId")
    );
  };

  const handleFolderSubLevel = async (folder, onBack) => {
    try {
      setIsLoading(true);
      let response = {};
      if (onBack) {
        response = await dispatch(fetchFolderById(folder._id)).unwrap();
      } else if (folder.businessFolderFatherId) {
        response = await dispatch(
          fetchFolderById(folder.businessFolderFatherId)
        ).unwrap();
      } else {
        response = await dispatch(fetchAllFolders()).unwrap();
      }
      setLevelFolder(response);
      let documentsFilter = response?.documents;
      if (filterTypeDocument) {
        documentsFilter = response?.documents?.filter((doc) =>
          filterTypeDocument.includes(doc.fileType)
        );
      }
      if (filterExtensionsDocument.length) {
        documentsFilter = response?.documents?.filter((doc) => {
          const allowExtensionFormats = filterExtensionsDocument.map((e) =>
            e.toLowerCase()
          );
          const extension = doc?.name?.split(".").pop().toLowerCase();
          return allowExtensionFormats.includes(extension);
        });
      }
      const arrayItems =
        response?.subFolders?.concat(documentsFilter) ||
        documentsFilter ||
        response;
      setFoldersList(arrayItems);
      setFolderSelect(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !versionSelected?.name && setFolderSelect({});
  }, [versionSelected]);
  useEffect(() => {
    setFoldersList(folders);
  }, [folders]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle>
          <label className="heading__primary-color">{title}</label>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className="caption" dividers>
          {isLoading && <LoadingContent />}
          <Container style={{ margin: "2% 0" }}>
            <Row>
              {levelFolder && (
                <Col md={1}>
                  <IconButton
                    className="custom-input__button__secondary-color"
                    sx={{ padding: "10% 0%", borderRadius: "0" }}
                    onClick={() => {
                      handleFolderSubLevel(foldersPru, false);
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Col>
              )}
              <Col className="heading__primary-color">
                {levelFolder?.name || "Carpetas"}
              </Col>
            </Row>
          </Container>

          <TableContainer component={Paper} sx={{ overflowY: "scroll" }}>
            <Table>
              <TableBody>
                {foldersList?.length > 0 &&
                  foldersList.map((folder) => (
                    <div key={folder?._id}>
                      {folder?.documents ? (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            backgroundColor:
                              !folder?.documents &&
                              folder?.name === folderSelect.name
                                ? "blue"
                                : "inherit",
                          }}
                          onClick={() => {
                            !folder.documents && setFolderSelect(folder);
                          }}
                          className="select-folder"
                        >
                          <TableCell align="left" width={"5%"}>
                            {!folder?.documents ? (
                              <InsertDriveFileIcon fontSize="large" />
                            ) : (
                              <FolderIcon fontSize="large" />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <p className="body-content">{folder?.name}</p>
                          </TableCell>
                          <TableCell align="right">
                            {folder?.documents && (
                              <IconButton
                                className="custom-input__button__primary-color iconMaterialSetting"
                                onClick={() => {
                                  handleFolderSubLevel(folder, true);
                                }}
                              >
                                <ArrowForwardIosIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          className={
                            folder?._id === folderSelect?._id
                              ? "selected"
                              : "select-folder"
                          }
                          onClick={() => {
                            if (!isMovingDocument) {
                              !folder.documents && setFolderSelect(folder);
                            }
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <TableCell align="left" width={"5%"}>
                            {!folder.documents ? (
                              <InsertDriveFileIcon fontSize="large" />
                            ) : (
                              <FolderIcon fontSize="large" />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <p className="body-content">{folder.name}</p>
                          </TableCell>
                          <TableCell align="right">
                            {folder.documents && (
                              <IconButton className="custom-input__button__primary-color iconMaterialSetting">
                                <ArrowForwardIosIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </div>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Row>
            <Col>
              <Button
                variant="text"
                className="custom-input__button__secondary-color__forced"
                onClick={handleCancel}
              >
                {disagreeText}
              </Button>
            </Col>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__primary-color__forced"
                disabled={getIsButtonIsDisabled()}
                onClick={() => {
                  handleAgree(folderSelect, levelFolder);
                }}
              >
                {agreeText}
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalAssociateVersion;
