import * as React from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrum from "../../../../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../../../../components/sectionTitle";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  fetchFormTags,
  getTagsFormList,
  getTagsFormStatus,
} from "../manageCustomFormTags/states/tagSliceForms";
import ModalAssociateTags from "../../../../../../../../../components/Modals/ModalAssociateTags";
import ModalDecision from "../../../../../../../../../components/Modals/modalDecision";
import ManageRequiredDocumentsSection from "./sections/RequiredDocumentsSection";
import LoadingContent from "../../../../../../../../../components/loadingContent";
import { Check, Clear, Close } from "@mui/icons-material";
import {
  updateCustomForm,
  resetRequestTypeForm,
  getCustomRequestType,
  addNewCustomRequestType,
} from "../../../../../../../../../services/documents/formCustomization/CustomizationService";
import { FormSection } from "./sections/formSection/FormSection";
import { UpdatedCustomRequestTypeDtoFactory } from "../../../../../../../../../services/documents/formCustomization/factories/updatedCustomRequestTypeDtoFactory";
import { CODES } from "../../../../../../../../../utils/codes";
import { ResetRequestTypeFormDto } from "../../../../../../../../../services/documents/formCustomization/dto/resetRequestTypeFormDto";
import { DetailsSection } from "./sections/details/DetailsSection";
import useCustomFormStateManager from "./states/useCustomFormStateManager";
import ManageCustomFormModes from "./constants/manageCustomFormModes";
import { isDefined } from "../../../../../../../../../utils/validations";
import AddNewCustomRequestTypeDto from "../../../../../../../../../services/documents/formCustomization/dto/addNewCustomRequestType.Dto";
import { resetFormListStatus } from "../../states/formCustomizationSlice";

// TYPE DEFINITIONS BEGIN

/**
 * Defines the structure of a the custom form.
 *
 * @typedef {Object} CustomFormType
 * @property {Object} company The company of the form.
 * @property {string} [originalFormId] The original form id.
 * @property {string} [group] The group of the form.
 * @property {string} type The type of the form.
 * @property {string} name The name of the form.
 * @property {Array} description The description of the form.
 * @property {Array} [labels] The labels of the form.
 * @property {Array<SectionType>} sections The sections of the form.
 * @property {Array<RequiredDocumentType>} requiredDocuments The required documents of the form.
 * @property {Array<QuestionType>} fields The questions of the form.
 * @property {boolean} allowUpdateOtherDocumentsToTrace The flag to allow update other documents to trace.
 */

/**
 * Defines the structure of a section.
 *
 * @typedef {Object} SectionType
 * @property {string} [name] The name of the section.
 * @property {Array<QuestionType>} [fields] The questions of the section.
 */

/**
 * Defines the structure of a question.
 *
 * @typedef {Object} QuestionType
 *
 * @property {string} type The type of the question.
 * @property {string} value The value of the question.
 * @property {string} [label] The label of the question.
 * @property {Array<string>} [options] The options of the question.
 * @property {ValidationType} validations The validations of the question.
 */

/**
 * Defines the structure of a validation.
 *
 * @typedef {Object} ValidationType
 * @property {ValidationPropsType} [required] The required validation.
 * @property {ValidationPropsType} [minLength] The min length validation.
 * @property {ValidationPropsType} [maxLength] The max length validation.
 * @property {ValidationPropsType} [pattern] The pattern validation.
 */

/**
 * Defines the props of a validation.
 *
 * @typedef {Object} ValidationPropsType
 * @property {string} message The message of the validation.
 * @property {any} value The value of the validation.
 */

/**
 * Defines the structure of a required document.
 *
 * @typedef {Object} RequiredDocumentType
 * @property {string} [originalFilename] The original name of the document.
 * @property {boolean} [isRequired] Whether the document is required or not.
 * @property {string} [fileExtension] The file extensions of the document.
 * @property {string} [type] The type of the document.
 */

// TYPE DEFINITIONS END

const Accordion = styled((props) => (
  <MuiAccordion disableGutters square sx={{ boxShadow: 1 }} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "8px 8px 0px 0px",
  marginBottom: "32px",
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon fontSize="large" sx={{ color: "#00374F" }} />}
    {...props}
  />
))(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)(
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(3),
  })
);

/**
 * Logic for the ManageCustomForm component
 */
const ManageCustomFormLogic = Object.freeze({
  /**
   * Initial states for the variables on ManageCustomForm component
   */
  InitialStates: {
    modalDecisionInfoProps: {
      open: false,
      type: undefined,
      title: undefined,
      message: undefined,
      agreeText: undefined,
      agreeIcon: undefined,
      agreeIconStart: undefined,
      disagreeText: undefined,
      disagreeIcon: undefined,
      disagreeIconStart: undefined,
      handleAgree: () => undefined,
      handleDisagree: () => undefined,
      onClose: () => undefined,
    },
    customFormState: {
      originalFormId: undefined,
      group: undefined,
      type: undefined,
      name: "Nuevo formulario",
      description: undefined,
      labels: [],
      sections: [],
      requiredDocuments: [],
      fields: [],
      allowUpdateOtherDocumentsToTrace: true,
      company: {
        _id: undefined,
      },
    },
    isLoading: false,
    mode: ManageCustomFormModes.CREATE,
    isSubmittingDeferred: {
      detailsSection: false,
      formSection: false,
      save: false,
    },
    resetStartModificationDate: false,
  },
  Variables: {
    startModificationDateLastValue: new Date(),
  },
});

/**
 * ManageCustomForm component, used to manage the custom form
 */
export default function ManageCustomForm() {
  // States

  const {
    customFormState,
    addQuestion,
    deleteQuestion,
    updateQuestion,
    addRequiredDocument,
    deleteRequiredDocument,
    updateRequiredDocument,
    toggleAllowUpdateOtherDocumentsToTrace,
    setCustomFormState,
    setLabels,
    deleteLabel,
    prepareCustomFormForSave,
    changeGroup,
    changeType,
    changeName,
    changeDescription,
    addSection,
    updateSectionName,
    deleteSection,
    getModifiedProperties,
  } = useCustomFormStateManager({
    customForm: ManageCustomFormLogic.InitialStates.customFormState,
  });
  const [isLoading, setIsLoading] = useState(
    ManageCustomFormLogic.InitialStates.isLoading
  );
  const [modalDecisionInfoProps, setModalDecisionInfoProps] = useState(
    ManageCustomFormLogic.InitialStates.modalDecisionInfoProps
  );
  const [mode, setMode] = useState(ManageCustomFormLogic.InitialStates.mode);
  const [isSubmittingDeferred, setIsSubmittingDeferred] = useState(
    ManageCustomFormLogic.InitialStates.isSubmittingDeferred
  );
  const [resetStartModificationDate, setResetStartModificationDate] = useState(
    ManageCustomFormLogic.InitialStates.resetStartModificationDate
  );

  // Hooks

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux

  const tagsList = useSelector(getTagsFormList);
  const statusTags = useSelector(getTagsFormStatus);

  // Utils Functions

  /**
   * Start to reset the start modification date of the custom form after update it with the last changes made by other users.
   *
   * Is used before {@link finishResetStartModificationDate}.
   */
  const startResetStartModificationDate = () => {
    setResetStartModificationDate(true);
  };

  /**
   * Finish to reset the start modification date of the custom form after update it with the last changes made by other users.
   *
   * Is used after {@link startResetStartModificationDate}.
   */
  const finishResetStartModificationDate = () => {
    setResetStartModificationDate(false);
  };

  /**
   * The start modification date of the custom form.
   *
   * Is used for the backend to validate if the custom form was updated while the user was editing it.
   */
  const startModificationDate = useMemo(() => {
    /**
     * If the `resetStartModificationDate` is true, the start modification date will be updated with the current date otherwise it will return the last value.
     */
    if (resetStartModificationDate) {
      const currentDate = new Date();
      ManageCustomFormLogic.Variables.startModificationDateLastValue =
        currentDate;
      finishResetStartModificationDate();

      return currentDate;
    } else {
      return ManageCustomFormLogic.Variables.startModificationDateLastValue;
    }
  }, [resetStartModificationDate]);

  // Action functions

  /**
   * Get the custom form by id
   *
   * @returns {Promise<CustomFormType>} The custom form
   */
  const getCustomForm = async () => {
    let foundCustomForm;
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      const response = await getCustomRequestType({ companyId, formId: id });
      if (response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS) {
        foundCustomForm = response?.data?.responseMessage;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    return foundCustomForm;
  };

  /**
   * Reset the custom form
   */
  const resetCustomForm = async () => {
    try {
      setIsLoading(true);
      const resetRequestTypeFormDto = new ResetRequestTypeFormDto({
        formId: id,
        companyId: localStorage.getItem("company"),
        originalFormId: customFormState.originalFormId,
      });
      const response = await resetRequestTypeForm(resetRequestTypeFormDto);
      const success =
        response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS;
      openResetCustomFormModal(success);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Save the custom form and finish the deferred save process.
   *
   * Depending on the `mode` of the custom form, the function will call the `handleAddNewCustomRequestType` or `handleUpdateCustomForm` function.
   */
  const saveCustomForm = async () => {
    try {
      setIsLoading(true);
      const prepareCustomFormForSaveResult = prepareCustomFormForSave();
      if (typeof prepareCustomFormForSaveResult === "string") {
        openValidationsErrorModal(prepareCustomFormForSaveResult);
        return;
      }
      let response;

      if (mode === ManageCustomFormModes.CREATE) {
        response = await handleAddNewCustomRequestType();
      } else if (mode === ManageCustomFormModes.EDIT) {
        response = await handleUpdateCustomForm();
        if (
          response.data?.responseCode === CODES.COD_RESPONSE_ERROR_WAS_MODIFIED
        ) {
          openOutdatedCustomFormWarningModal();
          setCustomFormState(response.data.data);
          return;
        } else if (
          response.data?.responseCode === CODES.COD_RESPONSE_ERROR_FIND
        ) {
          openDeletedCustomFormWarningModal();
          return;
        }
      }
      const success =
        response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS;
      openSaveCustomFormModal(success);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmittingDeferred(
        ManageCustomFormLogic.InitialStates.isSubmittingDeferred
      );
      setIsLoading(false);
    }
  };

  /**
   * Save the details section info and start the `saveFormSection` deferred process.
   *
   * @param {string} group The group of the custom form
   * @param {string} type The type of the custom form
   * @param {string} description The description of the custom form
   */
  const saveDetailsSectionInfo = (group, type, description) => {
    changeGroup(group);
    changeType(type);
    changeDescription(description);
    changeName(
      type || ManageCustomFormLogic.InitialStates.customFormState.name
    );

    setIsSubmittingDeferred({
      ...isSubmittingDeferred,
      detailsSection: false,
      formSection: true,
    });
  };

  /**
   * Save the form section info and start the `saveCustomForm` deferred process.
   *
   * If the `sectionIndex` and `newSectionName` not provided, the function will continue with the `saveCustomForm` deferred process.
   *
   * @param {number} [sectionIndex] The index of the section to update.
   * @param {string} [newSectionName] The new name of the section.
   */
  const saveFormSectionInfo = (sectionIndex, newSectionName) => {
    if ((isDefined(sectionIndex), isDefined(newSectionName))) {
      updateSectionName(sectionIndex, newSectionName);
    }

    setIsSubmittingDeferred({
      ...isSubmittingDeferred,
      formSection: false,
      save: true,
    });
  };

  // Handlers

  /**
   * Update the custom form
   *
   * @returns {Promise<Object>} The response of the update custom form service
   */
  const handleUpdateCustomForm = async () => {
    const { userId } =
      JSON.parse(localStorage.getItem("payloadToken")) ?? undefined;
    const modifiedPropertiesForTraceability = getModifiedProperties();
    const updateCustomFormDto =
      UpdatedCustomRequestTypeDtoFactory.createUpdateCustomFormDto({
        ...modifiedPropertiesForTraceability,
        formId: id,
        companyId: localStorage.getItem("company"),
        group: customFormState.group,
        type: customFormState.type,
        description: customFormState.description,
        modifiedBy: userId,
        startModificationDate: startModificationDate,
      });
    const response = await updateCustomForm(updateCustomFormDto);
    return response;
  };

  /**
   * Add a new custom request type
   *
   * @returns {Promise<Object>} The response of the add new custom request type service
   */
  const handleAddNewCustomRequestType = async () => {
    const addNewCustomRequestTypeDto = AddNewCustomRequestTypeDto.fromObject({
      ...customFormState,
      companyId: localStorage.getItem("company"),
    });
    const response = await addNewCustomRequestType(addNewCustomRequestTypeDto);
    return response;
  };

  /**
   * Handle the save custom form event.
   *
   * Starts the submit deferred process, that will save the custom form when all the sections are saved, flow:
   * 1. Save the details section info with `saveDetailsSectionInfo`.
   * 2. Save the form section info with `saveFormSectionInfo`.
   * 3. Save the form with `saveCustomForm`.
   *
   * Note: The save of the custom form is deferred because the form is divided in sections and the form is saved when all the sections are saved.
   */
  const handleSaveCustomForm = () => {
    setIsSubmittingDeferred({
      ...isSubmittingDeferred,
      detailsSection: true,
    });
  };

  // Modals

  /**
   * Close the decision info modal
   */
  const closeModalDecisionInfo = () => {
    setModalDecisionInfoProps(
      ManageCustomFormLogic.InitialStates.modalDecisionInfoProps
    );
  };

  /**
   * Open the reset custom form modal
   *
   * This modal is shown when the user reset the custom form and the reset was successful or not.
   *
   * @param {boolean} success - Whether the reset was successful or not
   * @param {function} setModalDecisionInfoProps - Function to set the modal decision info props
   */
  const openResetCustomFormModal = (success) => {
    setModalDecisionInfoProps({
      open: true,
      type: success ? undefined : "warning",
      title: success
        ? "Formulario restablecido"
        : "Error restableciendo el formulario",
      message: success
        ? "El formulario ha sido restablecido a su version original."
        : "No se ha restablecido el formulario.",
      agreeText: "Aceptar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: () => {
        navigate(-1);
        dispatch(resetFormListStatus());
      },
      onClose: closeModalDecisionInfo,
    });
  };

  /**
   * Open the reset custom form warning modal
   *
   * This modal is shown when the user tries to reset the custom form.
   */
  const openResetCustomFormWarningModal = () => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message:
        "¿Esta seguro de restablecer este formulario? Todos los cambios se restablecerán.",
      agreeText: "Aceptar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: () => {
        closeModalDecisionInfo();
        resetCustomForm();
      },
      disagreeText: "Cancelar",
      disagreeIconStart: <Close fontSize="large" />,
      handleDisagree: closeModalDecisionInfo,
      onClose: closeModalDecisionInfo,
    });
  };

  /**
   * Open the save custom form modal
   *
   * This modal is shown when the user save the custom form and the save was successful or not.
   *
   * @param {boolean} success - Whether the save was successful or not
   */
  const openSaveCustomFormModal = (success) => {
    setModalDecisionInfoProps({
      open: true,
      type: success ? undefined : "warning",
      title: success
        ? "Formulario actualizado"
        : "Atención",
      message: success
        ? "Se han guardado los cambios de tu formulario exitosamente."
        : "No se han guardado los cambios de tu formulario.",
      agreeText: "Aceptar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: () => handleSaveCustomFormModalClick("Agree"),
      onClose: () => handleSaveCustomFormModalClick("Close"),
    });
  };

  /**
   * On click the save custom form modal the actions to execute are:
   * 
   * - Agree: Redirect to the previous page.
   * - Close: Close the modal.
   * 
   * @param {"Agree" | "Close"} action The action to execute
   */
  const handleSaveCustomFormModalClick = (action) => {
    const actionsFunction = {
      Agree: () => navigate(-1),
      Close: closeModalDecisionInfo,
    }

    dispatch(resetFormListStatus());
    actionsFunction[action]();
  }

  /**
   * Open the validations error modal.
   *
   * This modal is shown when the user tries to save the custom form with invalid data.
   *
   * @param {string} message The message of the error.
   */
  const openValidationsErrorModal = (message) => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message: message,
      agreeText: "Aceptar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: closeModalDecisionInfo,
      onClose: closeModalDecisionInfo,
    });
  };

  /**
   * Open the go back warning modal
   *
   * This modal is shown when the user tries to go back without saving the custom form.
   */
  const openGoBackWarningModal = () => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message:
        "Esta acción eliminará todos los cambios realizados en el formulario. ¿Estás seguro de continuar?",
      agreeText: "Aceptar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: () => navigate(-1),
      disagreeText: "Cancelar",
      disagreeIconStart: <Close fontSize="large" />,
      handleDisagree: closeModalDecisionInfo,
      onClose: closeModalDecisionInfo,
    });
  };

  /**
   * Open the outdated custom form warning modal.
   *
   * This modal is shown when the custom form was updated while the user was editing it.
   */
  const openOutdatedCustomFormWarningModal = () => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message:
        "Se han realizado actualizaciones en el formulario mientras lo completabas. Para continuar revisa los cambios más recientes.",
      agreeText: "Actualizar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: handleOutdatedCustomFormWarningModalClick,
      onClose: handleOutdatedCustomFormWarningModalClick,
    });
  };

  /**
   * On click the outdated custom form warning modal, the custom form will be updated with the last changes made by other users.
   */
  const handleOutdatedCustomFormWarningModalClick = () => {
    startResetStartModificationDate();
    closeModalDecisionInfo();
    dispatch(resetFormListStatus());
  }

  /**
   * Open the deleted custom form warning modal.
   *
   * This modal is shown when the custom form was deleted while the user was editing it.
   */
  const openDeletedCustomFormWarningModal = () => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message:
        "Lo sentimos, este formulario ya no está disponible. Contáctate con el responsable.",
      agreeText: "Actualizar",
      agreeIconStart: <Check fontSize="large" />,
      handleAgree: handleDeletedCustomFormWarningModalClick,
      onClose: handleDeletedCustomFormWarningModalClick,
    });
  };

  /**
   * On click the deleted custom form warning modal, the user will be redirected to the previous page.
   */
  const handleDeletedCustomFormWarningModalClick = () => {
    navigate(-1)
    dispatch(resetFormListStatus());
  }

  // useEffects

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setMode(ManageCustomFormModes.CREATE);
        return;
      }
      const customForm = await getCustomForm();
      if (customForm) {
        setCustomFormState(customForm);
        setMode(ManageCustomFormModes.EDIT);
      } else if (!customForm) {
        // Redirection when the custom form is not found
        navigate(-1);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchFormTags("CUSTOMFORM"));
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (isSubmittingDeferred.save) {
      saveCustomForm();
    }
  }, [isSubmittingDeferred]);

  return (
    <Container
      fluid
      className="custom-container-scroll d-flex flex-column gap-4"
    >
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>

      <Row className="align-items-center justify-content-between gap-4">
        <Col xs={"auto"}>
          <SectionTitle title={customFormState.name} />
        </Col>
        <Col xs={"auto"}>
          <ModalAssociateTags
            tagsList={tagsList}
            handleTagsSelected={setLabels}
            previousTagsSelected={customFormState.labels}
            type="CUSTOMFORM"
          />
        </Col>
      </Row>
      <Row className="flex-row-reverse gy-4">
        {customFormState.labels?.map((tag, tagIndex) => {
          return (
            <Col xs={"auto"} key={tag?._id}>
              <span className="caption custom-badges__secundary">
                {tag.name}
                <Clear
                  className="custom-badges__delete-button"
                  onClick={() => deleteLabel(tagIndex)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row className="my-5">
        <DetailsSection
          Wrapper={({ children }) => (
            <Accordion defaultExpanded>{children}</Accordion>
          )}
          TittleWrapper={({ children }) => (
            <AccordionSummary aria-controls="panel-content" id="panel-header">
              {children}
            </AccordionSummary>
          )}
          ContentWrapper={AccordionDetails}
          title="Información de la solicitud"
          subTitle="Detalles de la solicitud"
          originalFormId={customFormState.originalFormId}
          group={customFormState.group}
          type={customFormState.type}
          description={customFormState.description}
          mode={mode}
          isSubmittingExternally={isSubmittingDeferred.detailsSection}
          saveDetailsSectionInfo={saveDetailsSectionInfo}
        />
        <FormSection
          Wrapper={Accordion}
          TittleWrapper={AccordionSummary}
          ContentWrapper={AccordionDetails}
          title="Formulario"
          fields={customFormState.fields}
          sections={customFormState.sections}
          handleAddQuestion={addQuestion}
          handleUpdateQuestion={updateQuestion}
          handleDeleteQuestion={deleteQuestion}
          mode={mode}
          onAddSection={addSection}
          isSubmittingExternally={isSubmittingDeferred.formSection}
          saveFormSectionInfo={saveFormSectionInfo}
          onDeleteSection={deleteSection}
        />
        <ManageRequiredDocumentsSection
          Wrapper={Accordion}
          TittleWrapper={AccordionSummary}
          ContentWrapper={AccordionDetails}
          title="Documentos de la solicitud"
          requiredDocuments={customFormState.requiredDocuments}
          isAllowedUploadOtherDocuments={
            customFormState.allowUpdateOtherDocumentsToTrace
          }
          allowOrDenyUploadOtherDocumentsCallback={
            toggleAllowUpdateOtherDocumentsToTrace
          }
          addDocumentCallback={addRequiredDocument}
          updateDocumentCallback={updateRequiredDocument}
          deleteDocumentCallback={deleteRequiredDocument}
        />
      </Row>
      <Divider />
      <Row className="sidebar__bottom__container">
        <Stack
          direction="horizontal"
          gap={3}
          className="flex-wrap justify-content-end"
        >
          <Button
            variant="contained"
            startIcon={<ArrowBackOutlinedIcon />}
            className="custom-input__button__secondary-color"
            onClick={openGoBackWarningModal}
            sx={{
              whiteSpace: "nowrap",
              width: "fit-content",
            }}
          >
            Regresar
          </Button>
          {mode === ManageCustomFormModes.EDIT &&
            customFormState.originalFormId && (
              <Button
                variant="contained"
                startIcon={<RestoreOutlinedIcon />}
                className="custom-input__button__secondary-color"
                onClick={openResetCustomFormWarningModal}
                sx={{
                  whiteSpace: "nowrap",
                  width: "fit-content",
                }}
              >
                Restablecer
              </Button>
            )}
          <Button
            variant="contained"
            startIcon={<SaveOutlinedIcon />}
            className="custom-input__button__primary-color"
            onClick={handleSaveCustomForm}
            sx={{
              whiteSpace: "nowrap",
              width: "fit-content",
            }}
          >
            Guardar
          </Button>
        </Stack>
      </Row>
      <ModalDecision {...modalDecisionInfoProps} />
    </Container>
  );
}
