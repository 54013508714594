import * as yup from "yup";

import BlankSpaceFormInputs from "../constants/blankSpaceFormInputs";

/**
 * Structure of blank space in a form with own validations
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @constant
 */
const BlankSpaceFormSchema = yup.object().shape({
  [BlankSpaceFormInputs.Name.inputName]: yup
    .string()
    .default("")
    .trim()
    .required("*Este campo es obligatorio")
    .max(
      BlankSpaceFormInputs.Name.maxLength,
      BlankSpaceFormInputs.Name.maxLengthErrorMessage
    ),
  [BlankSpaceFormInputs.Description.inputName]: yup
    .string()
    .default("")
    .trim()
    .required("*Este campo es obligatorio")
    .max(
      BlankSpaceFormInputs.Description.maxLength,
      BlankSpaceFormInputs.Description.maxLengthErrorMessage
    ),
});

export default BlankSpaceFormSchema;
