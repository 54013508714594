/**
 * The file extensions accepted for the form when uploading documents.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @readonly
 * @enum {string}
 */
export const DocumentFileExtensions = Object.freeze({
  PDF: "pdf",
  WORD: "docx",
  EXCEL: "xlsx",
});
