import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingContent from "../../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../../components/sectionTitle";
import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";
import {
  Checkbox,
  Dialog,
  Drawer,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
} from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartsListDelete,
  getPartsListDelete,
  getPartsListStatusDelete,
  resetPartListStatus,
  fetchSignatoriesListDelete,
  getSignatoriesListDelete,
  getSignatoriesListStatusDelete,
  resetSignatoriesListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { PARTS_LIBRARY_STATE, PERSON_TYPE, SIGNATORIES_STATE } from "../utils";
import { MENU_OPTIONS_DELETE } from "../../../../../../../utils/menuOptions";
import {
  DeletePartPermanent,
  RestoreRemovePart,
  findPartByCompany,
  restoreTemporarilyDeletedSignatory,
  deleteSignatoryPermanent,
  findSignatoriesByCompany,
} from "../../../../../../../services/parts/partsServices";
import { CODES } from "../../../../../../../utils/codes";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getFileDocument } from "../../../../../../../services/documents/documentsRequest";
import { Buffer } from "buffer";

export const PartsPaperBasket = () => {
  // Redux
  const dispatch = useDispatch();
  const partsListStatusDelete = useSelector(getPartsListStatusDelete);
  const partsListDelete = useSelector(getPartsListDelete);
  const signatoriesListStatusDelete = useSelector(
    getSignatoriesListStatusDelete
  );
  const signatoriesListDelete = useSelector(getSignatoriesListDelete);

  // Constants
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Fecha de eliminación
        </label>,
      ],
      field: "deleteAt",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Días restantes
        </label>,
      ],
      field: "countDays",
    },
    {
      label: [<label aria-hidden="true" key="5"></label>],
      field: "actions",
    },
  ];

  const TYPE_ELEMENT = {
    PART: {
      id: 1,
      name: "Parte",
      key: "PART",
      icon: (
        <AccountCircleIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
    SIGNATORY: {
      id: 2,
      name: "Firmante",
      key: "SIGNATORY",
      icon: (
        <PersonOutlineIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
  };

  // Statuses
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });
  const [data, setData] = useState({ columns, rows: [] });
  const [anchorSelected, setAnchorSelected] = useState(null);
  const openSelected = Boolean(anchorSelected);
  const [selectedElement, setSelectedElement] = useState({
    id: "",
    type: "",
    name: "",
  });
  const [openModalDecisionDelete, setOpenModalDecisionDelete] = useState(false);
  const [openDetailsDrawner, setOpenDetailsDrawner] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [isOpenModalPartsInfo, setIsOpenModalPartsInfo] = useState(false);
  const [filterByMe, setFilterByMe] = useState(false);

  // Functions
  const getName = (element) => {
    let name = "";
    const elementSelected = TYPE_ELEMENT[element?.typeElement?.key]?.id;
    const partNameByPersonType = {
      [PERSON_TYPE.LEGAL]: (part) => part?.business?.businessName,
      [PERSON_TYPE.NATURAL]: (part) =>
        `${part?.firstName || ""} ${part?.firstSurname || ""}`,
    };

    if (elementSelected === TYPE_ELEMENT.PART.id) {
      name = partNameByPersonType[element.typePart](element);
    } else if (elementSelected === TYPE_ELEMENT.SIGNATORY.id) {
      name = partNameByPersonType[PERSON_TYPE.NATURAL](element);
    }

    return name;
  };

  const buildRows = (data) => {
    if (!data.length) return [];

    return data.map((item) => {
      const deafultName = getName(item);
      return {
        element: (
          <Row>
            <Col xs={"auto"} className="datatable-CB__tr-name">
              {TYPE_ELEMENT[item?.typeElement?.key]?.icon}
            </Col>
            <Col className="datatable-CB__tr-name">
              {item?.typeElement?.key === "SIGNATORY" &&
              !item?.relatedParties?.length
                ? "Firmante sin parte relacionada:"
                : ""}
              {deafultName}
            </Col>
          </Row>
        ),
        type: TYPE_ELEMENT[item?.typeElement?.key]?.name,
        deleteAt: moment(item?.deletedAt).format("DD/MM/YYYY HH:mm:ss"),
        countDays: item?.daysUntilDelete,
        actions: (
          <IconButton
            onClick={(event) => {
              console.log(item);
              setSelectedElement({ ...item, name: deafultName });
              setAnchorSelected(event.currentTarget);
            }}
          >
            <MoreHorizIcon fontSize="large" />
          </IconButton>
        ),
        name: deafultName,
      };
    });
  };

  const restorePart = async (item) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const newStatusId = PARTS_LIBRARY_STATE.ACTIVE._id;
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const responseDeletePart = await RestoreRemovePart({
        company,
        partId: item?._id,
        status: newStatusId,
        modifiedBy: userId,
      });
      setResponseData(responseDeletePart);
      if (
        responseDeletePart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeletePart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseDeletePart,
          data: {
            message: "La parte ha sido restaurada correctamente.",
          },
        });
        dispatch(resetPartListStatus());
      }
      setIsOpenModalResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const restoreSignatory = async (item) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const newStatusId = SIGNATORIES_STATE.ACTIVE._id;
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const responseRestoreSignatory = await restoreTemporarilyDeletedSignatory(
        {
          company,
          signatoryId: item?._id,
          status: newStatusId,
          modifiedBy: userId,
        }
      );
      setResponseData(responseRestoreSignatory);
      if (
        responseRestoreSignatory.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseRestoreSignatory.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseRestoreSignatory,
          data: {
            message: "El firmante ha sido restaurado correctamente.",
          },
        });
        dispatch(resetSignatoriesListStatus());
      }
      setIsOpenModalResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletePart = async (item) => {
    try {
      setIsLoading(true);
      setOpenModalDecisionDelete(false);
      const company = localStorage.getItem("company");
      const responseDeletePart = await DeletePartPermanent({
        company,
        partId: item?._id,
      });
      setResponseData(responseDeletePart);
      if (
        responseDeletePart.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeletePart.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseDeletePart,
          data: {
            message: "La parte ha sido eliminada correctamente.",
          },
        });
        dispatch(resetPartListStatus());
      }
      setIsOpenModalResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSignatory = async (item) => {
    try {
      setIsLoading(true);
      setOpenModalDecisionDelete(false);
      const responseDeleteSignatory = await deleteSignatoryPermanent({
        signatoryId: item?._id,
      });
      setResponseData(responseDeleteSignatory);
      if (
        responseDeleteSignatory.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDeleteSignatory.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setResponseData({
          ...responseDeleteSignatory,
          data: {
            message: "El firmante ha sido eliminado correctamente.",
          },
        });
        dispatch(resetSignatoriesListStatus());
      }
      setIsOpenModalResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handles
  const handleCloseSelected = () => {
    setAnchorSelected(null);
  };

  const handleRestore = async (item) => {
    const restoreByItemType = {
      [TYPE_ELEMENT.PART.id]: restorePart,
      [TYPE_ELEMENT.SIGNATORY.id]: restoreSignatory,
    };
    restoreByItemType[item?.typeElement?.id](item);
  };

  const handleDelete = async (item) => {
    const deleteByItemType = {
      [TYPE_ELEMENT.PART.id]: deletePart,
      [TYPE_ELEMENT.SIGNATORY.id]: deleteSignatory,
    };
    deleteByItemType[item?.typeElement?.id](item);
  };

  const handleManageOptionsMenu = async ({ option, element }) => {
    setAnchorSelected(null);
    switch (option) {
      case 0:
        await handleRestore(element);
        break;
      case 1:
        setOpenModalDecisionDelete(true);
        break;
      case 2:
        setOpenDetailsDrawner(true);
        break;
      case 3:
        setIsOpenModalPartsInfo(true);
        break;
      default:
        break;
    }
  };

  const handleFilterByPerson = async (event) => {
    try {
      setFilterByMe(event?.target?.checked);
      if (event?.target?.checked) {
        setIsLoading(true);
        const company = localStorage.getItem("company");
        const payloadTokenInfo = localStorage.getItem("payloadToken") ?? "{}";
        const userCorporateUnitsInfo = localStorage.getItem("userCorporateUnits") ?? "[]"
        const {
          loginType = "",
          subRole = "",
          corporateUnits: defaultUC = [],
          userId = "",
        } = JSON.parse(payloadTokenInfo);
        const corporateUnitId = localStorage.getItem("corporateUnitId");
        const userCorporateUnits = JSON.parse(userCorporateUnitsInfo)
        let associatedCorporateUnits = [];

        if (loginType === "ADMIN") {
          if (subRole !== "ADMIN PROPIETARIO") {
            const corporateUnits = localStorage.getItem("userCorporateUnits")
              ? userCorporateUnits
              : defaultUC;
            associatedCorporateUnits = corporateUnits?.map((uc) => uc?._id) ?? [];
          }
        } else {
          associatedCorporateUnits = [corporateUnitId];
        }

        const status = PARTS_LIBRARY_STATE.DELETED._id;
        const [{ value: responseParts }, { value: responseSignatories }] =
          await Promise.allSettled([
            findPartByCompany({
              company,
              status,
              associatedCorporateUnits,
              deletedBy: userId,
            }),
            findSignatoriesByCompany({
              company,
              status,
              associatedCorporateUnits,
              deletedBy: userId,
            }),
          ]);
        if (
          responseParts.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseParts.data.success &&
          responseSignatories.status === CODES.COD_RESPONSE_HTTP_OK &&
          responseSignatories.data.success
        ) {
          const partsList = responseParts.data.responseMessage.data.map(
            (item) => ({
              ...item,
              typeElement: TYPE_ELEMENT.PART,
            })
          );
          const signatoriesList =
            responseSignatories.data.responseMessage.data.map((item) => ({
              ...item,
              typeElement: TYPE_ELEMENT.SIGNATORY,
            }));
          const dataParts = buildRows(partsList);
          const dataSignatories = buildRows(signatoriesList);
          const data = { columns, rows: [...dataParts, ...dataSignatories] };
          setData(data);
          setFilteredData(data);
        }
      } else {
        dispatch(resetPartListStatus());
        dispatch(resetSignatoriesListStatus());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBuildedRowsWithoutElementTypeData = (buildedRows, elementTypeName) =>
    buildedRows.filter((buildedRow) => buildedRow.type !== elementTypeName);

  // Use Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (partsListStatusDelete === "fetch") {
          let userId = "";
          if (filterByMe) {
            userId = JSON.parse(localStorage.getItem("payloadToken"))?.userId;
          }
          dispatch(fetchPartsListDelete({ userId }));
        }
        const partsList = partsListDelete.map((item) => ({
          ...item,
          typeElement: TYPE_ELEMENT.PART,
        }));
        const dataParts = buildRows(partsList);

        setData(prevData => {
          const newData = {
            columns,
            rows: [
              ...dataParts,
              ...getBuildedRowsWithoutElementTypeData(
                prevData.rows ?? [],
                TYPE_ELEMENT.PART.name
              ),
            ],
          };

          setFilteredData(newData);
          return newData;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [partsListDelete, partsListStatusDelete, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (signatoriesListStatusDelete === "fetch") {
          let userId = "";
          if (filterByMe) {
            userId = JSON.parse(localStorage.getItem("payloadToken"))?.userId;
          }
          dispatch(fetchSignatoriesListDelete({ userId }));
        }
        const signatoriesList = signatoriesListDelete.map((item) => ({
          ...item,
          typeElement: TYPE_ELEMENT.SIGNATORY,
        }));
        const dataSignatories = buildRows(signatoriesList);

        setData(prevData => {
          const newData = {
            columns,
            rows: [
              ...getBuildedRowsWithoutElementTypeData(
                prevData.rows ?? [],
                TYPE_ELEMENT.SIGNATORY.name
              ),
              ...dataSignatories,
            ],
          };
          
          setFilteredData(newData);
          return newData;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [signatoriesListDelete, signatoriesListStatusDelete, dispatch]);

  useEffect(() => {
    const loadingStatusName = "loading";
    if (
      partsListStatusDelete === loadingStatusName ||
      signatoriesListStatusDelete === loadingStatusName
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [partsListStatusDelete, signatoriesListStatusDelete]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Papelera"} />
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15, alignItems: "center", margin: "1% 0" }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
        <Col />
        <Col xs={"auto"}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<CheckCircleIcon />}
                onClick={handleFilterByPerson}
                checked={filterByMe}
              />
            }
            label="Solo eliminados por mí"
          />
        </Col>
      </Row>
      <Row className="dt-list-center-content">
        <MDBDataTableV5
          noRecordsFoundLabel={"No tiene elementos eliminados"}
          hover
          pagingTop
          searchBottom={false}
          entries={10}
          data={filteredData}
          infoLabel={["Mostrando", "a", "de", "elementos"]}
          fullPagination
          responsive={true}
          onSort={(value) => {
            onSortHTMLElement({
              excludesColumns: ["actions"],
              sort: value,
              filteredDataTable: filteredData,
              setFilteredDataTable: setFilteredData,
            });
          }}
        />
      </Row>
      <Menu
        id="basic-menu"
        anchorEl={anchorSelected}
        open={openSelected}
        onClose={handleCloseSelected}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {MENU_OPTIONS_DELETE.map((item) => (
          <MenuItem
            key={item.option}
            onClick={() => {
              handleManageOptionsMenu({
                option: item.option,
                element: selectedElement,
              });
            }}
          >
            <ListItemIcon sx={{ color: "#00374F" }}>{item.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Roboto",
                fontSize: "1.4rem",
                fontWeight: "regular",
                lineHeight: "2rem",
                color: "#676879",
              }}
              primary={`${item.title} ${
                item.option === 0
                  ? TYPE_ELEMENT[
                      selectedElement?.typeElement?.key
                    ]?.name?.toLowerCase()
                  : ""
              }`}
            />
          </MenuItem>
        ))}
      </Menu>
      <DetailBarPaperbask
        openDetailsDrawner={openDetailsDrawner}
        selectedElement={selectedElement}
        title={
          "Detalles de la " +
          TYPE_ELEMENT[selectedElement?.typeElement?.key]?.name.toLowerCase()
        }
        handleClose={() => {
          setOpenDetailsDrawner(false);
        }}
      />
      <ModalInfo
        title={`${selectedElement?.typeElement?.name}s`}
        responseData={responseData}
        onClose={() => setIsOpenModalResponse(false)}
        open={isOpenModalResponse}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={() => setIsOpenModalResponse(false)}
      />
      <ModalDecision
        title={"Eliminar"}
        message={`¿Estás seguro de eliminar definitivamente?`}
        agreeText={"Eliminar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          handleDelete(selectedElement);
        }}
        handleDisagree={() => setOpenModalDecisionDelete(false)}
        onClose={() => setOpenModalDecisionDelete(false)}
        open={openModalDecisionDelete}
      />
      <ModalInfoParts
        data={[selectedElement]}
        open={isOpenModalPartsInfo}
        handleClose={() => {
          setIsOpenModalPartsInfo(false);
          setSelectedElement(null);
        }}
      />
    </Container>
  );
};

const DetailBarPaperbask = ({
  openDetailsDrawner,
  handleClose,
  selectedElement,
  title = "Detalles de la parte",
}) => {
  const handleOpenDocument = async (actualDocument) => {
    try {
      const file = await getFileDocument(
        actualDocument.filenameInBucket,
        actualDocument.bucketName,
        false
      );

      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const finalFile = URL.createObjectURL(
        new Blob([bf], {
          type: "application/pdf",
        })
      );
      window.open(finalFile);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer anchor="right" open={openDetailsDrawner} onClose={handleClose}>
      <Container fluid style={{ width: "46.7rem", height: "inherit" }}>
        <Row className="col-flex mt-4">
          <Col md={10}>
            <span className="heading__primary-color">{title}</span>
          </Col>
          <Col md={1}>
            <CloseIcon
              fontSize="large"
              onClick={() => {
                handleClose();
              }}
            />
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <br />

        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            Fecha de creación:
          </Col>
          <Col className="caption">
            {moment(selectedElement?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Col>
        </Row>
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            Creado por:
          </Col>
          <Col className="caption">
            {`${selectedElement?.createdBy?.firstName || ""} ${
              selectedElement?.createdBy?.firstSurname || ""
            }`}
            {(!selectedElement?.createdBy?.firstName ||
              !selectedElement?.createdBy?.firstSurname) &&
              `${
                selectedElement?.createdBy?.companies[0]?.companyData
                  ?.companyName || ""
              }`}
          </Col>
        </Row>
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            Fecha y hora de eliminación:
          </Col>
          <Col md={"auto"} className="caption">
            {moment(selectedElement?.deletedAt).format("DD/MM/YYYY HH:mm:ss")}
          </Col>
        </Row>
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            Eliminado por:
          </Col>
          <Col className="caption">
            {`${selectedElement?.deletedBy?.firstName || ""} ${
              selectedElement?.deletedBy?.firstSurname || ""
            }`}
            {(!selectedElement?.deletedBy?.firstName ||
              !selectedElement?.deletedBy?.firstSurname) &&
              `${
                selectedElement?.deletedBy?.companies[0]?.companyData
                  ?.companyName || ""
              }`}
          </Col>
        </Row>
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            {`Unidad(es) corporativa(s)`}:
          </Col>
          <Col className="caption">
            {!selectedElement?.associatedCorporateUnits?.length
              ? "No hay unidades corporativas asociadas"
              : selectedElement?.associatedCorporateUnits
                  ?.map((uc) => uc?.name)
                  .join(", ")}
          </Col>
        </Row>
        {selectedElement?.modifiedBy && (
          <>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                Fecha y hora de última modificación:
              </Col>
              <Col md={"auto"} className="caption">
                {selectedElement?.updatedAt &&
                  moment(selectedElement?.updatedAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
              </Col>
            </Row>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                Última modificación hecha por:
              </Col>
              <Col className="caption">
                {`${selectedElement?.modifiedBy?.firstName || ""} ${
                  selectedElement?.modifiedBy?.firstSurname || ""
                }`}
                {(!selectedElement?.modifiedBy?.firstName ||
                  !selectedElement?.modifiedBy?.firstSurname) &&
                  `${
                    selectedElement?.modifiedBy?.companies[0]?.companyData
                      ?.companyName || ""
                  }`}
              </Col>
            </Row>
          </>
        )}
        {selectedElement?.filenameInBucket && (
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Nombre del documento:
            </Col>
            <Col className="caption__other-Color-Active">
              <span onClick={() => handleOpenDocument(selectedElement)}>
                {selectedElement?.cerlFileName}
              </span>
            </Col>
          </Row>
        )}
      </Container>
    </Drawer>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalInfoParts = ({ data, open, handleClose }) => {
  const containerRef = useRef(null);
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Nombre
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Identificación
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Unidades corporativas
        </label>,
      ],
      field: "unitsCoporative",
    },
  ];
  const [dataTableInfo, setDataTableInfo] = useState({ rows: [], columns });

  const getName = (item) => {
    return `${item?.firstName || ""} ${item?.lastName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
  };

  const buildRows = (data) => {
    if (!data.length) return [];
    return data.map((item) => {
      let name = getName(item);
      let id = `${item?.documentType}. ${item?.documentNumber}`;
      let contact = `${item?.email || ""}`;
      let address = item?.physicalAddress;
      let country = item?.country;
      let city = `${item?.province || ""}, ${item?.city || ""}`;
      let number = `+${item?.countryCodeNumber || ""} ${item?.phone || ""}`;
      if (item?.typePart === "JURIDICA") {
        name = item?.business?.businessName;
        id = `Nit. ${item?.business?.nit}`;
      }
      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;
      return {
        name,
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            <Col xs={"auto"}>
              <PopoverActions
                containerRef={containerRef}
                parentId="signatories-content"
                type="large"
                classNameButton=""
                description={
                  <div>
                    <Row style={{ top: 10 }}>
                      <span className="home-init-cards-row__title-row">
                        Más datos de contacto
                      </span>
                    </Row>
                    <Row>
                      <span className="caption" style={{ width: "auto" }}>
                        {address}{" "}
                      </span>
                    </Row>
                    <Row>
                      <span className="caption" style={{ width: "auto" }}>
                        {country} {city}
                      </span>
                    </Row>
                    <Row>
                      <span className="caption" style={{ width: "auto" }}>
                        {number}
                      </span>
                    </Row>
                    <Row>
                      <span className="caption" style={{ width: "auto" }}>
                        {telephone}
                      </span>
                    </Row>
                  </div>
                }
                icon={<InfoOutlinedIcon fontSize="large" />}
              />
            </Col>
          </Row>
        ),
        unitsCoporative: !item?.associatedCorporateUnits?.length
          ? "No hay unidades corporativas asociadas"
          : item?.associatedCorporateUnits?.map((uc) => uc?.name).join(", "),
      };
    });
  };
  useEffect(() => {
    if (open) {
      const rows = buildRows(data);
      setDataTableInfo({ rows, columns });
    }
  }, [open, data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="lg"
    >
      <Container id="signatories-content" ref={containerRef}>
        <br />
        <Row
          className="justify-content-md-end"
          style={{ alignItems: "center" }}
        >
          <Col>
            <label className="heading__primary-color">Visualizar</label>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Col>
        </Row>
        <Row className="dt-list-center-content">
          <MDBDataTableV5
            hover
            pagingTop
            searchBottom={false}
            entries={1}
            data={dataTableInfo}
            entriesLabel=""
            info={false}
            sortable={false}
            paging={false}
          />
        </Row>
      </Container>
    </Dialog>
  );
};
