import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import { useNavigate } from "react-router-dom";
import {
  deletePermanentlyHeading,
  findAllHeadings,
  updateHeading,
} from "../../../../../../services/admin/documents/headingsServices";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../unitCorporativeSlice";
import { CODES } from "../../../../../../utils/codes";
import LoadingContent from "../../../../../../components/loadingContent";
import { getFileDocument } from "../../../../../../services/documents/documentsRequest";
import moment from "moment";
import "moment/locale/es";
import { CustomToolTip } from "../../../../../../components/Tooltip/Tooltip";
import { getCompanyPlan } from "../../../../../../parts/storage/storageSlice";

const MenuItemComponent = ({ handleClick, menuElement, ...props }) => {
  return (
    <div {...props}>
      <MenuItem
        onClick={() => {
          handleClick(menuElement.selectionClick);
        }}
        style={{ width: "351px" }}
      >
        <ListItemIcon>{menuElement.icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontFamily: "Roboto",
            fontSize: "1.4rem",
            fontWeight: "regular",
            lineHeight: "2rem",
            color: "#676879",
          }}
          primary={menuElement.title}
        />
      </MenuItem>
    </div>
  );
};

const FilterBar = ({
  openDrawer,
  setOpenDrawer,
  listOptions,
  handleAction,
}) => {
  const [corporateUnitsSelected, setCorporateUnitsSelected] = useState([]);

  const handleChecked = (item, event) => {
    if (event) {
      setCorporateUnitsSelected((oldArray) => [...oldArray, item]);
    } else {
      setCorporateUnitsSelected((oldArray) =>
        oldArray.filter((old) => old._id !== item._id)
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
      }}
    >
      <Container fluid style={{ width: "25rem", height: "inherit" }}>
        <br />
        <Row style={{ alignItems: "center" }}>
          <Col>
            <span className="home-init-cards-row__title-row">Filtrar</span>
          </Col>
          <Col xs={"auto"}>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="header-corporate-unit"
            >
              <span className="label__subtitle">Unidades corporativas</span>
            </AccordionSummary>
            <AccordionDetails>
              {listOptions.map((filter) => (
                <Form.Group
                  className="mb-1"
                  controlId={filter.label}
                  key={filter._id || filter.label}
                >
                  <Form.Check
                    type="checkbox"
                    style={{
                      fontSize: "1.3rem",
                    }}
                    label={filter.name}
                    checked={corporateUnitsSelected.some(
                      (uc) => uc._id === filter._id
                    )}
                    onChange={(e) => {
                      handleChecked(filter, e.target.checked);
                    }}
                  />
                </Form.Group>
              ))}
            </AccordionDetails>
          </Accordion>
        </Row>
        <br />
        <Row
          style={{
            position: "absolute",
            bottom: 15,
            justifyContent: "center",
            width: "inherit",
          }}
        >
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__secondary-color"
              onClick={() => {
                setCorporateUnitsSelected([]);
                setOpenDrawer(false);
                handleAction([]);
              }}
            >
              Limpiar filtro
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              type="button"
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={() => {
                setOpenDrawer(false);
                handleAction(corporateUnitsSelected);
              }}
            >
              Aplicar
            </Button>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

const getTimeCreated = (date) => {
  // Dos horas en milisegundos.
  const twoHours = 7200000;
  const differenceDays = new Date() - new Date(date);
  return differenceDays < twoHours;
};

const ImageCard = ({ title, handleEdit, handleView, documentImage }) => {
  return (
    <Container key={documentImage?._id} fluid>
      <Row
        style={{
          alignItems: "center",
          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
          padding: 5,
        }}
      >
        <Col>
          <Row>
            <span className="home-init-cards-row__title-row">{title}</span>
          </Row>
          <Row>
            <span className="caption__disabled">
              Fecha de creación:{" "}
              {moment(documentImage?.createdAt).format("DD/MM/YYYY")}
            </span>
          </Row>
        </Col>
        <Col xs={"auto"}>
          {getTimeCreated(documentImage.createdAt) && (
            <label
              className="caption custom-badges__secundary"
              style={{
                width: "max-content",
                margin: "0",
                zIndex: "1",
              }}
            >
              Nuevo
            </label>
          )}
        </Col>
        <Col xs={"auto"}>
          <IconButton
            aria-label="edit"
            className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
            sx={{ borderRadius: "8px" }}
            onClick={handleView}
          >
            <VisibilityOutlinedIcon fontSize="large" />
          </IconButton>
        </Col>
        <Col xs={"auto"}>
          <IconButton
            aria-label="edit"
            className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
            sx={{ borderRadius: "8px" }}
            onClick={handleEdit}
          >
            <EditOutlinedIcon fontSize="large" />
          </IconButton>
        </Col>
      </Row>
    </Container>
  );
};

const ImageCardDelete = ({ title, handleAction, documentImage }) => {
  return (
    <Container
      key={documentImage?._id}
      fluid
      style={{
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
        padding: "1rem",
      }}
    >
      <Row>
        <Col xs={"auto"}>
          <span
            className="home-init-cards-row__title-row"
            style={{ height: "auto" }}
          >
            {title}
          </span>
        </Col>
      </Row>
      <br />
      <Row>
        <span className="caption__disabled">Fecha de eliminación:</span>
        <span className="caption__disabled">
          {moment(documentImage?.updatedAt).format("DD/MM/YYYY")}
        </span>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <Tooltip title={<h5>Visualizar membrete</h5>}>
            <IconButton
              className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
              aria-label="view"
              sx={{ borderRadius: "8px" }}
              onClick={() => handleAction(0)}
            >
              <VisibilityOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Col>
        <Col xs={"auto"}>
          <Tooltip title={<h5>Restaurar membrete</h5>}>
            <IconButton
              className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
              aria-label="restore"
              sx={{ borderRadius: "8px" }}
              onClick={() => handleAction(1)}
            >
              <RestoreFromTrashOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Col>
        <Col xs={"auto"}>
          <Tooltip title={<h5>Eliminar definitivamente</h5>}>
            <IconButton
              className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
              aria-label="delete"
              sx={{ borderRadius: "8px" }}
              onClick={() => handleAction(2)}
            >
              <DeleteOutlinedIcon fontSize="large" color="error" />
            </IconButton>
          </Tooltip>
        </Col>
      </Row>
    </Container>
  );
};

export default function ManageHeadingsModule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ID_STATUS_DELETE = "621fdcd7e1b7dfa85f10bf46";

  const ID_STATUS_NEW = "64a74534ec9d9505220adfe5";

  const statusUnitsCorporativesList = useSelector(getUnitsCorporativesStatus);
  const unitsCorporativesList = useSelector(getUnitsCorporativesList);
  const companyPlan = useSelector(getCompanyPlan);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSortMenu = Boolean(anchorElSort);
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [dataHeadings, setDataHeadings] = useState([]);
  const [dataHeadingsDelete, setDataHeadingsDelete] = useState([]);
  const [filterDataHeadings, setFilterDataHeadings] = useState([]);
  const [filterDataHeadingsDelete, setFilterDataHeadingsDelete] = useState([]);
  const [isOnlyCorporteUnit, setIsOnlyCorporteUnit] = useState(false);
  const [listCorporateUnits, setListCorporateUnits] = useState([]);
  const [isLoadingFindAll, setIsLoadingFindAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [openModalDeletePermanently, setOpenModalDeletePermanently] =
    useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [isUpdateInfo, setIsUpdateInfo] = useState(false);
  const [openModalPermissions, setOpenModalPermissions] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  //Deletes
  const [openModalRestore, setOpenModalRestore] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const menuElement = [
    {
      selectionClick: 0,
      title: "Alfabéticamente (A-Z)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
    },
    {
      selectionClick: 1,
      title: "Alfabéticamente (Z-A)",
      icon: (
        <SortOutlinedIcon fontSize="large" className="heading__primary-color" />
      ),
    },
    {
      selectionClick: 2,
      title: "Fecha (Reciente - Antigua)",
      icon: (
        <CalendarMonthOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
    {
      selectionClick: 3,
      title: "Fecha (Antigua - Reciente)",
      icon: (
        <CalendarMonthOutlinedIcon
          fontSize="large"
          className="heading__primary-color"
        />
      ),
    },
  ];

  //News

  const handleSortByAlpha = (sort) => {
    let filter = [];
    let filterDelete = [];

    const compareData = (firts, second) =>
      firts.name.localeCompare(second.name);

    if (sort === "asc") {
      filter = isOnlyCorporteUnit
        ? filterDataHeadings.sort(compareData)
        : filterDataHeadings.map((data) => ({
            ...data,
            headings: data.headings.sort(compareData),
          }));

      filterDelete = filterDataHeadingsDelete.sort(compareData);
    } else if (sort === "desc") {
      filter = isOnlyCorporteUnit
        ? filterDataHeadings.sort((a, b) => compareData(b, a))
        : filterDataHeadings.map((data) => ({
            ...data,
            headings: data.headings.sort((a, b) => compareData(b, a)),
          }));

      filterDelete = filterDataHeadingsDelete.sort((a, b) => compareData(b, a));
    } else {
      return;
    }

    setFilterDataHeadings(filter);
    setFilterDataHeadingsDelete(filterDelete);
  };

  const handleSortByDate = (sort) => {
    let filter = [];
    let filterDelete = [];
    const compareDates = (firstDate, secondDate) =>
      new Date(firstDate?.createdAt) - new Date(secondDate?.createdAt);
    const compareDatesDelete = (firstDate, secondDate) =>
      new Date(firstDate?.updatedAt) - new Date(secondDate?.updatedAt);

    if (sort === "asc") {
      filter = filterDataHeadings.map((item) => ({
        ...item,
        headings: item.headings.sort(compareDates),
      }));

      filterDelete = filterDataHeadingsDelete.sort(compareDatesDelete);
    } else if (sort === "desc") {
      filter = filterDataHeadings.map((item) => ({
        ...item,
        headings: item.headings.sort((a, b) => compareDates(b, a)),
      }));

      const filterDataHeadingsDeleteCopy = [...filterDataHeadingsDelete]; // Create a copy to avoid mutating the original array
      filterDelete = filterDataHeadingsDeleteCopy.sort((a, b) =>
        compareDatesDelete(b, a)
      );
    } else {
      filter = filterDataHeadings;
      filterDelete = filterDataHeadingsDelete;
    }
    setFilterDataHeadings(filter);
    setFilterDataHeadingsDelete(filterDelete);
  };

  const handleSortElement = (event) => {
    setAnchorElSort(null);
    switch (event) {
      case 0:
        handleSortByAlpha("asc");
        break;
      case 1:
        handleSortByAlpha("desc");
        break;
      case 2:
        handleSortByDate("desc");
        break;
      case 3:
        handleSortByDate("asc");
        break;
      default:
        break;
    }
  };

  const sortCorporateUnits = (unitsCorporatives) => {
    const list = [...unitsCorporatives];
    //Ordenar alfabeticamente las unidades corporativas por nombre
    list.sort((a, b) => a.name.localeCompare(b.name));

    return list;
  };

  const buildHeadings = (data) => {
    const unitsCorporatives = sortCorporateUnits(unitsCorporativesList);
    const headings = [];
    const headingsDelete = data.filter(
      (heading) => heading?.status?._id === ID_STATUS_DELETE
    );
    for (const corporateUnit of unitsCorporatives) {
      const headingsByCorporateUnit = data.filter((item) =>
        item.applicableCorporateUnitIds.some(
          (uc) => uc._id === corporateUnit._id
        )
      );
      const headingsActive = headingsByCorporateUnit.filter(
        (heading) => heading?.status?._id === ID_STATUS_NEW
      );
      headings.push({
        ...corporateUnit,
        headings: headingsActive,
      });
    }
    return {
      headings,
      headingsDelete,
    };
  };

  const fetchData = async () => {
    try {
      setIsLoadingFindAll(true);
      const companyId = localStorage.getItem("company");
      const response = await findAllHeadings({ companyId });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        response.data.responseCode === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setResponseData(response);
        setOpenModalPermissions(true);
      }
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        return response.data.responseMessage.data;
      }
      return [];
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFindAll(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (statusUnitsCorporativesList === "success") {
        const response = await fetchData();
        setListCorporateUnits(unitsCorporativesList);
        let headings = [];
        let headingsDelete = [];
        if (unitsCorporativesList.length > 1) {
          const dataBuilded = buildHeadings(response);
          headings = dataBuilded.headings;
          headingsDelete = dataBuilded.headingsDelete;
          setIsOnlyCorporteUnit(false);
        } else {
          headings = response.filter(
            (heading) => heading.status._id === ID_STATUS_NEW
          );
          headingsDelete = response.filter(
            (heading) => heading.status._id === ID_STATUS_DELETE
          );
          setIsOnlyCorporteUnit(true);
        }
        setDataHeadings(headings);
        setDataHeadingsDelete(headingsDelete);
        setFilterDataHeadings(headings);
        setFilterDataHeadingsDelete(headingsDelete);
      }
    })();
  }, [statusUnitsCorporativesList, unitsCorporativesList, isUpdateInfo]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isStorageEnable) {
        setDisableSave(true);
      }
    }
  }, [companyPlan]);

  // Delete actions

  const handleRestore = async (data) => {
    try {
      setIsLoading(true);
      const companyId = localStorage.getItem("company");
      const response = await updateHeading({
        letterheadId: data?._id,
        status: ID_STATUS_NEW,
        companyId,
      });
      setResponseData(response);
      if (
        response.data.success &&
        response.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const corporateUnits =
          response?.data?.responseMessage?.data?.applicableCorporateUnitIds;
        let message;
        if (
          response?.data?.responseMessage?.data?.applicableCorporateUnitIds
            .length > 1
        ) {
          message = (
            <Container fluid className="caption">
              <Row>
                El membrete se ha restaurado con éxito y está disponible para
                las siguientes unidades corporativas:
              </Row>
              <br />
              <Row style={{ paddingLeft: "5%" }}>
                <ul>
                  {corporateUnits.map(({ _id, name }) => (
                    <li key={_id}>{name}</li>
                  ))}
                </ul>
              </Row>
            </Container>
          );
        } else {
          message = "El membrete se ha restaurado con éxito";
        }
        setResponseData({
          ...response,
          data: {
            responseMessage: { message },
          },
        });
        setIsUpdateInfo((oldState) => !oldState);
      }
      setOpenModalRestore(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePermanently = async (data) => {
    try {
      setIsLoading(true);
      setOpenModalDelete(false);
      const companyId = localStorage.getItem("company");
      const response = await deletePermanentlyHeading({
        letterheadId: data?._id,
        companyId,
      });
      setResponseData(response);
      setOpenModalDeletePermanently(true);
      if (
        response.status !== CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        response.data.responseCode !== CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsUpdateInfo((oldState) => !oldState);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = async (data) => {
    try {
      setIsLoading(true);
      const response = await getFileDocument(
        data.documentPdfId.filenameInBucket,
        data.documentPdfId.bucketName,
        false
      );
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const bufferData = response?.data?.responseMessage?.buffer?.data;
        if (bufferData) {
          const blob = new Blob([new Uint8Array(bufferData).buffer], {
            type: "application/pdf",
          });
          const file = URL.createObjectURL(blob);
          window.open(file);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActionDelete = (event, item) => {
    switch (event) {
      case 0:
        handleView(item);
        break;
      case 1:
        handleRestore(item);
        break;
      case 2:
        setItemSelected(item);
        setOpenModalDelete(true);
        break;
      default:
        break;
    }
  };

  const handleFilterByCorporateUnit = (corporateUnits) => {
    if (!corporateUnits.length) {
      setFilterDataHeadings(dataHeadings);
      return;
    }

    const filterCorporateUnits = (item) =>
      corporateUnits.some((uc) => uc._id === item._id);

    // Se filtra y se ordena los membretes por unidad corporativa

    const filter = dataHeadings.filter(filterCorporateUnits);

    // Se filtra y se ordena los membretes eliminados por unidad corporativa
    const filterDelete = dataHeadingsDelete.filter((item) =>
      item.applicableCorporateUnitIds.some(filterCorporateUnits)
    );

    const filterDeleteCopy = [...filterDelete];
    const filterSortDelete = filterDeleteCopy.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    // Se actualizan los datos

    setFilterDataHeadings(filter);

    setFilterDataHeadingsDelete(filterSortDelete);
  };

  const handleUploadDoc = (file) => {
    if (!file?.name) return;
    setDocumentData(file);
    navigate("create", {
      state: { document: file },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusUnitsCorporativesList === "fetch") {
          dispatch(fetchUnitsCoporativesList());
        }
      } catch (error) {
        console.log("========Fetch Units Corporatives Error========");
        console.log(error);
        console.log("========Finish Fetch Units Corporatives Error========");
      }
    };
    fetchData();
  }, [statusUnitsCorporativesList, dispatch]);

  useEffect(() => {
    if (statusUnitsCorporativesList === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusUnitsCorporativesList]);

  return (
    <Container fluid className="custom-container-scroll">
      {(isLoadingFindAll || isLoading) && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title={"Membretes y logos"} />
        </Col>
      </Row>
      <br />
      <Row>
        <label className="caption">
          Elige y define las imágenes que podrán ser usadas como membretes en
          los documentos.
        </label>
      </Row>
      <br />
      <Row style={{ rowGap: 20 }}>
        <Col xs={"auto"}>
          {disableSave ? (
            <CustomToolTip
              direction="left"
              message="La compañía ha alcanzado el máximo de su plan de almacenamiento, contacta con el usuario administrador"
            >
              <Button
                variant="contained"
                type="button"
                disabled
                className={"custom-input__button__primary-color"}
                startIcon={<AddIcon fontSize="large" />}
              >
                Nuevo membrete
              </Button>
            </CustomToolTip>
          ) : (
            <Button
              variant="contained"
              type="button"
              className="custom-input__button__primary-color"
              startIcon={<AddIcon fontSize="large" />}
              onClick={() => setOpenModalUploadDocument(true)}
            >
              Nuevo membrete
            </Button>
          )}
        </Col>
        <Col></Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<SortOutlinedIcon fontSize="large" />}
            endIcon={<KeyboardArrowDownOutlinedIcon fontSize="large" />}
            onClick={(event) => setAnchorElSort(event.currentTarget)}
          >
            Ordenar
          </Button>
          <Menu
            anchorEl={anchorElSort}
            open={openSortMenu}
            onClose={() => setAnchorElSort(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuElement.map((item) => (
              <MenuItemComponent
                handleClick={handleSortElement}
                menuElement={item}
                key={item.selectionClick}
              />
            ))}
          </Menu>
        </Col>
        {!isOnlyCorporteUnit && (
          <Col xs={"auto"}>
            <Button
              variant="contained"
              type="button"
              className="custom-input__button__secondary-color"
              startIcon={<FilterAltOutlinedIcon fontSize="large" />}
              onClick={() => setOpenDrawerFilter(true)}
            >
              Filtrar
            </Button>
          </Col>
        )}
      </Row>
      <br />
      <br />
      {filterDataHeadings?.length < 1 && (
        <span className="caption">
          No se han encontrados membretes en tu compañía
        </span>
      )}
      {filterDataHeadings?.length > 0 && (
        <>
          {!isOnlyCorporteUnit && (
            <Row id="headings-news">
              {filterDataHeadings?.length > 0 &&
                filterDataHeadings.map((unitCorporative) => (
                  <div key={unitCorporative?._id}>
                    <Accordion className="custom-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={`header-corporate-unit-${unitCorporative?._id}`}
                      >
                        <Row style={{ alignItems: "center", width: "100%" }}>
                          <Col xs={"auto"}>
                            <span className="heading__primary-color">
                              {unitCorporative?.name || ""}
                            </span>
                          </Col>
                        </Row>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Row style={{ rowGap: 25 }}>
                          {unitCorporative?.headings?.length > 0 &&
                            unitCorporative.headings.map((heading) => (
                              <Col
                                key={heading?._id}
                                xl={4}
                                lg={6}
                                md={8}
                                xs={12}
                              >
                                <ImageCard
                                  title={heading.name}
                                  handleEdit={() =>
                                    navigate(`edit/${heading?._id}`)
                                  }
                                  handleView={() => handleView(heading)}
                                  documentImage={heading}
                                />
                              </Col>
                            ))}
                          {unitCorporative?.headings?.length === 0 && (
                            <span className="caption">
                              No hay membretes en esta unidad corporativa
                            </span>
                          )}
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
            </Row>
          )}
          {isOnlyCorporteUnit && (
            <Row id="headings-news" style={{ rowGap: 25 }}>
              {filterDataHeadings.map((heading) => (
                <Col key={heading?._id} xl={4} lg={6} md={8} xs={12}>
                  <ImageCard
                    title={heading.name}
                    handleEdit={() => navigate(`edit/${heading?._id}`)}
                    handleView={() => handleView(heading)}
                    documentImage={heading}
                  />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
      {filterDataHeadingsDelete?.length > 0 && (
        <Row id="headings-deleted">
          <Accordion className="custom-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="header-corporate-unit-headings"
            >
              <Row style={{ alignItems: "center", width: "100%" }}>
                <Col xs={"auto"}>
                  <span
                    style={{ color: "red" }}
                    className="heading__primary-color"
                  >
                    Membretes eliminados
                  </span>
                </Col>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              <Row style={{ rowGap: 25 }}>
                {filterDataHeadingsDelete?.length > 0 &&
                  filterDataHeadingsDelete.map((headingDelete) => (
                    <Col key={headingDelete?._id} lg={4} md={6} xs={12}>
                      <ImageCardDelete
                        title={headingDelete?.name}
                        handleAction={(event) =>
                          handleActionDelete(event, headingDelete)
                        }
                        imageUrl={headingDelete.previewImage}
                        documentImage={headingDelete}
                      />
                    </Col>
                  ))}
              </Row>
            </AccordionDetails>
          </Accordion>
        </Row>
      )}
      <FilterBar
        openDrawer={openDrawerFilter}
        setOpenDrawer={setOpenDrawerFilter}
        listOptions={listCorporateUnits}
        handleAction={handleFilterByCorporateUnit}
      />
      <ModalUploadDocs
        open={openModalUploadDocument}
        title={"Selecciona el documento donde tienes el logo o membrete"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        message={"El archivo debe estar en formato word"}
        documentData={documentData}
        setDocumentData={handleUploadDoc}
        onClose={() => {
          setOpenModalUploadDocument(false);
          setDocumentData({});
        }}
        typeDocument={"ONLY_WORD"}
        description="Asegúrate que el documento solo contenga los membretes que quieres usar y en la posición que los quieres ver"
        messageTypeValidation="*Solo se permiten formatos .docx o .doc"
      />
      {/* Deletes */}
      <ModalInfo
        open={openModalRestore}
        confirmationText="Aceptar"
        onClose={() => setOpenModalRestore(false)}
        responseData={responseData}
        title={
          responseData.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
            ? "Permisos de membretes"
            : "Membrete restaurado"
        }
        handleCloseDefault={() => setOpenModalRestore(false)}
        buttonClose={true}
      />
      <ModalDecision
        agreeText={"Eliminar"}
        disagreeText={"Cancelar"}
        handleAgree={() => handleDeletePermanently(itemSelected)}
        handleDisagree={() => {
          window.location.href = "#headings-news";
          setOpenModalDelete(false);
        }}
        message={
          "¿Seguro quieres eliminar definitivamente el membrete? Si lo eliminas no será posible recuperarlo."
        }
        onClose={() => setOpenModalDelete(false)}
        open={openModalDelete}
        title={"Eliminar definitivamente"}
      />
      <ModalInfo
        open={openModalDeletePermanently}
        confirmationText="Aceptar"
        onClose={() => setOpenModalDeletePermanently(false)}
        responseData={responseData}
        title={"Membrete eliminado permanentemente"}
        handleCloseDefault={() => setOpenModalDeletePermanently(false)}
      />
      <ModalInfo
        open={openModalPermissions}
        confirmationText="Aceptar"
        onClose={() => navigate(-1)}
        responseData={responseData}
        title={"Permisos en membretes"}
        handleCloseDefault={() => navigate(-1)}
        buttonClose={true}
      />
    </Container>
  );
}
