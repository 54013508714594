import { QuestionFormTypes } from "../../../services/documents/formCustomization/constants/questionFormTypes";
import { TypeInputLong } from "../TypeInputLong";
import { TypeInputShort } from "../TypeInputShort";
import { TypeInputShortCheck } from "../TypeInputShortCheck";
import { TypeInputCheckList } from "../TypeInputCheckList";
import { TypeInputList } from "../TypeInputList";
import { TypeInputDate } from "../TypeInputDate";
import { TypeInputDateCheck } from "../TypeInputDateCheck";
import PropTypes from "prop-types";

// BEGINNING OF TYPE DEFINITIONS
/**
 * The type of the question.
 *
 * @typedef {Object} QuestionType
 * @property {string} _id The id of the question.
 * @property {string} value The value of the question.
 * @property {string} [label] The label of the question.
 * @property {Array<string>} [options] The options of the question.
 * @property {{ required: { value: boolean }, maxLength?: { value: number } }} validations The validations of the question.
 */

// END OF TYPE DEFINITIONS

const { INPUT, INPUT_CHECKBOX, LIST, LIST_CHECKBOX, DATE, DATE_CHECKBOX, LIST_CHECKBOX_NO_USE } =
  QuestionFormTypes;

/**
 * The types of the input forms.
 *
 * @readonly
 * @enum {string}
 */
export const AllowedInputTypes = Object.freeze({
  INPUT,
  INPUT_CHECKBOX,
  LIST,
  LIST_CHECKBOX,
  DATE,
  DATE_CHECKBOX,
  LIST_CHECKBOX_NO_USE
});

/**
 * Factory for the input types.
 *
 * @function
 * @param {Object} props The props for the input factory.
 * @param {string} props.type The type of the input, must be one of the `AllowedInputTypes`.
 * @param {Object} props.inputProps The props for the input.
 * @param {QuestionType} props.inputProps.question The question props.
 * @param {(question: QuestionType) => void} props.inputProps.onDataChange The function to call when the data changes.
 * @param {() => void} props.inputProps.onDelete The function to call when the question is deleted.
 * @returns {JSX.Element} The input form.
 */
const TypeInputFactory = ({ type, inputProps }) => {
  switch (type) {
    case INPUT:
      if (inputProps.question.validations.maxLength?.value === 250) {
        return <TypeInputShort {...inputProps} />;
      } else {
        return <TypeInputLong {...inputProps} />;
      }
    case INPUT_CHECKBOX:
      return <TypeInputShort {...inputProps} />;
    case LIST:
      return <TypeInputList {...inputProps} />;
    case LIST_CHECKBOX:
      return <TypeInputCheckList {...inputProps} />;
    case DATE:
      return <TypeInputDate {...inputProps} />;
    case DATE_CHECKBOX:
      return <TypeInputDate {...inputProps} />;
    case LIST_CHECKBOX_NO_USE:
      return <></>;
    default:
      throw new Error(`Type ${type} is not a valid input type.`);
  }
};

TypeInputFactory.propTypes = {
  type: PropTypes.oneOf(Object.values(AllowedInputTypes)).isRequired,
  inputProps: PropTypes.shape({
    question: PropTypes.shape({
      value: PropTypes.string.isRequired,
      validations: PropTypes.shape({
        required: PropTypes.shape({
          value: PropTypes.bool.isRequired,
        }).isRequired,
        maxLength: PropTypes.shape({
          value: PropTypes.number.isRequired,
        }),
      }).isRequired,
      label: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
    }),
    onDataChange: PropTypes.func,
    onDelete: PropTypes.func,
  }).isRequired,
};

export default TypeInputFactory;
