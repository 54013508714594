import React, { useState } from "react";
import { removeHtmlTags } from "../../utils/partsCorrection";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
export const CopyTextComponent = ({ text = "" }) => {
  const defaultColors = {
    ia: {
      colorIcons: "#17ccbd",
      classNameText: "custom-input__button__withOutBackground_ia",
    },
    dark: {
      colorIcons: (theme) => theme.palette.grey[500],
      classNameText: "custom-input__button__withOutBackground",
    },
  };
  const [copied, setCopied] = useState(false);
  const handleCopyText = ({ text = "" }) => {
    navigator.clipboard
      .writeText(removeHtmlTags(text))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Error al copiar al portapapeles:", err));
  };
  return (
    <IconButton
      title={copied ? "¡Copiado!" : "Copiar texto"}
      aria-label="close"
      onClick={() => handleCopyText({ text: text })}
      sx={{
        color: defaultColors["dark"].colorIcons,
        padding: 0,
      }}
    >
      {copied ? (
        <AssignmentTurnedInOutlinedIcon />
      ) : (
        <ContentCopyOutlinedIcon />
      )}
    </IconButton>
  );
};
