import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ProgressBarCapacity from "../../components/TimelineBar/ProgressBarCapacity";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import {
  getListBackgroundProcesses,
  updateListBackgroundProcesses,
} from "./backgroundProcessSlice";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SvgIconUploading from "./SvgIconLoading";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
const MODULES = {
  "Otros documentos": {
    name: "Otros documentos",
    icon: (
      <FilePresentOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  Contratos: {
    name: "Contratos",
    icon: (
      <InsertDriveFileOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  "Contratos guardados": {
    name: "Contratos guardados",
    icon: (
      <InsertDriveFileOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  Informes: {
    name: "Informes",
    icon: (
      <FeedOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  Resumen: {
    name: "Resumen",
    icon: (
      <DescriptionOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  "Crear parte desde certificado": {
    name: "Crear parte desde certificado",
    icon: (
      <DescriptionOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },

  "Crear minuta desde un contrato": {
    name: "Crear minuta desde un contrato",
    icon: (
      <FeedOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
  "Crear cláusula desde un contrato": {
    name: "Crear cláusula desde un contrato",
    icon: (
      <ExtensionOutlinedIcon
        className="text__primary-color"
        sx={{ fontSize: "3rem" }}
      />
    ),
  },
};

/**
 * Represents the component for view process background ia.
 * @author [Faber Alberto Hoyos Ordosgoitia]
 * @method
 */

export default function ViewBackground() {
  // Navigate
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();

  const backgroundData = useSelector(getListBackgroundProcesses);

  const [title, setTitle] = useState("Cargando archivos");
  const [perBarLoading, setPerBarLoading] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [dataProccess, setDataProccess] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Builds and processes rows based on the provided data.
   * @param {Object} data - The data object containing items to process.
   * @returns {Array} An array of processed rows.
   * @example
   * // Example input data
   * const inputData = {
   *     processName1: {
   *         processName: 'processName1',
   *         groupName: 'groupName1',
   *         items: [
   *             {
   *               id: '11130640-2470-4b17-8251-696c85dab5d4',
   *               nameFile: 'file1.pdf',
   *               userLeft: true,
   *               isFetchingInfo: true,
   *               finishProcessing: false,
   *               navigationInfo: {},
   *               isErrorProcessing: false,
   *             },
   *         ]
   *     },
   * };
   * // Call the function with example input data
   * const processedRows = buildRowsProccess(inputData);
   * // Output:
   * // processedRows will be an array of processed rows based on the input data,
   * // with only items where userLeft is true and additional properties added.
   * // For example:
   * [
   *     {
   *         processName: 'processName1',
   *         items: [
   *             { groupName: 'processName1', title: 'file1.pdf', icon: 'icon_url', isLoading: true }
   *         ]
   *     },
   * ]
   */
  const buildRowsProccess = useCallback((data) => {
    const filterData = Object.values(data).filter((el) => el.items.length);

    const list = filterData.map((el) => ({
      ...el,
      items: el.items
        .filter((i) => i.userLeft)
        .map((i) => ({
          ...i,
          groupName: el?.processName,
          title: i?.nameFile,
          icon: MODULES?.[el.groupName]?.icon,
          isLoading: i?.isFetchingInfo && !i?.finishProcessing,
        })),
    }));

    return list;
  }, []);

  useEffect(() => {
    if (backgroundData) {
      const data = buildRowsProccess(backgroundData);
      setDataProccess(data);
    }
  }, [backgroundData, buildRowsProccess]);

  useEffect(() => {
    if (dataProccess.length) {
      const filterData = dataProccess.map((d) => d.items).flat();
      const currentSizeLoading = filterData.length;
      const dataFinish = filterData.filter((i) => !i.isLoading).length;
      const perLoading = (dataFinish * 100) / currentSizeLoading;
      setTitle(!!dataFinish ? "Archivos cargados" : "Cargando archivos");
      setPerBarLoading(perLoading);
      const isDataProccess = Boolean(filterData.length);
      setIsOpen(isDataProccess);
    } else {
      setIsOpen(false);
    }
  }, [dataProccess]);

  return (
    isOpen && (
      <Container
        className={`overflow-y-auto fluid card-background background__proccess__container`}
      >
        <Row className="text__primary-color-content align-items-center">
          <Col>{title}</Col>
          <Col xs="auto">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <ExpandLessIcon
                  className="text__primary-color icon-rotate-mid"
                  fontSize="large"
                />
              ) : (
                <ExpandMoreIcon
                  className="text__primary-color icon-rotate-mid"
                  fontSize="large"
                />
              )}
            </IconButton>
          </Col>
        </Row>
        {isExpanded && (
          <>
            <Row className="mt-4">
              <Col>
                <ProgressBarCapacity used={perBarLoading} capacity={100} />
              </Col>
            </Row>
            {dataProccess.map((group) => (
              <div key={uuidv4()}>
                {group.items.length > 0 && (
                  <>
                    <Row>
                      <Col className="text__disable-color-medium">
                        {group?.groupName || ""}
                      </Col>
                    </Row>
                    {group.items.map((item) => (
                      <Row
                        key={item?.id}
                        className="justify-content-center align-items-center select-folder"
                        style={{ padding: "1rem 0rem" }}
                        onClick={() => {
                          if (!item.isFetchingInfo) {
                            if (item?.isErrorProcessing) {
                              return dispatch(
                                updateListBackgroundProcesses({
                                  type: "DELETE",
                                  module: item?.groupName,
                                  id: item?.id,
                                })
                              );
                            }
                            const { navigationInfo } = item || {};
                            const { url = "", state = "" } =
                              navigationInfo || {};
                            if (state) {
                              navigate(url, { state });
                            } else {
                              navigate(url);
                            }
                          }
                        }}
                      >
                        <Col xs="auto">{item?.icon}</Col>
                        <Col className="text__primary-color-content">
                          {item?.title || ""}
                        </Col>
                        <Col xs="auto">
                          {!item?.isErrorProcessing && item?.isLoading && (
                            <SvgIconUploading />
                          )}
                          {!item?.isErrorProcessing && !item?.isLoading && (
                            <CheckCircleOutlinedIcon
                              className="text__primary-color"
                              sx={{ fontSize: "3.5rem" }}
                            />
                          )}
                          {item?.isErrorProcessing && (
                            <Tooltip
                              title={
                                <h4>
                                  {item.isErrorProcessingMessage ||
                                    "Ha ocurrido un error procesando el documento, intentalo de nuevo."}
                                </h4>
                              }
                            >
                              <ErrorOutlineOutlinedIcon
                                className="text__error-color"
                                sx={{ fontSize: "3.5rem" }}
                              />
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </Container>
    )
  );
}
