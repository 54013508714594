import { getItemsFrequency } from "./array.utils";
import changeDefaultBlankSpaceName from "./changeDefaultBlankSpaceName";
import { replaceTextAtOccurrence } from "./string.utils";
import { isNumber } from "./validations";

/** BEGIN TYPE DEFINITION */

/**
 * @typedef {Object} BlankSpaceType
 * @property {string} name The name of the blank space.
 * @property {string | undefined} description The description of the blank space.
 * @property {number | undefined} id The identifier of the blank space.
 */

/** END TYPE DEFINITION */

export const getBlankSpaceIndex = (blankspaceArray) => {
  let id = blankspaceArray.reduce((acc, bs) => (acc > bs.id ? acc : bs.id), 0);
  return Number(id) + 1;
};
export const transformSelectionToHtml = (selection) => {
  var range = selection?.getRangeAt(0);

  var fragment = range?.cloneContents();

  var div = document?.createElement("div");
  div.appendChild(fragment?.cloneNode(true));

  return div.innerHTML || "";
};

export const replaceBlankSpace1 = (htmlString, oldValue, newValue) => {
  console.log(htmlString);
  const searchValue = 'data-blank-space="blankSpace-' + oldValue + '"';
  const replaceValue = 'data-blank-space="blankSpace-' + newValue + '"';
  const modifiedString = htmlString.replace(searchValue, replaceValue);

  return modifiedString;
};

export function replaceBlankSpace(htmlString) {
  // Crear un elemento DOM temporal para manipular el HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Encontrar todos los elementos con la clase 'blank-space-text'
  const blankSpaces = tempDiv.querySelectorAll(".blank-space-text");

  // Iterar sobre cada elemento de espacio en blanco
  blankSpaces.forEach((blankSpace) => {
    // Extraer el número del contenido del elemento
    const matches = blankSpace.textContent.match(/¬ESPACIO #(\d+)¬/);
    if (matches && matches[1]) {
      // Actualizar el atributo 'data-blank-space' con el nuevo número
      const newNumber = matches[1];
      blankSpace.setAttribute("data-blank-space", `blankSpace-${newNumber}`);
    }
  });

  // Devolver el HTML actualizado como string
  return tempDiv.innerHTML;
}

export const updateStringBlankSpaces = (inputString) => {
  const regex = /¬ESPACIO #(\d+)¬/g;
  const resultArray = [];
  const blankSpacesArray = [];
  let updatedString = "";
  let lastIndex = 0;

  let match;
  while ((match = regex.exec(inputString)) !== null) {
    const espacioNumber = parseInt(match[1], 10);
    resultArray.push(espacioNumber);

    const replacement = `¬ESPACIO #${resultArray.length}¬`;
    let newBlankSpace = {
      id: resultArray.length,
      name: `blankSpace-${resultArray.length}`,
    };
    blankSpacesArray.push(newBlankSpace);
    const substring = inputString.substring(lastIndex, match.index);
    updatedString += substring + replacement;

    lastIndex = regex.lastIndex;
  }

  updatedString += inputString.substring(lastIndex);
  return { text: `<div>${updatedString}</div>`, blankspaces: blankSpacesArray };
};

/**
 * Replace blank spaces on text.
 *
 * @param {string} text The text where the blank spaces will be replaced.
 * @param {Array<{ name: string }>} blankSpaces The blank spaces to replace.
 * @param {string} newBlankSpacesText The new text for the blank spaces in the text.
 * @param {string} blankSpaceTextWrapper The wrapper for the blank space text. Default is "¬".
 * @returns {string} The text with the blank spaces replaced.
 */
export const replaceBlankSpacesOnText = (
  text,
  blankSpaces,
  newBlankSpacesText,
  blankSpaceTextWrapper = "¬"
) => {
  let newText = text;
  for (const blankSpace of blankSpaces) {
    const blankSpaceName = changeDefaultBlankSpaceName(blankSpace.name);
    const blankSpaceText = `${blankSpaceTextWrapper}${blankSpaceName}${blankSpaceTextWrapper}`;
    newText = newText.replace(blankSpaceText, newBlankSpacesText);
  }

  return newText;
};

/**
 * Unify objects with blank spaces.
 *
 * @param {Array<{ name: string, blankSpaces: Array<BlankSpaceType>, text?: string }>} array The object with the text and the blank spaces.
 */
export function unifyObjectsWithBlankSpaces(array = []) {
  let concatenatedText = "";
  let currentBlankSpacesLength = 0;
  const blankSpaces = [];

  array.forEach((predeterminedText) => {
    const blankSpacesPropertyNames = ["blankSpaces", "blankspaces"];
    /** @type {Array<BlankSpaceType>} */
    const objectsWithBlankSpaces = [];
    /** @type {string} */
    let predeterminedTextText = predeterminedText.text;

    for (const propertyName of blankSpacesPropertyNames) {
      if (predeterminedText[propertyName]) {
        objectsWithBlankSpaces.push(...predeterminedText[propertyName]);
        break;
      }
    }

    for (const [
      blankSpaceIndex,
      blankSpace,
    ] of objectsWithBlankSpaces.entries()) {
      const newBlankSpace = {
        ...blankSpace,
        id: currentBlankSpacesLength + 1,
      };

      currentBlankSpacesLength++;
      predeterminedTextText = predeterminedTextText.replace(
        blankSpace.name,
        newBlankSpace.name
      );
      predeterminedTextText = predeterminedTextText.replace(
        `blankSpace-${blankSpaceIndex + 1}`,
        `blankSpace-${newBlankSpace?.id}`
      );
      objectsWithBlankSpaces[blankSpaceIndex] = {
        ...newBlankSpace,
        name: changeDefaultBlankSpaceName(newBlankSpace.name),
      };
    }

    blankSpaces.push(...objectsWithBlankSpaces);
    concatenatedText += `${predeterminedTextText} </br>`;
  });

  const renameResult = renameRepeatedBlankSpaces(blankSpaces, concatenatedText);
  const objectResult = {
    /** @type {string | undefined} Name of the unified object. */
    name: array[0].name,
    /** @type {string | undefined} Text with the renamed blank spaces. */
    text: renameResult.text,
    /** @type {Array<BlankSpaceType>} Renamed blank spaces. */
    blankspaces: renameResult.blankSpaces,
  };

  return objectResult;
}

/**
 * The function renames the blank spaces of the array of blank spaces in the text and in itself.
 *
 * @param {string} text The text where the blank spaces must be renamed.
 * @param {Array<BlankSpaceType>} blankSpaces The blank spaces to rename.
 * @param options The options to rename the blank spaces.
 * @returns The text with the blank spaces renamed and the renamed blank spaces.
 */
export function renameRepeatedBlankSpaces(
  blankSpaces = [],
  text = "",
  options = {
    prefixWhenLastCharIsANumber: ".",
    prefixWhenLastCharIsNotANumber: " ",
  }
) {
  /**
   * Is necessary for keep the blank spaces order, the renamed ones will be replace the original ones in the same index.
   */
  const renamedBlankSpaces = [...blankSpaces];
  const blankSpacesFrequency = getItemsFrequency(
    blankSpaces.map((blankSpace) => blankSpace.name)
  );
  let textWithTheRenamedBlankSpaces = text;

  for (const blankSpaceFrequency of blankSpacesFrequency) {
    /** If the blank space is not repeated, then we do not do anything. */
    if (blankSpaceFrequency.frequency <= 1) {
      continue;
    }

    /** Iterate over the indexes where the blank space is repeated. */
    for (const [
      occurrencesIterator,
      blankSpaceOccurrenceIndex,
    ] of blankSpaceFrequency.occurrencesIndex.entries()) {
      const renamedBlankSpace = {
        ...blankSpaces[blankSpaceOccurrenceIndex],
      };
      const renamedBlankSpaceOriginalName = renamedBlankSpace.name;
      /** @type {string | undefined} The text with the renamed blank space. */
      let textWithTheRenamedBlankSpace;

      /** The first occurrence of the blank space does not need to be renamed. */
      if (occurrencesIterator === 0) {
        continue;
      }

      /**
       * Using the default {@link options}, if the last character of the blank space name is a number, then we add a dot before the frequency counter (ESPACIO #1.1).
       * Otherwise, we add a space before the frequency counter (ESPACIO REPETIDO 1).
       */
      renamedBlankSpace.name = generateNameForRepeatedBlankSpace(
        renamedBlankSpace,
        occurrencesIterator,
        options
      );

      /** Replace the renamed blank space in the text. */
      textWithTheRenamedBlankSpace = replaceTextAtOccurrence(
        textWithTheRenamedBlankSpaces,
        renamedBlankSpaceOriginalName,
        renamedBlankSpace.name,
        occurrencesIterator + 1
      );

      if (textWithTheRenamedBlankSpace) {
        textWithTheRenamedBlankSpaces = textWithTheRenamedBlankSpace;
      } else {
        console.warn(
          `The blank space ${renamedBlankSpaceOriginalName} could not be renamed in the text.`
        );
      }

      renamedBlankSpaces[blankSpaceOccurrenceIndex] = renamedBlankSpace;
    }
  }

  const objectToReturn = {
    /**
     * @type {string} The text with the blank spaces renamed.
     */
    text: textWithTheRenamedBlankSpaces,
    /**
     * @type {Array<BlankSpaceType>} The renamed blank spaces.
     */
    blankSpaces: renamedBlankSpaces,
  };

  return objectToReturn;
}

/**
 * Generate a name for a repeated blank space.
 *
 * @param {BlankSpaceType} blankSpace The blank space object.
 * @param {number} occurrencesIterator The iterator of the occurrences.
 * @param {Object} options The options to generate the name.
 * @returns {string} The new name for the repeated blank space.
 */
function generateNameForRepeatedBlankSpace(
  blankSpace = {
    name: "",
  },
  occurrencesIterator = 0,
  options = {
    prefixWhenLastCharIsANumber: ".",
    prefixWhenLastCharIsNotANumber: " ",
  }
) {
  const { prefixWhenLastCharIsANumber, prefixWhenLastCharIsNotANumber } =
    options;
  const lastNameCharacter = blankSpace.name.at(-1);

  if (isNumber(lastNameCharacter)) {
    return buildRepeatedBlankSpaceName(
      blankSpace.name,
      prefixWhenLastCharIsANumber,
      occurrencesIterator
    );
  } else {
    return buildRepeatedBlankSpaceName(
      blankSpace.name,
      prefixWhenLastCharIsNotANumber,
      occurrencesIterator
    );
  }
}

/**
 * Build a repeated blank space name.
 *
 * The resultant name structure is: `${name}${middle}${suffix}`.
 *
 * @param {string} name The name of the blank space.
 * @param {string} middle The middle part of the name.
 * @param {number} suffix The suffix of the name.
 * @returns {string} The new name of the blank space.
 */
function buildRepeatedBlankSpaceName(name = "", middle = "", suffix = "") {
  return `${name}${middle}${suffix}`;
}
