import { Button, Tooltip, IconButton, TextField } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { yupResolver } from "@hookform/resolvers/yup";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Col, Form, Row, Stack, Container } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Add from "@mui/icons-material/Add";
import { IOSSwitch } from "../../../../../../rulesReview/rulesReview";
import ModalDecision from "../../../../../../../../../../components/Modals/modalDecision";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { onSortHTMLElement } from "../../../../../../../../../../utils/findComponentHTML";

/**
 * Set of constants used in the component ManageRequiredDocuments
 * @constant
 * @default
 */
const MANAGE_REQUIRED_DOCUMENTS_CONSTANTS = {
  DocsDataTableColumns: [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Documento a subir
        </label>,
      ],
      field: "title",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Acciones
        </label>,
      ],
      field: "action",
    },
  ],
};
/**
 * Set of constants used in the component DocumentEdition
 * @constant
 * @default
 */
const DOCUMENT_EDITION_CONSTANTS = {
  AcceptedFormats: {
    PDF: [".pdf"],
    Word: [".doc", ".docx"],
    Excel: [".xls", ".xlsx"],
  },
  InitialDocument: {
    originalFilename: "",
    type: "requiredDocuments",
    isRequired: false,
    fileExtension: [],
  },
  DocumentSchema: yup.object().shape({
    originalFilename: yup
      .string()
      .required("El nombre del documento es requerido"),
    isRequired: yup.boolean().required(),
    fileExtension: yup.object().shape({
      PDF: yup.boolean().required(),
      Word: yup.boolean().required(),
      Excel: yup.boolean().required(),
    }),
  }),
};

/**
 * Renders a component that allows the user to manage required documents.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @name ManageRequiredDocuments
 * @param {Object} [props] - The component props.
 * @param {Array} props.requiredDocuments - The array of required documents, by default it's an empty array.
 * @param {boolean} props.isAllowedUploadOtherDocuments - State variable that indicates if the user is allowed to upload other documents, by default it's true.
 * @param {Function} [props.allowOrDenyUploadOtherDocumentsCallback] - The callback function for allow or deny uploading other documents to the traceability of the form.
 * @param {Function} [props.addDocumentCallback] - The callback function for adding a document.
 * @param {Function} [props.updateDocumentCallback] - The callback function for updating a document.
 * @param {Function} [props.deleteDocumentCallback] - The callback function for deleting a document.
 * @param {JSX.Element} [props.Wrapper] - The wrapper component for the section.
 * @param {JSX.Element} [props.TittleWrapper] - The wrapper component for the title.
 * @param {JSX.Element} [props.ContentWrapper] - The wrapper component for the content.
 * @param {string} props.title - The title of the section.
 * @return {JSX.Element} The rendered component.
 * @throws {Error} If some parameters are incompatible or missing in the specified props
 *
 * @example // Example usage of detail mode.
 * <ManageRequiredDocuments requiredDocuments={requiredDocuments} />
 * @example // Example of allow specific management actions.
 * // Allow add action
 * <ManageRequiredDocuments requiredDocuments={requiredDocuments} addDocumentCallback={addDocument}/>
 * // Allow update action
 * <ManageRequiredDocuments requiredDocuments={requiredDocuments} updateDocumentCallback={updateDocument} />
 * // Allow delete action
 * <ManageRequiredDocuments requiredDocuments={requiredDocuments} deleteDocumentCallback={deleteDocument} />
 * // Allow upload other documents.
 * <ManageRequiredDocuments isAllowedUploadOtherDocuments={isAllowedUploadOtherDocuments} allowUploadOtherDocumentsCallback={allowUploadOtherDocuments} disallowUploadOtherDocumentsCallback={disallowUploadOtherDocuments} />
 */
export default function RequiredDocumentsSection({
  Wrapper = ({ children }) => <div>{children}</div>,
  TittleWrapper = ({ children }) => <div>{children}</div>,
  ContentWrapper = ({ children }) => <div>{children}</div>,
  title,
  requiredDocuments = [],
  isAllowedUploadOtherDocuments = true,
  allowOrDenyUploadOtherDocumentsCallback,
  addDocumentCallback,
  updateDocumentCallback,
  deleteDocumentCallback,
}) {
  const [dataTable, setDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [selectedDocument, setSelectedDocument] = useState(undefined);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(undefined);
  const [isOpenDeleteDocumentModal, setIsOpenDeleteDocumentModal] =
    useState(false);
  const [isOpenDeletedDocumentModal, setIsOpenDeletedDocumentModal] =
    useState(false);
  const [showDocumentEdition, setShowDocumentEdition] = useState(false);

  // Management functions
  const addDocument = (document) => {
    addDocumentCallback(document);
    desactiveDocumentEdition();
  };

  const updateDocument = (updatedDocument) => {
    updateDocumentCallback(updatedDocument, selectedDocumentIndex);
    desactiveDocumentEdition();
  };

  const deleteDocument = () => {
    deleteDocumentCallback(selectedDocumentIndex);
    setIsOpenDeletedDocumentModal(true);
    desactiveDocumentEdition();
  };

  const allowOrDenyUploadOtherDocuments = () => {
    allowOrDenyUploadOtherDocumentsCallback();
  };

  // Data table actions
  const handleUpdateAction = (document, documentIndex) => {
    setSelectedDocumentIndex(documentIndex);
    activeDocumentEdition(document);
  };

  const handleDeleteAction = (documentIndex) => {
    setSelectedDocumentIndex(documentIndex);
    setIsOpenDeleteDocumentModal(true);
  };

  // Modals actions
  const handleDeleteDocumentModalDisagree = () => {
    deleteDocument();
    setIsOpenDeleteDocumentModal(false);
    setIsOpenDeletedDocumentModal(true);
  };

  const handleDeletedDocumentModalAgree = () => {
    setIsOpenDeletedDocumentModal(false);
  };

  // Auxiliar functions
  const activeDocumentEdition = (document = undefined) => {
    setSelectedDocument(document);
    setShowDocumentEdition(true);
  };

  const desactiveDocumentEdition = () => {
    setSelectedDocument(undefined);
    setSelectedDocumentIndex(undefined);
    setShowDocumentEdition(false);
  };

  const buildDataTable = () => {
    setDataTable({
      columns: MANAGE_REQUIRED_DOCUMENTS_CONSTANTS.DocsDataTableColumns,
      rows: requiredDocuments.map((item, index) => {
        return {
          title: (
            <Form.Label>
              {item.originalFilename}
              {item.isRequired && <span className="text-danger">*</span>}
            </Form.Label>
          ),
          action: (
            <Stack direction="horizontal" gap={3}>
              <Tooltip describeChild title={<h5>Editar documento</h5>}>
                <span>
                  <IconButton
                    className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                    sx={{ borderRadius: "8px" }}
                    onClick={() =>
                      handleUpdateAction(requiredDocuments[index], index)
                    }
                    disabled={!updateDocumentCallback}
                  >
                    <ModeEditOutlinedIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip describeChild title={<h5>Eliminar documento</h5>}>
                <span>
                  <IconButton
                    className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
                    sx={{ borderRadius: "8px" }}
                    onClick={() => handleDeleteAction(index)}
                    disabled={!deleteDocumentCallback}
                  >
                    <DeleteOutlinedIcon fontSize="large" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          ),
        };
      }),
    });
  };

  useEffect(() => {
    buildDataTable();
  }, [requiredDocuments]);

  return (
    <Container fluid className="p-0">
      <Wrapper>
        <TittleWrapper aria-controls="panel-content" id="panel-header">
          <Row>
            <h1 className="heading__primary-color">{title}</h1>
          </Row>
        </TittleWrapper>
        <ContentWrapper>
          <Row style={{ color: "#00374F" }}>
            <h1 className="heading__default">
              Documentos requeridos para iniciar el proceso:
            </h1>
          </Row>
          <Row>
            <MDBDataTableV5
              hover={dataTable.rows && dataTable.rows.length > 0}
              pagingTop
              searchBottom={false}
              entries={5}
              data={dataTable}
              entriesLabel=""
              infoLabel={["Mostrando", "a", "de", "documentos"]}
              fullPagination
              onSort={(value) => {
                onSortHTMLElement({
                  excludesColumns: ["acciones"],
                  sort: value,
                  filteredDataTable: dataTable,
                  setFilteredDataTable: setDataTable,
                });
              }}
              noRecordsFoundLabel="No hay documentos."
            />
          </Row>

          {!showDocumentEdition ? (
            <Row className="flex justify-content-end mt-4">
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  startIcon={<Add fontSize="large" />}
                  className="custom-input__button__primary-color"
                  onClick={() => {
                    setSelectedDocument(undefined);
                    setShowDocumentEdition(true);
                  }}
                  disabled={!addDocumentCallback}
                >
                  <p className="button">Agregar documento</p>
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className="mt-5">
              <DocumentEdition
                documentToModify={selectedDocument}
                addDocumentCallback={
                  addDocumentCallback ? addDocument : undefined
                }
                updateDocumentCallback={
                  updateDocumentCallback ? updateDocument : undefined
                }
                deleteDocumentCallback={
                  deleteDocumentCallback
                    ? () => setIsOpenDeleteDocumentModal(true)
                    : undefined
                }
                desactiveDocumentEdition={desactiveDocumentEdition}
              />
            </Row>
          )}
          <Row className="mt-5">
            <Col xs={"auto"}>
              <p className="label__description">
                ¿Desea permitirle al usuario cargar otros documentos diferentes
                a los de la trazabilidad?
              </p>
            </Col>
            <Col>
              <Col className="d-flex">
                <Stack direction="horizontal" className="ms-auto">
                  <label
                    htmlFor={`isAllowedUploadOtherDocumentsSwitch`}
                    className="label__description"
                  >
                    No permitir
                  </label>
                  <IOSSwitch
                    id={`isAllowedUploadOtherDocumentsSwitch`}
                    onChange={allowOrDenyUploadOtherDocuments}
                    sx={{ m: 1 }}
                    checked={isAllowedUploadOtherDocuments}
                    disabled={!allowOrDenyUploadOtherDocumentsCallback}
                  />
                  <label
                    htmlFor={`isAllowedUploadOtherDocumentsSwitch`}
                    className="label__description"
                  >
                    Permitir
                  </label>
                </Stack>
              </Col>
            </Col>
          </Row>
          <ModalDecision
            open={isOpenDeleteDocumentModal}
            type={"warning"}
            title={"Atención"}
            message={
              "Está acción eliminará esté documento. ¿Estás seguro de continuar?"
            }
            agreeText={"Aceptar"}
            agreeIconStart={<CheckIcon />}
            handleAgree={handleDeleteDocumentModalDisagree}
            disagreeText={"Cancelar"}
            disagreeIconStart={<CloseIcon />}
            handleDisagree={() => setIsOpenDeleteDocumentModal(false)}
            onClose={() => setIsOpenDeleteDocumentModal(false)}
          />
          <ModalDecision
            open={isOpenDeletedDocumentModal}
            title={"Documento eliminado"}
            message={"Se ha eliminado el documento exitosamente."}
            agreeText={"Aceptar"}
            agreeIconStart={<CheckIcon />}
            handleAgree={handleDeletedDocumentModalAgree}
            onClose={() => setIsOpenDeletedDocumentModal(false)}
          />
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

RequiredDocumentsSection.propTypes = {
  requiredDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      originalFilename: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      isRequired: PropTypes.bool.isRequired,
      fileExtension: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  isAllowedUploadOtherDocuments: PropTypes.bool,
  allowOrDenyUploadOtherDocumentsCallback: PropTypes.func,
  addDocumentCallback: PropTypes.func,
  updateDocumentCallback: PropTypes.func,
  deleteDocumentCallback: PropTypes.func,
  Wrapper: PropTypes.elementType,
  TittleWrapper: PropTypes.elementType,
  ContentWrapper: PropTypes.elementType,
  title: PropTypes.string.isRequired,
};

/**
 * Component that render a form to view details, create or update a document.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @name DocumentEdition
 * @param {Object} [props] The component props.
 * @param {Object} [props.documentToModify] The document to modify, if give it enables the edition mode, else, enables the creation mode
 * @param {Function} [props.addDocumentCallback] The callback function that add a document
 * @param {Function} [props.updateDocumentCallback] The callback function that update a document
 * @param {Function} [props.deleteDocumentCallback] The callback function that delete a document
 * @param {Function} [props.desactiveDocumentEdition] A function that desactive the edition
 * @returns {JSX.Element} The rendered component.
 * @throws {Error} If some parameters are incompatible or missing in the specified props
 *
 * @example // Example usage of detail mode.
 * <DocumentEdition documentToModify={document} />
 * @example // Example usage of creation mode.
 * <DocumentEdition addDocumentCallback={addDocument} updateDocumentCallback={updateDocument} deleteDocumentCallback={deleteDocument} />
 * @example // Example usage of edition mode.
 * <DocumentEdition documentToModify={document} addDocumentCallback={addDocument} updateDocumentCallback={updateDocument} deleteDocumentCallback={deleteDocument} desactiveDocumentEdition={desactiveDocumentEdition} />
 */
function DocumentEdition({
  documentToModify,
  addDocumentCallback,
  updateDocumentCallback,
  deleteDocumentCallback,
  desactiveDocumentEdition,
}) {
  const acceptedFormatsName = Object.keys(
    DOCUMENT_EDITION_CONSTANTS.AcceptedFormats
  );
  const {
    control,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(DOCUMENT_EDITION_CONSTANTS.DocumentSchema),
  });

  const adaptDocumentFormDataToDocument = (documentFormData) => {
    const fileExtension = adaptFileExtensions(
      documentFormData,
      "toFileExtension"
    );
    return {
      ...documentFormData,
      fileExtension,
    };
  };

  const atLeastOneAcceptedFormatIsActive = () => {
    const fileExtensions = getValues("fileExtension");
    return Object.values(fileExtensions).some((value) => !!value);
  };

  const validateFileExtensions = () => {
    const isValid = atLeastOneAcceptedFormatIsActive();
    if (!isValid) {
      setError("fileExtension", {
        type: "custom",
        message: "Por favor, seleccione al menos un formato de archivo.",
      });
    }
    return isValid;
  };

  const handleAddDocument = (documentFormValues) => {
    if (!validateFileExtensions()) return;
    addDocumentCallback(adaptDocumentFormDataToDocument(documentFormValues));
  };

  const handleUpdateDocument = (documentFormValues) => {
    if (!validateFileExtensions()) return;
    updateDocumentCallback(adaptDocumentFormDataToDocument(documentFormValues));
  };

  const adaptFileExtensions = (documentFormData, transformOption) => {
    const transformOptions = {
      toFileExtension: "toFileExtension",
      toAcceptedFormats: "toAcceptedFormats",
    };
    if (!Object.values(transformOptions).includes(transformOption)) {
      throw new Error(
        `The transform option "${transformOption}" is not valid. Valid options are ${Object.values(
          transformOptions
        ).join(", ")}`
      );
    }
    const getAcceptedFormatFileExtensions = (formatName) => {
      const acceptedFormatName = acceptedFormatsName.find(
        (name) => name.toLowerCase() === formatName.toLowerCase()
      );
      return DOCUMENT_EDITION_CONSTANTS.AcceptedFormats[acceptedFormatName];
    };
    const transformToFileExtension = (formatName) => {
      const acceptedFormatFileExtensions =
        getAcceptedFormatFileExtensions(formatName);
      return acceptedFormatFileExtensions;
    };
    const transformToAcceptedFormat = (currentFileExtensions, format) => {
      const acceptedFormatFileExtensions =
        DOCUMENT_EDITION_CONSTANTS.AcceptedFormats[format];
      let haveFileExtensions = false;
      let transformedFileExtensions = currentFileExtensions.filter(
        (extension) => {
          const includeFileExtension =
            acceptedFormatFileExtensions.includes(extension);
          if (includeFileExtension) {
            haveFileExtensions = true;
          }
          return !includeFileExtension;
        }
      );
      if (haveFileExtensions) {
        transformedFileExtensions.push(format);
      }
      return transformedFileExtensions;
    };
    let result = [];
    if (transformOption === transformOptions.toFileExtension) {
      result = Object.entries(documentFormData.fileExtension).map(
        (extension) => {
          if (!extension[1]) return undefined;
          return transformToFileExtension(extension[0]);
        }
      );
      result = [...new Set(result.flatMap((item) => item))].filter(
        (item) => !!item
      );
    } else if (transformOption === transformOptions.toAcceptedFormats) {
      result = documentFormData.fileExtension;
      acceptedFormatsName.forEach((formatName) => {
        result = transformToAcceptedFormat(result, formatName);
      });
      result = result.reduce((obj, formatName) => {
        if (!formatName) return obj;
        obj[formatName] = true;
        return obj;
      }, {});
    }
    return result;
  };

  const closeDocumentEdition = () => {
    reset();
    desactiveDocumentEdition();
  };

  useEffect(() => {
    let documentState =
      documentToModify ?? DOCUMENT_EDITION_CONSTANTS.InitialDocument;
    let adaptedFileExtension = adaptFileExtensions(
      documentState,
      "toAcceptedFormats"
    );
    documentState = {
      ...documentState,
      fileExtension: adaptedFileExtension,
    };
    reset(documentState);
  }, [documentToModify, reset]);

  return (
    <Container className="p-5" style={{ backgroundColor: "#F4F6FA" }}>
      <Row>
        <Col xs={12} md="6" className="d-flex align-items-center">
          <ModeEditOutlinedIcon fontSize="large" />
          <Controller
            control={control}
            name="originalFilename"
            defaultValue={
              getValues("originalFilename") ??
              DOCUMENT_EDITION_CONSTANTS.InitialDocument.originalFilename
            }
            render={({ field: { value, onChange } }) => (
              <TextField
                variant="standard"
                placeholder="Nombre del documento"
                value={value}
                onChange={onChange}
                className="ms-3"
                fullWidth
                sx={{
                  color: "#00374F",
                  "& .MuiInputBase-input": {
                    color: "#00374F",
                    fontSize: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "12px",
                  },
                }}
                error={!!errors?.originalFilename}
                helperText={errors?.originalFilename?.message}
              />
            )}
          />
        </Col>
        <Col xs={12} md={"auto"} className="ms-auto">
          <Controller
            control={control}
            name="isRequired"
            defaultValue={
              documentToModify?.isRequired ??
              DOCUMENT_EDITION_CONSTANTS.InitialDocument.isRequired
            }
            render={({ field: { value, onChange } }) => (
              <Stack direction="horizontal">
                <label
                  htmlFor="isRequiredSwitch"
                  className="label__description"
                >
                  Opcional
                </label>
                <IOSSwitch
                  id="isRequiredSwitch"
                  onChange={onChange}
                  sx={{ m: 1 }}
                  checked={value}
                  label="Obligatorio"
                />
                <label
                  htmlFor="isRequiredSwitch"
                  className="label__description"
                >
                  Obligatorio
                </label>
              </Stack>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={"auto"}>
          <p className="label__description">Permitir solo archivos de tipo:</p>
        </Col>
        <Col xs={12} md={"auto"}>
          <Stack direction="horizontal" gap={4} className="flex-wrap">
            {acceptedFormatsName.map((acceptedFormat) => (
              <Controller
                key={acceptedFormat}
                control={control}
                name={`fileExtension.${acceptedFormat}`}
                defaultValue={
                  getValues(`fileExtension.${acceptedFormat}`) ?? false
                }
                render={({ field: { value, onChange } }) => (
                  <Stack direction="horizontal">
                    <IOSSwitch
                      id={`${acceptedFormat}Switch`}
                      onChange={onChange}
                      sx={{ m: 1 }}
                      checked={value}
                    />
                    <label
                      htmlFor={`${acceptedFormat}Switch`}
                      className="label__description"
                    >
                      {acceptedFormat}
                    </label>
                  </Stack>
                )}
              />
            ))}
          </Stack>
        </Col>
        {errors?.fileExtension && (
          <Col xs={12}>
            <p className="caption custom-input__error">
              {errors.fileExtension.message}
            </p>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="d-flex align-items-center justify-content-end">
          <Stack direction="horizontal" gap={4}>
            <Button
              variant="contained"
              startIcon={<DeleteOutlinedIcon fontSize="large" />}
              className="custom-input__button__primary-color"
              onClick={
                documentToModify ? deleteDocumentCallback : closeDocumentEdition
              }
            >
              <p className="button">Eliminar</p>
            </Button>
            {documentToModify ? (
              <IconButton
                className="custom-input__button__secondary-color custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={handleSubmit(handleUpdateDocument)}
                disabled={!documentToModify || !updateDocumentCallback}
              >
                <Add fontSize="large" />
                <p className="button">Actualizar documento</p>
              </IconButton>
            ) : (
              <IconButton
                className="custom-input__button__secondary-color custom-input__data-table-icon"
                sx={{ borderRadius: "8px" }}
                onClick={handleSubmit(handleAddDocument)}
                disabled={!addDocumentCallback}
              >
                <Add fontSize="large" />
                <p className="button">Agregar documento</p>
              </IconButton>
            )}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

DocumentEdition.propTypes = {
  documentToModify: PropTypes.shape({
    originalFilename: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    fileExtension: PropTypes.arrayOf(PropTypes.string),
  }),
  addDocumentCallback: PropTypes.func,
  updateDocumentCallback: PropTypes.func,
  deleteDocumentCallback: PropTypes.func,
  desactiveDocumentEdition: PropTypes.func,
};
