import { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Button } from "@mui/material";
import { DeleteOutlined, EditOutlined, Add } from "@mui/icons-material";
import { Col, Container, Row, Stack } from "react-bootstrap";
import BlankSpaceFormModal from "./BlankSpaceFormModal";

/**
 * AddBlankSpaceButton represents a button to add a blank space.
 *
 * @param {Object} props Component props.
 * @param {() => void} props.onClick Function to execute when button is clicked.
 * @returns AddBlankSpaceButton component.
 */
export const AddBlankSpaceButton = ({ onClick = () => {} }) => {
  return (
    <Button
      variant="contained"
      startIcon={<Add fontSize="large" />}
      className="custom-input__button__secondary-color"
      onClick={onClick}
      sx={{
        width: "fit-content",
        whiteSpace: "nowrap",
      }}
    >
      Espacio en blanco
    </Button>
  );
};

AddBlankSpaceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

/**
 * BlankSpacesList represents a list of blank spaces.
 *
 * @param {Object} props Component props.
 * @param {Array<Object>} [props.blankSpaces] List of blank spaces, by default is an empty array.
 * @param {(blankSpace: Object, index: number) => void} props.onEdit Function to execute when edit button is clicked.
 * @param {(blankSpace: Object, index: number) => void} props.onDelete Function to execute when delete button is clicked.
 * @returns BlankSpacesList component.
 */
export const BlankSpacesList = ({ blankSpaces = [], onEdit, onDelete }) => {
  /** States */
  /** Is used for knowing which blank space is being edited. */
  const [selectedBlankSpace, setSelectedBlankSpace] = useState({
    blankSpace: undefined,
    index: undefined,
  });
  /** Is used for knowing if the blank space form modal is open. */
  const [isOpenBlankSpaceFormModal, setIsOpenBlankSpaceFormModal] =
    useState(false);

  /** Functions */
  /**
   * Cleans the selected blank space.
   */
  const cleanSelectedBlankSpace = () => {
    setSelectedBlankSpace({
      blankSpace: undefined,
      index: undefined,
    });
  };

  /**
   * Open the blank space form modal.
   */
  const openBlankSpaceFormModal = () => {
    setIsOpenBlankSpaceFormModal(true);
  };

  /**
   * Close the blank space form modal and clean the selected blank space.
   */
  const closeBlankSpaceFormModal = () => {
    setIsOpenBlankSpaceFormModal(false);
    cleanSelectedBlankSpace();
  };

  /** Handlers */
  /**
   * Handle click edit button. It sets the selected blank space and its index and opens the blank space form modal.
   * 
   * @param {Object} blankSpace - Selected blank space
   * @param {number} index - Selected blank space index
   */
  const handleClickEdit = (blankSpace, index) => {
    setSelectedBlankSpace({
      blankSpace,
      index,
    });
    openBlankSpaceFormModal();
  };

  /**
   * Handle blank space form modal agree button. It calls the onEdit function and closes the blank space form modal.
   * 
   * @param {Object} editedBlankSpace - Edited blank space.
   */
  const handleBlankSpaceFormModalAgree = (editedBlankSpace) => {
    onEdit(editedBlankSpace, selectedBlankSpace.index);
    closeBlankSpaceFormModal();
  }

  return (
    <Container fluid className="d-flex flex-column gap-3">
      <Row>
        <p className="label">{blankSpaces.length} Espacios en blanco:</p>
      </Row>
      <Container fluid className="gx-0">
        {blankSpaces.map((blankSpace, index) => (
          <Row
            key={`${blankSpace?.name}-${index}`}
            className="align-items-center flex-lg-nowrap justify-content-between "
          >
            <Col xs="12" lg="6">
              <p className="heading__primary-color text-break">
                {blankSpace.name}
              </p>
            </Col>
            <Col xs="12" lg="auto" className="flex-shrink-0">
              <Stack direction="horizontal">
                <IconButton
                  onClick={() => {
                    onDelete(blankSpace, index);
                  }}
                >
                  <DeleteOutlined
                    className="heading__primary-color"
                    fontSize="large"
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleClickEdit(blankSpace, index);
                  }}
                >
                  <EditOutlined
                    className="heading__primary-color"
                    fontSize="large"
                  />
                </IconButton>
              </Stack>
            </Col>
          </Row>
        ))}
      </Container>

      <BlankSpaceFormModal
        open={isOpenBlankSpaceFormModal}
        blankSpaces={blankSpaces}
        selectedBlankSpace={selectedBlankSpace.blankSpace}
        onAgree={handleBlankSpaceFormModalAgree}
        onDisagree={closeBlankSpaceFormModal}
        onClose={closeBlankSpaceFormModal}
      />
    </Container>
  );
};

BlankSpacesList.propTypes = {
  blankSpaces: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
