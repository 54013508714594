/**
 * Structure of the form inputs for the section form.
 * 
 * @readonly
 */
const SectionFormInputs = Object.freeze({
  Name: {
    InputName: "name",
    DefaultValue: "Nombre de la sección",
    InputType: "text",
    Validations: {
      ValueType: "string",
      Required: true,
      RequiredMessage: "Este campo es obligatorio",
    },
  },
});

export default SectionFormInputs;
