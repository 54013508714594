import React, { useEffect, useState } from "react";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
  LevelFormat,
  convertInchesToTwip,
  LineRuleType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  LevelSuffix,
  convertMillimetersToTwip,
  TableLayoutType,
  SectionType,
  Header,
  UnderlineType,
} from "docx";
import { saveAs } from "file-saver";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import { NUMBERINGWORDSA, NUMBERINGWORDSO } from "../../utils/numberings";
import { FileDownloadOutlined } from "@mui/icons-material";
import { previewHeadingDocument } from "../../services/admin/documents/headingsServices";
import { CODES } from "../../utils/codes";
import { getFileDocument } from "../../services/documents/documentsRequest";
import { Buffer } from "buffer";
import { Mixpanel } from "../../utils/mixPanel";
import ModalDecision from "../Modals/modalDecision";
import ModalAssociateVersion from "../Modals/modalAssociateVersion";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../parts/folders/foldersSlice";
import { codesDocumentType } from "../../utils/codesDocumentType";
import changeDefaultBlankSpaceName from "../../utils/changeDefaultBlankSpaceName";
import sanitizeHtml from "sanitize-html";
const sizeText = 24;

const colorhighlighted = "yellow";

const fontFamily = "Times New Roman";

const numberingWordsA = NUMBERINGWORDSA;

const numberingWordsO = NUMBERINGWORDSO;

const regexDeleteHtmlTags = /(<([^>]+?)>)/gi;

const PERSON_TYPES = {
  JURIDICA: "Persona jurídica",
  NATURAL: "Persona natural",
  "Persona jurídica": "Persona jurídica",
  "Persona natural": "Persona natural",
};

const configDocument = [
  {
    reference: "romanNumbering",
    levels: [
      {
        level: 0,
        text: "%1.",
        format: LevelFormat.UPPER_ROMAN,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        alignment: AlignmentType.START,
        format: LevelFormat.UPPER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1),
              hanging: convertInchesToTwip(0.36),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        alignment: AlignmentType.START,
        format: LevelFormat.UPPER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1.5),
              hanging: convertInchesToTwip(0.54),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumbering",
    levels: [
      {
        level: 0,
        text: "%1. ",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(4),
              hanging: convertMillimetersToTwip(4),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(10),
              hanging: convertMillimetersToTwip(6),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(19),
              hanging: convertMillimetersToTwip(9),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumberingHeaders",
    levels: [
      {
        level: 0,
        text: "%1)",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
      {
        level: 1,
        text: "%1.%2)",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(1),
              hanging: convertInchesToTwip(0.5),
            },
          },
        },
      },
    ],
  },
  {
    reference: "lowerRomanNumbering",
    levels: [
      {
        level: 0,
        text: "(%1)",
        format: LevelFormat.LOWER_ROMAN,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.25),
            },
          },
        },
      },
    ],
  },
  {
    reference: "decimalNumberingText",
    levels: [
      {
        level: 0,
        text: "",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
        },
      },
      {
        level: 1,
        text: "%1.%2",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(10),
              hanging: convertMillimetersToTwip(6),
            },
          },
        },
      },
      {
        level: 2,
        text: "%1.%2.%3",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        suffix: LevelSuffix.NOTHING,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertMillimetersToTwip(19),
              hanging: convertMillimetersToTwip(9),
            },
          },
        },
      },
    ],
  },
  {
    reference: "letterLowerNumbering",
    levels: [
      {
        level: 0,
        text: "%1)",
        format: LevelFormat.DECIMAL,
        alignment: AlignmentType.START,
        style: {
          run: {
            size: sizeText,
            bold: true,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
      {
        level: 1,
        text: "%2)",
        alignment: AlignmentType.START,
        format: LevelFormat.LOWER_LETTER,
        style: {
          run: {
            size: sizeText,
            bold: false,
          },
          paragraph: {
            indent: {
              left: convertInchesToTwip(0.5),
              hanging: convertInchesToTwip(0.18),
            },
          },
        },
      },
    ],
  },
];

const DocStyles = {
  paragraphStyles: [
    {
      id: "headerContentWithNumbering",
      name: "header Content With Numbering",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "headerContent",
      name: "header Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "clauseContent",
      name: "clause Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
        numbering: {
          reference: "decimalNumbering",
          level: 0,
        },
      },
    },
    {
      id: "subclauseContent",
      name: "subclause Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        numbering: {
          reference: "decimalNumbering",
          level: 1,
        },
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "childsContent",
      name: "child Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        numbering: {
          reference: "decimalNumbering",
          level: 2,
        },
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "signatoriesBullets",
      name: "signatories Bullets",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 275.8,
          lineRule: LineRuleType.AUTO,
        },
      },
    },
    {
      id: "subclauseContentLetterLower",
      name: "Subclause Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 276,
        },
        numbering: {
          reference: "letterLowerNumbering",
          level: 0,
        },
      },
    },
    {
      id: "childContentLetterLower",
      name: "Child Content",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          line: 276,
        },
        numbering: {
          reference: "letterLowerNumbering",
          level: 1,
        },
      },
    },
  ],
  default: {
    document: {
      run: { size: sizeText },
    },
  },
};

const buildDocument = ({
  partHeader,
  title,
  clauses,
  parts,
  headers,
  numberingType,
  otherSignatories,
  typeWord = "legal",
  documentAttachament,
}) => {
  const finalTextParts = partHeader || "";
  const objDocument = {
    title,
    clauses,
    parts,
    headers,
    finalTextParts,
    numberingType,
    otherSignatories,
    documentAttachament,
  };
  let sections;
  if (typeWord === "legal") {
    sections = sectionsDocument(objDocument);
  } else if (typeWord === "table") {
    sections = sectionsDocumentsTable(objDocument);
  }
  const document = new Document({
    numbering: {
      config: configDocument,
    },
    title: title,
    styles: DocStyles,
    sections,
  });
  return document;
};

export const WordCreator = ({
  title,
  clauses,
  parts,
  headers,
  partHeader,
  message,
  numberingType,
  otherSignatories = [],
  selectedHeader,
  setIsLoading,
  mixPanelTrack,
}) => {
  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);
  const dispatch = useDispatch();

  const [openModalSelectFolder, setOpenModalSelectFolder] = useState(false);
  const [openModalAddDocument, setOpenModalAddDocument] = useState(false);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  const openCreateMenu = Boolean(anchorElCreate);

  const generateDocx = async (
    typeWord = "legal",
    documentAttachament = null
  ) => {
    const document = buildDocument({
      partHeader,
      title,
      clauses,
      parts,
      headers,
      numberingType,
      otherSignatories,
      typeWord,
      documentAttachament,
    });

    if (mixPanelTrack) {
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      Mixpanel.track(mixPanelTrack, {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    }
    if (!selectedHeader) {
      return Packer.toBlob(document).then((blob) => blob);
    } else {
      Packer.toBlob(document).then(async (blob) => {
        const obj = {
          file: blob,
          ...(selectedHeader.documentDocxId && {
            documentDocxTemplate: selectedHeader.documentDocxId._id,
          }),
          documentPdfTemplate: selectedHeader.documentPdfId._id,
          originalname: title + ".docx",
          contentType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };

        setIsLoading(true);
        const response = await previewHeadingDocument(obj);
        if (
          response.status === CODES.COD_RESPONSE_HTTP_OK &&
          response.data.success
        ) {
          handleDownloadDocument(
            response.data.responseMessage.data,
            false,
            title + ".docx"
          );
        }
      });
    }
  };

  const handleDownloadDocument = async (actualDocument, isWord, name) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      setIsLoading(false);
      const bf = Buffer.from(file.data.responseMessage.buffer.data);

      const blob = new Blob([new Uint8Array(bf).buffer]);
      saveAs(blob, name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (foldersStatus == "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [foldersStatus]);

  return (
    <>
      <Button
        startIcon={<FileDownloadOutlined />}
        variant="contained"
        className="custom-input__button__primary-color"
        size="medium"
        onClick={(event) => setAnchorElCreate(event.currentTarget)}
      >
        {message}
      </Button>
      <Menu
        anchorEl={anchorElCreate}
        open={openCreateMenu}
        onClose={() => setAnchorElCreate(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModalAddDocument(true);
            setAnchorElCreate(null);
          }}
        >
          <ListItemText
            sx={{
              ".MuiListItemText-primary": {
                fontSize: "1.4rem",
              },
            }}
          >
            Formato tablas
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setAnchorElCreate(null);
            const file = await generateDocx();
            saveAs(file, `${title || "doc"}.docx`);
          }}
        >
          <ListItemText
            sx={{
              ".MuiListItemText-primary": {
                fontSize: "1.4rem",
              },
            }}
          >
            Formato Legal AI
          </ListItemText>
        </MenuItem>
      </Menu>
      <ModalDecision
        title={"Anexar documento"}
        message={"¿Quieres anexar un documento?"}
        open={openModalAddDocument}
        onClose={() => setOpenModalAddDocument(false)}
        agreeText="Sí, anexar"
        disagreeText="No, continuar sin anexar"
        handleAgree={() => {
          setOpenModalAddDocument(false);
          setOpenModalSelectFolder(true);
        }}
        handleDisagree={async () => {
          setOpenModalAddDocument(false);
          const file = await generateDocx("table");
          saveAs(file, `${title || "doc"}.docx`);
        }}
      />
      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={async (doc) => {
          setOpenModalSelectFolder(false);
          setOpenModalAddDocument(false);
          const file = await generateDocx("table", doc.documentContent);
          saveAs(file, `${title || "doc"}.docx`);
        }}
        onClose={() => {
          setOpenModalSelectFolder(false);
        }}
        open={openModalSelectFolder}
        folders={folders}
        title="Anexar documento"
        filterTypeDocument={[
          codesDocumentType.contract._id,
          codesDocumentType.draft._id,
        ]}
      />
    </>
  );
};

const clausesList = ({ clauses, numberingType }) =>
  clauses
    .map((item, idx) => {
      const arr = [];
      const subclauses = item?.clause?.subclauses || item?.subclauses || [];
      const paragraphs = item?.clause?.paragraphs || item?.paragraphs || [];
      arr.push(
        clauseContent(
          item?.clause?.title || item?.title || "Sin título",
          idx,
          item?.clause?.text || item?.text || "",
          numberingType,
          item?.clause?.blankspaces || item?.blankspaces
        )
      );
      subclauses?.forEach((element) => {
        arr.push(
          subclauseContent(
            element.subclause.text,
            element.subclause.title,
            numberingType,
            element?.subclause?.blankspaces
          )
        );
        if (element.subclause.childs.length > 0) {
          element.subclause.childs.forEach((child) => {
            arr.push(
              childsContent(
                child.text,
                child?.title,
                numberingType,
                child?.blankspaces
              )
            );
          });
        }
      });

      paragraphs?.forEach((element, idx) => {
        const text = element?.paragraph?.text || "";
        arr.push(
          paragraphContent(
            text,
            idx,
            numberingType,
            element?.paragraph?.blankspaces || element?.blankspaces
          )
        );
      });
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);

const headersList = (headers) =>
  headers
    .map((item, idx) => {
      const arr = [];
      arr.push(docTitle(item.title), breakLine());
      if (!item.childs.length) {
        arr.push(headerContent(item.text), breakLine());
      } else {
        arr.push(headerContent(item.text));
        item.childs.forEach((child) => {
          arr.push(
            breakLine(),
            headerContentWithNumbering(child.text, idx),
            breakLine()
          );
        });
      }
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);

const headerContent = (headerText) => {
  let textResult = headerText;
  textResult = textResult.replace(/°/g, " ");
  textResult = textResult.replace(/((&nbsp;))*/gim, "");
  const text = textResult.replace(regexDeleteHtmlTags, "");
  return new Paragraph({
    style: "headerContent",
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

const headerContentWithNumbering = (headerText, instance) => {
  let textResult = headerText;
  textResult = textResult.replace(/°/g, " ");
  textResult = textResult.replace(/((&nbsp;))*/gim, "");
  const text = textResult.replace(regexDeleteHtmlTags, "");
  return new Paragraph({
    style: "headerContentWithNumbering",
    numbering: {
      reference: "decimalNumberingHeaders",
      level: 0,
      instance: instance,
    },
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

const breakLine = () => {
  return new Paragraph({
    children: [new TextRun({ size: sizeText })],
  });
};

const principalDocTitle = (text) => {
  return new Paragraph({
    heading: HeadingLevel.TITLE,
    alignment: AlignmentType.CENTER,
    children: [textDoc(text.toUpperCase(), fontFamily, sizeText, true)],
  });
};

const docTitle = (text) => {
  return new Paragraph({
    heading: HeadingLevel.TITLE,
    alignment: AlignmentType.CENTER,
    numbering: {
      reference: "romanNumbering",
      level: 0,
      instance: 2,
    },
    children: [textDoc(text.toUpperCase(), fontFamily, sizeText, true)],
  });
};

const textDoc = (text, font, size, bold, colorhighlighted) => {
  return new TextRun({
    text: text,
    font: font,
    size: size,
    bold: bold,
    highlight: colorhighlighted,
  });
};

const paragraphDoc = (text) => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

/**
 * Transform a text with blank spaces to a list of text doc
 * @function
 * @param {Array<{ name: string, value: string, description?: string }>} blankSpaces The blank spaces
 * @param {string} textResult The cleaned text of the HTML
 * @returns {Array<TextRun>} The text doc of the blank spaces
 */
const transformBlankSpacesToTextDoc = (blankSpaces, textResult) => {
  // Split the text into an array of strings based on the "¬" character
  const splitText = textResult.split("¬");
  // Create an array to store the text doc of the blank spaces
  const splitTextDoc = splitText.map((text) => {
    const BlankSpacePropertiesToFindBy = {
      Name: "name",
      Value: "value",
    };
    // variable for store the property that was found to identify
    // the blank space value to be used in the text doc
    let blankSpaceFoundBy;
    let blankSpaceTextDoc;
    // Loop through the blank spaces array and find the one that matches the text
    const foundBlankSpace = blankSpaces.find((bs) => {
      let found = false;
      if (changeDefaultBlankSpaceName(bs.name) === text) {
        blankSpaceFoundBy = BlankSpacePropertiesToFindBy.Name;
        found = true;
      } else if (bs?.value === text) {
        blankSpaceFoundBy = BlankSpacePropertiesToFindBy.Value;
        found = true;
      }
      return found;
    });
    if (!foundBlankSpace) {
      // If the blank space was not found, create a text doc with the text
      blankSpaceTextDoc = textDoc(text, fontFamily, sizeText, false);
    } else if (blankSpaceFoundBy === BlankSpacePropertiesToFindBy.Value) {
      // If the blank space was found by value, create a text doc with the value
      blankSpaceTextDoc = textDoc(
        changeDefaultBlankSpaceName(foundBlankSpace.value),
        fontFamily,
        sizeText,
        true
      );
    } else if (blankSpaceFoundBy === BlankSpacePropertiesToFindBy.Name) {
      // If the blank space was found by name, create a text doc with the name
      blankSpaceTextDoc = textDoc(
        changeDefaultBlankSpaceName(foundBlankSpace.name),
        fontFamily,
        sizeText,
        false,
        colorhighlighted
      );
    }
    return blankSpaceTextDoc;
  });
  return splitTextDoc;
};

const isTableInClause = (textHtml) => {
  const parser = new DOMParser();
  const htmlTableElement = parser
    .parseFromString(textHtml, "text/html")
    .querySelector(".contract_table");
  return !!htmlTableElement;
};

const NUMBER_TYPE = {
  number: "decimalNumbering",
  letter: "decimalNumberingText",
};

const indentifyJumpLine = (
  textHtml,
  level,
  indentTable,
  numberingType,
  blankSpaces = []
) => {
  const referenceDefault = NUMBER_TYPE[numberingType];

  const cleanHtml = sanitizeHtml(textHtml, {
    allowedTags: ["br"],
  });

  const textHtmlWithJumpLines = cleanHtml.replace(/<br[^>]*>/gi, "\n");

  const textArray = textHtmlWithJumpLines.split("\n");

  let resultText = [];

  for (const [index, text] of textArray.entries()) {
    if (isTableInClause(text)) {
      const buildTable = createTable(text, indentTable);
      resultText.push(buildTable);
    } else {
      let textResult = text;
      let styleParagraph = {};

      let numbering;

      if (index === 0 && level !== 3) {
        numbering = {
          reference: referenceDefault,
          level,
        };
      }

      if (level !== 3) {
        styleParagraph = {
          indent: {
            left: convertInchesToTwip(level / 2),
          },
        };
      }

      textResult = textResult.replace(regexDeleteHtmlTags, "");
      textResult = textResult.replace(/°/g, "");
      textResult = textResult.replace(/\s+/g, " ");
      const textArea = document.createElement("textarea");
      textArea.innerHTML = textResult;
      textResult = textArea.value;

      const blankSpacesTextDoc = transformBlankSpacesToTextDoc(
        blankSpaces,
        textResult
      );

      textResult = textResult.replace(/¬/g, "");

      const children = blankSpacesTextDoc?.length
        ? blankSpacesTextDoc
        : [textDoc(textResult, fontFamily, sizeText, false)];

      resultText.push(
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          numbering,
          ...styleParagraph,
          children,
          spacing: {
            line: 276,
          },
        })
      );
    }
  }

  return resultText;
};

const clauseContent = (
  title,
  index,
  clauseText,
  numberingType,
  blankSpaces = []
) => {
  const indentTable = numberingType === "number" ? 230 : 0;

  const result = indentifyJumpLine(
    clauseText,
    0,
    indentTable,
    numberingType,
    blankSpaces
  );

  return new Paragraph({
    children: [
      textDoc(
        numberingType === "number"
          ? `${title}: `
          : `Cláusula ${numberingWordsA[index + 1]} - ${title}: `,
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const subclauseContent = (
  subclauseText,
  title,
  numberingType,
  blankSpaces = []
) => {
  const result = indentifyJumpLine(
    subclauseText,
    1,
    570,
    numberingType,
    blankSpaces
  );

  return new Paragraph({
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const paragraphContent = (
  paragraphText,
  index,
  numberingType,
  blankSpaces = []
) => {
  const result = indentifyJumpLine(
    paragraphText,
    3,
    0,
    numberingType,
    blankSpaces
  );

  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [
      textDoc(
        "Parágrafo " + numberingWordsO[index + 1] + ": ",
        fontFamily,
        sizeText,
        true
      ),
      ...result,
    ],
  });
};

const childsContent = (childText, title, numberingType, blankSpaces = []) => {
  const result = indentifyJumpLine(
    childText,
    2,
    1075,
    numberingType,
    blankSpaces
  );

  return new Paragraph({
    children: [
      textDoc(` ${title ? title + ": " : ""}`, fontFamily, sizeText, true),
      ...result,
    ],
  });
};

const signatoriesBullets = (item, instance) => {
  item = item.replaceAll(/¬/g, "");
  return new Paragraph({
    numbering: {
      reference: "lowerRomanNumbering",
      level: 0,
      instance: instance,
    },
    style: "signatoriesBullets",
    children: [textDoc(item, fontFamily, sizeText, false)],
  });
};

const partsContent = (part) => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    children: [textDoc(part.name + ":", fontFamily, sizeText, true)],
  });
};

const finalTextPart = (text) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [textDoc(text, fontFamily, sizeText, false)],
  });
};

const signatoriesContent = (signatories, instance) => {
  return signatories
    .map((item) => {
      const arr = [];
      const text = item?.text?.replace(/\s+/g, " ");
      arr.push(signatoriesBullets(text, instance));
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const signatureTitle = (signature) => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    children: [textDoc(`${signature},`, fontFamily, sizeText, false)],
  });
};

const signatureList = (signatories) => {
  return signatories.flatMap((item) => {
    return item.signatories.flatMap((signatory) => {
      const arr = [];
      arr.push(paragraphDoc("_________________________________________"));
      if (PERSON_TYPES[item.type] === "Persona jurídica") {
        arr.push(paragraphDoc(signatory?.name || ""));
        arr.push(paragraphDoc(signatory?.charge || ""));
        arr.push(paragraphDoc(item?.information?.name || ""));
      } else if (PERSON_TYPES[item.type] === "Persona natural") {
        arr.push(paragraphDoc(signatory?.name || ""));
        const id = `${signatory?.documentType || ""} ${
          signatory?.documentNumber || ""
        }`;
        arr.push(paragraphDoc(id));
      }
      arr.push(breakLine(), breakLine());
      return arr;
    });
  });
};

const otherSignatoriesList = (others) => {
  if (!others.length) return [];
  return others
    .map((item) => {
      const arr = [];
      arr.push(
        signatureTitle(item.name),
        breakLine(),
        breakLine(),
        ...signatureList(item.signatories)
      );
      return arr;
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

const getPartTransform = (part, instance) => {
  if (!part) return [];
  return [
    partsContent(part),
    breakLine(),
    ...signatoriesContent(part?.signatories || [], instance),
    breakLine(),
    finalTextPart(part?.text || ""),
  ];
};

const getSignatureListTransform = (part) => {
  if (!part) return [];
  return [
    signatureTitle(part?.name || ""),
    breakLine(),
    breakLine(),
    ...signatureList(part?.signatories || []),
  ];
};

const findBlankSpace = (text) => {
  let textResult = text.replace(/¬/g, "");
  const blankspacesClauseText = textResult.includes("ESPACIO #");
  let textColorLight = null;
  textColorLight = textDoc(
    `${textResult.trim()}`,
    fontFamily,
    sizeText,
    false,
    blankspacesClauseText ? colorhighlighted : null
  );
  return textColorLight;
};

const getTable = (htmlTable) => {
  const parser = new DOMParser();
  const htmlTableElement = parser
    .parseFromString(htmlTable, "text/html")
    .querySelector(".contract_table");

  // Extraer la información de la tabla
  const rows = htmlTableElement.querySelectorAll("tr");
  const tableData = [];

  rows.forEach((row) => {
    const cells = row.querySelectorAll("td");
    const headers = row.querySelectorAll("th");
    const rowData = [];
    if (headers.length) {
      headers.forEach((cell) => {
        const colspan = cell.attributes.getNamedItem("colspan")?.value;
        const rowspan = cell.attributes.getNamedItem("rowspan")?.value;
        const data = {
          colspan,
          content: findBlankSpace(cell.innerText),
          rowspan,
        };
        rowData.push(data);
      });
      tableData.push({ header: true, rowData });
    } else if (cells.length) {
      cells.forEach((cell) => {
        const colspan = cell.attributes.getNamedItem("colspan")?.value;
        const rowspan = cell.attributes.getNamedItem("rowspan")?.value;
        const data = {
          colspan,
          content: findBlankSpace(cell.innerText),
          rowspan,
        };
        rowData.push(data);
      });
      tableData.push({ header: false, rowData });
    }
  });

  return tableData;
};

const createTable = (htmlTable, indent, width = null) => {
  const tableData = getTable(htmlTable);
  return new Table({
    indent: {
      size: indent,
      type: WidthType.DXA,
    },
    width,
    rows: tableData.map(({ header, rowData }) => {
      return new TableRow({
        tableHeader: header,
        children: rowData.map((cellData) => {
          return new TableCell({
            children: [new Paragraph({ children: [cellData.content] })],
            margins: {
              top: convertInchesToTwip(0.15),
              bottom: convertInchesToTwip(0.15),
              left: convertInchesToTwip(0.15),
              right: convertInchesToTwip(0.15),
            },
            columnSpan: cellData?.colspan,
            rowSpan: cellData?.rowspan,
            shading: {
              fill: header ? "#A3A3A3" : "auto",
              color: "auto",
            },
          });
        }),
      });
    }),
  });
};

const sectionsDocument = ({
  title,
  clauses,
  parts,
  headers,
  numberingType,
  otherSignatories,
}) => {
  return [
    {
      properties: {},
      children: [
        principalDocTitle(title || ""),
        breakLine(),
        //Parts
        docTitle("PARTES"),
        breakLine(),
        ...getPartTransform(parts[0] || parts["partA"], 1),
        ...getPartTransform(parts[1] || parts["partB"], 2),
        ...getPartTransform(parts[2] || parts["partC"], 3),
        ...getPartTransform(parts[3] || parts["partD"], 4),
        // finalTextPart(finalTextParts),
        //Headers
        ...headersList(headers),
        //CLAUSES
        docTitle("CLÁUSULAS"),
        breakLine(),
        breakLine(),
        ...clausesList({ clauses, numberingType }),
        breakLine(),
        breakLine(),
        breakLine(),
        //SIGNATURES
        ...getSignatureListTransform(parts[0] || parts["partA"]),
        ...getSignatureListTransform(parts[1] || parts["partB"]),
        ...getSignatureListTransform(parts[2] || parts["partC"]),
        ...getSignatureListTransform(parts[3] || parts["partD"]),
        ...otherSignatoriesList(otherSignatories),
      ],
    },
  ];
};

export const wordCreatorFile = ({
  title,
  clauses,
  parts,
  partHeader = "",
  headers,
  numberingType = "number",
  otherSignatories = [],
}) => {
  return WordBlobDocument({
    title,
    clauses,
    parts,
    partHeader,
    headers,
    numberingType,
    otherSignatories,
  });
};

export const WordBlobDocument = ({
  title,
  clauses,
  parts,
  headers,
  partHeader,
  numberingType,
  otherSignatories = [],
}) => {
  const document = buildDocument({
    partHeader,
    title,
    clauses,
    parts,
    headers,
    numberingType,
    otherSignatories,
  });
  const fileBlob = Packer.toBlob(document).then((blob) => blob);
  return fileBlob;
};

const sectionsOtherDocument = ({ title, content, blankSpaces }) => {
  return [
    {
      properties: {},
      children: [
        principalDocTitle(title || ""),
        breakLine(),
        //CLAUSES
        ...otherDocumentTextList({ content, blankSpaces }),
      ],
    },
  ];
};

const otherDocumentTextList = ({ content, blankSpaces }) => {
  const arr = [];

  const cleanHtml = content.replace(/[°¬]/g, "");

  const arrayOfDocxElements = htmlToDocx(cleanHtml, blankSpaces);

  arrayOfDocxElements.forEach((element) => {
    arr.push(element);
  });

  return arr;
};

function styledTextRun(text, styles) {
  return new TextRun({
    text: text,
    ...styles,
    size: styles.size,
    underline: styles.underline,
  });
}

function processHtmlNode(node, parentStyles = {}, blankSpaces = []) {
  const children = [];
  const currentStyles = { ...parentStyles }; // Copia los estilos del padre

  // Actualiza currentStyles basado en el nodo actual
  if (node.nodeName === "STRONG" || node.nodeName === "B") {
    currentStyles.bold = true;
  }
  if (node.nodeName === "I" || node.nodeName === "EM") {
    currentStyles.italics = true;
  }
  if (node.nodeName === "U") {
    currentStyles.underline = { type: UnderlineType.SINGLE };
  }
  if (
    node.nodeName === "S" ||
    node.nodeName === "STRIKE" ||
    node.nodeName === "DEL"
  ) {
    currentStyles.strike = true;
  }
  if (node.nodeName === "H1") {
    currentStyles.heading = HeadingLevel.HEADING_1;
  } else if (node.nodeName === "H2") {
    currentStyles.heading = HeadingLevel.HEADING_2;
  } else if (node.nodeName === "H3") {
    currentStyles.heading = HeadingLevel.HEADING_3;
  } else if (node.nodeName === "H4") {
    currentStyles.heading = HeadingLevel.HEADING_4;
  } else if (node.nodeName === "H5") {
    currentStyles.heading = HeadingLevel.HEADING_6;
  } else if (node.nodeName === "H6") {
    currentStyles.heading = HeadingLevel.HEADING_6;
  }
  if (node.style && node.style.color) {
    let color = node.style.color;
    // Comprobar si el color está en formato RGB
    if (color.startsWith("rgb")) {
      color = rgbToHex(color);
    } else if (color.startsWith("#") && color.length === 7) {
      // Si el color está en formato hexadecimal con '#', remover el '#'
      color = color.slice(1);
    }
    currentStyles.color = color;
  }

  if (node.style && node.style.textAlign) {
    switch (node.style.textAlign) {
      case "left":
        currentStyles.alignment = AlignmentType.LEFT;
        break;
      case "right":
        currentStyles.alignment = AlignmentType.RIGHT;
        break;
      case "center":
        currentStyles.alignment = AlignmentType.CENTER;
        break;
      case "justify":
        currentStyles.alignment = AlignmentType.JUSTIFIED;
        break;
      default:
        currentStyles.alignment = AlignmentType.JUSTIFIED;
      // Puedes agregar más casos si es necesario
    }
  }

  if (node.style?.fontSize) {
    const fontSize = node.style.fontSize;
    let fontSizeValue;

    if (fontSize.endsWith("px")) {
      const fontSizePx = parseInt(fontSize, 10);
      fontSizeValue = pxToPt(fontSizePx);
    } else {
      fontSizeValue = parseInt(fontSize, 10);
    }

    currentStyles.size = fontSizeValue;
  }
  if (node.style?.fontFamily) {
    const sanitizedFontFamily = node.style.fontFamily
      .split(",")[0]
      .trim()
      .replace(/['"]/g, "");
    currentStyles.font = sanitizedFontFamily;
  }
  // Go through all the child nodes of the current node (node as elements on the dom)
  for (const childNode of node.childNodes) {
    // If the node is a text node, check if it has a blank spaces
    if (childNode.nodeType === 3) {
      let textContent = childNode.textContent;
      const highlightedStyles = {
        ...currentStyles,
        bold: true,
        highlight: "yellow",
      };
      let blankSpacesOnTextContent = false;

      // Go through all the blank spaces and replace them with a highlighted text run
      for (const blankSpace of blankSpaces) {
        const textReplacement = {
          value: blankSpace.value || blankSpace.name,
          byValue: blankSpace.value?.length > 0,
        };
        const blankSpaceNamePattern = RegExp(textReplacement.value, "g");
        const matchForHighlight = blankSpaceNamePattern.exec(textContent);
        // If the blank space is found in the text, replace it with a highlighted text run
        if (matchForHighlight) {
          blankSpacesOnTextContent = true;
          // Add the text before the blank space
          if (matchForHighlight.index > 0) {
            children.push(
              styledTextRun(
                textContent.slice(0, matchForHighlight.index),
                currentStyles
              )
            );
          }
          // Add the blank space
          children.push(
            styledTextRun(textReplacement.value, {
              ...highlightedStyles,
              highlight: textReplacement.byValue ? undefined : "yellow",
            })
          );
          // Update the text content to remove the blank space
          textContent = textContent.slice(
            matchForHighlight.index + textReplacement.value.length
          );
          // If there is more text after the blank space, add it
          if (textContent.length > 0) {
            children.push(styledTextRun(textContent, currentStyles));
          }
        }
      }
      // If there are no blank spaces in the text content, add it as a regular text run
      if (!blankSpacesOnTextContent) {
        children.push(styledTextRun(textContent, currentStyles));
      }
    } else if (childNode.nodeType === 1) {
      // Nodo de elemento
      // Pasa los estilos acumulados a la función recursiva
      children.push(...processHtmlNode(childNode, currentStyles, blankSpaces));
    }
  }

  return children;
}

// Función para convertir un string HTML a elementos de docx
function htmlToDocx(htmlString, blankSpaces) {
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(htmlString, "text/html");
  const docxElements = [];
  htmlDocument.body.childNodes.forEach((node) => {
    if (node.nodeType === 1) {
      if (node.nodeName === "UL" || node.nodeName === "OL") {
        const list = processListNode(node);
        docxElements.push(...list);
      }
      if (node.nodeName === "TABLE") {
        const table = processTableNode(node);
        docxElements.push(table);
      } else {
        const children = processHtmlNode(node, {}, blankSpaces);
        let paragraphOptions = { children };
        let headingLevel;
        switch (node.nodeName) {
          case "H1":
            headingLevel = HeadingLevel.HEADING_1;
            break;
          case "H2":
            headingLevel = HeadingLevel.HEADING_2;
            break;
          case "H3":
            headingLevel = HeadingLevel.HEADING_3;
            break;
          case "H4":
            headingLevel = HeadingLevel.HEADING_4;
            break;
          case "H5":
            headingLevel = HeadingLevel.HEADING_5;
            break;
          case "H6":
            headingLevel = HeadingLevel.HEADING_6;
            break;
        }

        if (headingLevel) {
          paragraphOptions.heading = headingLevel;
        }
        paragraphOptions.alignment = AlignmentType.JUSTIFIED;
        if (node.style && node.style.textAlign) {
          switch (node.style.textAlign) {
            case "left":
              paragraphOptions.alignment = AlignmentType.LEFT;
              break;
            case "right":
              paragraphOptions.alignment = AlignmentType.RIGHT;
              break;
            case "center":
              paragraphOptions.alignment = AlignmentType.CENTER;
              break;
            case "justify":
              paragraphOptions.alignment = AlignmentType.JUSTIFIED;
              break;

            default:
              paragraphOptions.alignment = AlignmentType.JUSTIFIED;
          }
        }

        const paragraph = new Paragraph(paragraphOptions);
        docxElements.push(paragraph);
      }
    }
  });

  return docxElements;
}

function pxToPt(px) {
  return Math.round(px * 0.75);
}

function processListNode(listNode, level = 0) {
  const items = [];

  listNode.childNodes.forEach((node) => {
    if (node.nodeName === "LI") {
      const listItemChildren = processHtmlNode(node);
      const listItem = new Paragraph({
        children: listItemChildren,
        bullet: {
          level: level,
        },
        indent: {
          left: 720 * level,
        },
      });
      items.push(listItem);

      // Buscar sublistas dentro del elemento de la lista
      node.childNodes.forEach((subNode) => {
        if (subNode.nodeName === "UL" || subNode.nodeName === "OL") {
          const subListItems = processListNode(subNode, level + 1);
          items.push(...subListItems);
        }
      });
    }
  });

  return items;
}
function processTableNode(tableNode) {
  const rows = [];
  const processRowNode = (rowNode) => {
    const cells = [];
    rowNode.childNodes.forEach((cellNode) => {
      if (cellNode.nodeName === "TD" || cellNode.nodeName === "TH") {
        const cellContents = processHtmlNode(cellNode);
        const cell = new TableCell({
          children: cellContents.map(
            (content) => new Paragraph({ children: [content] })
          ),
        });
        cells.push(cell);
      }
    });
    return new TableRow({ children: cells });
  };

  tableNode.childNodes.forEach((sectionNode) => {
    if (sectionNode.nodeName === "THEAD" || sectionNode.nodeName === "TBODY") {
      sectionNode.childNodes.forEach((rowNode) => {
        if (rowNode.nodeName === "TR") {
          const row = processRowNode(rowNode);
          rows.push(row);
        }
      });
    } else if (sectionNode.nodeName === "TR") {
      const row = processRowNode(sectionNode);
      rows.push(row);
    }
  });

  if (rows.length > 0) {
    return new Table({
      rows: rows,
    });
  } else {
    return null;
  }
}
const buildOtherDocument = ({ title, content, blankSpaces }) => {
  const document = new Document({
    numbering: {
      config: configDocument,
    },
    title: title,
    styles: DocStyles,
    sections: sectionsOtherDocument({
      title,
      content,
      blankSpaces,
    }),
  });
  return document;
};

function rgbToHex(rgb) {
  // Asumimos que el formato de entrada es 'rgb(r, g, b)'
  const rgbValues = rgb.match(/\d+/g).map(Number);
  return rgbValues
    .map((val) => {
      const hex = val.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join("");
}

/**
 * Function to create a Word document.
 *
 * @param {Object} params The parameters to create the Word document.
 * @param {string} params.title The title of the document.
 * @param {string} params.content The content of the document.
 * @param {Array} [params.blankSpaces] The blank spaces of the document.
 * @param {Object} [params.selectedHeader] The heading to be used in the document.
 * @returns The Word document as a `Blob` or an `response object` with the error message.
 */
export const WordBlobOtherDocument = async ({
  title = "",
  content = "",
  blankSpaces = [],
  selectedHeader,
}) => {
  const document = buildOtherDocument({
    title,
    content,
    blankSpaces,
  });
  const handleDownloadDocument = async (actualDocument, isWord) => {
    try {
      const file = await getFileDocument(
        actualDocument.Path,
        actualDocument.BucketName,
        isWord
      );
      // setIsLoading(false);

      const bf = Buffer.from(file.data.responseMessage.buffer.data);
      return new Blob([new Uint8Array(bf).buffer]);
    } catch (error) {
      console.log(error);
    }
  };

  try {
    const response = {
      success: false,
      responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
      responseMessage:
        "Ha ocurrido un error al generar el documento, inténtelo de nuevo más tarde.",
    };
    const fileBlob = await Packer.toBlob(document);
    if (!selectedHeader) {
      return fileBlob;
    }
    const obj = {
      file: fileBlob,
      ...(selectedHeader.documentDocxId && {
        documentDocxTemplate: selectedHeader.documentDocxId._id,
      }),
      documentPdfTemplate: selectedHeader.documentPdfId._id,
      originalname: title + ".docx",
      contentType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    };
    const previewHeadingDocumentResponse = await previewHeadingDocument(obj);
    const serviceResponse = previewHeadingDocumentResponse?.data;

    if (
      previewHeadingDocumentResponse.status !== CODES.COD_RESPONSE_HTTP_OK ||
      !previewHeadingDocumentResponse.data.success
    ) {
      if (
        serviceResponse.responseCode === CODES.COD_RESPONSE_HTTP_BAD_REQUEST
      ) {
        return serviceResponse;
      }

      return response;
    }

    return handleDownloadDocument(serviceResponse.responseMessage.data, true);
  } catch (error) {
    console.error(error);
  }
};

const tableParts = (parts) => {
  return new Table({
    indent: {
      size: 1,
      type: WidthType.AUTO,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows: [
      new TableRow({
        tableHeader: true,
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [textDoc(`PARTES`, fontFamily, sizeText, true)],
                alignment: AlignmentType.CENTER,
              }),
            ],

            columnSpan: [2],
          }),
        ],
      }),
      ...generatePartsTable(parts),
    ],
  });
};

const generatePartsTable = (parts) => {
  const result = parts?.map((part) =>
    part?.signatories?.map((signatory) => {
      const arrayOfPart = [];
      arrayOfPart.push(
        new TableRow({
          columnSpan: [1],
          tableHeader: true,
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [textDoc(part.name, fontFamily, sizeText, true)],
                }),
              ],
              columnSpan: [1],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    textDoc(
                      signatory?.information?.name,
                      fontFamily,
                      sizeText,
                      true
                    ),
                  ],
                }),
              ],
              columnSpan: [1],
            }),
          ],
        })
      );
      arrayOfPart.push(
        new TableRow({
          columnSpan: [1],
          tableHeader: true,
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    textDoc(
                      signatory?.information?.documentType,
                      fontFamily,
                      sizeText,
                      true
                    ),
                  ],
                }),
              ],
              columnSpan: [1],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    textDoc(
                      signatory?.information?.documentNumber,
                      fontFamily,
                      sizeText,
                      true
                    ),
                  ],
                }),
              ],
              columnSpan: [1],
            }),
          ],
        })
      );

      if (
        signatory.type === "Persona jurídica" ||
        signatory.type === "JURIDICA"
      ) {
        arrayOfPart.push(
          new TableRow({
            columnSpan: [1],
            tableHeader: true,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      textDoc(
                        signatory?.information?.signatory?.charge,
                        fontFamily,
                        sizeText,
                        true
                      ),
                    ],
                  }),
                ],
                columnSpan: [1],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      textDoc(
                        signatory?.information?.signatory?.name,
                        fontFamily,
                        sizeText,
                        true
                      ),
                    ],
                  }),
                ],
                columnSpan: [1],
              }),
            ],
          })
        );
        arrayOfPart.push(
          new TableRow({
            columnSpan: [1],
            tableHeader: true,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      textDoc(
                        signatory?.information?.signatory?.documentType,
                        fontFamily,
                        sizeText,
                        true
                      ),
                    ],
                  }),
                ],
                columnSpan: [1],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      textDoc(
                        signatory?.information?.signatory?.documentNumber,
                        fontFamily,
                        sizeText,
                        true
                      ),
                    ],
                  }),
                ],
                columnSpan: [1],
              }),
            ],
          })
        );
      }

      return arrayOfPart;
    })
  );

  return result.flat(Infinity);
};

const transformTextTable = (
  textHtml,
  withTitle = false,
  title,
  blankSpaces = []
) => {
  const textArray = textHtml.split(/<p>(.*?)<\/p>/gi).filter((item) => item);

  let resultText = [];

  for (const [index, text] of textArray.entries()) {
    if (isTableInClause(text)) {
      if (withTitle && index === 0) {
        resultText.push(
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children: [textDoc(title, fontFamily, sizeText, true)],
            spacing: {
              line: 276,
            },
          })
        );
      }
      const width = {
        size: 100,
        type: WidthType.PERCENTAGE,
      };
      const buildTable = createTable(text, 0, width);

      resultText.push(buildTable);
    } else {
      let textResult = text;

      textResult = textResult.replace(regexDeleteHtmlTags, "");
      textResult = textResult.replace(/°/g, "");
      textResult = textResult.replace(/((&nbsp;))/gim, " ");
      textResult = textResult.replace(/\s+/g, " ");
      textResult = textResult.replace(/&amp;/g, "&");
      const blankSpacesTextDoc = transformBlankSpacesToTextDoc(
        blankSpaces,
        textResult
      );

      textResult = textResult.replace(/¬/g, "");

      const children = blankSpacesTextDoc?.length
        ? blankSpacesTextDoc
        : [textDoc(textResult, fontFamily, sizeText, false)];

      if (withTitle && index === 0) {
        resultText.push(
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children: [textDoc(title, fontFamily, sizeText, true), ...children],
            spacing: {
              line: 276,
            },
          })
        );
      } else {
        resultText.push(
          new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children,
            spacing: {
              line: 276,
            },
          })
        );
      }
    }
  }

  return resultText;
};

const buildRowsClauses = ({ clauses, numberingType }) => {
  const tableData = [];
  clauses.forEach(({ clause }, index) => {
    let tableSubclauseContent = {};
    let textParagraph = [];
    if (clause.subclauses.length) {
      tableSubclauseContent = tableSubClauses(clause.subclauses);
    }
    if (clause.paragraphs.length) {
      textParagraph = clause.paragraphs
        .map(({ paragraph }, index) => {
          const title = `Paragrafo ${NUMBERINGWORDSO[index + 1]}: `;
          const text = transformTextTable(
            paragraph.text,
            true,
            title,
            paragraph?.blankspaces
          );
          if (text.length) return [breakLine(), ...text];
          return [];
        })
        .flat();
    }
    const textClause = `${clause.text}`;
    const title =
      numberingType === "number"
        ? `${index + 1}. ${clause.title}`
        : `Cláusula ${numberingWordsA[index + 1]} - ${clause.title}`;
    tableData.push(
      new TableRow({
        tableHeader: false,
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [textDoc(title, fontFamily, sizeText, true)],
              }),
            ],
          }),
        ],
      })
    );
    tableData.push(
      new TableRow({
        tableHeader: false,
        children: [
          new TableCell({
            children: [
              ...transformTextTable(textClause, false, "", clause?.blankspaces),
              tableSubclauseContent,
              ...textParagraph,
            ],
          }),
        ],
      })
    );
  });
  return tableData;
};

const tableSubClauses = (subclauses) => {
  const rowsTextContent = subclauses
    .map(({ subclause: { title, text, childs, blankspaces } }) => {
      const subcluasesResult = [];
      subcluasesResult.push(
        new TableRow({
          tableHeader: false,
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  style: "subclauseContentLetterLower",
                  alignment: AlignmentType.CENTER,
                  children: [textDoc(title, fontFamily, sizeText, false)],
                }),
              ],
            }),
            new TableCell({
              children: [...transformTextTable(text, false, "", blankspaces)],
            }),
          ],
        })
      );

      childs.forEach(({ title, text, blankspaces }) => {
        subcluasesResult.push(
          new TableRow({
            tableHeader: false,
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    style: "childContentLetterLower",
                    alignment: AlignmentType.CENTER,
                    children: [textDoc(title, fontFamily, sizeText, false)],
                  }),
                ],
              }),
              new TableCell({
                children: [...transformTextTable(text, false, "", blankspaces)],
              }),
            ],
          })
        );
      });

      return subcluasesResult;
    })
    .flat();

  return new Table({
    margins: {
      marginUnitType: WidthType.DXA,
      top: 110,
      bottom: 110,
      left: 110,
      right: 110,
    },
    width: {
      size: 90,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    alignment: AlignmentType.END,
    rows: [
      new TableRow({
        tableHeader: false,
        children: [
          new TableCell({
            width: {
              size: 35,
              type: WidthType.PERCENTAGE,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [textDoc("GARANTÍAS", fontFamily, sizeText, true)],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 65,
              type: WidthType.PERCENTAGE,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [textDoc("DESCRIPCIÓN", fontFamily, sizeText, true)],
              }),
            ],
          }),
        ],
      }),
      ...rowsTextContent,
    ],
  });
};

const tableClauses = ({ clauses, numberingType }) => {
  const rows = buildRowsClauses({ clauses, numberingType });
  return new Table({
    margins: {
      marginUnitType: WidthType.DXA,
      top: 110,
      bottom: 110,
      left: 110,
      right: 110,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows,
  });
};

function spliceArray(array, length) {
  var result = [];
  while (array?.length) {
    result.push(array.splice(0, length));
  }
  return result;
}

const tableSignatories = (signatories) => {
  if (!signatories?.length) return [];
  const arrayParts = [...signatories];
  const signatoriesSplice = spliceArray(arrayParts, 2);
  const rows = signatoriesSplice.map(
    (list) =>
      new TableRow({
        tableHeader: false,
        children: list.map(
          (signatorie) =>
            new TableCell({
              children: [
                signatureTitle(signatorie?.name || ""),
                breakLine(),
                breakLine(),
                ...signatureList(signatorie?.signatories || []),
              ],
            })
        ),
      })
  );
  return new Table({
    borders: 0,
    margins: {
      marginUnitType: WidthType.DXA,
      top: 110,
      bottom: 110,
      left: 110,
      right: 110,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    layout: TableLayoutType.FIXED,
    rows,
  });
};

export const sectionsDocumentsTable = ({
  title,
  clauses,
  parts,
  headers,
  numberingType,
  otherSignatories,
  documentAttachament,
}) => {
  const document = [
    {
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              alignment: AlignmentType.END,
              children: [textDoc("CTR_________", fontFamily, sizeText, false)],
            }),
          ],
        }),
      },
      properties: {},
      children: [
        principalDocTitle(title || ""),
        principalDocTitle("CTR_________"),
        breakLine(),
        breakLine(),
        tableParts(parts),
        breakLine(),
        tableClauses({ clauses, numberingType }),
        breakLine(),
        breakLine(),
        tableSignatories([...parts, ...otherSignatories]),
      ],
    },
  ];
  if (documentAttachament)
    document.push(sectionsDefaultAttachment(documentAttachament));
  return document;
};

const sectionsDefaultAttachment = (document) => {
  const clauses = document?.clauses || [];
  return {
    properties: {
      type: SectionType.NEXT_PAGE,
    },
    children: [
      principalDocTitle("ANEXO  1" || ""),
      principalDocTitle("CONTRATO CTRXXXX" || ""),
      principalDocTitle("CLÁUSULAS GENERALES" || ""),
      breakLine(),
      ...clausesList({ clauses, numberingType: "letter" }),
      breakLine(),
    ],
  };
};
