import { WordBlobOtherDocument } from "../../../../../../components/WordCreator/WordCreator";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { CODES } from "../../../../../../utils/codes";

export const handlePreviewTemplate = async (template) => {
    try {
        let fileWord = await WordBlobOtherDocument({
            title: template.name,
            content: template.text,
            blankSpaces: template?.blankSpaces ?? template?.blankspaces,
        });
        const response = await convertToPdfService(fileWord);
        if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
            const dataFile = response.data.responseMessage?.data;

            const file = URL.createObjectURL(
                new Blob([new Uint8Array(dataFile?.buffer?.data).buffer], {
                    type: "application/pdf",
                })
            );
            window.open(file);
        }
    } catch (error) {
        console.log(error);
    }
};