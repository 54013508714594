import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Checkbox, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import ClearIcon from "@mui/icons-material/Clear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomSearchFilterButton from "../../../../../../../components/Search/CustomSearchFilterButton";
import FilterByPart from "../../../../../../../parts/filters/FilterByPart";
import { onSortHTMLElement } from "../../../../../../../utils/findComponentHTML";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartsList,
  fetchSignatoriesList,
  getPartsList,
  getPartsListStatus,
  getSignatoriesList,
  getSignatoriesListStatus,
  resetPartListStatus,
  resetSignatoriesListStatus,
  setSelectedPart,
} from "../../../../../../../parts/parts/partsSlice";
import PopoverActions from "../../../../../../../components/Popover/PopoverActions";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import { ModalListSelectCheck } from "../../../../../../../components/Modals/modalListCheck";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import LoadingContent from "../../../../../../../components/loadingContent";
import {
  manageRelatedPartToSignatory,
  updatePart,
} from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../../utils/codes";
import ModalSelectRoleSignatory from "../../../../../../../components/Modals/ModalSelectRoleSignatory";

import { PopoverLimits } from "../../../../../../../components/Popover/PopoverLimits";
export const SelectSignatory = ({ role }) => {
  //Redux
  const dispatch = useDispatch();
  const partsList = useSelector(getPartsList);
  const partsListStatus = useSelector(getPartsListStatus);
  const signatoriesList = useSelector(getSignatoriesList);
  const signatoriesListStatus = useSelector(getSignatoriesListStatus);

  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);

  //states
  const [openModalSelectSignatoryRole, setOpenModalSelectSignatoryRole] =
    useState(false);
  const [deletedSignatoryList, setDeletedSignatoryList] = useState([]);

  const columns = [
    {
      label: [
        <label aria-hidden="true" key="0">
          Seleccionar
        </label>,
      ],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Firmante
        </label>,
      ],
      field: "name",
    },
    {
      label: "",
      field: "actions",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Identificación
        </label>,
      ],
      field: "id",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Contacto
        </label>,
      ],
      field: "contact",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Parte relacionada
        </label>,
      ],
      field: "relatedParts",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Unidades corporativas
        </label>,
      ],
      field: "corporateUnits",
    },
  ];
  const [data, setData] = useState({ columns, rows: [] });
  const [filteredData, setFilteredData] = useState({ columns, rows: [] });
  const [partsSelected, setPartsSelected] = useState([]);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingFetchUCl, setIsLoadingFetchUCl] = useState(false);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);
  const [unitCorporativeSelected, setUnitCorporativeSelected] = useState([]);

  const [selectedSignatories, setSelectedSignatories] = useState([]);
  const [isOpenModalSelectUC, setIsOpenModalSelectUC] = useState(false);

  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [signatorySelected, setSignatorySelected] = useState(null);

  const [isLoading, setIsLoading] = useState(null);

  //Router

  const navigate = useNavigate();

  const { part } = useParams();

  //Handles
  const handlePartsSelected = (parts) => {
    setPartsSelected(parts);
  };

  const handleDeletePartSelected = (part) => {
    const newParts = partsSelected.filter((t) => t.id !== part);
    setPartsSelected(newParts);
    if (newParts.length > 0) {
      let filteredParts = [];
      const selectedList = newParts.map((item) => item.id);
      filteredData.rows.forEach((item) => {
        const list = item.relatedParts;
        const filterList = list.some((item) => selectedList.includes(item._id));
        if (filterList) {
          filteredParts.push(item);
        }
      });

      setFilteredData({ ...filteredData, rows: filteredParts });
    } else {
      /**Ninguna parte se está buscando */
      setFilteredData(data);
    }
  };

  const handleAddCorporateUnit = async (data) => {
    try {
      setIsLoadingAll(true);
      setIsOpenModalSelectUC(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
      setSignatorySelected(null);
    }
  };

  const handleDeleteCorporateUnit = async ({ id, element }) => {
    try {
      setIsLoadingAll(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAll(false);
    }
  };

  const handleOpenModalListCorporateUnits = (info) => {
    setUnitCorporativeSelected(info.associatedCorporateUnits);
    setSignatorySelected(info);
    setIsOpenModalSelectUC(true);
  };

  const getName = (item) => {
    let fullName = `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
    return fullName.replace(/  +/g, " ");
  };

  const getPartName = (partId) => {
    let name = "";
    let part = {};

    if (partId._id) {
      part = partId;
    } else {
      part = partsList.find((item) => item._id === partId);
    }

    name = getName(part);
    if (part?.typePart === "JURIDICA") {
      name = part?.business?.businessName;
    }

    return name;
  };

  const handleAddSignatoriesToPart = async () => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));

      const transformSignatoriesArray = transformSignatories();
      const obj = {
        modifiedBy: userId,
        company,
        relatedSignatories: transformSignatoriesArray,
        partId: part,
      };

      const response = await updatePart(obj);

      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const transformedArrayForUpdate = transformSignatoriesArray.map(
          (item) => {
            return {
              part,
              signatory: item.signatory,
              position: item.position,
              limitations: item?.limitationsLegalRepresentative,
            };
          }
        );
        const serviceAddPartsToSignatories = await manageRelatedPartToSignatory(
          {
            managePartToSignatories: transformedArrayForUpdate,
            action: "ADD",
          }
        );
        if (
          serviceAddPartsToSignatories.status === CODES.COD_RESPONSE_HTTP_OK &&
          serviceAddPartsToSignatories?.data?.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          if (deletedSignatoryList.length > 0) {
            const transformedArrayForUpdate = deletedSignatoryList.map(
              (item) => {
                return {
                  part,
                  signatory: item._id,
                };
              }
            );
            const serviceRemovePartsToSignatories =
              await manageRelatedPartToSignatory({
                managePartToSignatories: transformedArrayForUpdate,
                action: "DELETE",
              });
            if (
              serviceRemovePartsToSignatories.status ===
                CODES.COD_RESPONSE_HTTP_OK &&
              serviceRemovePartsToSignatories?.data?.responseCode ===
                CODES.COD_RESPONSE_SUCCESS
            ) {
              setResponseData(response);
              setIsOpenModalInfo(true);
            }
          }

          setOpenModalSelectSignatoryRole(false);
          setResponseData(response);
          setIsOpenModalInfo(true);
          dispatch(resetPartListStatus());
          dispatch(resetSignatoriesListStatus());
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckSignatory = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedSignatories((oldArray) => [...oldArray, item]);

      setDeletedSignatoryList(
        deletedSignatoryList.filter((itm) => itm?._id != item?._id)
      );
    } else {
      setDeletedSignatoryList([...deletedSignatoryList, item]);
      setSelectedSignatories(
        selectedSignatories.filter((itm) => itm?._id != item?._id)
      );
    }
  };

  const transformSignatories = () => {
    return selectedSignatories.map((item) => {
      return {
        signatory: item._id,
        isDefault: false,
        position: item.role,
      };
    });
  };

  const handleClickEditSignatory = (item) => (event) => {
    if (role === "ADMIN") {
      navigate(`/admin/library/update/signatory/${item._id}`);
    } else if (role === "SERVICE") {
      navigate(
        `/service/library/partsAndSignatures/update/signatory/${item._id}`
      );
    }
  };

  const buildData = (data) => {
    if (!data.length) return [];
    const formatedData = [];
    for (const item of data) {
      const name = getName(item);
      const id = `${item?.documentType}. ${item?.documentNumber}`;
      const contact = `${item?.email || ""}`;
      const address = item?.residence?.physicalAddress;
      const country = item?.residence?.country;
      const city = `${item?.residence?.province?.concat(", ") || ""} ${
        item?.residence?.city || ""
      }`;
      const number = `+${item?.mobileNumberData?.countryCode || ""} ${
        item?.mobileNumberData?.phone || ""
      }`;

      let telephone = `+${item?.countryCodeTelephone || ""} ${
        item?.cityCode || ""
      } ${item?.numberTelephone || ""}`;
      formatedData.push({
        name,
        actions: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <IconButton onClick={() => handleClickEditSignatory(item)}>
                <EditOutlinedIcon fontSize="large" />
              </IconButton>
            </Col>
          </Row>
        ),
        select: (
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Col xs={"auto"}>
              <Checkbox
                checked={selectedSignatories?.some(
                  (itm) => itm?._id === item?._id
                )}
                onClick={(e) => handleCheckSignatory(e, item)}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  color: "#00374f",
                  "&.Mui-checked": {
                    color: "#00374f",
                  },
                }}
              />
            </Col>
          </Row>
        ),
        id,
        contact: (
          <Row className="caption" style={{ alignItems: "center" }}>
            <Col xs={"auto"}>{contact}</Col>
            {contact && (
              <Col>
                <PopoverActions
                  parentId="clause-parent"
                  type="large"
                  classNameButton=""
                  description={
                    <div>
                      <Row style={{ top: 10 }}>
                        <span className="home-init-cards-row__title-row">
                          Más datos de contacto
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {address}{" "}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {country} {city}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {number}
                        </span>
                      </Row>
                      <Row>
                        <span className="caption" style={{ width: "auto" }}>
                          {telephone}
                        </span>
                      </Row>
                    </div>
                  }
                  icon={<InfoOutlinedIcon fontSize="large" />}
                />
              </Col>
            )}
          </Row>
        ),
        corporateUnits: (
          <Row style={{ rowGap: 5, alignItems: "center" }}>
            {item?.associatedCorporateUnits?.length > 0 &&
              item.associatedCorporateUnits.map(({ _id, name }) => (
                <Col key={_id} xs={12} lg={6}>
                  <span
                    style={{ cursor: "pointer", display: "inline-block" }}
                    className="caption custom-badges__secundary"
                    onClick={() =>
                      handleDeleteCorporateUnit({ id: _id, element: item })
                    }
                  >
                    {name + " "}{" "}
                    <ClearIcon className="custom-badges__delete-button" />
                  </span>
                </Col>
              ))}
            <Col xs={12} lg={6}>
              <span
                style={{ cursor: "pointer" }}
                className="caption custom-badges__disabled"
                onClick={() => handleOpenModalListCorporateUnits(item)}
              >
                Agregar
                <AddOutlinedIcon
                  fontSize="large"
                  className="custom-badges__delete-button"
                />
              </span>
            </Col>
          </Row>
        ),
        relatedParts: item.relatedParties.map((part) => (
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <span className="caption__other-Color-Active">
                <b>{part?.position}.</b> {getPartName(part?.part)}
              </span>
            </Col>

            {part.part?.legalRepresentative?.limitations && (
              <Col>
                <PopoverLimits
                  limitationsUpdatedDate={part?.limitationsUpdatedDate}
                  createdAt={part?.part?.createdAt}
                  limitations={part.part?.legalRepresentative?.limitations}
                />
              </Col>
            )}
          </Row>
        )),
        ...item,
      });
    }
    return formatedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = [];
        if (signatoriesListStatus === "fetch") {
          dispatch(fetchSignatoriesList());
        }
        data = signatoriesList;
        const tranformData = buildData(data);
        setData({ rows: tranformData, columns });
        setFilteredData({ rows: tranformData, columns });
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [signatoriesList, signatoriesListStatus, dispatch, selectedSignatories]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (partsListStatus === "fetch") {
          dispatch(fetchPartsList());
        }
      } catch (error) {
        console.log("==================error==================");
        console.log(error);
        console.log("====================================");
      }
    };
    fetchData();
  }, [partsListStatus]);

  useEffect(() => {
    if (signatoriesListStatus === "loading") {
      setIsLoadingAll(true);
    } else {
      setIsLoadingAll(false);
    }
  }, [signatoriesListStatus]);

  useEffect(() => {
    try {
      const { subRole, corporateUnits: defaultUC } = JSON.parse(
        localStorage.getItem("payloadToken")
      );

      const corporateUnits = localStorage.getItem("userCorporateUnits")
        ? JSON.parse(localStorage.getItem("userCorporateUnits"))
        : defaultUC;
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
    if (statusFetchUnits === "loading") {
      setIsLoadingFetchUCl(true);
    } else {
      setIsLoadingFetchUCl(false);
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    if (isLoadingFetchUCl || isLoadingAll) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingFetchUCl, isLoadingAll]);
  useEffect(() => {
    if (part) {
      const currentPart = partsList.find((item) => item?._id === part);

      if (currentPart) {
        const currentPartSignatories = currentPart.relatedSignatories;
        setSelectedSignatories((prev) => [
          ...prev,
          ...currentPartSignatories.map((item) => item.signatory),
        ]);
      }
    }
  }, [partsList, part]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Firmantes`} />
      </Row>
      <Row className="mt-3">
        <Col xs={"auto"} className="caption">
          {part &&
            `Selecciona los firmantes que quieres agregar a la parte ${getPartName(
              part
            )}`}
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: 15 }}>
        <Col xs={7} xl={5}>
          <CustomSearchFilterButton
            placeholder="Buscar"
            list={data}
            setFilteredData={setFilteredData}
            validations={{ maxLength: 20 }}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={"auto"}>
            <FilterByPart
              list={data}
              setFilteredInfo={setFilteredData}
              handlePartSelected={handlePartsSelected}
              partList={partsList} //SIGNATORIES LISTT
              currentPartsSelected={partsSelected}
            />
          </Col>
        </Col>
      </Row>
      <br />
      <Row>
        {partsSelected.map((part) => {
          return (
            <Col xs={"auto"} key={part?.id}>
              <span className="caption custom-badges__secundary">
                {part.name + " "}{" "}
                <ClearIcon
                  className="custom-badges__delete-button"
                  onClick={() => handleDeletePartSelected(part.id)}
                />
              </span>
            </Col>
          );
        })}
      </Row>
      <Row
        className="dt-list-center-content "
        style={{ paddingBottom: "5rem" }}
      >
        {
          <MDBDataTableV5
            noRecordsFoundLabel={
              !data?.rows?.length
                ? "Aún no tienes firmantes creados"
                : "No se encontraron resultados para tu búsqueda"
            }
            hover
            pagingTop
            searchBottom={false}
            entries={10}
            data={filteredData}
            infoLabel={["Mostrando", "a", "de", "firmantes"]}
            fullPagination
            onSort={(value) => {
              onSortHTMLElement({
                excludesColumns: ["actions", "corporateUnits"],
                sort: value,
                filteredDataTable: filteredData,
                setFilteredDataTable: setFilteredData,
              });
            }}
          />
        }
      </Row>
      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            startIcon={<ArrowBackIcon fontSize="large" />}
            variant="contained"
            className="custom-input__button__secondary-color"
            onClick={() => navigate(-1)}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            variant="contained"
            className="custom-input__button__primary-color"
            startIcon={<CheckIcon fontSize="large" />}
            disabled={!selectedSignatories.length}
            onClick={() => {
              // handleAddSignatoriesToPart();
              setOpenModalSelectSignatoryRole(true);
            }}
          >
            Seleccionar
          </Button>
        </Col>
      </Row>

      <ModalListSelectCheck
        handleAgree={handleAddCorporateUnit}
        list={listUnitsCorporatives}
        onClose={() => setIsOpenModalSelectUC(false)}
        open={isOpenModalSelectUC}
        title="Añadir unidades corporativas a un firmante"
        currentDataSelected={unitCorporativeSelected}
      />
      <ModalSelectRoleSignatory
        selectedSignatories={selectedSignatories}
        setSelectedSignatories={setSelectedSignatories}
        setIsOpen={setOpenModalSelectSignatoryRole}
        isOpen={openModalSelectSignatoryRole}
        submitForm={handleAddSignatoriesToPart}
        description={
          "Escribe el cargo que corresponda al o los firmantes que vas a relacionar con esta parte."
        }
        title={"Firmantes"}
      />
      <ModalInfo
        title={
          responseData.status === CODES.COD_RESPONSE_HTTP_OK
            ? "Actualización exitosa"
            : "Problemas actualizando la parte"
        }
        responseData={responseData}
        onClose={() => {
          if (
            responseData.status === CODES.COD_RESPONSE_HTTP_OK &&
            responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
          ) {
            dispatch(resetPartListStatus());
            dispatch(resetSignatoriesListStatus());
            dispatch(setSelectedPart({}));
            navigate(-1);
          }
          setIsOpenModalInfo(false);
        }}
        open={isOpenModalInfo}
        buttonClose={true}
        confirmationText="Aceptar"
        handleCloseDefault={() => setIsOpenModalInfo(false)}
      />
    </Container>
  );
};
