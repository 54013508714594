import { DescriptionOutlined, InsertDriveFile, InsertDriveFileOutlined, TextSnippet, TextSnippetOutlined } from "@mui/icons-material";

/**
 * Structure of the form inputs for the details section.
 *
 * @readonly
 */
const DetailsFormInputs = Object.freeze({
  DocumentGroup: {
    InputName: "documentGroup",
    InputLabel: "Tipo de documento",
    DefaultValue: "Contrato",
    InputType: "select",
    Validations: {
      ValueType: "string",
      Required: true,
      RequiredMessage: "Este campo es obligatorio",
      Options: [
        {
          text: "Contrato",
          icon: (
            <InsertDriveFileOutlined
              fontSize="large"
              className="heading__primary-color"
            />
          ),
        },
        {
          text: "Otro",
          icon: (
            <TextSnippetOutlined
              fontSize="large"
              className="heading__primary-color"
            />
          ),
        },
      ],
    },
  },
  DocumentType: {
    InputName: "documentType",
    InputLabel: "Subtipo de documento",
    DefaultValue: "",
    InputType: "text",
    InputPlaceholder: "Contrato de trabajo",
    Validations: {
      ValueType: "string",
      Required: true,
      RequiredMessage: "Este campo es obligatorio",
      MaxLength: 250,
      MaxLengthMessage: `Máximo ${250} caracteres permitidos`,
    },
  },
  DocumentDescription: {
    InputName: "documentDescription",
    InputLabel: "Descripción",
    DefaultValue: "",
    InputType: "textarea",
    InputPlaceholder:
      "Usa este documento para todos los contratos de trabajo de la empresa",
    Validations: {
      ValueType: "string",
      Required: true,
      RequiredMessage: "Este campo es obligatorio",
      MaxLength: 1000,
      MaxLengthMessage: `Máximo ${1000} caracteres permitidos`,
    },
  },
});

export default DetailsFormInputs;
