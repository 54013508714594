import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../../components/loadingContent";
import CustomSearchFilter from "../../../../../../components/Search/CustomSearchFilter";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import { NUMBERINGWORDSO } from "../../../../../../utils/numberings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactHtmlParser from "react-html-parser";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  getSegmentedDocument,
  setClauseSelected,
  setClauseIndexSelected,
} from "./clauseBulkSlice";
import { addManyClauses, addNewClause } from "./clauseSlice";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import { CODES } from "../../../../../../utils/codes";

const ClausesBulk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDocumentResult, setIsOpenModalDocumentResult] =
    useState(false);

  const segmentedDocumentBulkCreateClause = useSelector(getSegmentedDocument);

  const [isOpenModalSaveClause, setIsOpenModalSaveClause] = useState(false);
  const [isOpenModalSaveAllClauses, setIsOpenModalSaveAllClauses] =
    useState(false);
  const [isOpenModalToLibrary, setIsOpenModalToLibrary] = useState(false);

  const [clauses, setClauses] = useState([]);
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [clauseCreateSelected, setClauseCreateSelected] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [multipleClauses, setMultipleClauses] = useState([]);
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);

  useEffect(() => {
    if (!segmentedDocumentBulkCreateClause) {
      navigate(-1);
    } else {
      setIsOpenModalDocumentResult(true);
      setClauses(segmentedDocumentBulkCreateClause);
      setFilteredClauses(segmentedDocumentBulkCreateClause);
      setMultipleClauses(segmentedDocumentBulkCreateClause);
    }
  }, []);

  const handleCloseModalDecisionSaveClause = () => {
    setIsOpenModalSaveClause(false);
  };

  const handleAgreeModalDecisionSaveClause = () => {
    handleCreateOneClause();
    setIsOpenModalSaveClause(false);
  };

  const handleCloseModalDecisionSaveAllClauses = () => {
    setIsOpenModalSaveAllClauses(false);
  };

  const handleAgreeModalDecisionSaveAllClauses = async () => {
    setIsOpenModalSaveAllClauses(false);
    handleCreateMultipleClause();
  };

  const handleUpdateClause = async (clause, index) => {
    dispatch(setClauseSelected(clause));
    dispatch(setClauseIndexSelected(index));
    navigate("edit-clause/bulkCreate");
  };

  const buildCategories = (categories) => {
    const newCategories = [];
    for (const category of categories) {
      newCategories.push(category._id);
    }
    return newCategories;
  };

  const buildLabels = (labels) => {
    const newLabels = [];
    for (const label of labels) {
      newLabels.push(label._id);
    }
    return newLabels;
  };

  const buildParagraphs = (paragraphs) => {
    const newParagraphs = [];
    for (const [index, paragraph] of paragraphs.entries()) {
      const clauseParagraph = {
        index: index + 1,
        text: paragraph?.paragraph?.text || paragraph?.text,
        blankspaces:
          paragraph?.paragraph?.blankspaces || paragraph?.blankspaces,
      };
      newParagraphs.push(clauseParagraph);
    }
    return newParagraphs;
  };

  const buildClause = (clause) => {
    const corporateUnit = localStorage.getItem("corporateUnitId");
    return {
      blankspaces: clause.blankspaces,
      categories: buildCategories(clause.categories),
      inFavor: clause.inFavor,
      isSensitive: clause.isSensitive,
      labels: buildLabels(clause.labels),
      paragraphs: buildParagraphs(clause.paragraphs),
      parts: {
        ...clause.parts,
      },
      sensitiveText: clause.sensitiveText,
      subclauses: clause.subclauses,
      text: clause.text,
      title: clause.title,
      corporateUnit,
    };
  };

  const handleSwitchParts = async (clause, index) => {
    setFilteredClauses((prevItems) => {
      let newArray = updateClauseSwap(prevItems, index);
      return newArray;
    });

    setMultipleClauses((prevItems) => {
      let newArray = updateClauseSwap(prevItems, index);
      return newArray;
    });
  };

  const updateClauseSwap = (prevItems, index) => {
    const updatedItems = [...prevItems];
    const updatedItem = { ...updatedItems[index] };

    updatedItem.text = handleChangeCurrentParts(updatedItem.text, [
      updatedItem?.parts?.partA,
      updatedItem?.parts?.partB,
    ]);
    updatedItems[index] = updatedItem;
    return updatedItems;
  };

  const handleChangeCurrentParts = (textHtml, parts) => {
    if (!textHtml) return "";

    const parser = new DOMParser();
    let htmlElement = parser.parseFromString(textHtml, "text/html");

    if (htmlElement?.querySelector(`[data-part-number="0"]`)) {
      const data = htmlElement?.querySelectorAll(`[data-part-number="0"]`);
      if (data?.length) {
        for (const item of data) {
          item.setAttribute("data-part-number", "x");
        }
      }
    }
    if (htmlElement?.querySelector(`[data-part-number="1"]`)) {
      const data = htmlElement?.querySelectorAll(`[data-part-number="1"]`);
      if (data?.length) {
        for (const item of data) {
          item.setAttribute("data-part-number", "y");
        }
      }
    }

    //Validamos que exista la data para cambiar su valor
    if (htmlElement?.querySelector(`[data-part-number="x"]`)) {
      const data = htmlElement?.querySelectorAll(`[data-part-number="x"]`);
      if (data?.length) {
        for (const item of data) {
          const part = parts[1];
          item.textContent = `°${part}°`;
          item.setAttribute("data-part", part);
          item.setAttribute("data-part-number", "1");
        }
      }
    }
    if (htmlElement?.querySelector(`[data-part-number="y"]`)) {
      const data = htmlElement?.querySelectorAll(`[data-part-number="y"]`);
      if (data?.length) {
        for (const item of data) {
          const part = parts[0];
          item.textContent = `°${part}°`;
          item.setAttribute("data-part", part);
          item.setAttribute("data-part-number", "0");
        }
      }
    }

    //Extraemos de Object Document el HTML String
    const stringifiedDocument = htmlElement.documentElement.outerHTML;

    //Limpiamos el HTML string para que no tenga etiquetas principales del html
    const cleanedString = stringifiedDocument
      .replace(/<\/?(html|head|body)>/gi, "")
      .replace(/style\s*=\s*['"]color:\s*red;['"]/gi, "")
      .replace(/<span\b(?![^>]*class=")[^>]*>(.*?)<\/span>/gi, "$1");

    return cleanedString;
  };

  const handleCreateOneClause = async () => {
    try {
      setIsLoading(true);
      let obj = buildClause(clauseCreateSelected);
      if (obj.categories[0] && obj.inFavor !== "") {
        const responseDispatch = await dispatch(addNewClause(obj)).unwrap();
        setResponseData(responseDispatch);
      } else {
        let defaultObj = {
          ...obj,
          inFavor: obj.inFavor === "" ? "Balanceada" : obj.inFavor,
          categories: obj.categories[0]
            ? obj.categories
            : ["6244ad439bdc62c9d12e16c6"],
        };
        const responseDispatch = await dispatch(
          addNewClause(defaultObj)
        ).unwrap();
        if (responseDispatch.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          const filterClauses = multipleClauses.filter(
            (item) => item !== clauseCreateSelected
          );
          setMultipleClauses(filterClauses);
        }
        setResponseData(responseDispatch);
      }
      setIsOpenModalToLibrary(true);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateMultipleClause = async () => {
    try {
      setIsLoading(true);
      let saveClauses = [];
      const arrayClauses = multipleClauses || [];
      for (const clause of arrayClauses) {
        let obj = buildClause(clause);
        if (obj.categories[0] && obj.inFavor !== "") {
          saveClauses = [...saveClauses, obj];
        } else {
          saveClauses = [
            ...saveClauses,
            {
              ...obj,
              inFavor: obj.inFavor === "" ? "Balanceada" : obj.inFavor,
              categories: obj.categories[0]
                ? obj.categories
                : ["6244ad439bdc62c9d12e16c6"],
            },
          ];
        }
      }
      const responseDispatch = await dispatch(
        addManyClauses({ clauses: saveClauses })
      ).unwrap();
      if (
        responseDispatch?.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
        responseDispatch?.data?.responseCode ===
          CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setIsOpenModalUnauthorized(true);
        setResponseData(responseDispatch);
        throw new Error("No tienes los permisos suficientes");
      }
      setResponseData(responseDispatch);
      setIsOpenModalToLibrary(true);
      setIsOpenModal(true);
    } catch (error) {
      console.log("============ Create Multiple Clauses Error ============");
      console.log(error);
      console.log(
        "============ Finish Create Multiple Clauses Error ============"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModalToLibrary = () => {
    setIsOpenModalToLibrary(false);
  };

  const handleAgreeModalToLibrary = () => {
    navigate("../library/clauses/");
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Crear cláusula desde un contrato"} />
      </Row>
      <br />
      <br />
      <Row className="mb-5">
        <Col xs={5}>
          <CustomSearchFilter
            list={clauses}
            setFilteredData={setFilteredClauses}
            placeholder="Buscar cláusula"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Divider />
      </Row>
      <Row className="mb-5">
        <label className="heading__primary-color">
          {segmentedDocumentBulkCreateClause?.title || ""}
        </label>
      </Row>
      <Row className="mb-5">
        <div>
          {filteredClauses?.map((clause, i) => {
            return (
              <div key={clause?._id}>
                <Accordion className="custom-accordion">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Row style={{ width: "100%" }}>
                      <Col sm={4}>
                        <p className="heading__primary-color">{clause.title}</p>
                      </Col>
                      <Col
                        sm={{ span: 4, offset: 4 }}
                        style={{ textAlign: "right" }}
                      >
                        <Row>
                          <Col sm={"auto"}>
                            <span className="subheading">
                              A favor de:{" "}
                              <span className="caption">
                                {clause.inFavor || "--------"}
                              </span>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col xs={6}>
                        <span className="subheading">
                          Categoría:{" "}
                          {clause.categories.map((category) => {
                            return (
                              <span
                                key={category?._id}
                                style={{ marginRight: "1%" }}
                                className="caption custom-badges__secundary"
                              >
                                {category.name || "--------"}
                              </span>
                            );
                          })}
                        </span>
                      </Col>
                      {Object.values(clause?.parts || {}).filter(
                        (prop) => prop !== null
                      )?.length === 2 && (
                        <Col xs={"auto"}>
                          <Button
                            variant="text"
                            size="medium"
                            className="custom-input__button__link"
                            onClick={() => {
                              handleSwitchParts(clause, i);
                            }}
                          >
                            Click aquí para intercambiar partes
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <br />
                    <br />
                    <Row>
                      <span className="caption">
                        {/* Text clause */}
                        {ReactHtmlParser(clause.text)}
                        <ol className="clauses">
                          {clause.subclauses?.map((item) => {
                            return (
                              <li
                                key={item?._id}
                                className="justify-text"
                                style={{ fontWeight: "bold" }}
                              >
                                <b>
                                  {item.subclause?.title &&
                                    `${item.subclause.title}:`}
                                </b>
                                <span className="caption">
                                  {ReactHtmlParser(
                                    item?.subclause?.text ||
                                      item?.newSubclause?.text
                                  )}
                                </span>
                                <ol>
                                  {item.subclause?.childs &&
                                    item.subclause?.childs.map((child) => {
                                      return (
                                        <li
                                          key={child?._id}
                                          className="justify-text clauses-number"
                                        >
                                          <b>{child.title || ""}</b>
                                          <span className="caption">
                                            {ReactHtmlParser(child.text)}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ol>
                              </li>
                            );
                          })}
                        </ol>
                        <br />
                        <ol className="paragraphs clauses-number">
                          {clause.paragraphs.map((el, i) => {
                            return (
                              <li
                                key={el?._id}
                                className="justify-text clauses-number"
                                numbering={`Parágrafo ${
                                  NUMBERINGWORDSO[i + 1]
                                }`}
                              >
                                <span className="caption">
                                  {ReactHtmlParser(
                                    el?.text || el?.paragraph?.text
                                  )}
                                </span>
                              </li>
                            );
                          })}
                        </ol>
                      </span>
                    </Row>
                    <Row style={{ justifyContent: "right" }}>
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          startIcon={<EditOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          onClick={() => handleUpdateClause(clause, i)}
                        >
                          <p className="button">Editar cláusula</p>
                        </Button>
                      </Col>
                      <Col xs={"auto"}>
                        <Button
                          variant="contained"
                          startIcon={<LocalLibraryOutlinedIcon />}
                          className="custom-input__button__secondary-color"
                          onClick={() => {
                            setIsOpenModalSaveClause(true);
                            setClauseCreateSelected(clause);
                          }}
                        >
                          <p className="button">
                            Agregar esta cláusula a mi biblioteca
                          </p>
                        </Button>
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </Row>
      <Row className="mb-5" style={{ justifyContent: "right" }}>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<DeleteOutlineIcon fontSize="large" />}
            className="custom-input__button__secondary-color"
            onClick={() => setIsOpenModalToLibrary(true)}
          >
            Descartar
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            type="submit"
            variant="contained"
            startIcon={<PostAddOutlinedIcon fontSize="large" />}
            className="custom-input__button__primary-color"
            onClick={() => setIsOpenModalSaveAllClauses(true)}
          >
            Agregar todas la cláusulas a mi biblioteca
          </Button>
        </Col>
        <ModalWarningForm
          title={"Atención"}
          description={
            "Las cláusulas que te presentamos, son obtenidas mediante un sistema de Inteligencia Artificial. Por esta razón, es importante revisar con atención los resultados presentados, de forma previa a la incorporación de la cláusula a tu biblioteca."
          }
          open={isOpenModalDocumentResult}
          onClose={() => setIsOpenModalDocumentResult(false)}
        />
        <ModalDecision
          title={"Guardar Cláusula"}
          message={"¿Estás seguro de guardar esta cláusula en la biblioteca?"}
          open={isOpenModalSaveClause}
          onClose={handleCloseModalDecisionSaveClause}
          agreeText="Guardar"
          disagreeText="Cancelar"
          handleAgree={handleAgreeModalDecisionSaveClause}
          handleDisagree={handleCloseModalDecisionSaveClause}
        />

        <ModalDecision
          title={"Guardar Cláusulas"}
          message={
            "¿Estás seguro de guardar todas las cláusulas en la biblioteca?"
          }
          open={isOpenModalSaveAllClauses}
          onClose={handleCloseModalDecisionSaveAllClauses}
          agreeText="Guardar"
          disagreeText="Cancelar"
          handleAgree={handleAgreeModalDecisionSaveAllClauses}
          handleDisagree={handleCloseModalDecisionSaveAllClauses}
        />

        <ModalInfo
          title={"Creación de cláusula"}
          responseData={responseData}
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />

        <ModalDecision
          title={"Cláusulas"}
          message={
            "¿Quieres ir a cláusulas en biblioteca? si tienes cambios pendientes se borrarán"
          }
          open={isOpenModalToLibrary}
          onClose={handleCloseModalToLibrary}
          agreeText="Ir a biblioteca"
          disagreeText="Cancelar"
          handleAgree={handleAgreeModalToLibrary}
          handleDisagree={handleCloseModalToLibrary}
        />
        <ModalInfo
          title="Sin permisos suficientes"
          onClose={() => setIsOpenModalUnauthorized(false)}
          open={isOpenModalUnauthorized}
          responseData={responseData}
          confirmationText="Aceptar"
        />
      </Row>
    </Container>
  );
};

export default ClausesBulk;
