import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import LoadingContent from "../../../../../../../components/loadingContent";
import Breadcrum from "../../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../../components/sectionTitle";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DiscussionBoard from "../../../../../../../components/DiscussionBoard/DiscussionBoard";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Tab, Tabs } from "@mui/material";
import { REGEXP } from "../../../../../../../utils/regexp";
import { TabContext, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import { MDBDataTableV5 } from "mdbreact";
import { findOneDataExternalRequest } from "../../../../../../../services/general/CarvajalServices";
import { CODES } from "../../../../../../../utils/codes";
import { useParams, useNavigate } from "react-router-dom";
import { dateNormal } from "../../../../../../../utils/getDate";
import {
  addCommentApproval,
  createApproval,
} from "../../../../../../../services/approvals/approvalServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { getFileDocument } from "../../../../../../../services/documents/documentsRequest";
import { saveAs } from "file-saver";
import { validateCompleteSigns } from "../ViewRequestStandard";

const CreateApproval = () => {
  //Hooks
  const { consecutive } = useParams();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test("onlyLettter", "*Solo debe incluir letra", function (value) {
        if (value.trim()) {
          const schema = yup.string().matches(REGEXP.ONLY_LETTERS);
          return schema.isValidSync(value);
        }
        return false;
      }),
    position: yup
      .string()
      .required("*Este campo es obligatorio")
      .test("onlyLettter", "*Solo debe incluir letra", function (value) {
        if (value.trim()) {
          const schema = yup.string().matches(REGEXP.ONLY_LETTERS);
          return schema.isValidSync(value);
        }
        return false;
      }),
    email: yup
      .string()
      .required("*Este campo es obligatorio")
      .email("*Este campo debe ser un correo válido"),
    message: yup.string().required("*Este campo es obligatorio"),
    documentId: yup.string().nullable().required("*Este campo es obligatorio"),
  });

  const COLUMNS_DOCUMENTS_PRINCIPAL = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Seleccionar documento
        </label>,
      ],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Seguimiento
        </label>,
      ],
      field: "followup",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Descripción
        </label>,
      ],
      field: "description",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Creado por
        </label>,
      ],
      field: "createdBy",
    },
    {
      label: [
        <label aria-hidden="true" key="4">
          Dirigido a
        </label>,
      ],
      field: "assignedTo",
    },
    {
      label: [
        <label aria-hidden="true" key="5">
          Fecha de creación
        </label>,
      ],
      field: "createdAt",
    },
    {
      label: [
        <label aria-hidden="true" key="6">
          Documento Adjunto
        </label>,
      ],
      field: "originalFilename",
    },
  ];

  const COLUMNS_DOCUMENTS_REQUIRED = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Selecionar documento
        </label>,
      ],
      field: "select",
    },
    {
      label: [
        <label aria-hidden="true" key="1">
          Documento anexo
        </label>,
      ],
      field: "requiredDoc",
    },
    {
      label: [
        <label aria-hidden="true" key="6">
          Documento Adjunto
        </label>,
      ],
      field: "originalFilename",
    },
  ];

  const STATES_COLOR = {
    100: "#FFD7B1",
    200: "#E6E9EF",
    300: "#CCF4E3",
    400: "#F9DADE",
  }

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [indexTab, setIndexTab] = useState("1");
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [isModalRequest, setIsModalRequest] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [documentList, setDocumentsList] = useState("");
  const [dataForm, setDataForm] = useState({});
  const [requestCommentaries, setRequestCommentaries] = useState({
    _id: "",
    comments: [],
  });
  const [dataTableDocumentPrincipal, setDataTableDocumentPrincipal] = useState({
    columns: [],
    rows: [],
  });
  const [dataTableRequiredDocs, setDataTableRequiredDocs] = useState({
    columns: [],
    rows: [],
  });

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [isOpenModalTypeDocument, setIsOpenModalTypeDocument] = useState(false);

  //Handler
  const handleChangeTab = (event, newValue) => {
    setIndexTab(newValue);
  };
  const handleRequestCreateApproval = async () => {
    try {
      setIsOpenModalTypeDocument(false);
      setIsModalRequest(false);
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const user = JSON.parse(localStorage.getItem("payloadToken"));
      let userName = "";
      if (user?.personType === "Juridica") {
        userName = user?.companyName || "";
      } else {
        userName = `${user?.firstName || ""} ${user?.secondName || ""} ${user?.firstSurname || ""} ${user?.secondSurname || ""}`;
      }
      const isMainDocument = handleSearchName(true);
      const stateApproval = "629fb9275f7d5320e0942b23";
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const requestCreateApproval = await createApproval(
        consecutive,
        userName,
        dataForm.name,
        dataForm.position,
        dataForm.email,
        dataForm.message,
        dataForm.documentId,
        company,
        isMainDocument,
        stateApproval,
        corporateUnit
      );
      if (
        requestCreateApproval.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
      ) {
        setResponseDataUnauthorized(requestCreateApproval);
        setIsOpenModalUnauthorized(true);
        return;
      }
      setResponseData(requestCreateApproval);
      setIsOpenModal(true);
      setIsModalRequest(false);
      if (
        requestCreateApproval.status === CODES.COD_RESPONSE_HTTP_OK ||
        requestCreateApproval.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        const userRole = localStorage.getItem("role");
        const approvalComment = {
          userName: userName,
          aplicantName:
            requestCreateApproval.data.responseMessage.data.aplicantName,
          approverName: dataForm.name,
          userPosition: userRole === "EXTERNAL" ? "Solicitante" : "Abogado",
          message: dataForm.message,
          associatedDocument: dataForm.documentId,
          isCommentOnly: false,
          isSendMail: true,
          isApprovalComment: true,
          isFirstComment: true,
        };
        await addCommentApproval({
          consecutive,
          approvalId: requestCreateApproval.data.responseMessage.data._id,
          approvalComment,
          isDiscussionComment: true,
          companyId: company,
          corporateUnit
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickDownload = async ({filenameInBucket, bucketName}) => {
    try {
      setIsLoading(true);
      const file = await getFileDocument(filenameInBucket, bucketName, false);
      const bf = file.data.responseMessage.buffer.data
      const blob = new Blob([new Uint8Array(bf).buffer]);
      let fileName = file.data.responseMessage.filename;
      const contentType = fileName.split(".").length === 1 && file.data.responseMessage.contentType;
      if (contentType && contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        fileName = fileName + ".docx"
      } else if (contentType && contentType === "application/pdf") {
        fileName = fileName + ".pdf"
      }
      saveAs(blob, fileName);
    } catch (error) {
        console.error(error)
    } finally {
      setIsLoading(false);
    }
  };
  const handlePreRequest = (data) => {
    setDataForm(data);
    if (documentList) {
      const { 
        documentPrincipal,
        documentsAttachments,
        documentsOtherApplicant,
        documentsOtherAttorney
      } = documentList;
      const docs = [documentPrincipal, documentsAttachments, documentsOtherApplicant, documentsOtherAttorney].flat();
      const docFilter = docs.find(doc => doc._id === data.documentId);
      const typeDocument = docFilter.filenameInBucket.split(".").pop();
      if (typeDocument !== "pdf") {
        setIsOpenModalTypeDocument(true);
        return;
      }
    }
    setIsModalRequest(true);
  };
  const handleSearchName = (isRequestValidate) => {
    const arrayDocumentsPrincipals = documentList?.documentPrincipal || [];
    const arrayDocumentsAttachments = documentList?.documentsAttachments || [];
    for (const document of arrayDocumentsPrincipals) {
      if (document._id === getValues("documentId")) {
        return isRequestValidate ? true : document.originalFilename;
      }
    }
    for (const document of arrayDocumentsAttachments) {
      if (document._id === getValues("documentId")) {
        return isRequestValidate ? false : document.originalFilename;
      }
    }
  };

  const getTitle = (item) => {
    if (item?.isSignatureFinish) return item?.tracking || "";
    if (item?.userRole === "EXTERNAL") return " Respuesta Solicitante";
    if (item?.userRole === "SERVICE") return " Respuesta Abogado";
    return " Documento adjunto aprobación";
  };

  //UseEffect
  useEffect(() => {
    const handleRequestDocument = async () => {
      try {
        const corporateUnit = localStorage.getItem("corporateUnitId");
        const company = localStorage.getItem("company");

        const obj = {
          consecutive,
          company,
          corporateUnit,
        };
        const GetDataRequest = await findOneDataExternalRequest(obj);
        if (GetDataRequest.status) {
          if (GetDataRequest.status === CODES.COD_RESPONSE_HTTP_OK) {
            const data = GetDataRequest.data.responseMessage;

            const dataFilter = {
              _id: data?._id,
              stateRequest: data?.requestStatus?.name,
              color: STATES_COLOR[data?.requestStatus?.colorId] || "light",
              applicant:
                data?.applicant?.firstName + " " + data?.applicant?.firstSurname,
              applicantId: data?.applicant?._id,
              attorneyInCharge:
                data?.attorneyInCharge?.firstName +
                " " +
                data?.attorneyInCharge?.firstSurname,
              requestSubject: data?.requestSubject,
              requestType: data?.requestType,
              requestSubType: data?.requestSubType?.name,
              responseLimitDate: data?.responseLimitDate,
              requestDetails: data?.requestDetails,
              documentsOtherAttorney: data?.documents?.attorneyDocuments,
              documentsAttachments: data?.documents?.attorneyDocuments?.concat(
                data?.documents?.applicantDocuments,
                data?.documents?.requiredDocuments
              ),
              documentsOtherApplicant: data?.documents?.applicantDocuments,
              documentPrincipal: data?.documents?.mainDocuments.filter(doc => !(!doc.isRejected && !validateCompleteSigns(data.signatures, doc) && doc.subtype === "Signature")),
              comments: data?.comments,
            };
            setDocumentsList(dataFilter);
            setDataTableDocumentPrincipal({
              columns: COLUMNS_DOCUMENTS_PRINCIPAL,
              rows: dataFilter?.documentPrincipal
                .filter((document) => {
                  return !document.isRejected;
                })
                .reverse()
                .map((filterDocument, index) => {
                  return {
                    select: (
                      <input
                        {...register("documentId")}
                        type="radio"
                        name="documentId"
                        value={filterDocument._id}
                        style={{
                          margin: "30px",
                        }}
                      />
                    ),
                    description: filterDocument.description,
                    createdAt: dateNormal(filterDocument.createdAt),
                    createdBy: filterDocument.createdBy,
                    assignedTo: filterDocument.assignedTo,
                    followup: (
                      <label className="caption">
                        {`#${index + 1} ${getTitle(filterDocument)}`}
                      </label>
                    ),
                    originalFilename: (
                      <label
                        onClick={() => {
                          handleClickDownload(filterDocument);
                        }}
                        style={{
                          color: "#17B4BC",
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          borderRadius: "0.8rem",
                          padding: "0.4rem",
                        }}
                      >
                        {filterDocument.originalFilename}
                      </label>
                    ),
                  };
                }),
            });

            setDataTableRequiredDocs({
              columns: COLUMNS_DOCUMENTS_REQUIRED,
              rows: dataFilter?.documentsAttachments.map((document) => {
                if (!document.filenameInBucket) {
                  return <></>;
                }
                return {
                  select: (
                    <input
                      {...register("documentId")}
                      type="radio"
                      name="documentId"
                      value={document._id}
                      style={{
                        margin: "30px",
                      }}
                    />
                  ),
                  requiredDoc: document.originalFilename,
                  originalFilename: (
                    <label
                      onClick={() => {
                        handleClickDownload(document);
                      }}
                      style={{
                        color: "#17B4BC",
                        cursor: "pointer",
                        fontSize: "1.5rem",
                        borderRadius: "0.8rem",
                        padding: "0.4rem",
                      }}
                    >
                      {document.originalFilename}
                    </label>
                  ),
                };
              }),
            });
            setRequestCommentaries({
              _id: GetDataRequest.data.responseMessage._id,
              comments: GetDataRequest.data.responseMessage.comments,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleRequestDocument();
  }, []);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <Col xs={3} className="col-start">
          <SectionTitle title={`Vistos buenos - Solicitud ${consecutive}`} />
        </Col>
        <Col xs={6}></Col>
        <Col className="col-flex" xs={2}>
          <Button
            variant="contained"
            startIcon={<ArticleOutlinedIcon />}
            className="custom-input__button__secondary-color"
            size="medium"
            onClick={() => setIsHideDiscussion(false)}
          >
            Mensajes
          </Button>
        </Col>
      </Row>
      <div className="custom-accordion__container-document">
        <Row className="heading" style={{ margin: "2% 0" }}>
          Datos del aprobador
        </Row>
        <Row className="caption" style={{ margin: "1% 0" }}>
          Nombre del aprobador
        </Row>
        <Form onSubmit={handleSubmit(handlePreRequest)}>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("name")}
                  placeholder="Nombres y apellidos*"
                  bsPrefix={
                    errors.name
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.name?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Cargo del aprobador
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("position")}
                  placeholder="Escriba un cargo*"
                  bsPrefix={
                    errors.position
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.position?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Correo electrónico:
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-5">
                <Form.Control
                  {...register("email")}
                  placeholder="correo@ejemplo.com*"
                  bsPrefix={
                    errors.email
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.email?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="heading" style={{ margin: "1% 0" }}>
            Solicitar
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Escriba un mensaje para enviar al aprobador, junto con el archivo adjunto:
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register("message")}
                  as="textarea"
                  placeholder="Cordial Saludo...*"
                  bsPrefix={
                    errors.message
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.message?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="caption" style={{ margin: "1% 0" }}>
            Elija el documento del cual solicita aprobación:
          </Row>
          <Row>
            <TabContext value={indexTab}>
              <Box>
                <Tabs 
                  value={indexTab} 
                  onChange={handleChangeTab}
                  className="label__primary-color"
                  sx={{
                    '& .MuiTab-root': {
                      color: '#00374f !important',
                      textTransform: 'none !important',
                      fontWeight: '400 !important',
                      fontSize: '1.2rem !important'
                    },
                    '& .Mui-selected': {
                      color: '#00374f !important',
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: '#00374f !important',
                    },
                  }}
                >
                  <Tab label="Documentos principales" value={"1"} />
                  <Tab label="Documentos anexos" value={"2"} />
                </Tabs>
              </Box>
              {[dataTableDocumentPrincipal,dataTableRequiredDocs].map((docs, index) => (
                <TabPanel
                  key={docs._id}
                  value={`${index + 1}`}
                  sx={{
                    '.css-13xfq8m-MuiTabPanel-root': {
                      padding: 'none',
                    }
                  }}
                >
                  <>
                    <div
                      className="caption custom-input__error"
                      style={{ border: "1px sollid red" }}
                    >
                      {errors.documentId?.message}
                    </div>
                    <Row className="data-table dt-list-items">
                      <MDBDataTableV5
                        noRecordsFoundLabel="No se encontraron resultados"
                        hover
                        infoLabel={["Mostrando", "a", "de", "documentos"]}
                        entries={5}
                        pagesAmount={4}
                        data={docs}
                        searchBottom={false}
                        pagingTop
                      />
                    </Row>
                  </>
                </TabPanel>
              ))}
            </TabContext>
          </Row>
          <Row className="justify-content-md-end">
            <Col xs={"auto"}>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
                size="medium"
              >
                Solicitar aprobación
              </Button>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={requestCommentaries.comments}
        id={requestCommentaries._id}
      />
      <ModalInfo
        title={"Solicitud exitosa"}
        responseData={responseData}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal();
          navigate(-1);
        }}
      />
      <ModalDecision
        title={"Atención"}
        message={`¿Estás seguro que quieres pedir la aprobación del documento ${handleSearchName()}?`}
        agreeText={"Solicitar aprobación"}
        disagreeText={"Cancelar"}
        handleAgree={handleRequestCreateApproval}
        handleDisagree={() => {
          setIsModalRequest(false);
        }}
        open={isModalRequest}
        onClose={setIsModalRequest}
      />
      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => {
          setIsOpenModalUnauthorized(false);
          navigate(-1);
        }}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={
          <Row style={{ alignItems: 'center' }} className="heading__warning-color">
            <Col>
              <WarningAmberOutlinedIcon fontSize="large" />
            </Col>
            <Col>
              <span>Aprobaciones</span>
            </Col>
          </Row>
        }
        message={`El documento es un formato diferente a .PDF por lo que podrás consultar si fue aprobado en la plataforma. Si quieres ver la marca de aprobación en el documento cárgalo en PDF. ¿Seguro quieres continuar?`}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        handleAgree={handleRequestCreateApproval}
        handleDisagree={() => {
          setIsOpenModalTypeDocument(false);
        }}
        open={isOpenModalTypeDocument}
        onClose={() => setIsOpenModalTypeDocument(false)}
      />
    </Container>
  );
};

export default CreateApproval;
