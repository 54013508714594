import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CardDocumentInfo from "../../../../../../../components/Cards/CardDocumentInfo";
import ModalUploadDocs from "../../../../../../../components/Modals/ModalUploadDocs";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import { yupResolver } from "@hookform/resolvers/yup";
import dateFormat from "dateformat";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../../../../../components/loadingContent";
import {
  getDocById,
  getFileDocument,
  getPartsFromDocument,
} from "../../../../../../../services/documents/documentsRequest";
import { CODES } from "../../../../../../../utils/codes";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import ModalAssociateVersion from "../../../../../../../components/Modals/modalAssociateVersion";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import ModalWarningForm from "../../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../../components/Popover/PopoverInfo";
import {
  cleanStore,
  setAuthor,
  setHeader,
  setInfoDocument,
  setInitialInfoLibrary,
  setName,
  setSelectedClausesWithoutCheckPart,
} from "../../../../../../../parts/document/currentDocumentSlice";
import {
  fetchAllFolders,
  getFolders,
  getStatusFolders,
} from "../../../../../../../parts/folders/foldersSlice";
import { addVersion } from "../../../../../../../services/versions/versionRequest";
import {
  FROM_REVIEW,
  REVIEW_TYPES,
} from "../../../../../../../utils/approvalsState";
import { codesDocumentType } from "../../../../../../../utils/codesDocumentType";
import { Mixpanel } from "../../../../../../../utils/mixPanel";
import axios from "axios";
import { convertToPdfService } from "../../../../../../../services/utils/convertWordToPdf";
import GetToken from "../../../../../../../utils/getToken";
import { env } from "../../../../../../../env";
import {
  selectListInfoForModule,
  updateListBackgroundProcesses,
} from "../../../../../../../parts/backgroundProcess/backgroundProcessSlice";
import LoadingContentInBackground from "../../../../../../../components/loadingContentInBackground";
import WorkerBuilder from "../../../../../../../workers/worker-builder";
import {
  getFileFromIndexedDB,
  saveFileToIndexedDB,
} from "../../../../../../../utils/indexdb";
import WorkerContract from "../../../../../../../workers/reviewContractWorker";
import { v4 as uuidv4 } from "uuid";
import { CustomToolTip } from "../../../../../../../components/Tooltip/Tooltip";
import { changeFileExtension } from "../../../../../../../utils/fileUtils";
const ReviewUploadDocument = () => {
  //Consts

  const serviceUrl = env.REACT_APP_API_URL;
  const token = GetToken();
  const payloadToken = JSON.parse(localStorage.payloadToken);

  //Background workers

  const reviewWorkerRefSavedContract = useRef(null);

  const listInfoReviewBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessReviewSavedContract")
  );

  const listInfoContracSavedtBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoReviewBackground) {
      let currentInfo = {};
      listInfoReviewBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoContracSavedtBackgroundRef.current = currentInfo;
    }
  }, [listInfoReviewBackground]);

  /**
   * Yup
   */

  const schema = yup.object().shape({
    owner: yup
      .string()
      .required("Este campo es obligatorio")
      .max(250, "*Máximo 250 caracteres permitidos"),
  });

  /**
   * Use Form
   */
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  /**
   * Use Params
   */

  const { documentId } = useParams();

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * UseNavigate
   */

  const navigate = useNavigate();
  const location = useLocation();
  const { state: locationState } = location;

  /**
   * useSelector
   */

  const folders = useSelector(getFolders);
  const foldersStatus = useSelector(getStatusFolders);

  /**
   * Use State
   */

  //Upload Document
  const [documentInAssociationData, setDocumentInAssociationData] = useState(
    {}
  );

  //Document to associate

  const [baseDocumentAssociation, setBaseDocumentAssociation] = useState({});

  //Modals
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [openSucceedModal, setOpenSucceedModal] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [openFailedSegModal, setOpenFailedSegModal] = useState(false);
  const [openModalVersion, setOpenModalVersion] = useState(false);
  const [openModalVersionChangeDocument, setOpenModalVersionChangeDocument] =
    useState(false);
  const [idProcess, setIdProcess] = useState(null);

  //LOADING PAGE
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFetchParts, setIsLoadingFetchParts] = useState(false);

  //Document parts
  const [documentPartList, setDocumentPartList] = useState([]);

  //Segmented document
  const [documentSelected, setDocumentSelected] = useState({});
  const [responseData, setResponseData] = useState({});

  //Validation Document Exist
  const [isEmptyDocumentLoad, setIsEmptyDocumentLoad] = useState(false);

  //Background states
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);
  const [allowBackgroundOptions, setAllowBackgroundOptions] = useState(true);

  /**
   * UseEffect
   */

  //loading base Document
  useEffect(() => {
    const asociatedDocumentData = async (id) => {
      setIsLoading(true);
      const info = await getDocById(id);
      if (info.status === CODES.COD_RESPONSE_HTTP_OK && info.data.success) {
        const updated = dateFormat(
          info.data.responseMessage.updatedAt,
          "paddedShortDate"
        );
        const text = `Última actualización: ${updated}`;
        setBaseDocumentAssociation({
          ...info.data.responseMessage,
          name: info.data.responseMessage.name,
          documentName: text,
          bucketName: info.data.responseMessage.bucketName,
          filenameInBucket: info.data.responseMessage.filenameInBucket,
          businessFolderId: info.data.responseMessage.folderAssociated,
          versions: info.data.responseMessage.versions,
        });

        setIsLoading(false);
      }
    };
    asociatedDocumentData(documentId);
    //Set clean current document
    dispatch(cleanStore());
  }, []);

  //loading folders

  useEffect(() => {
    if (foldersStatus === "fetch") {
      dispatch(fetchAllFolders());
    }
  }, [foldersStatus]);

  /**
   * Handles
   */

  //Set to current document
  const setToCurrentDocument = (data) => {
    let parts = [];
    if (data.parts.partA) {
      parts.push(data.parts.partA);
    }
    if (data.parts.partB) {
      parts.push(data.parts.partB);
    }
    if (data.parts.partC) {
      parts.push(data.parts.partC);
    }
    if (data.parts.partD) {
      parts.push(data.parts.partD);
    }

    //Headers
    for (const element of data.headers) {
      dispatch(setHeader(element));
    }

    //Clauses (parts correction)
    let formatedClauses = [];
    for (const element of data.clauses) {
      let clause = element;
      let newPartsFormat = [];
      if (clause.parts.partA) {
        newPartsFormat.push(clause.parts.partA);
      }
      if (clause.parts.partB) {
        newPartsFormat.push(clause.parts.partB);
      }
      if (clause.parts.partC) {
        newPartsFormat.push(clause.parts.partC);
      }
      if (clause.parts.partD) {
        newPartsFormat.push(clause.parts.partD);
      }

      formatedClauses.push({ ...clause, parts: newPartsFormat });
    }

    dispatch(
      setInitialInfoLibrary({
        title: data.title ? data.title : data.name,
        parts: parts,
        summary: data.summary,
        represented: data.represented,
      })
    );
    dispatch(setName({ name: data.name }));
    dispatch(setSelectedClausesWithoutCheckPart({ clauses: formatedClauses }));
    dispatch(
      setInfoDocument({
        filenameInBucket: data.filenameInBucket,
        bucketName: data.bucketName,
      })
    );
  };

  //Extract parts from document
  const handleProccesDocumentPartsExtraction = async ({
    file,
    filenameInBucket,
    bucketName,
  }) => {
    try {
      const company = localStorage.getItem("company");
      const partsExtractionObj = {
        file,
        filenameInBucket,
        bucketName,
        company,
      };
      setIsLoadingFetchParts(true);
      const service = await getPartsFromDocument(partsExtractionObj);

      if (
        service.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        service?.data?.success
      ) {
        setDocumentPartList(service.data?.responseMessage?.data);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingFetchParts(false);
    } finally {
      setIsLoadingFetchParts(false);
    }
  };

  //Segment document
  const handleConfirmationAndSegment = async (data) => {
    try {
      setAllowBackgroundOptions(false);

      setIsLoadingInBackground(true);
      let paramsContract = {};
      let fileUpload = null;

      const isEditable =
        documentSelected?.fileType === codesDocumentType.contract._id ||
        documentSelected?.fileType === codesDocumentType.draft._id;

      const represented =
        data.represented === "OTRO" ? data.otherRepresented : data.represented;

      const hasClauses = documentSelected?.documentContent?.clauses?.length;

      if (isEditable && hasClauses) {
        paramsContract = {
          company: payloadToken.company,
          document: JSON.stringify(documentSelected?.documentContent),
          from: FROM_REVIEW.CLAUSES,
          typeReview: REVIEW_TYPES.EXISTING_LEGAL,
        };
        const document = await getFileDocument(
          documentSelected?.filenameInBucket,
          documentSelected?.bucketName,
          false
        );
        const { buffer, contentType } = document.data.responseMessage;
        const arrayBuffer = new Uint8Array(buffer?.data).buffer;

        fileUpload = new File([arrayBuffer], documentSelected?.name, {
          type: contentType,
        });
      } else if (isEditable && !hasClauses) {
        const document = await getFileDocument(
          documentSelected?.filenameInBucket,
          documentSelected?.bucketName,
          false
        );
        let documentFile = document.data.responseMessage;

        const { buffer, contentType } = documentFile;
        fileUpload = new File([buffer?.data], documentSelected?.name, {
          type: contentType,
        });

        if (contentType !== "application/pdf") {
          const { data } = buffer || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);

          const docView = pdfConverted.data.responseMessage.data.buffer.data;
          const bf = Buffer.from(docView);
          const name = changeFileExtension(documentSelected?.name, "pdf");
          fileUpload = new File([bf], name, {
            type: "application/pdf",
          });
        }
        paramsContract = {
          file: fileUpload,
          company: payloadToken.company,
          from: FROM_REVIEW.FILE,
          typeReview: REVIEW_TYPES.EXISTING,
        };
      } else if (!isEditable && !documentInAssociationData?.name) {
        paramsContract = {
          company: payloadToken.company,
          bucketName: documentSelected?.bucketName,
          filenameInBucket: documentSelected?.filenameInBucket,
          from: FROM_REVIEW.DOCUMENTS,
          typeReview: REVIEW_TYPES.EXISTING_LEGAL,
        };
        const document = await getFileDocument(
          documentSelected?.filenameInBucket,
          documentSelected?.bucketName,
          false
        );
        const { buffer, contentType } = document.data.responseMessage;
        fileUpload = new File([buffer?.data], documentSelected?.name, {
          type: contentType,
        });
      } else {
        let dataFile = documentInAssociationData;
        if (documentInAssociationData?.type !== "application/pdf") {
          const convertDoc = await convertToPdfService(
            documentInAssociationData,
            "file"
          );
          const docView = convertDoc.data.responseMessage.data.buffer.data;
          const bf = Buffer.from(docView);
          const name = documentInAssociationData?.name.substring(
            0,
            documentInAssociationData?.name.lastIndexOf(".")
          );
          dataFile = new File([bf], name + ".pdf", {
            type: "application/pdf",
          });
        }
        paramsContract = {
          file: dataFile,
          company: payloadToken.company,
          from: FROM_REVIEW.FILE,
          typeReview: REVIEW_TYPES.EXISTING,
        };
      }
      setAllowBackgroundOptions(true);
      if (reviewWorkerRefSavedContract.current) {
        const idProcess = uuidv4();
        const nameFile = documentInAssociationData?.name || "";
        setIdProcess(idProcess);
        reviewWorkerRefSavedContract.current.postMessage({
          paramsContract,
          token: token,
          serviceUrl: serviceUrl,
          idProcess,
        });
        const idFileIDB = uuidv4();
        const idDocumentIDB = uuidv4();
        await saveFileToIndexedDB(fileUpload, idFileIDB);
        await saveFileToIndexedDB(documentInAssociationData, idDocumentIDB);
        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessReviewSavedContract",
            updateData: {
              id: idProcess,
              nameFile,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                otherInfo: {
                  owner: data.owner,
                  data: { represented },
                  documentId: documentId,
                  baseDocumentAssociation: baseDocumentAssociation?._id,
                  idFileUpload: idFileIDB,
                  idDocumentAssociated: idDocumentIDB,
                },
              },
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setResponseData({
          status: 500,
          data: {
            responseMessage:
              "El proceso esta tomando más tiempo de lo normal, por favor intentelo más tarde.",
          },
        });
        setOpenFailedSegModal(true);
        setIsLoadingInBackground(false);
      }
      console.log("========= Start handle Segment Document =========");
      console.error(error);
      console.log("========= Start handle Segment Document =========");
    }
  };

  const handleSubmitInfo = (data) => {
    if (!documentInAssociationData.name && !documentSelected?.name) {
      setIsEmptyDocumentLoad(true);
      return;
    }
    if (
      data.owner !== "" &&
      (documentInAssociationData.name || documentSelected?.name)
    ) {
      dispatch(setAuthor({ author: data.owner }));
      handleConfirmationAndSegment(data);
    }
  };

  //Trigger download
  const triggerDownload = async () => {
    const file = await getFileDocument(
      baseDocumentAssociation.filenameInBucket,
      baseDocumentAssociation.bucketName,
      false
    );
    const bf = Buffer.from(file.data.responseMessage.buffer.data);
    const blob = new Blob([bf]);
    const extention = baseDocumentAssociation.name
      .split(".")
      .pop()
      .toLowerCase();
    const newName =
      extention === "docx"
        ? baseDocumentAssociation.name
        : extention === "pdf"
        ? baseDocumentAssociation.name
        : `${baseDocumentAssociation.name}.docx`;
    saveAs(blob, newName);
  };

  //Adapted clauses for new Version
  const formatingSegmented = (data) => {
    let formatedClauses = [];
    for (const element of data.clauses) {
      let clause = element;
      if (clause.parts.partA === "") {
        clause.parts.partA = "Parte A";
      }
      if (clause.parts.partB === "") {
        clause.parts.partB = "Parte B";
      }
      formatedClauses.push(clause);
    }
    return {
      ...data,
      name: data?.name || data?.title,
      clauses: formatedClauses,
      represented: data?.represented,
    };
  };

  //Trigger new version
  const createNewVersion = async (data) => {
    try {
      setIsLoading(true);
      const formatSegmented = formatingSegmented(data);
      const addNewVersion = await addVersion({
        _id: data.baseDocumentAssociation || data.documentId,
        name: formatSegmented.name,
        fileType: codesDocumentType.contract._id,
        documentContent: formatSegmented,
        file: data?.fileUpload || data.documentInAssociationData,
        inReview: true,
        versionFrom: "CONTRACT",
      });

      if (
        addNewVersion.status === CODES.COD_RESPONSE_HTTP_OK &&
        addNewVersion.data.success
      ) {
        setOpenSucceedModal(true);
        setToCurrentDocument(formatSegmented);
      } else {
        setOpenFailedModal(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Modals

  //Navigate after adding new version
  const handleAgreeModalInfo = () => {
    setOpenSucceedModal(false);
    navigate(`/service/review/in-negotiation/view-document/${documentId}`);
  };

  const handleProcessContractInfo = async (
    segmentDoc,
    owner,
    fileUpload,
    documentId,
    baseDocumentAssociation,
    documentInAssociationData,
    represented
  ) => {
    try {
      setIsLoading(true);
      if (
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        segmentDoc.data.success
      ) {
        await createNewVersion({
          ...segmentDoc.data.responseMessage,
          clauses: segmentDoc.data.responseMessage.clauses,
          author: owner,
          fileUpload,
          documentInAssociationData,
          documentId,
          baseDocumentAssociation,
          represented,
        });
      } else {
        Mixpanel.track("Msj error segmentando este documento ", {
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
        });
        setOpenFailedSegModal(true);
        setResponseData(segmentDoc);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Use effects for background

  const handleMessageBackgroundContract = useCallback(async (event) => {
    const segmentDoc = event.data;
    const idProcess = event.data.idProcess;
    setIsLoadingInBackground(false);
    if (segmentDoc.data.success) {
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReviewSavedContract",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: true,
            navigationInfo: {
              url: location.pathname,
              otherInfo: {
                finishProcess: true,
                segmentDoc,
                ...listInfoContracSavedtBackgroundRef.current?.[idProcess]
                  ?.navigationInfo?.otherInfo,
              },
              state: {
                processId: idProcess,
              },
            },
          },
        })
      );

      const userLeft =
        listInfoContracSavedtBackgroundRef.current?.[idProcess]?.userLeft;

      if (!userLeft) {
        setIsLoadingInBackground(false);
        const idFileIDB =
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.idFileUpload;
        const idDocumentIDB =
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.idDocumentAssociated;
        const fileUpload = await getFileFromIndexedDB(idFileIDB);
        const documentInAssociationData = await getFileFromIndexedDB(
          idDocumentIDB
        );

        await handleProcessContractInfo(
          segmentDoc,
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.owner || {},
          fileUpload,
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.documentId,
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.baseDocumentAssociation,
          documentInAssociationData,
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.data?.represented
        );

        dispatch(
          updateListBackgroundProcesses({
            type: "DELETE",
            module: "backgroundProcessReviewSavedContract",
            id: idProcess,
          })
        );
      }

      const itemsRef = Object.values(
        listInfoContracSavedtBackgroundRef.current
      );

      if (
        (segmentDoc.status === CODES.COD_RESPONSE_HTTP_OK ||
          segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED) &&
        !itemsRef.length
      ) {
        reviewWorkerRefSavedContract.current.removeEventListener(
          "message",
          handleMessageBackgroundContract
        );
        reviewWorkerRefSavedContract.current.terminate();
      }
    } else {
      const userLeft =
        listInfoContracSavedtBackgroundRef.current?.[idProcess]?.userLeft;
      if (!userLeft) {
        setIsLoadingInBackground(false);
        setResponseData({
          status: 500,
          data: { responseMessage: "Error segmentando el documento" },
        });
        setOpenFailedSegModal(true);

        Mixpanel.track("Msj error segmentando este documento ", {
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
        });
      }
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReviewSavedContract",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: false,
            isErrorProcessing: true,
            navigationInfo: {},
          },
        })
      );
    }
  }, []);
  const handleMessageRefSavedContract = useRef(handleMessageBackgroundContract);

  useEffect(() => {
    if (!reviewWorkerRefSavedContract.current) {
      reviewWorkerRefSavedContract.current = new WorkerBuilder(WorkerContract);
    }
    reviewWorkerRefSavedContract.current.removeEventListener(
      "message",
      handleMessageRefSavedContract.current
    );

    reviewWorkerRefSavedContract.current.addEventListener(
      "message",
      handleMessageBackgroundContract
    );
  }, [handleMessageBackgroundContract]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (
        listInfoContracSavedtBackgroundRef.current?.[idProcess]?.navigationInfo
          ?.otherInfo?.finishProcess
      ) {
        setIsLoading(true);
        const idFileUpload =
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.idFileUpload;
        const idDocumentAssociated =
          listInfoContracSavedtBackgroundRef.current?.[idProcess]
            ?.navigationInfo?.otherInfo?.idDocumentAssociated;
        const fileUpload = await getFileFromIndexedDB(idFileUpload);
        const documentInAssociationData = await getFileFromIndexedDB(
          idDocumentAssociated
        );
        try {
          await handleProcessContractInfo(
            listInfoContracSavedtBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.segmentDoc,
            listInfoContracSavedtBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.owner || {},
            fileUpload,
            listInfoContracSavedtBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.documentId,
            listInfoContracSavedtBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.baseDocumentAssociation,
            documentInAssociationData,
            listInfoContracSavedtBackgroundRef.current?.[idProcess]
              ?.navigationInfo?.otherInfo?.data?.represented
          );

          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReviewSavedContract",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoContracSavedtBackgroundRef, locationState]);

  return (
    <Container
      fluid
      style={{ height: "100%" }}
      className="custom-accordion__container mb-3"
    >
      {/**Loading page */}
      {isLoading && !isLoadingInBackground && <LoadingContent />}
      {isLoadingInBackground && !isLoading && (
        <LoadingContentInBackground
          actions={allowBackgroundOptions}
          open={isLoadingInBackground}
          setIsOpen={setIsLoadingInBackground}
          moduleName={"backgroundProcessReviewSavedContract"}
          processId={idProcess}
        />
      )}
      <Row style={{ height: "7%" }} className="col-start">
        <Breadcrum />
      </Row>
      <Row style={{ height: "10%" }} className="col-start">
        <Col className="col-start" md={3}>
          <SectionTitle title={"Asociar a documento en revisión"} />
        </Col>
      </Row>
      <Row>
        <Col xs={"auto"} className="crud-titles">
          Carpeta asociada
        </Col>
        <Col xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
          <span className="caption custom-badges__disabled">
            {`${
              baseDocumentAssociation?.businessFolderId?.name ||
              "Carpeta asociada"
            } `}
          </span>
        </Col>
      </Row>
      <br />
      <Row className="crud-titles">Documento asociado</Row>
      <Row style={{ paddingLeft: "1%", paddingTop: "0.5%" }}>
        <CardDocumentInfo
          name={baseDocumentAssociation.name}
          documentName={baseDocumentAssociation.documentName}
          handleDownload={triggerDownload}
        />
      </Row>
      <br />
      <Row>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<SyncAltOutlinedIcon />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              setOpenModalVersionChangeDocument(true);
            }}
          >
            Cambiar documento asociado
          </Button>
        </Col>
      </Row>
      <br />
      <Row style={{ height: "14%" }}>
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ height: "40%" }} className="crud-titles">
            <Col className="crud-titles" md={"auto"}>
              Cargue un documento para su revisión en formato Word o PDF
            </Col>
            <Col className="col-start" md={"auto"}>
              <Col xs={"auto"}>
                <PopoverInfo
                  section={{
                    description:
                      "Te recordamos que la revisión de los documentos funcionará mejor si tienen una estructura de contratos.",
                  }}
                />
              </Col>
            </Col>
          </Row>
          <Row className="col-start" style={{ height: "50%" }}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<FolderOutlinedIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setOpenModalVersion(true);
                }}
              >
                Seleccionar documento de Legal AI
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                className="custom-input__button__secondary-color"
                onClick={() => {
                  setOpenUploadModal(true);
                }}
              >
                Cargar documento
              </Button>
            </Col>
            {documentInAssociationData.name && (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="caption custom-badges__disabled">
                  {`${documentInAssociationData.name} `}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() => {
                      setDocumentInAssociationData({});
                    }}
                  />
                </span>
              </Col>
            )}
            {documentSelected?.name && (
              <Col
                xs={"auto"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="caption custom-badges__disabled">
                  {`${documentSelected.name} `}
                  <ClearIcon
                    className="custom-badges__delete-button"
                    onClick={() => {
                      setDocumentSelected({});
                    }}
                  />
                </span>
              </Col>
            )}
          </Row>
        </Container>
      </Row>
      <Row style={{ height: "15%" }}>
        <Form onSubmit={handleSubmit(handleSubmitInfo)}>
          <Row className="heading mb-2" style={{ alignItems: "center" }}>
            <Col xs={12} className="crud-titles">
              ¿Qué rol contractual representas?
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group>
                {isLoadingFetchParts ? (
                  <CustomToolTip
                    direction="right"
                    message={
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <Spinner
                            animation="border"
                            className="spinner__iaColor"
                          />
                        </Col>

                        <Col xs="auto">
                          <p className="caption p-2 gradient-text">
                            Identificando roles contractuales con nuestra IA
                          </p>
                        </Col>
                      </Row>
                    }
                  >
                    <Form.Select
                      id={"field-documentType-legal"}
                      {...register("represented", {
                        required: {
                          value: true,
                          message: "*Este campo es obligatorio",
                        },
                      })}
                      disabled
                      className="label"
                      defaultValue={""}
                    >
                      <option value="" disabled defaultValue className="label">
                        Rol contractual
                      </option>
                    </Form.Select>
                  </CustomToolTip>
                ) : (
                  <Form.Select
                    id={"field-documentType-legal"}
                    {...register("represented", {
                      required: {
                        value: true,
                        message: "*Este campo es obligatorio",
                      },
                    })}
                    className="label"
                    defaultValue={""}
                    onChange={(e) => {
                      setValue("represented", e.target.value);
                      setValue("otherRepresented", "");
                    }}
                  >
                    <option value="" disabled defaultValue className="label">
                      Rol contractual
                    </option>

                    {documentPartList.map((part, index) => {
                      return (
                        <option
                          value={part}
                          className="label"
                          key={`${part}${index}`}
                        >
                          {part}
                        </option>
                      );
                    })}
                    <option value="OTRO" className="label">
                      Otro
                    </option>
                  </Form.Select>
                )}
                <div className="caption custom-input__error">
                  {errors?.documentType?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            {watch("represented") === "OTRO" && (
              <Col md={4}>
                <Form.Group>
                  <Form.Control
                    {...register("otherRepresented", {
                      required: {
                        value: true,
                        message: "*Este campo es obligatorio",
                      },
                    })}
                    placeholder={"Rol contractual"}
                    style={{
                      border: "0.1rem solid #c5c7d0",
                    }}
                    bsPrefix={
                      errors?.otherRepresented
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors?.otherRepresented?.message}
                  </div>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row style={{ height: "40%" }}>
            <Col className="crud-titles" md={"auto"}>
              ¿De quién proviene está versión?
            </Col>
            <Col className="col-start" md={"auto"}>
              <Col xs={"auto"}>
                <PopoverInfo
                  section={{
                    description:
                      "Con este nombre aparecerá en un futuro cuál es el origen de ​la versión.",
                  }}
                />
              </Col>
            </Col>
          </Row>
          <Row style={{ height: "40%" }}>
            <Col md={5}>
              <Form.Group className="mb-5">
                <Form.Control
                  maxLength={251}
                  {...register("owner")}
                  placeholder="Transportador - Área Jurídica - Camila Pérez"
                  bsPrefix={
                    errors.owner
                      ? "input-group-container__no-icon-error label"
                      : "input-group-container__no-icon label"
                  }
                />
                <div
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.owner?.message}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ height: "10%" }} className="col-start">
            <Col>
              <Button
                className={
                  watch("owner") === "" ||
                  watch("represented") === "" ||
                  (watch("represented") === "OTRO" &&
                    watch("otherRepresented") === "") ||
                  (!documentInAssociationData.name && !documentSelected.name)
                    ? "button-primary-folders__disabled"
                    : "button-primary-folders__select"
                }
                disabled={
                  watch("owner") === "" ||
                  (!documentInAssociationData.name && !documentSelected.name) ||
                  watch("represented") === "" ||
                  (watch("represented") === "OTRO" &&
                    watch("otherRepresented") === "")
                }
                endIcon={<ArrowForwardIcon fontSize="large" />}
                type="submit"
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalUploadDocs
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF o Word."
        }
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => {
          setOpenUploadModal(false);
        }}
        onClose={() => {
          setOpenUploadModal(false);
        }}
        open={openUploadModal}
        documentData={documentInAssociationData}
        setDocumentData={(file) => {
          if (!file?.name && !file?.filename) return;
          setValue("represented", "");
          handleProccesDocumentPartsExtraction({ file });
          setDocumentInAssociationData(file);
          setDocumentSelected({});
        }}
      />

      <ModalInfo
        title={"Documento guardado satisfactoriamente"}
        responseData={{
          status: CODES.COD_RESPONSE_HTTP_OK,
          data: {
            responseMessage:
              "Tu documento ha sido guardado en la misma ubicación del documento original.",
          },
        }}
        open={openSucceedModal}
        onClose={() => {
          handleAgreeModalInfo();
        }}
      />

      <ModalInfo
        title={"Ha ocurrido un error modificando la versión"}
        responseData={{
          status: CODES.COD_RESPONSE_HTTP_ERROR,
          data: {
            responseMessage:
              "El documento no pudo ser asociado. Verifique el contenido del documento.",
          },
        }}
        open={openFailedModal}
        onClose={() => {
          setOpenFailedModal(false);
        }}
      />
      <ModalInfo
        title={"Ha ocurrido un error procesando el nuevo documento"}
        responseData={responseData}
        open={openFailedSegModal}
        onClose={() => {
          setOpenFailedSegModal(false);
        }}
      />

      <ModalWarningForm
        title={"Advertencia"}
        description={"No se ha cargado un documento para hacer una revisón"}
        open={isEmptyDocumentLoad}
        onClose={() => setIsEmptyDocumentLoad(false)}
      />

      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file) => {
          setOpenModalVersion(false);

          setDocumentSelected(file);
          setDocumentInAssociationData({});
          handleProccesDocumentPartsExtraction({
            filenameInBucket: file.filenameInBucket,
            bucketName: file.bucketName,
          });
        }}
        onClose={() => {
          setOpenModalVersion(false);
        }}
        open={openModalVersion}
        folders={folders}
        versionSelected={documentSelected}
        title="Asociar documento"
      />

      <ModalAssociateVersion
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={(file, folder) => {
          setOpenModalVersionChangeDocument(false);
          const updated = dateFormat(file.updatedAt, "paddedShortDate");
          const text = `Última actualización: ${updated}`;
          setBaseDocumentAssociation({
            ...file,
            documentName: text,
            businessFolderId: folder,
          });
        }}
        onClose={() => {
          setOpenModalVersionChangeDocument(false);
        }}
        open={openModalVersionChangeDocument}
        folders={folders}
        versionSelected={baseDocumentAssociation}
        title="Cambiar documento"
        filterTypeDocument={[codesDocumentType.contract._id]}
      />
    </Container>
  );
};

export default ReviewUploadDocument;
