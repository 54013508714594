import React, { useState, useEffect } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
//Material Import
import { Button } from "@mui/material";
import { MDBDataTableV5 } from "mdbreact";
//Parts
import Breadcrum from "../../../../../parts/breadcrum";
//Components
import LoadingContent from "../../../../../components/loadingContent";
import SectionTitle from "../../../../../components/sectionTitle";
import CustomSearch from "../../../../../components/Datatable/customSearch";
import DiscussionBoard from "../../../../../components/DiscussionBoard/DiscussionBoard";

import ModalDecision from "../../../../../components/Modals/modalDecision";
//Icons
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
  setUnitCorporativeAllowedStatus,
  setUnitCorporativeStatus,
} from "./unitCorporativeSlice";
//Services
//Utils

const ButtonPart = ({ handleClick }) => (
  <Row>
    <Col xs={"auto"}></Col>
  </Row>
);

const UnitsCorporatives = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      label: [
        <label
          key="1"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Nombre de la unidad corporativa
        </label>,
      ],
      field: "name",
    },
    {
      label: [
        <label
          key="2"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Descripción
        </label>,
      ],
      field: "description",
    },
    {
      label: [
        <label
          key="3"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Partes
        </label>,
      ],
      field: "parts",
    },
    {
      label: [
        <label
          key="4"
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
        >
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const statusUnitsCorporativesList = useSelector(getUnitsCorporativesStatus);
  const unitsCorporativesList = useSelector(getUnitsCorporativesList);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [isHideDiscussion, setIsHideDiscussion] = useState(true);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const buildRows = (rows) => {
    if (rows.length < 0) {
      return [];
    }
    const result = rows.map((row) => {
      const parts = row?.relatedParts?.length ? (
        <Button
          type="button"
          variant="contained"
          className="custom-input__button__secondary-color"
          onClick={() =>
            navigate(`../library?filterBy=CORPORATE_UNIT&id=${row?._id}`)
          }
          sx={{
            whiteSpace: "nowrap",
            width: "fit-content",
          }}
        >
          Ver partes
        </Button>
      ) : (
        "Sin partes asociadas."
      );
      return {
        name: row.name,
        description: row.description,
        parts,
        actions: (
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color__forced custom-input__data-table-icon"
              onClick={() => {
                handleUpdateUnitCorporative(row._id);
              }}
            >
              <EditOutlinedIcon fontSize="large" />
            </Button>
            <Button
              variant="contained"
              className="custom-input__button__primary-color__forced custom-input__data-table-icon"
              // onClick={() => setIsOpenModalDecision(true)}
              onClick={() => {
                setIsOpenModalDecision(true);
              }}
            >
              <DeleteOutlineOutlinedIcon fontSize="large" />
            </Button>
          </Stack>
        ),
      };
    });
    return result;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusUnitsCorporativesList === "fetch") {
          dispatch(fetchUnitsCoporativesList());
        }
      } catch (error) {
        console.log("========Fetch Units Corporatives Error========");
        console.log(error);
        console.log("========Finish Fetch Units Corporatives Error========");
      }
    };
    fetchData();
  }, [statusUnitsCorporativesList, dispatch]);
  useEffect(() => {
    if (statusUnitsCorporativesList === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusUnitsCorporativesList]);
  useEffect(() => {
    const rows = buildRows(unitsCorporativesList);
    setDataTable({
      columns: columns,
      rows: rows,
    });
    setFilteredDataTable({
      columns: columns,
      rows: rows,
    });
  }, [unitsCorporativesList]);
  const handleCloseModalDecision = () => {
    setIsOpenModalDecision(false);
  };
  const handleAgreeModalDecision = () => {
    setIsOpenModalDecision(false);
    navigate("../help");
  };
  const handleDisagreeModalDecision = () => {
    setIsOpenModalDecision(false);
  };
  const handleUpdateUnitCorporative = (id) => {
    dispatch(setUnitCorporativeStatus());
    dispatch(setUnitCorporativeAllowedStatus());
    navigate(`edit/${id}`);
  };
  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row>
        <Col xs={"auto"}>
          <SectionTitle title="Unidades corporativas" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={"auto"} className="caption">
          Gestiona las unidades corporativas de la base de datos.
        </Col>
      </Row>
      <br />
      <Row className="justify-content-between align-items-center g-3">
        <Col xs="12" md="6">
          <CustomSearch
            placeholder="Buscar unidad corporativa"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
        <Col xs="auto">
          <Stack direction="horizontal" gap={3} className="flex-wrap">
            <Button
              variant="contained"
              startIcon={<SettingsIcon />}
              className="custom-input__button__secondary-color"
              onClick={() => navigate("personalize")}
              sx={{
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              Personalizar
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("rules-review")}
              sx={{
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              Reglas de revisión
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className="custom-input__button__primary-color"
              onClick={() => navigate("create")}
              sx={{
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              Crear unidad corporativa
            </Button>
          </Stack>
        </Col>
      </Row>
      <br />
      <Row className="custom-datatable__container data-table dt-list-items-change-last-child">
        <MDBDataTableV5
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={filteredDataTable}
          infoLabel={["Mostrando", "a", "de", "unidades corporativas"]}
          fullPagination
        />
      </Row>

      <ModalDecision
        title={
          <Row
            className="heading__warning-color"
            style={{ alignItems: "center" }}
          >
            <Col xs={"auto"}>
              <InfoOutlinedIcon fontSize="large" />
            </Col>
            <Col>Eliminar unidad corporativa</Col>
          </Row>
        }
        message={
          "Si quiere  eliminar una Unidad Corporativa creada, deberá contactarse con soporte técnico."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDecision}
        agreeText="Contactar con soporte"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDecision}
        handleDisagree={handleDisagreeModalDecision}
      />
      <DiscussionBoard
        isHideDiscussion={isHideDiscussion}
        setIsHideDiscussion={setIsHideDiscussion}
        comments={[]}
        id={""}
        addComment={false}
      />
    </Container>
  );
};

export default UnitsCorporatives;
//.._. ._ _... . ._.
