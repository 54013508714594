import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { TypeInputMenuButton } from "../../../components/TypeInputMenuButton";
import TypeInputFactory from "../../../../../../../../../../../../components/InputForms/factories/TypeInputFactory";
import ManageCustomFormModes from "../../../constants/manageCustomFormModes";
import { Controller, useForm } from "react-hook-form";
import { Button, IconButton, TextField } from "@mui/material";
import {
  Check,
  Close,
  CreateOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { CustomToolTip } from "../../../../../../../../../../../../components/Tooltip/Tooltip";
import ModalDecision from "./../../../../../../../../../../../../components/Modals/modalDecision";
import SectionSchema from "../schemas/sectionSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import SectionFormInputs from "../constants/sectionFormInputs";

// TYPE DEFINITIONS BEGIN

/**
 * Defines the structure of a section.
 *
 * @typedef {Object} SectionType
 * @property {string} name The name of the section.
 * @property {Array<QuestionType>} fields The questions of the section.
 */

/**
 * Defines the structure of a question.
 *
 * @typedef {Object} QuestionType
 *
 * @property {string} type The type of the question.
 * @property {string} value The value of the question.
 * @property {string} [label] The label of the question.
 * @property {Array<string>} [options] The options of the question.
 * @property {ValidationType} [validations] The validations of the question.
 */

/**
 * Defines the structure of a validation.
 *
 * @typedef {Object} ValidationType
 * @property {ValidationPropsType} [required] The required validation.
 * @property {ValidationPropsType} [minLength] The min length validation.
 * @property {ValidationPropsType} [maxLength] The max length validation.
 * @property {ValidationPropsType} [pattern] The pattern validation.
 */

/**
 * Defines the props of a validation.
 *
 * @typedef {Object} ValidationPropsType
 * @property {string} message The message of the validation.
 * @property {any} value The value of the validation.
 */

/**
 * The modes of the Section component.
 *
 * @typedef {"create" | "edit"} SectionModesType
 */

// TYPE DEFINITIONS END

/**
 * Logic for the Section component.
 */
const SectionLogic = Object.freeze({
  /**
   * The initial states of the Section component.
   */
  InitialStates: {
    isEditing: false,
    modalDecisionInfoProps: {
      open: false,
      type: undefined,
      title: undefined,
      message: undefined,
      agreeText: undefined,
      agreeIcon: undefined,
      agreeIconStart: undefined,
      disagreeText: undefined,
      disagreeIcon: undefined,
      disagreeIconStart: undefined,
      handleAgree: () => undefined,
      handleDisagree: () => undefined,
      onClose: () => undefined,
    },
  },
});

/**
 * Section component, this is a section of a form.
 *
 * @param {Object} props
 * @param {SectionType} props.section The section to render.
 * @param {number} props.sectionIndex The index of the section.
 * @param {(sectionIndex: number, fieldIndex: number, question: QuestionType) => void} props.onUpdateQuestion The function to update a question.
 * @param {(sectionIndex: number, fieldIndex: number) => void} props.onDeleteQuestion The function to delete a question.
 * @param {(sectionIndex: number, question: QuestionType) => void} props.onAddQuestion The function to add a question.
 * @param {SectionModesType} props.mode The mode of the section.
 * @param {boolean} props.isSubmittingExternally If the form is submitting externally.
 * @param {(sectionIndex: number, newSectionName: string) => void} props.saveFormSectionInfo Function to call when the form is submitted.
 * @param {(sectionIndex: number) => void} props.onDeleteSection Function to delete a section.
 * @returns The rendered section component.
 */
const Section = ({
  section,
  sectionIndex,
  onUpdateQuestion,
  onDeleteQuestion,
  onAddQuestion,
  mode,
  isSubmittingExternally,
  saveFormSectionInfo,
  onDeleteSection,
}) => {
  // Constants
  const sectionSchemaDefault = SectionSchema.getDefault();

  // States
  const [isEditing, setIsEditing] = useState(
    SectionLogic.InitialStates.isEditing
  );
  const [modalDecisionInfoProps, setModalDecisionInfoProps] = useState(
    SectionLogic.InitialStates.modalDecisionInfoProps
  );

  // Hooks
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(SectionSchema),
    defaultValues: sectionSchemaDefault,
    mode: "all",
  });

  /**
   * Toggles the editing mode.
   */
  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };

  /**
   * Opens the delete section warning modal.
   */
  const openDeleteSectionWarningModal = () => {
    setModalDecisionInfoProps({
      open: true,
      type: "warning",
      title: "Eliminar sección",
      message:
        "¿Seguro deseas eliminar esta sección? Todas las preguntas serán eliminadas.",
      agreeText: "Aceptar",
      agreeIconStart: <Check />,
      disagreeText: "Cancelar",
      disagreeIconStart: <Close />,
      handleAgree: () => {
        onDeleteSection(sectionIndex);
        closeModalDecisionInfo();
      },
      handleDisagree: closeModalDecisionInfo,
      onClose: closeModalDecisionInfo,
    });
  };

  /**
   * Closes the decision info modal.
   */
  const closeModalDecisionInfo = () => {
    setModalDecisionInfoProps(
      SectionLogic.InitialStates.modalDecisionInfoProps
    );
  };

  useEffect(() => {
    if (section) {
      setValue(
        SectionFormInputs.Name.InputName,
        section.name ?? sectionSchemaDefault.sectionName
      );
    }
  }, [section]);

  useEffect(() => {
    if (isSubmittingExternally) {
      trigger();
      saveFormSectionInfo(
        sectionIndex,
        getValues(SectionFormInputs.Name.InputName)
      );
    }
  }, [isSubmittingExternally]);

  return (
    <Container fluid className="d-flex flex-column gap-3">
      <Row>
        <Col xs="12">
          {/* Section Name */}
          <Row
            className={`align-items-center ${
              isEditing ? "py-5 rounded-4" : ""
            }`}
            style={{ backgroundColor: isEditing ? "#F4F6FA" : "" }}
          >
            <Col>
              <Row className="align-items-center">
                <Col xs="auto">
                  {/* Toggle edit button */}
                  <EditIconButton
                    onClick={toggleIsEditing}
                  />
                </Col>
                {isEditing ? (
                  <Col>
                    <Row className="g-4">
                      <Col className="flex-grow-1">
                        <Controller
                          control={control}
                          name={SectionFormInputs.Name.InputName}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              variant="standard"
                              type="text"
                              autoFocus
                              sx={{
                                "& .MuiInputBase-root": { fontSize: "" },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "12px",
                                },
                              }}
                              error={!!errors[SectionFormInputs.Name.InputName]}
                              helperText={
                                errors[SectionFormInputs.Name.InputName]
                                  ?.message
                              }
                              className="flex-grow-0"
                            />
                          )}
                        />
                      </Col>
                      <Col xs="12" md="auto">
                        <Button
                          variant="contained"
                          startIcon={<DeleteOutline />}
                          className="custom-input__button__primary-color flex-md-grow-0"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                          onClick={openDeleteSectionWarningModal}
                        >
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col>
                    <p className="heading__default">
                      {getValues(SectionFormInputs.Name.InputName)}
                    </p>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {/* Section fields */}
          <Row>
            {section.fields.map((field, fieldIndex) => (
              <TypeInputFactory
                key={field._id}
                type={field.type}
                inputProps={{
                  question: field,
                  onDataChange: (question) =>
                    onUpdateQuestion(sectionIndex, fieldIndex, question),
                  onDelete: () => onDeleteQuestion(sectionIndex, fieldIndex),
                }}
              />
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <TypeInputMenuButton
            handleAddQuestion={(question) =>
              onAddQuestion(sectionIndex, question)
            }
            btnMessage={
              mode === ManageCustomFormModes.CREATE
                ? "Agregar pregunta a la sección"
                : undefined
            }
          />
        </Col>
      </Row>
      <ModalDecision {...modalDecisionInfoProps} />
    </Container>
  );
};

Section.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        validations: PropTypes.shape({
          required: PropTypes.shape({
            message: PropTypes.string,
            value: PropTypes.boolean,
          }),
          minLength: PropTypes.shape({
            message: PropTypes.string,
            value: PropTypes.number,
          }),
          maxLength: PropTypes.shape({
            message: PropTypes.string,
            value: PropTypes.number,
          }),
          pattern: PropTypes.shape({
            message: PropTypes.string,
            value: PropTypes.string,
          }),
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  onUpdateQuestion: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(ManageCustomFormModes)).isRequired,
  isSubmittingExternally: PropTypes.bool.isRequired,
  saveFormSectionInfo: PropTypes.func.isRequired,
  onDeleteSection: PropTypes.func.isRequired,
};

/**
 * Edit icon button.
 *
 * @function
 * @param {Object} props The props of the component.
 * @param {() => void} props.onClick The function to call when the button is clicked.
 */
const EditIconButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <CreateOutlined fontSize="large" className="heading__primary-color" />
    </IconButton>
  );
};

EditIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Section;
