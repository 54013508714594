/**
 * The types of the questions.
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @readonly
 * @enum {string}
 */
export const QuestionFormTypes = Object.freeze({
  INPUT: "input",
  INPUT_CHECKBOX: "input-checkbox",
  LIST: "list",
  LIST_INPUT: "list-input",
  LIST_CHECKBOX: "list-checkbox-multiple",
  LIST_INPUT_CHECKBOX: "list-input-checkbox",
  DATE: "date",
  DATE_CHECKBOX: "date-checkbox",
  // No se usa pero para evitar errores
  LIST_CHECKBOX_NO_USE: "list-checkbox",
});
