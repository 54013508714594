import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import TypeInputFormSchema, {
  TypeInputFormSchemaPropertiesName,
} from "./schemas/typeInputFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Button, FormControlLabel, IconButton, TextField } from "@mui/material";
import CreateOutlined from "@mui/icons-material/CreateOutlined";
import { IOSSwitch } from "../switch/switch";
import { DeleteOutlined } from "@mui/icons-material";
import ModalDecision from "../Modals/modalDecision";
import { CustomToolTip } from "../Tooltip/Tooltip";

// BEGINNING OF TYPE DEFINITIONS
/**
 * The type of the modal.
 *
 * @typedef {Object} ModalInfoPropsType
 * @property {boolean} open - The open state of the modal.
 * @property {string} type - The type of the modal.
 * @property {string} title - The title of the modal.
 * @property {string} message - The message of the modal.
 * @property {string} agreeText - The text of the agree button.
 * @property {React.Component} agreeIcon - The icon of the agree button.
 * @property {React.Component} agreeIconStart - The icon start of the agree button.
 * @property {string} disagreeText - The text of the disagree button.
 * @property {React.Component} disagreeIcon - The icon of the disagree button.
 * @property {React.Component} disagreeIconStart - The icon start of the disagree button.
 * @property {() => void} handleAgree - The function to handle the agree button.
 * @property {() => void} handleDisagree - The function to handle the disagree button.
 * @property {() => void} onClose - The function to close the modal.
 */
/**
 * The type of the question.
 *
 * @typedef {Object} QuestionType
 * @property {string} _id - The id of the question.
 * @property {string} value - The value of the question.
 * @property {{ required: { value: boolean } }} validations - The validations of the question.
 */

// END OF TYPE DEFINITIONS

/**
 * The logic necessary for `BaseTypeInput`.
 *
 * @constant
 */
const BaseTypeInputLogic = Object.freeze({
  /**
   * Object with functions to initialize the props of the `BaseTypeInput` component.
   *
   * @constant
   */
  Initializer: {
    /**
     * Set the initial value of the editing state.
     *
     * @function
     * @param {React.Dispatch<React.SetStateAction<boolean>} setIsEditing - The function to set the editing state.
     */
    initializeIsEditing: (setIsEditing) => {
      setIsEditing(false);
    },
    /**
     * Set the initial values of the form.
     *
     * @function
     * @param {(name: string, value: any) => void} setFormValue - The function to set the form value.
     * @param {QuestionType} question - The question to set the initial values.
     */
    initializeFormValues: (setFormValue, question) => {
      const initialValues = {
        title: question.value,
        isRequired: question.validations.required.value,
      };
      for (const propertyName in TypeInputFormSchemaPropertiesName) {
        const propertyValue = initialValues[propertyName];
        if (propertyValue !== undefined && propertyValue !== null) {
          setFormValue(propertyName, initialValues[propertyName]);
        }
      }
    },
    /**
     * Set the initial values of the modal info props.
     *
     * @param {React.Dispatch<React.SetStateAction<ModalInfoPropsType>>} setModalInfoProps
     */
    initializeModalInfoProps: (setModalInfoProps) => {
      setModalInfoProps({
        open: false,
        type: undefined,
        title: undefined,
        message: undefined,
        agreeText: undefined,
        agreeIcon: undefined,
        agreeIconStart: undefined,
        disagreeText: undefined,
        disagreeIcon: undefined,
        disagreeIconStart: undefined,
        handleAgree: () => undefined,
        handleDisagree: () => undefined,
        onClose: () => undefined,
      });
    },
  },
  /**
   * Toggle the editing state.
   *
   * @function
   * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsEditing - The function to set the editing state.
   * @param {(isEditing: boolean) => void} [onToggleIsEditing] - The function to call when toggle the editing state, is optional.
   */
  toggleIsEditing: (setIsEditing, onToggleIsEditing) => {
    let toggleValue;
    setIsEditing((prevIsEditing) => {
      toggleValue = !prevIsEditing;
      return toggleValue;
    });
    onToggleIsEditing && onToggleIsEditing(toggleValue);
  },
  /**
   * Save the changes of the question.
   *
   * @function
   * @param {(name?: string) => TypeInputFormSchema | any} formValues - The form values.
   * @param {QuestionType} question - The question to modify.
   * @param {(question: QuestionType) => void} onDataChange - The function to save the changes.
   */
  saveChanges: (question, formValues, onDataChange) => {
    const modifiedQuestion = {
      ...question,
      value: formValues.title,
      validations: {
        required: {
          ...question.validations.required,
          value: formValues.isRequired,
        },
      },
    };
    onDataChange(modifiedQuestion);
  },
  /**
   * Open the warning delete modal.
   *
   * @function
   * @param {React.Dispatch<React.SetStateAction<ModalInfoPropsType>>} setModalInfoProps - The function to set the modal info props.
   * @param {() => void} onDelete - The function to delete the question.
   */
  openWarningDeleteModal: (setModalInfoProps, onDelete) => {
    const closeModal = () =>
      BaseTypeInputLogic.Initializer.initializeModalInfoProps(
        setModalInfoProps
      );
    setModalInfoProps({
      open: true,
      type: "warning",
      title: "Atención",
      message: "Está acción eliminará la pregunta. ¿Estás seguro de continuar?",
      agreeText: "Aceptar",
      disagreeText: "Cancelar",
      handleAgree: () => {
        onDelete();
        closeModal();
      },
      handleDisagree: closeModal,
      onClose: closeModal,
    });
  },
});

/**
 * The base input form component for the different types of questions.
 *
 * @function
 * @param {Object} props The props of the component.
 * @param {QuestionType} props.question The initial values of the question.
 * @param {(question: QuestionType) => void} props.onDataChange - The function to save the changes.
 * @param {() => void} props.onDelete The function to delete the question.
 * @param {React.ReactElement} [props.children] The children of the component, is optional.
 * @param {(isEditing: boolean) => void} [props.onToggleIsEditing] The function to call when toggle the editing state, is optional.
 * @param {boolean} [props.saveChangesExternally] The flag to save the changes externally, by default is false.
 * @returns {JSX.Element} The component.
 */
const BaseTypeInput = ({
  question,
  onDataChange,
  onDelete,
  children,
  onToggleIsEditing,
  saveChangesExternally = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  /**
   * The props of the modal.
   *
   * @type {[ModalInfoPropsType, React.Dispatch<React.SetStateAction<ModalInfoPropsType>>]}
   */
  const [modalInfoProps, setModalInfoProps] = useState();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(TypeInputFormSchema),
    defaultValues: TypeInputFormSchema.getDefault(),
    mode: "onBlur",
  });

  /** functions */

  /**
   * Save the changes of the question.
   *
   * @param {() => void} [onChange] The function to call before save the changes. Is optional.
   */
  const saveChanges = (onChange) => {
    onChange?.();
    BaseTypeInputLogic.saveChanges(question, getValues(), onDataChange);
  };

  /** Use Effects */

  useEffect(() => {
    BaseTypeInputLogic.Initializer.initializeIsEditing(setIsEditing);
    BaseTypeInputLogic.Initializer.initializeModalInfoProps(setModalInfoProps);
  }, []);

  useEffect(() => {
    BaseTypeInputLogic.Initializer.initializeFormValues(setValue, question);
  }, [question, setValue]);

  useEffect(() => {
    if (saveChangesExternally) {
      saveChanges();
    }
  }, [saveChangesExternally]);

  return (
    <Container
      className={"my-3 " + (isEditing ? "py-5 rounded-4" : "")}
      style={{ backgroundColor: isEditing ? "#F4F6FA" : "" }}
    >
      <Row className="gap-4">
        <Col xs={12} lg={5} xxl={3}>
          <Row className="align-items-center">
            <Col xs="auto">
              {/* Toggle edit button */}
              <EditIconButton
                onClick={() => {
                  BaseTypeInputLogic.toggleIsEditing(
                    setIsEditing,
                    onToggleIsEditing
                  );
                }}
              />
            </Col>
            <Col className="grow">
              {isEditing ? (
                // Title input field
                <Controller
                  control={control}
                  name={TypeInputFormSchemaPropertiesName.title}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        saveChanges(() => field.onChange(e));
                      }}
                      fullWidth
                      variant="standard"
                      type="text"
                      multiline
                      maxRows={3}
                      autoFocus
                      InputProps={{
                        endAdornment: getValues(
                          TypeInputFormSchemaPropertiesName.isRequired
                        ) && <span style={{ color: "red" }}>*</span>,
                      }}
                      sx={{
                        "& .MuiInputBase-root": { fontSize: "" },
                        "& .MuiFormHelperText-root": {
                          fontSize: "12px",
                        },
                      }}
                      error={!!errors[TypeInputFormSchemaPropertiesName.title]}
                      helperText={
                        errors[TypeInputFormSchemaPropertiesName.title]?.message
                      }
                    />
                  )}
                />
              ) : (
                <p className="label__description m-0 text-break">
                  {getValues(TypeInputFormSchemaPropertiesName.title)}
                  {getValues(TypeInputFormSchemaPropertiesName.isRequired) && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </p>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="d-flex flex-column gap-4 justify-content-center"
        >
          {children && (
            <Row>
              {/* Additional inputs content */}
              {children}
            </Row>
          )}
          {isEditing && (
            <Row>
              {/* Buttons */}
              <Stack
                direction="horizontal"
                gap={3}
                className="justify-content-end flex-wrap"
              >
                {/* isRequired check input */}
                <Controller
                  control={control}
                  name={TypeInputFormSchemaPropertiesName.isRequired}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            saveChanges(() => field.onChange(e));
                          }}
                          colorbase={"#00374F"}
                        />
                      }
                      label="Obligatoria"
                      labelPlacement="end"
                      className="caption"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    />
                  )}
                />
                {/* Delete button */}
                <Button
                  variant="contained"
                  startIcon={<DeleteOutlined />}
                  className="custom-input__button__primary-color__forced"
                  onClick={() =>
                    BaseTypeInputLogic.openWarningDeleteModal(
                      setModalInfoProps,
                      onDelete
                    )
                  }
                >
                  Eliminar
                </Button>
              </Stack>
            </Row>
          )}
        </Col>
      </Row>
      {/* Modals */}
      <ModalDecision {...modalInfoProps} />
    </Container>
  );
};

BaseTypeInput.propTypes = {
  question: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    validations: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.any,
  onToggleIsEditing: PropTypes.func,
  saveChangesExternally: PropTypes.bool,
};

export default BaseTypeInput;

/**
 * Edit icon button.
 *
 * @function
 * @param {Object} props The props of the component.
 * @param {() => void} props.onClick The function to call when the button is clicked.
 */
const EditIconButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <CreateOutlined fontSize="large" className="heading__primary-color" />
    </IconButton>
  );
};

EditIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
