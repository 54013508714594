import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../../../components/loadingContent";
import SectionTitle from "../../../components/sectionTitle";
import Breadcrum from "../../breadcrum";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalInfo from "../../../components/Modals/modalInfo";
import ModalDecision from "../../../components/Modals/modalDecision";
import { createTags } from "../../folders/folderTagSlice";
import { useDispatch } from "react-redux";
import { CODES } from "../../../utils/codes";
import { Mixpanel } from "../../../utils/mixPanel";

const FolderCreateTag = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("*Este campo es obligatorio")
      .test(
        "maxNumbers",
        "*Máximo 100 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(100);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    description: yup
      .string()
      .test(
        "maxNumbers",
        "*Máximo 200 caracteres permitidos",
        function (value) {
          if (!!value) {
            const schema = yup.string().max(200);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  //Servicio para crear tag desde folders ==> falta por cambiar
  const handleCreateTag = async (data) => {
    try {
      setIsLoading(true);
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      const companyId = localStorage.getItem("company");
      let obj = {
        name: data.name,
        description: data.description,
        company: companyId,
        resourceType: "BUSINESSFOLDER",
      };
      const createTagService = await dispatch(createTags(obj)).unwrap();
      if (
        createTagService.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        createTagService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        Mixpanel.track("Btn guardar", {
          homeButton: "Carpetas - Agrega etiquetas",
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
          state: createTagService.status,
        });
      }
      setResponseData(createTagService);
      setIsLoading(false);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Crear Etiqueta"} />
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleCreateTag)}>
          <Form.Group as={Col} xs={{ span: 5, offset: 0 }} className="mb-5">
            <Form.Label className="form__label">
              Nombre de la etiqueta <span style={{ color: "red" }}>{"*"}</span>
            </Form.Label>
            <Form.Control
              {...register("name")}
              bsPrefix={
                errors.name
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={101}
              placeholder={"Nombre de la etiqueta"}
            />
            <div className="caption custom-input__error">
              {errors.name?.message}
            </div>
          </Form.Group>
          <Form.Group as={Col} className="mb-5" xs={{ span: 8, offset: 0 }}>
            <Form.Label className="form__label">
              Descripción de la etiqueta
            </Form.Label>
            <Form.Control
              {...register("description")}
              as="textarea"
              bsPrefix={
                errors.description
                  ? "input-group-container__no-icon-error label"
                  : "input-group-container__no-icon label"
              }
              maxLength={201}
              placeholder="Descripción de la etiqueta"
            />
            <div className="caption custom-input__error">
              {errors.description?.message}
            </div>
          </Form.Group>
          <Row xs={"auto"}>
            <Col>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                className="custom-input__button__primary-color"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Estado de los Cambios"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />
    </Container>
  );
};

export default FolderCreateTag;
