import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { CODES } from "../../utils/codes";
const ModalInfo = ({
  title,
  responseData,
  onClose,
  open,
  confirmationText = "Aceptar",
  buttonClose = false,
  handleCloseDefault,
  AdditionalFields = null,
  additionalFieldsProps = {},
}) => {
  const { data, status } = responseData;

  const handleClose = () => {
    onClose();
  };

  let titleColor = "";

  if (
    status === CODES.COD_RESPONSE_HTTP_OK ||
    status === CODES.COD_RESPONSE_HTTP_CREATED
  ) {
    titleColor = "success";
  } else if (
    status === CODES.COD_RESPONSE_HTTP_ERROR ||
    status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED
  ) {
    titleColor = "error";
  } else {
    titleColor = "warning";
  }

  const classNameTitle = `heading__${titleColor}-color`;

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {buttonClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      <Dialog
        // maxWidth="xl"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleCloseDefault}>
          <label className={classNameTitle}>
            {(titleColor === "success" || titleColor === "warning") && (
              <InfoOutlinedIcon fontSize="large" />
            )}

            {titleColor === "error" && (
              <WarningAmberOutlinedIcon fontSize="large" />
            )}

            {" " + title}
          </label>
        </DialogTitleContainer>
        <DialogContent className="caption" dividers>
          {data?.responseMessage?.message ||
            data?.responseMessage ||
            data?.message}

          {AdditionalFields && <AdditionalFields {...additionalFieldsProps} />}
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleClose}
            >
              {confirmationText}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalInfo;
