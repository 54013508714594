import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate, useLocation } from "react-router-dom";
import { getCurrentDocument, getReports } from "../../../../../../../parts/document/currentDocumentSlice";
import { useSelector } from "react-redux";
import LoadingContent from "../../../../../../../components/loadingContent";
import { MDBDataTableV5 } from "mdbreact";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { CODES } from "../../../../../../../utils/codes";
import { REPORTS_TYPE } from "../../../../../../../utils/codesReportsTypes";
import { reportIAResult } from "../../..../../../../../../../services/admin/manageUserService";
import ModalInpuText from "../../../../../../../components/Modals/modalInpuText";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import * as XLSX from "xlsx";
import moment from "moment";

const columns = [
  {
    label: [
      <label
        key="1"
        className="default-text--xs text-color--dt-column"
        aria-hidden="true"
      >
        Deudor
      </label>,
    ],
    field: "responsible",
  },
  {
    label: [
      <label
        key="2"
        className="default-text--xs text-color--dt-column"
        aria-hidden="true"
      >
        Obligación
      </label>,
    ],
    field: "obligation",
  },
  {
    label: [
      <label
        key="3"
        className="default-text--xs text-color--dt-column"
        aria-hidden="true"
      >
        Plazo
      </label>,
    ],
    field: "deadline",
  },
  {
    label: "",
    field: "action",
  },
];

export const ReportsObligations = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataReports = useSelector(getReports);
  const currentDocument = useSelector(getCurrentDocument);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorFilterBy, setAnchorFilterBy] = useState(null);
  const [filterSelected, setFilterSelected] = useState(null);
  const [filteredData, setFilteredData] = useState({
    columns: columns,
    rows: [],
  });
  const [itemsFilter, setItemsFilter] = useState([]);
  const [isOpenModalInputReport, setIsOpenModalInputReport] = useState(false);
  const [itemReport, setItemReport] = useState(null);
  const openFilterBy = Boolean(anchorFilterBy);
  const handleDeleteFilter = () => {
    setFilterSelected(null);
    setFilteredData(data);
  };
  const handleMenuItemClickFilterBy = (option) => {
    setAnchorFilterBy(null);
    setFilterSelected(option);
    const rowsFilter = data.rows.filter(
      (item) => item.responsible === option.title
    );
    setFilteredData({
      columns,
      rows: rowsFilter,
    });
  };
  const handleInfoToSupport = async (reportMessage) => {
    let isInfoSentToSupport = false;
    try {
      setIsLoading(true);
      const reportInformation = {
        message: reportMessage,
        functionalityName: `${REPORTS_TYPE.OBLIGATIONS.name}`,
        currentPathname: location.pathname,
      };
      const serviceResponse = await reportIAResult(reportInformation);
      if (serviceResponse) {
        if (serviceResponse.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          isInfoSentToSupport = true;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return isInfoSentToSupport;
  };
  const handleAgreeModalInputReport = async (data) => {
    if (data === "") return;
    if (itemReport) {
      const isInfoSentToSupport = await handleInfoToSupport(data);
      setIsOpenModalInputReport(!isInfoSentToSupport);
    }
  };
  const buildRows = (data) => {
    const rows = [];
    for (const report of data) {
      if (report?.category !== 1 && report?.category !== 2) return;
      rows.push({
        responsible: report?.responsible,
        obligation: report?.content,
        deadline: report?.duration,
        action: (
          <Row style={{ gap: 3 }}>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                startIcon={<RemoveRedEyeOutlinedIcon />}
                className="custom-input__button__primary-color"
                onClick={() => navigate(`viewContract?type=obligations#${report.id_text}.0.0`, { state: { obligation: report }})}
              >
                Ver en contrato
              </Button>
            </Col>
          </Row>
        ),
      });
    }
    return rows;
  };

  const buildListPersonFilter = (data) => {
    const list = [];
    const uniqueTitles = new Set();

    for (const [index, item] of data.entries()) {
      const title = item?.responsible;
      if (!uniqueTitles.has(title)) {
        list.push({
          title: title,
          selectionClick: index,
        });
        uniqueTitles.add(title);
      }
    }

    return list;
  };

  const buildCsvReport = (data) => {
    try {
      setIsLoading(true);

      const dataRefactor = data.find((item) => item.typeReport === type).reports;

      const textData = dataRefactor.map(data => [data.responsible, data.content, data.duration]);
      
      const csv = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet([
        ["Deudor", "Obligación", "Plazo"],
        ...textData
      ]);
      XLSX.utils.book_append_sheet(csv, worksheet, "reporte_obligaciones");
      XLSX.writeFile(csv, `${moment().format("DDMMyyyy")} Informe de obligaciones ${currentDocument?.name || ""}.xlsx`);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const reportsData = [
      ...dataReports.find((item) => item.typeReport === type).reports,
    ];
    const persons = buildListPersonFilter(reportsData);
    const rows = buildRows(reportsData);
    setData({ columns, rows });
    setFilteredData({ columns, rows });
    setItemsFilter(persons);
  }, [dataReports]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ alignItems: "center" }}>
        <Col>
          {filterSelected && (
            <span
              style={{ marginRight: "1%" }}
              className="subheading caption custom-badges__disabled"
            >
              {filterSelected.title}
              <ClearIcon
                className="custom-badges__delete-button"
                onClick={() => handleDeleteFilter()}
              />
            </span>
          )}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<DownloadOutlinedIcon />}
            className={"custom-input__button__primary-color"}
            onClick={() => buildCsvReport(dataReports)}
          >
            <p className="button">Descargar excel</p>
          </Button>
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon />}
            endIcon={<ExpandMoreIcon />}
            className={"custom-input__button__secondary-color"}
            onClick={(e) => setAnchorFilterBy(e.currentTarget)}
          >
            <p className="button">Filtrar por</p>
          </Button>
          {itemsFilter.length > 0 && (
            <Menu
              id="menu-filter-by"
              anchorEl={anchorFilterBy}
              open={openFilterBy}
              onClose={() => setAnchorFilterBy(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {itemsFilter.map((option) => (
                <MenuItem
                  key={option.selectionClick}
                  onClick={() => handleMenuItemClickFilterBy(option)}
                >
                  <span className="body-content">{option.title}</span>
                </MenuItem>
              ))}
            </Menu>
          )}
        </Col>
      </Row>
      <br />
      <br />
      <Row
        className="data-table dt-list-items"
        style={{ wordBreak: "break-word" }}
      >
        <MDBDataTableV5
          autoWidth={false}
          noRecordsFoundLabel="No se encontraron resultados"
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={filteredData}
          infoLabel={["Mostrando", "a", "de", "obligaciones"]}
          fullPagination
        />
      </Row>
      <ModalInpuText
        title={"Reportar obligación"}
        message={"¿Deseas reportar la siguiente obligación?"}
        agreeText={"Aceptar"}
        placeholder={"Escriba el texto aquí..."}
        disagreeText={"Cancelar"}
        handleAgree={handleAgreeModalInputReport}
        handleDisagree={() => {
          setItemReport(null);
          setIsOpenModalInputReport(false);
        }}
        onClose={() => setIsOpenModalInputReport(false)}
        open={isOpenModalInputReport}
      />
    </Container>
  );
};
