import { useEffect, useState } from "react";
import PropTypes from "prop-types";
/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @enum {number}
 * @name LoadDataActions
 * @description The enum for the load data actions
 */
const LoadDataActions = {
  NEXT: 1,
  PREVIOUS: -1,
};

/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @function
 * @name useInfiniteDataLoader
 * @description A hook that loads data on the list
 * @param {object} props - The props of the hook
 * @param {array} props.data - The data to be loaded
 * @param {number} [props.elementsQtyToAdd] - The quantity of elements to be added, default is 20
 * @param {boolean} [props.keepPreviousData] - Whether to keep previous data, default is true
 * @param {number} [props.previousElementsQtyToKeep] - The quantity of previous elements to keep, default is 0
 * @returns {{filteredData: filteredData, loadFilteredData: loadFilteredData, isInInitialData: isInInitialData, isInLastData: isInLastData, goToPage: goToPage}} An object with the following properties:
 * @property {array} filteredData - The filtered data
 * @property {function} loadFilteredData - The function to load the filtered data
 * @property {boolean} isInInitialData - Whether the initial data is loaded
 * @property {boolean} isInLastData - Whether the last data is loaded
 * @property {goToPage} goToPage - The function to go to a specific page
 * @example
 * const data = Array.from({length: 50}).map((_, index) => `Item ${index}`);
 * const { filteredData, loadFilteredData, isInInitialData, isInLastData, goToPage } = useDataLoader(data);
 */
export const useInfiniteDataLoader = ({
  data,
  elementsQtyToAdd = 20,
  keepPreviousData = true,
  previousElementsQtyToKeep = 0,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // TODO: Check the data load logic when keepPreviousData is false @Roberto
  const [isInLastData, setIsInLastData] = useState(false);
  const [isInInitialData, setIsInInitialData] = useState(true);
  const [lastLoadDataAction, setLastLoadDataAction] = useState(
    LoadDataActions.NEXT
  );
  const lastPage = Math.ceil(data.length / elementsQtyToAdd);

  const indexIsInRange = (index) => {
    return index >= 0 && index < data.length;
  };

  const getQtyToSkip = (previousOrNext) => {
    return previousOrNext !== lastLoadDataAction ? 2 : 1;
  };

  const getInitialIndex = (previousOrNext) => {
    let initialIndex = 0;
    const qtyToSkip = getQtyToSkip(previousOrNext);
    if (keepPreviousData) {
      initialIndex = filteredData.length;
    } else if (!keepPreviousData) {
      if (previousOrNext === LoadDataActions.PREVIOUS) {
        initialIndex = indexIsInRange(
          elementsQtyToAdd * currentPage - qtyToSkip
        )
          ? elementsQtyToAdd * (currentPage - qtyToSkip)
          : elementsQtyToAdd * currentPage;
      } else if (previousOrNext === LoadDataActions.NEXT) {
        initialIndex = indexIsInRange(elementsQtyToAdd * currentPage)
          ? elementsQtyToAdd * currentPage
          : data.length - elementsQtyToAdd;
      }
      initialIndex = Math.max(0, initialIndex - previousElementsQtyToKeep);
    }
    setIsInInitialData(initialIndex === 0);
    return initialIndex;
  };

  const getFinalIndex = (initialIndex, previousOrNext) => {
    let finalIndex = indexIsInRange(initialIndex + elementsQtyToAdd)
      ? initialIndex + elementsQtyToAdd
      : data.length;
    const newCurrentPage =
      previousOrNext === LoadDataActions.PREVIOUS
        ? currentPage - 1
        : currentPage + 1;
    if (newCurrentPage < 0) {
      setCurrentPage(1);
    } else if (newCurrentPage > lastPage) {
      setCurrentPage(lastPage);
    } else {
      setCurrentPage(newCurrentPage);
    }
    finalIndex = Math.min(finalIndex, data.length);
    setIsInLastData(finalIndex === data.length);
    return finalIndex;
  };

  const getDataToInclude = (initialIndex, finalIndex) => {
    let dataToInclude = data.slice(initialIndex, finalIndex);
    if (keepPreviousData) {
      dataToInclude = filteredData.concat(dataToInclude);
    }
    return dataToInclude;
  };

  /**
   * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
   * @function
   * @name loadFilteredData
   * @description Loads data on the list
   * @param {number} previousOrNext - 1 for next, -1 for previous
   * @returns {void}
   * @throws {Error} If previousOrNext is not -1 or 1
   * @example
   * // load new data on the list
   * loadNewFilteredData(1);
   * // load previous data on the list
   * loadNewFilteredData(-1);
   */
  const loadFilteredData = (previousOrNext) => {
    if (!Object.values(LoadDataActions).includes(previousOrNext)) {
      throw new Error(`
      previousOrNext must be one of ${Object.values(LoadDataActions).join(
        ", "
      )}`);
    }
    if (
      keepPreviousData &&
      (filteredData.length === data.length ||
        previousOrNext === LoadDataActions.PREVIOUS)
    )
      return;
    else if (!keepPreviousData) {
      if (
        (previousOrNext === LoadDataActions.PREVIOUS && isInInitialData) ||
        (previousOrNext === LoadDataActions.NEXT && isInLastData)
      )
        return;
    }
    setLastLoadDataAction(previousOrNext);
    let initialDataIndex = getInitialIndex(previousOrNext);
    let finalDataIndex = getFinalIndex(initialDataIndex, previousOrNext);
    let dataToInclude = getDataToInclude(initialDataIndex, finalDataIndex);
    setFilteredData(dataToInclude);
  };

  const goToPage = (page) => {
    if (page === -1) {
      setCurrentPage(lastPage);
    }
    if (page < 1 || page > lastPage) return;
    if (page >= 1 && page <= lastPage) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    let initialIndex = keepPreviousData
      ? 0
      : elementsQtyToAdd * currentPage - elementsQtyToAdd;
    if (!keepPreviousData) {
      if (initialIndex > data.length) {
        initialIndex = data.length - elementsQtyToAdd;
      } else if (initialIndex < 0) {
        initialIndex = 0;
      }
    }
    let finalIndex = elementsQtyToAdd * currentPage + elementsQtyToAdd;
    setFilteredData(data.slice(initialIndex, finalIndex));
  }, [currentPage, data]);

  return {
    filteredData,
    loadFilteredData,
    isInInitialData,
    isInLastData,
    goToPage,
  };
};

useInfiniteDataLoader.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.array.isRequired,
    elementsQtyToAdd: PropTypes.number,
    keepPreviousData: PropTypes.bool,
    previousElementsQtyToKeep: PropTypes.number,
  }),
};
