import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../../parts/breadcrum";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress, IconButton } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import {
  createPart,
  createPartMassive,
  getPartInfoFromFile,
} from "../../../../../../../services/parts/partsServices";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";
import LoadingContent from "../../../../../../../components/loadingContent";
import { CODES } from "../../../../../../../utils/codes";
import { useNavigate } from "react-router-dom";
import {
  resetPartListStatus,
  resetSignatoriesListStatus,
} from "../../../../../../../parts/parts/partsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUnitsCoporativesList,
  getUnitsCorporativesList,
  getUnitsCorporativesStatus,
} from "../../../../../admin/modules/unitCorporative/unitCorporativeSlice";
import { v4 as uuidv4 } from "uuid";
import ModalDecision from "../../../../../../../components/Modals/modalDecision";
import { PERSON_TYPE, TYPE_CHARGES } from "../utils";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import { CustomToolTip } from "../../../../../../../components/Tooltip/Tooltip";
import { IOSSwitch } from "../../../../../admin/modules/rulesReview/rulesReview";
import ModalUploadDocsMassive from "../../../../../../../components/Modals/ModalUploadDocsMassive";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
export const CreatePartCertificateMassive = () => {
  //States
  const [openModalUploadDocument, setOpenModalUploadDocument] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseDataInfo, setResponseDataInfo] = useState({});

  const [validateCerlDate, setValidateCerlDate] = useState(false);
  const [listUnitsCorporatives, setListUnitsCorporatives] = useState([]);

  const [documentList, setDocumentList] = useState([]);
  const [currentDocInfo, setCurrenDocInfo] = useState(null);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalDecisionCreate, setIsOpenModalDecisionCreate] =
    useState(false);
  //redux
  const dispatch = useDispatch();
  const getListUnitsCorporatives = useSelector(getUnitsCorporativesList);
  const statusFetchUnits = useSelector(getUnitsCorporativesStatus);
  //Handles

  //Router
  const navigate = useNavigate();

  const handleCreatePartSelected = async () => {
    setIsOpenModalDecisionCreate(false);
    try {
      setIsLoading(true);

      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const corporateUnitDefault =
        listUnitsCorporatives.find((uc) => uc?.isDefault)?._id || "";
      const corporateUnitId = localStorage.getItem("corporateUnitId");
      const fieldSuggestions = currentDocInfo;
      let identificationData = {};

      identificationData = {
        business: {
          businessName: fieldSuggestions?.business.value || "",
          nit: fieldSuggestions?.documentNumberLegal.value || "",
          type: fieldSuggestions?.documentTypeLegal.value || "",
          checkDigit: "",
        },
        legalRepresentative: {
          documentType: fieldSuggestions?.documentType.value || "",
          documentNumber: fieldSuggestions?.documentNumber.value || "",
          firstName: fieldSuggestions?.firstName.value || "",
          firstSurname: fieldSuggestions?.firstSurname.value || "",
          secondName: fieldSuggestions?.lastName.value || "",
          secondSurname: fieldSuggestions?.secondSurname.value || "",
          limitations:
            fieldSuggestions?.limitationsLegalRepresentative.value || "",
        },
        comercialName: fieldSuggestions?.comercialName.value || "",
        charge: TYPE_CHARGES?.LEGAL,
      };

      const contactInfo = {
        email: fieldSuggestions?.email.value || "",
        city: fieldSuggestions?.city.value || "",
        province: fieldSuggestions?.province.value || "",
        physicalAddress: fieldSuggestions?.address.value || "",
      };
      const obj = {
        ...contactInfo,
        ...identificationData,
        createdBy: userId,
        company,
        typePart: PERSON_TYPE?.LEGAL,
        relatedSignatories: [],
        labels: [],
        associatedCorporateUnits: [corporateUnitId ?? corporateUnitDefault],
        ...(fieldSuggestions?.bucketName && {
          bucketName: fieldSuggestions?.bucketName,
        }),
        ...(fieldSuggestions?.cerlFileName && {
          cerlFileName: fieldSuggestions?.cerlFileName,
        }),
        ...(fieldSuggestions?.filenameInBucket && {
          filenameInBucket: fieldSuggestions?.filenameInBucket,
        }),
      };
      const response = await createPart(obj);
      setResponseDataInfo(response);
      setIsOpenModalInfo(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateMultipleParts = async () => {
    setIsOpenModalDecisionCreate(false);
    try {
      setIsLoading(true);

      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      const corporateUnitDefault =
        listUnitsCorporatives.find((uc) => uc?.isDefault)?._id || "";

      const partsObj = documentList
        .map((part) => {
          if (!part.error && part.info) {
            const fieldSuggestions = part.info;
            let identificationData = {};

            identificationData = {
              business: {
                businessName: fieldSuggestions?.business.value || "",
                nit: fieldSuggestions?.documentNumberLegal.value || "",
                type: fieldSuggestions?.documentTypeLegal.value || "",
                checkDigit: "",
              },
              legalRepresentative: {
                documentType: fieldSuggestions?.documentType.value || "",
                documentNumber: fieldSuggestions?.documentNumber.value || "",
                firstName: fieldSuggestions?.firstName.value || "",
                firstSurname: fieldSuggestions?.firstSurname.value || "",
                secondName: fieldSuggestions?.lastName.value || "",
                secondSurname: fieldSuggestions?.secondSurname.value || "",
                limitations:
                  fieldSuggestions?.limitationsLegalRepresentative.value || "",
              },
              comercialName: fieldSuggestions?.comercialName.value || "",
              charge: TYPE_CHARGES?.LEGAL,
            };

            const contactInfo = {
              email: fieldSuggestions?.email.value || "",
              city: fieldSuggestions?.city.value || "",
              province: fieldSuggestions?.province.value || "",
              physicalAddress: fieldSuggestions?.address.value || "",
            };
            const obj = {
              ...contactInfo,
              ...identificationData,
              createdBy: userId,
              company,
              typePart: PERSON_TYPE?.LEGAL,
              relatedSignatories: [],
              labels: [],
              ...(corporateUnitDefault && {
                associatedCorporateUnits: [corporateUnitDefault],
              }),
              ...(fieldSuggestions?.bucketName && {
                bucketName: fieldSuggestions?.bucketName,
              }),
              ...(fieldSuggestions?.cerlFileName && {
                cerlFileName: fieldSuggestions?.cerlFileName,
              }),
              ...(fieldSuggestions?.filenameInBucket && {
                filenameInBucket: fieldSuggestions?.filenameInBucket,
              }),
            };
            return obj;
          }
        })
        .filter((part) => part !== undefined);

      if (partsObj.length > 0) {
        const response = await createPartMassive({ massiveParts: partsObj });
        setResponseDataInfo(response);
        setIsOpenModalInfo(true);
      } else {
        setIsOpenModalDecisionCreate(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModalInfo = () => {
    setIsOpenModalInfo(false);
    dispatch(resetPartListStatus());
    dispatch(resetSignatoriesListStatus());
  };

  const handleShowInfo = (document) => {
    if (!document.error && !document.isLoading) {
      const docObj = {
        ...document.info,
        id: document.id,
      };
      setCurrenDocInfo(docObj);
    } else {
      setCurrenDocInfo(null);
    }
  };
  //useEffect
  useEffect(() => {
    try {
      const { subRole, corporateUnits } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      if (subRole === "ADMIN ADMINISTRADOR") {
        setListUnitsCorporatives(corporateUnits);
      } else {
        setListUnitsCorporatives(getListUnitsCorporatives);
      }
    } catch (error) {
      console.error(error);
    }
  }, [getListUnitsCorporatives]);

  useEffect(() => {
    if (statusFetchUnits === "fetch") {
      dispatch(fetchUnitsCoporativesList());
    }
  }, [statusFetchUnits]);

  useEffect(() => {
    const processDocument = async (document) => {
      const documentId = uuidv4();
      const newDocument = {
        doc: document,
        id: documentId,
        isLoading: true,
        info: null,
        error: null,
      };
      setDocumentList((prevList) => [...prevList, newDocument]);

      try {
        const company = localStorage.getItem("company");
        const createdBy = JSON.parse(
          localStorage.getItem("payloadToken")
        ).userId;
        const obj = {
          file: document,
          company,
          createdBy,
          validateCerlDate,
        };
        const response = await getPartInfoFromFile(obj);

        if (
          response?.status === CODES.COD_RESPONSE_HTTP_CREATED &&
          response?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          let newFileInfoResponse = response?.data?.responseMessage?.data;
          setDocumentList((prevList) =>
            prevList.map((doc) => {
              if (doc.id === documentId) {
                return {
                  ...doc,
                  isLoading: false,
                  info: JSON.parse(newFileInfoResponse),
                };
              }
              return doc;
            })
          );
        } else {
          setDocumentList((prevList) =>
            prevList.map((doc) => {
              if (doc.id === documentId) {
                if (
                  response?.data?.responseCode === CODES.COD_RESPONSE_ERROR_FIND
                ) {
                  return {
                    ...doc,
                    error: true,
                    isLoading: false,
                    warning: true,
                    info: "Es necesario que cargues un CERL con una fecha de expedición no mayor a 30 días",
                  };
                } else {
                  return {
                    ...doc,
                    isLoading: false,
                    error: true,

                    info: "No ha sido posible extraer la información de esté documento",
                  };
                }
              }
              return doc;
            })
          );
        }
      } catch (error) {
        setDocumentList((prevList) =>
          prevList.map((doc) => {
            if (doc.id === documentId) {
              return {
                ...doc,
                isLoading: false,
                error: true,
                info: "No ha sido posible extraer información de este documento",
              };
            }
            return doc;
          })
        );
      }
    };

    if (documentData.length > 0) {
      documentData.forEach((document) => {
        processDocument(document);
      });

      setDocumentData([]);
    }
  }, [documentData]);

  // Resto del componente...

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"} className="mt-4">
        <Col xs={3}>
          <SectionTitle title={"Crear partes desde certificados"} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <p className="caption">
            Cargue el o los Certificados de Existencia y Representación Legal de
            los cuales quiere extraer la información de las partes
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <p className="caption">Recuerda cargar un archivo en formato PDF</p>
        </Col>
      </Row>

      <Row className="mb-3 align-items-center">
        <Col xs={"auto"}>
          <span className="caption">
            ¿Quieres que el sistema compruebe que el Certificado de Existencia y
            Representación Legal haya sido expedido en los últimos 30 días?
          </span>
        </Col>
        <Col xs={"auto"}>
          <span className="caption">No</span>
        </Col>

        <Col xs={"auto"}>
          <IOSSwitch
            onChange={(event) => {
              setValidateCerlDate(event.target.checked);
            }}
            sx={{ m: 1 }}
            colorbase={"#00374F"}
            value={validateCerlDate}
          />
        </Col>
        <Col xs={"auto"}>
          <span className="caption">Si</span>
        </Col>
      </Row>
      <Row style={{ marginBottom: "7rem" }}>
        <Col xs={3}>
          {documentList.map((doc) => {
            return (
              <Row className="mt-3">
                <Col
                  xs={12}
                  className={
                    doc?.id === currentDocInfo?.id
                      ? "custom-container-cerl__selected "
                      : "custom-container-cerl"
                  }
                  onClick={() => handleShowInfo(doc)}
                >
                  <Row>
                    <Col xs={10}>
                      <Row>
                        <Col xs={12}>
                          <p className="heading__primary-color">Documento</p>
                        </Col>
                        <Col xs={12}>
                          <p className="caption__disabled"> {doc?.doc?.name}</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={2}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {doc.isLoading ? (
                        <CircularProgress
                          size={30}
                          style={{ color: "#00374f" }}
                        />
                      ) : doc.error ? (
                        <CustomToolTip direction="right" message={doc.info}>
                          {doc.warning ? (
                            <WarningAmberOutlinedIcon
                              style={{ fontSize: 30 }}
                              className="color__warningColor"
                            />
                          ) : (
                            <ReportOutlinedIcon
                              style={{ fontSize: 30, color: "#e44258" }}
                            />
                          )}
                        </CustomToolTip>
                      ) : (
                        <IconButton aria-label="edit">
                          <CheckCircleOutlineOutlinedIcon
                            style={{ fontSize: 30, color: "#00ca72" }}
                          />
                        </IconButton>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}

          <Row className="mt-4">
            <Col xs={8}>
              <Button
                variant="contained"
                type="button"
                className="custom-input__button__secondary-color"
                startIcon={<AddIcon fontSize="large" />}
                onClick={() => {
                  setOpenModalUploadDocument(true);
                }}
              >
                Cargar documentos
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={9}>
          {currentDocInfo && (
            <Row className="justify-content-center p-3">
              <Container className="custom-container-extract">
                <Row>
                  <Col xs={12}>
                    <p className="caption">
                      La información que te presentamos es obtenida mediante
                      Inteligencia Artificial, por esta razón es importante que
                      revises detalladamente los resultados presentados:
                    </p>
                  </Col>
                  <Col xs={12} className="mt-2">
                    <ol>
                      {Object.entries(currentDocInfo).map(([key, value]) => {
                        if (key.includes("title")) {
                          return (
                            <p key={uuidv4()} className="subheading">
                              {`${value.name}:`}
                            </p>
                          );
                        }
                        if (value.value) {
                          if (key === "limitationsLegalRepresentative") {
                            return (
                              <li key={uuidv4()} className="caption">
                                {`${value.name}:`}
                                {ReactHtmlParser(value.value)}
                              </li>
                            );
                          } else {
                            return (
                              <li
                                className="caption"
                                key={uuidv4()}
                              >{`${value.name}: ${value.value}`}</li>
                            );
                          }
                        }
                      })}
                    </ol>
                  </Col>
                </Row>
              </Container>
            </Row>
          )}
        </Col>
      </Row>

      <Row className="sidebar__bottom__container">
        <Col xs={2}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__secondary-color"
            startIcon={<ClearIcon fontSize="large" />}
            onClick={() => {
              setIsOpenModalDecision(true);
            }}
          >
            Cancelar
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            variant="contained"
            type="button"
            className="custom-input__button__primary-color"
            startIcon={<AddOutlinedIcon fontSize="large" />}
            onClick={() => {
              setIsOpenModalDecisionCreate(true);
            }}
          >
            Crear
          </Button>
        </Col>
      </Row>

      <ModalUploadDocsMassive
        open={openModalUploadDocument}
        title={"Selecciona el documento para subir"}
        agreeText={"Continuar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato PDF, pesar máximo 5mb y tener una vigencia máxima de 30 días."
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setOpenModalUploadDocument(false);
        }}
        typeDocument={"ONLY_PDF"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato pdf"
        maxFileSize={5242880}
      />
      <ModalInfo
        title={
          responseDataInfo.status === CODES.COD_RESPONSE_HTTP_CREATED
            ? "Creación de partes exitosa"
            : "Atención"
        }
        responseData={responseDataInfo}
        onClose={handleCloseModalInfo}
        open={isOpenModalInfo}
        buttonClose={true}
      />

      <ModalDecision
        open={isOpenModalDecision}
        message={
          "¿Estás seguro de que deseas cancelar la creación de tus partes legales? "
        }
        agreeText={"Cerrar y continuar"}
        disagreeText={"Cancelar todo"}
        title={"Cancelar creación de partes"}
        type={"warning"}
        onClose={() => setIsOpenModalDecision(false)}
        handleDisagree={() => {
          setDocumentData([]);
          setCurrenDocInfo(null);
          setDocumentList([]);
          navigate(-1);
        }}
        handleAgree={() => setIsOpenModalDecision(false)}
        agreeIconStart={<CheckOutlinedIcon />}
        disagreeIconStart={<ClearIcon />}
      />
      <ModalDecision
        open={isOpenModalDecisionCreate}
        message={"¿De dónde quieres crear las partes?"}
        agreeText={"Todos los documentos"}
        disagreeText={
          currentDocInfo ? "Documento seleccionado" : "Seleccionar documento"
        }
        title={"Atención"}
        onClose={() => setIsOpenModalDecisionCreate(false)}
        handleDisagree={() => {
          if (currentDocInfo) {
            handleCreatePartSelected();
          } else {
            setIsOpenModalDecisionCreate(false);
          }
        }}
        handleAgree={() => {
          handleCreateMultipleParts();
        }}
        agreeIconStart={<BallotOutlinedIcon />}
        disagreeIconStart={<SelectAllOutlinedIcon />}
      />
    </Container>
  );
};
