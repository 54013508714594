import { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @enum {number}
 * @name ScrollPositions
 * @description The enum for the scroll positions
 */
const ScrollPositions = {
  TOP: -1,
  BOTTOM: 1,
};

/**
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @function
 * @name useScrollSpy
 * @description Hook for handle the scroll of an element
 * @param {object} props - The props of the hook
 * @param {{current: any}} props.elementRef - The ref of the element
 * @param {boolean} [props.rememberLastScrollPosition] - Whether to remember the last scroll position, default is true
 * @returns {{isNearToBottom: isNearToBottom, isNearToTop: isNearToTop}} An object with the following properties:
 * @property {boolean} isNearToBottom - Whether the element is near to the bottom
 * @property {boolean} isNearToTop - Whether the element is near to the top
 * @example
 * const elementRef = useRef();
 * const { isScrollingToBottom, isScrollingToTop } = useScrollSpy(elementRef);
 */
export const useScrollSpy = ({
  elementRef,
  rememberLastScrollPosition = true,
}) => {
  const [isNearToBottom, setIsNearToBottom] = useState(false);
  const [isNearToTop, setIsNearToTop] = useState(true);
  const [lastScrollRange, setLastScrollRange] = useState(0);
  const differenceToBeNear = 1;

  useEffect(() => {
    const checkIsNotNearToEdge = (position, scrollableHeight) => {
      if (!rememberLastScrollPosition) return true;
      if (position === ScrollPositions.TOP) {
        return lastScrollRange !== differenceToBeNear;
      } else if (position === ScrollPositions.BOTTOM) {
        return lastScrollRange !== scrollableHeight - differenceToBeNear;
      }
    };

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = elementRef.current;
      const scrollableHeight = scrollHeight - clientHeight;

      if (
        checkIsNotNearToEdge(ScrollPositions.TOP, scrollableHeight) &&
        differenceToBeNear >= scrollTop
      ) {
        setIsNearToTop(true);
        setLastScrollRange(differenceToBeNear);
      } else if (isNearToTop) setIsNearToTop(false);

      if (
        checkIsNotNearToEdge(ScrollPositions.BOTTOM, scrollableHeight) &&
        scrollableHeight - differenceToBeNear < scrollTop
      ) {
        setIsNearToBottom(true);
        setLastScrollRange(scrollableHeight - differenceToBeNear);
      } else if (isNearToBottom) setIsNearToBottom(false);
    };

    elementRef?.current.addEventListener("scroll", handleScroll);

    return () => {
      elementRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [
    elementRef,
    isNearToBottom,
    isNearToTop,
    lastScrollRange,
    rememberLastScrollPosition,
  ]);
  
  return { isNearToBottom, isNearToTop };
};

useScrollSpy.propTypes = {
  props: PropTypes.shape({
    elementRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    rememberLastScrollPosition: PropTypes.bool,
  }),
};
