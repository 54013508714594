import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ModalInpuText = ({
  title,
  message,
  agreeText,
  placeholder,
  disagreeText,
  handleAgree,
  handleDisagree,
  onClose,
  open,
  disableDisagreeText = false,
  validation = {},
  inputType = "textarea",
  value = "",
}) => {
  const [returnMessage, setReturnMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const handleClose = () => {
    onClose();
  };

  const handleSubmitRequest = () => {
    handleAgree(returnMessage);
  };

  useEffect(() => {
    setError("text", "");
    setReturnMessage("");
    if (value) {
      setValue("text", value);
      setReturnMessage(value);
    } else {
      setValue("text", "");
    }
  }, [open]);

  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <Form onSubmit={handleSubmit(handleSubmitRequest)}>
          <DialogTitleContainer onClose={handleClose}>
            <label className="heading__primary-color">{title}</label>
          </DialogTitleContainer>
          <DialogContent className="caption" dividers>
            <p className="caption" style={{ padding: "2% 0" }}>
              {message}
            </p>
            <Form.Group>
              <Form.Control
                {...register("text", validation)}
                as={inputType}
                placeholder={placeholder}
                bsPrefix={
                  errors?.text?.message
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                onChange={(e) => {
                  setReturnMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleSubmit(handleSubmitRequest)();
                }}
              />
              <div
                className="caption custom-input__error"
                style={{ border: "1px sollid red" }}
              >
                {errors.text?.message}
              </div>
            </Form.Group>
          </DialogContent>
          <DialogActions>
            <Row>
              <Col>
                <Button
                  variant="text"
                  className={
                    !disableDisagreeText
                      ? "custom-input__button__secondary-color__forced"
                      : "custom-input__button__secondary-color__forced__disabled"
                  }
                  onClick={handleDisagree}
                  disabled={disableDisagreeText}
                >
                  {disagreeText}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="contained"
                  type="submit"
                  className="custom-input__button__primary-color__forced"
                >
                  {agreeText}
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default ModalInpuText;
