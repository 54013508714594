import { Container, Row, Stack } from "react-bootstrap";
import PropTypes from "prop-types";
import Section from "./components/Section";
import ManageCustomFormModes from "../../constants/manageCustomFormModes";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect } from "react";
import TypeInputFactory from "../../../../../../../../../../../components/InputForms/factories/TypeInputFactory";
import { TypeInputMenuButton } from "../../components/TypeInputMenuButton";

// TYPE DEFINITIONS BEGIN

/**
 * The modes of the `FormSection` component.
 *
 * @typedef {"create" | "edit"} FormSectionModesType
 */

// TYPE DEFINITIONS END

/**
 * FormSection component, this is a form section that contains a title and a list of sections
 *
 * @param {Object} props
 * @param {JSX.Element} [props.Wrapper] Wrapper component, default is a div
 * @param {JSX.Element} [props.TittleWrapper] Wrapper component for the title, default is a div
 * @param {JSX.Element} [props.ContentWrapper] Wrapper component for the content, default is a div
 * @param {String} props.title Title of the form section
 * @param {Array} [props.fields] List of fields, default is an empty array
 * @param {Array} [props.sections] List of sections, default is an empty array
 * @param {Function} props.handleAddQuestion Function to handle the add question
 * @param {Function} props.handleUpdateQuestion Function to handle the update question
 * @param {Function} props.handleDeleteQuestion Function to handle the delete question
 * @param {FormSectionModesType} props.mode The mode of the form section
 * @param {(sectionName: string) => void} props.onAddSection Function to add a section
 * @param {boolean} props.isSubmittingExternally If the form is submitting externally
 * @param {(sectionIndex: ?number, newSectionName: ?string) => void} props.saveFormSectionInfo Function to call when the form is submitted
 * @param {(sectionIndex: number) => void} props.onDeleteSection Function to delete a section
 * @returns The FormSection component
 */
export const FormSection = ({
  Wrapper = ({ children }) => <div>{children}</div>,
  TittleWrapper = ({ children }) => <div>{children}</div>,
  ContentWrapper = ({ children }) => <div>{children}</div>,
  title,
  fields = [],
  sections = [],
  handleAddQuestion,
  handleUpdateQuestion,
  handleDeleteQuestion,
  mode,
  onAddSection,
  isSubmittingExternally,
  saveFormSectionInfo,
  onDeleteSection,
}) => {
  /**
   * Handles the add section.
   */
  const handleAddSection = () => {
    const sectionName = "Nombre de la sección";
    onAddSection(sectionName);
  };

  /**
   * Sets the form section info when the form is submitting externally and there are no sections.
   */
  useEffect(() => {
    if (isSubmittingExternally && sections.length === 0) {
      saveFormSectionInfo();
    }
  }, [isSubmittingExternally]);

  return (
    <Wrapper>
      <TittleWrapper aria-controls="panel-content" id="panel-header">
        <h1 className="heading__primary-color">{title}</h1>
      </TittleWrapper>
      <ContentWrapper>
        <Container fluid className="d-flex flex-column gap-5">
          {sections?.map((section, sectionIndex) => (
            <Section
              key={section._id}
              section={section}
              sectionIndex={sectionIndex}
              onUpdateQuestion={handleUpdateQuestion}
              onDeleteQuestion={handleDeleteQuestion}
              onAddQuestion={handleAddQuestion}
              mode={mode}
              isSubmittingExternally={isSubmittingExternally}
              saveFormSectionInfo={saveFormSectionInfo}
              onDeleteSection={onDeleteSection}
            />
          ))}
          <Row>
            {fields.map((field, fieldIndex) => (
              <TypeInputFactory
                key={field._id}
                type={field.type}
                inputProps={{
                  question: field,
                  onDataChange: (question) =>
                    handleUpdateQuestion(undefined, fieldIndex, question),
                  onDelete: () => handleDeleteQuestion(undefined, fieldIndex),
                }}
              />
            ))}
          </Row>
          <Stack direction="vertical" gap={3}>
            <Button
              variant="contained"
              startIcon={<Add />}
              className={`custom-input__button__primary-color`}
              style={{
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
              onClick={handleAddSection}
            >
              Agregar sección
            </Button>
            <TypeInputMenuButton handleAddQuestion={(question) => handleAddQuestion(undefined, question)} />
          </Stack>
        </Container>
      </ContentWrapper>
    </Wrapper>
  );
};

FormSection.propTypes = {
  Wrapper: PropTypes.elementType,
  TittleWrapper: PropTypes.elementType,
  ContentWrapper: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  fields: PropTypes.array,
  sections: PropTypes.array,
  handleAddQuestion: PropTypes.func.isRequired,
  handleUpdateQuestion: PropTypes.func.isRequired,
  handleDeleteQuestion: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(ManageCustomFormModes)).isRequired,
  onAddSection: PropTypes.func.isRequired,
  isSubmittingExternally: PropTypes.bool.isRequired,
  saveFormSectionInfo: PropTypes.func.isRequired,
  onDeleteSection: PropTypes.func.isRequired,
};
