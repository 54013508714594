import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SectionCardButtonWithNavButton from "../../../../../components/Cards/SectionCardButtonWithNavButton";

import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import { CustomToolTip } from "../../../../../components/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import { PropTypes } from "prop-types";

/**
 * This function will check if the user has permission to perform an action in a module.
 * 
 * @param {Array} permissions The permissions of the user.
 * @param {String} moduleName The name of the module.
 * @param {Array} actions The actions to check.
 * @returns The result of the check, true if the user has permission, false otherwise.
 */
const hasPermission = (permissions, moduleName, actions) => {
  const module = permissions.find(
    (permission) => permission.module === moduleName
  );
  if (!module) return false;
  return actions.some((action) => module.actions[action]);
};

const LibraryService = () => {
  const { permissions = [] } = JSON.parse(localStorage.getItem("payloadToken"));
  const allowedToUse = {
    partsAndSignatory: hasPermission(permissions, "PartsAndSignatory", [
      "create",
      "update",
      "delete",
    ]),
  };

  return (
    <Container fluid>
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Biblioteca"} />
      </Row>
      <br />
      <br />
      <Row>
        <p className="caption">¿Qué quieres gestionar?</p>
      </Row>
      <br />
      <br />
      <br />
      <Row style={{ rowGap: "30px" }}>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<FeedOutlinedIcon className="cards__icon-svg" />}
            title="Mis Minutas"
            description="Revisa, edita, agrega y elimina minutas de tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/contractTypes"
            mixPanelTrack="Mis minutas"
          />
        </Col>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<ExtensionOutlinedIcon className="cards__icon-svg" />}
            title="Mis Cláusulas"
            description="Revisa, edita, agrega y elimina cláusulas de tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/clauses"
            mixPanelTrack="Mis cláusulas"
          />
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: "30px" }}>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<FeedOutlinedIcon className="cards__icon-svg" />}
            title="Mis Plantillas"
            description="Revisa, edita, agrega y elimina plantillas de otros documentos en tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/documentTemplates"
            mixPanelTrack="Plantillas de otros documentos"
          />
        </Col>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <SectionCardButtonWithNavButton
            icon={<ExtensionOutlinedIcon className="cards__icon-svg" />}
            title="Mis Bloques"
            description="Revisa, edita, agrega y elimina otros bloques de texto en tu biblioteca."
            navigationButton={
              <ArrowForwardOutlinedIcon className="cards__nav-button__icon" />
            }
            navigationPath="service/library/predeterminedTexts"
            mixPanelTrack="Textos predeterminados"
          />
        </Col>
      </Row>
      <br />
      <Row style={{ rowGap: "30px" }}>
        <Col xs={12} lg={6} style={{ padding: "0% 8%" }}>
          <PartsAndSignatoriesCardButton
            isActive={allowedToUse.partsAndSignatory}
          />
        </Col>
      </Row>
    </Container>
  );
};

/**
 * This component will render the Parts and Signatories card button.
 * 
 * @param {Object} params The params of the component.
 * @param {Boolean} params.isActive The active state of the button.
 * @returns The rendered Parts and Signatories card button.
 */
const PartsAndSignatoriesCardButton = ({ isActive = false }) => {
  /**
   * This function will render the card button.
   * 
   * @param {Object} params The params of the function.
   * @param {Boolean} params.isActive The active state of the button.
   */
  const renderCardButton = ({ isActive = false }) => {
    return (
      <SectionCardButtonWithNavButton
        icon={<SupervisedUserCircleOutlinedIcon className="cards__icon-svg" />}
        title="Partes y Firmantes "
        description="Revisa, edita, agrega y elimina partes y firmantes de tu biblioteca."
        navigationButton={
          <IconButton
            className={getNavigationButtonColor(isActive)}
            disabled={!isActive}
          >
            <ArrowForwardOutlinedIcon
              className={getNavigationButtonColor(isActive)}
            />
          </IconButton>
        }
        navigationPath="service/library/partsAndSignatures"
        mixPanelTrack="Plantillas de otros documentos"
        isActive={isActive}
      />
    );
  };

  /**
   * This function will return the color of the navigation button.
   * 
   * @param {Boolean} isActive The active state of the button.
   */
  const getNavigationButtonColor = (isActive = false) => {
    return isActive
      ? "cards__nav-button__icon"
      : "cards__nav-button__icon__disabled";
  };

  return (
    <>
      {isActive ? (
        renderCardButton({ isActive })
      ) : (
        <CustomToolTip message="Por favor, contacta al Usuario Administrador para obtener los permisos necesarios.">
          {renderCardButton({ isActive })}
        </CustomToolTip>
      )}
    </>
  );
};

PartsAndSignatoriesCardButton.propTypes = {
  isActive: PropTypes.bool,
};

export default LibraryService;
