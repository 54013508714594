import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../utils/mixPanel";

/**
 * Class that contains the colors for the component {@link SectionCardButtonWithNavButton}.
 * 
 * @param {Object} args The arguments of the class.
 * @param {String} args.heading The color of the heading.
 * @param {String} args.cards__icon_section The color of the icon section.
 * @param {String} args.caption The color of the caption.
 */
class SectionCardButtonWithNavButtonColors {
  constructor({ heading, cards__icon_section, caption }) {
    this.heading = heading;
    this.cards__icon_section = cards__icon_section;
    this.caption = caption;
  }
}

const SectionCardButtonWithNavButton = (props) => {
  const {
    title,
    description,
    icon,
    navigationButton,
    navigationPath,
    mixPanelTrack,
    isActive = true,
  } = props;
  const navigate = useNavigate();
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  /** The available colors for the component */
  const colors = {
    active: new SectionCardButtonWithNavButtonColors({
      heading: "heading__primary-color",
      cards__icon_section: "cards__icon-section",
      caption: "caption",
    }),
    inactive: new SectionCardButtonWithNavButtonColors({
      heading: "heading__disabled",
      cards__icon_section: "cards__icon-section__disabled",
      caption: "caption__disabled",
    }),
  };
  /** The current color of the component */
  const [currentColor, setCurrentColor] = useState(colors.active);

  /**
   * This function will handle the navigation button click.
   * 
   * If the isActive prop is false, the function will return, otherwise it will track the event and navigate to the path specified in the navigation path prop.
   */
  const handleNavigationButtonClick = () => {
    if(!isActive) return;

    if (mixPanelTrack) {
      Mixpanel.track(title, {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
    }
    navigate(`/${navigationPath}`);
  }

  /** 
   * This effect will change the color of the component based on the isActive prop.
   */
  useEffect(() => {
    setCurrentColor(isActive ? colors.active : colors.inactive);
  }, [isActive]);

  return (
    <div
      className="section-card"
      style={{ cursor: isActive ? "auto" : "default" }}
    >
      <Row style={{ height: "inherit" }}>
        <Col xs={"auto"} className={currentColor.cards__icon_section}>
          {icon}
        </Col>
        <Col className="section-card__title">
          <Row>
            <p className={currentColor.heading}>{title}</p>
          </Row>
          <br />
          <Row>
            <p className={`caption__justify ${currentColor.caption}`}>{description}</p>
          </Row>
          <br />
          <Row>
            <div
              data-testid="button-nav"
              className="cards__nav-button"
              onClick={handleNavigationButtonClick}
            >
              {navigationButton}
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionCardButtonWithNavButton;
