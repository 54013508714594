import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Drawer } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
const DetailsForm = ({
  openDetailsDrawer,
  setOpenDetailsDrawer,
  selectedElement,
}) => {

  const ID_STATUS_DELETE = "621fdcd7e1b7dfa85f10bf46";

  const isDeletedElement = selectedElement?.state === ID_STATUS_DELETE;

  const getPropertyName = (propertyName) => {
    const propertiesName = {
      name: "Nombre del Formulario",
      sections: "Preguntas del Formulario",
      requiredDocuments: "Documentos Requeridos",
      labels: "Etiquetas",
      fields: "Preguntas del Formulario",
    };
    const name = propertiesName[propertyName] ?? "";
    
    return name;
  };

  return (
    <Drawer
      anchor="right"
      open={openDetailsDrawer}
      onClose={() => {
        setOpenDetailsDrawer(false);
      }}
    >
      <Container fluid style={{ width: "46.7rem", height: "inherit" }}>
        <Row className="col-flex mt-4">
          <Col md={10}>
            <span className="heading__primary-color">
              {`Detalles Formulario de ${selectedElement?.name}.`}{" "}
            </span>
          </Col>
          <Col md={1}>
            <CloseIcon
              fontSize="large"
              onClick={() => {
                setOpenDetailsDrawer(false);
              }}
            />
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
        </Row>
        <br />
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        >
          <Col md={"auto"} className="subheading">
            Creado:
            <div className="caption">{selectedElement?.createdAt}</div>
          </Col>
        </Row>

        {isDeletedElement && (<>
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Creado por:
              <div className="caption">{
                selectedElement?.createdBy?.firstName ||
                selectedElement?.createdBy?.firstSurname ||
                selectedElement?.createdBy?.companies?.[0]?.companyData
                  ?.companyName
              }</div>
            </Col>
          </Row>
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Eliminado:
              <div className="caption">{selectedElement?.updatedAt}</div>
            </Col>
          </Row>
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Eliminado por:
              <div className="caption">{
                selectedElement?.deletedBy?.firstName ||
                selectedElement?.deletedBy?.firstSurname ||
                selectedElement?.deletedBy?.companies?.[0]?.companyData
                  ?.companyName
              }</div>
            </Col>
          </Row>
          <Row
            style={{ height: "auto", marginBottom: "3%" }}
            className="p-2 ml-2"
          >
            <Col md={"auto"} className="subheading">
              Unidades corporativas:
              <div className="caption">
                <Row style={{ rowGap: "3%", columnGap: "2%" }}>
                  {selectedElement?.corporateUnits?.length > 0 ? (
                    selectedElement.corporateUnits.map((label) => (
                      <Col
                        className="drawner-details__labels"
                        md={"auto"}
                        key={label._id}
                      >
                        {label.name}
                      </Col>
                    ))
                  ) : (
                    <Col md={"auto"} className="caption">
                      Sin unidades corporativas
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </>)}

        {selectedElement?.modifiedBy && (
          <>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                Última edición:
                <div className="caption"> {selectedElement?.updatedAt}</div>
              </Col>
            </Row>
            <Row
              style={{ height: "auto", marginBottom: "3%" }}
              className="p-2 ml-2"
            >
              <Col md={"auto"} className="subheading">
                Modificado por:
                <div className="caption">
                  {`Formulario modificado por última vez por ${
                    selectedElement?.modifiedBy?.firstName ||
                    selectedElement?.modifiedBy?.firstSurname ||
                    selectedElement?.modifiedBy?.companies?.[0]?.companyData
                      ?.companyName
                  }
                `}
                </div>
              </Col>
            </Row>
            {!isDeletedElement && (
              <Row
                style={{ height: "auto", marginBottom: "3%" }}
                className="p-2 ml-2"
              >
                <Col md={"auto"} className="subheading">
                  Datos modificados:
                  <ul>
                    {selectedElement?.lastUpdatesTrace?.length > 0 ? (
                      selectedElement?.lastUpdatesTrace?.map((item, i) => (
                        <li key={item?._id} className="caption">
                          {`${getPropertyName(item.propertyName)}.`}
                        </li>
                      ))
                    ) : (
                      <li className="caption">Sin modificaciones</li>
                    )}
                  </ul>
                </Col>
              </Row>
            )}

            {selectedElement && !isDeletedElement && (
              <Row
                style={{ height: "auto", marginBottom: "3%" }}
                className="p-2 ml-2"
              >
                <Col md={"auto"} className="subheading">
                  Etiquetas:
                  <div className="caption">
                    <Row style={{ rowGap: "3%", columnGap: "2%" }}>
                      {selectedElement?.labels?.length > 0 ? (
                        selectedElement.labels.map((label) => (
                          <Col
                            className="drawner-details__labels"
                            md={"auto"}
                            key={label._id}
                          >
                            {label.name}
                          </Col>
                        ))
                      ) : (
                        <Col md={"auto"} className="caption">
                          Sin etiquetas
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
        <Row
          style={{ height: "auto", marginBottom: "3%" }}
          className="p-2 ml-2"
        ></Row>
      </Container>
      <Row
        style={{ height: "auto", marginBottom: "3%" }}
        className="m-2 justify-content-end"
      >
        <Col xs={"auto"} style={{ margin: "2rem 1rem" }}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            className="custom-input__button__primary-color"
            onClick={() => {
              setOpenDetailsDrawer(false);
            }}
          >
            <p className="button">Volver</p>
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

export default DetailsForm;
