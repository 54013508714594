import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Form, InputGroup } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { isDefined } from "../../utils/validations";

/**
 * The types of search to perform in {@link CustomSearchBar}.
 */
const SearchTypes = Object.freeze({
  /**
   * The search is performed in the client side.
   */
  Local: "local",
  /**
   * The search is performed in the server side executing the callback function.
   */
  Remote: "remote",
});

/**
 * The options to configure the {@link CustomSearchBar}.
 */
class Options {
  /**
   * The debounce time to wait before performing the search.
   *
   * @type {number}
   */
  #debounceTime;
  /**
   * The properties paths to search into.
   *
   * @type {string[]}
   */
  #propertiesPathsToSearchInto;
  /**
   * If the search should be strict or not.
   *
   * @type {boolean}
   */
  #strictSearch;
  /**
   * The type of search to perform. See {@link SearchTypes} for more information.
   *
   * @type {"local" | "remote"}
   */
  #searchType;
  /**
   * The maximum length of the search text.
   * 
   * @type {number | undefined}
   */
  #maxLength = undefined;

  /**
   * The options to configure the {@link CustomSearchBar}.
   *
   * @param {Object} options The options to configure the search bar.
   * @param {number} [options.debounceTime] The debounce time to wait before performing the search, by default is **500**.
   * @param {string[]} [options.propertiesPathsToSearchInto] The properties paths to search into, by default is **[]**.
   * @param {boolean} [options.strictSearch] If the search should be strict or not, by default is **false**.
   * @param {"local" | "remote"} [options.searchType] The type of search to perform, by default is **local**.
   * @param {number} [options.maxLength] The maximum length of the search text.
   * @returns {Options} The options to configure the search bar.
   */
  constructor({
    debounceTime = 500,
    propertiesPathsToSearchInto = [],
    strictSearch = false,
    searchType = SearchTypes.Local,
    maxLength,
  } = {}) {
    this.#debounceTime = debounceTime;
    this.#propertiesPathsToSearchInto = propertiesPathsToSearchInto;
    this.#strictSearch = strictSearch;
    this.#searchType = searchType;
    this.#maxLength = maxLength;
  }

  get debounceTime() {
    return this.#debounceTime;
  }

  get propertiesPathsToSearchInto() {
    return this.#propertiesPathsToSearchInto;
  }

  get strictSearch() {
    return this.#strictSearch;
  }

  get searchType() {
    return this.#searchType;
  }

  get maxLength() {
    return this.#maxLength;
  }
}

/**
 * Is a custom search bar that allows to search into a list of items, filtering them by the search text and receiving the result activating a callback, it can be configured with some useful options.
 *
 * @param {Object} props The props of the component.
 * @param {Array} props.itemsToSearch The items to search into.
 * @param {string} props.placeholder The placeholder of the search input.
 * @param {(result: { searchText: ?string, filteredItems: ?Array }) => void} props.onSearch The function to call when the search is performed.
 * @param {Options} props.options The options to configure the search bar, see {@link Options} for more information.
 * @returns The JSX element to render.
 */
const CustomSearchBar = ({
  itemsToSearch = [],
  placeholder = "Buscar",
  onSearch = () => {},
  options = new Options(),
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchCount, setSearchCount] = useState(0);

  /**
   * Handle the search change, updating the {@link searchText} state.
   *
   * @param {Object} e The event object.
   */
  const handleSearchChange = (e = {}) => {
    const value = e?.target?.value;

    if (
      isDefined(value) &&
      typeof value === typeof searchText &&
      value !== searchText
    ) {
      setSearchText(value);
    }
  };

  /**
   * Perform the search.
   *
   * @param {string} searchText The text to search into the items.
   * @returns The result of the search.
   */
  const search = (searchText = "") => {
    const result = {
      searchText: undefined,
      filteredItems: undefined,
    };
    if (options?.searchType === SearchTypes.Local) {
      if (options?.propertiesPathsToSearchInto?.length) {
        result.filteredItems = searchByProperties(
          searchText,
          options?.propertiesPathsToSearchInto,
          itemsToSearch
        );
      } else {
        result.filteredItems = searchByValue(searchText, itemsToSearch);
      }
    } else {
      result.searchText = searchText;
    }

    onSearch?.(result);
  };

  /**
   * Search the items by the properties paths.
   *
   * @param {string} searchText The text to search into the items.
   * @param {string[]} propertiesPaths The properties paths to search into, it should be separated by dots.
   * @param {Array} itemsToSearch The items to search into.
   * @returns The items properties that match with the value.
   */
  const searchByProperties = (
    searchText = "",
    propertiesPaths = [],
    itemsToSearch = []
  ) => {
    const filteredItems = itemsToSearch?.filter((item) => {
      let somePropertyContainsSearchText = false;

      for (const propertyPath of propertiesPaths) {
        let itemPropertyValue = accessProperty(item, propertyPath);

        if (typeof itemPropertyValue !== "string") {
          continue;
        }

        let searchTexToMatch = searchText;

        if (!options?.strictSearch) {
          itemPropertyValue = itemPropertyValue?.toLowerCase();
          searchTexToMatch = searchText?.toLowerCase();
        }

        if (!itemPropertyValue?.includes(searchTexToMatch)) {
          continue;
        }

        somePropertyContainsSearchText = true;
      }

      return somePropertyContainsSearchText;
    });

    return filteredItems;
  };

  /**
   * Search the items by the value.
   *
   * @param {string} searchText The text to search into the items.
   * @param {Array} itemsToSearch The items to search into.
   * @returns The items that match with the value.
   */
  const searchByValue = (searchText = "", itemsToSearch = []) => {
    const filteredItems = itemsToSearch?.filter((item) => {
      if (typeof item !== "string") {
        return false;
      }
      let valueToSearchInto = item;
      let searchTexToMatch = searchText;

      if (!options?.strictSearch) {
        valueToSearchInto = item?.toLowerCase();
        searchTexToMatch = searchText?.toLowerCase();
      }

      return valueToSearchInto?.includes(searchTexToMatch);
    });

    return filteredItems;
  };

  /**
   * Access the property of an object by the path.
   *
   * @param {Object} obj The object to access the property.
   * @param {string} path The path to access the property, it should be separated by dots.
   * @returns The value of the property or **undefined** if the property does not exist.
   */
  const accessProperty = (obj = {}, path = "") => {
    const keys = path?.split(".") ?? [];
    const value = keys.reduce((accPathValue, key) => {
      return accPathValue?.[key];
    }, obj);

    return value;
  };

  /** Perform the search when the search text changes. */
  useEffect(() => {
    const searchTimer = setTimeout(() => {
      searchCount && search(searchText);
    }, options?.debounceTime);

    setSearchCount((prevSearchCount) => prevSearchCount + 1);

    return () => {
      clearTimeout(searchTimer);
    };
  }, [searchText]);

  return (
    <Container fluid className="g-0">
      <InputGroup bsPrefix="input-group-container" style={{ padding: "8px" }}>
        <Form.Control
          bsPrefix="custom-input"
          placeholder={placeholder}
          onChange={handleSearchChange}
          maxLength={options?.maxLength}
        />
        <InputGroup.Text bsPrefix="container-icon">
          <SearchIcon fontSize="large" />
        </InputGroup.Text>
      </InputGroup>
    </Container>
  );
};

CustomSearchBar.propTypes = {
  itemsToSearch: PropTypes.array,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  options: PropTypes.instanceOf(Options),
};

export default CustomSearchBar;
export {
  Options as CustomSearchBarOptions,
  SearchTypes as CustomSearchBarSearchTypes,
};
