import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import { Button, MenuItem, Select } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../utils/regexp";
import { countries } from "../../utils/countries";
import { useOutletContext, useNavigate } from "react-router-dom";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import recaptcha from "../../assets/svg/recaptcha.png";
import recaptchaBadge from "../../assets/svg/recaptchaBadge.png";
const RegisterPhase3 = () => {
  const { handleGeneralPhases, setUserData } = useOutletContext();
  const navigate = useNavigate();

  const [actualCcountryCodeTel, setActualCountryCodeTel] = useState("");
  const [isActiveTel, setIsActiveTel] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(8, "Mínimo 8 caracteres")
      .test("upperCase", "*Al menos una letra mayúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_UPPER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase", "*Al menos una letra minúscula", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_LOWER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", "*Al menos un número", function (value) {
        if (!!value) {
          const schema = yup.string().matches(REGEXP.ONE_NUMBER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test(
        "specialChar",
        "*Al menos un caracter especial (#?!@$%^&*-)",
        function (value) {
          if (!!value) {
            const schema = yup.string().matches(REGEXP.ONE_SPECIAL_CHAR);
            return schema.isValidSync(value);
          }
          return true;
        }
      ),
    password2: yup
      .string()
      .required("*Este campo es obligatorio")
      .oneOf([yup.ref("password"), null], "*La contraseña no coincide"),
    email: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido"),
    email2: yup
      .string()
      .email("*Este campo debe ser un correo válido")
      .required("*Este campo es requerido")
      .oneOf([yup.ref("email"), null], "*El correo no coincide"),
    countryCode: yup.string().required("*Este campo es obligatorio"),
    Cel: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_NUMBERS, {
        message: "*Solo debe incluir números",
      }),
    extension: !isActiveTel
      ? yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
    Tel: !isActiveTel
      ? yup
          .string()
          .required("*Este campo es obligatorio")
          .min(7, "Mínimo 7 números")
          .max(7, "Máximo 7 números")
          .matches(REGEXP.ONLY_NUMBERS, {
            message: "*Solo debe incluir números",
          })
      : yup.string().nullable().default(""),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handlePhase3 = (data) => {
    handleGeneralPhases(2, true, true);
    setUserData((prev) => ({
      ...prev,
      email: data.email,
      password: data.password,
      phoneNumber: data.Cel,
      countryCode: data.countryCode,
      extension: data.extension,
      landlineNumber: data.Tel,
    }));
  };

  const handleBack = () => {
    handleGeneralPhases(2, false, false);
    navigate(-1);
  };

  useEffect(() => {
    if (isActiveTel) {
      setValue("extension", "");
      setValue("Tel", "");
    }
  }, [isActiveTel]);
  return (
    <Container style={{ height: "100%", padding: "7% 5%" }}>
      <Row className="display-x-large__primary-color" style={{ height: "10%" }}>
        Bienvenido a Legal AI
      </Row>
      <Row style={{ height: "10%" }}>
        <p className="body-content">
          Completa el siguiente formulario con los datos de tu empresa
        </p>
        <p className="caption">(Los campos que tengan * son obligatorios)</p>
      </Row>
      <form onSubmit={handleSubmit(handlePhase3)}>
        <Row style={{ height: "20%", margin: "3% 0" }}>
          <Container fluid>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.email
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <AlternateEmailIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("email")}
                      bsPrefix="custom-input__small"
                      placeholder="Correo electrónico*"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.email?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.email2
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <AlternateEmailIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("email2")}
                      bsPrefix="custom-input__small"
                      placeholder="Confirmar correo eletrónico*"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.email2?.message}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.password
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("password")}
                      bsPrefix="custom-input__small"
                      placeholder="Contraseña*"
                      type="password"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.password?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.password2
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("password2")}
                      bsPrefix="custom-input__small"
                      placeholder="Confirmar contraseña*"
                      type="password"
                      onPaste={(e) => e.preventDefault()}
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.password2?.message}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Select
                  className={
                    errors.countryCode
                      ? "input-group-container__no-icon__country-list__error label"
                      : "input-group-container__no-icon__country-list label"
                  }
                  {...register("countryCode")}
                  onChange={(e) => {
                    setValue("countryCode", e.target.value);
                    setActualCountryCodeTel(e.target.value);
                  }}
                >
                  {countries.map((c) => (
                    <MenuItem
                      key={c.code}
                      value={c.phone}
                      className="input-group-container__no-icon__country-list label"
                      style={{ border: "none !important" }}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                        alt={`Flag of ${c.label}`}
                      />
                      {c.label} ({c.code}) +{c.phone}
                    </MenuItem>
                  ))}
                </Select>
                <p className="caption custom-input__error">
                  {errors.countryCode?.message}
                </p>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.Cel
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <PhoneAndroidIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("Cel")}
                      bsPrefix="custom-input__small"
                      placeholder="Número de celular*"
                      type="tel"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.Cel?.message}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    placeholder="Indicativo"
                    disabled
                    value={actualCcountryCodeTel}
                    bsPrefix={
                      errors.Callsign
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={1}>
                <Form.Group className="mb-5">
                  <Form.Control
                    {...register("extension")}
                    placeholder="Ext"
                    disabled={isActiveTel}
                    bsPrefix={
                      errors.extension
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.extension?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.Tel
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <PhoneIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("Tel")}
                      disabled={isActiveTel}
                      bsPrefix="custom-input__small"
                      placeholder="Número de telefono"
                      type="tel"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.Tel?.message}
                  </div>
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-4" controlId="Terminos">
                  <Form.Check
                    type="checkbox"
                    label="No aplica"
                    className="subheading"
                    onChange={(e) => {
                      setIsActiveTel(e.target.checked);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Row>
        <Row className="justify-content-center align-items-center mb-3">
          <Col xs={2} className="text-end">
            <a href="https://policies.google.com/privacy">
              <img src={recaptchaBadge} alt="recaptcha" />
            </a>
          </Col>

          <Col xs={8} className="text-start">
            <a href="https://policies.google.com/terms">
              <img src={recaptcha} alt="recaptcha badge" />
            </a>
          </Col>
        </Row>
        <Row style={{ height: "15%" }}>
          <Col md={2}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              className="custom-input__button__secondary-color"
              onClick={handleBack}
            >
              Regresar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              type="submit"
              variant="contained"
              className="custom-input__button__primary-color"
            >
              Siguiente
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default RegisterPhase3;
