import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, IconButton, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { createRef, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import LoadingContent from "../../../../../../components/loadingContent";
import ModalAssociateCategories from "../../../../../../components/Modals/ModalAssociateCategories";
import ModalAssociateTags from "../../../../../../components/Modals/ModalAssociateTags";
import ModalDecision from "../../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalPreviewClause from "../../../../../../components/Modals/ModalPreviewClause";
import ModalWarningForm from "../../../../../../components/Modals/ModalWarningForm";
import PopoverInfo from "../../../../../../components/Popover/PopoverInfo";
import SectionTitle from "../../../../../../components/sectionTitle";
import Breadcrum from "../../../../../../parts/breadcrum";
import {
  fetchClauseCategories,
  getClauseCategoriesList,
  getClauseCategoriesStatus,
} from "../../../../../../parts/categories/clauseCategorySlice";
import {
  fetchTags,
  getStatus,
  getTagList,
} from "../../../../../../parts/manageTags/tagSlice";
import RichEditorText from "../../../../../../parts/RichEditorText";
import { getBlankSpaceIndex } from "../../../../../../utils/blankspaces";
import { CODES } from "../../../../../../utils/codes";
import {
  checkText,
  countPartErrors,
  plainTextTagsLinks,
} from "../../../../../../utils/partsCorrection";
import { CLAUSES_INFO } from "../../../../../../utils/popoverInfo";
import { REGEXP } from "../../../../../../utils/regexp";
import { setStatusFetchContractTypes } from "../contractTypes/contractTypesSlice";
import {
  fetchClauseById,
  getCurrentClause,
  getCurrentClauseStatus,
  modifyClause,
} from "./clauseSlice";
import { changeNameParts } from "./utils";
import NumeralsModule from "./clausesComponents/numeralsModule";
import ParagraphModule from "./clausesComponents/paragraphsModule";
import BlankSpaceFormModal from "../../../../../../components/Blankspaces/BlankSpaceFormModal";
import { EditOutlined } from "@mui/icons-material";
import changeDefaultBlankSpaceName from "./../../../../../../utils/changeDefaultBlankSpaceName";

const UpdateClause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const categoriesList = useSelector(getClauseCategoriesList);
  const statusCategories = useSelector(getClauseCategoriesStatus);
  const tagsList = useSelector(getTagList);
  const statusTags = useSelector(getStatus);
  const currentClause = useSelector(getCurrentClause);
  const statusCurrentClause = useSelector(getCurrentClauseStatus);

  const [clause, setClause] = useState({
    title: "",
    parts: [],
    blankSpaces: [],
    sensitiveText: "",
    subClauses: [],
    paragraphs: [],
    text: "",
    inFavor: "",
    tags: [],
    categories: [],
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalDecision, setIsOpenModalDecision] = useState(false);
  const [isOpenModalDecisionDelPart, setIsOpenModalDecisionDelPart] =
    useState(false);
  const [blankSpaceFormModalProps, setBlankSpaceFormModalProps] = useState({
    selectedBlankSpace: undefined,
    blankSpaces: [],
    open: false,
    onAgree: () => undefined,
    onDisagree: () => undefined,
    onClose: () => undefined,
  });
  const [editTitle, setEditTitle] = useState(false);
  const [isSensitiveClause, setIsSensitiveClause] = useState(false);
  const [isOpenModalCategoriesEmpty, setIsOpenModalCategoriesEmpty] =
    useState(false);

  const [tagsSelected, setTagsSelected] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [partDeleted, setPartDeleted] = useState(null);

  const [partsErrorsModal, setPartsErrorsModal] = useState(false);

  const [currentData, setCurrentData] = useState({});

  //Unauthorized
  const [isOpenModalUnauthorized, setIsOpenModalUnauthorized] = useState(false);
  const [responseDataUnauthorized, setResponseDataUnauthorized] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  const parts = {
    0: "Parte A",
    1: "Parte B",
    2: "Parte C",
    3: "Parte D",
  };

  const schema = yup.object().shape({
    title: yup.string().required("*Este campo es obligatorio"),
    parts: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.ONLY_LETTERS, {
            message: "*Solo debe incluir letras",
          }),
      })
    ),
    sensitiveClauseText: !isSensitiveClause
      ? yup.string().nullable().default("")
      : yup
          .string()
          .required("*Este campo es obligatorio")
          .matches(REGEXP.LETTERS_COMMAS, {
            message: "*Solo debe incluir letras",
          }),

    inFavor: yup
      .string("*Este campo debe ser un numero")
      .required("*Este campo es obligatorio"),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parts: [{ title: "" }, { title: "" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: partsFields,
    append: partsAppend,
    remove: partsRemove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parts", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const clauseRichEditorTextRef = useRef();
  const subClauseRichEditorTextRef = useRef([]);
  const paragraphRichEditorTextRef = useRef([]);

  const addTextInContentsNumerals = () => {
    const subClauses = getValues("subClauses");
    subClauses.forEach((subClause, idx) => {
      const parts = getValues("parts").map((part) => part.title);
      const text = changeNameParts(subClause.text, parts);
      subClauseRichEditorTextRef.current[idx]?.current?.setEditorContents(
        plainTextTagsLinks(text)
      );
      setValue(`subClauses.${idx}`, {
        ...subClause,
        isImported: false,
        text,
      });
      setClause((clause) => ({
        ...clause,
        subClauses: clause.subClauses.map((subclause, index) => {
          if (index === idx) return { ...subclause, isImported: false, text };
          return subclause;
        }),
      }));
    });
  };

  const addTextInContentsParagrahs = () => {
    const paragraphs = getValues("paragraphs");
    paragraphs.forEach((paragraph, idx) => {
      const parts = getValues("parts").map((part) => part.title);
      const text = changeNameParts(paragraph.text, parts);
      paragraphRichEditorTextRef.current[idx].current?.setEditorContents(
        plainTextTagsLinks(text)
      );
      setValue(`paragraphs.${idx}`, {
        ...paragraph,
        text,
      });
      setClause((clause) => ({
        ...clause,
        paragraphs: clause.paragraphs.map((paragraph, index) => {
          console.log("asd::: ", paragraph);
          if (index === idx) return { ...paragraph, isImported: false, text };
          return paragraph;
        }),
      }));
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCategories === "fetch") {
          dispatch(fetchClauseCategories());
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCategories, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusTags === "fetch") {
          dispatch(fetchTags("CLAUSES"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusTags, dispatch]);

  useEffect(() => {
    if (
      statusCategories === "loading" ||
      statusTags === "loading" ||
      statusCurrentClause === "loading"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [statusCategories, statusTags, statusCurrentClause]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusCurrentClause === "fetch") {
          dispatch(fetchClauseById(params.id));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [statusCurrentClause, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    if (currentClause) {
      setValue("title", currentClause.title);
      const prevParts = [
        { title: currentClause.parts.partA },
        { title: currentClause.parts.partB },
      ];
      if (currentClause.parts.partC) {
        prevParts.push({ title: currentClause.parts.partC });
      }
      if (currentClause.parts.partD) {
        prevParts.push({ title: currentClause.parts.partD });
      }

      reset({
        parts: prevParts,
        subClauses: buildPrevNumerals(currentClause.subclauses),
        paragraphs: buildPrevParagraphs(currentClause.paragraphs),
      });

      const parts = prevParts.map((part) => part.title);
      const text = changeNameParts(currentClause.text, parts);

      clauseRichEditorTextRef.current.setEditorContents(
        plainTextTagsLinks(text)
      );
      setValue("clauseText", text);
      buildPrevClauseBlankSpaces(currentClause.blankspaces);

      setIsSensitiveClause(currentClause.isSensitive);
      setValue("sensitiveClauseText", currentClause.sensitiveText);

      setCategoriesSelected(currentClause.categories);
      setTagsSelected(currentClause.labels);
      setValue("inFavor", currentClause.inFavor);
    }
    setIsLoading(false);
  }, [currentClause]);

  const buildPrevNumerals = (numerals) => {
    const numeralsArr = [];
    for (const [idx, item] of numerals.entries()) {
      const obj = {
        title: item?.subclause?.title,
        text: item?.subclause?.text,
        blankSpaces: buildPrevNumeralsBlankSpaces(item.subclause.blankspaces),
        isImported: true,
        numerals: buildPrevSubNumerals(item.subclause.childs),
        id: item.subclause._id,
        _id: item.subclause._id,
      };
      numeralsArr.push(obj);
      setClause((clause) => ({
        ...clause,
        subClauses: [...clause.subClauses, obj],
      }));
      subClauseRichEditorTextRef.current[idx] =
        subClauseRichEditorTextRef.current[idx] ?? createRef();
    }
    return numeralsArr;
  };

  const buildPrevParagraphs = (paragraphs) => {
    const paragraphsArr = [];
    for (const [idx, item] of paragraphs.entries()) {
      const blankSpacesParagraphs =
        item?.paragraph?.blankspaces || item?.paragraph?.blankSpaces || [];
      const obj = {
        title: "",
        text: item.paragraph.text,
        isImported: true,
        blankSpaces: blankSpacesParagraphs.map((blankSpace) => {
          return {
            id: blankSpace.index,
            name: changeDefaultBlankSpaceName(blankSpace.name),
            description: blankSpace?.description ?? "",
          };
        }),
      };
      paragraphsArr.push(obj);
      setClause((clause) => ({
        ...clause,
        paragraphs: [...clause.paragraphs, obj],
      }));
      paragraphRichEditorTextRef.current[idx] =
        paragraphRichEditorTextRef.current[idx] ?? createRef();
    }
    return paragraphsArr;
  };

  const buildPrevClauseBlankSpaces = (blankspaces) => {
    setClause((prevClause) => ({
      ...prevClause,
      blankSpaces: blankspaces.map((blankSpace) => {
        clauseRichEditorTextRef?.current?.setBlankSpacesValues(
          blankSpace.index
        );

        return {
          id: blankSpace.index,
          name: changeDefaultBlankSpaceName(blankSpace.name),
          description: blankSpace?.description ?? "",
        };
      }),
    }));
  };

  const buildPrevNumeralsBlankSpaces = (blankspaces) => {
    const blankSpacesArr = [];
    for (const blankSpace of blankspaces) {
      blankSpacesArr.push({
        id: blankSpace.index,
        name: changeDefaultBlankSpaceName(blankSpace.name),
        description: blankSpace?.description ?? "",
      });
    }
    return blankSpacesArr;
  };

  const buildPrevSubNumerals = (subNumerals) => {
    const subNumeralsArr = [];
    for (const item of subNumerals) {
      const obj = {
        title: item.title,
        text: item.text,
        blankSpaces: buildPrevNumeralsBlankSpaces(item.blankspaces),
        isImported: true,
      };
      subNumeralsArr.push(obj);
    }
    return subNumeralsArr;
  };

  const handleClose = () => {
    setIsOpenModal(false);
    navigate(-1);
  };

  const handleCloseModalCategoriesEmpty = () => {
    setIsOpenModalCategoriesEmpty(false);
  };

  const handleTagsSelected = (tags) => {
    setTagsSelected(tags);
  };

  const handleCategoriesSelected = (categories) => {
    setCategoriesSelected(categories);
  };

  const handleBlankSpaces = (blankSpace) => {
    let blankSpaceId = getBlankSpaceIndex(clause.blankSpaces);
    let item = {
      id: blankSpaceId,
      name: `ESPACIO #${blankSpaceId}`,
      description: "",
    };
    setClause((clause) => ({
      ...clause,
      blankSpaces: [...clause.blankSpaces, item],
    }));
    clauseRichEditorTextRef.current.addBlankSpace(clause.blankSpaces);
  };

  const removeBlankSpace = (index) => {
    setClause((clause) => ({
      ...clause,
      blankSpaces: clause.blankSpaces.filter(
        (blankSpace) => blankSpace.id !== index
      ),
    }));
    clauseRichEditorTextRef.current.removeBlankSpace(index);
  };

  /**
   * Edits a blank space
   * @param {Object} editedBlankSpace The edited blank space
   * @param {number} editedBlankSpace.id The id of the blank space
   * @param {string} editedBlankSpace.name The name of the blank space
   * @param {string} editedBlankSpace.description The description of the blank space
   * @param {any} editedBlankSpace.value The value of the blank space
   */
  const editBlankSpace = (editedBlankSpace) => {
    const blankSpacesCopy = [...clause.blankSpaces];
    const editedBlankSpaceIndex = blankSpacesCopy.findIndex(
      (bs) => bs.id === editedBlankSpace.id
    );
    blankSpacesCopy[editedBlankSpaceIndex] = {
      ...blankSpacesCopy[editedBlankSpaceIndex],
      ...editedBlankSpace,
    };
    setClause((clause) => ({
      ...clause,
      blankSpaces: blankSpacesCopy,
    }));
    clauseRichEditorTextRef.current.editBlankSpace(
      editedBlankSpace.id,
      editedBlankSpace
    );
  };

  const handleCloseModalDesicion = () => {
    setIsOpenModalDecision(false);
  };

  const handleDisagreeModalDesicion = () => {
    handleCloseModalDesicion();
  };

  const handleAgreeModalDesicion = () => {
    navigate(-1);
  };

  const handleCloseModalDesicionDelPart = () => {
    setIsOpenModalDecisionDelPart(false);
  };

  const handleDisagreeModalDesicionDelPart = () => {
    handleCloseModalDesicionDelPart();
  };

  const handleAgreeModalDesicionDelPart = () => {
    partsRemove(partDeleted);
    handleCloseModalDesicionDelPart();
  };

  const handleDeletePart = async (idx) => {
    setPartDeleted(idx);
    setIsOpenModalDecisionDelPart(true);
  };

  const handleOpenBlankSpaceFormModal = (blankSpace) => {
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: true,
      selectedBlankSpace: blankSpace,
      blankSpaces: clause.blankSpaces,
      onAgree: (blankSpace) => {
        editBlankSpace(blankSpace);
        handleCloseBlankSpaceFormModal();
      },
      onDisagree: handleCloseBlankSpaceFormModal,
      onClose: handleCloseBlankSpaceFormModal,
    });
  };

  const handleCloseBlankSpaceFormModal = () => {
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: false,
    });
  };

  const buildNumerals = (numerals) => {
    const newNumerals = [];
    for (const [index, subClause] of numerals.entries()) {
      const clauseNumeral = {
        index: index + 1,
        subClauseId: null,
        newSubclause: {
          ...subClause,
          title: subClause.title,
          text: subClause.text,
          childs: [],
          blankspaces: buildBlankspaces(clause.subClauses[index].blankSpaces),
        },
      };
      for (const [subNumeralIdx, subNumeral] of subClause.numerals.entries()) {
        const numeral = {
          index: subNumeralIdx + 1,
          title: subNumeral.title,
          text: subNumeral.text,
          blankspaces: buildBlankspaces(
            clause.subClauses[index].numerals[subNumeralIdx].blankSpaces
          ),
        };
        clauseNumeral.newSubclause.childs.push(numeral);
      }
      newNumerals.push(clauseNumeral);
    }

    return newNumerals;
  };

  const buildParagraphs = (paragraphs) => {
    const newParagraphs = [];
    for (const [index, paragraph] of paragraphs.entries()) {
      const blankSpacesParagraphs =
        clause?.paragraphs?.[index]?.blankspaces ||
        clause?.paragraphs?.[index]?.blankSpaces ||
        [];
      const clauseParagraph = {
        index: index + 1,
        text: paragraph.text,
        blankspaces: buildBlankspaces(blankSpacesParagraphs),
      };
      newParagraphs.push(clauseParagraph);
    }
    return newParagraphs;
  };

  const buildBlankspaces = (blankSpaces) => {
    const newBlankspaces = [];
    for (const [index, blankSpace] of blankSpaces.entries()) {
      const clauseBlankspaces = {
        index: index + 1,
        name: blankSpace.name,
        description: blankSpace?.description ?? "",
        value: "",
      };
      newBlankspaces.push(clauseBlankspaces);
    }
    return newBlankspaces;
  };

  const buildCategories = (categories) => {
    const newCategories = [];
    for (const category of categories) {
      newCategories.push(category._id);
    }
    return newCategories;
  };

  const buildTags = (tags) => {
    const newTags = [];
    for (const tag of tags) {
      newTags.push(tag._id);
    }
    return newTags;
  };

  const handleUpdateClause = async (data) => {
    try {
      if (categoriesSelected.length === 0) {
        setIsOpenModalCategoriesEmpty(true);
        setIsLoading(false);
        return;
      }
      const numerals = buildNumerals(data.subClauses);
      const paragraphs = buildParagraphs(data.paragraphs);
      const categories = buildCategories(categoriesSelected);
      const tags = buildTags(tagsSelected);
      const blankspaces = buildBlankspaces(clause.blankSpaces);

      const obj = {
        clauseContent: {
          title: data.title,
          parts: {
            type: "",
            partA: data.parts[0].title,
            partB: data.parts[1].title,
            partC: data.parts[2]?.title || null,
            partD: data.parts[3]?.title || null,
          },
          text: data.clauseText,
          subclauses: numerals,
          paragraphs: paragraphs,
          categories: categories,
          labels: tags,
          inFavor: data.inFavor,
          isSensitive: isSensitiveClause,
          sensitiveText: data.sensitiveClauseText,
          blankspaces,
        },
        clauseId: params.id,
        timeZone: new Date().getTimezoneOffset() / -60,
      };
      setCurrentData(obj);

      let errorParts = handleCheckParts(obj.clauseContent);

      if (errorParts > 0) {
        setPartsErrorsModal(true);
        setIsLoading(false);
        return;
      }

      dispatchUpdateClause(obj);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatchUpdateClause = async (obj) => {
    try {
      setIsLoading(true);
      const responseDispatch = await dispatch(modifyClause(obj)).unwrap();
      if (responseDispatch.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseDataUnauthorized(responseDispatch);
        setIsOpenModalUnauthorized(true);
        return;
      }
      if (
        responseDispatch.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseDispatch.data.success
      ) {
        dispatch(setStatusFetchContractTypes({ status: "fetch" }));
      }
      setResponseData(responseDispatch);
      setIsOpenModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIsClausesSensitive = (e) => {
    let checked = e.target.checked;
    setIsSensitiveClause(checked);
  };

  const handleDeleteTag = (tag) => {
    setTagsSelected(tagsSelected.filter((t) => t !== tag));
  };

  const handleDeleteCategory = (category) => {
    setCategoriesSelected(categoriesSelected.filter((c) => c !== category));
  };

  const handleCheckParts = (clause) => {
    let partsErrorsArray = [];
    let errorsClause = checkText({
      text: clause.clauseText ? clause.clauseText : clause.text,
    });

    partsErrorsArray.push(...errorsClause);

    for (const subclause of clause.subclauses) {
      let errorsSubclause = checkText(subclause.newSubclause);
      partsErrorsArray.push(...errorsSubclause);

      for (const numeral of subclause.newSubclause.childs) {
        let errorsNumeral = checkText(numeral);
        partsErrorsArray.push(...errorsNumeral);
      }
    }

    for (const paragraph of clause.paragraphs) {
      let errorsParagraph = checkText(paragraph);
      partsErrorsArray.push(...errorsParagraph);
    }
    return countPartErrors(partsErrorsArray);
  };
  useEffect(() => {
    if (currentClause) {
      const parts = getValues("parts").map((part) => part.title);
      const textClause = getValues("clauseText");
      const text = changeNameParts(textClause, parts);
      clauseRichEditorTextRef?.current?.setEditorContents(
        plainTextTagsLinks(text)
      );
      setValue("clauseText", text);
    }

    addTextInContentsNumerals();
    addTextInContentsParagrahs();
  }, [isUpdate]);

  const navigateToSection = () => {
    setTimeout(() => {
      const section = document.getElementById("partsSection");
      if (section) {
        section.scrollIntoView({ behavior: "auto", block: "start" });
      }
    }, 100);
  };

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"} id="partsSection">
        <SectionTitle title={"Actualizar cláusula"} />
        <IconButton aria-label="delete"></IconButton>
      </Row>
      <br />
      <br />
      <Row>
        <Form onSubmit={handleSubmit(handleUpdateClause)}>
          <Col
            xs={{ span: 6, offset: 0 }}
            className="create-clause-coachmark-step-1"
          >
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Título de la cláusula*
                <PopoverInfo section={CLAUSES_INFO.clauseTitle} />
              </Form.Label>
              <Form.Control
                {...register("title", {
                  onChange: (e) => setValue("title", e.target.value.trimLeft()),
                  setValueAs: (value) => value.trim(),
                })}
                placeholder="Título"
                bsPrefix={
                  errors.title
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
              />
              <div className="caption custom-input__error">
                {errors.title?.message}
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label className="heading mb-4">
                Rol contractual*
                <PopoverInfo section={CLAUSES_INFO.partsDefinition} />
              </Form.Label>
              {partsFields.map((item, idx) => {
                return (
                  <Row key={item?.id}>
                    <Col xs={8}>
                      <Form.Control
                        {...register(`parts.${idx}.title`, {
                          onChange: (e) => {
                            setValue(`parts.${idx}.title`, e.target.value);
                          },
                        })}
                        placeholder={`${parts[idx]}`}
                        bsPrefix={
                          errors?.parts?.[idx]?.title
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        onBlur={(e) => {
                          setIsUpdate((oldState) => !oldState);
                          navigateToSection();
                        }}
                      />
                    </Col>
                    {idx > 1 && (
                      <Col xs={1}>
                        <Button
                          variant="contained"
                          className="custom-input__button__primary-color"
                          onClick={() => handleDeletePart(idx)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </Button>
                      </Col>
                    )}
                    <div className="caption custom-input__error  mb-4">
                      {errors?.parts?.[idx]?.title?.message}
                    </div>
                  </Row>
                );
              })}
            </Form.Group>
            {partsFields.length < 4 && (
              <Row xs={"auto"} style={{ justifyContent: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className="custom-input__button__secondary-color"
                  onClick={() => {
                    partsAppend({
                      title: "",
                    });
                  }}
                >
                  Agregar Parte
                </Button>
              </Row>
            )}
          </Col>
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Form.Group className="mb-5 create-clause-coachmark-step-2">
            <Row className="mb-2">
              <Col xs={10}>
                <Row style={{ marginBottom: "3%" }}>
                  <Col>
                    <Form.Label className="heading mb-4">
                      1. Texto de la cláusula*
                      <PopoverInfo
                        section={CLAUSES_INFO.clauseText}
                        placement="top"
                      />
                    </Form.Label>
                  </Col>
                  <Col xs={"auto"}>
                    <Row>
                      {getValues("parts").map((item, idx) => {
                        if (watch(`parts.${idx}.title`)) {
                          return (
                            <Col
                              className="create-clause-coachmark-step-3"
                              xs={"auto"}
                              key={`${JSON.stringify(item)}${idx}`}
                            >
                              <Button
                                onClick={() =>
                                  clauseRichEditorTextRef.current.addPart(
                                    item.title,
                                    idx
                                  )
                                }
                                variant="contained"
                                className="custom-input__button__secondary-color"
                              >
                                {item.title}
                              </Button>
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <Col
                        className="create-clause-coachmark-step-4"
                        xs={"auto"}
                      >
                        <Button
                          variant="contained"
                          startIcon={<AddIcon fontSize="large" />}
                          className="custom-input__button__secondary-color"
                          onClick={() =>
                            handleBlankSpaces(clause.blankSpaces.length + 1)
                          }
                        >
                          Espacio en blanco
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ maxWidth: "120rem" }}>
                  <Col xs={12}>
                    <Controller
                      control={control}
                      name="clauseText"
                      render={({ field: { onChange, onBlur } }) => (
                        <RichEditorText
                          ref={clauseRichEditorTextRef}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          customClassName={
                            errors.clauseText
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                          deleteBlankSpace={(id) => {
                            setClause((clause) => ({
                              ...clause,
                              blankSpaces: clause.blankSpaces.filter(
                                (blankSpace) => blankSpace.id !== id
                              ),
                            }));
                          }}
                        />
                      )}
                    />
                    <div className="caption custom-input__error">
                      {errors.clauseText?.message}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <Container>
                  <Row>
                    <p className="label">
                      {clause.blankSpaces.length} Espacios en blanco:
                    </p>
                  </Row>
                  {clause.blankSpaces.map((item) => {
                    return (
                      <Row xs={"auto"} key={item?.id}>
                        <Col
                          xs={"auto"}
                          className="d-flex align-items-center justify-content-center "
                        >
                          <p className="heading__primary-color text-break">
                            {changeDefaultBlankSpaceName(item.name)}
                          </p>
                        </Col>
                        <Col xs={"auto"}>
                          <Stack direction="row">
                            <IconButton
                              onClick={() => removeBlankSpace(item.id)}
                            >
                              <DeleteOutlineIcon
                                className="heading__primary-color"
                                fontSize="large"
                              />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleOpenBlankSpaceFormModal(item)
                              }
                            >
                              <EditOutlined
                                className="heading__primary-color"
                                fontSize="large"
                              />
                            </IconButton>
                          </Stack>
                        </Col>
                      </Row>
                    );
                  })}
                </Container>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <NumeralsModule
              control={control}
              register={register}
              getValues={getValues}
              watch={watch}
              errors={errors}
              setClause={setClause}
              clause={clause}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              setValue={setValue}
              subClauseRichEditorTextRef={subClauseRichEditorTextRef}
              isUpdate={isUpdate}
            />
          </Form.Group>
          <Divider />
          <br />
          <br />
          <Form.Group style={{ justifyContent: "right" }} className="mb-5">
            <ParagraphModule
              control={control}
              getValues={getValues}
              watch={watch}
              errors={errors}
              clause={clause}
              setClause={setClause}
              paragraphRichEditorTextRef={paragraphRichEditorTextRef}
            />
          </Form.Group>
          <Divider />
          <br />
          <br />

          <Form.Group className="mb-5 create-clause-coachmark-step-6">
            <Form.Label className="heading mb-4">
              Cláusula sensible
              <PopoverInfo section={CLAUSES_INFO.sensitiveClause} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSensitiveClause}
                        onChange={(e) => handleIsClausesSensitive(e)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                          color: "#00374f",
                          "&.Mui-checked": {
                            color: "#00374f",
                          },
                        }}
                      />
                    }
                    label={<p className="body-content">Sensible</p>}
                  />
                </FormGroup>
              </Col>
              {isSensitiveClause && (
                <Col>
                  <Form.Control
                    {...register("sensitiveClauseText")}
                    bsPrefix={
                      errors.sensitiveClauseText
                        ? "input-group-container__no-icon-error label"
                        : "input-group-container__no-icon label"
                    }
                  />
                  <div className="caption custom-input__error">
                    {errors.sensitiveClauseText?.message}
                  </div>
                </Col>
              )}
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Añadir Categorías*
              <PopoverInfo section={CLAUSES_INFO.categories} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateCategories
                  categories={categoriesList}
                  handleCategoriesSelected={handleCategoriesSelected}
                  previousCategoriesSelected={categoriesSelected}
                />
              </Col>
              {categoriesSelected.map((category, idx) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={category?._id}
                  >
                    <span className="caption custom-badges__secundary">
                      {category.name + " "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteCategory(category)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>

          <Form.Group xs={"auto"} className="mb-5">
            <Form.Label className="heading mb-4">
              A favor de*
              <PopoverInfo section={CLAUSES_INFO.inFavor} />
            </Form.Label>
            <Row xs={"auto"}>
              <Form.Select
                className="label mb-1"
                defaultValue=""
                bsPrefix={
                  errors.inFavor
                    ? "input-group-container__no-icon-error label"
                    : "input-group-container__no-icon label"
                }
                {...register("inFavor")}
              >
                <option className="label" value="" disabled>
                  Seleccione uno
                </option>
                <option className="label" value="Balanceada">
                  Balanceada
                </option>
                {getValues("parts").map((item, idx) => {
                  if (watch(`parts.${idx}.title`)) {
                    return (
                      <option
                        key={`${JSON.stringify(item)}${idx}`}
                        className="label"
                        value={item.title}
                      >
                        {item.title}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </Form.Select>
              <p className="caption custom-input__error">
                {errors.inFavor?.message}
              </p>
            </Row>
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="heading mb-4">
              Etiquetas
              <PopoverInfo section={CLAUSES_INFO.tags} />
            </Form.Label>
            <Row>
              <Col xs={"auto"}>
                <ModalAssociateTags
                  tags={tagsList}
                  handleTagsSelected={handleTagsSelected}
                  previousTagsSelected={tagsSelected}
                  type="CLAUSES"
                />
              </Col>
              {tagsSelected.map((tag) => {
                return (
                  <Col
                    xs={"auto"}
                    style={{ display: "flex", alignItems: "center" }}
                    key={tag?._id}
                  >
                    <span className="caption custom-badges__disabled">
                      {tag.name + " "}{" "}
                      <ClearIcon
                        className="custom-badges__delete-button"
                        onClick={() => handleDeleteTag(tag)}
                      />
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>

          <Divider />
          <br />
          <br />
          <Row xs={"auto"}>
            <Col>
              <ModalPreviewClause clause={clause} hookFormInfo={watch} />
            </Col>
            <Col>
              <Button
                variant="contained"
                startIcon={<DeleteOutlineIcon fontSize="large" />}
                className="custom-input__button__secondary-color"
                onClick={() => setIsOpenModalDecision(true)}
              >
                Descartar
              </Button>
            </Col>
            <Col>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardOutlinedIcon fontSize="large" />}
                className="custom-input__button__primary-color"
              >
                Terminar y Actualizar
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <Divider />
        </Form>
      </Row>

      <ModalWarningForm
        title={"Advertencia"}
        description={
          "No se han asociado categorías a la clausula, estas son obligatorias"
        }
        open={isOpenModalCategoriesEmpty}
        onClose={handleCloseModalCategoriesEmpty}
      />

      <ModalInfo
        title={"Cambios guardados"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />

      <ModalDecision
        title={"Eliminar parte"}
        message={"¿Está seguro que desea eliminar la parte seleccionada?."}
        open={isOpenModalDecisionDelPart}
        onClose={handleCloseModalDesicionDelPart}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicionDelPart}
        handleDisagree={handleDisagreeModalDesicionDelPart}
      />

      <ModalDecision
        title={"Cancelar"}
        message={
          "¿Está seguro que desea cancelar? Los cambios realizados se perderán."
        }
        open={isOpenModalDecision}
        onClose={handleCloseModalDesicion}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={handleAgreeModalDesicion}
        handleDisagree={handleDisagreeModalDesicion}
      />

      <ModalInfo
        title="Sin permisos suficientes"
        onClose={() => setIsOpenModalUnauthorized(false)}
        open={isOpenModalUnauthorized}
        responseData={responseDataUnauthorized}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Errores gramaticales encontrados"}
        message={
          <Container fluid className="caption">
            <Row>
              Hemos detectado algunas inconsistencias gramaticales sin corregir,
              ¿seguro quieres continuar?
            </Row>
            <br />
          </Container>
        }
        open={partsErrorsModal}
        onClose={() => setPartsErrorsModal(false)}
        agreeText="Aceptar"
        disagreeText="Cancelar"
        handleAgree={() => {
          setPartsErrorsModal(false);
          dispatchUpdateClause(currentData);
        }}
        handleDisagree={() => {
          setPartsErrorsModal(false);
        }}
      />
      <BlankSpaceFormModal {...blankSpaceFormModalProps} />
    </Container>
  );
};

export default UpdateClause;
