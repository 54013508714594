import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Breadcrum from "../../../../../../parts/breadcrum";
import SectionTitle from "../../../../../../components/sectionTitle";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedTemplate,
  setSelectedTemplate,
  setStatusTemplete,
} from "./templeteSlice";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Controller, useForm } from "react-hook-form";
import RichEditorText from "../../../../../../parts/RichEditorText";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REGEXP } from "../../../../../../utils/regexp";
import {
  getBlankSpaceIndex,
  replaceBlankSpace,
} from "../../../../../../utils/blankspaces";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModalUploadDocs from "../../../../../../components/Modals/ModalUploadDocs";
import { convertToHtml } from "mammoth/mammoth.browser";
import ModalInfo from "../../../../../../components/Modals/modalInfo";
import ModalNameAndTags from "../../../../../../components/Modals/ModalNameAndTags";
import LoadingContent from "../../../../../../components/loadingContent";
import { CODES } from "../../../../../../utils/codes";
import { setStatusPrederminatedTexts } from "../pretederminatedTexts/prederminatedTextsSlice";
import { ImportPredeterminedText } from "../../../../../../parts/associations/ImportPredeterminedText";
import {
  createOtherDocumentTemplete,
  findTemplateById,
  updateOtherDocumentTemplete,
} from "../../../../../../services/documentTemplates/templeteServices";
import { createPrederminatedTextService } from "../../../../../../services/predeterminatedTexts/predeterminatedTextsService";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { ImportClauseModal } from "../../../../../../parts/associations/ImportClauseModal";
import { generateClauseHTML } from "../../../../../../utils/clausesUtils";
import ReactDOMServer from "react-dom/server";
import BlankSpaceFormModal from "../../../../../../components/Blankspaces/BlankSpaceFormModal";
import { EditOutlined } from "@mui/icons-material";
import changeDefaultBlankSpaceName from "../../../../../../utils/changeDefaultBlankSpaceName";

const UpdateTempleteDocument = () => {
  //States

  const [template, setTemplate] = useState({
    blankSpaces: [],
  });
  const [anchorElImport, setAnchorElImport] = useState(null);
  const [selectedText, setSelectedText] = useState("");

  const [documentData, setDocumentData] = useState({});
  const [isOpenModalUploadDocument, setIsOpenModalUploadDocument] =
    useState(false);

  const [isOpenModalSaveDocument, setIsOpenModalModalSaveDocument] =
    useState(false);

  const [isOpenModalNoTextSelected, setIsOpenModalNoTextSelected] =
    useState(false);
  const [isOpenModalImportClauseText, setIsOpenModalImportClauseText] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [documentAdditionalInfoToSave, setDocumentAdditionalInfoToSave] =
    useState({ type: "", placeholder: "" });

  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseDataModalInfo, setResponseDataModalInfo] = useState({});
  const [isOpenModalImportText, setIsOpenModalImportText] = useState(false);
  const [blankSpaceFormModalProps, setBlankSpaceFormModalProps] = useState({
    selectedBlankSpace: undefined,
    blankSpaces: [],
    open: false,
    onAgree: () => undefined,
    onDisagree: () => undefined,
    onClose: () => undefined,
  });

  const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
  //Redux

  const dispatch = useDispatch();
  const currentTemplate = useSelector(getSelectedTemplate);

  //Refs
  const templateRichEditorTextRef = useRef();

  //Const and variables
  const isOpenImportMenu = Boolean(anchorElImport);

  //Router

  const navigate = useNavigate();
  const { id } = useParams();

  //Form schema

  const schema = yup.object().shape({
    templateText: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.RICH_EDITOR_CONTENT, {
        message: "*Este campo no puede estar vacío",
      }),
  });

  //Form

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  //Functions

  const handleClickOpenImportMenu = (event) => {
    setAnchorElImport(event.currentTarget);
  };

  const handleBlankSpaces = () => {
    let blankSpaceId = getBlankSpaceIndex(template.blankSpaces);
    let item = {
      id: blankSpaceId,
      name: `ESPACIO #${blankSpaceId}`,
      description: "",
    };
    setTemplate((template) => ({
      ...template,
      blankSpaces: [...template.blankSpaces, item],
    }));
    templateRichEditorTextRef.current.addBlankSpace(template.blankSpaces);
  };

  const addNewBlankSpace = (blankspace) => {
    setTemplate((template) => ({
      ...template,
      blankSpaces: [...template.blankSpaces, blankspace],
    }));
    templateRichEditorTextRef.current.addBlankspacesArray([blankspace]);
  };

  const addNewText = (text) => {
    setTemplate((template) => ({
      ...template,
    }));

    templateRichEditorTextRef.current.addText(text);
  };

  const removeBlankSpace = (index) => {
    setTemplate((template) => ({
      ...template,
      blankSpaces: template.blankSpaces.filter(
        (blankSpace) => blankSpace.id !== index
      ),
    }));
    templateRichEditorTextRef.current.removeBlankSpace(index);
  };

  const handleSubmitTemplateInfo = (data) => {
    setDocumentAdditionalInfoToSave({
      type: "template",
      placeholder: "Escriba el nombre de la plantilla",
      text: "Por favor, escriba un nombre para esta plantilla",
    });
    setIsOpenModalModalSaveDocument(true);
  };

  const handleSelectionChange = ({ event, selection }) => {
    setSelectedText(selection || "");
  };

  const filterOutImages = (htmlString) => {
    return htmlString.replace(/<img[^>]*>/g, "");
  };

  const handleCreateOtherDocument = async (documentName, tagsSelected) => {
    setIsOpenModalModalSaveDocument(false);
    if (documentAdditionalInfoToSave.type === "other") {
      await handleCreateText(documentName, tagsSelected);
    } else {
      await handleCreateTemplate(documentName, tagsSelected);
    }
  };

  const handleCreateText = async (documentName, tagsSelected) => {
    const { text, blankspaces } = updateStringBlankSpaces(selectedText);

    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const createTextObject = {
      name: documentName,
      text,
      blankSpaces: blankspaces,
      labels: tagsSelected.map((tag) => tag._id),
      company,
      corporateUnit,
      createdBy: userId,
    };

    setIsLoading(true);
    const createTextService = await createPrederminatedTextService(
      createTextObject
    );
    if (
      createTextService.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      createTextService?.data?.success
    ) {
      dispatch(setStatusPrederminatedTexts());
    }
    setIsOpenModalInfo(true);
    setResponseDataModalInfo(createTextService);

    setIsLoading(false);
  };

  const handleCreateTemplate = async (documentName, tagsSelected) => {
    const company = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const createTextObject = {
      name: documentName,
      text: getValues("templateText"),
      blankspaces: template.blankSpaces,
      labels: tagsSelected.map((tag) => tag._id),
      company,
      corporateUnit,
      createdBy: userId,
    };

    setIsLoading(true);
    const createTextService = await createOtherDocumentTemplete(
      createTextObject
    );
    if (
      createTextService.status === CODES.COD_RESPONSE_HTTP_CREATED &&
      createTextService?.data?.success
    ) {
      dispatch(setStatusTemplete({ status: "fetch" }));
    }
    setIsOpenModalInfo(true);
    setResponseDataModalInfo(createTextService);

    setIsLoading(false);
  };

  const handleUpdateTemplate = async (data) => {
    setDocumentAdditionalInfoToSave({
      type: "template",
    });
    const updateTemplatetObject = {
      text: data.templateText,
      blankspaces: template.blankSpaces,
      otherDocumentTemplateId: id,
    };

    setIsLoading(true);
    const updateTemplateService = await updateOtherDocumentTemplete(
      updateTemplatetObject
    );
    if (
      updateTemplateService.status === CODES.COD_RESPONSE_HTTP_OK &&
      updateTemplateService?.data?.success
    ) {
      dispatch(setStatusTemplete({ status: "fetch" }));
    }
    setIsOpenModalInfo(true);
    setResponseDataModalInfo(updateTemplateService);

    setIsLoading(false);
  };

  const updateStringBlankSpaces = (inputString) => {
    const regex = /¬ESPACIO #(\d+)¬/g;
    const resultArray = [];
    const blankSpacesArray = [];
    let updatedString = "";
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(inputString)) !== null) {
      const espacioNumber = parseInt(match[1], 10);
      resultArray.push(espacioNumber);

      const replacement = `¬ESPACIO #${resultArray.length}¬`;
      let newBlankSpace = {
        id: resultArray.length,
        name: `ESPACIO #${resultArray.length}`,
        description: "",
      };
      blankSpacesArray.push(newBlankSpace);
      const substring = inputString.substring(lastIndex, match.index);
      updatedString += substring + replacement;

      lastIndex = regex.lastIndex;
    }

    updatedString += inputString.substring(lastIndex);
    return { text: updatedString, blankspaces: blankSpacesArray };
  };

  const handleAddPredeterminedTextToTemplate = (selectedTextList) => {
    const regex = /¬ESPACIO #(\d+)¬/g;
    let currentBlankspacesLenght = template.blankSpaces.length;
    selectedTextList.forEach((predeterminedText) => {
      let inputString = predeterminedText.text;
      let updatedString = "";
      let lastIndex = 0;
      let match;
      let arrayOfPreviousNumbers = [];

      while ((match = regex.exec(inputString)) !== null) {
        const espacioNumber = parseInt(match[1], 10);

        currentBlankspacesLenght++;

        const replacement = `¬ESPACIO #${currentBlankspacesLenght}¬`;
        let newBlankSpace = {
          id: currentBlankspacesLenght,
          name: `ESPACIO #${currentBlankspacesLenght}`,
          description: "", // TODO: Recover description from predetermined text @Roberto
        };

        arrayOfPreviousNumbers.push({
          old: espacioNumber,
          new: currentBlankspacesLenght,
        });

        addNewBlankSpace(newBlankSpace);

        const substring = inputString.substring(lastIndex, match.index);
        updatedString += substring + replacement;

        lastIndex = regex.lastIndex;
      }

      updatedString += inputString.substring(lastIndex);

      const finalText = transformNewBlankSpace(
        arrayOfPreviousNumbers,
        updatedString
      );

      addNewText(finalText);
    });
  };

  const transformNewBlankSpace = (arrayOfChanges, text) => {
    let newText = text;

    arrayOfChanges.forEach((textObject) => {
      newText = replaceBlankSpace(newText, textObject.old, textObject.new);
    });

    return newText;
  };
  const handleAddClauseToTemplate = (clause, isTitleIncluded) => {
    const clauseStructure = generateClauseHTML({ clause, isTitleIncluded });

    const clauseStructureString =
      ReactDOMServer.renderToStaticMarkup(clauseStructure);
    handleAddPredeterminedTextToTemplate([{ text: clauseStructureString }]);
  };

  /**
   * Edits a blank space
   * @param {Object} editedBlankSpace The edited blank space
   * @param {number} editedBlankSpace.id The id of the blank space
   * @param {string} editedBlankSpace.name The name of the blank space
   * @param {string} editedBlankSpace.description The description of the blank space
   * @param {any} editedBlankSpace.value The value of the blank space
   */
  const editBlankSpace = (editedBlankSpace) => {
    const blankSpacesCopy = [...template.blankSpaces];
    const editedBlankSpaceIndex = blankSpacesCopy.findIndex(
      (bs) => bs.id === editedBlankSpace.id
    );
    blankSpacesCopy[editedBlankSpaceIndex] = {
      ...blankSpacesCopy[editedBlankSpaceIndex],
      ...editedBlankSpace,
    };
    setTemplate((clause) => ({
      ...clause,
      blankSpaces: blankSpacesCopy,
    }));
    // TODO: Send the focus to the editor after the blank space is edited @Roberto
    templateRichEditorTextRef.current.editBlankSpace(
      editedBlankSpace.id,
      editedBlankSpace
    );
  };

  const handleOpenBlankSpaceFormModal = (blankSpace) => {
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: true,
      selectedBlankSpace: blankSpace,
      blankSpaces: template.blankSpaces,
      onAgree: (blankSpace) => {
        editBlankSpace(blankSpace);
        handleCloseBlankSpaceFormModal();
      },
      onDisagree: handleCloseBlankSpaceFormModal,
      onClose: handleCloseBlankSpaceFormModal,
    });
  };

  const handleCloseBlankSpaceFormModal = () => {
    setBlankSpaceFormModalProps({
      ...blankSpaceFormModalProps,
      open: false,
    });
  };

  //Use effects

  const handleFileChange = useCallback(
    async (event) => {
      const file = documentData;

      if (file.name) {
        try {
          const result = await readWordFile(file);

          const filteredHtml = filterOutImages(result);
          templateRichEditorTextRef.current.addText(filteredHtml);
          setAnchorElImport(null);
          setDocumentData({});
        } catch (error) {
          console.error("Error reading Word file:", error);
        }
      }
    },
    [documentData]
  );

  const readWordFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const arrayBuffer = event.target.result;
        try {
          const result = await convertToHtml({
            arrayBuffer,
          });
          resolve(result.value);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  }, []);

  //Use effects

  useEffect(() => {
    handleFileChange();
  }, [documentData, handleFileChange]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentTemplate._id) {
        setValue("templateText");
        templateRichEditorTextRef?.current?.setEditorContents(
          currentTemplate.text
        );

        setTemplate((template) => ({
          ...template,
          blankSpaces: currentTemplate.blankspaces?.map((blankSpace) => ({
            ...blankSpace,
            name: changeDefaultBlankSpaceName(blankSpace.name),
          })),
        }));
        templateRichEditorTextRef?.current?.addBlankspacesArray(
          currentTemplate.blankspaces
        );
      } else {
        setIsLoading(true);
        const service = await findTemplateById({ otherDocumentTemplateId: id });
        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          dispatch(setSelectedTemplate(service.data?.responseMessage?.data));
          templateRichEditorTextRef?.current?.addBlankspacesArray(
            service.data?.responseMessage?.data?.blankspaces
          );
        }
        setIsLoading(false);
      }
    };

    // call the function
    fetchData();
  }, [currentTemplate, id]);

  return (
    <Container fluid className="h-100 d-flex flex-column gap-4">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={"Editar plantilla"} />
      </Row>

      <Form>
        <Row className="gap-xs-5 gap-md-0">
          <Col xs={8} className="d-flex flex-column gap-3 flex-grow-1">
            <Row className="gap-2">
              <Col>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<AddIcon fontSize="large" />}
                  className="custom-input__button__primary-color"
                  sx={{ whiteSpace: "nowrap", width: "fit-content" }}
                  onClick={handleClickOpenImportMenu}
                >
                  Importar
                </Button>
                <Menu
                  anchorEl={anchorElImport}
                  open={isOpenImportMenu}
                  onClose={() => {
                    setAnchorElImport(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setIsOpenModalImportClauseText(true);
                      setAnchorElImport(null);
                    }}
                  >
                    <ListItemIcon>
                      <PostAddOutlinedIcon
                        fontSize="large"
                        className="heading__primary-color"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "1.4rem",
                        },
                      }}
                    >
                      Texto desde cláusula
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setIsOpenModalImportText(true);
                      setAnchorElImport(null);
                    }}
                  >
                    <ListItemIcon>
                      <PlaylistAddOutlinedIcon
                        fontSize="large"
                        className="heading__primary-color"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "1.4rem",
                        },
                      }}
                    >
                      Texto desde bloque
                    </ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setIsOpenModalUploadDocument(true);
                    }}
                  >
                    <ListItemIcon>
                      <NoteAddOutlinedIcon
                        fontSize="large"
                        className="heading__primary-color"
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        ".MuiListItemText-primary": {
                          fontSize: "1.4rem",
                        },
                      }}
                    >
                      Documento word
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Col>
              <Col xs="auto">
                <Stack
                  direction="row"
                  gap={1}
                  className="flex-wrap align-self-end"
                >
                  <Button
                    variant="contained"
                    startIcon={<FactCheckOutlinedIcon fontSize="large" />}
                    className="custom-input__button__secondary-color"
                    sx={{ whiteSpace: "nowrap", width: "fit-content" }}
                    onClick={() => {
                      if (!selectedText.length) {
                        setIsOpenModalNoTextSelected(true);
                      } else {
                        setDocumentAdditionalInfoToSave({
                          type: "other",
                          placeholder: "Escriba el nombre del bloque",
                          text: "Por favor, escriba un nombre para este bloque.",
                        });
                        setIsOpenModalModalSaveDocument(true);
                      }
                    }}
                  >
                    Guardar selec. como bloque
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon fontSize="large" />}
                    className="custom-input__button__secondary-color"
                    sx={{ whiteSpace: "nowrap", width: "fit-content" }}
                    onClick={() => handleBlankSpaces()}
                  >
                    Espacio en blanco
                  </Button>
                </Stack>
              </Col>
            </Row>
            <Row style={{ maxHeight: "393px" }}>
              <Col>
                <Controller
                  control={control}
                  name="templateText"
                  render={({ field: { onChange, onBlur } }) => (
                      <RichEditorText
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange}
                        ref={templateRichEditorTextRef}
                        customClassName={
                          errors.templateText
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                        placeholder="Escriba aquí"
                        handleSelection={handleSelectionChange}
                        deleteBlankSpace={(id) => {
                          setTemplate((template) => ({
                            ...template,
                            blankSpaces: template.blankSpaces.filter(
                              (blankSpace) => blankSpace.id !== id
                            ),
                          }));
                        }}
                      />
                  )}
                />

                <div className="caption custom-input__error">
                  {errors.templateText?.message}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={3} xxl={2} className="flex-shrink-0">
            <Container fluid className="g-0">
              <Row className="py-3">
                <p className="label">
                  {template.blankSpaces.length} Espacios en blanco:
                </p>
              </Row>
              <Container
                fluid
                className="overflow-auto g-0"
                style={{ maxHeight: "576px" }}
              >
                {template?.blankSpaces.map((item, index) => {
                  return (
                    <Row xs="auto" key={`Template ${item?.id} ${index}`}>
                      <Col
                        xs="12"
                        md="auto"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="heading__primary-color text-break">
                          {changeDefaultBlankSpaceName(item.name)}
                        </p>
                      </Col>
                      <Col xs={"auto"}>
                        <Stack direction="row">
                          <IconButton onClick={() => removeBlankSpace(item.id)}>
                            <DeleteOutlineIcon
                              className="heading__primary-color"
                              fontSize="large"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenBlankSpaceFormModal(item)}
                          >
                            <EditOutlined
                              className="heading__primary-color"
                              fontSize="large"
                            />
                          </IconButton>
                        </Stack>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Container>
          </Col>
        </Row>
        <Row className="sidebar__bottom__container fixed-bottom">
          <Col xs={"auto"}>
            <Button
              variant="contained"
              className="custom-input__button__secondary-color"
              startIcon={<SaveAsOutlinedIcon fontSize="large" />}
              onClick={handleSubmit(handleUpdateTemplate)}
            >
              Sobreescribir plantilla
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              startIcon={<SaveOutlinedIcon fontSize="large" />}
              onClick={handleSubmit(handleSubmitTemplateInfo)}
            >
              Guardar como nuevo
            </Button>
          </Col>
        </Row>
      </Form>
      <ModalUploadDocs
        open={isOpenModalUploadDocument}
        title={"Selecciona el documento para subir"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        message={
          "El archivo que estas a punto de subir debe estar en formato word y pesar máximo 75mb"
        }
        documentData={documentData}
        setDocumentData={setDocumentData}
        onClose={() => {
          setIsOpenModalUploadDocument(false);
        }}
        typeDocument={"ONLY_WORD"}
        messageTypeValidation="*Formato incorrecto, recuerda cargar un documento en formato Word"
        maxFileSize={78643200} //75MB
      />

      <ModalInfo
        title="Crear bloque"
        onClose={() => setIsOpenModalNoTextSelected(false)}
        open={isOpenModalNoTextSelected}
        responseData={{
          status: 400,
          data: {
            responseMessage:
              "Debe seleccionar primero una sección de texto para almacenarla como bloque",
          },
        }}
        confirmationText="Aceptar"
      />

      <ModalNameAndTags
        open={isOpenModalSaveDocument}
        onClose={setIsOpenModalModalSaveDocument}
        title={"Nombrar y etiquetar"}
        placeholder={documentAdditionalInfoToSave.placeholder}
        text={documentAdditionalInfoToSave.text}
        handleAgree={handleCreateOtherDocument}
      />

      <ModalInfo
        title="Crear otro documento"
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
          if (
            (responseDataModalInfo.status === CODES.COD_RESPONSE_HTTP_CREATED ||
              responseDataModalInfo.status === CODES.COD_RESPONSE_HTTP_OK) &&
            documentAdditionalInfoToSave.type === "template"
          ) {
            navigate(-1);
          }
        }}
        responseData={responseDataModalInfo}
      />
      <ImportPredeterminedText
        show={isOpenModalImportText}
        setShow={setIsOpenModalImportText}
        mixPanelTrack="Crear Btn Importar Texto Predeterminado"
        onSelect={handleAddPredeterminedTextToTemplate}
      />
      <ImportClauseModal
        show={isOpenModalImportClauseText}
        setShow={setIsOpenModalImportClauseText}
        mixPanelTrack="Crear Btn Importar Texto Predeterminado"
        onSelect={handleAddClauseToTemplate}
      />
      <BlankSpaceFormModal {...blankSpaceFormModalProps} />
    </Container>
  );
};

export default UpdateTempleteDocument;
