import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

export function findPartByCompany({
  company,
  status,
  associatedCorporateUnits,
  deletedBy,
  createdBy,
}) {
  return axios({
    method: "GET",
    url: `partsLibrary/getAllParts`,
    params: { company, status, associatedCorporateUnits, deletedBy, createdBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function addCorporateUnitToParts({
  partId,
  company,
  associatedCorporateUnits,
  actionToPart,
}) {
  return axios({
    method: "PUT",
    url: `corporate-unit/managePartToCorporateUnits`,
    data: {
      companyId: company,
      partId,
      associatedCorporateUnits,
      actionToPart,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function deleteTemporalPart({ partId, company, status, modifiedBy }) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updateStatusPart`,
    data: { company, partId, status, modifiedBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createPart(data) {
  return axios({
    method: "POST",
    url: `partsLibrary/createPart`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function createPartMassive(data) {
  return axios({
    method: "POST",
    url: `partsLibrary/createPartMassive`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updatePart(data) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updatePart`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function manageRelatedPartToSignatory(data) {
  return axios({
    method: "PUT",
    url: `partsLibrary/manageRelatedPartToSignatory`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function createPartFromFile(data) {
  const formData = new FormData();
  console.log(data);
  formData.append("file", data.file);
  formData.append("company", data.company);
  formData.append("createdBy", data.createdBy);
  if (data.associatedCorporateUnits) {
    formData.append(
      "associatedCorporateUnits",
      JSON.stringify(data.associatedCorporateUnits)
    );
  }

  return axios({
    method: "POST",
    url: `partsLibrary/loadCERLFile`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function createMassivePartsFromFile(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("company", data.company);
  formData.append("createdBy", data.createdBy);
  if (data.associatedCorporateUnits) {
    formData.append(
      "associatedCorporateUnits",
      JSON.stringify(data.associatedCorporateUnits)
    );
  }

  return axios({
    method: "POST",
    url: `partsLibrary/loadMassiveParts`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function getPartInfoFromFile(data) {
  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("company", data.company);
  formData.append("createdBy", data.createdBy);
  formData.append("validateCerlDate", data.validateCerlDate);
  return axios({
    method: "POST",
    url: `partsLibrary/getCERLFileInfo`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export function findPartById({ partId }) {
  return axios({
    method: "GET",
    url: `partsLibrary/getPart`,
    params: { partId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function RestoreRemovePart({ partId, company, status, modifiedBy }) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updateStatusPart`,
    data: { company, partId, status, modifiedBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function DeletePartPermanent({ partId, company }) {
  return axios({
    method: "DELETE",
    url: `partsLibrary/deletePart`,
    params: { company, partId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

//Signatories

export function findSignatoriesByCompany({
  company,
  status,
  associatedCorporateUnits,
  deletedBy,
  createdBy,
}) {
  return axios({
    method: "GET",
    url: `partsLibrary/getAllSignatories`,
    params: { company, status, associatedCorporateUnits, deletedBy, createdBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function createSignatory(data) {
  return axios({
    method: "POST",
    url: `partsLibrary/createSignatory`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function addCorporateUnitToSignatory({
  signatoryId,
  company,
  associatedCorporateUnits,
  actionToSignatory,
}) {
  return axios({
    method: "PUT",
    url: `corporate-unit/manageSignatoryToCorporateUnits`,
    data: {
      companyId: company,
      signatoryId,
      associatedCorporateUnits,
      actionToSignatory,
    },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function updateSignatory(data) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updateSignatory`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
export function findSignatoryById({ signatoryId }) {
  return axios({
    method: "GET",
    url: `partsLibrary/getSignatory`,
    params: { signatoryId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteSignatoryTemporarily({
  company,
  signatoryId,
  status,
  modifiedBy,
}) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updateStatusSignatory`,
    data: { company, signatoryId, status, modifiedBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function restoreTemporarilyDeletedSignatory({
  company,
  signatoryId,
  status,
  modifiedBy,
}) {
  return axios({
    method: "PUT",
    url: `partsLibrary/updateStatusSignatory`,
    data: { company, signatoryId, status, modifiedBy },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function deleteSignatoryPermanent({ signatoryId }) {
  return axios({
    method: "DELETE",
    url: `partsLibrary/deleteSignatory`,
    params: { signatoryId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function manageRelatedSignatoryToPart(data) {
  return axios({
    method: "PUT",
    url: `partsLibrary/manageRelatedSignatoryToPart`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function manageSignatoriesPartsRelation(data) {
  return axios({
    method: "PUT",
    url: `partsLibrary/manageSignatoriesPartsRelation`,
    data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
