import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import { MDBDataTableV5 } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingContent from "../../../../../components/loadingContent";
import ModalDecision from "../../../../../components/Modals/modalDecision";
import ModalInfo from "../../../../../components/Modals/modalInfo";
import ModalInpuText from "../../../../../components/Modals/modalInpuText";
import SectionTitle from "../../../../../components/sectionTitle";
import Breadcrum from "../../../../../parts/breadcrum";
import { getCompanyPlan } from "../../../../../parts/storage/storageSlice";
import { getFileDocument } from "../../../../../services/documents/documentsRequest";
import {
  deleteSignature,
  findAllSignaturesByConsecutive,
  ReturnSignture,
  updateEmailSignaturesRequest,
} from "../../../../../services/signatures/signaturesService";
import { CODES } from "../../../../../utils/codes";
import { onSortHTMLElement } from "../../../../../utils/findComponentHTML";
export default function ManageSignature() {
  const { consecutive } = useParams();
  const companyPlan = useSelector(getCompanyPlan);
  const columnsSignatures = [
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="1"
        >
          Documento para firma
        </label>,
      ],
      field: "document",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="2"
        >
          Fecha de solicitud
        </label>,
      ],
      field: "date",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="3"
        >
          Fecha actualización
        </label>,
      ],
      field: "dateResponse",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="4"
        >
          Firmantes
        </label>,
      ],
      field: "signatory",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="5"
        >
          Correos
        </label>,
      ],
      field: "emails",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="6"
        >
          Estado
        </label>,
      ],
      field: "state",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="7"
        >
          Comentarios
        </label>,
      ],
      field: "comments",
    },
    {
      label: [
        <label
          className="default-text--xs text-color--dt-column"
          aria-hidden="true"
          key="8"
        >
          Acciones
        </label>,
      ],
      field: "actions",
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [dataSignatures, setDataSignatures] = useState({
    rows: [],
    columns: columnsSignatures,
    data: [],
  });
  const [isStoragePlanLimit, setIsStoragePlanLimit] = useState(false);
  const [isSignaturePlanLimit, setIsSignaturePlanLimit] = useState(false);
  const [isOpenModalResume, setIsOpenModalResume] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModalDeleteSignature, setIsOpenModalDeleteSignature] = useState({
    open: false,
    id: null,
  });
  const [isOpenModalDeleteResponse, setIsOpenModalDeleteResponse] =
    useState(false);

  const [editModeUserId, setEditModeUserId] = useState(null);
  const [openModalEditEmail, setOpenModalEditEmail] = useState(false);
  const [openResponseEmailModal, setOpenResponseEmailModal] = useState({
    open: false,
    response: {},
  });

  const handleReturnSignature = async (signatureId, restartOTP = false) => {
    try {
      setIsLoading(true);
      const response = await ReturnSignture({ signatureId, restartOTP });
      fetchData();
      setIsOpenModalResume(true);
      setResponseData(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickDocument = async (data) => {
    try {
      setIsLoading(true);
      const response = await getFileDocument(
        data.filenameInBucket,
        data.bucketName
      );
      if (response.status) {
        if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
          const bf = response.data.responseMessage.buffer.data;
          const blob = new Blob([new Uint8Array(bf).buffer]);
          let fileName = response.data.responseMessage.filename;
          const contentType =
            fileName.split(".").length === 1 &&
            response.data.responseMessage.contentType;
          if (
            contentType &&
            contentType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            fileName = fileName + ".docx";
          } else if (contentType && contentType === "application/pdf") {
            fileName = fileName + ".pdf";
          }
          saveAs(blob, fileName);
        }
      }
    } catch (error) {
      console.log(
        "========= Start Handle Download Document To Sign Service ========="
      );
      console.error(error);
      console.log(
        "========= End Handle Download Document To Sign Service ========="
      );
    } finally {
      setIsLoading(false);
    }
  };

  const buildRowsSignatures = (data) => {
    const getStateStyle = (state) => {
      if (state.hasSigned) return { color: "#CCF4E3", name: "Firmado" };
      if (state.hasRejected) return { color: "#E6E9EF", name: "Devuelto" };
      return { color: "#FFD7B1", name: "Pendiente" };
    };

    return data.map((item) => {
      const signatories = (
        <Row style={{ alignItems: "center" }}>
          <ul style={{ listStyle: "none", margin: 0 }}>
            {item?.signatoriesInfo.map((signatory) => (
              <li key={signatory?._id} style={{ paddingBottom: "3px" }}>
                {`${signatory?.firstName || ""} ${
                  signatory?.firstSurname || ""
                }`}
              </li>
            ))}
          </ul>
        </Row>
      );
      const emails = item?.signatoriesInfo.map((signatory) => (
        <Row style={{ alignItems: "center" }}>
          <Col>{signatory.email}</Col>
          {!signatory?.hasSigned && !signatory?.hasRejected && (
            <Col xs={"auto"}>
              <IconButton
                onClick={() => {
                  setEditModeUserId({
                    userId: signatory?._id,
                    signatureId: item?._id,
                    email: signatory?.email,
                  });
                  setOpenModalEditEmail(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Col>
          )}
        </Row>
      ));
      const filterRejected = item?.signatoriesInfo?.filter(
        (signatory) => signatory?.hasRejected
      );
      const filterSigned = item?.signatoriesInfo?.filter(
        (signatory) => signatory?.hasSigned
      );
      const dateResponse =
        !filterSigned.length && !filterRejected.length
          ? "---"
          : item?.updatedAt;
      const isRejected = filterRejected.length > 0;
      const states = (
        <Row style={{ alignItems: "center" }}>
          <ul style={{ listStyle: "none", margin: 0 }}>
            {item?.signatoriesInfo.map((signatory) => (
              <li key={signatory?._id} style={{ paddingBottom: "3px" }}>
                <span
                  className="custom-badge"
                  style={{ backgroundColor: getStateStyle(signatory).color }}
                >
                  {getStateStyle(signatory).name}
                </span>
              </li>
            ))}
          </ul>
        </Row>
      );

      let objDownload = {};
      if (!item?.documentAssociated) {
        objDownload = {
          _id: item?.documentId,
          type: item?.documentType,
        };
      } else {
        objDownload = item.documentAssociated;
      }

      const getComment = () => {
        if (isRejected) {
          return filterRejected.map((item) => item.rejectionMessage).join(", ");
        } else if (
          isStoragePlanLimit &&
          isSignaturePlanLimit &&
          item?.isSuspended
        ) {
          return "Sin almacenamiento ni firmas disponible en el plan, contacta al usuario administrador";
        } else if (isSignaturePlanLimit && item?.isSuspended) {
          return "Sin firmas disponibles en el plan, contacta al usuario administrador";
        } else if (isStoragePlanLimit && item?.isSuspended) {
          return "Sin almacenamiento disponible en el plan, contacta al usuario administrador";
        } else if (item?.noOTPAvailable && item?.isSuspended) {
          return (
            <>
              <p>
                El firmante ha alcanzado el máximo de intentos disponibles para
                firmar.
              </p>
              <span
                className="caption__other-Color-Active"
                onClick={() => handleReturnSignature(item?._id, true)}
              >
                Reanudar firma
              </span>
            </>
          );
        } else if (
          (!isSignaturePlanLimit || !isStoragePlanLimit) &&
          item?.isSuspended
        ) {
          return (
            <span
              className="caption__other-Color-Active"
              onClick={() => handleReturnSignature(item?._id)}
            >
              Reanudar firma
            </span>
          );
        } else {
          return "Sin comentarios";
        }
      };

      return {
        document: (
          <label
            onClick={() => handleClickDocument(objDownload)}
            style={{
              textDecoration: "underline",
              color: "#067BBD",
              cursor: "pointer",
              fontSize: "1.2rem",
            }}
          >
            {item?.documentAssociated?.originalFilename ||
              "Sin previsualización de documento"}
          </label>
        ),
        date: item?.createdAt,
        dateResponse,
        signatory: signatories,
        state: states,
        comments: getComment(),
        emails,
        actions: (
          <Row>
            <Col xs={"auto"}>
              <Tooltip title={<h5>Elimina un proceso de firma en curso</h5>}>
                <IconButton
                  aria-label="delete"
                  className="custom-input__button__secondary-color"
                  onClick={() =>
                    setIsOpenModalDeleteSignature({ open: true, id: item?._id })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Col>
            {!item?.isSigned && !item?.isRejected && (
              <Col xs={"auto"}>
                <Tooltip
                  title={
                    <h5>Reenviar recordatorio para iniciar proceso de firma</h5>
                  }
                >
                  <IconButton
                    aria-label="remember"
                    className="custom-input__button__secondary-color"
                    onClick={() => handleReturnSignature(item?._id)}
                  >
                    <NotificationsActiveOutlinedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Col>
            )}
          </Row>
        ),
      };
    });
  };

  const handleDeleteSignature = async (id) => {
    try {
      setIsOpenModalDeleteSignature({ open: false, id: null });
      setIsLoading(true);
      const responseDelete = await deleteSignature(id);
      if (responseDelete.status === CODES.COD_RESPONSE_HTTP_OK) {
        fetchData();
      }
      setResponseData(responseDelete);
      setIsOpenModalDeleteResponse(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const companyId = localStorage.getItem("company");
      const corporateUnitId = localStorage.getItem("corporateUnitId");

      const responseSignature = await findAllSignaturesByConsecutive({
        consecutive,
        companyId,
        corporateUnitId,
      });
      const rowsSignatures = [];
      const dataSignatures = [];
      if (
        responseSignature.status === CODES.COD_RESPONSE_HTTP_OK &&
        responseSignature.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        const data = responseSignature.data.responseMessage;
        const buildRows = buildRowsSignatures(data);
        rowsSignatures.push(...buildRows);
        dataSignatures.push(...data);
      }
      setDataSignatures({
        rows: rowsSignatures,
        columns: columnsSignatures,
        data: dataSignatures,
      });
    } catch (error) {
      console.log("=========== Start Fetch All Signatures Service ===========");
      console.error(error);
      console.log("=========== End Fetch All Signatures Service ===========");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveClickSignatoryEmail = async (email) => {
    try {
      setIsLoading(true);
      const data = [...dataSignatures.data];
      const obj = {
        newEmail: email,
        signatureId: editModeUserId.signatureId,
        signatoryId: editModeUserId.userId,
      };
      setOpenModalEditEmail(false);
      const response = await updateEmailSignaturesRequest(obj);
      if (response.status === CODES.COD_RESPONSE_HTTP_OK) {
        const updateSignatures = data.map((item) => {
          if (item?._id === editModeUserId.signatureId) {
            return {
              ...item,
              signatoriesInfo: item.signatoriesInfo.map((signatory) => {
                if (signatory?._id === editModeUserId.userId) {
                  return {
                    ...signatory,
                    email,
                  };
                }
                return signatory;
              }),
            };
          }
          return item;
        });
        const rows = buildRowsSignatures(updateSignatures);
        setDataSignatures((oldArray) => ({
          ...oldArray,
          rows,
          data: updateSignatures,
        }));
      }
      setOpenResponseEmailModal({ open: true, response });
      setEditModeUserId(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isSignaturePlanLimit, isStoragePlanLimit]);

  useEffect(() => {
    if (companyPlan) {
      if (!companyPlan.isSignatureEnable) {
        setIsSignaturePlanLimit(true);
      }
      if (!companyPlan.isStorageEnable) {
        setIsStoragePlanLimit(true);
      }
    }
  }, [companyPlan]);

  return (
    <Container fluid className="custom-container-scroll">
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title={`Solicitud ${consecutive || "###"}`} />
        </Col>
      </Row>
      <br />
      <br />
      <Divider />
      <br />
      <Row>
        <p className="heading__primary-color">Información de firmas</p>
      </Row>
      <br />
      <Row>
        <p className="caption">
          Aquí puedes encontrar un historico de las firmas relacionadas con la{" "}
          <b>solicitud {consecutive || "###"}.</b>
        </p>
      </Row>
      <br />
      <Row className="dt-list-items">
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          entries={6}
          data={dataSignatures}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "firmas"]}
          fullPagination
          noRecordsFoundLabel="No se encontraron resultados"
          onSort={(value) => {
            onSortHTMLElement({
              sort: value,
              filteredDataTable: dataSignatures,
              setFilteredDataTable: setDataSignatures,
            });
          }}
        />
      </Row>
      <ModalInfo
        title="Reanudar firma"
        onClose={() => setIsOpenModalResume(false)}
        open={isOpenModalResume}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalDecision
        title={"Eliminar"}
        message={"¿Seguro que quieres eliminar este proceso de firma?"}
        agreeText={"Aceptar"}
        disagreeText={"Cancelar"}
        handleAgree={() => handleDeleteSignature(isOpenModalDeleteSignature.id)}
        onClose={() => {
          setIsOpenModalDeleteSignature({ open: false, id: null });
        }}
        handleDisagree={() => {
          setIsOpenModalDeleteSignature({ open: false, id: null });
        }}
        open={isOpenModalDeleteSignature.open}
        type={"warning"}
      />
      <ModalInfo
        title="Eliminar firma"
        onClose={() => setIsOpenModalDeleteResponse(false)}
        open={isOpenModalDeleteResponse}
        responseData={responseData}
        confirmationText="Aceptar"
      />
      <ModalInpuText
        title={"Actualizar"}
        message={"Ingresa el nuevo correo electrónico del firmante"}
        placeholder={"Escriba aquí..."}
        open={openModalEditEmail}
        onClose={() => setOpenModalEditEmail(false)}
        agreeText={"Guardar"}
        disagreeText="Cancelar"
        handleAgree={handleSaveClickSignatoryEmail}
        handleDisagree={() => setOpenModalEditEmail(false)}
        validation={{
          required: {
            value: true,
            message: "*Este campo es obligatorio",
          },
          maxLength: {
            value: 200,
            message: "*Máximo 200 caracteres permitidos",
          },
          pattern: {
            value: /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/,
            message: "*Debe ser un correo electrónico válido",
          },
        }}
        inputType="input"
        value={editModeUserId?.email || ""}
      />
      <ModalInfo
        title="Actualizar correo"
        onClose={() => setOpenResponseEmailModal({ open: false, response: {} })}
        open={openResponseEmailModal.open}
        responseData={openResponseEmailModal.response}
        confirmationText="Aceptar"
      />
    </Container>
  );
}
