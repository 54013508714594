import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BlankSpaceFormSchema from "../schemas/blankSpaceFormSchema";
import BlankSpaceFormInputs from "../constants/blankSpaceFormInputs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Box } from "@mui/material";
import { Check } from "@mui/icons-material";
import ModalDecision from "../../Modals/modalDecision";

/**
 * Initial states used in BlankSpaceForm
 * @readonly
 */
const InitialStates = Object.freeze({
  ModalDecisionProps: {
    open: false,
    type: undefined,
    title: undefined,
    message: undefined,
    children: undefined,
    agreeText: undefined,
    agreeIcon: undefined,
    agreeIconStart: undefined,
    disagreeText: undefined,
    disagreeIcon: undefined,
    disagreeIconStart: undefined,
    handleAgree: () => undefined,
    handleDisagree: () => undefined,
    onClose: () => undefined,
  },
});

/**
 * Form for creating new blank spaces or editing existing ones
 * @author [Roberto Carlos Salas Valencia](https://github.com/TheKizz)
 * @name BlankSpaceForm
 * @memberof BlankSpaceNameSpace
 * @param {Object} props The props of the component
 * @param {{ name: string, description?: string }} [props.selectedBlankSpace] The blank space to be modified, if is passed, the form will be in edit mode else it will be in create mode, is optional
 * @param {Array<{ name: string, description?: string }>} props.blankSpaces The list of existing blank spaces, is used for validations like has unique names, default is an empty array
 * @param {({ name: string, description?: string }) => void} props.onSubmit The function to be called when the form is submitted
 * @param {boolean} [props.isBeingSubmittedExternally] If the form is being submitted externally, default is false, is optional
 * @returns {JSX.Element} The rendered component
 * @throws {Error} If the selectedBlankSpace is not contained in the array of blankSpaces
 * @example
 * // Example usage of create mode
 * import { useState } from "react";
 * import BlankSpaceForm from "./BlankSpaceForm"; // Change the import path to your own
 *
 * const BlankSpaceFormCreateMode = () => {
 *  const [blankSpaces, setBlankSpaces] = useState([
 *    {
 *      name: "blank space name",
 *      description: "blank space description",
 *    },
 *    {
 *      name: "another blank space name 2",
 *      description: "another blank space description 2",
 *    },
 *  ]);
 *
 *  const onSubmit = (createdBlankSpace) => {
 *    setBlankSpaces((prevState) => [...prevState, createdBlankSpace]);
 *    console.log(createdBlankSpace);
 *  };
 *
 *  return (
 *    <BlankSpaceForm
 *      blankSpaces={blankSpaces}
 *      onSubmit={onSubmit}
 *    />
 *  );
 * }
 * @example
 * // Example usage of edit mode
 * import { useState } from "react";
 * import BlankSpaceForm from "./BlankSpaceForm"; // Change the import path to your own
 *
 * const BlankSpaceFormEditMode = () => {
 *  const [blankSpaces, setBlankSpaces] = useState([
 *    {
 *      name: "editable blank space name",
 *      description: "editable blank space description",
 *    },
 *    {
 *      name: "another editable blank space name 2",
 *      description: "another editable blank space description 2",
 *    },
 *  ]);
 *  const selectedBlankSpace = blankSpaces[0];
 *
 *  const onSubmit = (editedBlankSpace) => {
 *    setBlankSpaces((prevState) => {
 *      const newState = [...prevState];
 *      const selectedBlankSpaceIndex = newState.findIndex((blankSpace) => blankSpace.name === selectedBlankSpace.name);
 *      newState[selectedBlankSpaceIndex] = editedBlankSpace;
 *      return newState;
 *    });
 *    console.log(editedBlankSpace);
 *  };
 *
 *  return (
 *    <BlankSpaceForm
 *      selectedBlankSpace={selectedBlankSpace}
 *      blankSpaces={blankSpaces}
 *      onSubmit={onSubmit}
 *    />
 *  );
 * }
 *
 */
const BlankSpaceForm = ({
  selectedBlankSpace,
  blankSpaces,
  onSubmit,
  isBeingSubmittedExternally = false,
}) => {
  const [modalDecisionProps, setModalDecisionProps] = useState(
    InitialStates.ModalDecisionProps
  );
  const {
    control,
    formState: { errors, isValid },
    getValues,
    reset,
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(BlankSpaceFormSchema),
    defaultValues: selectedBlankSpace ?? BlankSpaceFormSchema.getDefault(),
    mode: "all",
  });

  const closeModal = () => {
    setModalDecisionProps(InitialStates.ModalDecisionProps);
  };

  /**
   * Validate if the given name is unique in the blankSpaces, if isn't unique, will open a warning modal
   * @function
   * @param {string} name
   * @returns {boolean} True if the given name is unique
   */
  const isUniqueName = (name) => {
    let sameNameQuantity = 0;
    if (selectedBlankSpace.name === name) return true;
    const state = !blankSpaces?.some((blankSpace) => {
      const isSameName = blankSpace.name === name;
      if (isSameName) {
        sameNameQuantity++;
      }
      return sameNameQuantity >= 1;
    });

    if (!state) {
      setModalDecisionProps({
        open: true,
        type: "warning",
        title: "Nombre existente",
        message:
          "Es necesario asignarle un nombre único a esté espacio en blanco",
        agreeText: "Aceptar",
        agreeIconStart: <Check fontSize="large" />,
        handleAgree: closeModal,
        onClose: closeModal,
      });
    }
    return state;
  };

  /**
   * Submit the form if the given values are valid, if isn't valid, will open a warning modal, if the form is valid, will call the onSubmit function
   * @function
   * @param {{ name: string, description?: string }} blankSpaceFormValues The values of the form
   */
  const submitForm = (blankSpaceFormValues) => {
    trigger();
    if (!isValid) return;
    if (!isUniqueName(blankSpaceFormValues.name)) return;
    onSubmit(blankSpaceFormValues);
    reset(BlankSpaceFormSchema.getDefault());
  };

  /**
   * Handle the input change, if the value is greater than the maxLength, will add a space at the end of the value
   *
   * @function
   * @param {Event} event The event of the input
   * @param {Function} onChange The onChange function of the input
   */
  const handleInputChange = (event, onChange) => {
    const { name, value } = event.target;
    const formInput = Object.keys(BlankSpaceFormInputs).find(
      (key) => BlankSpaceFormInputs[key].inputName === name
    );
    if (value.length > BlankSpaceFormInputs[formInput].maxLength) {
      setValue(name, value + " ");
      trigger(name);
      setValue(name, value.slice(0, BlankSpaceFormInputs[formInput].maxLength));
    } else {
      onChange(value);
    }
  };

  /**
   * Submit the form when isBeingSubmittedExternally change to true
   */
  useEffect(() => {
    if (isBeingSubmittedExternally) {
      submitForm(getValues());
    }
  }, [isBeingSubmittedExternally]);

  /**
   * Reset the form if selectedBlankSpace change
   */
  useEffect(() => {
    reset(selectedBlankSpace ?? BlankSpaceFormSchema.getDefault());
  }, [selectedBlankSpace]);

  return (
    <>
      <Box
        component="form"
        onKeyDown={(event) => event.key === "Enter" && submitForm(getValues())}
      >
        {Object.entries(BlankSpaceFormInputs).map(
          ([blankSpaceInputKey, blankSpaceInputValue]) => (
            <Controller
              key={`form-control-${blankSpaceInputKey}`}
              name={blankSpaceInputValue.inputName}
              control={control}
              render={({ field }) => (
                <Box sx={{ m: 1, gap: 1 }}>
                  <label
                    htmlFor={`${blankSpaceInputValue.inputName}textField`}
                    className="label__description"
                  >
                    {blankSpaceInputValue.inputLabel}
                  </label>
                  <TextField
                    id={`${blankSpaceInputValue.inputName}textField`}
                    {...field}
                    onChange={(event) =>
                      handleInputChange(event, field.onChange)
                    }
                    type={blankSpaceInputValue.inputType}
                    placeholder={blankSpaceInputValue.inputPlaceholder}
                    error={!!errors[blankSpaceInputValue.inputName]}
                    helperText={errors[blankSpaceInputValue.inputName]?.message}
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiFormHelperText-root": {
                        fontSize: "1.2rem"
                      },
                    }}
                  />
                </Box>
              )}
            />
          )
        )}
      </Box>
      <ModalDecision {...modalDecisionProps} />
    </>
  );
};

BlankSpaceForm.propTypes = {
  selectedBlankSpace: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  blankSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isBeingSubmittedExternally: PropTypes.bool,
};

export default BlankSpaceForm;
