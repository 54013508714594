import { Avatar, Button, Divider } from "@mui/material";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import ModalDecision from "../Modals/modalDecision";
import { NavLink } from "react-router-dom";
import { Logout } from "../../services/general/authServices";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import IconButton from "@mui/material/IconButton";
import { Mixpanel } from "../../utils/mixPanel";
import DrawerSearchConsults from "../consults/searchBarConsults";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
const Sidebar = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const [openModalConsult, setOpenModalConsult] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMediumMobile, setIsMediumMobile] = useState(false);

  const SUBROL_TYPES = {
    "ADMIN PROPIETARIO": "propietario",
    "ADMIN ADMINISTRADOR": "administrador",
    "SERVICE DIRECTOR": "director",
    "SERVICE SENIOR": "senior",
    "SERVICE JUNIOR": "junior",
    "SERVICE PRACTICANTE": "practicante",
  };

  const corporateUnitName = JSON.parse(localStorage.getItem("corporateUnit"));
  const corporateUnit = corporateUnitName?.name || "";
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const typeUser =
    payloadToken?.loginType === "SERVICE" ? "Servicios" : "Administrador";
  const role = SUBROL_TYPES[payloadToken.subRole];

  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const handleLogoutAgree = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      await Logout({ refreshToken, email: payloadToken.email });
      setOpenLogoutModal(false);
      localStorage.clear();
      window.location.replace("/");
      Mixpanel.reset();
    } catch (error) {
      setOpenLogoutModal(false);
    }
  };

  const handleLogoutDisagree = () => {
    setOpenLogoutModal(false);
  };

  const serviceMenu = [
    {
      content: "Inicio",
      icon: (
        <HomeOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "home",
      prefix: "service",
    },
    {
      content: "Crear",
      icon: (
        <AddOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "create",
      prefix: "service",
    },
    {
      content: "Revisar",
      icon: (
        <VisibilityOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "review",
      prefix: "service",
    },
    {
      content: "Carpetas",
      icon: (
        <FolderOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "folders",
      prefix: "service",
    },
    {
      content: "Biblioteca",
      icon: (
        <LocalLibraryOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "library",
      prefix: "service",
    },
    {
      content: "Alertas",
      icon: (
        <InfoOutlinedIcon className="heading__primary-color" fontSize="large" />
      ),
      route: "alerts",
      prefix: "service",
    },
    {
      content: "Solicitudes",
      icon: (
        <EmailOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "requests",
      prefix: "service",
    },
    {
      content: "Firmar",
      icon: (
        <RateReviewOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "signView",
      prefix: "service",
    },
    {
      content: "Indicadores",
      icon: (
        <DonutLargeOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "dashboard",
      prefix: "service - admin",
    },
  ];

  const adminMenu = [
    {
      content: "Administrar usuarios",
      icon: (
        <ManageAccountsOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "manageUser",
      prefix: "admin",
    },
    {
      content: "Unidades corporativas",
      icon: (
        <CorporateFareOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "unitsCorporatives",
      prefix: "admin",
    },

    {
      content: "Planes",
      icon: (
        <BusinessCenterIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "managePlan",
      prefix: "admin",
    },
    {
      content: "Biblioteca",
      icon: (
        <LocalLibraryOutlinedIcon
          className="heading__primary-color"
          fontSize="large"
        />
      ),
      route: "library",
      prefix: "admin",
    },
  ];

  const handleMixPanelAnalytics = (item) => {
    Mixpanel.track(`Btn Sidebar ${item.content}`, {
      email: payloadToken?.email,
      companyName: payloadToken?.companyName,
    });
  };

  useEffect(() => {
    const updateMediaQuery = () => {
      const isMedium = window.innerWidth <= 768;
      setIsExpanded(!isMedium);
      setIsMediumMobile(isMedium);
    };
    updateMediaQuery();
    window.addEventListener("resize", updateMediaQuery);
    return () => {
      window.removeEventListener("resize", updateMediaQuery);
    };
  }, []);

  return (
    <div
      className={
        isExpanded ? "sidebar-base__expanded" : "sidebar-base__contracted"
      }
    >
      <Container className="sidebar-base__container">
        {/**HEADER */}
        <Row className="sidebar-base__header-row">
          <Container
            fluid
            className="sidebar-base__header-row__header-container "
          >
            <Row className="align-items-center ">
              <Col xs={isExpanded ? 9 : 12} className="text-center pb-3">
                {" "}
                <Avatar
                  sx={{
                    width: "4.8rem",
                    height: "4.8rem",
                    backgroundColor: "#00374F",
                    fontSize: "2rem",
                    fontFamily: "Roboto",
                  }}
                >
                  {payloadToken.firstName?.toUpperCase().charAt(0) || "P"}{" "}
                  {payloadToken.firstSurname?.toUpperCase().charAt(0) || "J"}
                </Avatar>
              </Col>

              <Col md={isExpanded ? 3 : 12} className="text-center pb-3">
                <IconButton
                  className="custom-input__button__disabled "
                  sx={{
                    borderRadius: "16px",
                    height: "40px",
                    width: "40px",
                    fill: "#00374F",
                  }}
                  onClick={() => {
                    if (isMediumMobile && !isExpanded) {
                      setIsExpanded(false);
                      return;
                    }
                    setIsExpanded(!isExpanded);
                  }}
                >
                  {isExpanded ? (
                    <MenuOpenOutlinedIcon
                      className="text__primary-color"
                      fontSize="large"
                    />
                  ) : (
                    <ChevronRightOutlinedIcon
                      className="text__primary-color"
                      fontSize="large"
                    />
                  )}
                </IconButton>
              </Col>
            </Row>
            {isExpanded && (
              <Row className="sidebar-base__header-row__header-container__name">
                <p className="heading">
                  {payloadToken.firstName} {payloadToken.firstSurname}
                </p>
              </Row>
            )}

            <Row className="sidebar-base__header-row__header-container__role align-items-center">
              {isExpanded && (
                <Col md={isExpanded ? 9 : 12}>
                  <p className="caption mb-1">{`${typeUser} ${role}`}</p>
                  <p className="caption">{corporateUnit}</p>
                </Col>
              )}
              {/* <Col md={isExpanded ? 3 : 12} className="text-center pb-3">
                <IconButton
                  className="custom-input__button__iaColor"
                  sx={{ borderRadius: "16px", height: "40px", width: "40px" }}
                  onClick={() => {
                    setOpenModalConsult(true);
                  }}
                >
                  <SavedSearchIcon className="text-white" fontSize="large" />
                </IconButton>
              </Col> */}
            </Row>
          </Container>
        </Row>

        <Divider variant="fullWidth" />

        {/**NAVBAR */}
        <Row className="sidebar-base__navbar-row">
          <Container fluid>
            <Nav className="flex-column">
              {payloadToken["loginType"] === "SERVICE" && (
                <>
                  {serviceMenu
                    .filter((option) =>
                      payloadToken["subRole"] === "SERVICE DIRECTOR" ||
                      payloadToken["loginType"] === "ADMIN" ||
                      payloadToken["subRole"] === "ADMIN PROPIETARIO" ||
                      payloadToken["subRole"] === "ADMIN ADMINISTRADOR"
                        ? option.prefix === "service - admin" ||
                          option.prefix === "service"
                        : option.prefix === "service"
                    )
                    .map((item) => {
                      return (
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-4"
                              : "sidebar-base__navbar-row__navbar-container__nav-row mt-4"
                          }
                          key={item?.route}
                          to={item.route}
                          style={{ textDecoration: "none" }}
                          onClick={() => handleMixPanelAnalytics(item)}
                        >
                          <Row
                            className="justify-content-center align-items-center "
                            style={{ height: "inherit" }}
                          >
                            <Col
                              md={isExpanded ? 2 : 12}
                              className={
                                !isExpanded
                                  ? "sidebar-base__navbar-row__navbar-container__icon-container"
                                  : "sidebar-base__navbar-row__navbar-container__icon-container__padding"
                              }
                            >
                              {item.icon}
                            </Col>
                            {isExpanded && (
                              <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                                {item.content}
                              </Col>
                            )}
                          </Row>
                        </NavLink>
                      );
                    })}
                </>
              )}
              {payloadToken["loginType"] === "ADMIN" && (
                <>
                  {adminMenu.map((item) => {
                    return (
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-4"
                            : "sidebar-base__navbar-row__navbar-container__nav-row mt-4"
                        }
                        key={item?.route}
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        onClick={() => handleMixPanelAnalytics(item)}
                      >
                        <Row
                          className="justify-content-center align-items-center "
                          style={{ height: "inherit" }}
                        >
                          <Col
                            md={isExpanded ? 2 : 12}
                            className={
                              !isExpanded
                                ? "sidebar-base__navbar-row__navbar-container__icon-container"
                                : "sidebar-base__navbar-row__navbar-container__icon-container__padding"
                            }
                          >
                            {item.icon}
                          </Col>
                          {isExpanded && (
                            <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                              {item.content}
                            </Col>
                          )}
                        </Row>
                      </NavLink>
                    );
                  })}
                </>
              )}
            </Nav>
          </Container>
        </Row>

        {/**LOGOUT */}
        <Row className="sidebar-base__header-row__header-container  container-fluid">
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "sidebar-base__navbar-row__navbar-container__nav-row__selected mt-4"
                : "sidebar-base__navbar-row__navbar-container__nav-row mt-4"
            }
            key={"help"}
            to={"help"}
            style={{ textDecoration: "none" }}
            onClick={() => {
              Mixpanel.track("Btn Sidebar Soporte técnico", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
            }}
          >
            <Row
              className="justify-content-center align-items-center "
              style={{ height: "inherit" }}
            >
              <Col
                md={isExpanded ? 2 : 12}
                className={
                  !isExpanded
                    ? "sidebar-base__navbar-row__navbar-container__icon-container"
                    : "sidebar-base__navbar-row__navbar-container__icon-container__padding"
                }
              >
                <SupportAgentOutlinedIcon
                  className="heading__primary-color"
                  fontSize="large"
                />
              </Col>
              {isExpanded && (
                <Col className="sidebar-base__navbar-row__navbar-container__nav-element">
                  Soporte técnico
                </Col>
              )}
            </Row>
          </NavLink>
        </Row>
        <Row className="sidebar-base__header-row__header-container  container-fluid">
          <Button
            variant="text"
            className="sidebar-base__logout-row__button"
            onClick={() => {
              Mixpanel.track("Btn Sidebar Cerrar sesión", {
                email: payloadToken?.email,
                companyName: payloadToken?.companyName,
              });
              setOpenLogoutModal(true);
            }}
          >
            <Row className="w-100">
              <Col
                md={isExpanded ? 2 : 12}
                className="sidebar-base__logout-row__logout-container"
              >
                <img
                  alt="logout"
                  className="sidebar-base__logout-row__logout-icon"
                  src={require("../../assets/svg/logoutIcon.svg").default}
                />
              </Col>
              {isExpanded && (
                <Col className="sidebar-base__logout-row__logout-text">
                  Cerrar sesión
                </Col>
              )}
            </Row>
          </Button>
        </Row>

        <Divider />

        {/**FOOTER */}

        <Row className="sidebar-base__logo-row">
          <Col
            md={isExpanded ? 2 : 12}
            className="sidebar-base__logo-row__logo-container"
          >
            <img
              alt="singleIcon"
              className="sidebar-base__logo-row__logo-svg"
              src={require("../../assets/svg/singleLogo.svg").default}
            />
          </Col>
          {isExpanded && (
            <Col className="sidebar-base__logout-row__logout-icon">
              <p>Legal AI</p>
            </Col>
          )}
        </Row>

        <ModalDecision
          title={"Cerrar sesión"}
          message={"¿Está seguro?"}
          open={openLogoutModal}
          onClose={handleClose}
          agreeText="Cerrar sesión"
          disagreeText="Cancelar"
          handleAgree={handleLogoutAgree}
          handleDisagree={handleLogoutDisagree}
        />

        <DrawerSearchConsults
          open={openModalConsult}
          setOpen={setOpenModalConsult}
        />
      </Container>
    </div>
  );
};

export default Sidebar;
