import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import BaseTypeInput from "./BaseTypeInput";
import { useInfiniteDataScroll } from "../../hooks/useInfiniteDataScroll";
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  AddOutlined,
  DeleteOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import PropTypes from "prop-types";

// BEGINNING OF TYPE DEFINITIONS
/**
 * The type of the question.
 *
 * @typedef {Object} QuestionType
 * @property {string} _id The id of the question.
 * @property {string} value The value of the question.
 * @property {string[]} options The options of the question.
 * @property {{ required: { value: boolean } }} validations The validations of the question.
 */

// END OF TYPE DEFINITIONS

/**
 * The list question input component with a checkbox.
 *
 * @param {Object} props The props of the component.
 * @param {QuestionType} props.question The question to display.
 * @param {(question: QuestionType) => void} props.onDataChange The function to call when the data changes.
 * @param {() => void} props.onDelete The function to call when the question is deleted.
 * @returns The jsx element to render.
 */
export const TypeInputList = ({ question, onDataChange, onDelete }) => {
  const { options } = question;
  const [isEditable, setIsEditable] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [transformedOptions, setTransformedOptions] = useState(() => {
    if (options.length && typeof options[0] === "object") {
      return options.map((option, index) => ({
        ...option,
        id: index + 1,
        isEditing: false,
      }));
    } else if (options.length && typeof options[0] === "string") {
      return options.map((option, index) => ({
        id: index + 1,
        answerToShow: option,
        isEditing: false,
      }));
    } else {
      return [];
    }
  });
  const [saveChangesFlag, setSaveChangesFlag] = useState(false);
  const optionsListRef = useRef();
  const { filteredData, goToPageAndScroll } = useInfiniteDataScroll({
    elementRef: optionsListRef,
    data: transformedOptions,
  });

  const activeSaveChangesFlag = () => {
    setSaveChangesFlag(true);
  };

  const desactiveSaveChangesFlag = () => {
    setSaveChangesFlag(false);
  };

  const handleToggleIsEditable = (isEditable) => {
    setIsEditable(isEditable);
  };

  const handleOptionLabelChange = (id, newAnswerToShow) => {
    setTransformedOptions((prevTransformedOptions) =>
      prevTransformedOptions.map((option) =>
        option.id === id ? { ...option, answerToShow: newAnswerToShow } : option
      )
    );
    activeSaveChangesFlag();
  };

  const handleAddOption = () => {
    setTransformedOptions((prevTransformedOptions) => {
      const newOptionId = prevTransformedOptions.length
        ? prevTransformedOptions[prevTransformedOptions.length - 1].id + 1
        : 1;

      return [
        ...prevTransformedOptions,
        {
          id: newOptionId,
          answerToShow: `Opción ${newOptionId}`,
          isEditing: false,
        },
      ];
    });
    goToPageAndScroll(-1, "bottom");
    setIsOptionsOpen(true);
    activeSaveChangesFlag();
  };

  const handleDeleteOption = (id) => {
    setTransformedOptions((prevTransformedOptions) =>
      prevTransformedOptions.filter((option) => option.id !== id)
    );
    activeSaveChangesFlag();
  };

  const handleOpenOptions = () => {
    setIsOptionsOpen((prevState) => !prevState);
  };

  const handleEditOption = (id, isEditing) => {
    if (!isEditable) return;
    setTransformedOptions((prevTransformedOptions) =>
      prevTransformedOptions.map((option) =>
        option.id === id ? { ...option, isEditing } : option
      )
    );
  };

  const renderOption = (option) => (
    <Collapse
      key={`${option.answerToShow}-${option.id}`}
      in={isOptionsOpen}
      timeout="auto"
      className="caption"
    >
      <ListItem>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div onClick={() => handleEditOption(option.id, true)}>
            {option.isEditing ? (
              <TextField
                variant="standard"
                value={option.answerToShow}
                onChange={(event) =>
                  handleOptionLabelChange(option.id, event.target.value)
                }
                onBlur={() => handleEditOption(option.id, false)}
                autoFocus
                InputProps={{ classes: { input: "caption" } }}
              />
            ) : (
              option.answerToShow
            )}
          </div>
          {isEditable && (
            <IconButton onClick={() => handleDeleteOption(option.id)}>
              <DeleteOutlined
                fontSize="large"
                className="heading__primary-color"
              />
            </IconButton>
          )}
        </div>
      </ListItem>
    </Collapse>
  );

  const transformQuestionToOriginalStructure = (question) => {
    const optionsOriginalStructure = transformedOptions.map(
      (option) => option.answerToShow
    );

    return {
      ...question,
      options: optionsOriginalStructure,
    };
  };

  const saveChanges = (question) => {
    const questionOriginalStructure =
      transformQuestionToOriginalStructure(question);
    onDataChange(questionOriginalStructure);
    desactiveSaveChangesFlag();
  };

  return (
    <BaseTypeInput
      question={question}
      onDelete={onDelete}
      onDataChange={saveChanges}
      onToggleIsEditing={handleToggleIsEditable}
      saveChangesExternally={saveChangesFlag}
    >
      <Container>
        <List sx={{}}>
          <ListItemButton onClick={handleOpenOptions}>
            <ListItemText primary="Menú desplegable" />
            {isOptionsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <div
            ref={optionsListRef}
            style={{ maxHeight: "300px", overflowY: "auto" }}
          >
            {filteredData.map(renderOption)}
          </div>
          {isEditable && (
            <ListItem>
              <Button
                onClick={() => handleAddOption()}
                variant="contained"
                className="custom-input__button__primary-color"
                startIcon={<AddOutlined />}
              >
                Agregar opción
              </Button>
            </ListItem>
          )}
        </List>
      </Container>
    </BaseTypeInput>
  );
};

TypeInputList.propTypes = {
  question: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    validations: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
