import React from "react";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import NearbyErrorOutlinedIcon from "@mui/icons-material/NearbyErrorOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PopoverActions from "../Popover/PopoverActions";
import { Col } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { TYPES_RELEVANCE_CLAUSES } from "../../utils/codesReportsTypes";
export const ImportanceTag = ({ clause }) => {
  return (
    <>
      {clause?.importance &&
        clause?.importance?.relevance === TYPES_RELEVANCE_CLAUSES.RELEVANT && (
          <Col xs={"auto"}>
            <PopoverActions
              parentId="clause-parent"
              classNameButton="custom-input__button__default-other-color-dark"
              type="large"
              description={
                "Legal AI ha detectado que esta cláusula merece especial atención."
              }
              icon={<CheckOutlinedIcon fontSize="large" />}
              textIcon={"Importante"}
              sx={{ borderRadius: "4px" }}
            />
          </Col>
        )}

      {clause?.importance &&
        clause?.importance?.risk_grade <= TYPES_RELEVANCE_CLAUSES.RISKGRADE && (
          <Col xs={"auto"}>
            <Tooltip
              title={
                <h3>
                  {clause?.importance?.risk_grade === 2
                    ? "Sensibilidad Media"
                    : "Sensibilidad Alta"}
                </h3>
              }
            >
              <IconButton
                aria-label="blankspaces"
                onClick={() => console.log("close")}
                className={
                  clause?.importance?.risk_grade === 2
                    ? "icon-button__warningAdvices"
                    : "icon-button__dangerAdvices"
                }
              >
                {clause?.importance?.risk_grade === 2 ? (
                  <WarningAmberOutlinedIcon fontSize="large" />
                ) : (
                  <NearbyErrorOutlinedIcon fontSize="large" />
                )}
              </IconButton>
            </Tooltip>
          </Col>
        )}
    </>
  );
};
