/**
 * Check if a value is defined, that is, it is not undefined or null
 *
 * @param {any} value - Value to check
 * @returns {boolean} - True if the value is defined, false otherwise
 */
export const isDefined = (value) => {
  return value !== undefined && value !== null;
};

/**
 * Check if a value is a number or a string that can be converted to a number.
 * 
 * @param {string | number} value Value to check.
 * @returns {boolean} True if the value is a number, false otherwise.
 */
export const isNumber = (value) => {
  const validType = {
    string: "string",
    number: "number",
  };

  if (!Object.values(validType).includes(typeof value)) {
    return false;
  }

  if (typeof value === validType.string && value.trim() === "") {
    return false;
  }

  if (typeof value === validType.number || !isNaN(value)) {
    return true;
  }
};
