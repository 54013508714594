import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Breadcrum from "../../../../../../../parts/breadcrum";
import { Col, Container, Form, Row } from "react-bootstrap";

import { REGEXP } from "../../../../../../../utils/regexp";

import { Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedPart,
  resetPartListStatus,
  resetSignatoriesListStatus,
  setSelectedPart,
} from "../../../../../../../parts/parts/partsSlice";
import {
  findPartById,
  manageRelatedPartToSignatory,
  updatePart,
} from "../../../../../../../services/parts/partsServices";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../../../components/loadingContent";
import { CODES } from "../../../../../../../utils/codes";
import { UpdateContactInfo } from "./ContactInfo/updateContactInfo";
import { TYPE_CHARGES } from "../utils";
import ModalInfo from "../../../../../../../components/Modals/modalInfo";

export const UpdatePartNaturalLibrary = ({ role = "" }) => {
  //States
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowFormInfoContact, setIsShowFormInfoContact] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);

  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [selectedSignatories, setSelectedSignatories] = useState([]);
  const [deletedSignatoryList, setDeletedSignatoryList] = useState([]);
  const [isChangeValue, setIsChangeValue] = useState(false);

  //Redux
  const dispatch = useDispatch();
  const currentPart = useSelector(getSelectedPart);

  const [tagsSelected, setTagsSelected] = useState([]);

  //Router
  const navigate = useNavigate();

  //Const utils

  const modalInfoTitle =
    responseData.status === CODES.COD_RESPONSE_HTTP_OK &&
    responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ? "Actualización exitosa"
      : "Error al actualizar";

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({});

  useEffect(async () => {
    try {
      if (currentPart._id) {
        setValue("firstName", currentPart?.firstName);
        setValue("lastName", currentPart?.secondName);
        setValue("firstSurname", currentPart?.firstSurname);
        setValue("secondSurname", currentPart?.secondSurname);
        setValue("documentType", currentPart?.documentType);
        setValue("documentNumber", currentPart?.documentNumber);
        setValue("otherTypeDocument", currentPart?.otherTypeDocument);
        setValue("nameTradeNatural", currentPart?.comercialName);

        setValue("email", currentPart?.email);
        setValue("confirmEmail", currentPart?.email);

        setValue("countryCode", currentPart?.countryCodeNumber);
        setValue("numberPhone", currentPart?.phone);

        setValue("countryCodeTelephone", currentPart?.countryCodeTelephone);
        setValue("cityCode", currentPart?.cityCode);
        setValue("extension", currentPart?.extension);
        setValue("telephoneNumber", currentPart?.numberTelephone);

        setValue("country", currentPart?.country);
        setValue("province", currentPart?.province);
        setValue("city", currentPart?.city);

        setValue("address", currentPart?.physicalAddress);
        setSelectedSignatories(currentPart.relatedSignatories);
        const tags = currentPart.labels;
        const tagsId = tags.map((tag) => tag._id);
        setValue("labels", tagsId || []);
        setTagsSelected(tags);
      } else {
        setIsLoading(true);
        const service = await findPartById({ partId: id });
        if (
          service.status === CODES.COD_RESPONSE_HTTP_OK &&
          service?.data?.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          dispatch(setSelectedPart(service.data?.responseMessage?.data));
        }
        setIsLoading(false);
      }
    } catch (error) {}
  }, [currentPart]);

  //handles

  const handleShowForm = () => {
    setIsShowFormInfoContact(true);
  };

  const handleUpdatePart = async (data) => {
    try {
      setIsLoading(true);
      const company = localStorage.getItem("company");
      const { userId = "" } = JSON.parse(localStorage.getItem("payloadToken"));
      let identificationData = {};

      identificationData = {
        documentType: data?.documentType || "",
        documentNumber: data?.documentNumber || "",
        firstName: data?.firstName || "",
        firstSurname: data?.firstSurname || "",
        secondName: data?.lastName || "",
        secondSurname: data?.secondSurname || "",
        comercialName: data?.nameTradeNatural || "",

        charge: TYPE_CHARGES?.NATURAL,
      };

      const contactInfo = {
        email: data?.email || "",
        countryCodeNumber: data?.countryCode || "",
        countryCodeTelephone: data?.countryCodeTelephone || "",
        cityCode: data?.cityCode || "",
        extension: data?.extension || "",
        phone: data?.numberPhone || "",
        numberTelephone: data?.telephoneNumber || "",
        country: data?.country || "",
        city: data?.city || "",
        province: data?.province || "",
        physicalAddress: data?.address || "",
        otherTypeDocument: data?.otherTypeDocument || "",
      };
      const obj = {
        ...contactInfo,
        ...identificationData,

        modifiedBy: userId,
        company,

        relatedSignatories: selectedSignatories.map((item) => {
          return {
            signatory: item.signatory?._id,
            isDefault: item.isDefault,
            position: item.position,
          };
        }),
        partId: currentPart._id,
        labels: data?.labels || [],
      };
      const response = await updatePart(obj);
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        if (deletedSignatoryList.length > 0) {
          const transformedArrayForUpdate = deletedSignatoryList.map((item) => {
            return {
              part: currentPart._id,
              signatory: item.signatory._id,
              position: item.position || "Apoderado",
            };
          });
          const serviceRemovePartsToSignatories =
            await manageRelatedPartToSignatory({
              managePartToSignatories: transformedArrayForUpdate,
              action: "DELETE",
            });
          if (
            serviceRemovePartsToSignatories.status ===
              CODES.COD_RESPONSE_HTTP_OK &&
            serviceRemovePartsToSignatories?.data?.responseCode ===
              CODES.COD_RESPONSE_SUCCESS
          ) {
            setResponseData(response);
            setIsOpenModalInfo(true);
          }
        }
        setResponseData(response);
        setIsOpenModalInfo(true);
      } else if (response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED) {
        setResponseData(response);
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container fluid className="custom-container-scroll">
        {isLoading && <LoadingContent />}
        <Row className="mb-4">
          <Breadcrum />
        </Row>
        <Row>
          <Col className="home-init-cards-row__title-row mb-4">
            <span>Editar Parte</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-description mb-5">
            <p>Diligencia los siguientes campos para editar la Parte.</p>
          </Col>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit(handleUpdatePart)}>
            {!isShowFormInfoContact && (
              <>
                <Row>
                  <Col className="home-init-cards-row__title-row">
                    <span>Datos de identificación:</span>
                    {/* <PopoverInfo section={{ description: "Es la persona que directamente va a contratar, a tener derechos y/o obligaciones." }} /> */}
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Primer nombre
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("firstName", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Primer nombre"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.firstName
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.firstName?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Segundo nombre
                      </Form.Label>
                      <Form.Control
                        {...register("lastName", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("lastName", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Segundo nombre"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.lastName
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.lastName?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Primer apellido
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        {...register("firstSurname", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("firstSurname", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Primer apellido"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.firstSurname
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.firstSurname?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Segundo apellido
                      </Form.Label>
                      <Form.Control
                        {...register("secondSurname", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("secondSurname", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Segundo apellido"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.secondSurname
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.secondSurname?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Tipo de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Select
                        {...register("documentType", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentType", event.target.value);
                          setIsChangeValue(true);
                        }}
                        className="label"
                        defaultValue={""}
                      >
                        <option
                          value=""
                          disabled
                          defaultValue
                          className="label"
                        >
                          Tipo de documento
                        </option>
                        <option value="CEDULA DE CIUDADANIA" className="label">
                          Cédula de ciudadanía - COL
                        </option>
                        <option value="TARJETA DE IDENTIDAD" className="label">
                          Tarjeta de identidad - COL
                        </option>
                        <option value="CEDULA DE EXTRANJERIA" className="label">
                          Cédula de extranjería - COL
                        </option>
                        <option value="INE" className="label">
                          INE - MEX
                        </option>
                        <option value="CURP" className="label">
                          CURP - MEX
                        </option>
                        <option value="PASAPORTE" className="label">
                          Pasaporte
                        </option>
                        <option value="OTRO" className="label">
                          Otros
                        </option>
                      </Form.Select>
                      <div className="caption custom-input__error">
                        {errors?.documentType?.message}
                      </div>
                    </Form.Group>
                  </Col>
                  {watch("documentType") === "OTRO" && (
                    <Col xs={"auto"} md={"auto"} lg={4}>
                      <Form.Group>
                        <Form.Label className="form__label">
                          Otro tipo de documento
                        </Form.Label>
                        <Form.Control
                          {...register("otherTypeDocument")}
                          placeholder={"Ingrese tipo de documento"}
                          style={{
                            border: "0.1rem solid #c5c7d0",
                          }}
                          bsPrefix={
                            errors?.otherTypeDocument
                              ? "input-group-container__no-icon-error label"
                              : "input-group-container__no-icon label"
                          }
                        />
                        <div className="caption custom-input__error">
                          {errors?.otherTypeDocument?.message}
                        </div>
                      </Form.Group>
                    </Col>
                  )}
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Número de documento
                        <span style={{ color: "red" }}>{"* "}</span>
                      </Form.Label>
                      <Form.Control
                        {...register("documentNumber", {
                          required: {
                            value: true,
                            message: "*Este campo es obligatorio",
                          },
                          pattern: {
                            value: REGEXP.LETTERS_AND_NUMBERS,
                            message: "*Solo debe incluir numeros y letras",
                          },
                        })}
                        onChange={(event) => {
                          setValue("documentNumber", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Número de documento"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.documentNumber
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.documentNumber?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {watch("documentType") === "TARJETA DE IDENTIDAD" && (
                  <>
                    <br />
                    <Row>
                      <Col xs={"auto"} md={"auto"} lg={8}>
                        <h1 className="text-description">
                          Recuerda que los contratos, así como otros documentos
                          legales deben ser firmados por personas que sean
                          plenamente capaces legalmente.
                        </h1>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs={"auto"} md={"auto"} lg={4}>
                    <Form.Group>
                      <Form.Label className="form__label">
                        Nombre comercial
                      </Form.Label>
                      <Form.Control
                        {...register("nameTradeNatural", {
                          required: false,
                          maxLength: {
                            value: 250,
                            message: "Máximo 250 caracteres permitidos",
                          },
                        })}
                        onChange={(event) => {
                          setValue("nameTradeNatural", event.target.value);
                          setIsChangeValue(true);
                        }}
                        placeholder={"Nombre comercial"}
                        style={{
                          border: "0.1rem solid #c5c7d0",
                        }}
                        bsPrefix={
                          errors?.nameTradeNatural
                            ? "input-group-container__no-icon-error label"
                            : "input-group-container__no-icon label"
                        }
                      />
                      <div className="caption custom-input__error">
                        {errors?.nameTradeNatural?.message}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "end" }}>
                  <Col xs={"auto"}>
                    {isShowFormInfoContact && (
                      <Button
                        id={"back-button"}
                        type="button"
                        variant="contained"
                        className="custom-input__button__secondary-color"
                        // onClick={() => handleShow(false)}
                      >
                        Regresar
                      </Button>
                    )}
                  </Col>
                  <Col xs={"auto"}>
                    <Button
                      id={"save-button"}
                      type="button"
                      variant="contained"
                      className="custom-input__button__primary-color"
                      onClick={handleSubmit(handleShowForm)}
                    >
                      Siguiente
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {isShowFormInfoContact && (
              <UpdateContactInfo
                register={register}
                errors={errors}
                watch={watch}
                handleSubmit={handleSubmit}
                control={control}
                handleShow={setIsShowForm}
                setValue={setValue}
                handleBack={() => setIsShowFormInfoContact(false)}
                tagsSelected={tagsSelected}
                setTagsSelected={setTagsSelected}
                selectedSignatories={selectedSignatories}
                setSelectedSignatories={setSelectedSignatories}
                currentPart={currentPart}
                deletedSignatoryList={deletedSignatoryList}
                setDeletedSignatoryList={setDeletedSignatoryList}
                isChangeValue={isChangeValue}
              />
            )}
          </Form>

          <ModalInfo
            title={modalInfoTitle}
            responseData={responseData}
            onClose={() => {
              if (
                responseData.status === CODES.COD_RESPONSE_HTTP_OK &&
                responseData.data.responseCode === CODES.COD_RESPONSE_SUCCESS
              ) {
                if (role === "ADMIN") {
                  navigate("../library");
                } else if (role === "SERVICE") {
                  navigate("../library/partsAndSignatures");
                }
                dispatch(resetPartListStatus());
                dispatch(resetSignatoriesListStatus());
              }
              setIsOpenModalInfo(false);
            }}
            open={isOpenModalInfo}
            buttonClose={true}
            confirmationText="Aceptar"
            handleCloseDefault={() => setIsOpenModalInfo(false)}
          />
        </Row>
      </Container>
    </>
  );
};
