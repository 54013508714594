import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  setHeader,
  setIdMongo,
  setInfoDocument,
  setInitialInfoLibrary,
  setName,
  setSelectedClausesWithoutCheckPart,
} from "../../../../../../parts/document/currentDocumentSlice";
import { findRulesByCorporateUnit } from "../../../../../../services/admin/rules/rulesServices";
import {
  getDocById,
  getFileDocument,
} from "../../../../../../services/documents/documentsRequest";
import { convertToPdfService } from "../../../../../../services/utils/convertWordToPdf";
import { createNewVersion } from "../../../../../../services/versions/versionRequest";
import {
  FROM_REVIEW,
  REVIEW_TYPES,
} from "../../../../../../utils/approvalsState";
import { CODES } from "../../../../../../utils/codes";
import { codesDocumentType } from "../../../../../../utils/codesDocumentType";
import { Buffer } from "buffer";
import { Mixpanel } from "../../../../../../utils/mixPanel";
import axios from "axios";
import GetToken from "../../../../../../utils/getToken";
import WorkerBuilder from "../../../../../../workers/worker-builder";
import WorkerOtherDocs from "../../../../../../workers/reviewOtherDocumentWorker";
import WorkerContract from "../../../../../../workers/reviewContractWorker";
import { env } from "../../../../../../env";
import {
  selectListInfoForModule,
  updateListBackgroundProcesses,
} from "../../../../../../parts/backgroundProcess/backgroundProcessSlice";
import {
  deleteFileFromIndexedDB,
  getFileFromIndexedDB,
  saveFileToIndexedDB,
} from "../../../../../../utils/indexdb";

const SelectedDocumentOptions = Object.freeze({
  CONTRACT: "Contrato",
  OTHER: "Otro",
});

/**
 * Custom hook to manage the review of documents on background.
 *
 * For correct use, the hook MUST be used with the component `LoadingContentInBackground`.
 *
 * @param {Object} props - The props of the hook.
 * @param {String} props.selectedDocument - The selected document type.
 * @param {Object} props.documentData - The document file data.
 * @param {Object} [props.versionSelected] - The selected version, by default is an empty object.
 * @param {Object} [props.documentSelected] - The selected document, by default is an empty object.
 * @param {String} [props.reviewRule] - The review rule, by default is an empty string.
 * @param {Boolean} [props.replaceRedirectionUrls] - The replace redirection urls, by default is false.
 * @param {Object} [props.modals] - The modals functions.
 */
const useReviewDocument = ({
  selectedDocument,
  documentData,
  versionSelected = {},
  documentSelected = {},
  reviewRule = "",
  replaceRedirectionUrls = false,
  modals: {
    setOpenModalSave = () => {},
    setIsOpenModalLimitPlan = () => {},
    setIsOpenModalError = () => {},
    setIsOpenModalInfoRulesNotFound = () => {},
  },
}) => {
  // WORKER

  const reviewWorkerRef = useRef(null);
  const reviewWorkerRefContract = useRef(null);

  // Data contract background

  const listInfoContractBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessReviewContract")
  );

  const listInfoContractBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoContractBackground) {
      let currentInfo = {};
      listInfoContractBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoContractBackgroundRef.current = currentInfo;
    }
  }, [listInfoContractBackground]);

  // Data review background

  const listInfoReviewBackground = useSelector((state) =>
    selectListInfoForModule(state, "backgroundProcessReview")
  );

  const listInfoReviewBackgroundRef = useRef();

  useEffect(() => {
    if (listInfoReviewBackground) {
      let currentInfo = {};
      listInfoReviewBackground.forEach((item) => {
        currentInfo = {
          ...currentInfo,
          [item.id]: item,
        };
      });
      listInfoReviewBackgroundRef.current = currentInfo;
    }
  }, [listInfoReviewBackground]);

  // Constants

  const token = GetToken();
  const serviceUrl = env.REACT_APP_API_URL;

  // Hooks

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();

  // States

  const [isLoading, setIsloading] = useState(false);
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(false);
  const [allowBackgroundOptions, setAllowBackgroundOptions] = useState(true);
  const [listRules, setListRules] = useState([]);
  const [notFoundRulesMessage, setNotFoundRulesMessage] = useState({});
  const [moduleName, setModuleName] = useState("");
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
  const [isEmptyDocumentLoad, setIsEmptyDocumentLoad] = useState(false);
  const [isEmptyFolderSelected, setIsEmptyFolderSelected] = useState(false);
  const [responseDataError, setResponseDataError] = useState({});
  const [idProcess, setIdProcess] = useState(null);

  // Functions

  const setInfoDocumentState = (document) => {
    const a = {
      ...document.documentContent.parts.partA,
    };
    const b = {
      ...document.documentContent.parts.partB,
    };

    let parts = [a, b];

    if (document.documentContent.parts.partC) {
      const c = {
        ...document.documentContent.parts.partC,
      };
      parts.push(c);
    }

    if (document.documentContent.parts.partD) {
      const d = {
        ...document.documentContent.parts.partD,
      };
      parts.push(d);
    }

    const finalObj = {
      title: document.documentContent.title,
      parts: parts,
      summary: document.summary,
      represented: document.represented,
    };
    dispatch(setInitialInfoLibrary(finalObj));
    dispatch(
      setSelectedClausesWithoutCheckPart({
        clauses: document?.clauses?.map((cla) => {
          const partsClause = [cla.parts.partA, cla.parts.partB];
          if (cla.parts.partC) partsClause.push(cla.parts.partC);
          if (cla.parts.partD) partsClause.push(cla.parts.partD);
          return cla?.parts ? { ...cla, parts: partsClause } : cla;
        }),
      })
    );
    dispatch(
      setName({
        name: document.documentContent.name,
      })
    );
    dispatch(
      setIdMongo({
        id: document.documentContent._id,
      })
    );
    for (const [i] of document.documentContent.headers.entries()) {
      dispatch(setHeader(document.documentContent.headers[i]));
    }
    dispatch(
      setInfoDocument({
        filenameInBucket: document.filenameInBucket,
        bucketName: document.bucketName,
      })
    );
  };

  // Handlers

  const handleRequest = async (data) => {
    if (!documentData.name && !documentSelected?.name) {
      setIsEmptyDocumentLoad(true);
      return;
    }
    if (!versionSelected._id && data.name) {
      setIsEmptyFolderSelected(true);
      return;
    }
    try {
      setOpenModalSave(false);
      setIsOpenModalLimitPlan(false);

      const payloadToken = JSON.parse(localStorage.payloadToken);

      const represented =
        data.represented === "OTRO" ? data.otherRepresented : data.represented;

      let paramsContract = {};
      if (
        documentSelected?.fileType === codesDocumentType.contract._id ||
        documentSelected?.fileType === codesDocumentType.draft._id
      ) {
        paramsContract = {
          company: payloadToken.company,
          document: JSON.stringify(documentSelected?.documentContent),
          from: FROM_REVIEW.CLAUSES,
          typeReview: REVIEW_TYPES.NEW_LEGAL,
          bucketName: documentSelected?.bucketName,
          filenameInBucket: documentSelected?.filenameInBucket,
        };
      } else if (!documentData?.name) {
        paramsContract = {
          company: payloadToken.company,
          bucketName: documentSelected?.bucketName,
          filenameInBucket: documentSelected?.filenameInBucket,
          from: FROM_REVIEW.DOCUMENTS,
          typeReview: REVIEW_TYPES.NEW_LEGAL,
        };
      } else {
        let dataFile = documentData;
        if (documentData?.type !== "application/pdf") {
          setIsloading(true);
          const convertDoc = await convertToPdfService(documentData, "file");
          const docView = convertDoc.data.responseMessage.data.buffer.data;
          const bf = Buffer.from(docView);
          const name = documentData?.name.substring(
            0,
            documentData?.name.lastIndexOf(".")
          );
          dataFile = new File([bf], name + ".pdf", {
            type: "application/pdf",
          });
          setIsloading(false);
        }
        paramsContract = {
          file: dataFile,
          company: payloadToken.company,
          from: FROM_REVIEW.FILE,
          typeReview: REVIEW_TYPES.NEW,
        };
      }
      if (reviewWorkerRefContract.current) {
        const idProcess = uuidv4();
        const nameFile = documentSelected?.name || documentData?.name || "";
        setIsLoadingInBackground(true);
        setModuleName("backgroundProcessReviewContract");
        setIdProcess(idProcess);
        reviewWorkerRefContract.current.postMessage({
          paramsContract,
          token,
          serviceUrl,
          idProcess,
        });
        const idFileIDB = uuidv4();
        await saveFileToIndexedDB(documentData, idFileIDB);
        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessReviewContract",
            updateData: {
              id: idProcess,
              nameFile,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {
                url: "/service/review/first-Time",
                otherInfo: {
                  data: { name: data.name, represented },
                  paramsContract: { typeReview: paramsContract.typeReview },
                  versionSelected: versionSelected,
                  idFile: idFileIDB,
                },
              },
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setResponseDataError({
          status: 500,
          data: {
            responseMessage:
              "El proceso esta tomando más tiempo de lo normal, por favor intentelo más tarde.",
          },
        });
        setIsOpenModalError(true);
      }
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const handleProcessContractInfo = async (
    segmentDoc,
    data,
    paramsContract,
    versionSelected,
    documentData,
    idFileIDB
  ) => {
    const corporateUnit = localStorage.getItem("corporateUnitId");

    if (
      !segmentDoc?.data?.success &&
      segmentDoc?.status !== CODES.COD_RESPONSE_HTTP_OK
    ) {
      setIsLoadingInBackground(false);
      setResponseDataError(segmentDoc);
      setIsOpenModalError(true);
      Mixpanel.track("Msj error segmentando este documento ", {
        email: payloadToken?.email,
        companyName: payloadToken?.companyName,
      });
      return;
    }
    if (data.name) {
      const version = await createNewVersion({
        name:
          documentData.type === "application/pdf"
            ? `${data.name}_revisado.pdf`
            : `${data.name}_revisado.docx`,
        fileType: codesDocumentType.contract._id,
        documentContent: {
          ...segmentDoc.data.responseMessage,
          author: data.owner,
          name:
            documentData.type === "application/pdf"
              ? `${data.name}_revisado.pdf`
              : `${data.name}_revisado.docx`,
        },
        file: documentData,
        folderAssociated: versionSelected._id,
        corporateUnit,
        inReview: true,
        reviewCounts: 1,
      });
      const newDocument = await getDocById(version.data.responseMessage._id);
      await deleteFileFromIndexedDB(idFileIDB);
      if (
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_ERROR ||
        version.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED ||
        version.status === CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setIsloading(false);
      } else if (
        segmentDoc.data.success &&
        segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED &&
        version.data.success &&
        version.status === CODES.COD_RESPONSE_HTTP_CREATED
      ) {
        setIsloading(false);
        setOpenModalSave(false);
        setInfoDocumentState({
          ...newDocument.data.responseMessage,
          clauses: segmentDoc.data.responseMessage.clauses,
          summary: segmentDoc.data.responseMessage.summary,
          represented: data.represented,
        });
        const idDocument =
          paramsContract.typeReview === REVIEW_TYPES.NEW
            ? "NEW"
            : newDocument?.data?.responseMessage?._id;
        navigate(`view-document/${idDocument}`, {
          replace: replaceRedirectionUrls,
        });
      }
    } else {
      setIsloading(false);
      setOpenModalSave(false);
      const a = { ...segmentDoc.data.responseMessage.parts.partA };
      const b = { ...segmentDoc.data.responseMessage.parts.partB };

      let parts = [a, b];

      if (segmentDoc.data.responseMessage.parts.partC) {
        const c = { ...segmentDoc.data.responseMessage.parts.partC };
        parts.push(c);
      }

      if (segmentDoc.data.responseMessage.parts.partD) {
        const d = { ...segmentDoc.data.responseMessage.parts.partD };
        parts.push(d);
      }

      const finalObj = {
        title: segmentDoc.data.responseMessage.title,
        parts: parts,
        summary: segmentDoc.data.responseMessage.summary,
        represented: data.represented,
      };
      dispatch(setInitialInfoLibrary(finalObj));

      const clausesDocument = segmentDoc?.data?.responseMessage?.clauses || [];

      dispatch(
        setSelectedClausesWithoutCheckPart({
          clauses: clausesDocument?.map((cla) => {
            return cla.parts
              ? {
                  ...cla,
                  parts: cla.parts.partD
                    ? [
                        cla.parts.partA,
                        cla.parts.partB,
                        cla.parts.partC,
                        cla.parts.partD,
                      ]
                    : cla.parts.partC
                    ? [cla.parts.partA, cla.parts.partB, cla.parts.partC]
                    : [cla.parts.partA, cla.parts.partB],
                }
              : cla;
          }),
        })
      );
      dispatch(
        setName({
          name: segmentDoc.data.responseMessage.name,
        })
      );

      const headersDocument = segmentDoc?.data?.responseMessage?.headers || [];

      for (const [i] of headersDocument.entries()) {
        dispatch(setHeader(segmentDoc.data.responseMessage.headers[i]));
      }
      let bucketName, filenameInBucket;
      if (paramsContract.typeReview === REVIEW_TYPES.NEW) {
        filenameInBucket = segmentDoc.data.responseMessage.filenameInBucket;
        bucketName = segmentDoc.data.responseMessage.bucketName;
      } else {
        bucketName = documentSelected?.bucketName;
        filenameInBucket = documentSelected?.filenameInBucket;
      }

      dispatch(
        setInfoDocument({
          bucketName,
          filenameInBucket,
        })
      );
      const idDocument =
        paramsContract.typeReview === REVIEW_TYPES.NEW
          ? "NEW"
          : segmentDoc?.data?.responseMessage?.versionAssociated;

      navigate(`view-document/${idDocument}`, {
        replace: replaceRedirectionUrls,
      });
    }
  };

  const getFileToReview = async ({ filenameInBucket, bucketName }) => {
    try {
      let response = {};

      const document = await getFileDocument(
        filenameInBucket,
        bucketName,
        true
      );

      response = document?.data?.responseMessage;

      const { buffer, contentType } = document.data.responseMessage;
      const blob = new Blob([new Uint8Array(buffer?.data).buffer], {
        type: contentType,
      });

      if (
        contentType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const dataFile = await convertToPdfService(blob);
        response = dataFile.data.responseMessage?.data;
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleReviewOtherDocuments = async () => {
    try {
      setIsLoadingInBackground(true);
      let file = documentData;
      if (documentSelected?.name) {
        setAllowBackgroundOptions(false);
        const docFile = await getFileToReview(documentSelected);
        setAllowBackgroundOptions(true);
        const bf = Buffer.from(docFile?.buffer?.data);
        const nameFile = docFile?.originalname
          ? docFile?.originalname.substring(
              0,
              docFile?.originalname.lastIndexOf(".")
            )
          : docFile?.name.substring(0, docFile?.name.lastIndexOf("."));
        file = new File([bf], `${nameFile}.pdf`, {
          type: "application/pdf",
        });
      }
      const company = localStorage.getItem("company");
      const obj = {
        documentId: Object.keys(documentSelected).length
          ? documentSelected._id
          : null,
        company,
        file,
        type: reviewRule?.name,
      };

      if (reviewWorkerRef.current) {
        const idProcess = uuidv4();
        const nameFile = documentSelected?.name || documentData?.name || "";
        setModuleName("backgroundProcessReview");
        setIdProcess(idProcess);
        reviewWorkerRef.current.postMessage({
          obj,
          token,
          reviewRule,
          serviceUrl,
          idProcess,
        });
        dispatch(
          updateListBackgroundProcesses({
            type: "ADD",
            id: idProcess,
            module: "backgroundProcessReview",
            updateData: {
              id: idProcess,
              nameFile,
              isFetchingInfo: true,
              finishProcessing: false,
              navigationInfo: {},
              userLeft: false,
            },
          })
        );
      } else {
        console.error("Worker not initialized");
      }
    } catch (error) {
      console.error(error);
      setIsLoadingInBackground(false);
    } finally {
      setIsloading(false);
    }
  };

  const handleMessageBackgroundOtherDocs = useCallback((event) => {
    const { data, reviewRule, idProcess } = event.data;

    if (event.data.success) {
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReview",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: true,
            navigationInfo: {
              url: `/service/review/first-Time/another-document`,
              state: {
                data,
                rule: reviewRule,
                processId: idProcess,
              },
            },
          },
        })
      );
      const userLeft =
        listInfoReviewBackgroundRef.current?.[idProcess]?.userLeft;
      if (!userLeft) {
        dispatch(
          updateListBackgroundProcesses({
            type: "DELETE",
            module: "backgroundProcessReview",
            id: idProcess,
          })
        );

        navigate(`another-document`, {
          state: {
            data,
            rule: reviewRule,
          },
        });
      }
    } else {
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReview",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: false,
            isErrorProcessing: true,
            navigationInfo: {},
          },
        })
      );
    }

    const itemsRef = Object.values(listInfoReviewBackgroundRef.current);

    if (!itemsRef.length) {
      reviewWorkerRef.current.removeEventListener(
        "message",
        handleMessageBackgroundOtherDocs
      );
      reviewWorkerRef.current.terminate();
    }

    setIsLoadingInBackground(false);
    setIsloading(false);
  }, []);

  const handleMessageBackgroundContract = useCallback(async (event) => {
    const success = event.data.data.success;
    const segmentDoc = event.data;
    const idProcess = event.data.idProcess;
    if (success) {
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReviewContract",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: true,
            navigationInfo: {
              idProcess,
              url: `/service/review/first-Time`,
              state: {
                processId: idProcess,
              },
              otherInfo: {
                finishProcess: true,
                segmentDoc,
                data:
                  listInfoContractBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo?.data || {},
                paramsContract:
                  listInfoContractBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo?.paramsContract,
                idFile:
                  listInfoContractBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo?.idFile,

                versionSelected:
                  listInfoContractBackgroundRef.current?.[idProcess]
                    ?.navigationInfo?.otherInfo?.versionSelected,
              },
            },
          },
        })
      );
      setIsloading(false);
      const userLeft =
        listInfoContractBackgroundRef.current?.[idProcess]?.userLeft;

      if (!userLeft) {
        setIsloading(true);
        const idFileIDB =
          listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.idFile;
        const file = await getFileFromIndexedDB(idFileIDB);
        await handleProcessContractInfo(
          segmentDoc,
          listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.data || {},
          listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.paramsContract,
          listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.versionSelected || {},
          file,
          listInfoContractBackgroundRef.current?.[idProcess]?.otherInfo?.idFile
        );
        dispatch(
          updateListBackgroundProcesses({
            type: "DELETE",
            module: "backgroundProcessReviewContract",
            id: idProcess,
          })
        );
      }

      const itemsRef = Object.values(listInfoContractBackgroundRef.current);

      if (
        (segmentDoc.status === CODES.COD_RESPONSE_HTTP_OK ||
          segmentDoc.status === CODES.COD_RESPONSE_HTTP_CREATED) &&
        !itemsRef.length
      ) {
        reviewWorkerRefContract.current.removeEventListener(
          "message",
          handleMessageBackgroundContract
        );
        reviewWorkerRefContract.current.terminate();
      }
    } else {
      const userLeft =
        listInfoContractBackgroundRef.current?.[idProcess]?.userLeft;
      if (!userLeft) {
        setIsloading(false);
        setIsLoadingInBackground(false);
        setResponseDataError({
          status: 500,
          data: { responseMessage: "Error segmentando el documento" },
        });
        setIsOpenModalError(true);
        Mixpanel.track("Msj error segmentando este documento ", {
          email: payloadToken?.email,
          companyName: payloadToken?.companyName,
        });
      }
      dispatch(
        updateListBackgroundProcesses({
          id: idProcess,
          type: "UPDATE",
          module: "backgroundProcessReviewContract",
          updateData: {
            isFetchingInfo: false,
            finishProcessing: false,
            isErrorProcessing: true,
            navigationInfo: {},
          },
        })
      );
    }
  }, []);

  // Refs

  const handleMessageRef = useRef(handleMessageBackgroundOtherDocs);
  const handleMessageRefContract = useRef(handleMessageBackgroundContract);

  // UseEffects

  useEffect(() => {
    if (!reviewWorkerRef.current) {
      reviewWorkerRef.current = new WorkerBuilder(WorkerOtherDocs);
    }
    reviewWorkerRef.current.removeEventListener(
      "message",
      handleMessageRef.current
    );

    reviewWorkerRef.current.addEventListener(
      "message",
      handleMessageBackgroundOtherDocs
    );
  }, [handleMessageBackgroundOtherDocs]);

  useEffect(() => {
    if (!reviewWorkerRefContract.current) {
      reviewWorkerRefContract.current = new WorkerBuilder(WorkerContract);
    }
    reviewWorkerRefContract.current.removeEventListener(
      "message",
      handleMessageRefContract.current
    );

    reviewWorkerRefContract.current.addEventListener(
      "message",
      handleMessageBackgroundContract
    );
  }, [handleMessageBackgroundContract]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        if (selectedDocument === "Otro") {
          const corporateUnit = localStorage.getItem("corporateUnitId");
          const response = await findRulesByCorporateUnit(corporateUnit);
          if (
            response.status === CODES.COD_RESPONSE_HTTP_OK &&
            response.data.success
          ) {
            const rules = response?.data?.responseMessage?.data;
            setListRules(rules);
            return;
          }
          setNotFoundRulesMessage({
            status: 401,
            data: { message: response?.data?.responseMessage },
          });
          setIsOpenModalInfoRulesNotFound(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsloading(false);
      }
    };
    fetchData();
  }, [selectedDocument]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const idProcess = locationState?.processId;
      if (
        listInfoContractBackgroundRef.current?.[idProcess]?.finishProcessing
      ) {
        setIsloading(true);
        const idFileIDB =
          listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
            ?.otherInfo?.idFile;
        const file = await getFileFromIndexedDB(idFileIDB);
        try {
          await handleProcessContractInfo(
            listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.segmentDoc,
            listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.data || {},
            listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.paramsContract,
            listInfoContractBackgroundRef.current?.[idProcess]?.navigationInfo
              ?.otherInfo?.versionSelected || {},
            file
          );
          dispatch(
            updateListBackgroundProcesses({
              type: "DELETE",
              module: "backgroundProcessReviewContract",
              id: idProcess,
            })
          );
        } catch (error) {
          console.error(error);
        } finally {
          setIsloading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [listInfoContractBackgroundRef, locationState]);

  return {
    idProcess,
    isLoading,
    isLoadingInBackground,
    setIsLoadingInBackground,
    allowBackgroundOptions,
    listRules,
    notFoundRulesMessage,
    moduleName,
    isEmptyDocumentLoad,
    setIsEmptyDocumentLoad,
    isEmptyFolderSelected,
    setIsEmptyFolderSelected,
    responseDataError,
    handleRequest,
    handleReviewOtherDocuments,
  };
};

useReviewDocument.propTypes = {
  selectedDocument: PropTypes.oneOf(Object.values(SelectedDocumentOptions))
    .isRequired,
  documentData: PropTypes.any.isRequired,
  versionSelected: PropTypes.object,
  documentSelected: PropTypes.object,
  reviewRule: PropTypes.string,
  modals: PropTypes.shape({
    setOpenModalSave: PropTypes.func,
    setIsOpenModalLimitPlan: PropTypes.func,
    setIsOpenModalError: PropTypes.func,
    setIsOpenModalInfoRulesNotFound: PropTypes.func,
  }),
};

export default useReviewDocument;
