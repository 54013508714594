import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Button, Drawer } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setFolderStatus } from "../../parts/folders/foldersSlice";
import { getFileDocument } from "../../services/documents/documentsRequest";
import { SIGNATORY_PROPERTIES_TRANSLATION } from "../../services/parts/consts/partsConsts";
import { convertToPdfService } from "../../services/utils/convertWordToPdf";
import { updateStateGeneralDocument } from "../../services/versions/versionRequest";
import { CODES } from "../../utils/codes";
import { codesDocumentType } from "../../utils/codesDocumentType";
import { DOCUMENT_STATE } from "../../utils/documentStates";
import LoadingContent from "../loadingContent";

const DetailsBar = ({
  openDetailsDrawner,
  setOpenDetailsDrawner,
  selectedElement,
  pageName
}) => {
  //STATES
  const [isLoading, setIsLoading] = useState(false);
  const listElements = {
    folder: "Carpeta",
    alert: "alerta",
    clause: "Cláusula",
    minute: "Minuta",
    document: "Documento",
    Parte: "la parte",
    Firmante: "del firmante",
    Certificado: "del certificado",
    predeterminatedText: "bloques",
    documentTemplate: "plantilla",
    conceptLegal: "concepto jurídico",
  };

  //HOOKS
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //HANDLES
  const handlePreviewPdf = async (version) => {
    try {
      setIsLoading(true);
      const getRequest = await getFileDocument(
        version.filenameInBucket,
        version.bucketName,
        version.isWord
      );
      if (
        getRequest.status === CODES.COD_RESPONSE_HTTP_OK &&
        getRequest.data.success
      ) {
        let docView = getRequest.data.responseMessage.buffer;
        if (
          getRequest.data.responseMessage.contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const { data } = getRequest.data.responseMessage.buffer || {};
          const { contentType } = getRequest.data?.responseMessage || {};
          const blob = new Blob([new Uint8Array(data).buffer], {
            type: contentType,
          });
          const pdfConverted = await convertToPdfService(blob);

          docView = pdfConverted?.data?.responseMessage?.data?.buffer;
        }
        const file = URL.createObjectURL(
          new Blob([new Uint8Array(docView.data).buffer], {
            type: "application/pdf",
          })
        );
        window.open(file);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getpageName = (pageName) => {
    if (pageName === "Alert") {
      return listElements.alert;
    } else if (pageName === "Clause") {
      return listElements.clause;
    } else if (pageName === "Document") {
      return listElements.document;
    } else if (pageName === "Minute") {
      return listElements.minute;
    } else if (pageName === "Parte") {
      return listElements.Parte;
    } else if (pageName === "predeterminatedText") {
      return listElements.predeterminatedText;
    } else if (pageName === "documentTemplate") {
      return listElements.documentTemplate;
    } else if (pageName === "conceptLegal") {
      return listElements.conceptLegal;
    } else {
      return listElements.folder;
    }
  };

  const getFieldName = (field) => {
    if (pageName === "Alert") {
      return `${field} de la alerta`;
    } else {
      return field;
    }
  };

  const getDateField = () => {
    if (pageName === "Alert") {
      return `Alerta creada por`;
    } else {
      return `Usuario que creo`;
    }
  };

  const getModifiedField = (item) => {
    if (item.lastUpdatesTrace) {
      return item?.lastUpdatesTrace
        ?.map(
          (traceabilityEntry) =>
            SIGNATORY_PROPERTIES_TRANSLATION[traceabilityEntry?.propertyName]
        )
        ?.join(", ");
    } else {
      return "";
    }
  };

  const getModifiedDateField = () => {
    if (pageName === "Alert") {
      return `Alerta modificada por última vez por`;
    } else {
      return `Usuario que hizo la última modificación`;
    }
  };
  const handleChangeState = async () => {
    try {
      const requestChangeStatus = await updateStateGeneralDocument({
        newStatus: DOCUMENT_STATE.active._id,
        id: selectedElement?._id,
      });
      if (
        requestChangeStatus.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestChangeStatus.data.success
      ) {
        dispatch(setFolderStatus({ newStatus: "fetch" }));
        setOpenDetailsDrawner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openDetailsDrawner}
      onClose={() => {
        setOpenDetailsDrawner(false);
      }}
    >
      <Container fluid style={{ width: "44.7rem", height: "inherit" }}>
        {isLoading && <LoadingContent />}
        <Row className="col-flex" style={{ height: "10%" }}>
          <Col className="heading" md={10}>
            {`Detalles de ${getpageName(pageName)?.toLowerCase()}`}
          </Col>
          <Col md={1}>
            <CloseIcon
              fontSize="large"
              onClick={() => {
                setOpenDetailsDrawner(false);
              }}
            />
          </Col>
        </Row>
        <hr style={{ margin: "0" }} />
        <Row>
          <Container fluid style={{ paddingLeft: "5%" }}>
            <Row
              style={{ height: "auto", marginTop: "5%", marginBottom: "3%" }}
            >
              <Col md={"auto"} className="subheading">
                {getFieldName("Nombre")}:
              </Col>
              <Col className="caption">
                {selectedElement?.name
                  ? selectedElement?.name
                  : selectedElement?.title}
              </Col>
            </Row>

            {(pageName === "FolderBasket" ||
              pageName === "Folder" ||
              pageName === "Alert") && (
              <Row style={{ height: "auto", marginBottom: "3%" }}>
                <Col md={"auto"} className="subheading">
                  {getFieldName("Descripción")}:
                </Col>
                <Col md={"auto"} className="caption">
                  {selectedElement?.description}
                </Col>
              </Row>
            )}

            {(pageName === "FolderBasket" || pageName === "Folder") && (
              <Row
                style={{
                  height: "auto",
                  marginBottom: "3%",
                }}
              >
                <Col md={"auto"} className="subheading">
                  Etiquetas:
                </Col>
                <Col className="caption">
                  <Row style={{ rowGap: "3%", columnGap: "2%" }}>
                    {selectedElement?.labels?.length > 0 ? (
                      selectedElement.labels.map((label) => (
                        <Col
                          className="drawner-details__labels"
                          md={"auto"}
                          key={label._id}
                        >
                          {label.name}
                        </Col>
                      ))
                    ) : (
                      <Col md={"auto"} className="caption">
                        Sin etiquetas
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )}
            {(pageName === "Document" || pageName === "DocumentBasket") && (
              <>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Estado del documento:
                  </Col>
                  <Col md={"auto"} className="caption">
                    {selectedElement?.status.name}
                  </Col>
                  {codesDocumentType?.contract?._id ===
                    selectedElement?.fileType &&
                    selectedElement?.status?._id ===
                      DOCUMENT_STATE.archived._id && (
                      <Col
                        md={"auto"}
                        className="caption__other-Color-Active"
                        onClick={handleChangeState}
                      >
                        Cambiar a estado activo
                      </Col>
                    )}
                </Row>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Tipo de documento
                  </Col>
                  <Col className="caption">
                    {
                      Object.values(codesDocumentType).find(
                        (code) => code._id === selectedElement?.fileType
                      )?.name
                    }
                  </Col>
                </Row>
              </>
            )}
            <Row style={{ height: "auto", marginBottom: "3%" }}>
              <Col md={"auto"} className="subheading">
                Fecha y hora de creación:
              </Col>
              <Col className="caption">
                {moment(selectedElement?.createdAt).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </Col>
            </Row>
            {selectedElement?.createdBy && (
              <Row style={{ height: "auto", marginBottom: "3%" }}>
                <Col md={"auto"} className="subheading">
                  {getDateField()}:
                </Col>
                <Col className="caption">
                  {`${selectedElement?.createdBy?.firstName || ""} ${
                    selectedElement?.createdBy?.firstSurname || ""
                  }`}
                  {(!selectedElement?.createdBy?.firstName ||
                    !selectedElement?.createdBy?.firstSurname) &&
                    `${
                      selectedElement?.createdBy?.companies[0]?.companyData
                        ?.companyName || ""
                    }`}
                </Col>
              </Row>
            )}

            {selectedElement?.updatedAt && selectedElement?.modifiedBy && (
              <Row style={{ height: "auto", marginBottom: "3%" }}>
                <Col md={"auto"} className="subheading">
                  Fecha de última modificación:
                </Col>
                <Col md={"auto"} className="caption">
                  {selectedElement?.updatedAt &&
                    moment(selectedElement?.updatedAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                </Col>
              </Row>
            )}
            {selectedElement?.modifiedBy && (
              <>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    {getModifiedDateField()}:
                  </Col>
                  <Col className="caption">
                    {`${selectedElement?.modifiedBy?.firstName || ""} ${
                      selectedElement?.modifiedBy?.firstSurname || ""
                    }`}
                    {(!selectedElement?.modifiedBy?.firstName ||
                      !selectedElement?.modifiedBy?.firstSurname) &&
                      `${
                        selectedElement?.modifiedBy?.companies[0]?.companyData
                          ?.companyName || ""
                      }`}
                    {pageName === "Alert" &&
                      ` en ${getModifiedField(selectedElement)}`}
                  </Col>
                </Row>
              </>
            )}
            {selectedElement?.observations && (
              <Row
                id="observations"
                style={{ height: "auto", marginBottom: "3%" }}
              >
                <Col md={"auto"} className="subheading">
                  Observaciones:
                </Col>
                <Col className="caption">
                  {typeof selectedElement?.observations === "string" ? (
                    `${selectedElement?.observations}`
                  ) : (
                    <ul>
                      {Object.entries(selectedElement?.observations || {}).map(
                        ([key, value]) => (
                          <li key={key}>{`${key}: ${value}`}</li>
                        )
                      )}
                    </ul>
                  )}
                </Col>
              </Row>
            )}
            {selectedElement?.reviewCounts > 0 && (
              <Row
                id="number-reviews"
                style={{ height: "auto", marginBottom: "3%" }}
              >
                <Col md={"auto"} className="subheading">
                  Número de revisiones:
                </Col>
                <Col className="caption">
                  {`${selectedElement?.reviewCounts}`}
                </Col>
              </Row>
            )}
            {pageName === "Minute" && (
              <>
                <Row
                  id="state-minute"
                  style={{ height: "auto", marginBottom: "3%" }}
                >
                  <Col md={"auto"} className="subheading">
                    Estado:
                  </Col>
                  <Col className="caption">
                    {selectedElement?.associatedCorporateUnits?.length === 1 ||
                    selectedElement?.publishData?.isPublish
                      ? "Publicada"
                      : "Sin publicar"}
                  </Col>
                </Row>
                {(selectedElement?.associatedCorporateUnits?.length === 1 ||
                  selectedElement?.publishData?.isPublish) && (
                  <>
                    <Row
                      id="date-minute"
                      style={{ height: "auto", marginBottom: "3%" }}
                    >
                      <Col md={"auto"} className="subheading">
                        Fecha y hora de publicación:
                      </Col>
                      <Col className="caption">
                        {moment(
                          selectedElement?.associatedCorporateUnits?.[0]
                            ?.createdAt ||
                            selectedElement?.publishData?.createdAt
                        ).format("DD/MM/YYYY HH:mm:ss")}
                      </Col>
                    </Row>
                    <Row
                      id="user-minute"
                      style={{ height: "auto", marginBottom: "3%" }}
                    >
                      <Col md={"auto"} className="subheading">
                        Usuario que publicó:
                      </Col>
                      <Col className="caption">
                        {`
                          ${
                            selectedElement?.associatedCorporateUnits?.[0]
                              ?.publishedBy?.firstName ||
                            selectedElement?.publishData?.publishedBy?.firstName
                          } 
                          ${
                            selectedElement?.associatedCorporateUnits?.[0]
                              ?.publishedBy?.firstSurname ||
                            selectedElement?.publishData?.publishedBy
                              ?.firstSurname
                          }
                        `}
                      </Col>
                    </Row>
                    <Row
                      id="description-minute"
                      style={{ height: "auto", marginBottom: "3%" }}
                    >
                      <Col md={"auto"} className="subheading">
                        Descripción:
                      </Col>
                      <Col className="caption">
                        {selectedElement?.associatedCorporateUnits?.[0]
                          ?.description ||
                          selectedElement?.publishData?.description}
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            {location.pathname.includes("delete") && (
              <>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Fecha de eliminación:
                  </Col>
                  <Col md={"auto"} className="caption">
                    {moment(selectedElement?.updatedAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </Col>
                </Row>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Usuario que eliminó:
                  </Col>
                  <Col className="caption">
                    {`${selectedElement?.deletedBy?.firstName || ""} ${
                      selectedElement?.deletedBy?.firstSurname || ""
                    }`}
                    {(!selectedElement?.deletedBy?.firstName ||
                      !selectedElement?.deletedBy?.firstSurname) &&
                      `${
                        selectedElement?.deletedBy?.companies[0]?.companyData
                          ?.companyName || ""
                      }`}
                  </Col>
                </Row>
              </>
            )}
            {pageName === "DocumentBasket" &&
              selectedElement?.requestAssociated?.requestSubject && (
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Solicitud relacionada:
                  </Col>
                  <Col className="caption" md={"auto"}>
                    {selectedElement?.requestAssociated?.requestSubject}
                  </Col>
                  <Col
                    md={"auto"}
                    className="caption__other-Color-Active"
                    onClick={() => {
                      navigate(
                        `/service/requests/viewRequest/${selectedElement?.requestAssociated?.consecutive}`
                      );
                    }}
                  >
                    Ver historial
                  </Col>
                </Row>
              )}
            {pageName === "FolderBasket" && (
              <>
                <Row style={{ height: "auto", marginBottom: "3%" }}>
                  <Col md={"auto"} className="subheading">
                    Documentos:
                  </Col>
                </Row>
                {selectedElement?.documents?.length > 0 ? (
                  selectedElement.documents
                    .filter(
                      (document) =>
                        document.status === DOCUMENT_STATE.active._id
                    )
                    .map((document) => (
                      <Row
                        style={{ height: "auto", marginBottom: "3%" }}
                        key={document._id || document.name}
                      >
                        <Col
                          data-testid="preview-pdf"
                          md={"auto"}
                          className="caption__other-Color-Active"
                          onClick={() => {
                            handlePreviewPdf(document);
                          }}
                        >
                          {document.name}
                        </Col>
                      </Row>
                    ))
                ) : (
                  <Row style={{ height: "auto", marginBottom: "3%" }}>
                    <Col md={"auto"} className="caption">
                      Sin documentos
                    </Col>
                  </Row>
                )}
              </>
            )}
            {pageName === "Alert" && (
              <>
                <Row>
                  <Col md={"auto"} className="subheading">
                    Documento relacionado:
                  </Col>
                  <Col
                    md={"auto"}
                    className="caption__other-Color-Active"
                    onClick={() => {
                      if (selectedElement?.documentAssociated?.name) {
                        handlePreviewPdf(selectedElement?.documentAssociated);
                      }
                    }}
                  >
                    {selectedElement?.documentAssociated?.name
                      ? selectedElement?.documentAssociated?.name
                      : "Ningún documento relacionado"}
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Row>

        <Row className="drawner-details" style={{ paddingTop: "60%" }}>
          <Col md={"auto"} className="mb-2">
            <Button
              type="submit"
              variant="contained"
              className="drawner-details__back"
              onClick={() => {
                setOpenDetailsDrawner(false);
              }}
              startIcon={<ArrowBackOutlinedIcon fontSize="large" />}
            >
              Volver
            </Button>
          </Col>
          {pageName === "Folder" && (
            <Col md={"auto"}>
              <Button
                type="submit"
                variant="contained"
                className="drawner-details__edit"
                onClick={() => {
                  navigate(`/service/folders/edit/${selectedElement._id}`);
                }}
                startIcon={<CreateOutlinedIcon fontSize="large" />}
              >
                Editar información
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </Drawer>
  );
};

export default DetailsBar;
