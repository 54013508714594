import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Slide,
} from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import CustomSearch from "../Datatable/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import CloseIcon from "@mui/icons-material/Close";
import LoadingContent from "../loadingContent";
import {
  getFolders,
  getStatusFolders,
  fetchAllFolders,
} from "../../parts/folders/foldersSlice";
import { useDispatch, useSelector } from "react-redux";
import { CODES } from "../../utils/codes";
import { getFolderById } from "../../services/business_folder/businessFolderService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalAssociateFolder = ({ open, close, setDataSelected, type }) => {
  const dispatch = useDispatch();
  const statusGetFolders = useSelector(getStatusFolders);
  const folders = useSelector(getFolders);
  const [isLoading, setIsloading] = useState(false);
  const columns = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Etiquetas
        </label>,
      ],
      field: "labels",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const columnsDocs = [
    {
      label: [
        <label aria-hidden="true" key="1">
          Elemento
        </label>,
      ],
      field: "element",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Tipo
        </label>,
      ],
      field: "type",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const [dataTable, setDataTable] = useState({ columns: [], rows: [] });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: [],
    rows: [],
  });
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const handleSelectedData = (data) => {
    setDataSelected(data);
    close();
  };
  const handleOpenFolder = async (folder) => {
    try {
      setIsloading(true);
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");
      const response = await getFolderById({
        companyId,
        id: folder._id,
        corporateUnit,
      });
      if (
        response.status === CODES.COD_RESPONSE_HTTP_OK &&
        response.data.success
      ) {
        const data = response.data.responseMessage;
        setIsFolderOpen(true);
        const rowsDocuments = buildRowsDocument(data.documents);
        const rowsFolders = buildRowsFolder(data.subFolders);
        const rows = [...rowsFolders, ...rowsDocuments];
        setDataTable({ columns: columnsDocs, rows: rows });
        setFilteredDataTable({ columns: columnsDocs, rows: rows });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };
  const buildRowsDocument = (data) => {
    if (data.length > 0) {
      const rows = data.map((item, index) => {
        return {
          element: item.name,
          type: "Documento",
          action: (
            <Row key={item?._id}>
              <Col xs={"auto"}>
                {type === "document" && (
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    size="medium"
                    onClick={() => {
                      handleSelectedData(item);
                    }}
                  >
                    Seleccionar documento
                  </Button>
                )}
              </Col>
            </Row>
          ),
        };
      });
      return rows;
    }
    return [];
  };
  const buildRowsFolder = (data) => {
    if (data.length > 0) {
      const rows = data.map((folder, index) => {
        return {
          element: folder.name,
          type: "Carpeta",
          labels: folder.labels.map((label) => label?.name).join(", ") || "---",
          action: (
            <Row key={folder?._id}>
              <Col xs={"auto"}>
                <Button
                  variant="contained"
                  className="custom-input__button__secondary-color"
                  size="medium"
                  onClick={() => handleOpenFolder(folder)}
                >
                  Abrir
                </Button>
              </Col>
              {type === "folder" && (
                <Col xs={"auto"}>
                  <Button
                    variant="contained"
                    className="custom-input__button__primary-color"
                    size="medium"
                    onClick={() => {
                      handleSelectedData(folder);
                    }}
                  >
                    Seleccionar carpeta
                  </Button>
                </Col>
              )}
            </Row>
          ),
        };
      });
      return rows;
    }
    return [];
  };
  useEffect(() => {
    if (statusGetFolders === "loading") {
      setIsloading(true);
    } else {
      setIsloading(false);
    }
  }, [statusGetFolders]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (statusGetFolders === "fetch") {
          dispatch(fetchAllFolders());
        }
        const processedFolders = buildRowsFolder(folders);
        setDataTable({ columns: columns, rows: processedFolders });
        setFilteredDataTable({ columns: columns, rows: processedFolders });
      } catch (e) {
        console.log(e);
      }
    };
    if (!isFolderOpen) {
      fetchData();
    }
  }, [dispatch, statusGetFolders, isFolderOpen, open]);

    const handleClickCloseModalFolder = () => {
        setIsFolderOpen(false)
    };
    
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={close}
            TransitionComponent={Transition}
        >
            {isLoading && <LoadingContent />}
            <Container>
            <Row className="justify-content-md-end">
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </Col>
          </Row>
          <Row className="text-description" style={{ margin: "2% 0" }}>
            Haz click en el botón “Seleccionar” de la carpeta donde quieres
            guardar el documento firmado.
          </Row>
          <Row style={{ margin: "1% 0" }}>
            <Col md={6} className="col-flex">
              <CustomSearch
                dataTableInfo={dataTable}
                setFilteredDataTable={setFilteredDataTable}
              />
            </Col>
            <Col md={3}></Col>
          </Row>
                <Row style={{ margin: "1% 0" }}>
                    {isFolderOpen && (
                        <Row>
                            <Col xs={"auto"}>
                                <Button
                                    variant="contained"
                                    className="custom-input__button__primary-color"
                                    onClick={handleClickCloseModalFolder}
                                >
                                    Regresar a Carpetas
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <MDBDataTableV5
                        hover
                        pagingTop
                        searchBottom={false}
                        entries={6}
                        data={filteredDataTable}
                        entriesLabel=""
                        infoLabel={["Mostrando", "a", "de", "carpetas"]}
                        fullPagination
                        noRecordsFoundLabel={
                            !folders?.length 
                                ? "Aún no tienes carpetas creadas." 
                                : "No se han encontrado resultados."
                        }
                    />
                </Row>
                <DialogActions>
                    <Row>
                        <Col xs={"auto"}>
                            <Button
                                variant="contained"
                                className="custom-input__button__secondary-color"
                                onClick={close}
                            >
                                Regresar
                            </Button>
                        </Col>
                    </Row>
                </DialogActions>
            </Container>
        </Dialog>
    );

};

export default ModalAssociateFolder;
