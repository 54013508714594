import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSignaturesDetails } from "../../services/signatures/signaturesService";
export const signaturesSlice = createSlice({
  name: "signatures",
  initialState: {
    signaturesResume: [],
    signatureStatus: "fetch",
    signatureError: null,
  },
  reducers: {
    setSignaturesSidePanelStatus(state, action) {
      state.signatureStatus = action.payload.status;
    },
    updateEmailUserSignatureSidePanel(state, action) {
      const { signatureId, signatoryId, newEmail } = action.payload;
      const signatures = state.signaturesResume;
      const signatureIndex = signatures.findIndex(signature => signature?._id === signatureId);
      const signatoryIndex = signatures[signatureIndex].signatoriesInfo.findIndex(signatory => signatory?._id === signatoryId);
      signatures[signatureIndex].signatoriesInfo[signatoryIndex] = {
        ...signatures[signatureIndex].signatoriesInfo[signatoryIndex],
        email: newEmail
      };
      state.signaturesResume = signatures;
    },
    resetSignaturesSidePanel(state, action) {
      const { signatures } = action.payload;
      state.signaturesResume = signatures;
    },
    resetSignaturesSidePanelStatus(state, action) {
      state.signatureStatus = "fetch";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSignatures.pending, (state, action) => {
        state.signatureStatus = "loading";
      })
      .addCase(fetchSignatures.fulfilled, (state, action) => {
        state.signatureStatus = "succeeded";
        state.signaturesResume = action.payload.data.responseMessage;
      })
      .addCase(fetchSignatures.rejected, (state, action) => {
        state.signatureStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const getStatus = (state) => state.signatures.state;

export const { setSignaturesSidePanelStatus, resetSignaturesSidePanelStatus, updateEmailUserSignatureSidePanel, resetSignaturesSidePanel } =
  signaturesSlice.actions;

//Thunks

export const fetchSignatures = createAsyncThunk(
  "signatures/fetchSignatures",
  async (signatureType) => {
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const company = localStorage.getItem("company");
    const obj = {
      signatureType,
      corporateUnit,
      company,
    };
    const response = await getAllSignaturesDetails(obj);
    return {
      data: response.data,
      status: response.status,
    };
  }
);

// Action creators are generated for each case reducer function

export default signaturesSlice.reducer;
